import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, lineHeight } from '~/styles/tokens.stylex';

import { formColors } from '../tokens.stylex';

export const styles = stylex.create({
  input: {
    flexGrow: 1,
    minWidth: 0,
    width: '100%',
    fontSize: fontSize.regular,
    fontWeight: fontWeight.regular,
    lineHeight: lineHeight.tight,

    '::placeholder': {
      color: formColors.placeholderText,
      opacity: 1,
    },

    // Unset global styles
    margin: 'unset',
    padding: 'unset',
    borderWidth: 'unset',
    borderStyle: 'unset',
    borderColor: 'unset',
    borderRadius: 'unset',
    color: 'unset',
    backgroundColor: 'unset',
    cursor: 'unset',
  },

  passwordInput: {
    letterSpacing: '0.2em',
  },
});
