import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { invoicesQueryOptions } from './useInvoices';

export const useAcquireInvoiceLock = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => {
      const token = getAccessToken();
      return request(`/invoices/${id}/lock`, { method: 'POST', token });
    },
    onSuccess: (data, id) => {
      queryClient.setQueryData(invoicesQueryOptions.queryKey, (invoices) =>
        (invoices ?? []).map((invoice) => (invoice.id === id ? { ...invoice, lockedByUser: true } : invoice)),
      );
    },
  });
};
