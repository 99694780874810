import type { ComboboxValue, FormMultiComboboxProps as Props } from './types';

import { useFormControlContext } from '../../context';
import { MultiCombobox } from './MultiCombobox';

export const FormMultiCombobox = <T extends ComboboxValue>({ onAfterChange, ...props }: Props<T>) => {
  const { field, fieldState, id } = useFormControlContext();

  return (
    <MultiCombobox
      id={id}
      innerRef={field.ref}
      invalid={fieldState.invalid}
      onBlur={field.onBlur}
      onChange={(value: T[]) => {
        field.onChange(value);
        onAfterChange?.({ oldValue: field.value, newValue: value });
      }}
      value={field.value ?? []}
      {...props}
    />
  );
};
