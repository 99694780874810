import classNames from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { useClients } from '~/api/clients';
import { Button, CountryFlag, FormCombobox, FormControl, FormErrorMessage, FormLabel, IconTooltip, Label } from '~/components';
import { Icon } from '~/components/SVG';
import { AnchorTargets, SearchParamKeys } from '~/constants/url';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useIntl } from '~/hooks/useIntl';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { qs } from '~/utils/searchParams';
import { formatVatNumber } from '~/utils/string';

import type { DropdownFooterButtonProps, FormClientContactProps } from './types';

import styles from './FormClientContact.module.scss';

export const FormClientContact = ({ page }: FormClientContactProps) => {
  const { data: clients } = useClients();
  const existingClients = useMemo(() => clients.filter(({ deleted }) => !deleted), [clients]);

  const { control, setValue, watch } = useInvoiceQuotationFormContext();

  const navigate = useNavigate();
  const { compareForSort, displayNameOfCountry } = useIntl();
  const { t } = useTranslation(['clients', 'invoices', 'quotations', 'validation']);

  const watchClientId = watch('clientId');
  const watchContactId = watch('contactId');

  const selectedClient = useMemo(() => existingClients.find(({ id }) => id === watchClientId) ?? null, [existingClients, watchClientId]);
  const selectedContact = useMemo(
    () => selectedClient?.contacts.filter(({ deleted }) => !deleted).find(({ id }) => id === watchContactId) ?? null,
    [selectedClient?.contacts, watchContactId],
  );

  const clientOptions = useMemo(
    () =>
      existingClients
        .map((client) => ({
          value: client.id,
          label: client.name,
          filterValue: [client.name, ...client.contacts.filter(({ deleted }) => !deleted).map(({ fullName }) => fullName)],
        }))
        .sort(({ label: a }, { label: b }) => compareForSort(a, b)),
    [compareForSort, existingClients],
  );

  const contactOptions = useMemo(
    () =>
      selectedClient?.contacts
        .filter(({ deleted }) => !deleted)
        .sort((a, b) => a.fullName.localeCompare(b.fullName))
        .map((contact) => ({ value: contact.id, label: contact.fullName })) ?? [],
    [selectedClient?.contacts],
  );

  /** Update the custom agreement, condition, and special conditions if a contact with custom agreement is selected */
  useEffect(() => {
    const customAgreements = selectedContact?.customAgreements ?? [];

    if (!selectedContact || customAgreements.length === 0) return;

    setValue('customAgreementId', customAgreements[0].id);
    setValue('condition', { id: 0, type: 'customAgreement' });
    setValue(
      'specialConditions',
      t('invoices:fields.specialConditions.custom', {
        date: dayjs(customAgreements[0].date).format('DD-MM-YYYY'),
        lng: selectedContact.language,
      }),
    );
  }, [selectedContact, setValue, t]);

  /** Clear the custom agreement, condition, and special conditions if a contact with custom agreement is removed */
  const clearCustomAgreement = useCallback(() => {
    const customAgreements = selectedContact?.customAgreements ?? [];

    if (customAgreements.length === 0) return;

    setValue('customAgreementId', null);
    setValue('condition', null);
    setValue('specialConditions', '');
  }, [selectedContact?.customAgreements, setValue]);

  return (
    <div className="z-10" data-pf-id="iq-input-client-contact">
      {/* Client */}
      {!selectedClient && (
        <FormControl control={control} name="clientId" rules={{ required: true }}>
          <FormLabel>{t('invoices:fields.clientId.label')}</FormLabel>

          <FormCombobox
            Footer={<DropdownFooterButton link={routes.createClient}>{t('invoices:fields.clientId.add')}</DropdownFooterButton>}
            onAfterChange={({ newValue }) => {
              const newSelectedClient = existingClients.find(({ id }) => id === newValue);
              const contacts = newSelectedClient?.contacts.filter(({ deleted }) => !deleted) ?? [];

              if (contacts.length === 1) {
                setValue('contactId', contacts[0].id);
              }
            }}
            options={clientOptions}
            placeholder={t('invoices:fields.clientId.placeholder')}
          />
          <FormErrorMessage required={t('validation:required')} />
        </FormControl>
      )}

      {/* Contact */}
      {selectedClient && !selectedContact && (
        <FormControl control={control} name="contactId" rules={{ required: true }}>
          <FormLabel>{t('invoices:fields.clientId.label')}</FormLabel>
          <div className={styles.Container}>
            <div className={styles.ContactSelect}>
              <div className={styles.Company}>
                <span>{selectedClient.name}</span>
                <span>{formatVatNumber(selectedClient.vatNumber ?? selectedClient.otherNumber ?? '')}</span>
              </div>

              <div className={styles.InputWrapper}>
                <FormCombobox
                  Footer={
                    <DropdownFooterButton link={r(routes.createContact, { clientId: selectedClient.id })}>
                      {t('invoices:fields.contactId.add')}
                    </DropdownFooterButton>
                  }
                  options={contactOptions}
                  placeholder={t('invoices:fields.contactId.placeholder')}
                  transparent
                />

                <Button icon="Close" onClick={() => setValue('clientId', null)} type="iconOnly" />
              </div>
            </div>
          </div>
          <FormErrorMessage required={t('validation:required')} />
        </FormControl>
      )}

      {/* Client and contact information */}
      {selectedClient && selectedContact && (
        <>
          <Label>{t('invoices:fields.clientId.label')}</Label>
          <div className={styles.Container}>
            <div
              className={classNames(
                styles.ClientContactInfo,
                selectedClient.clientType === 'private' && styles.Private,
                !!selectedContact.comments && styles.HasNotes,
              )}
            >
              {selectedClient.clientType === 'organisation' && (
                <div className={styles.CompanyInfo}>
                  <span>{selectedClient.name}</span>
                  {selectedClient.vatNumber && <span>{formatVatNumber(selectedClient.vatNumber)}</span>}
                  {selectedClient.otherNumber && <span>{selectedClient.otherNumber}</span>}
                </div>
              )}

              <div className={styles.AddressInfo}>
                <span>
                  {selectedContact.address.street} {selectedContact.address.number}
                </span>
                <span>
                  {selectedContact.address.zipCode} {selectedContact.address.city}
                </span>
                {selectedContact.address.countryCode !== 'BE' && (
                  <span className={styles.CountryWrapper}>
                    <CountryFlag countryCode={selectedContact.address.countryCode} />
                    {displayNameOfCountry(selectedContact.address.countryCode)}
                  </span>
                )}
              </div>

              <div className={styles.ContactInfo}>
                <span>{selectedClient.isInformalAssociation ? selectedClient.name : selectedContact.fullName}</span>

                {selectedContact.emails
                  .filter(({ messages }) => messages.includes(page === 'invoice' ? 'invoices & credit notes' : 'quotations'))
                  .map(({ email, type }, i) => (
                    <span className={styles.EmailWrapper} key={i}>
                      <span>{email}</span>
                      {type === 'user' && (
                        <IconTooltip
                          size={16}
                          text={
                            page === 'invoice'
                              ? t('invoices:createEdit.clientInfo.emailSelfTooltip')
                              : t('quotations:createEdit.clientInfo.emailSelfTooltip')
                          }
                        />
                      )}
                    </span>
                  ))}

                <Link
                  className={styles.AddEmailButton}
                  to={{
                    pathname: r(routes.editContact, { clientId: selectedClient.id, contactId: selectedContact.id }),
                    search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }),
                    hash: AnchorTargets.CONTACT_EMAILS,
                  }}
                >
                  <Icon name="Add" size={20} />
                  {page === 'invoice'
                    ? t('invoices:createEdit.clientInfo.addEmailReceiver')
                    : t('quotations:createEdit.clientInfo.addEmailReceiver')}
                </Link>
              </div>

              {selectedContact.comments && (
                <div className={styles.Notes}>
                  <span>{t('clients:createEdit.subsections.comments.title')}</span>
                  {selectedContact.comments}
                </div>
              )}

              <div className={styles.ClientContactActions}>
                <Button
                  icon="Edit"
                  onClick={() =>
                    navigate({
                      pathname: r(routes.editContact, { clientId: selectedClient.id, contactId: selectedContact.id }),
                      search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }),
                    })
                  }
                  type="iconOnly"
                />
                <Button
                  icon="Close"
                  onClick={() => {
                    setValue('clientId', null);
                    setValue('contactId', null);
                    clearCustomAgreement();
                  }}
                  type="iconOnly"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const DropdownFooterButton = ({ children, link }: DropdownFooterButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      extraClasses="relative after:absolute after:inset-x-0 after:-inset-y-2" // Increase vertical hitbox to cover padding area
      icon="Add"
      onClick={() =>
        navigate({
          pathname: link,
          search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }),
        })
      }
      type="tertiary"
    >
      {children}
    </Button>
  );
};
