import { auth } from './auth';
import { clients } from './clients';
import { common } from './common';
import { community } from './community';
import { creditnotes } from './creditnotes';
import { dashboard } from './dashboard';
import { filters } from './filters';
import { invitations } from './invitations';
import { invoices } from './invoices';
import { lines } from './lines';
import { products } from './products';
import { quotations } from './quotations';
import { settings } from './settings';
import { validation } from './validation';

export const english = {
  auth,
  clients,
  common,
  community,
  creditnotes,
  dashboard,
  filters,
  invitations,
  invoices,
  lines,
  products,
  validation,
  quotations,
  settings,
};
