import { useMemo } from 'react';

import { useScreenWidth } from '~/hooks/useScreenWidth';

import type { LineProps as Props } from './types';

import { LineDesktop } from '../Line/LineDesktop';
import { LineMobile } from '../Line/LineMobile';
import { useSectionContext } from '../Section/context';
import { LineContext } from './context';

export const SectionLines = () => {
  const { linesFieldArray } = useSectionContext();
  const { fields: lines } = linesFieldArray;

  return useMemo(() => lines.map((line, lineIndex) => <Line key={line.id} line={line} lineIndex={lineIndex} />), [lines]);
};

const Line = ({ line, lineIndex }: Props) => {
  const screen = useScreenWidth();

  const { sectionIndex } = useSectionContext();
  const lineFieldName = `sections.${sectionIndex}.lines.${lineIndex}` as const;
  const context = useMemo(
    () => ({
      line,
      lineIndex,
      lineFieldName,
      isProductLine: line.type === 'entry' && !!line.productName,
      isMobile: !screen.isLarge,
    }),
    [line, lineFieldName, lineIndex, screen.isLarge],
  );

  return <LineContext.Provider value={context}>{screen.isLarge ? <LineDesktop /> : <LineMobile />}</LineContext.Provider>;
};
