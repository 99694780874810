import * as stylex from '@stylexjs/stylex';

import { systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  flagWrapper: {
    // Ensure the flag does not shrink
    width: 16,
  },

  callingCodeOptions: {
    minWidth: 'fit-content',
  },

  callingCodeTag: {
    color: systemColors.textMuted,
  },

  phoneNumberInput: {
    width: '100%',
  },
});
