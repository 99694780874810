import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { getAccessToken } from '~/utils/auth';

import type { CreateCreditNotePayload, CreditNote } from './types';

import { apiResourceSchema } from '../common/schemas';
import { invoicesQueryOptions } from '../invoices';
import { request } from '../request';
import { userQueryOptions } from '../user';
import { creditNotesQueryOptions } from './useCreditNotes';

export const useCreateCreditNote = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateCreditNotePayload) => {
      const token = getAccessToken();
      const response = await request('/creditnotes', { method: 'POST', token, body });

      // TODO: Schema validation
      const { data: creditNote } = apiResourceSchema(z.unknown()).parse(response);

      return creditNote as CreditNote;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(creditNotesQueryOptions);
      queryClient.invalidateQueries(userQueryOptions); // Refetch numbering series to update the next number
      return queryClient.invalidateQueries(invoicesQueryOptions);
    },
  });
};
