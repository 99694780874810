import * as stylex from '@stylexjs/stylex';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useHeartbeat } from '~/api/application';
import { Coffee1, Coffee2 } from '~/components/SVG';
import { Text, Title } from '~/components/UI';

import { styles } from './MaintenanceScreen.styles';

export const MaintenanceScreen = () => {
  const { dataUpdatedAt, isSuccess } = useHeartbeat();
  const [maintenanceStartedAt] = useState(Date.now());

  const { t } = useTranslation(['common']);

  const [Coffee] = useState(() => (Math.random() > 0.5 ? Coffee1 : Coffee2));

  // If the heartbeat succeeds, the application is live again
  if (isSuccess && dataUpdatedAt > maintenanceStartedAt) window.location.reload();

  return (
    <div {...stylex.props(styles.centerContainer)}>
      <div {...stylex.props(styles.contentWrapper)}>
        <Coffee styles={styles.coffee} />

        <div {...stylex.props(styles.textWrapper)}>
          <Title as="h2">{t('common:maintenance.title')}</Title>
          <Text>{t('common:maintenance.description')}</Text>
        </div>
      </div>
    </div>
  );
};
