import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

import type { UseTooltipReturn } from './hooks';

export const TooltipContext = createContext<UseTooltipReturn | null>(null);

export const useTooltipContext = () => {
  const context = useContext(TooltipContext);
  invariant(context, 'Tooltip components must be wrapped in <Tooltip />');

  return context;
};
