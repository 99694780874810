import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Contact } from '~/api/clients/types';

import { ColumnIds } from '~/constants/table';

import { ContactsTableOptions } from './ContactsTableOptions';

const columnHelper = createColumnHelper<Contact>();

export const useContactsTableColumns = () => {
  const { t } = useTranslation(['clients']);

  return useMemo(
    () => [
      // Name
      columnHelper.accessor('fullName', {
        id: ColumnIds.contactsName,
        header: t('clients:detail.contacts.columns.name'),
        cell: ({ getValue }) => <div className="lg:truncate">{getValue()}</div>,
        meta: {
          styles: { size: '1fr', minSize: 150 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Emails
      columnHelper.accessor(({ emails }) => emails.join(' '), {
        id: ColumnIds.contactsEmail,
        header: t('clients:detail.contacts.columns.email'),
        cell: ({ row: { original: contact } }) => {
          if (contact.emails.length === 0) return '-';

          return (
            <ul className="lg:overflow-hidden">
              {contact.emails.slice(0, contact.emails.length > 3 ? 2 : 3).map(({ email, type }, i) => (
                <li className="lg:truncate" key={i}>
                  {type === 'client' ? email : t('clients:detail.contacts.selfEmail', { email })}
                </li>
              ))}
              {contact.emails.length > 3 && (
                <li className="text-medium-gray italic lg:truncate">
                  {t('clients:detail.contacts.moreEmails', { amount: contact.emails.length - 2 })}
                </li>
              )}
            </ul>
          );
        },
        meta: {
          styles: { size: '1fr', minSize: 150 },
          isResizable: true,
        },
      }),

      // Phone number
      columnHelper.accessor('phoneNumber', {
        id: ColumnIds.contactsPhoneNumber,
        header: t('clients:detail.contacts.columns.phoneNumber'),
        cell: ({ getValue }) => <div className="lg:truncate">{getValue()}</div>,
        meta: {
          styles: { size: '1fr', minSize: 100 },
          isResizable: true,
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: contact } }) => <ContactsTableOptions contact={contact} />,
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [t],
  );
};
