import { useTranslation } from 'react-i18next';

import { Warning } from '~/components';

export const ItDevelopmentWarning = () => {
  const { t } = useTranslation(['auth']);

  return (
    <Warning title={t('auth:registrationPage.stepBusinessInformation.alertItDevelopers.title')}>
      {t('auth:registrationPage.stepBusinessInformation.alertItDevelopers.description')}
    </Warning>
  );
};
