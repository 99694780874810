import { match } from 'ts-pattern';

import type { Product } from '~/api/products/types';
import type { Language } from '~/types/app';
import type { InvoiceQuotationFormEntryLine, InvoiceQuotationFormSection, InvoiceQuotationFormTextLine } from '~/types/invoiceQuotation';

import { ActivityType, CreativeWork } from '~/types/app';

/**
 * Create a new creative line
 */
export const createLine = () =>
  ({
    type: 'entry',
    description: '' as const,
    price: '0' as const,
    discountType: null,
    discountValue: null,
    quantity: '1' as const,
    unit: null,
    creativeWork: CreativeWork.CREATIVE,
    productId: null,
    productName: null,
    productStatus: null,
  }) satisfies InvoiceQuotationFormEntryLine;

/**
 * Create a new line based on a product
 */
export const createLineFromProduct = (product: Product, lang: Language) =>
  ({
    ...createLine(),
    price: `${product.price ?? 0}`,
    unit: product.unit,
    creativeWork: mapActivityTypeToCreativeWork(product.activityType),
    productId: product.id,
    productName: product.name[lang] ?? '',
    productStatus: product.status,
  }) satisfies InvoiceQuotationFormEntryLine;

/**
 * Create a new photo album section
 */
export const createPhotoAlbumSection = (name: string) =>
  ({
    name,
    isPhotoAlbum: true,
    lines: [createLine()],
  }) satisfies InvoiceQuotationFormSection;

/**
 * Create a text-only line
 */
export const createTextLine = () =>
  ({
    type: 'text',
    description: '' as const,
  }) satisfies InvoiceQuotationFormTextLine;

/**
 * Check if a line is free based on the discount options
 */
export const isFreeLine = (line: InvoiceQuotationFormEntryLine) =>
  match(line.discountType)
    .with('percentage', () => +(line.discountValue ?? 0) >= 100)
    .with('flatRate', () => +(line.discountValue ?? 0) >= +line.price * +line.quantity)
    .with(null, () => false)
    .exhaustive();

/**
 * Map the ActivityType enum to the CreativeWork enum
 */
const mapActivityTypeToCreativeWork = (activityType: ActivityType) => {
  switch (activityType) {
    case ActivityType.CreativeGeneral:
      return CreativeWork.CREATIVE;
    case ActivityType.CreativeComputer:
      return CreativeWork.CREATIVE_COMPUTER;
    case ActivityType.CreativeReuse:
      return CreativeWork.REUSAGE;
    case ActivityType.CreativeSubcontracting:
      return CreativeWork.SUBCONTRACTING;
    case ActivityType.NonCreative:
      return CreativeWork.NO_CREATIVE;
  }
};
