import * as stylex from '@stylexjs/stylex';

import { spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing.xsmall,
    alignItems: 'center',
  },

  end: {
    justifyContent: 'end',
  },

  wrapReverse: {
    flexWrap: 'wrap-reverse',
  },
});
