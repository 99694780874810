import { captureException } from '@sentry/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken, removeAccessToken, removeLoginType } from '~/utils/auth';

import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const token = getAccessToken();
      await request('/authentication/logout', { method: 'POST', token });
    },
    onSettled: () => {
      removeAccessToken();
      removeLoginType();

      queryClient.removeQueries();

      if (window.productFruits) {
        const pf = window.productFruits as { services: { destroy: () => void } };
        pf.services.destroy();
      }
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) && captureException(new Error('Failed to log out', { cause: error }), { level: 'error' }),
  });
};
