import { useMutation } from '@tanstack/react-query';

import { setAccessToken, setLoginType } from '~/utils/auth';

import { request } from '../request';
import { accessTokenSchema } from './schemas';

type Payload = {
  activationKey: string;
};

export const useAdminLogin = () => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const response = await request('/authentication/login-from-admin', { method: 'POST', body });

      const { accessToken } = accessTokenSchema.parse(response);

      return accessToken;
    },
    onSuccess: (accessToken) => {
      setAccessToken(accessToken);
      setLoginType('admin');
    },
  });
};
