import { useMemo } from 'react';

import { useProducts } from '~/api/products';

export const useProductCategories = () => {
  const { data: products } = useProducts();

  return useMemo(
    () => [...new Set(products.flatMap((product) => product.productCategories))].sort((a, b) => a.localeCompare(b)),
    [products],
  );
};
