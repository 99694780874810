import type { ComponentProps } from 'react';

import type { FilteredFormFieldProps } from '../types';

import { useFormFieldContext } from '../FormFieldContext';
import { Textarea } from './Textarea';

type Props = FilteredFormFieldProps<ComponentProps<typeof Textarea>>;

export const FormTextarea = (delegated: Props) => {
  const { field, fieldState } = useFormFieldContext();

  return (
    <Textarea
      disabled={field.disabled}
      invalid={fieldState.invalid}
      name={field.name}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={field.ref}
      value={field.value}
      {...delegated}
    />
  );
};
