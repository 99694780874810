import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { FormControl, FormErrorMessage, FormField, FormLabel } from '~/components';
import { Icon } from '~/components/SVG';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useIntl } from '~/hooks/useIntl';
import { insertIf } from '~/utils/arrays';
import { isWriter } from '~/utils/user';

import { useLineContext } from '../../SectionLines/context';
import { useFloatingListbox } from '../hooks';
import { preventNumberInputOnWheel } from '../utils';
import styles from './FormQuantity.module.scss';

export const FormQuantity = () => {
  const { data: user } = useUser();

  const { control, watch } = useInvoiceQuotationFormContext();
  const { isMobile, lineFieldName } = useLineContext();

  const watchQuantity = +watch(`${lineFieldName}.quantity`);
  const watchUnit = watch(`${lineFieldName}.unit`);

  const unitOptions = [
    null,
    'hours',
    'halfDays',
    'days',
    'kilometers',
    ...insertIf(isWriter(user) || watchUnit === 'words', 'words'),
    ...insertIf(isWriter(user) || watchUnit === 'characters', 'characters'),
  ] as const;

  const { floatingStyles, refs } = useFloatingListbox(unitOptions.length);

  const { formatDecimal } = useIntl();
  const { t } = useTranslation(['lines', 'validation']);

  return (
    <FormControl
      control={control}
      name={`${lineFieldName}.quantity`}
      rules={{
        required: true,
        min: 0.01,
        max: 100_000,
        validate: {
          maxDecimals: (value) => (`${+value}`.split('.')[1]?.length ?? 0) <= 4,
        },
      }}
    >
      {isMobile && <FormLabel>{t('lines:quantity.header')}</FormLabel>}

      <div className={styles.Wrapper} data-pf-id="line-quantity-unit-input" ref={refs.setReference}>
        <div className={styles.NumberInputWrapper}>
          <FormField>
            {({ field, fieldState, id }) => (
              <input
                className={classNames(styles.NumberInput, fieldState.invalid && styles.Invalid)}
                id={id}
                inputMode="decimal"
                max={100_000}
                min={0}
                onBlur={field.onBlur}
                onChange={field.onChange}
                onWheel={preventNumberInputOnWheel}
                ref={field.ref}
                type="number"
                value={field.value}
              />
            )}
          </FormField>
        </div>

        <FormControl as={Fragment} control={control} name={`${lineFieldName}.unit`}>
          <FormField>
            {({ field, id }) => (
              <Listbox onChange={field.onChange} value={field.value}>
                <ListboxButton className={styles.UnitSelect} id={id} onBlur={field.onBlur} ref={field.ref}>
                  <span className={styles.Unit}>
                    {watchUnit ? t(`lines:units.${watchUnit}`, { count: watchQuantity }) : t('lines:units.placeholder')}
                  </span>
                  <Icon name="KeyboardArrowDown" />
                </ListboxButton>

                <ListboxOptions className={styles.Options} modal={false} ref={refs.setFloating} style={floatingStyles}>
                  {unitOptions.map((unit) => (
                    <ListboxOption className={styles.Option} key={unit} value={unit}>
                      {unit === null ? t('lines:units.none') : t(`lines:units.${unit}`, { count: watchQuantity })}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Listbox>
            )}
          </FormField>
        </FormControl>
      </div>

      {isMobile && (
        <FormErrorMessage
          max={t('validation:max', { attribute: t('lines:quantity.header'), max: formatDecimal(100_000) })}
          maxDecimals={t('validation:maxDecimals', { attribute: t('lines:quantity.header'), max: 4 })}
          min={t('validation:min', { attribute: t('lines:quantity.header'), min: formatDecimal(0.01) })}
          required={t('validation:required')}
        />
      )}
    </FormControl>
  );
};
