import { queryOptions, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { getAccessToken } from '~/utils/auth';

import { QueryKeys } from '../keys';
import { request } from '../request';
import { contractPdfSchema } from './schemas';

export const contractPdfQueryOptions = (isAddendum: boolean) =>
  queryOptions({
    queryKey: isAddendum ? QueryKeys.userContractAddendumPdf : QueryKeys.userContractPdf,
    queryFn: async () => {
      const token = getAccessToken();
      const response = await request('/user/contract', { token });

      const { contract } = z.object({ contract: contractPdfSchema }).parse(response);

      return contract;
    },
    staleTime: Infinity,
  });

export const useContractPdf = (isAddendum: boolean) => {
  return useQuery(contractPdfQueryOptions(isAddendum));
};
