import { useTranslation } from 'react-i18next';

import { Button, Modal } from '~/components';

import type { InvoiceConfirmEditModalProps as Props } from './types';

export const InvoiceConfirmEditModal = ({ isLocked, onClose }: Props) => {
  const { t } = useTranslation(['invoices']);

  return (
    <Modal onClose={() => onClose(false)}>
      <Modal.Title>{t('invoices:details.confirmEditModal.title')}</Modal.Title>

      <p>{isLocked ? t('invoices:details.confirmEditModal.bodyLocked') : t('invoices:details.confirmEditModal.bodyPending')}</p>

      <Modal.Actions>
        <Button onClick={() => onClose(false)} type="secondary">
          {t('invoices:details.confirmEditModal.cancel')}
        </Button>
        <Button onClick={() => onClose(true)}>{t('invoices:details.confirmEditModal.confirm')}</Button>
      </Modal.Actions>
    </Modal>
  );
};
