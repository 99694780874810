import { useModal } from '~/hooks/useModal';

import type { AddHyperlinkProps as Props } from '../types';

import { Button } from '../../Button/Button';
import { AddHyperlinkModal } from './AddHyperlinkModal';

export const AddHyperlinkButton = ({ editor }: Props) => {
  const modal = useModal();

  const setLink = (link: string) => {
    if (!link) return modal.close();

    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: link.startsWith('http') ? link : `https://${link}`, target: '_blank' })
      .run();

    modal.close();
  };

  return (
    <>
      {!editor.isActive('link') ? (
        <Button extraClasses={editor.isActive('link') ? 'active' : ''} icon="Link" onClick={modal.open} type="iconOnly" />
      ) : (
        <Button
          extraClasses={editor.isActive('link') ? 'active' : ''}
          icon="LinkOff"
          onClick={() => editor.chain().focus().unsetLink().run()}
          type="iconOnly"
        />
      )}

      {modal.isOpen && <AddHyperlinkModal onClose={modal.close} onSubmit={(link) => setLink(link)} />}
    </>
  );
};
