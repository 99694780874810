import { useCallback, useMemo, useRef, useState } from 'react';

/**
 * Modal state manager
 */
export const useModal = (initialOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialOpen);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return useMemo(() => ({ isOpen, open, close }), [isOpen, open, close]);
};

/**
 * Modal state manager where data can be passed
 */
export const useModalWithData = <T>(
  initialData: T | null = null,
): { close: () => void; open: (data: T) => void } & ({ data: T; isOpen: true } | { data: null; isOpen: false }) => {
  const [data, setData] = useState(initialData);

  const open = useCallback((data: T) => setData(data), []);
  const close = useCallback(() => setData(null), []);

  return useMemo(
    () => (data !== null ? { data, isOpen: true, open, close } : { data: null, isOpen: false, open, close }),
    [data, open, close],
  );
};

/**
 * Asynchronous, imperative modal state manager
 *
 * Can be used to await a result, for example:
 * ```tsx
 * const modal = useAwaitableModal<boolean>();
 *
 * const onAction = () => {
 *   const confirmed = await modal.open();
 *   if (confirmed) doSomething();
 * }
 *
 * {modal.isOpen && (
 *   <Modal>
 *     <Button onClick={() => modal.closeWithResult(true)}>Confirm</Button>
 *     <Button onClick={() => modal.closeWithResult(false)}>Cancel</Button>
 *   </Modal>
 * )}
 * ```
 */
export const useAwaitableModal = <TResult, TData = undefined>() => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<TData | undefined>(undefined);
  const resolveRef = useRef<((value: TResult) => void) | null>(null);

  const open = useCallback((data?: TData) => {
    setIsOpen(true);
    setData(data);

    return new Promise<TResult>((resolve) => {
      resolveRef.current = resolve;
    });
  }, []);

  const closeWithResult = useCallback((result: TResult) => {
    setIsOpen(false);
    setData(undefined);

    resolveRef.current?.(result);
  }, []);

  return useMemo(() => ({ isOpen, data, open, closeWithResult }), [isOpen, data, open, closeWithResult]);
};
