import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, FormControl, FormErrorMessage, FormLabel, FormTextInput } from '~/components';
import { activities, otherActivityIndex } from '~/constants/constants';

import type { ActivitiesFormStepTwoProps, ActivitiesFormStepTwoType } from './types';

import { ActivityCheckbox } from '../ActivityCheckbox/ActivityCheckbox';

export const ActivitiesFormStepTwo = ({ formData, onFinish, onPreviousStep }: ActivitiesFormStepTwoProps) => {
  const { t } = useTranslation(['common', 'settings', 'validation']);

  const { control, getValues, handleSubmit, setValue, watch } = useForm<ActivitiesFormStepTwoType>({
    defaultValues: {
      secondaryActivities: formData.secondaryActivities,
      otherActivityInformation: formData.otherActivityInformation,
    },
  });

  const isOtherActivityChecked = watch('secondaryActivities')[otherActivityIndex];

  // Clear the other information when the other activity isn't checked
  useEffect(() => {
    if (!isOtherActivityChecked) setValue('otherActivityInformation', '');
  }, [isOtherActivityChecked, setValue]);

  // Uncheck the activity that has been selected as primary activity
  useEffect(() => {
    const index = activities.findIndex((activity) => activity === formData.primaryActivity);
    setValue(`secondaryActivities.${index}`, false);
  }, [formData.primaryActivity, setValue]);

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onFinish)}>
      <div>
        <label>{t('settings:fields.activities.secondary.label')}</label>

        <div className="flex flex-wrap gap-2">
          {activities.map(
            (activity, i) =>
              activity !== formData.primaryActivity && (
                <FormControl control={control} key={activity} name={`secondaryActivities.${i}`}>
                  <ActivityCheckbox activity={activity} />
                </FormControl>
              ),
          )}
        </div>
      </div>

      {isOtherActivityChecked && (
        <FormControl control={control} name="otherActivityInformation" rules={{ required: true }}>
          <FormLabel>{t('settings:fields.activities.other.label')}</FormLabel>
          <FormTextInput />
          <FormErrorMessage required={t('validation:required')} />
        </FormControl>
      )}

      <div className="flex flex-wrap gap-2 justify-end">
        <Button onClick={() => onPreviousStep(getValues())} type="secondary">
          {t('settings:profile.activities.modal.previous')}
        </Button>

        <Button isSubmit>{t('common:save')}</Button>
      </div>
    </form>
  );
};
