import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { clientsQueryOptions } from './useClients';

export const useDeleteContact = (clientId: number, contactId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request(`/clients/${clientId}/contacts/${contactId}`, { method: 'DELETE', token });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};
