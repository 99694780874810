import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';
import { conditionSchema } from './schemas';

export const conditionsQueryOptions = queryOptions({
  queryKey: QueryKeys.conditions,
  queryFn: async () => {
    const token = getAccessToken();
    const response = await request('/conditions', { token });

    const { data: conditions } = apiResourceSchema(conditionSchema.array()).parse(response);

    return conditions;
  },
  staleTime: Infinity,
  gcTime: Infinity,
});

export const useConditions = () => {
  return useSuspenseQuery(conditionsQueryOptions);
};
