export const filters = {
  clients_name: {
    label: 'Klantnaam',
    placeholder: 'Alle klanten',
  },
  clients_type: {
    label: 'Type klant',
    placeholder: 'Alle types',
  },
  credit_notes_brand: {
    label: 'Handelsnaam',
    placeholder: 'Alle handelsnamen',
  },
  credit_notes_client: {
    label: 'Klant',
    placeholder: 'Alle klanten',
  },
  credit_notes_doc_quarter: {
    label: 'Kwartaal',
    placeholder: 'Alle kwartalen',
  },
  credit_notes_doc_year: {
    label: 'Jaar',
    placeholder: 'Alle jaren',
  },
  credit_notes_status: {
    label: 'Status',
    placeholder: 'Alle statussen',
  },
  invoices_brand: {
    label: 'Handelsnaam',
    placeholder: 'Alle handelsnamen',
  },
  invoices_client: {
    label: 'Klant',
    placeholder: 'Alle klanten',
  },
  invoices_doc_quarter: {
    label: 'Kwartaal',
    placeholder: 'Alle kwartalen',
  },
  invoices_doc_year: {
    label: 'Jaar',
    placeholder: 'Alle jaren',
  },
  invoices_status: {
    label: 'Status',
    placeholder: 'Alle statussen',
  },
  products_activity_type: {
    label: 'Soort prestatie',
    placeholder: 'Alle prestaties',
  },
  products_categories: {
    label: 'Categorieën',
    placeholder: 'Alle categorieën',
  },
  products_status: {
    label: 'Status',
    placeholder: 'Alle statussen',
  },
  quarter: {
    option: 'Kwartaal {{quarter}} - {{year}}',
  },
  quotations_brand: {
    label: 'Handelsnaam',
    placeholder: 'Alle handelsnamen',
  },
  quotations_client: {
    label: 'Klant',
    placeholder: 'Alle klanten',
  },
  quotations_doc_quarter: {
    label: 'Kwartaal',
    placeholder: 'Alle kwartalen',
  },
  quotations_doc_year: {
    label: 'Jaar',
    placeholder: 'Alle jaren',
  },
  quotations_status: {
    label: 'Status',
    placeholder: 'Alle statussen',
  },
};
