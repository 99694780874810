import * as stylex from '@stylexjs/stylex';

import { spacing, systemColors } from '~/styles/tokens.stylex';

export const horizontalStyles = stylex.create({
  separator: {
    width: '100%',
    borderBlockEndWidth: 1,
    borderColor: systemColors.borderLight,
  },

  // Margin

  none: {
    marginBlock: spacing.none,
  },

  pixel: {
    marginBlock: spacing.pixel,
  },

  tiny: {
    marginBlock: spacing.tiny,
  },

  xxxsmall: {
    marginBlock: spacing.xxxsmall,
  },

  xxsmall: {
    marginBlock: spacing.xxsmall,
  },

  xsmall: {
    marginBlock: spacing.xsmall,
  },

  small: {
    marginBlock: spacing.small,
  },

  normal: {
    marginBlock: spacing.normal,
  },

  medium: {
    marginBlock: spacing.medium,
  },

  large: {
    marginBlock: spacing.large,
  },

  xlarge: {
    marginBlock: spacing.xlarge,
  },

  xxlarge: {
    marginBlock: spacing.xxlarge,
  },

  xxxlarge: {
    marginBlock: spacing.xxxlarge,
  },
});

export const verticalStyles = stylex.create({
  separator: {
    width: 'min-content',
    height: '100%',
    borderInlineEndWidth: 1,
    borderColor: systemColors.borderLight,
  },

  // Margin

  auto: {
    marginInline: 'auto',
  },

  none: {
    marginInline: spacing.none,
  },

  pixel: {
    marginInline: spacing.pixel,
  },

  tiny: {
    marginInline: spacing.tiny,
  },

  xxxsmall: {
    marginInline: spacing.xxxsmall,
  },

  xxsmall: {
    marginInline: spacing.xxsmall,
  },

  xsmall: {
    marginInline: spacing.xsmall,
  },

  small: {
    marginInline: spacing.small,
  },

  normal: {
    marginInline: spacing.normal,
  },

  medium: {
    marginInline: spacing.medium,
  },

  large: {
    marginInline: spacing.large,
  },

  xlarge: {
    marginInline: spacing.xlarge,
  },

  xxlarge: {
    marginInline: spacing.xxlarge,
  },

  xxxlarge: {
    marginInline: spacing.xxxlarge,
  },
});
