export const products = {
  alerts: {
    successCreated: 'Votre produit a été soumis avec succès pour approbation !',
    successDeleted: 'Le produit a été supprimé avec succès !',
    successUpdated: 'Le produit a été modifié avec succès !',
  },
  announcement:
    'Les Produits, une ligne de prestation que vous pouvez ajouter à maintes reprises. Ce que vous facturez souvent et ne devez donc plus remplir chaque fois et que nous vérifions et approuvons. Plus d\'info <a href="https://knowledge-creativeshelter-be.translate.goog/producten-wat-zijn-ze-en-hoe-gebruik-je-ze?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">peuvent être trouvées ici.</a>',
  createEdit: {
    blocker: {
      create: "Êtes-vous sûr de vouloir quitter cette page ? Votre produit n'a pas encore été créé.",
      edit: 'Êtes-vous sûr de vouloir quitter cette page ? Vos modifications ne sont enregistrées que lorsque vous créez le produit.',
    },
    confirmation: {
      create: {
        description: "Créez votre produit, afin que nous puissions le vérifier et l'approuver, et vous pourrez le facturer facilement !",
        submit: 'Créer un produit',
        title: 'Votre produit est en cours de création',
      },
      edit: {
        description:
          'Êtes-vous sûr de vouloir modifier le produit ? Lorsque vous modifiez votre produit, celui-ci doit être approuvé à nouveau.',
        submit: 'Modifier le produit',
        title: 'Votre produit est en cours de modification',
      },
    },
    info: {
      description: 'Saisissez ici les informations de votre produit',
      title: 'Informations produit',
    },
    submit: {
      create: 'Créer un produit',
      edit: 'Modifier le produit',
    },
    title: {
      create: 'Détails du produit',
      edit: 'Modifier le produit',
    },
  },
  fields: {
    activityType: {
      label: 'Type de performance',
      placeholder: 'Type de performance',
      tooltip:
        'Sélectionnez le type de prestation applicable. <a href="https://knowledge.creativeshelter.be/wanneer-moet-je-welke-soort-prestatie-aanduiden">Vous pouvez vérifier ici quelles prestations s\'appliquent à quel moment.</a>',
    },
    categories: {
      label: 'Catégories',
      placeholder: 'Ajoute ici une catégorie, par exemple : montage, postproduction, traduction, édition finale, ....',
    },
    name: {
      label: 'Nom du produit',
      placeholder: 'Nom du produit',
    },
    price: {
      label: 'Prix',
      tooltip:
        'Utilisez-vous souvent le même prix pour votre produit ? Remplissez-le déjà et épargnez-vous du travail. Vous pouvez toujours le modifier lorsque vous préparez votre facture.',
    },
    unit: {
      label: 'Unité',
    },
  },
  header: {
    actionBlock: {
      button: 'Créer un produit',
    },
    description: 'Consultez et modifiez vos produits et services ici.',
    title: 'Produits',
  },
  modal: {
    add_one: '{{count}} ajouter un produit',
    add_other: '{{count}} ajouter des produits',
    add_zero: 'Ajouter produit(s)',
    createNew: 'Créer un nouveau produit',
    missingTranslations: 'Aucune traduction trouvée',
    title: 'Ajouter produit(s)',
  },
  overview: {
    columns: {
      creativeWork: 'Type :',
      name: 'Nom du produit',
      price: 'Prix',
      productCategories: 'Catégories',
      status: {
        options: {
          Approved: 'Approuvé',
          InReview: 'Pour approbation',
          Pending: 'Pour approbation',
          Rejected: 'Refusé',
        },
        title: 'Statut',
      },
    },
    deleteModal: {
      description: 'Êtes-vous sûr de vouloir supprimer ce produit ?',
      title: 'Supprimer le produit',
    },
  },
};
