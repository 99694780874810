import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useAllowQuotation } from '~/api/quotations';
import { Button, Modal } from '~/components';

import type { AllowQuotationModalProps as Props } from './types';

export const AllowQuotationModal = ({ onClose, quotation }: Props) => {
  const { t } = useTranslation(['common', 'quotations']);

  const mutation = useAllowQuotation(quotation.id);

  const handleAllowQuotation = () => {
    mutation.mutate(undefined, {
      onError: () => toast.error(t('common:error')),
      onSettled: () => onClose(),
    });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('quotations:overview.allowModal.title')}</Modal.Title>

      <p>{t('quotations:overview.allowModal.description')}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Check" isLoading={mutation.isPending} onClick={handleAllowQuotation}>
          {t('quotations:overview.rowActions.allow')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
