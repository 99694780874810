import * as stylex from '@stylexjs/stylex';

import { styles } from './Spinner.styles';

type Props = {
  size?: 'regular' | 'inline';
};
export const Spinner = ({ size = 'regular' }: Props) => {
  return (
    <div {...stylex.props(styles.spinner, styles[size])}>
      <div {...stylex.props(styles.inner)} />
    </div>
  );
};
