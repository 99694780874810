import type { ListboxButtonProps, ListboxProps } from '@headlessui/react';
import type { CSSProperties, ForwardedRef, ReactNode } from 'react';

import { Listbox as HuiListbox, ListboxButton, ListboxOptions, ListboxSelectedOption } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { forwardRef } from 'react';

import type { IconName } from '~/components/SVG';

import { Flex } from '~/components/UI';

import type { WithoutStyleProps } from '../../types';

import { InputButton } from '../_shared/InputButton';
import { InputIcon } from '../_shared/InputIcon';
import { InputPrefix } from '../_shared/InputPrefix';
import { styles as inputWrapperStyles } from '../_shared/InputWrapper.styles';
import { styles } from './Listbox.styles';

type Props = WithoutStyleProps<ListboxProps> &
  Pick<ListboxButtonProps, 'onBlur' | 'autoFocus'> & {
    children: ReactNode;
    clearable?: boolean;
    icon?: IconName;
    placeholder?: string;
    prefix?: string;
    styles?: Partial<Record<'button' | 'options', stylex.StyleXStyles>>;
  };

export const Listbox = forwardRef(
  (
    { autoFocus, children, clearable, icon, onBlur, placeholder, prefix, styles: styleOverrides, ...delegated }: Props,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { invalid, onChange } = delegated;
    const iconName = invalid ? 'ErrorOutline' : icon;

    return (
      <HuiListbox {...delegated}>
        {({ disabled, invalid }) => (
          <>
            <ListboxButton
              {...stylex.props(
                inputWrapperStyles.wrapper,
                !!iconName && inputWrapperStyles.noPaddingLeft,
                inputWrapperStyles.noPaddingRight,
                styles.listboxButton,
                disabled && styles.disabled,
                invalid && styles.invalid,
                styleOverrides?.button,
              )}
              autoFocus={autoFocus}
              onBlur={onBlur}
              ref={ref}
            >
              {iconName && <InputIcon invalid={invalid} name={iconName} />}

              {prefix && <InputPrefix>{prefix}</InputPrefix>}

              <ListboxSelectedOption
                as="div"
                {...stylex.props(styles.selectedItem)}
                options={children}
                placeholder={<span {...stylex.props(styles.placeholder)}>{placeholder}</span>}
              />

              <Flex>
                {clearable && <InputButton borderRight iconName="Close" onClick={() => onChange?.('')} span />}
                <InputIcon invalid={invalid} name="KeyboardArrowDown" />
              </Flex>
            </ListboxButton>

            <ListboxOptions
              {...stylex.props(styles.options, styleOverrides?.options)}
              anchor={{ to: 'bottom start', gap: 4, padding: 16 }}
              modal={false}
              style={{ '--anchor-max-height': '240px' } as CSSProperties}
            >
              {children}
            </ListboxOptions>
          </>
        )}
      </HuiListbox>
    );
  },
);

Listbox.displayName = 'Listbox';
