import * as stylex from '@stylexjs/stylex';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';

import { styles } from './InputIcon.styles';

type Props = {
  invalid?: boolean;
  name: IconName;
  onClick?: () => void;
};
export const InputIcon = ({ invalid = false, name, onClick }: Props) => {
  return (
    <div {...stylex.props(styles.icon, invalid && styles.invalid)} onClick={onClick}>
      <Icon name={name} size={22} />
    </div>
  );
};
