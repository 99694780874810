import dayjs from 'dayjs';

import type { Quotation } from '~/api/quotations/types';

export enum QuotationStatus {
  PENDING = 'Pending',
  SENT = 'Sent',
  EXPIRED = 'Expired',
  ALLOWED = 'Allowed',
  DECLINED = 'Declined',
}

export const getQuotationStatus = (quotation: Quotation): QuotationStatus => {
  if (!quotation.allowed) return QuotationStatus.PENDING;

  if (quotation.declinedByUser) return QuotationStatus.DECLINED;

  if (quotation.allowedByClient || quotation.allowedByUser) return QuotationStatus.ALLOWED;

  if (dayjs(quotation.expirationDate).isBefore(dayjs())) return QuotationStatus.EXPIRED;

  return QuotationStatus.SENT;
};
