import type { ChartOptions } from 'chart.js';

import { useMemo } from 'react';

import { useIntl } from '~/hooks/useIntl';

export const useStackedBarChartOptions = (): ChartOptions<'bar'> => {
  const { formatCurrency } = useIntl();

  return useMemo(
    () => ({
      responsive: true,
      scales: {
        y: {
          stacked: true,
          beginAtZero: true,
          ticks: { callback: (value) => `€ ${value}` },
        },
        x: {
          stacked: true,
          grid: { display: true },
        },
      },
      plugins: {
        legend: { position: 'bottom' },
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }

              if (context.parsed.y !== null) {
                label += formatCurrency(context.parsed.y);
              }

              return label;
            },
          },
        },
      },
    }),
    [formatCurrency],
  );
};
