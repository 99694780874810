import { useCallback, useState } from 'react';

export const useClearableInput = (onChange?: (value: string) => void) => {
  const onClear = useCallback(() => onChange?.(''), [onChange]);

  return { onClear };
};

export const usePasswordInput = (isPasswordInput: boolean) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisible = useCallback(() => isPasswordInput && setIsPasswordVisible((visible) => !visible), [isPasswordInput]);

  return { isPasswordInput, isPasswordVisible, togglePasswordVisible };
};
