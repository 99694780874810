import { Field, Label, Switch } from '@headlessui/react';
import classNames from 'classnames';
import { forwardRef } from 'react';

import { IconTooltip } from '~/components';

import type { ToggleInputProps, ToggleProps } from './types';

import styles from './Toggle.module.scss';

export const Toggle = forwardRef<HTMLLabelElement, ToggleProps>(({ label, tooltip, ...props }, ref) => {
  return (
    <Field>
      <div className={styles.Wrapper}>
        <span className={classNames(styles.ToggleWrapper, props.disabled && styles.Disabled)} ref={ref}>
          <ToggleInput {...props} />
          <Label className={classNames(styles.Label, props.checked && styles.Label__Selected)}>{label}</Label>
        </span>

        {tooltip && <IconTooltip text={tooltip} />}
      </div>
    </Field>
  );
});

Toggle.displayName = 'Toggle';

export const ToggleInput = ({ checked, disabled, id, onBlur, onChange }: ToggleInputProps) => {
  return (
    <Switch checked={checked} className={styles.Toggle} disabled={disabled} id={id} onBlur={onBlur} onChange={onChange}>
      <span className={styles.ToggleThumb} />
    </Switch>
  );
};
