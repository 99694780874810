import type { ZodType } from 'zod';

import { z } from 'zod';

import { countryCodes, europeanUnionCountryCodes, europeanUnionVatCountryCodes, vatCountryCodes } from '~/constants/countries';
import { CreativeWork } from '~/types/app';
import { includes } from '~/utils/arrays';

// API responses

export const apiResourceSchema = <T extends ZodType>(dataSchema: T) =>
  z.object({
    data: dataSchema,
  });

export const paginatedApiResourceSchema = <T extends ZodType>(dataSchema: T) =>
  apiResourceSchema(dataSchema).extend({
    pagination: z.object({
      currentPage: z.number(),
      nextPage: z.number().nullable(),
      totalResults: z.number(),
    }),
  });

// Common

export const iso8601DateTimeSchema = z
  .string()
  .refine((value): value is `${number}-${number}-${number}` | `${number}-${number}-${number} ${number}:${number}:${number}` =>
    /^\d{4}-\d{2}-\d{2}(?: \d{2}:\d{2}:\d{2})?$/.test(value),
  );

export const countryCodeSchema = z.string().refine((value) => includes(countryCodes, value));
export const euCountryCodeSchema = z.string().refine((value) => includes(europeanUnionCountryCodes, value));
export const vatCountryCodeSchema = z.string().refine((value) => includes(vatCountryCodes, value));
export const euVatCountryCodeSchema = z.string().refine((value) => includes(europeanUnionVatCountryCodes, value));

// CO documents

export const productStatusSchema = z.enum(['InReview', 'Approved', 'Rejected']);
export const lineUnitSchema = z.enum(['hours', 'days', 'halfDays', 'kilometers', 'words', 'characters']);
export const lineDiscountTypeSchema = z.enum(['percentage', 'flatRate']);

export const entryLineSchema = z.object({
  type: z.literal('entry'),
  sectionName: z.string(),
  productId: z.number().nullable(),
  productName: z.string().nullable(),
  productStatus: productStatusSchema.nullable(),
  description: z.string(),
  price: z.number(),
  discountType: lineDiscountTypeSchema.nullable(),
  discountValue: z.coerce.number().nullable(),
  quantity: z.number(),
  unit: lineUnitSchema.nullable(),
  total: z.number(),
  creativeWork: z.nativeEnum(CreativeWork),
  isPhotoAlbum: z.boolean(),
});

export const textLineSchema = z.object({
  type: z.literal('text'),
  sectionName: z.string(),
  description: z.string(),
  isPhotoAlbum: z.boolean(),
});

export const lineSchema = z.discriminatedUnion('type', [entryLineSchema, textLineSchema]);

const vatAmountsSchema = z.tuple([
  z.object({ percentage: z.literal(6), amount: z.number() }),
  z.object({ percentage: z.literal(21), amount: z.number() }),
]);

export const calculationDataSchema = z.object({
  coExclVat: z.number(),
  coVat: z.number(),
  coInclVat: z.number(),
  coVatAmounts: vatAmountsSchema,
  coDiscount: z.number(),
  isMinimumCommissionApplied: z.boolean(),

  fcExclVat: z.number(),
  fcPerformanceExclVat: z.number(),
  fcCopyrightExclVat: z.number(),
  fcVatAmounts: vatAmountsSchema,
  fcWithholdingTax: z.number(),

  // Legacy invoices and quotation (before the new calculation) don't have
  // advantage calculations, hence it is marked as nullable.
  advantageWithCs: z
    .object({
      advantage: z.number(),
      advantagePercentage: z.number(),
      coTotalCommission: z.number(),
      fcCopyrightWithholdingTax: z.number(),
      fcCopyrightExpenses: z.number(),
      fcPerformanceExpenses: z.number(),
      fcPerformancePersonalTaxes: z.number(),
      fcPerformanceSocialContribution: z.number(),
      netAmount: z.number(),
    })
    .nullable(),
  advantageWithoutCs: z
    .object({
      expenses: z.number(),
      netAmount: z.number(),
      personalTaxes: z.number(),
      profit: z.number(),
      socialContribution: z.number(),
    })
    .nullable(),

  userIsWorkingFullTime: z.boolean(),
});

export const previewHtmlSchema = z.object({
  previewHTML: z.string(),
});
