import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { useTranslation } from 'react-i18next';

import { useContractPdf } from '~/api/user';
import { PreviewIFrame, Spinner } from '~/components';
import { Icon } from '~/components/SVG';

export const FrameworkAgreement = ({ isAddendum }: { isAddendum: boolean }) => {
  const { data: contractPdf, isError } = useContractPdf(isAddendum);

  const [ref] = useAutoAnimate();

  const { t } = useTranslation(['common', 'dashboard']);

  return (
    <Disclosure as="div" className="mt-6 border border-gray-300" ref={ref}>
      <DisclosureButton className="w-full text-left px-4 py-3 [data-focus]:border border-primary-400 flex justify-between items-center">
        {({ open }) => (
          <>
            <span>
              <span className="block font-semibold">{t('dashboard:frameworkAgreement.title')}</span>
              <span className="block text-sm">{t('dashboard:frameworkAgreement.subtitle')}</span>
            </span>

            <Icon name={open ? 'Remove' : 'Add'} />
          </>
        )}
      </DisclosureButton>

      <DisclosurePanel>
        {isError ? (
          <p className="my-10 text-error-500 italic text-center">{t('common:error')}</p>
        ) : (
          <div className="w-full h-[310mm] flex justify-center items-center bg-gray-300 max-h-[90vh] xl:max-h-[80vh] 2xl:max-h-[70vh]">
            {contractPdf ? <PreviewIFrame src={`data:application/pdf;base64,${contractPdf}`} /> : <Spinner centered />}
          </div>
        )}
      </DisclosurePanel>
    </Disclosure>
  );
};
