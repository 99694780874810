import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import type { CommunityProfileCardProps } from './types';

import { CommunityProfileFooter } from '../CommunityProfileFooter/CommunityProfileFooter';
import { CommunityProfilePicture } from '../CommunityProfilePicture/CommunityProfilePicture';
import styles from './CommunityProfileCard.module.scss';

export const CommunityProfileCard = ({ onClick, profile }: CommunityProfileCardProps) => {
  const { t } = useTranslation(['common', 'community']);

  return (
    <div className={styles.Wrapper} onClick={onClick}>
      <div className={styles.LogoWrapper}>
        <CommunityProfilePicture firstName={profile.firstName} lastName={profile.lastName} url={profile.profilePictureUrl} />
      </div>

      <article className={styles.Card}>
        <header className={styles.Header}>
          <span className={styles.Title}>{profile.brand ?? `${profile.firstName} ${profile.lastName}`}</span>
          <span className={styles.Subtitle}>{profile.brand ? `${profile.firstName} ${profile.lastName}` : ''}</span>
          {profile.openToWork && <span className={styles.OpenToWork}>{t('community:profileCard.openToWork')}</span>}
        </header>

        <ul className={styles.Activities}>
          {profile.activities.map((activity, i) => (
            <li className={classNames(styles.Activity, activity.isPrimaryActivity && styles.Primary)} key={i}>
              {t(`common:activities.${activity.id}.label`)}
            </li>
          ))}
        </ul>

        <CommunityProfileFooter profile={profile} />
      </article>
    </div>
  );
};
