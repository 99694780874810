import type { ScoreRowProps as Props } from './types';

import styles from './ScoreRow.module.scss';

export const ScoreRow = ({ onClick }: Props) => {
  return (
    <ul className={styles['score-list']}>
      {[...Array(10)].map((n, i) => (
        <li className={styles.score} key={i}>
          <button onClick={() => onClick(i + 1)}>
            <data value={i + 1}>{i + 1}</data>
          </button>
        </li>
      ))}
    </ul>
  );
};
