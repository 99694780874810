import type { Table } from '@tanstack/react-table';

import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

export const TableContext = createContext<unknown>(null);

export const useTableContext = <TData>() => {
  const instance = useContext(TableContext);
  invariant(instance, 'Table components must be wrapped in <Table />');

  return instance as Table<TData>;
};
