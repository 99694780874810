import type { KeyboardEvent } from 'react';

import * as stylex from '@stylexjs/stylex';
import { useRef } from 'react';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';

import { styles } from './InputButton.styles';

type Props = {
  borderLeft?: boolean;
  borderRight?: boolean;
  iconName: IconName;
  onClick: () => void;
  span?: boolean;
};

export const InputButton = ({ borderLeft = true, borderRight = false, iconName, onClick, span = false }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const Component = span ? 'span' : 'button';

  const spanProps = {
    tabIndex: 0,
    onKeyDown: (e: KeyboardEvent) => {
      e.stopPropagation();
      if (e.key === ' ' || e.key === 'Enter') buttonRef.current?.click();
    },
  };

  return (
    <Component
      {...stylex.props(
        styles.button,
        borderLeft && styles.borderLeft,
        borderRight && styles.borderRight,
        borderLeft && borderRight && styles.borderLeftAndRight,
      )}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      type="button"
      {...(span && spanProps)}
      ref={buttonRef}
    >
      <Icon name={iconName} />
    </Component>
  );
};
