import * as stylex from '@stylexjs/stylex';

import { spacing, systemColors } from '~/styles/tokens.stylex';

import { formColors } from '../tokens.stylex';

export const styles = stylex.create({
  button: {
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: systemColors.textMuted,
    borderWidth: 1,
    borderColor: {
      default: 'transparent',
      ':focus-visible': formColors.focusBorder,
    },
    borderStartEndRadius: {
      default: 0,
      ':last-child': 3,
    },
    borderEndEndRadius: {
      default: 0,
      ':last-child': 3,
    },
    outlineStyle: 'none',
    marginInlineStart: spacing.pixel,
  },

  // Fake borders (to avoid conflict with focus border)

  borderLeft: {
    boxShadow: `-1px 0 0 0 ${systemColors.borderLight}`,
  },

  borderRight: {
    boxShadow: `1px 0 0 0 ${systemColors.borderLight}`,
  },

  borderLeftAndRight: {
    boxShadow: `-1px 0 0 0 ${systemColors.borderLight}, 1px 0 0 0 ${systemColors.borderLight}`,
  },
});
