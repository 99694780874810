import dayjs from 'dayjs';

import type { CreditNote } from '~/api/creditNotes/types';
import type { Invoice } from '~/types/invoice';

// Invoices

export enum InvoiceStatus {
  PENDING = 'Pending',
  LOCKED_BY_USER = 'LockedByUser',
  SENT = 'Sent',
  EXPIRED = 'Expired',
  CO_PARTIALLY_PAID = 'CoPartiallyPaid',
  CO_PAID = 'CoPaid',
  FC_PAID = 'FcPaid',
  CREDITED = 'Credited',
}

export const getInvoiceStatus = (invoice: Invoice): InvoiceStatus => {
  if (invoice.lockedByUser) {
    return InvoiceStatus.LOCKED_BY_USER;
  }

  if (!invoice.allowed) {
    return InvoiceStatus.PENDING;
  }

  if (invoice.credited) {
    return InvoiceStatus.CREDITED;
  }

  if (invoice.febelfinDate && invoice.paid) {
    return InvoiceStatus.FC_PAID;
  }

  if (invoice.paid) {
    return InvoiceStatus.CO_PAID;
  }

  if (invoice.partiallyPaid) {
    return InvoiceStatus.CO_PARTIALLY_PAID;
  }

  if (dayjs(invoice.paymentDueDate) < dayjs()) {
    return InvoiceStatus.EXPIRED;
  }

  return InvoiceStatus.SENT;
};

// Credit notes
export enum CreditnoteStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
}

export const getCreditnoteStatus = (creditnote: CreditNote): CreditnoteStatus =>
  creditnote.allowed ? CreditnoteStatus.APPROVED : CreditnoteStatus.PENDING;

/**
 * Check if an entry number has already been used
 */
export const isDuplicateEntryNumber = (entryNumber: string, invoices: Invoice[], creditNotes: CreditNote[]) =>
  invoices.filter(({ docYear }) => docYear === dayjs().year()).some((invoice) => entryNumber === invoice.entryNumber) ||
  creditNotes.filter(({ docYear }) => docYear === dayjs().year()).some((creditNote) => entryNumber === creditNote.entryNumber);
