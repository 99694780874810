import type { ComponentProps } from 'react';

import type { FilteredFormFieldProps } from '../types';

import { useFormFieldContext } from '../FormFieldContext';
import { Listbox } from './Listbox';

type Props<TType> = FilteredFormFieldProps<ComponentProps<typeof Listbox>> & {
  afterOnChange?: (values: { newValue: TType; oldValue: TType }) => void;
};

export const FormListbox = <TType,>({ afterOnChange, children, ...delegated }: Props<TType>) => {
  const { field, fieldState } = useFormFieldContext();

  return (
    <Listbox
      disabled={field.disabled}
      invalid={fieldState.invalid}
      name={field.name}
      onBlur={field.onBlur}
      onChange={(value) => {
        field.onChange(value);
        afterOnChange?.({ oldValue: field.value, newValue: value as TType });
      }}
      ref={field.ref}
      value={field.value}
      {...delegated}
    >
      {children}
    </Listbox>
  );
};
