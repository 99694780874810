import * as stylex from '@stylexjs/stylex';

import { animationSpeed, neutralColors, radius, spacing, systemColors } from '~/styles/tokens.stylex';

import { formColors } from '../tokens.stylex';

const hasFocusedChild = ':has([data-focus]:not([data-invalid]))' as const;
const hasInvalidChild = ':has([data-invalid])' as const;
const hasDisabledChild = ':has([data-disabled])' as const;

export const styles = stylex.create({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'stretch',
    height: 40,
    minHeight: 40,
    paddingInline: spacing.small,
    borderWidth: 1,
    borderColor: systemColors.borderLight,
    borderRadius: radius.xxxsmall,
    color: formColors.inputText,
    backgroundColor: neutralColors.white,
    transitionProperty: 'border',
    transitionDuration: animationSpeed.fast,
  },

  dataStates: {
    borderColor: {
      default: systemColors.borderLight,
      [hasFocusedChild]: formColors.focusBorder,
      [hasInvalidChild]: formColors.invalid,
    },
    color: {
      default: formColors.inputText,
      [hasDisabledChild]: formColors.disabledText,
    },
    backgroundColor: {
      default: neutralColors.white,
      [hasDisabledChild]: formColors.disabledBackground,
    },
    cursor: {
      default: null,
      [hasDisabledChild]: 'not-allowed',
    },
  },

  noPaddingLeft: {
    paddingInlineStart: 0,
  },

  noPaddingRight: {
    paddingInlineEnd: 0,
  },
});
