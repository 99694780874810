import { useTranslation } from 'react-i18next';

import { TextInput } from '~/components';

import { useTableContext } from '../context';
import styles from './TableSearch.module.scss';

export const TableSearch = () => {
  const { getState, setGlobalFilter } = useTableContext();
  const { t } = useTranslation(['common']);

  const { globalFilter } = getState();

  return (
    <div className={styles.TableSearch}>
      <TextInput
        clearable
        iconName="Search"
        onChange={(value) => setGlobalFilter(value)}
        placeholder={t('common:datatable.search')}
        value={globalFilter ?? ''}
      />
    </div>
  );
};
