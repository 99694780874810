import dayjs from 'dayjs';
import invariant from 'tiny-invariant';

import type { IsoDateTime } from '~/api/common/types';
import type { UpdateDraftQuotationPayload, UpdateQuotationPayload } from '~/api/quotations/types';

import { flattenFormSectionsToLines } from '~/utils/invoicesQuotations';

import type { CreateEditQuotationFormType } from './types';

export const mapQuotationFormDataToDraftPayload = (data: CreateEditQuotationFormType) =>
  ({
    brandId: data.brandId,
    clientContactId: data.contactId,
    conditionId: data.condition?.type === 'condition' ? data.condition.id : null,
    customAgreementId: data.condition?.type === 'customAgreement' ? data.customAgreementId : null,
    customConditionId: data.condition?.type === 'customCondition' ? data.condition.id : null,
    description: data.description,
    draft: true as const,
    entryNumber: data.entryNumber,
    expirationDate: data.expirationDate ? (dayjs(data.expirationDate).format('YYYY-MM-DD HH:mm:ss') as IsoDateTime) : null,
    introduction: data.introduction,
    mail: data.mail,
    quotationLines: data.sections ? flattenFormSectionsToLines(data.sections) : [],
    specialConditions: data.specialConditions,
    title: data.title,
  }) satisfies UpdateDraftQuotationPayload;

export const mapQuotationFormDataToPayload = (data: CreateEditQuotationFormType) => {
  // Narrow types of required fields
  invariant(data.brandId !== null, 'Expected brandId to be defined.');
  invariant(data.contactId, 'Expected contactId to be defined.');
  invariant(data.condition, 'Expected condition to be defined.');
  invariant(data.expirationDate, 'Expected expirationDate to be defined.');

  return {
    brandId: data.brandId,
    clientContactId: data.contactId,
    conditionId: data.condition.type === 'condition' ? data.condition.id : null,
    customAgreementId: data.condition.type === 'customAgreement' ? data.customAgreementId : null,
    customConditionId: data.condition.type === 'customCondition' ? data.condition.id : null,
    description: data.description,
    draft: false as const,
    entryNumber: data.entryNumber,
    expirationDate: dayjs(data.expirationDate).format('YYYY-MM-DD HH:mm:ss') as IsoDateTime,
    introduction: data.introduction,
    mail: data.mail,
    quotationLines: flattenFormSectionsToLines(data.sections),
    specialConditions: data.specialConditions,
    title: data.title,
  } satisfies UpdateQuotationPayload;
};
