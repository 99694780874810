import * as stylex from '@stylexjs/stylex';

import { statusColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  kiiaLink: {
    color: {
      default: statusColors.infoDark,
      ':hover': statusColors.infoMuted,
      ':focus-visible': statusColors.infoMuted,
    },
  },
});
