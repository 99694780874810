import * as stylex from '@stylexjs/stylex';

import { statusColors } from '~/styles/tokens.stylex';

const isHovered = ':is([data-hover])';
const isFocused = ':is([data-focus])';

export const styles = stylex.create({
  form: {
    flexGrow: 1,
  },

  resendVerificationEmailLink: {
    color: {
      default: statusColors.infoDark,
      [isHovered]: statusColors.infoMuted,
      [isFocused]: statusColors.infoMuted,
    },
  },
});
