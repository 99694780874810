import type { CommunityProfilePictureProps as Props } from './types';

import styles from './CommunityProfilePicture.module.scss';

export const CommunityProfilePicture = ({ firstName, lastName, url }: Props) => {
  if (!url) {
    return (
      <div className={styles.Wrapper}>
        <div className={styles.ProfilePicturePlaceholder}>
          <span className={styles.ProfilePicturePlaceholderText}>
            {firstName.charAt(0)}
            {lastName.charAt(0)}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.Wrapper}>
      <img alt={`${firstName} ${lastName}`} className={styles.ProfilePicture} src={url} />
    </div>
  );
};
