import { Chart, registerables } from 'chart.js';
import { useEffect, useRef } from 'react';

import { useScreenWidth } from '~/hooks/useScreenWidth';

import type { CustomChartProps as Props } from './types';

import { ChartWrapper } from './ChartWrapper';

Chart.register(...registerables);

export const CustomChart = ({ datasets, labels, options, title, type, ...wrapperProps }: Props) => {
  const screen = useScreenWidth();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const chart: Chart = new Chart(canvasRef.current ?? 'CustomChart', {
      type,
      data: { labels, datasets },
      options,
    });

    return () => chart.destroy();
  }, [labels, datasets, type, options]);

  return (
    <ChartWrapper title={title} {...wrapperProps}>
      <canvas height={screen.isMobile ? 250 : 150} id="canvasChart" ref={canvasRef} />
    </ChartWrapper>
  );
};
