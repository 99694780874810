import { Icon } from '~/components/SVG';

import type { FilterableColumnIds, TableFilterTagProps as Props } from './types';

import { useTableContext } from '../context';
import { useTableFiltersContext } from './context';
import styles from './TableFilters.module.scss';

export const TableFilterTags = () => {
  const { getState } = useTableContext();

  const { columnFilters } = getState();

  if (columnFilters.length === 0) return null;

  return (
    <div className={styles.FilterTags}>
      {columnFilters.map(({ id, value }) =>
        Array.isArray(value) ? (
          value.map((value) => <TableFilterTag columnId={id as FilterableColumnIds} filterValue={value} key={`${id}-${value}`} />)
        ) : (
          <TableFilterTag columnId={id as FilterableColumnIds} filterValue={value} key={`${id}-${value}`} />
        ),
      )}
    </div>
  );
};

const TableFilterTag = ({ columnId, filterValue }: Props) => {
  const { getColumn } = useTableContext();
  const options = useTableFiltersContext()[columnId];

  const label = options?.find((option) => option.value === filterValue)?.label ?? `${columnId}: ${filterValue}`;

  return (
    <div className={styles.FilterTag}>
      {label}
      <button
        className={styles.CloseButton}
        onClick={() =>
          getColumn(columnId)?.setFilterValue((value: unknown) => (Array.isArray(value) ? value.filter((v) => v !== filterValue) : null))
        }
      >
        <Icon name="Close" size={14} />
      </button>
    </div>
  );
};
