import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { match, Pattern } from 'ts-pattern';

import type { IconName } from '~/components/SVG';

import { useCreditNotes } from '~/api/creditNotes';
import { Tooltip } from '~/components';
import { Icon } from '~/components/SVG';
import { useIntl } from '~/hooks/useIntl';

import type {
  InvoiceStatusDateBlockProps,
  InvoiceStatusIconProps,
  InvoiceStatusInfoProps,
  InvoiceStatusMessageProps,
  InvoiceStatusProgressBarProps,
  InvoiceStatusProgressBarsProps,
  InvoiceStatusTitleProps,
} from './types';

import { getInvoiceStatus, InvoiceStatus } from '../../utils';
import styles from './InvoiceStatusInfo.module.scss';

export const InvoiceStatusInfo = ({ invoice }: InvoiceStatusInfoProps) => {
  const status = getInvoiceStatus(invoice);

  return (
    <div className={styles.Container}>
      <article className={styles.Wrapper}>
        <span className={styles.Icon}>
          <InvoiceStatusIcon status={status} />
        </span>

        <InvoiceStatusTitle invoice={invoice} status={status} />

        <div className={styles.DateBlocks}>
          <InvoiceStatusDateBlock invoice={invoice} type="sent" />
          <InvoiceStatusDateBlock
            invoice={invoice}
            type={match(status)
              .returnType<InvoiceStatusDateBlockProps['type']>()
              .with(InvoiceStatus.CO_PAID, () => 'coPaid')
              .with(InvoiceStatus.FC_PAID, () => 'fcPaid')
              .with(InvoiceStatus.CREDITED, () => 'credited')
              .with(Pattern.any, () => 'expired')
              .exhaustive()}
          />
        </div>

        <InvoiceStatusMessage invoice={invoice} status={status} />
      </article>

      <InvoiceStatusProgressBars status={status} />
    </div>
  );
};

const InvoiceStatusIcon = ({ status }: InvoiceStatusIconProps) => {
  const iconName = (
    {
      [InvoiceStatus.PENDING]: 'PersonWithLaptop',
      [InvoiceStatus.LOCKED_BY_USER]: 'PendingOutlined',
      [InvoiceStatus.SENT]: 'Send',
      [InvoiceStatus.EXPIRED]: 'HourglassBottom',
      [InvoiceStatus.CO_PARTIALLY_PAID]: 'MoneySmall',
      [InvoiceStatus.CO_PAID]: 'Money',
      [InvoiceStatus.FC_PAID]: 'Money',
      [InvoiceStatus.CREDITED]: 'Receipt',
    } as const satisfies Record<InvoiceStatus, IconName>
  )[status];

  return <Icon name={iconName} size={64} />;
};

const InvoiceStatusTitle = ({ invoice, status }: InvoiceStatusTitleProps) => {
  const { t } = useTranslation(['invoices']);

  return (
    <h2 className={styles.Title}>
      {match([status, invoice])
        .with([InvoiceStatus.EXPIRED, { paymentFollowUp: Pattern.nonNullable }], () =>
          t('invoices:details.statusInfo.title.paymentFollowUp'),
        )
        .with(Pattern.any, () => t(`invoices:overview.columns.status.options.${status}`))
        .exhaustive()}
    </h2>
  );
};

const InvoiceStatusDateBlock = ({ invoice, type }: InvoiceStatusDateBlockProps) => {
  const date = (
    {
      sent: invoice.docDate,
      expired: invoice.paymentDueDate,
      coPaid: invoice.paidDate,
      fcPaid: invoice.febelfinDate,
      credited: invoice.creditNoteDate,
    } satisfies Record<InvoiceStatusDateBlockProps['type'], string | null>
  )[type];

  const { formatDate } = useIntl();
  const { t } = useTranslation(['invoices']);

  return (
    <dl className={styles.DateBlock}>
      <dt className={styles.Label}>{t(`invoices:details.statusInfo.date.${type}`)}</dt>
      <dd className={styles.Date}>{date ? formatDate(date) : '-'}</dd>
    </dl>
  );
};

const InvoiceStatusMessage = ({ invoice, status }: InvoiceStatusMessageProps) => {
  const { data: creditNotes } = useCreditNotes();

  return (
    <p className={styles.Message}>
      <Trans
        components={{ a: <a />, br: <br /> }}
        i18nKey={match([status, invoice])
          .with([InvoiceStatus.PENDING, Pattern.any], () => 'invoices:details.statusInfo.message.Pending.default' as const)
          .with([InvoiceStatus.LOCKED_BY_USER, Pattern.any], () => 'invoices:details.statusInfo.message.LockedByUser.default' as const)
          .with([InvoiceStatus.SENT, Pattern.any], () => 'invoices:details.statusInfo.message.Sent.default' as const)
          .with(
            [InvoiceStatus.EXPIRED, { paymentFollowUp: Pattern.nonNullable }],
            () => 'invoices:details.statusInfo.message.Expired.followingUp' as const,
          )
          .with([InvoiceStatus.EXPIRED, Pattern.any], () => 'invoices:details.statusInfo.message.Expired.default' as const)
          .with(
            [InvoiceStatus.CO_PARTIALLY_PAID, Pattern.any],
            () => 'invoices:details.statusInfo.message.CoPartiallyPaid.default' as const,
          )
          .with([InvoiceStatus.CO_PAID, Pattern.any], () => 'invoices:details.statusInfo.message.CoPaid.default' as const)
          .with([InvoiceStatus.FC_PAID, Pattern.any], () => 'invoices:details.statusInfo.message.FcPaid.default' as const)
          .with([InvoiceStatus.CREDITED, Pattern.any], () => 'invoices:details.statusInfo.message.Credited.default' as const)
          .exhaustive()}
        values={match(status)
          .with(InvoiceStatus.CREDITED, () => ({
            creditNoteNumber: creditNotes.find(({ invoiceId }) => invoiceId === invoice.id)?.entryNumber ?? '',
            creditNoteReason: creditNotes.find(({ invoiceId }) => invoiceId === invoice.id)?.reason ?? '',
          }))
          .otherwise(() => undefined)}
      />
    </p>
  );
};

const InvoiceStatusProgressBars = ({ status }: InvoiceStatusProgressBarsProps) => {
  const secondBarFilled = [
    InvoiceStatus.SENT,
    InvoiceStatus.EXPIRED,
    InvoiceStatus.CO_PARTIALLY_PAID,
    InvoiceStatus.CO_PAID,
    InvoiceStatus.FC_PAID,
    InvoiceStatus.CREDITED,
  ].includes(status);

  const thirdBarFilled = [InvoiceStatus.CO_PAID, InvoiceStatus.FC_PAID, InvoiceStatus.CREDITED].includes(status);

  const { t } = useTranslation(['invoices']);

  return (
    <div className={styles.ProgressBars}>
      <InvoiceStatusProgressBar
        isActive
        tooltipIcon="PersonWithLaptop"
        tooltipPlacement="top-start"
        tooltipText={t('invoices:details.statusInfo.progressBars.stepOneTooltip.body')}
        tooltipTitle={t('invoices:details.statusInfo.progressBars.stepOneTooltip.title')}
      />

      <InvoiceStatusProgressBar
        isActive={secondBarFilled}
        tooltipIcon="Send"
        tooltipPlacement="top"
        tooltipText={t('invoices:details.statusInfo.progressBars.stepTwoTooltip.body')}
        tooltipTitle={t('invoices:details.statusInfo.progressBars.stepTwoTooltip.title')}
      />

      <InvoiceStatusProgressBar
        isActive={thirdBarFilled}
        tooltipIcon="Money"
        tooltipPlacement="top-end"
        tooltipText={t('invoices:details.statusInfo.progressBars.stepThreeTooltip.body')}
        tooltipTitle={t('invoices:details.statusInfo.progressBars.stepThreeTooltip.title')}
      />
    </div>
  );
};

const InvoiceStatusProgressBar = ({
  isActive,
  tooltipIcon,
  tooltipPlacement,
  tooltipText,
  tooltipTitle,
}: InvoiceStatusProgressBarProps) => {
  return (
    <Tooltip placement={tooltipPlacement}>
      <Tooltip.Trigger>
        <div className={classNames(styles.ProgressBar, isActive && styles.Active)} />
      </Tooltip.Trigger>
      <Tooltip.Content>
        <div className="mb-2 flex items-center gap-x-2">
          <Icon name={tooltipIcon} size={20} />
          <p className="font-semibold text-base">{tooltipTitle}</p>
        </div>
        <p className="text-left text-wrap">{tooltipText}</p>
      </Tooltip.Content>
    </Tooltip>
  );
};
