import type { Header } from '@tanstack/react-table';
import type { MouseEventHandler } from 'react';

import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Icon } from '~/components/SVG';

import { useTableContext } from '../context';
import { useTableGridContext } from '../TableGrid/context';
import styles from './TableHeader.module.scss';

const alignmentStyles = {
  start: styles.Start,
  center: styles.Center,
  end: styles.End,
  stretch: styles.Stretch,
};

export const TableHeader = () => {
  const { getHeaderGroups } = useTableContext();

  return (
    <div className={styles.TableHeaderRow}>
      {getHeaderGroups()
        .flatMap((headerGroup) => headerGroup.headers)
        .map((header) => (
          <TableHeaderCell header={header} key={header.id} />
        ))}
    </div>
  );
};

const TableHeaderCell = ({ header }: { header: Header<unknown, unknown> }) => {
  const { columnStyles, resetGridTemplateColumns, setGridTemplateColumns } = useTableGridContext();

  const headerCellRef = useRef<HTMLDivElement>(null);
  const startPositionRef = useRef<number | null>(null);
  const [isResizing, setIsResizing] = useState(false);

  const onMouseDown = useCallback<MouseEventHandler>((e) => {
    startPositionRef.current = e.screenX;
    setIsResizing(true);
  }, []);

  const onMouseUp = useCallback(() => {
    setIsResizing(false);
    startPositionRef.current = null;
  }, []);

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      const headerElement = headerCellRef.current;

      if (!headerElement) return;

      window.requestAnimationFrame(() => {
        if (startPositionRef.current === null) return; // Mouse has already been released before this RAF callback is executed

        const offset = e.screenX - startPositionRef.current;

        // Note: This resizing logic assumes only a single header group is used,
        // because it uses `header.index` to compare positions.
        setGridTemplateColumns((prev) => {
          return prev.map((value, i) => {
            // Set widths of all columns to the left of the resized column fixed in pixels
            if (i < header.index) {
              const el = headerElement.parentElement?.children[i];
              const width = el?.getBoundingClientRect()?.width ?? 0;
              return `${width}px`;
            }

            // Fix width of resized column based on mouse position
            if (i === header.index) {
              const width = headerElement.getBoundingClientRect().width + offset;
              const minWidth = columnStyles[i].minSize ?? 0;
              return `${Math.max(width, minWidth)}px`;
            }

            // Don't alter columns to the right of the resized column
            return value;
          });
        });

        startPositionRef.current = e.screenX;
      });
    },
    [columnStyles, header.index, setGridTemplateColumns],
  );

  useEffect(() => {
    if (!isResizing) return;

    window.addEventListener('mouseup', onMouseUp);
    window.addEventListener('mousemove', onMouseMove);

    return () => {
      window.removeEventListener('mouseup', onMouseUp);
      window.removeEventListener('mousemove', onMouseMove);
    };
  }, [isResizing, onMouseMove, onMouseUp]);

  const alignment = header.column.columnDef.meta?.styles.justify ?? 'start';

  return (
    <div className={classNames(styles.TableHeaderCell, alignmentStyles[alignment])} key={header.id} ref={headerCellRef}>
      <span
        className={classNames(styles.TableHeaderText, header.column.getCanSort() && styles.Sortable)}
        onClick={header.column.getToggleSortingHandler()}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
      </span>

      {header.column.getIsSorted() && (
        <Icon name={header.column.getIsSorted() === 'asc' ? 'KeyboardArrowUp' : 'KeyboardArrowDown'} size={20} />
      )}

      {!!header.column.columnDef.meta?.isResizable && (
        <div
          className={classNames(styles.TableHeaderResizer, isResizing && styles.IsResizing)}
          onDoubleClick={resetGridTemplateColumns}
          onMouseDown={onMouseDown}
        >
          <span />
        </div>
      )}
    </div>
  );
};
