import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import { Text } from '../Text/Text';
import { styles } from './ErrorScreen.styles';

type Props = {
  children: ReactNode;
};

export const ErrorScreen = ({ children }: Props) => {
  return (
    <div {...stylex.props(styles.fullscreen)}>
      <Text size="xlarge" styles={styles.message} weight="bold">
        {children}
      </Text>
    </div>
  );
};
