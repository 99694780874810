import { useTranslation } from 'react-i18next';

import { BrandDot, Button } from '~/components';
import { Icon } from '~/components/SVG';

import type { SlideProps as Props } from './types';

export const Slide = ({ brandColor, client, contact, deleteAction, editAction, id, title }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <div className="title-and-buttons">
        <h5>
          {title || t('common:draft.defaultTitle')}
          {brandColor && <BrandDot color={brandColor} />}
        </h5>

        <div className="button-group">
          {editAction && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                editAction(id);
              }}
              type="iconOnly"
            >
              <Icon name="Edit" />
            </Button>
          )}

          <Button
            onClick={(e) => {
              e.stopPropagation();
              deleteAction(id);
            }}
            type="iconOnly"
          >
            <Icon name="Delete" />
          </Button>
        </div>
      </div>

      <div className="client">
        {client ? (
          <>
            <small className="organisation">
              {client.clientType === 'organisation' || client.isInformalAssociation ? client.name : ''}
            </small>
            <small>{contact?.fullName ?? ''}</small>
          </>
        ) : (
          <small>{t('common:draft.defaultClient')}</small>
        )}
      </div>
    </>
  );
};
