import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UpdateDraftQuotationPayload } from './types';

import { apiResourceSchema, calculationDataSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';

export const useCalculateForQuotation = (id: number) => {
  return useMutation({
    mutationKey: QueryKeys.quotationCalculation(id),
    mutationFn: async (body: UpdateDraftQuotationPayload) => {
      const token = getAccessToken();
      const response = await request(`/quotations/${id}/calculate`, { method: 'POST', token, body });

      const { data: calculationData } = apiResourceSchema(calculationDataSchema).parse(response);

      return calculationData;
    },
  });
};
