import {
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMemo, useState } from 'react';

import type { TooltipOptions } from './types';

export const useTooltip = ({
  disableSafePolygon = false,
  initialOpen = false,
  onOpenChange: setControlledOpen,
  open: controlledOpen,
  placement = 'top',
}: TooltipOptions) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(8), flip({ fallbackAxisSideDirection: 'start', crossAxis: placement.includes('-') }), shift({ padding: 8 })],
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    handleClose: disableSafePolygon ? null : safePolygon(),
    enabled: controlledOpen === undefined,
  });
  const focus = useFocus(context, { enabled: controlledOpen === undefined });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(() => ({ open, setOpen, ...interactions, ...data }), [open, setOpen, interactions, data]);
};

export type UseTooltipReturn = ReturnType<typeof useTooltip>;
