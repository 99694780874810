import type { FormToggleProps as Props } from './types';

import { useFormControlContext } from '../../context';
import { Toggle } from './Toggle';

export const FormToggle = (props: Props) => {
  const { field, id } = useFormControlContext();

  return <Toggle checked={!!field.value} id={id} onBlur={field.onBlur} onChange={field.onChange} ref={field.ref} {...props} />;
};
