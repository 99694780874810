import { useWindowWidth } from '@react-hook/window-size';
import { useCallback, useMemo } from 'react';

const breakpoints = ['2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'] as const;
type Breakpoint = (typeof breakpoints)[number];

// These numbers should match the breakpoints in _variables.scss
const getBreakpoint = (windowWidth: number): Breakpoint => {
  if (windowWidth >= 1920) return '3xl';
  if (windowWidth >= 1536) return '2xl';
  if (windowWidth >= 1280) return 'xl';
  if (windowWidth >= 1024) return 'lg';
  if (windowWidth >= 768) return 'md';
  if (windowWidth >= 640) return 'sm';
  if (windowWidth >= 400) return 'xs';
  return '2xs';
};

/**
 * Get the screen width breakpoint based on the window width.\
 * Provides helper functions for comparison against certain breakpoint.
 *
 * ```txt
 * [   0,  400[ - Extra extra small
 * [ 400,  640[ - Extra small
 * [ 640,  768[ - Small
 * [ 768, 1024[ - Medium
 * [1024, 1280[ - Large
 * [1280, 1536[ - Extra large
 * [1536, 1920[ - Extra extra large
 * [1920,   +∞[ - Extra extra extra large
 * ```
 */
export const useScreenWidth = () => {
  const windowWidth = useWindowWidth();

  const breakpoint = useMemo(() => getBreakpoint(windowWidth), [windowWidth]);

  const isSmallerThan = useCallback(
    (target: Exclude<Breakpoint, '2xs'>) => breakpoints.indexOf(breakpoint) < breakpoints.indexOf(target),
    [breakpoint],
  );

  const isLargerThan = useCallback(
    (target: Exclude<Breakpoint, '3xl'>) => breakpoints.indexOf(breakpoint) >= breakpoints.indexOf(target),
    [breakpoint],
  );

  return useMemo(
    () => ({
      breakpoint,

      /** Check if the screen width is smaller than the given breakpoint (exclusive) */
      isSmallerThan,
      /** Check if the screen width is larger than the given breakpoint (inclusive) */
      isLargerThan,

      /** Shorthand for `isSmallerThan('sm')` */
      isMobile: isSmallerThan('sm'),
      /** Shorthand for `isLargerThan('lg')` */
      isLarge: isLargerThan('lg'),
    }),
    [breakpoint, isLargerThan, isSmallerThan],
  );
};
