import type { Control, FieldValues } from 'react-hook-form';

import { useCallback } from 'react';
import { useFormState } from 'react-hook-form';

import type { VatCountryCode } from '~/api/common/types';

import { useClients } from '~/api/clients';
import { cleanVatNumber } from '~/utils/string';

import { useCreateClientContext } from '../context';

export const useIsBlocked = <T extends FieldValues>(control: Control<T>) => {
  const { contactFormData, organisation } = useCreateClientContext();
  const { isDirty } = useFormState({ control });

  return isDirty || !!organisation || !!contactFormData;
};

export const useGetDuplicateClient = () => {
  const { data: clients } = useClients();

  return useCallback(
    (vatCountryCode: VatCountryCode, vatNumberWithoutCountryCode: string) => {
      const newVatNumber = cleanVatNumber(`${vatCountryCode}${vatNumberWithoutCountryCode}`);

      return (
        clients.filter(({ deleted }) => !deleted).find(({ vatNumber: existingVatNumber }) => existingVatNumber === newVatNumber) ?? null
      );
    },
    [clients],
  );
};
