import { queryOptions, useQuery } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { QueryKeys } from '../keys';
import { request } from '../request';
import { conditionPdfSchema } from './schemas';

const conditionPdfQueryOptions = (id: number) =>
  queryOptions({
    queryKey: QueryKeys.conditionsPdf(id),
    queryFn: async () => {
      const token = getAccessToken();
      const response = await request(`/conditions/${id}/preview`, { token });

      const { pdf } = conditionPdfSchema.parse(response);

      return pdf;
    },
    staleTime: Infinity,
  });

export const useConditionPdf = (id: number) => {
  return useQuery(conditionPdfQueryOptions(id));
};
