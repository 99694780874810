import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';
import { useEffect, useId } from 'react';

import { useAlertContext } from './Alert.context';
import { styles } from './AlertDescription.styles';

type Props = {
  children: ReactNode;
};

export const AlertDescription = ({ children }: Props) => {
  const id = `AlertDescription_${useId()}`;
  const { setAriaDescriptionId } = useAlertContext();

  useEffect(() => {
    setAriaDescriptionId(id);
  }, [id, setAriaDescriptionId]);

  return (
    <div {...stylex.props(styles.alertDescription)} id={id}>
      {children}
    </div>
  );
};
