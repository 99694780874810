import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useProducts } from '~/api/products';
import { ColumnIds } from '~/constants/table';
import { useTable } from '~/hooks/useTable';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { useProductsTableColumns, useProductsTableFilters } from './ProductsTable.hooks';

export const ProductsTable = () => {
  const { data: products } = useProducts();

  const data = useMemo(() => products.toSorted((product1, product2) => product2.id - product1.id), [products]);
  const columns = useProductsTableColumns();
  const [table, Table] = useTable({ data, columns });
  const filterOptions = useProductsTableFilters(data);

  const navigate = useNavigate();

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <Table.Search />
        <Table.FiltersToggle />
      </Table.Menu>

      <Table.Filters
        options={{
          [ColumnIds.productsStatus]: filterOptions.statuses,
          [ColumnIds.productsActivityType]: filterOptions.activityTypes,
          [ColumnIds.productsCategories]: filterOptions.categories,
        }}
      >
        <Table.Filter columnId={ColumnIds.productsStatus} type="multiselect" />
        <Table.Filter columnId={ColumnIds.productsActivityType} type="multiselect" />
        {filterOptions.categories.length > 0 && <Table.Filter columnId={ColumnIds.productsCategories} type="multiselect" />}
      </Table.Filters>

      <Table.Grid>
        <Table.Header />
        <Table.Body>
          {(row) => <Table.Row onClick={(product) => navigate(r(routes.editProduct, { productId: product.id }))} row={row} />}
        </Table.Body>
      </Table.Grid>

      <Table.Pagination showPageSizeOptions />
    </Table.Root>
  );
};
