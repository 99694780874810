import * as stylex from '@stylexjs/stylex';

import { horizontalStyles, verticalStyles } from './Separator.styles';

type Props = {
  orientation?: 'horizontal' | 'vertical';
  // TODO: `Spacing` type with all valid spacing tokens
  spacing?:
    | 'none'
    | 'pixel'
    | 'tiny'
    | 'xxxsmall'
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'normal'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge'
    | 'xxxlarge';
};

export const Separator = ({ orientation = 'horizontal', spacing }: Props) => {
  return (
    <div
      {...stylex.props(
        orientation === 'horizontal'
          ? [horizontalStyles.separator, horizontalStyles[spacing ?? 'none']]
          : [verticalStyles.separator, verticalStyles[spacing ?? 'auto']],
      )}
    />
  );
};
