import { useCustomConditions } from '~/api/conditions';
import { ColumnIds } from '~/constants/table';
import { useTable } from '~/hooks/useTable';

import { useCustomConditionsTableColumns } from './CustomConditionsTable.hooks';

export const CustomConditionsTable = () => {
  const { data: customConditions } = useCustomConditions();

  const columns = useCustomConditionsTableColumns();

  const [table, Table] = useTable({
    data: customConditions,
    columns,
    initialState: {
      sorting: [
        {
          id: ColumnIds.customConditionsName,
          desc: false,
        },
      ],
    },
    enableSortingRemoval: false,
  });

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <Table.Search />
      </Table.Menu>

      <Table.Grid>
        <Table.Header />
        <Table.Body>{(row) => <Table.Row row={row} />}</Table.Body>
      </Table.Grid>

      <Table.Pagination />
    </Table.Root>
  );
};
