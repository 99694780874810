import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdateInvoiceForwardingSettings, useUser } from '~/api/user';
import { Button, Modal } from '~/components';

import type { DeleteAutoForwardingModalProps as Props } from './types';

export const DeleteAutoForwardingModal = ({ invoiceForwardingSetting, onClose }: Props) => {
  const { data: user } = useUser();
  const mutation = useUpdateInvoiceForwardingSettings();

  const { t } = useTranslation(['common', 'settings']);

  const onDelete = useCallback(() => {
    mutation.mutate(
      { entries: user.invoiceForwardingSettings.filter((entry) => entry.id !== invoiceForwardingSetting.id) },
      {
        onSuccess: () => {
          toast.success(t('settings:alerts.integrations.successForwardingEmailUnlinked'));
          onClose();
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  }, [invoiceForwardingSetting.id, mutation, onClose, t, user.invoiceForwardingSettings]);

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:integrations.forwarding.deleteModal.title')}</Modal.Title>

      <p>{t('settings:integrations.forwarding.deleteModal.description', { email: invoiceForwardingSetting.receiverEmail })}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Delete" isLoading={mutation.isPending} onClick={onDelete}>
          {t('settings:integrations.forwarding.deleteModal.confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
