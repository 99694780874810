import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/SVG';

import type { MultiSelectProps as Props } from './types';

import styles from './MultiSelect.module.scss';

export const MultiSelect = <T,>({
  disabled = false,
  id,
  innerRef,
  invalid = false,
  onBlur,
  onChange,
  options,
  placeholder,
  value,
}: Props<T>) => {
  const selectedOptions = options.filter((option) => value.includes(option.value));

  const { t } = useTranslation(['common']);

  return (
    <Listbox disabled={disabled} multiple onChange={onChange} value={value}>
      <ListboxButton
        className={classNames(styles.Button, invalid && styles.Button__Invalid, disabled && styles.Button__Disabled)}
        id={id}
        onBlur={onBlur}
        ref={innerRef}
      >
        {selectedOptions.length > 0 ? (
          <div className={styles.Tags}>
            {selectedOptions.map((option, i) => (
              <div className={styles.Tag} key={i}>
                <span>{option.label}</span>

                <span
                  className={styles.RemoveTag}
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(value.filter((value) => value !== option.value));
                  }}
                >
                  <Icon name="Close" size={10} />
                </span>
              </div>
            ))}
          </div>
        ) : (
          <span className={styles.Placeholder}>{placeholder}</span>
        )}

        <div className={styles.Buttons}>
          <span className={styles.ButtonExpand}>
            <Icon name="KeyboardArrowDown" />
          </span>
        </div>
      </ListboxButton>

      <ListboxOptions anchor={{ to: 'bottom', gap: 4 }} className={styles.Options} modal={false}>
        {options.length > 0 ? (
          options.map((option, i) => (
            <ListboxOption className={styles.Option} disabled={option.disabled} key={i} value={option.value}>
              <span>{option.label}</span>
              <span className={styles.Check}>
                <Icon name="Check" size={20} />
              </span>
            </ListboxOption>
          ))
        ) : (
          <div className={styles.NoOptions}>{t('common:listbox.noOptions')}</div>
        )}
      </ListboxOptions>
    </Listbox>
  );
};
