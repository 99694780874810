import type { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

export const FormControlContext = createContext<{
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
  id: string;
} | null>(null);

export const useFormControlContext = () => {
  const context = useContext(FormControlContext);
  invariant(context, 'Form components must be wrapped in <FormControl />');

  return context;
};
