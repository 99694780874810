import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, lineHeight, spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  label: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: spacing.xxsmall,
    fontSize: fontSize.regular,
    fontWeight: fontWeight.medium,
    lineHeight: lineHeight.tight,
    color: systemColors.textBlack,

    // Unset global styles
    padding: 'unset',
  },

  optional: {
    fontWeight: fontWeight.light,
    color: systemColors.textMuted,
  },
});
