import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { AddHyperlinkModalProps } from '../types';

import { Button } from '../../Button/Button';
import { Modal } from '../../Modal/Modal';

export const AddHyperlinkModal = ({ onClose, onSubmit }: AddHyperlinkModalProps) => {
  const [link, setLink] = useState('');

  const { t } = useTranslation(['common', 'quotations']);

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('quotations:richText.linkModal.title')}</Modal.Title>

      <form className="form-grid modal-form">
        <div className="col-span-2 form-group">
          <label htmlFor="link">{t('quotations:richText.linkModal.label')}</label>
          <input onChange={(e) => setLink(e.target.value)} placeholder={t('quotations:richText.linkModal.placeholder')} value={link} />
        </div>
      </form>

      <Modal.Actions>
        <Button onClick={() => onSubmit(link)}>{t('common:add')}</Button>
      </Modal.Actions>
    </Modal>
  );
};
