import * as stylex from '@stylexjs/stylex';

import { neutralColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  tooltipLink: {
    color: {
      default: neutralColors.white,
      ':hover': neutralColors.gray100,
    },
  },
});
