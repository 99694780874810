import type { ChartData, ChartOptions } from 'chart.js';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { AdvantageSimuationChartData, CalculationDataWithAdvantage } from './types';

/**
 * Get the chart data based on the chart type
 */
export const useChartData = ({
  advantageWithCs,
  advantageWithoutCs,
  isMinimumCommissionApplied,
  userIsWorkingFullTime,
}: CalculationDataWithAdvantage) => {
  const { t } = useTranslation(['invoices']);

  const dataWithCs = useMemo(
    () =>
      [
        {
          id: 'netAmount',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.netAmount.label'),
          description:
            advantageWithCs.advantage > 0
              ? t('invoices:createEdit.advantageSimulationModal.distribution.netAmount.descriptionWithCs')
              : t('invoices:createEdit.advantageSimulationModal.distribution.netAmount.descriptionWithCsNoAdvantage'),
          disclaimer: t('invoices:createEdit.advantageSimulationModal.distribution.netAmount.disclaimerWithCs') || null,
          color: 'bg-primary-500',
          value: advantageWithCs.netAmount,
        },
        {
          id: 'costs',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.costs.label'),
          description: t('invoices:createEdit.advantageSimulationModal.distribution.costs.descriptionWithCs'),
          disclaimer: t('invoices:createEdit.advantageSimulationModal.distribution.costs.disclaimerWithCs') || null,
          color: 'bg-primary-400',
          value: advantageWithCs.fcPerformanceExpenses + advantageWithCs.fcCopyrightExpenses,
        },
        {
          id: 'taxes',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.taxes.label'),
          description: t('invoices:createEdit.advantageSimulationModal.distribution.taxes.descriptionWithCs'),
          disclaimer: userIsWorkingFullTime
            ? t('invoices:createEdit.advantageSimulationModal.distribution.taxes.disclaimerWithCs') || null
            : t('invoices:createEdit.advantageSimulationModal.distribution.taxes.disclaimerWithCsNotMainOccupation') || null,
          color: 'bg-primary-300',
          value: advantageWithCs.fcPerformancePersonalTaxes,
        },
        {
          id: 'socialContribution',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.socialContribution.label'),
          description: t('invoices:createEdit.advantageSimulationModal.distribution.socialContribution.descriptionWithCs'),
          disclaimer: t('invoices:createEdit.advantageSimulationModal.distribution.socialContribution.disclaimerWithCs') || null,
          color: 'bg-primary-200',
          value: advantageWithCs.fcPerformanceSocialContribution,
        },
        {
          id: 'withholdingTaxes',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.withholdingTaxes.label'),
          description: t('invoices:createEdit.advantageSimulationModal.distribution.withholdingTaxes.descriptionWithCs'),
          disclaimer: t('invoices:createEdit.advantageSimulationModal.distribution.withholdingTaxes.disclaimerWithCs') || null,
          color: 'bg-primary-100',
          value: advantageWithCs.fcCopyrightWithholdingTax,
        },
        {
          id: 'commission',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.commission.label'),
          description: isMinimumCommissionApplied
            ? t('invoices:createEdit.advantageSimulationModal.distribution.commission.descriptionWithCsMinimumCommissionApplied')
            : t('invoices:createEdit.advantageSimulationModal.distribution.commission.descriptionWithCs'),
          disclaimer: t('invoices:createEdit.advantageSimulationModal.distribution.commission.disclaimerWithCs') || null,
          color: 'bg-primary-50',
          value: advantageWithCs.coTotalCommission,
        },
      ] satisfies AdvantageSimuationChartData[],
    [
      advantageWithCs.advantage,
      advantageWithCs.coTotalCommission,
      advantageWithCs.fcCopyrightExpenses,
      advantageWithCs.fcCopyrightWithholdingTax,
      advantageWithCs.fcPerformanceExpenses,
      advantageWithCs.fcPerformancePersonalTaxes,
      advantageWithCs.fcPerformanceSocialContribution,
      advantageWithCs.netAmount,
      isMinimumCommissionApplied,
      userIsWorkingFullTime,
      t,
    ],
  );

  const dataWithoutCs = useMemo(
    () =>
      [
        {
          id: 'netAmount',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.netAmount.label'),
          color: 'bg-primary-500',
          description: t('invoices:createEdit.advantageSimulationModal.distribution.netAmount.descriptionWithoutCs'),
          disclaimer: t('invoices:createEdit.advantageSimulationModal.distribution.netAmount.disclaimerWithoutCs') || null,
          value: advantageWithoutCs.netAmount,
        },
        {
          id: 'costs',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.costs.label'),
          description: t('invoices:createEdit.advantageSimulationModal.distribution.costs.descriptionWithoutCs'),
          disclaimer: t('invoices:createEdit.advantageSimulationModal.distribution.costs.disclaimerWithoutCs') || null,
          color: 'bg-primary-400',
          value: advantageWithoutCs.expenses,
        },
        {
          id: 'taxes',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.taxes.label'),
          description: t('invoices:createEdit.advantageSimulationModal.distribution.taxes.descriptionWithoutCs'),
          disclaimer: t('invoices:createEdit.advantageSimulationModal.distribution.taxes.disclaimerWithoutCs') || null,
          color: 'bg-primary-300',
          value: advantageWithoutCs.personalTaxes,
        },
        {
          id: 'socialContribution',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.socialContribution.label'),
          description: t('invoices:createEdit.advantageSimulationModal.distribution.socialContribution.descriptionWithoutCs'),
          disclaimer: t('invoices:createEdit.advantageSimulationModal.distribution.socialContribution.disclaimerWithoutCs') || null,
          color: 'bg-primary-200',
          value: advantageWithoutCs.socialContribution,
        },
        {
          id: 'withholdingTaxes',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.withholdingTaxes.label'),
          description: '',
          disclaimer: null,
          color: 'bg-primary-100',
          value: 0,
        },
        {
          id: 'commission',
          label: t('invoices:createEdit.advantageSimulationModal.distribution.commission.label'),
          description: '',
          disclaimer: null,
          color: 'bg-primary-50',
          value: 0,
        },
      ] satisfies AdvantageSimuationChartData[],
    [advantageWithoutCs.expenses, advantageWithoutCs.netAmount, advantageWithoutCs.personalTaxes, advantageWithoutCs.socialContribution, t],
  );

  return useMemo(() => ({ dataWithCs, dataWithoutCs }), [dataWithCs, dataWithoutCs]);
};

/**
 * Get the chart data and options to pass to the Doughnut chart component
 */
export const useChartConfig = (data: number[]): { data: ChartData<'doughnut', number[]>; options: ChartOptions<'doughnut'> } => {
  const chartColors = useMemo(
    () => [
      getComputedStyle(document.body).getPropertyValue('--color-primary-500'),
      getComputedStyle(document.body).getPropertyValue('--color-primary-400'),
      getComputedStyle(document.body).getPropertyValue('--color-primary-300'),
      getComputedStyle(document.body).getPropertyValue('--color-primary-200'),
      getComputedStyle(document.body).getPropertyValue('--color-primary-100'),
      getComputedStyle(document.body).getPropertyValue('--color-primary-50'),
    ],
    [],
  );

  return useMemo(
    () => ({
      data: {
        datasets: [
          {
            data,
            backgroundColor: chartColors,
            hoverBackgroundColor: chartColors,
            borderColor: 'white',
            hoverBorderColor: 'white',
            hoverOffset: 24,
          },
        ],
      },
      options: {
        events: ['click'],
        animation: { duration: 500 },
        cutout: '40%',
        layout: { padding: 16 },
        plugins: { tooltip: { enabled: false } },
      },
    }),
    [chartColors, data],
  );
};
