import type { Dispatch, SetStateAction } from 'react';

import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

import type { ColumnMetaStyles } from '~/types/declarations/table.d';

export const TableGridContext = createContext<{
  columnStyles: ColumnMetaStyles[];
  resetGridTemplateColumns: () => void;
  setGridTemplateColumns: Dispatch<SetStateAction<string[]>>;
} | null>(null);

export const useTableGridContext = () => {
  const instance = useContext(TableGridContext);

  invariant(instance, 'TableGrid components must be wrapped in <Table.Grid />');

  return instance;
};
