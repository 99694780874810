import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { Brand } from '~/types/user';

import { getAccessToken } from '~/utils/auth';

import type { UpdateBrandPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useUpdateBrand = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateBrandPayload) => {
      const token = getAccessToken();
      const response = await request(`/brands/${id}`, { method: 'PATCH', token, body });

      // TODO: Schema validation
      const { data: brand } = apiResourceSchema(z.unknown()).parse(response);

      return brand as Brand;
    },
    onSuccess: (updatedBrand) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, brands: user.brands.map((brand) => (brand.id === id ? updatedBrand : brand)) };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
