import { useState } from 'react';

import { useCreateClientContext } from '../context';
import { CreateEuropeanOrganisationWithOtherNumber } from './CreateEuropeanOrganisationWithOtherNumber';
import { CreateEuropeanOrganisationWithVatNumber } from './CreateEuropeanOrganisationWithVatNumber';

export const CreateEuropeanOrganisation = () => {
  const { organisation, resetClientContext } = useCreateClientContext();

  const [hasVatNumber, setHasVatNumber] = useState(organisation ? !!organisation.vatNumber : true);

  return hasVatNumber ? (
    <CreateEuropeanOrganisationWithVatNumber
      switchToOtherNumber={() => {
        setHasVatNumber(false);
        resetClientContext();
      }}
    />
  ) : (
    <CreateEuropeanOrganisationWithOtherNumber
      switchToVatNumber={() => {
        setHasVatNumber(true);
        resetClientContext();
      }}
    />
  );
};
