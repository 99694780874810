import * as stylex from '@stylexjs/stylex';

import { spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxsmall,
    isolation: 'isolate',
  },
});
