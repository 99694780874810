import type { Variants } from 'framer-motion';

import { springTransition } from '~/utils/motion';

export const headerVariants: Variants = {
  big: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
    transition: springTransition,
  },
  small: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    transition: springTransition,
  },
};

export const titleVariants: Variants = {
  big: {
    fontSize: '36px',
    lineHeight: '40px',
    transition: springTransition,
  },
  small: {
    fontSize: '28px',
    lineHeight: '36px',
    transition: springTransition,
  },
};
