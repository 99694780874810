import * as stylex from '@stylexjs/stylex';
import { useEffect, useId } from 'react';

import { useAlertContext } from './Alert.context';
import { styles } from './AlertTitle.styles';

type Props = {
  children: string;
};

export const AlertTitle = ({ children }: Props) => {
  const id = `AlertTitle_${useId()}`;
  const { setAriaLabelId } = useAlertContext();

  useEffect(() => {
    setAriaLabelId(id);
  }, [id, setAriaLabelId]);

  return (
    <div {...stylex.props(styles.alertTitle)} id={id}>
      {children}
    </div>
  );
};
