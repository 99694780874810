export const clients = {
  addressType: {
    'establishment unit': "Unité d'établissement",
    headquarters: 'Siège social',
  },
  alerts: {
    errorKboInformation: "Une erreur s'est produite lors de la récupération des informations sur l'entreprise. Réessayez plus tard.",
    errorViesInformation: "Une erreur s'est produite lors de la récupération des informations sur l'entreprise. Réessayez plus tard.",
    successContactCreated: 'Votre contact a été créé avec succès !',
    successContactDeleted: 'Votre contact a été supprimé avec succès !',
    successContactEditedOrganisation: 'Votre contact a été modifié avec succès !',
    successContactEditedPrivate: 'Le client a été modifié avec succès !',
    successCreated: 'Le client a bien été créé !',
    successDeleted: 'Le client a bien été supprimé !',
    successSyncStarted: 'Vos clients ont été synchronisés avec succès à Billit',
    successUpdated: 'Le client a bien été adapté.',
  },
  card: {
    label: 'Client',
  },
  contact: 'Personne de contact',
  create: {
    blocker:
      "Êtes-vous sûr de vouloir quitter la page ? Votre client n'a pas encore été enregistré et toutes les modifications seront perdues.",
    chooseType: {
      organisation: {
        description: 'Ajouter une organisation avec une personne de contact',
        title: 'Organisation',
      },
      private: {
        description: 'Ajouter un client privé ou une association de fait',
        title: 'Particulier',
      },
    },
    company: {
      changeVatNumber: 'Pas ce que vous cherchez ? Saisissez un autre numéro de TVA.',
      companyInformationDisclaimer:
        "Nous avons trouvé l'organisation ci-dessus sur la base du numéro de TVA. Des informations supplémentaires, telles que les coordonnées, l'adresse,... peuvent être ajoutées à l'étape suivante.",
      companyInformationDisclaimerWithoutAddress:
        "Nous avons trouvé l'organisation ci-dessus sur la base de son numéro de TVA. L'adresse du siège social est manquante, merci de la compléter ou de la demander.",
      searchCompanyInformation: 'Rechercher',
      switchToOtherNumber: "Votre client n'a pas de numéro de TVA ? <0>Cliquez ici pour saisir un autre numéro.</0>",
      switchToVatNumber: 'Votre client possède-t-il un numéro de TVA ?<0> Cliquez ici pour effectuer une recherche par numéro de TVA.</0>',
    },
    next: 'Suivant',
    submit: 'Créer un client',
    title: 'Créer un client',
    type: {
      contact: 'Personne de contact',
      organisation: 'Organisation',
    },
  },
  createContact: {
    blocker: "Êtes-vous sûr de vouloir quitter la page ? Votre contact n'a pas encore été enregistré.",
    submit: 'Ajouter un contact',
  },
  createEdit: {
    subsections: {
      comments: {
        description:
          'Ici, vous pouvez prendre des notes à propos de ce client. Par exemple, un délai de paiement différent, des accords particuliers, ...',
        title: 'Notes',
      },
      contactAddress: 'Adresse',
      customAgreement: {
        description:
          'Avez-vous un contrat-cadre existant avec votre client ? Vous pouvez l\'indiquer ici. Plus infos ? <a href="https://knowledge.creativeshelter.be/opdrachtgevers-en-overeenkomsten-wij-zetten-je-op-weg" target="_blank"> Lisez tout à ce sujet dans notre base de connaissances.</a>',
        title: 'Contrat-cadre',
      },
      emails: {
        description:
          "Indiquez ici à qui nous envoyons quels emails. Si vous choisissez d'envoyer vous-même (certains) emails, vous les recevrez vous-même avec les fichiers nécessaires afin de pouvoir les transmettre à votre client.",
        title: 'E-mails',
      },
      personalInfo: 'Données personnelles',
    },
  },
  customAgreementModal: {
    cancel: 'Non, retour',
    description:
      "Confirmez uniquement si vous avez déjà un contrat signé avec ce client. Un email suivra pour analyser votre contrat. Pourquoi ? Parce que nous devons être repris dans le contrat en tant que tierce partie. Plus d'info ? Lire <a href='https://knowledge-creativeshelter-be.translate.goog/opdrachtgevers-en-overeenkomsten-wij-zetten-je-op-weg?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp' target='_blank'>ici</a>.",
    submit: "Oui, j'ai un contrat signé",
    title: 'Attention !',
  },
  detail: {
    contacts: {
      add: 'Ajouter un contact',
      columns: {
        email: 'Adresse email',
        name: 'Nom',
        phoneNumber: 'Numéro de téléphone',
      },
      delete: {
        description: 'Êtes-vous sûr de vouloir supprimer votre contact {{name}} ?',
        submit: 'Supprimer le contact',
        title: 'Supprimer le contact',
      },
      moreEmails: "Et {{amount}} d'autres",
      rowActions: {
        delete: 'Supprimer',
        duplicate: 'Copier',
        edit: 'Éditer',
      },
      selfEmail: '{{email}}(vous-même)',
      title: 'Contacts',
    },
  },
  editClient: {
    blocker: "Êtes-vous sûr de vouloir quitter la page ? Les modifications apportées à votre client n'ont pas été enregistrées.",
    submit: 'Éditer',
  },
  editContact: {
    blocker: "Êtes-vous sûr de vouloir quitter la page ? Les modifications apportées à votre contact n'ont pas été enregistrées.",
    submit: 'Éditer',
  },
  fields: {
    address: {
      label: 'Adresse',
    },
    city: {
      label: 'Municipalité',
      placeholder: 'Ville ou municipalité',
    },
    clientType: {
      label: 'Type de client',
      options: {
        '1': 'Entreprise',
        '2': 'Particulier',
      },
    },
    companyName: {
      label: "Nom de l'entreprise",
    },
    countryCode: {
      label: 'Pays',
      placeholder: 'Choisissez un pays...',
    },
    customAgreement: {
      label: "J'ai déjà un contrat existant avec ce client.",
      small:
        "<strong>Attention !</strong> Que faire si mon client me propose un contrat ? Lisez <a href='https://knowledge-creativeshelter-be.translate.goog/opdrachtgevers-en-overeenkomsten-wij-zetten-je-op-weg?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp' target='_blank'>ici</a> ce que vous devez faire.",
      tooltip: "Ceci n'inclut pas les accords tels qu'une offre de prix, une confirmation par téléphone et/ou par e-mail.",
    },
    emailAddresses: {
      addReceiver: 'Ajouter un destinataire supplémentaire',
      addSelf: 'Envoyer vous-même',
      invoicesAndCreditNotes: 'Factures et notes de crédit',
      label: 'Adresse email',
      paymentReminders: 'Rappels de paiement',
      quotations: 'Offres',
    },
    enterpriseNumber: {
      label: "Numéro de TVA ou numéro d'entreprise",
    },
    firstName: {
      label: 'Prénom',
      placeholder: 'Prénom',
    },
    function: {
      label: 'Fonction',
      placeholder: 'Fonction',
    },
    lang: {
      label: 'Langue',
    },
    lastName: {
      label: 'Nom de famille',
      placeholder: 'Nom de famille',
    },
    number: {
      label: "Numéro d'habitation",
      placeholder: 'Numéro',
    },
    organisationName: {
      label: "Nom de l'organisation",
      tooltip: "Votre client est-il une association de fait ? Entrez ici le nom de l'association.",
    },
    otherNumber: {
      label: 'Autre numéro',
      placeholder: 'Autre numéro',
    },
    street: {
      label: 'Rue',
      placeholder: 'Rue',
    },
    telephone: {
      label: 'Numéro de téléphone',
      placeholder: 'Numéro de téléphone',
    },
    useOrganisationAddress: {
      label: "Utilisez l'adresse de l'organisation",
    },
    userComments: {
      label: 'Notes',
      placeholder: 'Commentaires à vous-même ou à Creative Shelter.',
    },
    vatNumber: {
      label: 'Numéro TVA',
    },
    vatOrOtherNumber: {
      label: 'Numéro de TVA ou autre type de numéro',
    },
    zipcode: {
      label: 'Code postal',
      placeholder: 'Code postal',
    },
  },
  header: {
    actionBlock: {
      button: 'Créer un client',
      buttonMobile: 'Nouveau client',
      dropdown: {
        billit: 'Synchronisez vos clients (Billit)',
      },
    },
    description: 'Ajouter des clients ou modifier des clients existants.',
    title: 'Clients',
  },
  kboOrganisationTooltip:
    'Vous ne pouvez pas modifier ce client, car les données ont été récupérées directement de la BCE. Les données que vous voyez ici ont été choisies par votre client.',
  overview: {
    columns: {
      contacts: 'Contacts',
      email: 'Adresse e-mail',
      name: 'Nom',
      type: 'Type de client',
      vat: 'Numéro de TVA',
      vatOrOtherNumber: 'Numéro de TVA ou autre type de numéro',
    },
    delete: {
      description: 'Êtes-vous sûr de vouloir supprimer le client ({{name}}) ?',
      submit: 'Supprimer le client',
      title: 'Supprimer le client',
    },
    moreContacts: "Et {{amount}} d'autres",
    rowActions: {
      addContact: 'Ajouter un contact',
      delete: 'Supprimer',
      edit: 'Éditer',
      view: 'Consulter',
    },
    subtitle: 'Vos clients',
  },
  type: {
    organisation: 'Organisation',
    private: 'Particulier',
  },
};
