import classNames from 'classnames';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimateExpand, IconTooltip } from '~/components';
import { Icon } from '~/components/SVG';
import { useIntl } from '~/hooks/useIntl';

import type { CoPaymentInfoCardProps as Props } from './types';

import styles from './PaymentInfoCard.module.scss';

export const FcPaymentInfoCard = ({ invoice }: Props) => {
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation(['invoices', 'lines']);

  const { formatCurrency, formatDate } = useIntl();

  const performanceSubtotal = invoice.calculationData.fcPerformanceExclVat;
  const vat6 = invoice.calculationData.fcVatAmounts[0].amount;
  const vat21 = invoice.calculationData.fcVatAmounts[1].amount;
  const performanceTotal = performanceSubtotal + vat6 + vat21;
  const copyrightSubtotal = invoice.calculationData.fcCopyrightExclVat;
  const withholdingTax = invoice.calculationData.fcWithholdingTax;
  const copyrightTotal = copyrightSubtotal - withholdingTax;

  return (
    <>
      <p className={styles.Title}>{t('invoices:details.paymentInfo.subtitleFc')}</p>
      <p className={styles.Subtitle}>{t('invoices:details.paymentInfo.forInvoice', { entryNumber: invoice.entryNumber })}</p>

      <article className={styles.Wrapper}>
        <header className={styles.Total}>
          <div className={styles.Line}>
            <p className={styles.Label}>{t('invoices:details.paymentInfo.totalPayment1')}</p>
            <p className={styles.Value}>{formatCurrency(performanceTotal)}</p>
          </div>

          <div className={styles.Line}>
            <p className={styles.Label}>{t('invoices:details.paymentInfo.totalPayment2')}</p>
            <p className={styles.Value}>{formatCurrency(copyrightTotal)}</p>
          </div>
        </header>

        <div className={styles.ToggleWrapper}>
          <button className={classNames(styles.Toggle, showDetails && styles.Expanded)} onClick={() => setShowDetails((prev) => !prev)}>
            {t('invoices:details.paymentInfo.details')}
            <Icon name="KeyboardArrowDown" size={18} />
          </button>
        </div>

        <AnimateExpand>
          {showDetails && (
            <div className={styles.Details}>
              <div className={styles.DetailsRow}>
                <span>{t('invoices:details.paymentInfo.performance')}</span>
                <span>{formatCurrency(performanceSubtotal)}</span>
              </div>
              <div className={styles.DetailsRow}>
                <span>{t('invoices:details.paymentInfo.vatCopyright')}</span>
                <span>{formatCurrency(vat6)}</span>
              </div>
              <div className={styles.DetailsRow}>
                <span>{t('invoices:details.paymentInfo.vatPerformance')}</span>
                <span>{formatCurrency(vat21)}</span>
              </div>
              {vat6 > 0 && vat21 > 0 && (
                <div className={styles.DetailsRow}>
                  <span>{t('invoices:details.paymentInfo.vatTotal')}</span>
                  <span>{formatCurrency(vat6 + vat21)}</span>
                </div>
              )}
              <div className={styles.DetailsRow}>
                <span>{t('invoices:details.paymentInfo.totalPayment1')}</span>
                <span>{formatCurrency(performanceTotal)}</span>
              </div>

              <hr className={styles.Divider} />

              <div className={styles.DetailsRow}>
                <span>{t('invoices:details.paymentInfo.copyright')}</span>
                <span>{formatCurrency(copyrightSubtotal)}</span>
              </div>
              <div className={styles.DetailsRow}>
                <span>{t('invoices:details.paymentInfo.withholdingTax')}</span>
                <span>{formatCurrency(withholdingTax * -1)}</span>
              </div>
              <div className={styles.DetailsRow}>
                <span>{t('invoices:details.paymentInfo.totalPayment2')}</span>
                <span>{formatCurrency(copyrightTotal)}</span>
              </div>
            </div>
          )}
        </AnimateExpand>

        {invoice.creditNoteDate ? (
          <div className={styles.LineCredited}>{t('invoices:details.paymentInfo.credited')}</div>
        ) : (
          <>
            <div className={styles.Line}>
              <p className={styles.Label}>{t('invoices:details.paymentInfo.status.label')}</p>
              <p className={classNames(styles.Status, invoice.febelfinDate ? styles.Paid : styles.NotPaid)}>
                {invoice.febelfinDate
                  ? t('invoices:details.paymentInfo.status.options.fcPaid')
                  : t('invoices:details.paymentInfo.status.options.notPaid')}
              </p>
            </div>

            <div className={styles.Line}>
              <p className={styles.Label}>{t('invoices:details.paymentInfo.dueDate')}</p>
              <p className={styles.Value}>{formatDate(invoice.paymentDueDate)}</p>
            </div>

            <div className={styles.Line}>
              {invoice.febelfinDate ? (
                <>
                  <p className={styles.Label}>{t('invoices:details.paymentInfo.paidDate')}</p>
                  <p className={styles.Value}>{formatDate(invoice.febelfinDate)}</p>
                </>
              ) : (
                <>
                  <p className={styles.Label}>
                    <span className={styles.LabelWithTooltip}>
                      {t('invoices:details.paymentInfo.expectedPaidDate')}
                      <IconTooltip size={18} text={t('invoices:details.paymentInfo.expectedPaidDateTooltip')} />
                    </span>
                  </p>
                  <p className={styles.Value}>{invoice.paid ? formatDate(dayjs(invoice.paidDate).nextBusinessDay().toDate()) : '–'}</p>
                </>
              )}
            </div>
          </>
        )}
      </article>
    </>
  );
};
