import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateNps, useUpdateNps } from '~/api/nps';
import { useUser } from '~/api/user';

import { Button } from '../../Button/Button';
import styles from './Nps.module.scss';
import { ScoreRow } from './ScoreRow/ScoreRow';

export const Nps = () => {
  const { refetch: refetchUser } = useUser();
  const createNpsMutation = useCreateNps();
  const updateNpsMutation = useUpdateNps();

  const [currentStep, setCurrentStep] = useState<'score' | 'feedback' | 'feedbackReceived'>('score');
  const [npsId, setNpsId] = useState<number | null>(null);

  const { t } = useTranslation(['settings']);

  const {
    formState: { isDirty },
    handleSubmit,
    register,
  } = useForm<{ feedback: string }>({
    defaultValues: {
      feedback: '',
    },
  });

  const saveNps = (score: number) => {
    createNpsMutation.mutate(
      { score, closed: false },
      {
        onSuccess: ({ id }) => {
          setNpsId(id);
          setCurrentStep('feedback');
        },
      },
    );
  };

  const closeNps = () => {
    createNpsMutation.mutate({ closed: true }); // Create a new nps record instead of updating the original ¯\_(ツ)_/¯
  };

  const saveFeedback = handleSubmit((data) => {
    if (!npsId) return;

    updateNpsMutation.mutate(
      { id: npsId, reason: data.feedback },
      {
        onSuccess: () => {
          setCurrentStep('feedbackReceived');
          setTimeout(() => refetchUser(), 5_000); // Refetch to update the showNps flag, which closes this popup
        },
      },
    );
  });

  return (
    <aside className={styles.NpsWrapper}>
      <div className={styles.Nps}>
        <Button extraClasses={styles.CloseButton} icon="Close" onClick={closeNps} type="iconOnly" />

        <div className={styles.Steps}>
          {/* Step 1: Form to fill in score */}
          {currentStep === 'score' && (
            <>
              <h5>{t('settings:nps.titleStepOne')}</h5>
              <ScoreRow onClick={(score) => saveNps(score)} />
            </>
          )}

          {/* Step 2: Form to fill in feedback message */}
          {currentStep === 'feedback' && (
            <>
              <h5>{t('settings:nps.titleStepTwo')}</h5>

              <form onSubmit={saveFeedback}>
                <div className="form-group">
                  <textarea className="mb-2" {...register('feedback', { required: true })} rows={2} />
                  <Button disabled={!isDirty} isSubmit>
                    {t('settings:nps.sendFeedback')}
                  </Button>
                </div>
              </form>
            </>
          )}

          {/* Step 3: Thank you for your feedback */}
          {currentStep === 'feedbackReceived' && <h4>{t('settings:nps.titleStepThree')}</h4>}
        </div>
      </div>
    </aside>
  );
};
