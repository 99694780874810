import type { NumberingSeries } from '~/types/user';

import { NumberingResetInterval, NumberingYearMonthFormat } from '~/types/user';
import { generateEntryNumberPrefix, generateEntryNumberSuffix } from '~/utils/string';

import { NumberingSeriesType } from './types';

export const DUPLICATE_FORMAT_ERROR_TOAST_ID = 'duplicate-format';

/**
 * Get the numbering series type based on the enablement of invoices and credit notes
 */
export const getNumberingSeriesType = ({ enabledForCreditNotes, enabledForInvoices }: NumberingSeries): NumberingSeriesType => {
  if (enabledForInvoices && enabledForCreditNotes) return NumberingSeriesType.Both;
  if (enabledForInvoices) return NumberingSeriesType.Invoices;
  if (enabledForCreditNotes) return NumberingSeriesType.CreditNotes;

  throw new Error("Unexpected numbering series type: numbering series can't be disabled for both invoices and credit notes.");
};

/**
 * Get the numbering series enabledFor* properties on the type
 */
export const getNumberingSeriesEnabledFor = (
  type: NumberingSeriesType,
): Pick<NumberingSeries, 'enabledForInvoices' | 'enabledForCreditNotes'> => {
  const enabledForInvoices = [NumberingSeriesType.Invoices, NumberingSeriesType.Both].includes(type);
  const enabledForCreditNotes = [NumberingSeriesType.CreditNotes, NumberingSeriesType.Both].includes(type);

  return { enabledForInvoices, enabledForCreditNotes };
};

/**
 * Check if a new numbering series format already exists
 * Note that there is no check on the digits, as this does not contribute to the uniqueness
 */
export const isDuplicateNumberingSeriesFormat = (
  numberingSeries: Pick<NumberingSeries, 'id' | 'format'>,
  allNumberingSeries: Pick<NumberingSeries, 'id' | 'format'>[],
): boolean =>
  allNumberingSeries
    .filter((series) => numberingSeries.id !== series.id)
    .some(
      (series) =>
        generateEntryNumberPrefix(numberingSeries.format).toLowerCase() === generateEntryNumberPrefix(series.format).toLowerCase() &&
        generateEntryNumberSuffix(numberingSeries.format).toLowerCase() === generateEntryNumberSuffix(series.format).toLowerCase(),
    );

/**
 * Default values when creating a new numbering series
 **/
type DefaultNumberingSeriesOptions = Pick<NumberingSeries, 'nextNumber' | 'format' | 'resetInterval'>;

const defaultNumberingSeriesOptions = {
  nextNumber: 1,
  format: {
    amountOfDigits: 4,
    prefixText: 'CS_',
    prefixYearMonth: NumberingYearMonthFormat.FullYear,
    prefixSeparator: '_',
    suffixText: null,
    suffixYearMonth: null,
    suffixSeparator: null,
  },
  resetInterval: NumberingResetInterval.Yearly,
} satisfies DefaultNumberingSeriesOptions;

/**
 * Get default options for a new numbering series
 * If a series with these default values already exists, add an
 * incrementing index to the prefix text (CS_, CS_2_, CS_3_, ...)
 */
export const getDefaultNumberingSeriesOptions = (numberingSeries: NumberingSeries[]): DefaultNumberingSeriesOptions => {
  const defaultFormat = defaultNumberingSeriesOptions.format;

  const lastDefaultFormatIndex = Math.max(
    ...numberingSeries
      // Get all numbering series that use the default format
      .filter(
        ({ format }) =>
          format.prefixText &&
          /CS_(?:\d+_)?/.test(format.prefixText) &&
          format.prefixYearMonth === defaultFormat.prefixYearMonth &&
          format.prefixSeparator === defaultFormat.prefixSeparator &&
          format.suffixText === defaultFormat.suffixText &&
          format.suffixYearMonth === defaultFormat.suffixYearMonth &&
          format.suffixSeparator === defaultFormat.suffixSeparator,
      )
      // Map the numbering series to the index in the prefix text (or 1 if no index)
      .map(({ format }) => {
        const match = format.prefixText?.match(/CS_(?<seriesIndex>\d+)?_?/);
        return +(match?.groups?.seriesIndex ?? 1);
      }),
    0,
  );

  if (lastDefaultFormatIndex === 0) return defaultNumberingSeriesOptions;

  return {
    ...defaultNumberingSeriesOptions,
    format: {
      ...defaultNumberingSeriesOptions.format,
      prefixText: `${defaultNumberingSeriesOptions.format.prefixText}${lastDefaultFormatIndex + 1}_`,
    },
  };
};
