import type { ComponentProps } from 'react';

import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { Trans } from 'react-i18next';
import { ZodError } from 'zod';

import { clientsQueryOptions } from '~/api/clients';
import { conditionsQueryOptions, customConditionsQueryOptions } from '~/api/conditions';
import { creditNotesQueryOptions } from '~/api/creditNotes';
import { invoicesQueryOptions } from '~/api/invoices';
import { plansQueryOptions } from '~/api/plans';
import { productsQueryOptions } from '~/api/products';
import { quotationsQueryOptions } from '~/api/quotations';
import { userQueryOptions } from '~/api/user';
import { env } from '~/constants/env';
import { FormattedZodError } from '~/constants/errors';
import { CommandMenuItem } from '~/providers/CommandMenuProvider/CommandMenuItem';
import { removeAccessToken, removeLoginType } from '~/utils/auth';

import styles from './GlobalErrorBoundary.module.scss';

const suspenseQueries = [
  userQueryOptions,
  quotationsQueryOptions,
  invoicesQueryOptions,
  creditNotesQueryOptions,
  productsQueryOptions,
  clientsQueryOptions,
  conditionsQueryOptions,
  customConditionsQueryOptions,
  plansQueryOptions,
] as const;

const ErrorBoundary = () => {
  const queryClient = useQueryClient();

  const isQueryError = suspenseQueries.some((queryOptions) => queryClient.getQueryState(queryOptions.queryKey)?.status === 'error');

  return (
    <div className={styles.container}>
      <h1 className={styles.error}>
        <Trans components={{ a: <a />, small: <small /> }} i18nKey={isQueryError ? 'common:loadingError' : 'common:error'} />
      </h1>

      {env.IS_LOCAL_DEV_ENV &&
        suspenseQueries
          .map((queryOptions) => [queryOptions.queryKey, queryClient.getQueryState(queryOptions.queryKey)?.error] as const)
          .filter(([, error]) => !!error)
          .map(([queryKey, error], i) => (
            <div className="mt-6" key={i}>
              <p className="text-error-500 font-semibold">Query key: {JSON.stringify(queryKey)}</p>
              <pre className="mt-2 p-4 text-error-500 bg-error-100 text-sm font-medium overflow-auto">
                <code>{error instanceof ZodError ? FormattedZodError.stringifyIssues(error.issues) : JSON.stringify(error, null, 2)}</code>
              </pre>
            </div>
          ))}

      <CommandMenuItem
        group="Error boundary"
        label="Force log out"
        onClick={() => {
          removeAccessToken();
          removeLoginType();
          window.location.reload();
        }}
      />
    </div>
  );
};

export const GlobalErrorBoundary = (delegated: Omit<ComponentProps<typeof SentryErrorBoundary>, 'fallback'>) => (
  <SentryErrorBoundary {...delegated} fallback={<ErrorBoundary />} />
);
