import * as stylex from '@stylexjs/stylex';

import type { MediaQueryTinyMobileAndBelow } from '~/styles/mediaQueries';

import { lineHeight, radius, spacing, systemColors } from '~/styles/tokens.stylex';

const TinyMobileAndBelow: MediaQueryTinyMobileAndBelow = '@media (width < 400px)';

export const styles = stylex.create({
  card: {
    padding: spacing.normal,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: systemColors.borderLight,
    borderRadius: radius.xxsmall,
  },

  header: {
    display: 'flex',
    gap: spacing.xsmall,
    alignItems: 'center',
  },

  noInvitations: {
    color: systemColors.textMuted,
    fontStyle: 'italic',
  },

  invitations: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.normal,
  },

  invitation: {
    display: 'grid',
    columnGap: spacing.xxsmall,
    alignItems: 'baseline',
    gridTemplateColumns: {
      default: '1fr auto',
      [TinyMobileAndBelow]: '1fr',
    },
    gridTemplateAreas: {
      default: `
        "name status"
        "email date"
      `,
      [TinyMobileAndBelow]: `
        "name"
        "email"
        "status"
        "date"
      `,
    },
  },

  name: {
    gridArea: 'name',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  status: {
    gridArea: 'status',
    textAlign: {
      default: 'right',
      [TinyMobileAndBelow]: 'unset',
    },
    color: systemColors.textMuted,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  emailAddress: {
    gridArea: 'email',
    color: systemColors.textMuted,
    lineHeight: lineHeight.tight,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  statusDate: {
    gridArea: 'date',
    lineHeight: lineHeight.tight,
    textAlign: {
      default: 'right',
      [TinyMobileAndBelow]: 'unset',
    },
    color: systemColors.textMuted,
  },
});
