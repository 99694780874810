import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useDeleteBrand = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request(`/brands/${id}`, { method: 'DELETE', token });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
