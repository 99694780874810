import classNames from 'classnames';

import { Icon } from '~/components/SVG';

import type { StatProps as Props } from './types';

import styles from './StatBlock.module.scss';

export const StatBlock = ({ icon, title, total }: Props) => {
  return (
    <div className={classNames(styles['stat-block'])}>
      <span className={styles.title}>{title}</span>

      <div className={styles.stat}>
        <div className={styles['icon-wrapper']}>
          <Icon name={icon} />
        </div>
        <span className={styles.total}>{total}</span>
      </div>
    </div>
  );
};
