import type { FormEvent } from 'react';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/SVG';

import type { UploadInputProps as Props } from './types';

import { FormError } from '../FormError/FormError';
import { Spinner } from '../Spinner/Spinner';

export const UploadInput = ({ fileTypes, isLoading = false, maxFileSizeInMegaByte, onChange, smallText }: Props) => {
  const [fileSizeIsTooBig, setFileSizeIsTooBig] = useState(false);
  const { t } = useTranslation(['common', 'validation']);

  /**
   * Handle upload input onChange event
   * Checks if the file size is not too big
   */
  const onUploadInputChange = (e: FormEvent<HTMLInputElement>) => {
    setFileSizeIsTooBig(false);

    if (!maxFileSizeInMegaByte) {
      return onChange(e);
    }

    const file = e.currentTarget.files?.item(0);

    if (file) {
      const fileSizeInMegaByte = file.size / 1024 / 1024;

      if (fileSizeInMegaByte <= maxFileSizeInMegaByte) {
        onChange(e);
      } else {
        setFileSizeIsTooBig(true);
      }
    }
  };

  return (
    <>
      <label className="upload-input">
        <input accept={fileTypes} onChange={onUploadInputChange} type="file" />
        {isLoading ? <Spinner size={24} /> : <Icon name="Upload" />}
        <span className="upload-input-text">{t('common:placeholders.file')}</span>
      </label>
      {fileSizeIsTooBig && <FormError>{t('validation:maxFileSize', { max: maxFileSizeInMegaByte })}</FormError>}
      {smallText && <small>{smallText}</small>}
    </>
  );
};
