import type { ReactNode } from 'react';

import { useMemo, useState } from 'react';

import { MaintenanceContext } from './MaintenanceProvider.context';
import { MaintenanceScreen } from './MaintenanceScreen';

type Props = {
  children: ReactNode;
};

export const MaintenanceProvider = ({ children }: Props) => {
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);

  const context = useMemo(() => ({ isMaintenanceMode, setIsMaintenanceMode }), [isMaintenanceMode]);

  return <MaintenanceContext.Provider value={context}>{isMaintenanceMode ? <MaintenanceScreen /> : children}</MaintenanceContext.Provider>;
};
