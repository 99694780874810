import { ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Combobox as HUI_Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/SVG';

import type { ComboboxValue, ComboboxProps as Props } from './types';

import styles from './Combobox.module.scss';

const includesString = (haystack: string, needle: string) =>
  haystack.toLowerCase().replace(/\s+/g, '').includes(needle.toLowerCase().replace(/\s+/g, ''));

export const Combobox = <T extends ComboboxValue = ComboboxValue>({
  disabled = false,
  Footer,
  id,
  innerRef,
  invalid = false,
  onBlur,
  onChange,
  options,
  placeholder,
  transparent = false,
  value,
}: Props<T>) => {
  const [query, setQuery] = useState('');

  const filteredOptions = useMemo(() => {
    if (query === '') return options;

    return options.filter((option) => {
      const filterValue = option.filterValue ?? option.label;
      return Array.isArray(filterValue) ? filterValue.some((value) => includesString(value, query)) : includesString(filterValue, query);
    });
  }, [options, query]);

  const { t } = useTranslation(['common']);

  return (
    <HUI_Combobox
      as="div"
      className={styles.Wrapper}
      disabled={disabled}
      immediate
      onChange={(value) => onChange(value)}
      onClose={() => setQuery('')}
      value={value}
    >
      <ComboboxInput
        className={classNames(styles.Input, invalid && styles.Input__Invalid, transparent && styles.Input__Transparent)}
        displayValue={(value) => options.find((option) => option.value === value)?.label ?? ''}
        id={id}
        onBlur={onBlur}
        onChange={(event) => setQuery(event.target.value)}
        placeholder={placeholder}
        ref={innerRef}
      />

      <div className={styles.Buttons}>
        {value !== null && (
          <button
            className={styles.ButtonClear}
            onClick={(e) => {
              e.stopPropagation();
              setQuery('');
              onChange(null);
            }}
            tabIndex={-1}
            type="button"
          >
            <Icon name="Close" />
          </button>
        )}
        <ComboboxButton className={styles.ButtonUnfold}>
          <Icon name="KeyboardArrowDown" />
        </ComboboxButton>
      </div>

      <ComboboxOptions anchor={{ to: 'bottom', gap: 4, padding: 40 }} className={styles.Options} modal={false}>
        {options.length === 0 ? (
          <div className={styles.NoOptions}>{t('common:listbox.noOptions')}</div>
        ) : filteredOptions.length === 0 && query !== '' ? (
          <div className={styles.NoResults}>{t('common:listbox.noResults')}</div>
        ) : (
          filteredOptions.slice(0, 100).map((option) => (
            <ComboboxOption className={styles.Option} disabled={option.disabled} key={option.value} value={option.value}>
              {option.label}
            </ComboboxOption>
          ))
        )}

        {Footer && <div className={styles.Footer}>{Footer}</div>}
      </ComboboxOptions>
    </HUI_Combobox>
  );
};
