import * as stylex from '@stylexjs/stylex';
import { Trans, useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { ExternalLink, Link, Text } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

import { styles } from './AuthLink.styles';

type Props = {
  to: 'registration' | 'login';
};

export const AuthLink = ({ to }: Props) => {
  const { t } = useTranslation(['auth']);

  return (
    <div {...stylex.props(styles.authLink)}>
      {/* Only show accountant login link on login page */}
      {to === 'registration' && (
        <Text>
          {t('auth:loginPage.accountantLoginCta.message')}{' '}
          <Trans components={{ accountantLink: <ExternalLink /> }} i18nKey="auth:loginPage.accountantLoginCta.action" />
        </Text>
      )}

      <Text>
        {match(to)
          .with('login', () => t('auth:registrationPage.common.loginCta.message'))
          .with('registration', () => t('auth:loginPage.registerCta.message'))
          .exhaustive()}{' '}
        <Link to={to === 'registration' ? routes.register : routes.login}>
          {match(to)
            .with('login', () => t('auth:registrationPage.common.loginCta.action'))
            .with('registration', () => t('auth:loginPage.registerCta.action'))
            .exhaustive()}
        </Link>
      </Text>
    </div>
  );
};
