import type { HTMLAttributes } from 'react';

import * as stylex from '@stylexjs/stylex';

import type { WithoutStyleProps } from '~/components/types';

import { styles } from './Title.styles';

type Props = WithoutStyleProps<HTMLAttributes<HTMLHeadingElement>> & {
  as?: `h${1 | 2 | 3 | 4 | 5 | 6}`;
  children: string;
  styles?: stylex.StyleXStyles;
};

export const Title = ({ as = 'h1', children, styles: styleOverrides, ...delegated }: Props) => {
  const Element = as;

  return (
    <Element {...stylex.props(styles.base, styles[as], styleOverrides)} {...delegated}>
      {children}
    </Element>
  );
};
