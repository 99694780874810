import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { download } from '../request';

export const useDownloadDocument = () => {
  return useMutation({
    mutationFn: (id: number) => {
      const token = getAccessToken();
      return download(`/documents/${id}`, { method: 'GET', token });
    },
  });
};
