/**
 * Exports the given data to an excel with the given heading and filename
 */
export const exportToExcel = async (data: Record<string, unknown>[], heading: string[], filename: string) => {
  const { utils, writeFile } = await import('./xlsx');

  // Create the workbook & sheet
  const wb = utils.book_new();
  const ws = utils.json_to_sheet([]);

  // Add heading to sheet
  utils.sheet_add_aoa(ws, [heading]);

  // Add data to sheet
  utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });

  // Add sheet to workbook
  utils.book_append_sheet(wb, ws, 'Sheet1');

  // Download workbook
  writeFile(wb, filename);
};
