import { useEffect } from 'react';

const showBlocker = (e: BeforeUnloadEvent) => {
  e.preventDefault();
  e.returnValue = '';
};

export const useBrowserBlocker = (isBlocked: boolean) => {
  useEffect(() => {
    if (!isBlocked) return;

    window.addEventListener('beforeunload', showBlocker);

    return () => window.removeEventListener('beforeunload', showBlocker);
  }, [isBlocked]);
};
