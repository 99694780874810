import type { InputFieldProps as Props } from './types';

import styles from '../CreateCustomConditionModal/CreateCustomConditionModal.module.scss';

export const InputFieldCheckbox = ({ checked, children, onChange, onClick, step, value }: Props) => {
  return (
    <div className={styles['radio-field']}>
      <input checked={checked} id={`${step}-${value}`} name={step} onChange={onChange} onClick={onClick} type="checkbox" value={value} />
      <label htmlFor={`${step}-${value}`}>{children}</label>
    </div>
  );
};
