// Old name: ExpandLess
import type { SVGProps } from 'react';

export const KeyboardArrowUp = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
      <path d="M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z" />
    </svg>
  );
};
