import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { Product } from '~/api/products/types';

import { DropdownMenu } from '~/components';
import { SearchParamKeys } from '~/constants/url';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { qs } from '~/utils/searchParams';

export const ProductsTableOptions = ({ product }: { product: Product }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  return (
    <DropdownMenu
      dropdownClass="text-sm"
      items={[
        {
          text: t('common:datatable.edit'),
          iconName: 'Edit',
          onClick: () =>
            navigate({
              pathname: r(routes.editProduct, { productId: product.id }),
              search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }),
            }),
        },
      ]}
    />
  );
};
