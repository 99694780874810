import type { UseFormReturn } from 'react-hook-form';

import classNames from 'classnames';
import { Fragment, useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { Button, CollapsibleSection, ErrorMessage, FormControl, FormErrorMessage, FormField, FormTextInput, Label } from '~/components';
import { EMAIL } from '~/constants/regex';
import { AnchorTargets } from '~/constants/url';

import type { ContactFormType } from '../types';

type ContactEmailsFormType = Pick<ContactFormType, 'emails'>;

export const ContactEmailsFormSection = <T extends ContactEmailsFormType>({
  form,
}: {
  form: T extends ContactEmailsFormType ? UseFormReturn<T> : never;
}) => {
  const { data: user } = useUser();
  const { t } = useTranslation(['clients', 'validation']);

  const {
    control,
    formState: { errors, isSubmitted },
    getValues,
    setValue,
    trigger,
    watch,
  } = form;

  const { append, fields, prepend, remove, update } = useFieldArray({
    control,
    name: 'emails',
    rules: {
      validate: {
        // Each mail type should be selected at least once
        eachTypeSelected: (emails) =>
          emails.some((email) => email.enableForQuotations) &&
          emails.some((email) => email.enableForInvoicesAndCreditNotes) &&
          emails.some((email) => email.enableForPaymentReminders),
        // At least one mail type should be selected for each email address
        noEmptySelection: (emails) =>
          !emails.some((email) => !email.enableForQuotations && !email.enableForInvoicesAndCreditNotes && !email.enableForPaymentReminders),
      },
    },
  });

  const addClientEmail = useCallback(() => {
    const userEmails = getValues('emails').filter(({ type }) => type === 'user');
    const quotationsEnabledForUser = userEmails.some((email) => email.enableForQuotations);
    const invoicesAndCreditNotesEnabledForUser = userEmails.some((email) => email.enableForInvoicesAndCreditNotes);
    const paymentRemindersEnabledForUser = userEmails.some((email) => email.enableForPaymentReminders);

    append({
      type: 'client',
      emailAddress: '',
      enableForQuotations: !quotationsEnabledForUser,
      enableForInvoicesAndCreditNotes: !invoicesAndCreditNotesEnabledForUser,
      enableForPaymentReminders: !paymentRemindersEnabledForUser,
    });

    if (isSubmitted) trigger('emails');
  }, [append, getValues, isSubmitted, trigger]);

  const addUserEmail = useCallback(() => {
    if (getValues('emails').some(({ type }) => type === 'user')) return;

    const isEmpty =
      getValues('emails').length === 1 &&
      getValues('emails.0').emailAddress === '' &&
      getValues('emails.0').enableForQuotations &&
      getValues('emails.0').enableForInvoicesAndCreditNotes &&
      getValues('emails.0').enableForPaymentReminders;

    if (isEmpty) remove(0);

    const clientEmails = isEmpty ? [] : getValues('emails').filter(({ type }) => type === 'client');
    const quotationsEnabledForClient = clientEmails.some((email) => email.enableForQuotations);
    const invoicesAndCreditNotesEnabledForClient = clientEmails.some((email) => email.enableForInvoicesAndCreditNotes);
    const paymentRemindersEnabledForClient = clientEmails.some((email) => email.enableForPaymentReminders);

    prepend({
      type: 'user',
      emailAddress: user.email,
      enableForQuotations: !quotationsEnabledForClient,
      enableForInvoicesAndCreditNotes: !invoicesAndCreditNotesEnabledForClient,
      enableForPaymentReminders: !paymentRemindersEnabledForClient,
    });

    if (isSubmitted) trigger('emails');
  }, [getValues, remove, prepend, user.email, isSubmitted, trigger]);

  const isEmpty =
    watch('emails').length === 1 &&
    watch('emails.0.emailAddress') === '' &&
    watch('emails.0.enableForQuotations') &&
    watch('emails.0.enableForInvoicesAndCreditNotes') &&
    watch('emails.0.enableForPaymentReminders');

  return (
    <CollapsibleSection
      description={t('clients:createEdit.subsections.emails.description')}
      id={AnchorTargets.CONTACT_EMAILS}
      title={t('clients:createEdit.subsections.emails.title')}
    >
      <Label>{t('clients:fields.emailAddresses.label')}</Label>

      <div className="space-y-8 lg:space-y-4">
        {fields.map((field, i) => (
          <div className="flex flex-col lg:flex-row flex-wrap gap-2 lg:items-start" key={field.id}>
            {/* Email address */}
            <div className="flex-1">
              <FormControl control={control} name={`emails.${i}.emailAddress`} rules={{ required: true, pattern: EMAIL }}>
                <div onKeyDown={(e) => e.key === 'Enter' && !e.ctrlKey && !e.shiftKey && !e.metaKey && addClientEmail()}>
                  <FormTextInput disabled={field.type === 'user'} />
                </div>
                <FormErrorMessage pattern={t('validation:email.invalid')} required={t('validation:required')} />
              </FormControl>
            </div>

            <div className="flex-1 flex gap-2 max-sm:flex-wrap items-center">
              {/* Mail type buttons */}
              {(
                [
                  { fieldName: 'enableForQuotations', label: t('clients:fields.emailAddresses.quotations') },
                  { fieldName: 'enableForInvoicesAndCreditNotes', label: t('clients:fields.emailAddresses.invoicesAndCreditNotes') },
                  { fieldName: 'enableForPaymentReminders', label: t('clients:fields.emailAddresses.paymentReminders') },
                ] as const
              ).map(({ fieldName, label }) => (
                <div className="max-sm:flex-1" key={fieldName}>
                  <FormControl as={Fragment} control={control} name={`emails.${i}.${fieldName}`}>
                    <FormField>
                      {({ field: mailTypeField }) => (
                        <button
                          className={classNames(
                            'max-sm:w-full h-10 px-4 border border-gray-300 text-sm leading-none lg:whitespace-nowrap',
                            'hover:border-primary-500 focus-within:border-primary-500 outline-1 outline-offset-4 outline-primary-400',
                            mailTypeField.value && 'text-primary-500 bg-primary-50 border-primary-500',
                          )}
                          onBlur={mailTypeField.onBlur}
                          onClick={() => {
                            const selected = !mailTypeField.value;
                            mailTypeField.onChange(selected);

                            // Deselect options from the other type if this one is selected
                            // An option can't be selected for both a 'user' and 'client' email
                            if (selected) {
                              getValues('emails').forEach(({ type }, i) => {
                                if (type !== field.type) setValue(`emails.${i}.${fieldName}`, false);
                              });
                            }

                            if (isSubmitted) trigger('emails');
                          }}
                          type="button"
                        >
                          {label}
                        </button>
                      )}
                    </FormField>
                  </FormControl>
                </div>
              ))}

              {/* Delete button */}
              {!isEmpty && (
                <div className="ml-auto">
                  <Button
                    icon="Delete"
                    onClick={() => {
                      if (fields.length > 1) {
                        remove(i);
                      } else {
                        update(i, {
                          type: 'client',
                          emailAddress: '',
                          enableForInvoicesAndCreditNotes: true,
                          enableForPaymentReminders: true,
                          enableForQuotations: true,
                        });
                      }

                      if (isSubmitted) trigger('emails');
                    }}
                    type="iconOnly"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Root errors */}
      <div>
        {errors.emails?.root?.type === 'eachTypeSelected' && <ErrorMessage>{t('validation:client.emails.eachTypeSelected')}</ErrorMessage>}
        {errors.emails?.root?.type === 'noEmptySelection' && <ErrorMessage>{t('validation:client.emails.noEmptySelection')}</ErrorMessage>}
      </div>

      <div className="mt-4 flex flex-wrap gap-x-6 gap-y-2 lg:gap-x-10">
        {/* Add client button */}
        <Button icon="Add" onClick={addClientEmail} type="tertiary">
          {t('clients:fields.emailAddresses.addReceiver')}
        </Button>

        {/* Add self button */}
        {!fields.some(({ type }) => type === 'user') && (
          <Button icon="Add" onClick={addUserEmail} type="tertiary">
            {t('clients:fields.emailAddresses.addSelf')}
          </Button>
        )}
      </div>
    </CollapsibleSection>
  );
};
