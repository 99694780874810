import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useApproveAccountant = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request('/integrations/accountant/approve', { method: 'POST', token });
    },
    onSuccess: () => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return {
          ...user,
          accountant: user.accountant ? { approved: true, data: user.accountant.data } : null,
        };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
