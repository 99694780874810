import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const PhotographerHead = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="KIIA 1"
      viewBox="630.68 270.4 78.45 94.29"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <path
        {...stylex.props(styles.stroke, styles.fillPrimary)}
        d="M669.13,326.1c-10.47,5.68-22.94,8.53-37.81,8-.41-2.22-.63-4.51-.63-6.85,0-.15,0-.3,0-.45,11.65,1.83,24.41.03,35.27-4.45,1,1.31,2.06,2.56,3.17,3.75Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M665.95,322.35c-10.86,4.48-23.62,6.28-35.27,4.45.05-4.59.94-8.99,2.51-13.05h0c.45-1.16.95-2.29,1.51-3.39,0,0,6.84-6.25,21.08-7.87,2.5,7.59,5.86,14.25,10.17,19.86Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M705.56,327.25c0,2.82-.31,5.56-.9,8.21,0,0,0,0,0,0-.52,2.35-1.27,4.62-2.22,6.78-14.31-1.88-25.23-7.47-33.31-16.15-1.11-1.19-2.17-2.44-3.17-3.75-4.32-5.61-7.67-12.27-10.17-19.86,3.95-.45,8.46-.54,13.56-.05,12.05,1.18,20.89,5.29,27.02,9.49,3.74,2.56,6.47,5.16,8.31,7.14.59,2.63.89,5.36.89,8.17Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M702.44,342.25c-5.78,13.21-18.97,22.44-34.31,22.44-18.34,0-33.6-13.19-36.81-30.59,14.87.53,27.34-2.32,37.81-8,8.08,8.68,19,14.26,33.31,16.15Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillBlack)}
        d="M704.67,319.08c-1.84-1.99-4.57-4.59-8.31-7.14-6.13-4.2-14.97-8.31-27.02-9.49-5.09-.5-9.61-.41-13.56.05-14.24,1.62-21.08,7.87-21.08,7.87,6.17-12.19,18.82-20.55,33.42-20.55,11.72,0,22.18,5.38,29.04,13.81,3.61,4.42,6.22,9.69,7.5,15.46Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M697.17,303.63c-6.86-8.43-17.32-13.81-29.04-13.81-14.6,0-27.25,8.36-33.42,20.55-.56,1.1-1.06,2.23-1.51,3.38-.03-.4-2.13-33.66,10.96-39.54,13.17-5.92,43.12-5.23,49.8,4.06,1.47,2.05,2.38,5.06,2.9,8.51.78,5.21.69,11.43.32,16.85Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillPrimary)}
        d="M704.66,335.46c.59-2.64.9-5.39.9-8.21s-.31-5.54-.89-8.17c-1.28-5.77-3.9-11.03-7.5-15.46.37-5.42.47-11.64-.32-16.85,11.43,10.41,16.69,24.88,7.81,48.68Z"
      ></path>
    </svg>
  );
};
