import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { invoiceAttachmentQueryOptions } from './useInvoiceAttachment';

export const useRemoveInvoiceAttachment = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request(`/invoices/${id}/attachment`, { method: 'DELETE', token });
    },
    onSuccess: () => {
      queryClient.setQueryData(invoiceAttachmentQueryOptions(id).queryKey, null);
    },
  });
};
