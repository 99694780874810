import type { Plan } from '~/api/plans/types';
import type { User } from '~/types/user';

import { PlanId } from '~/api/plans/constants';
import { Activity } from '~/types/user';

import { enumValues } from './objects';

// Payment plan

export const hasCorrectPlan = (user: User, minimumPlan: Plan['id']): boolean => {
  if (user.planId === PlanId.earlyAdopter) return true;

  return user.planId >= minimumPlan;
};

export const getActiveCommissionBracket = (user: User): 1 | 2 | 3 => {
  if (user.planId === PlanId.earlyAdopter) return 1;

  const brackets = Object.entries(user.commissionPercentages.revenueAmounts) as [['1', number], ['2', number]];
  const activeBracket = brackets.reverse().find(([, bracketLimit]) => user.totalInvoicedAmount >= bracketLimit) ?? (['0', 0] as const);
  const activeBracketIndex = activeBracket[0];

  return (+activeBracketIndex + 1) as 1 | 2 | 3;
};

// Activities

const isDigitalDesigner = (user: User): boolean => user.activities.map(({ id }) => id).includes(Activity.DIGITAL_DESIGN);
export const isPhotographer = (user: User): boolean => user.activities.map(({ id }) => id).includes(Activity.PHOTOGRAPHY);
export const isWriter = (user: User): boolean => user.activities.map(({ id }) => id).includes(Activity.LANGUAGE_AND_COMMUNICATION);
export const isItDeveloper = (user: User): boolean => user.activities.map(({ id }) => id).includes(Activity.IT_DEVELOPMENT);
export const isItRelated = (user: User): boolean => isItDeveloper(user) || isDigitalDesigner(user);

// Account completion

export enum AccountCompletionStep {
  FINANCIAL_INFO = 'financialInfo',
  VAT_REGULATION = 'vatRegulation',
  FRAMEWORK_AGREEMENT = 'frameworkAgreement',
}

export const getAccountCompletionProgress = (user: User) => ({
  [AccountCompletionStep.FINANCIAL_INFO]: !!user.financial.iban,
  [AccountCompletionStep.VAT_REGULATION]: user.vatExempt !== null,
  [AccountCompletionStep.FRAMEWORK_AGREEMENT]: user.contractSigned && user.addendumSigned,
});

export const isAccountCompleted = (user: User) => enumValues(getAccountCompletionProgress(user)).every((completed) => completed);
