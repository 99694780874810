import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { NumberingSeries } from '~/types/user';

import { getAccessToken } from '~/utils/auth';

import type { CreateNumberingSeriesPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useCreateNumberingSeries = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateNumberingSeriesPayload) => {
      const token = getAccessToken();
      const response = await request('/numbering-series', { method: 'POST', token, body });

      // TODO: Schema validation
      const { data: numberingSeries } = apiResourceSchema(z.unknown()).parse(response);

      return numberingSeries as NumberingSeries;
    },
    onSuccess: (numberingSeries) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, numberingSeries: [...user.numberingSeries, numberingSeries] };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
