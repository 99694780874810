import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const SoundEngineerHead = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="KIIA 1"
      viewBox="492.06 173.85 84.44 102.09"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <path
        {...stylex.props(styles.stroke, styles.fillPrimary)}
        d="M548.64,275.02c-20.08,5.08-40.96-11.39-47.55-34.89,0,0,18.98,23.16,48.1,13.91,23.59-7.49,26.47-29.38,26.56-30.07,3.93,22.99-7.76,46.15-27.11,51.04Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M574.89,219.95c-.14-.56-.29-1.11-.45-1.65,0,7.61-3.81,13.84-9.19,14.45-2.18.25-4.32-.47-6.21-1.9-3.28-2.49-5.79-7.17-6.42-12.72-.18-1.6-.19-3.17-.05-4.66.61-6.66,4.21-11.86,9.1-12.41,1.43-.16,2.85.09,4.18.69.27.12.53.25.78.4-.22-.3-.44-.6-.67-.9-5.01-6.59-11.41-11.56-18.44-14.32-1.29,4.88-.69,9.22-.69,9.22-4.97,2.59-9.79,5.25-14.39,7.99-13.21,7.87-24.68,16.46-33.16,26.31.25,2.73.72,5.5,1.42,8.28.12.48.24.95.38,1.42,0,0,18.99,23.16,48.1,13.92,23.59-7.49,26.47-29.39,26.56-30.07h0c-.23-1.35-.52-2.69-.85-4.03ZM535.36,238.71c-1.8.2-3.57-.25-5.19-1.21-2.28-1.36-4.28-3.76-5.65-6.79-.89-1.97-1.52-4.21-1.79-6.63-.04-.41-.08-.81-.11-1.22-.49-8.14,3.35-15.04,8.95-15.83.07-.01.13-.02.2-.03,2.16-.24,4.28.46,6.15,1.86,2.01,1.51,3.74,3.83,4.92,6.68.77,1.83,1.3,3.89,1.55,6.08.99,8.76-3.06,16.4-9.04,17.07Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M535.36,238.71c-1.8.2-3.57-.25-5.19-1.21l7.76-28.62c2.01,1.51,3.74,3.83,4.92,6.68.77,1.83,1.31,3.89,1.55,6.08.99,8.75-3.06,16.4-9.04,17.07Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M535.36,238.71c-1.8.2-3.57-.25-5.19-1.21l7.76-28.62c2.01,1.51,3.74,3.83,4.92,6.68.77,1.83,1.3,3.89,1.55,6.08.99,8.76-3.06,16.4-9.04,17.07Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M574.44,218.29h0c0,7.61-3.81,13.85-9.19,14.46-2.18.25-4.32-.47-6.2-1.9l6.81-29.11c.27.12.53.25.78.4h0c3.88,2.18,6.95,7.3,7.66,13.54.1.88.15,1.76.15,2.62Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M565.85,201.74l-6.81,29.11c-3.28-2.5-5.79-7.17-6.42-12.72-.18-1.6-.19-3.17-.05-4.66.61-6.66,4.21-11.86,9.1-12.41,1.43-.16,2.84.09,4.18.69Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M537.93,208.87l-7.76,28.62c-2.29-1.36-4.28-3.75-5.64-6.79-.89-1.97-1.52-4.22-1.79-6.63-.05-.41-.08-.81-.11-1.22-.49-8.14,3.35-15.04,8.95-15.83.07,0,.13-.02.2-.03,2.16-.25,4.28.46,6.15,1.86Z"
      ></path>
      <path {...stylex.props(styles.stroke)} d="M575.75,223.97s0,0,0,.01"></path>
      <line {...stylex.props(styles.stroke)} x1="522.64" x2="522.63" y1="222.86" y2="222.86"></line>
      <path
        {...stylex.props(styles.stroke, styles.fillBlack)}
        d="M566.64,202.14c-.22-.3-.44-.6-.67-.9-5-6.59-11.42-11.55-18.44-14.32,1.19-4.51,3.99-9.48,10.35-11.93,5.76-2.22,14.1-1.4,15.73,4.28,1.8,6.24-3.54,17.73-6.97,22.88Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M557.88,174.98c-6.36,2.45-9.16,7.42-10.35,11.93-1.29,4.88-.69,9.22-.69,9.22-4.97,2.59-9.79,5.25-14.39,8-13.21,7.87-24.68,16.47-33.16,26.31-.1.12-.2.23-.3.35l-6.51-3.09c2.31-2.52,4.61-5,6.89-7.43h0c7.38-7.88,14.56-15.21,21.37-21.58,5.91-5.52,11.54-10.33,16.8-14.14h0c1.42-1.03,2.81-1.99,4.17-2.86,6.02-3.89,11.47-6.27,16.18-6.7Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M541.71,181.68c-1.36.88-2.75,1.84-4.17,2.86h0c-5.26,3.81-10.89,8.62-16.8,14.14-6.81,6.37-13.99,13.7-21.37,21.58h0c-2.28,2.43-4.58,4.91-6.89,7.43,0,0-3.45-26.54,9.52-37.83,12.97-11.29,20.7-10.07,25.52-10.07,3.44,0,10.43,1.2,14.19,1.89Z"
      ></path>
    </svg>
  );
};
