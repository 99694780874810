export const creditnotes = {
  alerts: {
    duplicateEntryNumber: 'This credit note number already exists, please use another one.',
    successCreated: 'The credit note was created successfully!',
  },
  create: {
    confirmation: {
      description:
        'Are you sure you want to create the credit note? Once the credit note has been approved and sent, you can no longer make changes.',
      submit: 'Yes, create credit note',
      title: 'Your credit note is being forwarded',
    },
    partialPaymentsWarning: {
      message_one:
        "We have already received partial payment of {{ payment }} for this invoice. Feel free to credit the invoice, we'll pick it up with you to follow up on this payment.",
      message_other:
        "We have already received partial payments of {{ payment }} for this invoice. Feel free to credit the invoice, we'll pick it up with you to follow up on these payments.",
      title: 'A heads-up!',
    },
    submit: 'Create credit note',
    title: 'Credit note details',
  },
  fields: {
    invoiceId: {
      label: 'Credit note for invoice',
      placeholder: 'Select an invoice',
    },
    numberingSeriesId: {
      helpText: 'The credit note number will be <0>{{entryNumber}}</0>.',
      label: 'Numbering range',
      labelTooltip:
        'Questions about creating a numbering sequence? Click <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">here</a> for more information.',
      placeholder: 'Select a numbering range.',
      tooltip: 'This is the preview of your credit note number. When you send your invoice, the number will be definitive.',
      tooltipCreate:
        'A numbering range helps you number your invoices correctly and smoothly. You can create as many as you want, but one is sufficient. More info questions? <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">Please check it here.</a>',
    },
    reason: {
      label: 'Reason',
      placeholder: 'Enter the reason here.',
    },
  },
  overview: {
    columns: {
      client: 'Client',
      date: 'Date',
      entryNumber: 'Credit note',
      reason: 'Reason',
      status: {
        options: {
          Approved: 'Approved',
          Pending: 'For approval',
        },
        title: 'Status',
      },
      total: 'Credited',
      year: 'Year',
    },
    created: {
      subtitle: 'Credit notes',
    },
    rowActions: {
      download: 'Download',
    },
  },
};
