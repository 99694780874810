import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapsibleSection } from '~/components';
import { SearchParamModals } from '~/constants/url';
import { useModal } from '~/hooks/useModal';
import { useShouldOpenModal } from '~/hooks/useShouldOpenModal';

import { CreateNumberingSeriesModal } from './CreateNumberingSeriesModal';
import { NumberingSeriesTable } from './NumberingSeriesTable';
import { NumberingSeriesFormSection } from './types';

export const NumberingSeries = () => {
  const { t } = useTranslation(['settings']);

  const createModal = useModal(useShouldOpenModal(SearchParamModals.ADD_NUMBERING_SERIES));
  const [expandedNumberingSeries, setExpandedNumberingSeries] = useState<{ id: number; section: NumberingSeriesFormSection } | null>(null);

  return (
    <>
      <CollapsibleSection.Button icon="Add" onClick={createModal.open}>
        {t('settings:general.numberingSeries.add')}
      </CollapsibleSection.Button>

      <NumberingSeriesTable expandedNumberingSeries={expandedNumberingSeries} setExpandedNumberingSeries={setExpandedNumberingSeries} />

      {createModal.isOpen && (
        <CreateNumberingSeriesModal
          onClose={createModal.close}
          onCreate={(id) => setExpandedNumberingSeries({ id, section: NumberingSeriesFormSection.FORMAT_AND_OPTIONS })}
        />
      )}
    </>
  );
};
