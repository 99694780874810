import type { HTMLProps } from 'react';
import type { Control } from 'react-hook-form';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CountryCode } from '~/api/common/types';

import { CountryFlag, FormControl, FormLabel, FormSelect } from '~/components';
import { countryCodes } from '~/constants/countries';
import { useIntl } from '~/hooks/useIntl';

type FormWithCountrycode = { address: { countryCode: CountryCode } };

export const FormCountryCode = <T extends FormWithCountrycode>({
  control,
  disabled,
  ...props
}: HTMLProps<HTMLDivElement> & {
  control: T extends FormWithCountrycode ? Control<T> : never;
}) => {
  const { displayNameOfCountry } = useIntl();

  const countryOptions = useMemo(
    () =>
      countryCodes
        .map((countryCode) => ({ name: displayNameOfCountry(countryCode), code: countryCode }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [displayNameOfCountry],
  );

  const { t } = useTranslation(['clients']);

  return (
    <div {...props}>
      <FormControl control={control} name="address.countryCode" rules={{ required: true }}>
        <FormLabel>{t('clients:fields.countryCode.label')}</FormLabel>
        <FormSelect
          disabled={disabled}
          options={countryOptions.map((country) => ({
            label: country.name,
            value: country.code,
          }))}
          OptionWrapper={({ children, value }) => (
            <span className="flex items-center gap-x-2">
              <CountryFlag countryCode={value} />
              <span className="text-ellipsis overflow-hidden [button_&]:truncate">{children}</span>
            </span>
          )}
        />
      </FormControl>
    </div>
  );
};
