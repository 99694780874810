import { useSearchParams } from 'react-router-dom';

import { useInvoices } from '~/api/invoices';
import { Redirect } from '~/components/Functional';
import { SearchParamKeys } from '~/constants/url';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { CreateCreditNoteForm } from './CreateCreditNoteForm';

export const CreateCreditNote = () => {
  const { data: invoices } = useInvoices();

  const [searchParams] = useSearchParams();
  const invoiceIdQuery = searchParams.get(SearchParamKeys.INVOICE_ID);
  const invoiceId = invoiceIdQuery && !isNaN(+invoiceIdQuery) ? +invoiceIdQuery : undefined;
  const invoice = invoices.find(({ id }) => id === invoiceId);

  if (invoice?.credited) return <Redirect to={r(routes.showInvoice, { invoiceId: invoice.id })} />;

  const nonCreditedInvoices = invoices.filter((invoice) => invoice.allowed && !invoice.paid && !invoice.credited);

  if (nonCreditedInvoices.length === 0) return <Redirect to={routes.invoices} />;

  return <CreateCreditNoteForm invoiceId={invoiceId} nonCreditedInvoices={nonCreditedInvoices} />;
};
