import type { Editor, EditorContentProps } from '@tiptap/react';

export type RichTextEditorProps = {
  isInvalid: boolean;
  onChange?: (text: string) => void;
  placeholder?: string;
  setShouldUpdateContent?: (shouldUpdateContent: boolean) => void;
  shortcodes?: ShortcodeOption[];
  shouldUpdateContent?: boolean;
  value: string;
  withLinkButton?: boolean;
};

export type TextEditorMenuProps = {
  shortcodes?: ShortcodeOption[];
  withLinkButton: boolean;
} & EditorContentProps;

export type AddHyperlinkProps = {
  editor: Editor;
};

export type AddHyperlinkModalProps = {
  onClose: () => void;
  onSubmit: (link: string) => void;
};

export type AddShortcodeDropdownProps = {
  editor: Editor;
  shortcodes: ShortcodeOption[];
};

type ShortcodeOption = {
  code: string;
  label: Shortcodes;
};

export enum Shortcodes {
  CLIENTNAME = 'Clientname',
  QUOTATIONLINK = 'Quotationlink',
  FREELANCERFIRSTNAME = 'Freelancerfirstname',
}
