import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UpdateDraftQuotationPayload } from './types';

import { previewHtmlSchema } from '../common/schemas';
import { request } from '../request';

export const usePreviewQuotation = () => {
  return useMutation({
    mutationFn: async ({ body, id }: { body: UpdateDraftQuotationPayload; id: number }) => {
      const token = getAccessToken();
      const response = await request(`/quotations/${id}/preview`, { method: 'POST', token, body });

      const { previewHTML } = previewHtmlSchema.parse(response);

      return previewHTML;
    },
  });
};
