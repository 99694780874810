import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';

export const useRequestPasswordReset = () => {
  return useMutation({
    mutationFn: (emailAddress: string) => {
      const body = { emailAddress };
      return request('/authentication/request-password-reset-link', { method: 'POST', body });
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) &&
      captureException(new Error('Failed to request password reset', { cause: error }), { level: 'error' }),
  });
};
