import type { PageTitleProps as Props } from './types';

import styles from './PageTitle.module.scss';
import { PageTitleItems } from './PageTitleItems';

export const PageTitle = ({ children, subtitle, title }: Props) => {
  return (
    <div className={styles.PageTitleWrapper}>
      <div>
        <h1 className={styles.Title}>{title}</h1>
        {subtitle && <span className={styles.Subtitle}>{subtitle}</span>}
      </div>
      <div className={styles.ItemsWrapper}>{children}</div>
    </div>
  );
};

PageTitle.Items = PageTitleItems;
