import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, lineHeight, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  alertDescription: {
    color: systemColors.textBlack,
    fontSize: fontSize.small,
    fontWeight: fontWeight.regular,
    lineHeight: lineHeight.snug,
  },
});
