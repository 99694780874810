import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Redirect } from '~/components/Functional';
import { Flex, Span, Text } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

import { AuthLayoutContent } from '../AuthLayout/AuthLayoutContent';
import { AuthLayoutTitleGroup } from '../AuthLayout/AuthLayoutTitleGroup';
import { ResendVerificationEmail } from '../ResendVerificationEmail/ResendVerificationEmail';
import { useRegistrationContext } from './Registration.context';
import { RegistrationAccountantForm } from './RegistrationAccountantForm';

export const RegistrationSuccess = () => {
  const { clearRegistrationInformation, user } = useRegistrationContext();

  const { t } = useTranslation(['auth']);

  useEffect(() => {
    if (user) clearRegistrationInformation();
  }, [user, clearRegistrationInformation]);

  if (user === null) return <Redirect to={routes.login} />;

  return (
    <AuthLayoutContent>
      <AuthLayoutTitleGroup
        pill={t('auth:registrationPage.common.header.pill')}
        subtitle={
          user.eligible
            ? t('auth:registrationPage.stepSuccess.header.subtitle.eligible')
            : t('auth:registrationPage.stepSuccess.header.subtitle.ineligible')
        }
        title={t('auth:registrationPage.stepSuccess.header.title')}
      />

      {user.eligible ? (
        <Flex direction="column" gap="small">
          <Text>
            <Trans
              components={{ bold: <Span weight="medium" /> }}
              i18nKey="auth:registrationPage.stepSuccess.message.eligible"
              values={{ email: user.email }}
            />
          </Text>

          <ResendVerificationEmail emailAddress={user.email} />
        </Flex>
      ) : (
        <Text>
          <Trans components={{ break: <br /> }} i18nKey="auth:registrationPage.stepSuccess.message.ineligible" />
        </Text>
      )}

      <RegistrationAccountantForm userEmailAddress={user.email} />
    </AuthLayoutContent>
  );
};
