import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import type { Invoice } from '~/types/invoice';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';

export const invoicesQueryOptions = queryOptions({
  queryKey: QueryKeys.invoices,
  queryFn: async () => {
    const token = getAccessToken();
    const response = await request('/invoices', { token });

    // TODO: Schema validation
    const { data: invoices } = apiResourceSchema(z.unknown()).parse(response);

    return invoices as Invoice[];
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const useInvoices = () => {
  return useSuspenseQuery(invoicesQueryOptions);
};
