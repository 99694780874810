import { captureException } from '@sentry/react';
import { broadcastQueryClient } from '@tanstack/query-broadcast-client-experimental';
import { QueryClient } from '@tanstack/react-query';
import { ZodError } from 'zod';

import { shouldThrowError } from '~/api/errors';
import { FormattedZodError } from '~/constants/errors';

import { env } from '../../constants/env';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (shouldThrowError(error)) return false;

        if (error instanceof ZodError) {
          if (env.IS_PRODUCTION_ENV) {
            captureException(new FormattedZodError(error.issues, { cause: error }), { level: 'error' });
          }

          return false;
        }

        return failureCount < 3;
      },
      throwOnError: (error) => shouldThrowError(error),
    },
    mutations: {
      throwOnError: (error) => shouldThrowError(error),
    },
  },
});

// Broadcast Channel for query syncing between browser contexts
broadcastQueryClient({
  queryClient,
  broadcastChannel: 'craft',
});
