import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { IconTooltip } from '~/components';

import styles from './AutoForwarding.module.scss';
import { AutoForwardingForm } from './AutoForwardingForm';

export const AutoForwarding = () => {
  const { data: user } = useUser();
  const { t } = useTranslation(['settings']);

  const [enabledForm, setEnabledForm] = useState<number | 'create' | null>(user.invoiceForwardingSettings.length === 0 ? 'create' : null);

  return (
    <div className={styles.Stack}>
      <div className={classNames(styles.Row, styles.DesktopRow)}>
        <p className={styles.Label}>{t('settings:fields.email.label')}</p>
        <p className={styles.Label}>
          {t('settings:fields.forwarding.frequency.label')} <IconTooltip text={t('settings:fields.forwarding.frequency.tooltip')} />
        </p>
        <div className={styles.ColumnPlaceholder} />
      </div>

      {user.invoiceForwardingSettings.map((entry) => (
        <AutoForwardingForm
          disableForm={() => setEnabledForm(null)}
          enableForm={(id) => setEnabledForm(id)}
          invoiceForwardingSettings={entry}
          isEnabled={enabledForm === entry.id}
          key={entry.id}
        />
      ))}

      <AutoForwardingForm
        disableForm={() => setEnabledForm(null)}
        enableForm={(id) => setEnabledForm(id)}
        isEnabled={enabledForm === 'create'}
      />
    </div>
  );
};
