import { useTranslation } from 'react-i18next';

import type { Invoice } from '~/types/invoice';

import { DropdownMenu, Modal, PreviewIFrame } from '~/components';
import { insertIf } from '~/utils/arrays';

import {
  useOnCreditInvoice,
  useOnDownloadInvoice,
  useOnDuplicateInvoice,
  useOnEditInvoice,
  useOnPreviewInvoice,
  useOnShowInvoice,
} from '../../invoices.hooks';
import { InvoiceAlreadyApprovedModal } from '../../Modals/InvoiceAlreadyApprovedModal/InvoiceAlreadyApprovedModal';
import { InvoiceConfirmEditModal } from '../../Modals/InvoiceConfirmEditModal/InvoiceConfirmEditModal';
import { getInvoiceStatus, InvoiceStatus } from '../../utils';

export const InvoiceTableOptions = ({ invoice }: { invoice: Invoice }) => {
  const status = getInvoiceStatus(invoice);

  const { onShow } = useOnShowInvoice(invoice);
  const { alreadyApprovedModal, confirmEditModal, mutation: acquireLockMutation, onEdit } = useOnEditInvoice(invoice);
  const { mutation: previewMutation, onPreview } = useOnPreviewInvoice(invoice);
  const { mutation: duplicateMutation, onDuplicate } = useOnDuplicateInvoice(invoice);
  const { mutation: downloadMutation, onDownload } = useOnDownloadInvoice(invoice);
  const { onCredit } = useOnCreditInvoice(invoice);

  const { t } = useTranslation(['invoices']);

  return (
    <>
      <DropdownMenu
        dropdownClass="text-sm"
        isLoading={acquireLockMutation.isPending || previewMutation.isPending || duplicateMutation.isPending || downloadMutation.isPending}
        items={[
          {
            iconName: 'QuickReference',
            text: t('invoices:overview.rowActions.details'),
            onClick: onShow,
          },
          ...insertIf([InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status), {
            iconName: 'Edit',
            text: t('invoices:overview.rowActions.edit.label'),
            onClick: onEdit,
          }),
          {
            iconName: 'Visibility',
            text: t('invoices:overview.rowActions.preview'),
            onClick: onPreview,
          },
          {
            iconName: 'ContentCopy',
            text: t('invoices:overview.rowActions.duplicate'),
            onClick: onDuplicate,
          },
          ...insertIf(![InvoiceStatus.PENDING, InvoiceStatus.LOCKED_BY_USER].includes(status), {
            iconName: 'Download',
            text: t('invoices:overview.rowActions.download'),
            onClick: onDownload,
          }),
          ...insertIf([InvoiceStatus.SENT, InvoiceStatus.EXPIRED, InvoiceStatus.CO_PARTIALLY_PAID].includes(status), {
            iconName: 'Receipt',
            text: t('invoices:overview.rowActions.credit'),
            onClick: onCredit,
          }),
        ]}
      />

      {/* Prevent triggering row click when clicking outside a modal to close it */}
      <div onClick={(e) => e.stopPropagation()}>
        {confirmEditModal.isOpen && (
          <InvoiceConfirmEditModal isLocked={!!confirmEditModal.data?.lockedByUser} onClose={confirmEditModal.closeWithResult} />
        )}

        {alreadyApprovedModal.isOpen && (
          <InvoiceAlreadyApprovedModal invoice={alreadyApprovedModal.data} onClose={alreadyApprovedModal.close} />
        )}

        {previewMutation.data && (
          <Modal onClose={previewMutation.reset} variant="preview">
            <PreviewIFrame srcDoc={previewMutation.data} />
          </Modal>
        )}
      </div>
    </>
  );
};
