import { Fragment } from 'react';

import type { TableBodyProps as Props } from './types';

import { useTableContext } from '../context';
import { TableRowNoResults } from '../TableRow/TableRowNoResults';

export const TableBody = <TData,>({ children }: Props<TData>) => {
  const { getRowModel } = useTableContext<TData>();

  const { rows } = getRowModel();

  if (rows.length === 0) {
    return <TableRowNoResults />;
  }

  return rows.map((row) => <Fragment key={row.id}>{children(row)}</Fragment>);
};
