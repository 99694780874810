import { IconTooltip } from '~/components';
import { Icon } from '~/components/SVG';

import type { PlanFeatureProps as Props } from './types';

import styles from './PlanFeature.module.scss';

export const PlanFeature = ({ text, tooltip }: Props) => {
  return (
    <li className={styles.Wrapper}>
      <span className={styles.CheckIcon}>
        <Icon name="Check" size={28} />
      </span>

      <p>
        <span>{text}</span>
        {tooltip && (
          <span className={styles.Tooltip}>
            <IconTooltip text={tooltip} />
          </span>
        )}
      </p>
    </li>
  );
};
