import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import type { CreditNote } from '~/api/creditNotes/types';

import { useClients } from '~/api/clients';
import { useUser } from '~/api/user';
import { BrandDot, CheckboxInput } from '~/components';
import { CoDocumentStatus } from '~/components/UI';
import { ColumnIds } from '~/constants/table';
import { SearchParamKeys } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { filterBoolean } from '~/utils/arrays';
import { qs } from '~/utils/searchParams';

import { CreditnoteStatus, getCreditnoteStatus } from '../../utils';
import { CreditNotesTableOptions } from './CreditNotesTableOptions';

const columnHelper = createColumnHelper<CreditNote>();

export const useCreditNotesTableColumns = () => {
  const { data: user } = useUser();
  const { data: clients } = useClients();

  const location = useLocation();
  const { t } = useTranslation(['creditnotes', 'filters']);
  const { formatCurrency } = useIntl();

  return useMemo(
    () => [
      // Selector
      columnHelper.display({
        id: ColumnIds.selector,
        header: ({ table }) => (
          // Label is necessary to trigger the click event
          <label className="p-0">
            <CheckboxInput
              checked={table.getIsAllPageRowsSelected()}
              indeterminate={table.getIsSomePageRowsSelected()}
              onChange={table.getToggleAllPageRowsSelectedHandler()}
            />
          </label>
        ),
        cell: ({ row }) =>
          row.getCanSelect() && (
            <div onClick={(e) => e.stopPropagation()}>
              <label className="p-0 max-lg:relative max-lg:after:block max-lg:after:absolute max-lg:after:-inset-4 max-lg:after:cursor-pointer">
                <CheckboxInput checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
              </label>
            </div>
          ),
        meta: {
          styles: { size: 'max-content' },
          mobileStyles: { size: 'max-content' },
        },
      }),

      // Description & entry number
      columnHelper.accessor(({ description, entryNumber }) => `${description} ${entryNumber}`, {
        id: ColumnIds.creditNotesDescription,
        header: t('creditnotes:overview.columns.entryNumber'),
        cell: ({ row: { original: creditNote } }) => {
          const brand = user.brands.find((brand) => brand.id === creditNote.brandId);

          return (
            <div className="lg:truncate">
              <strong>{creditNote.description}</strong>
              <div className="lg:truncate">
                <span>{creditNote.entryNumber}</span>
                {brand && <BrandDot color={brand.color} small />}
              </div>
            </div>
          );
        },
        meta: {
          styles: { size: '3fr', minSize: 150 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Brand (hidden, column filter only)
      columnHelper.accessor(({ brandId }) => brandId ?? 0, {
        id: ColumnIds.creditNotesBrand,
        enableGlobalFilter: false,
      }),

      // Client
      columnHelper.accessor(({ clientId }) => clients.find((client) => client.id === clientId)?.name, {
        id: ColumnIds.creditNotesClient,
        header: t('creditnotes:overview.columns.client'),
        cell: ({ row: { original: creditNote } }) => {
          const client = clients.find(({ id }) => id === creditNote.clientId);

          if (!client) return null;

          return (
            <div className="lg:truncate" onClick={(e) => e.stopPropagation()}>
              <Link
                className="text-dark-gray hover:text-dark-gray hover:underline"
                to={{
                  pathname:
                    client.clientType === 'organisation'
                      ? r(routes.showClient, { clientId: client.id })
                      : r(routes.editContact, { clientId: client.id, contactId: client.contacts[0].id }),
                  search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }),
                }}
              >
                {client.name}
              </Link>
            </div>
          );
        },
        sortUndefined: 'last',
        meta: {
          styles: { size: '2fr', minSize: 125 },
          isResizable: true,
        },
      }),

      // Contact (hidden, global filter only)
      columnHelper.accessor(
        ({ contactId }) => {
          const contact = clients.flatMap(({ contacts }) => contacts).find(({ id }) => id === contactId);
          return contact ? `${contact.firstName} ${contact.lastName}` : '';
        },
        {
          id: ColumnIds.creditNotesContact,
        },
      ),

      // Doc date
      columnHelper.accessor(({ docDate }) => dayjs(docDate).format('DD/MM/YYYY'), {
        id: ColumnIds.creditNotesDocDate,
        header: t('creditnotes:overview.columns.date'),
        cell: ({ getValue }) => <span className="lg:truncate">{getValue()}</span>,
        sortingFn: ({ original: a }, { original: b }) => {
          const dateA = dayjs(a.docDate);
          const dateB = dayjs(b.docDate);

          return dateA.isSame(dateB) ? 0 : dateA.isBefore(dateB) ? -1 : 1;
        },
        meta: {
          styles: { size: 'max-content', minSize: 100 },
          isResizable: true,
        },
      }),

      // Doc year (hidden, column filter only)
      columnHelper.accessor(({ docYear }) => docYear, {
        id: ColumnIds.creditNotesDocYear,
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
      }),

      // Doc quarter (hidden, column filter only)
      columnHelper.accessor(({ docDate, docYear }) => `${docYear} ${dayjs(docDate).quarter()}`, {
        id: ColumnIds.creditNotesDocQuarter,
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
      }),

      // Reason
      columnHelper.accessor('reason', {
        id: ColumnIds.creditNotesReason,
        header: t('creditnotes:overview.columns.reason'),
        cell: ({ getValue }) => <span className="lg:truncate">{getValue()}</span>,
        meta: {
          styles: { size: '3fr', minSize: 100 },
          isResizable: true,
        },
      }),

      // Status
      columnHelper.accessor((creditNote) => getCreditnoteStatus(creditNote), {
        id: ColumnIds.creditNotesStatus,
        header: t('creditnotes:overview.columns.status.title'),
        cell: ({ getValue, row: { original: creditNote } }) => (
          <CoDocumentStatus small status={getCreditnoteStatus(creditNote)}>
            {t(`creditnotes:overview.columns.status.options.${getValue()}`)}
          </CoDocumentStatus>
        ),
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
        meta: {
          styles: { size: 'max-content', minSize: 120, justify: 'center' },
          mobileStyles: { size: 'max-content', minBreakpoint: 'sm' },
        },
      }),

      // Total
      columnHelper.accessor(({ creditNoteLegacy }) => creditNoteLegacy.totalFcExclVat ?? 0, {
        id: ColumnIds.creditNotesTotal,
        header: t('creditnotes:overview.columns.total'),
        cell: ({ getValue }) => <span className="lg:truncate">{formatCurrency(getValue())}</span>,
        invertSorting: true, // Because credit notes have negative values, it's more intuitive to consider the highest value as the lowest and vice versa
        enableGlobalFilter: false,
        meta: {
          styles: { size: 'max-content', minSize: 110, justify: 'end' },
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: creditNote } }) => <CreditNotesTableOptions creditNote={creditNote} />,
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [clients, formatCurrency, location.pathname, t, user],
  );
};

export const useCreditNotesTableFilterOptions = (creditNotes: CreditNote[]) => {
  const { data: user } = useUser();
  const { data: clients } = useClients();

  const { t } = useTranslation(['creditnotes', 'filters']);
  const { compareForSort } = useIntl();

  const clientOptions = useMemo(
    () =>
      [...new Set(creditNotes.map(({ clientId }) => clientId))]
        .map((clientId) => {
          const clientName = clients.find(({ id }) => id === clientId)?.name ?? '';
          return { label: clientName, value: clientName };
        })
        .sort((a, b) => compareForSort(a.label, b.label)),
    [clients, compareForSort, creditNotes],
  );

  const brandOptions = useMemo(
    () =>
      [...new Set(creditNotes.map(({ brandId }) => brandId))]
        .map((brandId) => {
          if (brandId === 0) return { label: user.companyName, value: 0 };

          const brand = user.brands.find((brand) => brand.id === brandId);

          if (!brand) return null;

          return { label: brand.companyName, value: brand.id };
        })
        .filter(filterBoolean)
        .sort((a, b) => compareForSort(a.label, b.label)),
    [compareForSort, creditNotes, user.brands, user.companyName],
  );

  const docYearOptions = useMemo(
    () =>
      [...new Set(creditNotes.map(({ docYear }) => docYear))]
        .sort((a, b) => b - a)
        .map((docYear) => ({ label: `${docYear}`, value: docYear })),
    [creditNotes],
  );

  const docQuarterOptions = useMemo(
    () =>
      [...new Set(creditNotes.map(({ docDate, docYear }) => `${docYear}Q${dayjs(docDate).quarter()}`))]
        .map((quarter) => ({ year: +quarter.split('Q')[0], quarter: +quarter.split('Q')[1] }))
        .sort((a, b) => b.year - a.year || b.quarter - a.quarter)
        .map(({ quarter, year }) => ({
          label: t('filters:quarter.option', { year, quarter }),
          value: `${year} ${quarter}`,
        })),
    [creditNotes, t],
  );

  const statusOptions = useMemo(
    () =>
      [CreditnoteStatus.PENDING, CreditnoteStatus.APPROVED].map((status) => ({
        label: t(`creditnotes:overview.columns.status.options.${status}`),
        value: status,
      })),
    [t],
  );

  return useMemo(
    () => ({
      clients: clientOptions,
      brands: brandOptions,
      docYears: docYearOptions,
      docQuarters: docQuarterOptions,
      statuses: statusOptions,
    }),
    [brandOptions, clientOptions, docQuarterOptions, docYearOptions, statusOptions],
  );
};
