import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '~/components';

import type { DeleteSectionModalProps as Props } from './types';

import { useSectionContext } from '../Section/context';

export const DeleteSectionModal = ({ onClose }: Props) => {
  const { sectionIndex, sectionsFieldArray } = useSectionContext();
  const { t } = useTranslation(['common', 'lines']);

  const deleteSection = useCallback(() => {
    sectionsFieldArray.remove(sectionIndex);
  }, [sectionIndex, sectionsFieldArray]);

  return (
    // Hidden <tr> for valid DOM nesting (as the modal renders a <div> backdrop)
    <tr className="h-0">
      <td>
        <Modal onClose={onClose}>
          <Modal.Title>{t('lines:photoAlbum.deleteModal.title')}</Modal.Title>

          <p>{t('lines:photoAlbum.deleteModal.description')}</p>

          <Modal.Actions>
            <Button icon="Delete" onClick={deleteSection}>
              {t('common:delete')}
            </Button>
          </Modal.Actions>
        </Modal>
      </td>
    </tr>
  );
};
