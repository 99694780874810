import { z } from 'zod';

import { colorThemes } from './constants';

export const contractPdfSchema = z.string();

export const colorThemeSchema = z.enum(colorThemes);

export const accountantSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
});

export const billitAuthorizationUrlSchema = z.object({
  redirectUrl: z.string(),
});

export const billitSsoUrlSchema = z.object({
  redirectUrl: z.string(),
});
