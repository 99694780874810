export const validation = {
  client: {
    emails: {
      eachTypeSelected: 'Duid minstens één e-mailadres aan voor elk type e-mail',
      invalid: 'Vul enkel geldige e-mailadressen in.',
      noEmptySelection: 'Selecteer minstens één type e-mail per e-mailadres',
      required: 'Vul minstens één e-mailadres in.',
    },
  },
  dateTomorrow: 'De vervaldatum moet minstens morgen zijn.',
  email: {
    invalid: 'Vul een geldig e-mailadres in.',
  },
  iban: 'Vul een geldig IBAN nummer in.',
  integrations: {
    accountantEmailInvalid: 'Jouw accountant heeft nog geen account op ons Credit-platform.',
  },
  invitations: {
    noDoubleInvite: 'Je hebt al een uitnodiging naar dit e-mailadres verstuurd.',
    noSelfInvite: 'Je kan jezelf niet uitnodigen!',
  },
  invoice: {
    lines: {
      minimumOne: 'Voeg tenminste één factuurlijn toe aan de factuur.',
      minimumOneNotFree: 'Voeg minstens één factuurlijn toe die niet gratis is.',
    },
  },
  max: '{{attribute}} mag niet groter zijn dan {{max}}.',
  maxDecimals: '{{attribute}} mag niet meer dan {{max}} cijfers na de komma bevatten.',
  maxDiscount: 'De korting mag niet groter zijn dan de totale prijs.',
  maxFileSize: 'Het bestand mag niet groter zijn dan {{max}} MB.',
  maxLength: '{{attribute}} mag niet groter zijn dan {{max}} karakters.',
  maxLengthInLanguage: '{{attribute}} mag niet groter zijn dan {{max}} karakters ({{lang}}).',
  min: '{{attribute}} mag niet kleiner zijn dan {{min}}.',
  minLength: '{{attribute}} moet groter zijn dan {{min}} karakters.',
  password: {
    invalid: 'Dit wachtwoord moet exact hetzelfde zijn als het nieuwe wachtwoord.',
  },
  phoneNumber: {
    format: 'Vul een geldig telefoonnummer in.',
    missingCallingCode: 'Telefoonnummer moet starten met een landcode (bv. +32 in België).',
  },
  quotation: {
    entrynumber: {
      invalid: 'Dit offertenummer werd al gebruikt voor een vorige offerte.',
    },
    lines: {
      minimumOne: 'Voeg minstens één offertelijn toe aan de offerte.',
      minimumOneNotFree: 'Voeg minstens één offertelijn toe die niet gratis is.',
    },
  },
  required: 'Dit veld is verplicht.',
  requiredAttribute: '{{attribute}} is verplicht.',
  requiredInLanguage: 'Dit veld is verplicht ({{lang}}).',
  signContract: {
    message: 'Vul "Gelezen en goedgekeurd" in',
  },
  vatNumber: {
    belgianFormat: 'Geef je een geldig ondernemingsnummer in? Deze bestaat uit 10 cijfers en start met 0 of 1.',
    inactive: 'Deze organisatie is niet actief.',
    invalid: 'Het btw-nummer is ongeldig, vraag je het nog eens op?',
    unique:
      'Je hebt al een klant aangemaakt met dit btw-nummer. <0>Klik hier</0> om een nieuwe contactpersoon toe te voegen, aan deze klant.</0>',
  },
};
