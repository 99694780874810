import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useCreateBrand } from '~/api/user';

import type { CreateEditBrandFormType, CreateBrandModalProps as Props } from './types';

import { CreateEditBrandModal } from './CreateEditBrandModal';
import { convertFileToBase64String } from './utils';

export const CreateBrandModal = ({ onClose }: Props) => {
  const mutation = useCreateBrand();

  const { t } = useTranslation(['common', 'settings']);

  const onSubmit = async (data: CreateEditBrandFormType) => {
    return mutation.mutate(
      {
        companyName: data.companyName,
        email: data.email,
        color: data.color,
        logo: data.logo ? await convertFileToBase64String(data.logo) : '',
      },
      {
        onSuccess: () => {
          toast.success(t('settings:alerts.brands.successCreated'));
          onClose();
        },
        onError: () => {
          toast.error(t('common:error'));
        },
      },
    );
  };

  return <CreateEditBrandModal brand={null} isLoading={mutation.isPending} onClose={onClose} onSubmit={onSubmit} />;
};
