import { useTranslation } from 'react-i18next';

import { Button } from '~/components';

import { useTableContext } from '../context';

export const TableFiltersToggle = () => {
  const { getState, toggleColumnFilters } = useTableContext();

  const { t } = useTranslation(['common']);

  const isOpen = !!getState().showColumnFilters;

  return (
    <Button extraClasses="max-lg:!px-3" icon="Tune" onClick={toggleColumnFilters} type="secondary">
      <span className="max-lg:hidden">{isOpen ? t('common:datatable.hideFilters') : t('common:datatable.showFilters')}</span>
    </Button>
  );
};
