import { IconTooltip } from '~/components';
import { useIntl } from '~/hooks/useIntl';

import type { CopyrightStatBlockProps as Props } from './types';

import styles from './CopyrightStatBlock.module.scss';

export const CopyrightStatBlock = ({ label, tooltip, value }: Props) => {
  const { formatCurrency } = useIntl();

  return (
    <article className={styles.Wrapper}>
      <span className={styles.Icon}>
        <IconTooltip color="text-primary-200" iconName="Info" text={tooltip} />
      </span>

      <header className={styles.Header}>
        <span>{label}</span>
      </header>

      <span className={styles.Value}>{formatCurrency(value)}</span>
    </article>
  );
};
