import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const Person1 = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="Referral 1"
      viewBox="305.08 176.1 337.94 333.4"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <g>
        <g>
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M479.47,450.16c-1.9-2.39-5.97-2.69-8.51.48-2.65,3.31-.37,7.43,2.64,8.67-2.28,2.43-4.78,4.17-7.29,5.26-2.86.11-6.03.45-9.4,1.01-2.08-.51-3.26-1.75-3.9-3.23-.03-.08-.06-.16-.1-.24,2.42-3.11,4.34-7.19,5.76-11.01h0c1.38-3.69,2.3-7.12,2.78-9.17.58-2.52,1.11-7.5.69-12.65,3.08-2.36,7.53-4.7,11.38-2.59,6.16,3.39,9.91,13.5,5.94,23.47Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M461.46,441.94c-.48,2.05-1.4,5.48-2.78,9.17-.99-3.3-4.61-6.25-8.99-3.53-5.74,3.56-4.75,11.97.1,13.95,1.18.48,2.15.72,2.96.81-1.52,1.9-3.23,3.41-5.14,4.24-5.91,2.55-10.66-.44-12.46-4.56.2-.47.41-.96.61-1.47,1.69-4.26,3.24-9.69,4.37-15.37,1.3-6.59,2.03-13.52,1.74-19.36-.16-3.18-.53-6.05-1.1-8.57h0c3.07-2.83,8.63-6.5,14.35-3.21,4.69,2.7,6.53,9.09,7.03,15.25.42,5.15-.11,10.13-.69,12.65Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M440.13,445.17c-.71-1.11-2-1.93-4.29-1.75-6.23.5-9.5,7.42-7.22,12.86,1.17,2.81,3.77,3.82,6.01,4.15.4.06.78.09,1.14.11-.2.51-.41,1-.61,1.47-.88,2.03-1.79,3.72-2.68,4.95-5.59,7.71-14.68,5.24-18.17,3.32,0-.02.01-.04.02-.06,1.48-5.61,2.05-12.55,2.02-19.87,0-3.73-.17-7.56-.45-11.36-.67-9.24-2.85-17.84-6.82-23.19,7.88-9.5,16.34-14.07,24.88-10,3.1,1.47,5.49,5.47,6.82,11.44h0c.56,2.52.94,5.39,1.1,8.57.29,5.84-.44,12.77-1.74,19.36Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M642.56,390.37l-30.13,101.73h-129.41l29.04-98.05c2.3-7.78,8.1-13.79,15.36-16.54,2.14-.81,4.41-1.34,6.75-1.54.74-.06,1.48-.1,2.23-.1h95.31c7.55,0,12.98,7.27,10.84,14.51Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M414.24,470.25s.04.02.06.04c-1.47,5.57-3.84,9.82-7.42,11.83-.32,2.27-.83,4.93-1.62,7.66-1.39,4.77-3.66,9.77-7.38,13.4-1.33,1.3-2.84,2.42-4.55,3.29-9.89,5.01-20.27,3.75-28.28-3.29-2.35-2.07-4.5-4.63-6.37-7.68,0,0,0,0,0,0-2-4.15-3.32-9.44-3.72-16.54-.36-6.31,1-14.33,3.76-22.69,5.01-15.13,14.66-31.4,27.2-40.74,10.74-7.99,18.29-6.3,23.15.28,3.96,5.35,6.14,13.95,6.82,23.19.27,3.8.44,7.63.45,11.36-3.07-2.12-7.07-2.01-10.19,1.18-4.46,4.55-4.02,14.19,2,17.93,2.24,1.39,4.34,1.37,6.09.79Z"
          />
          <polygon
            {...stylex.props(styles.fillBlack)}
            points="588.56 429.69 584.64 445.4 576.78 445.4 578.74 437.54 570.89 437.54 568.93 445.4 561.07 445.4 564.99 429.69 588.56 429.69"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M615.41,308.66c-1.42,2.86-4.3,5.87-7.86,8.79,0,0,0,0,0,0-8.01,4.37-18.61,7.78-27.43,10.13h0c-5.18,1.39-9.74,2.41-12.79,3.05-3.37-.13-6.55.23-9.39.82-1.01,1.65-1.86,3.83-1.9,6.47-.12,6.68,3.76,13.36,13.6,11.78,7.81-1.25,11.03-7.33,11.17-13.22,2.92,5.31-.2,12.92-4.13,16.74-1.55,1.51-3.44,2.86-5.64,4.08-3.74,2.08-8.36,3.77-13.65,5.18-2.07.55-4.23,1.06-6.5,1.53-1.06,2.46-1.77,6.37.04,11.85h-14.51c-.75,0-1.49.03-2.23.1-2.35.21-4.61.73-6.75,1.54-1.47-4.43-2.72-10.36-2.58-17.45h0c.1-4.95.88-10.45,2.74-16.41,9.68-31.02,44.78-42.26,65.46-47.12,18.1-4.25,27.69,1.34,22.35,12.14Z"
          />
          <path {...stylex.props(styles.stroke)} d="M550.88,364.01c-1.26.26-2.54.51-3.85.75" />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M580.79,336.49c-.14,5.89-3.36,11.97-11.17,13.22-9.84,1.58-13.73-5.1-13.6-11.78.05-2.65.9-4.82,1.9-6.47,2.85-.59,6.02-.95,9.39-.82,1.5.06,3.04.21,4.61.49,3.39.59,5.78,1.8,7.37,3.38.61.61,1.11,1.28,1.5,1.98Z"
          />
          <path {...stylex.props(styles.stroke)} d="M557.93,331.45c-7.7,1.59-12.96,4.89-12.96,4.89" />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M607.54,317.45c-5.61,4.6-12.9,8.97-18.73,12.16-3.45,1.89-6.4,3.37-8.17,4.24-.59.29-1.05.51-1.36.65-1.59-1.58-3.97-2.79-7.37-3.38-1.57-.27-3.11-.43-4.61-.49,3.05-.64,7.61-1.66,12.79-3.05h0c8.81-2.36,19.42-5.77,27.43-10.13Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M598.41,349.81c-2.05,3.56-5.38,7.56-10.21,12.04-2.7,2.51-5.25,4.53-7.63,6.12-3.42,2.3-6.5,3.73-9.19,4.5-5.87,1.67-9.93.21-11.84-2.24,3.42.57,7.71-.42,11.25-4.62,3.37-4,1.97-6.73.24-8.31,2.19-1.22,4.09-2.57,5.64-4.08,3.93-3.83,7.05-11.44,4.13-16.74-.39-.71-.88-1.37-1.5-1.98.31-.15.77-.37,1.36-.65,1.77-.87,4.71-2.35,8.17-4.24,3.1.93,5.86,2.07,7.68,3.46,4.89,3.71,6.26,9.22,1.92,16.74Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M602.15,369.22c-1.18,1.79-2.69,3.64-4.6,5.51-.4.39-.79.77-1.18,1.13h-11.65c.11-1.23-.03-2.5-.41-3.68-.77-2.41-2.3-3.61-3.75-4.2,2.38-1.6,4.93-3.62,7.63-6.12,4.83-4.48,8.16-8.49,10.21-12.04,2.2.22,4.21.81,5.46,2.2,2.32,2.58,3.5,9.31-1.72,17.2Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M606,375.86h-9.63c.39-.36.79-.74,1.18-1.13,1.9-1.87,3.41-3.72,4.6-5.51,1.71,1.5,3.49,3.73,3.85,6.64Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M584.73,375.86h-13c-.25-1.18-.34-2.34-.35-3.38,2.69-.77,5.77-2.2,9.19-4.5,1.45.59,2.98,1.79,3.75,4.2.38,1.18.52,2.45.41,3.68Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M570.79,365.62c-3.54,4.19-7.83,5.19-11.25,4.62-1.93-2.48-2.25-5.55-2.16-7.74,5.29-1.41,9.91-3.1,13.65-5.18,1.74,1.58,3.14,4.31-.24,8.31Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M571.73,375.86h-20.8c-1.81-5.48-1.1-9.39-.04-11.85,2.26-.47,4.43-.98,6.5-1.53-.09,2.19.23,5.26,2.16,7.74,1.91,2.46,5.97,3.92,11.84,2.24,0,1.05.09,2.2.35,3.38Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M612.43,492.1v.57c0,5.8-4.7,10.51-10.51,10.51h-196.86v-1.73c0-5.17,4.19-9.36,9.36-9.36h198.01Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            d="M502.53,333.04c-3.68,5-9.66,8.86-19.5,9.7-2.8.24-5.35.16-7.68-.17-10.26-1.44-16.21-7.68-19.33-12.43,5.91-3.58,11.32-6.3,16.35-7.89,8.55-2.7,19.04,1,30.16,10.79Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M527.43,377.5c-7.27,2.75-13.06,8.77-15.36,16.54l-29.04,98.05h-31.11l-.15-1.01c2.81-.44,5.59-1.18,8.02-2.06.85-.3,1.71-.62,2.57-.94,7.87-2.95,16.29-6.92,18.7-14.59,2.09-6.67-4.33-9.33-14.74-8.93,2.51-1.09,5-2.83,7.29-5.26,1.75-1.86,3.37-4.12,4.78-6.79.41-.78.77-1.57,1.09-2.36,3.97-9.96.22-20.07-5.94-23.47-3.84-2.11-8.3.23-11.38,2.59-.49-6.16-2.34-12.55-7.02-15.25-5.73-3.29-11.28.38-14.35,3.2-1.33-5.96-3.72-9.96-6.82-11.43-8.54-4.06-17.01.5-24.88,10-4.87-6.58-12.41-8.27-23.15-.28-12.54,9.34-22.19,25.6-27.2,40.74-1.6,1.32-3.19,2.95-4.76,4.95-16.23,20.73-3.77,41.12-3.77,41.12-19.76-13.32-44.31-40.52-40.18-60.6,2.27-11.04,30.7-31.32,62.63-53.17,1.13-.02,2.27-.14,3.41-.35,12.66-2.37,17.57-7.88,17.57-7.88,0,0,18.48,11.04,24.2-2.09,3.35-7.69.83-14.03-4.32-18.02,11.9-8.64,22.33-16.54,31.63-22.97,5.09,7.5,15.95,20.22,30.23,18.39v-13.09c2.33.33,4.89.41,7.68.17,9.85-.84,15.82-4.7,19.5-9.7,7.3,6.41,14.86,15.44,22.33,27h0c-.14,7.1,1.11,13.03,2.58,17.46Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M365.04,503.18h-13.6c-.42-.27-.84-.55-1.26-.83,0,0-12.46-20.39,3.77-41.12,1.57-2,3.16-3.64,4.76-4.95-2.77,8.36-4.12,16.38-3.76,22.69.4,7.09,1.72,12.38,3.72,16.54,0,0,0,0,0,0,1.87,3.05,4.02,5.61,6.37,7.68Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M475.34,342.57v13.09c-14.28,1.83-25.15-10.89-30.23-18.39-2.07-3.04-3.18-5.23-3.18-5.23l11.16-7.6s.82,2.52,2.91,5.7c3.12,4.76,9.08,10.99,19.33,12.43Z"
          />
          <path
            {...stylex.props(styles.stroke)}
            d="M379.92,448.49c.17,1.23.48,3.14.96,5.44,2.36,11.26,8.83,31.97,23.01,29.27,1.07-.2,2.07-.57,2.98-1.08"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M440.13,445.17c-1.13,5.69-2.68,11.12-4.37,15.37-.36-.02-.75-.06-1.14-.11-2.24-.33-4.83-1.34-6.01-4.15-2.28-5.44.99-12.37,7.22-12.86,2.29-.18,3.58.64,4.29,1.75Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M458.68,451.11h0c-1.43,3.82-3.35,7.9-5.76,11.01-.06.07-.11.14-.17.21-.81-.08-1.78-.33-2.96-.81-4.85-1.98-5.84-10.39-.1-13.95,4.38-2.72,8,.23,8.99,3.53Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M479.47,450.16c-.31.79-.68,1.57-1.09,2.36-1.41,2.68-3.03,4.93-4.78,6.79-3.01-1.25-5.29-5.36-2.64-8.67,2.54-3.17,6.61-2.87,8.51-.48Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M481.05,473.5c-2.4,7.66-10.83,11.63-18.7,14.59.58-.72,1.05-1.8,1.18-3.88.4-6.19-4.51-8.13-9.2-7.27-6.37,1.17-8.11,9.81-4.55,12.98.59.52,1.26.9,1.97,1.17-8.25,1.28-16.82-.08-18-8.49-.03-.23-.06-.46-.08-.69-.89-9.04,6.38-13.55,23.23-16.33,3.37-.56,6.54-.9,9.4-1.01,10.41-.41,16.83,2.25,14.74,8.92Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M463.54,484.21c-.13,2.08-.61,3.16-1.18,3.88-.87.33-1.73.64-2.58.95-2.43.88-5.21,1.62-8.02,2.06-.71-.26-1.38-.64-1.97-1.17-3.56-3.17-1.82-11.81,4.55-12.98,4.69-.86,9.6,1.08,9.2,7.27Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M416.34,450.36c.02,7.32-.55,14.26-2.02,19.87-.02.01-.05.02-.08.03-1.75.58-3.85.61-6.09-.79-6.02-3.74-6.46-13.38-2-17.93,3.12-3.19,7.12-3.29,10.19-1.18Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M451.76,491.09l.15,1.01h-27.54c-5.17,0-9.35,4.19-9.35,9.36v1.72h-17.14c3.72-3.63,5.99-8.63,7.37-13.4.8-2.74,1.31-5.39,1.62-7.66,3.58-2.01,5.95-6.26,7.42-11.83,3.5,1.91,12.59,4.38,18.17-3.32.89-1.23,1.8-2.93,2.68-4.95,1.8,4.12,6.55,7.11,12.46,4.56,1.91-.82,3.62-2.34,5.14-4.24.09,0,.18.02.26.02.64,1.47,1.82,2.71,3.9,3.23-16.85,2.77-24.12,7.28-23.23,16.32.02.23.05.46.08.69,1.18,8.41,9.75,9.77,18,8.49Z"
          />
          <path {...stylex.props(styles.stroke)} d="M523.46,392.6c1.61-3.21,3.86-8.21,12.7-9.81,8.84-1.6,91.61-.74,96.43-.61" />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M417.8,378.26c-5.72,13.13-24.2,2.09-24.2,2.09,0,0-4.9,5.5-17.57,7.88-1.14.21-2.28.32-3.41.35,7.42-5.07,15.03-10.23,22.53-15.38,6.48-4.44,12.57-8.78,18.32-12.96,5.14,3.99,7.66,10.33,4.32,18.02Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M363.11,348s-5.62-9.18-30.31-6.01c-24.69,3.17-29.92,15.03-8.87,19.15,0,0-23.89,3.01-17.88,14.08,6.01,11.08,46.53-2.02,46.53-2.02,0,0,12.34,10.25,25,7.88s17.57-7.88,17.57-7.88c0,0,18.48,11.04,24.2-2.09,5.72-13.14-5.68-22.31-18.02-22.63-12.34-.32-17.23,5.22-17.23,5.22,0,0,.77-8.86-7.3-12.66s-13.69,6.96-13.69,6.96Z"
          />
        </g>
        <g>
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M483.57,298.21c-23.76,7.24-49.71-11.28-58.96-39.04,0,0,24.07,26.64,58.41,13.89,27.82-10.33,30.01-36.72,30.07-37.54,6.04,27.31-6.63,55.72-29.53,62.7Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M511.84,230.73c-.2-.66-.41-1.31-.63-1.95.44,9.11-3.77,16.8-10.17,17.84-2.6.42-5.2-.31-7.54-1.92-4.08-2.8-7.35-8.25-8.42-14.87-.31-1.91-.42-3.78-.33-5.58.35-8.01,4.36-14.45,10.18-15.39,1.71-.28,3.41-.05,5.05.58.33.13.65.27.96.43-.28-.35-.56-.7-.85-1.04-6.37-7.6-14.34-13.18-22.91-16.09-1.26,5.92-.3,11.08-.3,11.08-5.81,3.39-11.42,6.85-16.78,10.4-15.36,10.19-28.6,21.14-38.2,33.42.46,3.26,1.18,6.55,2.18,9.83.17.56.35,1.12.54,1.68,0,0,24.07,26.64,58.41,13.89,27.82-10.33,30.01-36.72,30.07-37.54h0c-.35-1.6-.77-3.2-1.25-4.78ZM465.58,255.48c-2.14.35-4.29-.09-6.28-1.15-2.82-1.5-5.34-4.25-7.15-7.8-1.18-2.31-2.06-4.96-2.52-7.83-.08-.49-.14-.97-.2-1.45-1.06-9.72,3.15-18.21,9.8-19.47.08-.02.16-.03.24-.04,2.57-.42,5.15.3,7.47,1.88,2.5,1.69,4.7,4.38,6.28,7.72,1.02,2.15,1.79,4.58,2.21,7.19,1.69,10.43-2.72,19.81-9.84,20.97Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M465.58,255.48c-2.14.35-4.29-.09-6.28-1.15l7.64-34.73c2.5,1.69,4.7,4.38,6.28,7.72,1.02,2.15,1.79,4.58,2.21,7.19,1.69,10.43-2.72,19.82-9.84,20.97Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M465.58,255.48c-2.14.35-4.29-.09-6.28-1.15l7.64-34.73c2.5,1.69,4.7,4.38,6.28,7.72,1.02,2.15,1.79,4.58,2.21,7.19,1.69,10.43-2.72,19.82-9.84,20.97Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M511.21,228.77h0c.44,9.12-3.77,16.8-10.17,17.85-2.6.42-5.2-.31-7.54-1.92l6.47-35.26c.32.13.65.27.96.43h0c4.77,2.39,8.75,8.34,9.95,15.77.17,1.05.28,2.1.33,3.12Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M499.97,209.44l-6.47,35.26c-4.07-2.8-7.35-8.25-8.42-14.87-.31-1.91-.42-3.78-.33-5.58.35-8.01,4.36-14.45,10.18-15.39,1.71-.28,3.41-.06,5.05.58Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M466.93,219.6l-7.64,34.73c-2.82-1.5-5.34-4.25-7.15-7.8-1.18-2.31-2.06-4.96-2.52-7.83-.08-.49-.14-.97-.2-1.45-1.06-9.72,3.15-18.21,9.8-19.47.08-.02.16-.03.24-.04,2.57-.42,5.15.3,7.47,1.88Z"
          />
          <path {...stylex.props(styles.stroke)} d="M513.1,235.49s0,0,0,.02" />
          <line {...stylex.props(styles.stroke)} x1="449.42" x2="449.42" y1="237.23" y2="237.23" />
          <path
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            d="M500.93,209.87c-.28-.35-.57-.7-.85-1.04-6.37-7.6-14.34-13.18-22.91-16.09,1.16-5.47,4.23-11.59,11.71-14.89,6.77-2.99,16.8-2.49,19.08,4.22,2.51,7.37-3.22,21.43-7.03,27.8Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M488.87,177.86c-7.47,3.3-10.54,9.42-11.71,14.89-1.26,5.92-.3,11.08-.3,11.08-5.81,3.39-11.42,6.85-16.78,10.41-15.36,10.19-28.61,21.14-38.2,33.42-.11.15-.23.29-.34.44l-7.98-3.33c2.62-3.15,5.23-6.26,7.82-9.3h0c8.39-9.86,16.56-19.05,24.35-27.08,6.76-6.95,13.22-13.03,19.3-17.91h0c1.64-1.31,3.25-2.54,4.83-3.67,6.98-5,13.37-8.17,18.99-8.96Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M469.88,186.81c-1.58,1.13-3.19,2.36-4.83,3.67h0c-6.08,4.87-12.55,10.95-19.3,17.91-7.79,8.02-15.97,17.22-24.35,27.07h0c-2.59,3.04-5.2,6.15-7.82,9.3,0,0-5.66-31.58,9.22-45.86,14.89-14.27,24.21-13.26,29.99-13.54,4.12-.2,12.56.83,17.1,1.45Z"
          />
        </g>
      </g>
    </svg>
  );
};
