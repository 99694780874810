import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  linkButton: {
    fontSize: fontSize.small,
    fontWeight: fontWeight.regular,
  },
});
