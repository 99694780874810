import { useEffect, useId } from 'react';

import { useStableRef } from '~/hooks/useStableRef';

import type { TMenuItem } from './CommandMenuProvider.context';

import { useCommandMenuContext } from './CommandMenuProvider.context';

type Props = Omit<TMenuItem, 'id'>;

/**
 * Add an item to the command menu.
 *
 * This component itself doesn't render anything, because the MenuItem needs to be a child of MenuItems.
 * Instead, it adds an item to the items stored in context, and removes it on unmount.
 */
export const CommandMenuItem = ({ group, label, onClick, shortcut }: Props) => {
  const id = useId();
  const { addMenuItem, removeMenuItem, updateMenuItem } = useCommandMenuContext();

  const stableOnClick = useStableRef(onClick);

  useEffect(
    () => {
      addMenuItem({ id, group, label, shortcut, onClick: stableOnClick.current });
      return () => removeMenuItem(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id],
  );

  useEffect(() => {
    updateMenuItem({ id, group, label, shortcut, onClick: stableOnClick.current });
  }, [group, id, label, shortcut, stableOnClick, updateMenuItem]);

  return null;
};
