import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import invariant from 'tiny-invariant';

import { Filters } from '~/components/UI';

import type { TableFilterProps as Props } from './types';

import { Combobox } from '../../Form_old/FormFields/Combobox/Combobox';
import { MultiSelect } from '../../Form_old/FormFields/MultiSelect/MultiSelect';
import { Select } from '../../Form_old/FormFields/Select/Select';
import { useTableContext } from '../context';
import { useTableFiltersContext } from './context';

export const TableFilter = ({ columnId, type }: Props) => {
  const options = useTableFiltersContext()[columnId];
  invariant(options, `Missing filter options for column ${columnId}`);

  const { getColumn } = useTableContext();
  const column = useMemo(() => getColumn(columnId), [columnId, getColumn]);
  invariant(column, `Column ${columnId} does not exist on this table.`);

  const { t } = useTranslation(['filters']);

  const filterValue = column.getFilterValue();

  return (
    <Filters.Group>
      <Filters.Label htmlFor={columnId}>{t(`filters:${columnId}.label`)}</Filters.Label>
      {type === 'select' ? (
        <Select
          clearable
          id={columnId}
          onChange={column.setFilterValue}
          options={options}
          placeholder={t(`filters:${columnId}.placeholder`)}
          value={filterValue ?? null}
        />
      ) : type === 'combobox' ? (
        <Combobox
          id={columnId}
          onChange={column.setFilterValue}
          options={options}
          placeholder={t(`filters:${columnId}.placeholder`)}
          value={filterValue ?? null}
        />
      ) : (
        <MultiSelect
          id={columnId}
          onChange={column.setFilterValue}
          options={options}
          placeholder={t(`filters:${columnId}.placeholder`)}
          value={Array.isArray(filterValue) ? filterValue : []}
        />
      )}
    </Filters.Group>
  );
};
