import * as amplitude from '@amplitude/analytics-browser';
import { useId } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { match, Pattern } from 'ts-pattern';

import { useCheckVatNumberInUse, useFetchVatInformation } from '~/api/auth';
import { FetchError, HttpError, TooManyRequestsError } from '~/api/errors';
import { Form, FormField, FormInput, FormValidationError, Label, ValidationError } from '~/components/Form';
import { Alert, AlertDescription, AlertTitle, Button, ButtonGroup, ExternalLink, Flex } from '~/components/UI';
import { AmplitudeEventName, AmplitudeEventProperty } from '~/constants/amplitude';
import { env } from '~/constants/env';
import { SearchParamKeys } from '~/constants/url';
import { routes } from '~/providers/RouterProvider/router.routes';
import { checkEnterpriseNumberChecksum, cleanEnterpriseNumber } from '~/utils/string';

import { AuthLayoutContent } from '../AuthLayout/AuthLayoutContent';
import { AuthLayoutTitleGroup } from '../AuthLayout/AuthLayoutTitleGroup';
import { AuthLink } from '../AuthLink/AuthLink';
import { useRegistrationContext } from './Registration.context';
import { RegistrationAddReferralCodeButton } from './RegistrationReferralCode/RegistrationAddReferralCodeButton';
import { RegistrationReferralCode } from './RegistrationReferralCode/RegistrationReferralCode';
import { styles } from './RegistrationVatInformation.styles';

export const RegistrationVatInformation = () => {
  const fetchVatInformationMutation = useFetchVatInformation();
  const checkVatNumberInUseMutation = useCheckVatNumberInUse();

  const { hadReferralCode, setVatInformation, vatInformation } = useRegistrationContext();
  const [searchParams] = useSearchParams();

  const { control, formState, handleSubmit, setError } = useForm<{ vatNumber: string }>({
    defaultValues: {
      vatNumber: vatInformation?.vatNumber.slice(2) || searchParams.get(SearchParamKeys.REGISTRATION_ENTERPRISE_NUMBER) || '',
    },
  });
  const formId = useId();

  const navigate = useNavigate();
  const { t } = useTranslation(['auth', 'common', 'validation']);

  const onSubmit = handleSubmit((data) => {
    const vatNumber = `BE${cleanEnterpriseNumber(data.vatNumber)}`;

    fetchVatInformationMutation.mutate(vatNumber, {
      onSuccess: (vatInformation) => {
        if (!vatInformation.vatValid) return setError('vatNumber', { type: 'validVatNumber' });
        if (!vatInformation.vatActive) return setError('vatNumber', { type: 'activeVatNumber' });

        setVatInformation({ ...vatInformation, fetchedAt: Date.now() });

        const isCompany = vatInformation.enterpriseType !== 0;

        if (isCompany) {
          return navigate(routes.registerCompany);
        }

        amplitude.track(AmplitudeEventName.RegistrationStep1Completed, {
          [AmplitudeEventProperty.RegistrationEligible]: vatInformation.hasEligibleNacebelCodes,
        });

        navigate(routes.registerAccountInformation);
      },
    });
  });

  return (
    <AuthLayoutContent>
      <AuthLayoutTitleGroup
        pill={t('auth:registrationPage.common.header.pill')}
        subtitle={t('auth:registrationPage.stepVatNumber.header.subtitle')}
        title={t('auth:registrationPage.stepVatNumber.header.title')}
      />

      {env.KBO_UNAVAILABLE && (
        <Alert variant="warning">
          <AlertTitle>{t('auth:registrationPage.stepVatNumber.alert.kboNotAvailable.title')}</AlertTitle>
          <AlertDescription>
            <Trans components={{ break: <br /> }} i18nKey="auth:registrationPage.stepVatNumber.alert.kboNotAvailable.description" />
          </AlertDescription>
        </Alert>
      )}

      <Flex autoAnimate direction="column" gap="normal">
        <Form id={formId} onSubmit={onSubmit}>
          <FormField
            control={control}
            name="vatNumber"
            rules={{
              required: true,
              validate: {
                whitespaceOnly: (value) => !!value.trim(),
                vatNumberLength: (value) => /^(?:0?[1-9]\d{8}|1\d{9})$/.test(value.replace(/\D/g, '')),
                vatNumberChecksum: (value) => {
                  const enterpriseNumber = cleanEnterpriseNumber(value);
                  const isDummyEnterpriseNumber = enterpriseNumber === '0123456789' && !env.IS_PRODUCTION_ENV;

                  return checkEnterpriseNumberChecksum(enterpriseNumber) || isDummyEnterpriseNumber;
                },
                unique: async (value) => {
                  try {
                    const { inUse } = await checkVatNumberInUseMutation.mutateAsync(`BE${cleanEnterpriseNumber(value)}`);
                    return !inUse;
                  } catch {
                    return false;
                  }
                },
              },
            }}
          >
            <Label
              tooltip={
                <Trans
                  components={{ faqLink: <ExternalLink styles={styles.tooltipLink} /> }}
                  i18nKey="auth:registrationPage.stepVatNumber.form.vatNumberField.description"
                />
              }
            >
              {t('auth:registrationPage.stepVatNumber.form.vatNumberField.label')}
            </Label>

            <FormInput
              afterOnChange={() => fetchVatInformationMutation.isError && fetchVatInformationMutation.reset()}
              autoFocus
              inputMode="decimal"
              prefix="BE"
              transformValue={(value) => (value.trimStart().toUpperCase().startsWith('BE') ? value.trimStart().slice(2) : value)}
            />

            <FormValidationError
              required={t('validation:required')}
              validate-activeVatNumber={t('auth:registrationPage.stepVatNumber.form.vatNumberField.errors.inactiveVatNumber')}
              validate-unique={
                checkVatNumberInUseMutation.isError
                  ? match(checkVatNumberInUseMutation.error)
                      .with(Pattern.instanceOf(TooManyRequestsError), () => t('common:errors.tooManyRequests'))
                      .with(Pattern.instanceOf(HttpError), () => (
                        <Trans
                          components={{ email: <ExternalLink /> }}
                          i18nKey="auth:registrationPage.stepVatNumber.form.vatNumberField.errors.httpError"
                        />
                      ))
                      .with(Pattern.instanceOf(FetchError), () => t('common:errors.fetchError'))
                      .otherwise(() => t('auth:registrationPage.stepVatNumber.form.vatNumberField.errors.fallback'))
                  : t('auth:registrationPage.stepVatNumber.form.vatNumberField.errors.exists')
              }
              validate-validVatNumber={t('auth:registrationPage.stepVatNumber.form.vatNumberField.errors.invalidVatNumber')}
              validate-vatNumberChecksum={t('auth:registrationPage.stepVatNumber.form.vatNumberField.errors.vatNumberChecksum')}
              validate-vatNumberLength={t('auth:registrationPage.stepVatNumber.form.vatNumberField.errors.vatNumberLength')}
              validate-whitespaceOnly={t('validation:required')}
            />

            {fetchVatInformationMutation.error && (
              <ValidationError>
                {match(fetchVatInformationMutation.error)
                  .with(Pattern.instanceOf(TooManyRequestsError), () => t('common:errors.tooManyRequests'))
                  .with(Pattern.instanceOf(HttpError), () => (
                    <Trans
                      components={{ email: <ExternalLink /> }}
                      i18nKey="auth:registrationPage.stepVatNumber.form.vatNumberField.errors.httpError"
                    />
                  ))
                  .with(Pattern.instanceOf(FetchError), () => t('common:errors.fetchError'))
                  .otherwise(() => t('auth:registrationPage.stepVatNumber.form.vatNumberField.errors.fallback'))}
              </ValidationError>
            )}
          </FormField>
        </Form>

        <RegistrationReferralCode />

        <ButtonGroup wrapReverse>
          <Button form={formId} loading={formState.isValidating || fetchVatInformationMutation.isPending} type="submit">
            {t('auth:registrationPage.stepVatNumber.form.submitButton')}
          </Button>

          {hadReferralCode && <RegistrationAddReferralCodeButton />}
        </ButtonGroup>
      </Flex>

      <AuthLink to="login" />
    </AuthLayoutContent>
  );
};
