import type { QueryKey } from '@tanstack/react-query';

import type { CommunityProfileFilters } from './community/types';

type QueryKeysFactory = Record<string, QueryKey | ((...args: any[]) => QueryKey)>;

export const QueryKeys = {
  // User
  user: ['user'] as const,
  userContract: ['user', 'contract'] as const,
  userContractPdf: ['user', 'contract', 'contractPdf'] as const,
  userContractAddendumPdf: ['user', 'contract', 'contractAddendumPdf'] as const,

  // Quotations
  quotations: ['quotations'] as const,
  quotationAttachment: (id: number) => ['quotations', id, 'attachment'] as const,
  quotationForClient: (allowKey: string) => ['quotations', allowKey] as const,
  quotationCalculation: (id: number) => ['quotations', id, 'calculationData'] as const,

  // Invoices
  invoices: ['invoices'] as const,
  invoiceAttachment: (id: number) => ['invoices', id, 'attachment'] as const,
  invoiceHistory: (id: number) => ['invoices', id, 'history'] as const,

  // Credit notes
  creditNotes: ['creditNotes'] as const,

  // Products
  products: ['products'] as const,

  // Clients
  clients: ['clients'] as const,

  // Community
  community: (filters: CommunityProfileFilters) => ['community', filters] as const,

  // Plans
  plans: ['plans'] as const,

  // Conditions
  conditions: ['conditions'] as const,
  conditionsPdf: (id: number) => ['conditions', id, 'pdf'] as const,
  customConditions: ['customConditions'] as const,

  // Application
  heartbeat: ['heartbeat'],
} satisfies QueryKeysFactory;
