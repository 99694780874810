export type InvoiceCalculations = {
  commission: number[];
  copyright: number[];
  netBenefit: number[];
  performance: number[];
};

export type InvoiceOverviewCalculations = {
  paidCurrentQ: InvoiceInfo;
  paidPreviousQ: InvoiceInfo;
  total: InvoiceInfo;
  unpaidExpired: InvoiceInfo;
  unpaidPending: InvoiceInfo;
};

type InvoiceInfo = {
  amount: number;
  value: number;
};

export enum AccountCompletionStepType {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
}
