import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { match, Pattern } from 'ts-pattern';

import { BusinessConflict, BusinessConflictError, FetchError, HttpError } from '~/api/errors';
import { useCreateInvitation, useUser } from '~/api/user';
import { Form, FormField, FormInput, FormListbox, FormValidationError, Label, ListboxOption, ValidationError } from '~/components/Form';
import { Button, ButtonGroup, Dialog, ExternalLink, Flex } from '~/components/UI';
import { EMAIL } from '~/constants/regex';
import { Language } from '~/types/app';

import type { SendInvitationFormType } from './SendInvitationDialog.types';

type Props = {
  onClose: () => void;
};

export const SendInvitationDialog = ({ onClose }: Props) => {
  const mutation = useCreateInvitation();
  const { data: user } = useUser();

  const { t } = useTranslation(['common', 'invitations', 'validation']);

  const { control, handleSubmit, setError } = useForm<SendInvitationFormType>({
    defaultValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      language: user.lang,
    },
  });

  const onSubmit = handleSubmit((data) => {
    mutation.mutate(data, {
      onSuccess: (invitation) => {
        toast.success(t('invitations:sendInvitationDialog.success', { name: invitation.name }));
        onClose();
      },
      onError: (error) => {
        if (error instanceof BusinessConflictError && error.errorCode === BusinessConflict.ExistingUserCannotBeInvited) {
          return setError('emailAddress', { type: 'userExists' });
        }
      },
    });
  });

  return (
    <Dialog onClose={onClose} size="small" title={t('invitations:sendInvitationDialog.title')}>
      <Form onSubmit={onSubmit}>
        <Flex direction="column" gap="normal">
          <FormField control={control} name="firstName" rules={{ required: true, maxLength: 255 }}>
            <Label>{t('invitations:sendInvitationDialog.form.inviteeFirstNameField.label')}</Label>
            <FormInput autoFocus />
            <FormValidationError
              maxLength={t('validation:maxLength', {
                attribute: t('invitations:sendInvitationDialog.form.inviteeFirstNameField.label'),
                max: 255,
              })}
              required={t('validation:required')}
            />
          </FormField>

          <FormField control={control} name="lastName" rules={{ required: true, maxLength: 255 }}>
            <Label>{t('invitations:sendInvitationDialog.form.inviteeLastNameField.label')}</Label>
            <FormInput />
            <FormValidationError
              maxLength={t('validation:maxLength', {
                attribute: t('invitations:sendInvitationDialog.form.inviteeLastNameField.label'),
                max: 255,
              })}
              required={t('validation:required')}
            />
          </FormField>

          <FormField
            control={control}
            name="emailAddress"
            rules={{
              required: true,
              maxLength: 255,
              pattern: EMAIL,
              validate: {
                notSelf: (email) => email !== user.email,
                unique: (email) => !user.invitations.some((invitation) => email === invitation.email),
              },
            }}
          >
            <Label>{t('invitations:sendInvitationDialog.form.inviteeEmailAddressField.label')}</Label>
            <FormInput type="email" />
            <FormValidationError
              maxLength={t('validation:maxLength', {
                attribute: t('invitations:sendInvitationDialog.form.inviteeEmailAddressField.label'),
                max: 255,
              })}
              pattern={t('validation:email.invalid')}
              required={t('validation:required')}
              validate-notSelf={t('invitations:sendInvitationDialog.errors.noSelfInvite')}
              validate-unique={t('invitations:sendInvitationDialog.errors.noDoubleInvite')}
              validate-userExists={t('invitations:sendInvitationDialog.errors.userAlreadyExists')}
            />
          </FormField>

          <FormField control={control} name="language" rules={{ required: true }}>
            <Label>{t('invitations:sendInvitationDialog.form.inviteeLanguageField.label')}</Label>
            <FormListbox>
              {([Language.DUTCH, Language.FRENCH] as const).map((language) => (
                <ListboxOption key={language} value={language}>
                  {t(`common:languages.${language}`)}
                </ListboxOption>
              ))}
            </FormListbox>
          </FormField>

          {mutation.isError && (
            <ValidationError>
              {match(mutation.error)
                .with(Pattern.instanceOf(HttpError), () => (
                  <Trans components={{ email: <ExternalLink /> }} i18nKey="common:errors.httpErrorWithLink" />
                ))
                .with(Pattern.instanceOf(FetchError), () => t('common:errors.fetchError'))
                .otherwise(() => t('common:errors.unknown'))}
            </ValidationError>
          )}

          <ButtonGroup end>
            <Button icon="Send" loading={mutation.isPending} type="submit">
              {t('invitations:sendInvitationDialog.form.submitButton')}
            </Button>
          </ButtonGroup>
        </Flex>
      </Form>
    </Dialog>
  );
};
