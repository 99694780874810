import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { customConditionPdfSchema } from './schemas';

export const usePreviewCustomCondition = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      const token = getAccessToken();
      const response = await request(`/custom-conditions/${id}/pdf`, { token });

      const { pdf } = customConditionPdfSchema.parse(response);

      return pdf;
    },
  });
};
