import type { UseFormReturn } from 'react-hook-form';

import { Trans, useTranslation } from 'react-i18next';

import { Button, Checkbox, CollapsibleSection, FormControl, FormField, Modal } from '~/components';
import { useAwaitableModal } from '~/hooks/useModal';

import type { ContactFormType } from '../types';

type ContactCustomAgreementFormType = Pick<ContactFormType, 'customAgreement'>;

export const ContactCustomAgreementFormSection = <T extends ContactCustomAgreementFormType>({
  form,
}: {
  form: T extends ContactCustomAgreementFormType ? UseFormReturn<T> : never;
}) => {
  const { control } = form;

  const confirmationModal = useAwaitableModal<boolean>();

  const { t } = useTranslation(['clients', 'common', 'validation']);

  return (
    <CollapsibleSection
      description={<Trans components={{ a: <a /> }} i18nKey="clients:createEdit.subsections.customAgreement.description" />}
      title={t('clients:createEdit.subsections.customAgreement.title')}
    >
      <FormControl control={control} name="customAgreement">
        <FormField>
          {({ field, id }) => (
            <Checkbox
              checked={!!field.value}
              disabled={!!form.formState.defaultValues?.customAgreement} // If a custom agreement is already active, prevent unchecking this option
              id={id}
              label={t('clients:fields.customAgreement.label')}
              onBlur={field.onBlur}
              onChange={async (checked) => {
                if (!checked) return field.onChange(checked);

                const confirmed = await confirmationModal.open();
                if (confirmed) field.onChange(checked);
              }}
              ref={field.ref}
              tooltip={t('clients:fields.customAgreement.tooltip')}
            />
          )}
        </FormField>
      </FormControl>

      {confirmationModal.isOpen && (
        <Modal onClose={() => confirmationModal.closeWithResult(false)}>
          <Modal.Title>{t('clients:customAgreementModal.title')}</Modal.Title>

          <p>
            <Trans components={{ a: <a /> }} i18nKey="clients:customAgreementModal.description" />
          </p>

          <Modal.Actions>
            <Button onClick={() => confirmationModal.closeWithResult(false)} type="secondary">
              {t('clients:customAgreementModal.cancel')}
            </Button>
            <Button onClick={() => confirmationModal.closeWithResult(true)}>{t('clients:customAgreementModal.submit')}</Button>
          </Modal.Actions>
        </Modal>
      )}
    </CollapsibleSection>
  );
};
