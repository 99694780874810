import type { ReactNode } from 'react';

import { DialogBackdrop, DialogPanel, DialogTitle, Dialog as HuiDialog } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { Fragment } from 'react';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';

import { Button } from '../Button/Button';
import { Title } from '../Title/Title';
import { styles } from './Dialog.styles';

type Props = {
  children: ReactNode;
  icon?: IconName;
  onClose: () => void;
  open?: boolean;
  size?: 'normal' | 'small';
  title: string;
};

export const Dialog = ({ children, icon, onClose, open = true, size = 'normal', title }: Props) => {
  return (
    <HuiDialog {...stylex.props(styles.dialog)} onClose={onClose} open={open}>
      <DialogBackdrop {...stylex.props(styles.backdrop)} />

      <div {...stylex.props(styles.fixedContainer)}>
        <div {...stylex.props(styles.scrollContainer)}>
          <DialogPanel {...stylex.props(styles.panel, styles[`panel-${size}`])}>
            <header {...stylex.props(styles.header)}>
              <div {...stylex.props(styles.titleAndIcon)}>
                {icon && <Icon name={icon} size={28} />}

                <DialogTitle as={Fragment}>
                  <Title as="h4">{title}</Title>
                </DialogTitle>
              </div>

              <Button icon="Close" onClick={onClose} size="square" styles={styles.closeButton} variant="tertiary" />
            </header>

            {children}
          </DialogPanel>
        </div>
      </div>
    </HuiDialog>
  );
};
