export const AmplitudeEventName = {
  RegistrationStep1Completed: 'Step 1 Completed',
  RegistrationStep2Completed: 'Step 2 Completed',
  RegistrationStep3Completed: 'Step 3 Completed',
};

export const AmplitudeEventProperty = {
  RegistrationEligible: 'Eligible',
};

export const AmplitudeUserProperty = {
  MainOccupation: 'Main Occupation',
};
