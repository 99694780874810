import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { Button } from '~/components';
import { SearchParamModals } from '~/constants/url';
import { useModal } from '~/hooks/useModal';
import { useShouldOpenModal } from '~/hooks/useShouldOpenModal';

import profileStyles from '../Profile.module.scss';
import styles from './FinancialInfo.module.scss';
import { FinancialInfoModal } from './FinancialInfoModal/FinancialInfoModal';

export const FinancialInfo = () => {
  const { data: user } = useUser();

  const { t } = useTranslation(['settings']);

  const modal = useModal(useShouldOpenModal(SearchParamModals.FINANCIAL_INFO));

  return (
    <>
      <div className={styles['financial-info']}>
        <strong>{t('settings:profile.financial.bank')}:</strong>
        <span>{user.financial.bank}</span>
        <strong>{t('settings:profile.financial.iban')}:</strong>
        <span>{user.financial.iban}</span>
        <strong>{t('settings:profile.financial.bic')}:</strong>
        <span>{user.financial.bic}</span>
      </div>

      <div className={profileStyles['button-group']}>
        <Button icon="Edit" onClick={modal.open}>
          {t('settings:profile.financial.edit')}
        </Button>
      </div>

      {modal.isOpen && <FinancialInfoModal onClose={modal.close} />}
    </>
  );
};
