import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UpdateCommunityProfilePayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { userQueryOptions } from '../user';
import { userCommunityProfileSchema } from './schemas';

export const useUpdateCommunityProfile = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateCommunityProfilePayload) => {
      const token = getAccessToken();
      const response = await request(`/community/${id}`, { method: 'PATCH', token, body });

      const { data: communityProfile } = apiResourceSchema(userCommunityProfileSchema).parse(response);

      return communityProfile;
    },
    onSuccess: (communityProfile) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, communityProfile };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
