import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, lineHeight, radius, spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  pill: {
    paddingInline: spacing.small,
    paddingBlock: spacing.xxsmall,
    display: 'inline-flex',
    alignItems: 'center',
    gap: spacing.xxsmall,
    color: systemColors.textMuted,
    fontSize: fontSize.regular,
    fontWeight: fontWeight.semiBold,
    lineHeight: lineHeight.tight,
    borderWidth: 1,
    borderColor: systemColors.borderLight,
    borderRadius: radius.full,
    textWrap: 'balance',
    overflow: 'hidden', // Due to the rounded corners, a multi-line pill could potentially have text overflowing the border
  },
});
