import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomAgreement } from '~/api/clients/types';

import { useConditions, useCustomConditions } from '~/api/conditions';
import { useUser } from '~/api/user';
import { insertIf } from '~/utils/arrays';

export const useDropdownOptions = ({ customAgreements }: { customAgreements: CustomAgreement[] }) => {
  const { data: conditions } = useConditions();
  const { data: customConditions } = useCustomConditions();

  const { data: user } = useUser();

  const { t } = useTranslation(['common']);

  const numberingSeriesOptions = useMemo(
    () => user.numberingSeries.filter((series) => series.enabledForInvoices).map((series) => ({ value: series.id, label: series.name })),
    [user.numberingSeries],
  );

  const brandOptions = useMemo(
    () => [
      { value: 0, label: user.companyName },
      ...user.brands.map((brand) => ({ value: brand.id, label: brand.companyName })).sort((a, b) => a.label.localeCompare(b.label)),
    ],
    [user.brands, user.companyName],
  );

  const conditionOptions = useMemo<{ label: string; value: { id: number; type: 'condition' | 'customCondition' | 'customAgreement' } }[]>(
    () => [
      ...conditions.map((condition) => ({
        value: { id: condition.id, type: 'condition' as const },
        label: condition.name,
      })),
      ...customConditions.map((customCondition) => ({
        value: { id: customCondition.id, type: 'customCondition' as const },
        label: customCondition.name,
      })),
      ...insertIf(customAgreements.length > 0, {
        value: { id: 0, type: 'customAgreement' as const },
        label: t('common:customAgreementOptionLabel'),
      }),
    ],
    [conditions, customAgreements.length, customConditions, t],
  );

  const customAgreementOptions = useMemo(
    () =>
      customAgreements.map((customAgreement) => ({
        value: customAgreement.id,
        label: customAgreement.description,
      })),
    [customAgreements],
  );

  return useMemo(
    () => ({
      numberingSeries: numberingSeriesOptions,
      brands: brandOptions,
      conditions: conditionOptions,
      customAgreements: customAgreementOptions,
    }),
    [numberingSeriesOptions, brandOptions, conditionOptions, customAgreementOptions],
  );
};
