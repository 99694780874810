import { queryOptions, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { getAccessToken } from '~/utils/auth';

import { QueryKeys } from '../keys';
import { request } from '../request';

export const quotationAttachmentQueryOptions = (id: number) =>
  queryOptions({
    queryKey: QueryKeys.quotationAttachment(id),
    queryFn: async () => {
      const token = getAccessToken();
      const response = await request(`/quotations/${id}/attachment`, { token, responseType: 'blob' });

      const attachment = z.instanceof(Blob).or(z.null()).parse(response);

      return attachment;
    },
    staleTime: 60 * 1000,
    gcTime: Infinity,
  });

export const useQuotationAttachment = (id: number) => {
  return useQuery(quotationAttachmentQueryOptions(id));
};
