import { useConditionPdf } from '~/api/conditions';
import { Modal, PreviewIFrame, Spinner } from '~/components';

import type { ViewConditionModalProps as Props } from './types';

export const ViewConditionModal = ({ conditionId, onClose }: Props) => {
  const { data: conditionPdf } = useConditionPdf(conditionId);

  return (
    <Modal onClose={onClose} variant="preview">
      {conditionPdf ? (
        <PreviewIFrame src={`data:application/pdf;base64,${conditionPdf}#toolbar=0`} />
      ) : (
        <section className="flex flex-col justify-center h-full">
          <Spinner centered />
        </section>
      )}
    </Modal>
  );
};
