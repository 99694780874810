import { useCallback, useMemo, useRef, useState } from 'react';

export const useDialog = <TData = void, TResult = void>(initialOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const [data, setData] = useState<TData | undefined>(undefined);

  const resolveRef = useRef<((value: TResult) => void) | null>(null);

  const open = useCallback((data: TData) => {
    setIsOpen(true);
    setData(data);

    return new Promise<TResult>((resolve) => {
      resolveRef.current = resolve;
    });
  }, []);

  const close = useCallback((result: TResult) => {
    setIsOpen(false);
    setData(undefined);

    resolveRef.current?.(result);
  }, []);

  return useMemo(
    () =>
      ({
        isOpen,
        data: isOpen ? data : undefined,
        open,
        close,
      }) as (
        | {
            data: TData;
            isOpen: true;
          }
        | {
            data: undefined;
            isOpen: false;
          }
      ) & {
        close: typeof close;
        open: typeof open;
      },
    [close, data, isOpen, open],
  );
};
