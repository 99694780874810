import { useRouteError } from 'react-router-dom';

/**
 * Use this component as error element to prevent React Router from catching the error.
 * This allows the error to bubble outside of the router and be caught by Sentry's error boundary.
 */
export const RouteErrorBoundary = () => {
  const error = useRouteError();

  throw error;
};
