import { queryOptions, useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { getAccessToken } from '~/utils/auth';

import { ClientError } from '../errors';
import { QueryKeys } from '../keys';
import { request } from '../request';

export const invoiceAttachmentQueryOptions = (id: number) =>
  queryOptions({
    queryKey: QueryKeys.invoiceAttachment(id),
    queryFn: async () => {
      const token = getAccessToken();

      try {
        const response = await request(`/invoices/${id}/attachment`, { token, responseType: 'blob' });

        const attachment = z.instanceof(Blob).or(z.null()).parse(response);

        return attachment;
      } catch (error) {
        // 404 means no attachment exists for this invoice. Don't throw as it is not an error.
        if (error instanceof ClientError && error.statusCode === 404) {
          return null;
        }

        throw error;
      }
    },
    staleTime: 60 * 1000,
    gcTime: Infinity,
  });

export const useInvoiceAttachment = (id: number) => {
  return useQuery(invoiceAttachmentQueryOptions(id));
};
