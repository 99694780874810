import type { BlockerFunction } from 'react-router-dom';

import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';

import { Button, Modal } from '~/components';

import { useBrowserBlocker } from './Blocker.hooks';

type Props = {
  isBlocked: boolean | BlockerFunction;
  leaveText?: string;
  message: string;
  stayText?: string;
};

export const Blocker = ({ isBlocked, leaveText, message, stayText }: Props) => {
  useBrowserBlocker(typeof isBlocked === 'boolean' ? isBlocked : true);

  const shouldBlock: BlockerFunction = useCallback(
    (args) => {
      const {
        nextLocation: { state },
      } = args;

      // Allow bypassing the blocker for imperative navigations
      // by adding `{ blockable: false }` to the location state
      if (state?.blockable === false) {
        return false;
      }

      return typeof isBlocked === 'boolean' ? isBlocked : isBlocked(args);
    },
    [isBlocked],
  );

  const blocker = useBlocker(shouldBlock);

  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isBlocked) {
      blocker.reset();
    }
  }, [blocker, isBlocked]);

  return (
    <Modal isOpen={blocker.state !== 'unblocked'} onClose={() => blocker.reset?.()}>
      <Modal.Title>{t('common:blocker.title')}</Modal.Title>

      <p>{message}</p>

      <Modal.Actions>
        <Button onClick={() => blocker.proceed?.()} type="secondary">
          {leaveText ?? t('common:blocker.leave')}
        </Button>
        <Button onClick={() => blocker.reset?.()}>{stayText ?? t('common:blocker.stay')}</Button>
      </Modal.Actions>
    </Modal>
  );
};
