import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/nl';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import i18next from 'i18next';

dayjs.extend(customParseFormat);

export const getGreetingMessage = (currentTime: Date): string => {
  if (!currentTime || !dayjs(currentTime).isValid()) {
    return i18next.t('common:greetings.common');
  }

  const splitAfternoon = 12;
  const splitEvening = 17;
  const currentHour = dayjs(currentTime).hour();

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    // Between 12 PM and 5PM
    return i18next.t('common:greetings.noon');
  } else if (currentHour >= splitEvening) {
    // Between 5PM and Midnight
    return i18next.t('common:greetings.evening');
  }
  // Between dawn and noon
  return i18next.t('common:greetings.morning');
};
