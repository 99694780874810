import type { FormFieldProps as Props } from './types';

import { useFormControlContext } from '../context';

/**
 * Generic component that passes the form context to its children as render prop.
 * This can be used to create custom form inputs that integrate with FormControl.
 */
export const FormField = ({ children }: Props) => {
  const context = useFormControlContext();

  return children(context);
};
