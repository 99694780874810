import type { ReactNode } from 'react';

import { useTooltipContext } from './Tooltip.context';

type Props = {
  children: ReactNode;
};

export const TooltipTrigger = ({ children }: Props) => {
  const { getReferenceProps, refs } = useTooltipContext();

  return (
    <div {...getReferenceProps()} ref={refs.setReference}>
      {children}
    </div>
  );
};
