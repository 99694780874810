import { useScreenWidth } from '~/hooks/useScreenWidth';

import type { PageTitleItemsProps as Props } from './types';

import styles from './PageTitle.module.scss';

export const PageTitleItems = ({ breakpoint = 'sm', children }: Props) => {
  const screen = useScreenWidth();

  if (screen.isSmallerThan(breakpoint)) return null;

  return (
    <div className={styles.Items} data-css-container="page-title-items">
      {children}
    </div>
  );
};
