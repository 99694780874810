import { useLayoutEffect, useRef } from 'react';

/**
 * "The latest ref pattern"
 * Make a prop stable, so that the consumer does not have the burden of memoizing their props
 * Typically used for making callback functions stable, as they are usually inlined
 */
export const useStableRef = <T>(value: T) => {
  const ref = useRef(value);

  useLayoutEffect(() => {
    ref.current = value;
  });

  return ref;
};
