import classNames from 'classnames';
import { useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdateCommunityProfile, useUploadCommunityProfilePicture } from '~/api/community';
import { communityRegions } from '~/api/community/constants';
import { useUser } from '~/api/user';
import {
  Button,
  FormCheckbox,
  FormControl,
  FormErrorMessage,
  FormField,
  FormLabel,
  FormMultiSelect,
  FormSelect,
  FormTextInput,
  FormToggle,
  RichTextEditor,
  Spinner,
} from '~/components';
import { Blocker } from '~/components/Functional';
import { Icon } from '~/components/SVG';
import { CommunityProfileModal, CommunityProfilePicture } from '~/components/Templates/Community';
import { useIntl } from '~/hooks/useIntl';
import { useModalWithData } from '~/hooks/useModal';
import { Activity } from '~/types/user';

import type { EditUserCommunityProfileFormType } from './types';

import { mapUserCommunityProfileFormDataToPayload, mapUserCommunityProfileResponseToFormData } from './utils';

export const CommunitySettings = () => {
  const { data: user } = useUser();
  const updateMutation = useUpdateCommunityProfile(user.communityProfile.id);
  const uploadMutation = useUploadCommunityProfilePicture(user.communityProfile.id);

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<EditUserCommunityProfileFormType>({
    defaultValues: mapUserCommunityProfileResponseToFormData(user.communityProfile),
  });
  const isFormDisabled = !watch('enabled');

  const previewModal = useModalWithData<EditUserCommunityProfileFormType>();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation(['common', 'community', 'settings', 'validation']);

  const onSubmit = handleSubmit((data) => {
    updateMutation.mutate(mapUserCommunityProfileFormDataToPayload(data), {
      onSuccess: (communityProfile) => reset(mapUserCommunityProfileResponseToFormData(communityProfile)),
      onError: () => toast.error(t('settings:alerts.community.errorUpdated')),
    });
  });

  const onProfilePictureUpload = (profilePicture: File | null) => {
    if (profilePicture && profilePicture.size > 5012 * 1024) return toast.error(t('settings:alerts.community.errorProfilePictureTooLarge'));

    uploadMutation.mutate(profilePicture, {
      onSuccess: () => toast.success(t('settings:alerts.community.successProfilePictureUploaded')),
      onError: () => toast.error(t('settings:alerts.community.errorProfilePictureUploaded')),
      onSettled: () => {
        if (fileInputRef.current) fileInputRef.current.value = '';
      },
    });
  };

  const brandOptions = useMemo(
    () =>
      [{ id: 0, companyName: `${user.firstName} ${user.lastName}` }, ...user.brands].map((brand) => ({
        label: brand.companyName,
        value: brand.id,
      })),
    [user.brands, user.firstName, user.lastName],
  );

  const { compareForSort } = useIntl();
  const regionOptions = useMemo(
    () =>
      [...communityRegions]
        .map((region) => ({ value: region, label: t(`community:regions.${region}`) }))
        .sort(({ label: a }, { label: b }) => compareForSort(a, b)),
    [compareForSort, t],
  );

  if (user.communityProfile.banned) {
    return (
      <p className="italic">
        <Trans components={{ a: <a /> }} i18nKey="settings:profile.community.communityHidden" />
      </p>
    );
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Blocker isBlocked={isDirty} message={t('settings:profile.community.blocker')} />

        <FormControl control={control} name="enabled">
          <FormToggle
            label={
              isFormDisabled
                ? t('settings:profile.community.enableCommunityProfile')
                : t('settings:profile.community.disableCommunityProfile')
            }
          />
        </FormControl>

        <div className={classNames('mt-8 grid lg:grid-cols-2 gap-8', isFormDisabled && 'hidden')}>
          <div className="space-y-4">
            <div className="grid grid-cols-[auto_1fr] gap-x-10">
              {/* Profile picture */}
              <div className="self-center relative">
                <button
                  className="block size-28 rounded-full hover:text-primary-500 relative group"
                  disabled={uploadMutation.isPending}
                  onClick={() => fileInputRef.current?.click()}
                  type="button"
                >
                  <CommunityProfilePicture
                    firstName={user.firstName}
                    lastName={user.lastName}
                    url={user.communityProfile.profilePictureUrl}
                  />

                  <span
                    className={classNames(
                      'absolute inset-0 flex justify-center items-center text-primary-500',
                      'hidden group-hover:flex bg-white/50',
                    )}
                  >
                    <Icon name="Upload" size={32} />
                  </span>
                </button>

                <input
                  accept="image/jpeg,image/png"
                  hidden
                  id="profilePicture"
                  onChange={(e) => e.target.files?.[0] && onProfilePictureUpload(e.target.files[0])}
                  ref={fileInputRef}
                  type="file"
                />

                {user.communityProfile.profilePictureUrl && (
                  <button
                    className="absolute bottom-0 -right-4 text-medium-gray hover:text-primary-500"
                    disabled={uploadMutation.isPending}
                    onClick={() => onProfilePictureUpload(null)}
                    type="button"
                  >
                    {uploadMutation.isPending ? <Spinner size={24} /> : <Icon name="Delete" />}
                  </button>
                )}
              </div>

              <div className="space-y-4">
                {/* Brand */}
                {user.brands.length > 0 && (
                  <FormControl control={control} name="brandId" rules={{ required: true }}>
                    <FormLabel>{t('settings:fields.brandId.label')}</FormLabel>
                    <FormSelect
                      // If the user has not changed their default email address, update it to match the selected brand
                      onAfterChange={({ newValue, oldValue }) => {
                        const expectedEmail = oldValue === 0 ? user.email : user.brands.find(({ id }) => id === oldValue)?.email;

                        if (getValues('email') === expectedEmail) {
                          const email = newValue === 0 ? user.email : (user.brands.find(({ id }) => id === newValue)?.email ?? '');
                          setValue('email', email);
                        }
                      }}
                      options={brandOptions}
                    />
                    <FormErrorMessage required={t('validation:required')} />
                  </FormControl>
                )}

                {/* Email address */}
                <FormControl control={control} name="email" rules={{ required: true }}>
                  <FormLabel>{t('settings:fields.email.label')}</FormLabel>
                  <FormTextInput />
                  <FormErrorMessage required={t('validation:required')} />
                </FormControl>
              </div>
            </div>

            {/* Regions */}
            <FormControl control={control} name="regions">
              <FormLabel>{t('settings:fields.communityRegion.label')}</FormLabel>
              <FormMultiSelect options={regionOptions} placeholder={t('settings:fields.communityRegion.placeholder')} />
            </FormControl>

            {/* Open to work */}
            <FormControl control={control} name="openToWork">
              <FormCheckbox label={t('settings:fields.communityOpenToWork.label')} />
            </FormControl>
          </div>

          {/* Links */}
          <div className="space-y-2">
            <FormControl control={control} name="links.website">
              <FormLabel optional>{t('settings:fields.communityLinks.label')}</FormLabel>
              <FormTextInput iconName="Language" placeholder={t('settings:fields.communityLinks.placeholders.website')} />
            </FormControl>
            <FormControl control={control} name="links.linkedIn">
              <FormTextInput iconName="LinkedIn" placeholder={t('settings:fields.communityLinks.placeholders.linkedIn')} />
            </FormControl>
            <FormControl control={control} name="links.instagram">
              <FormTextInput iconName="Instagram" placeholder={t('settings:fields.communityLinks.placeholders.instagram')} />
            </FormControl>
            <FormControl control={control} name="links.facebook">
              <FormTextInput iconName="Facebook" placeholder={t('settings:fields.communityLinks.placeholders.facebook')} />
            </FormControl>
            <FormControl control={control} name="links.behance">
              <FormTextInput iconName="Behance" placeholder={t('settings:fields.communityLinks.placeholders.behance')} />
            </FormControl>
          </div>

          <div className="col-span-full space-y-4">
            {/* Description */}
            <FormControl control={control} name="description">
              <FormLabel optional>{t('settings:fields.communityDescription.label')}</FormLabel>
              <FormField>
                {({ field, fieldState }) => (
                  <RichTextEditor isInvalid={fieldState.invalid} onChange={field.onChange} value={field.value} withLinkButton={false} />
                )}
              </FormField>
            </FormControl>
          </div>
        </div>

        <div className="mt-4 flex gap-x-2 justify-end">
          {!isFormDisabled && (
            <Button icon="Visibility" onClick={() => previewModal.open(getValues())} type="secondary">
              {t('community:header.preview')}
            </Button>
          )}

          {isDirty && (
            <Button disabled={!isDirty} hasSpinner icon="Save" isLoading={updateMutation.isPending} isSubmit>
              {t('common:save')}
            </Button>
          )}
        </div>
      </form>

      {previewModal.isOpen && (
        <CommunityProfileModal
          onClose={previewModal.close}
          profile={{
            id: user.communityProfile.id,
            firstName: user.firstName,
            lastName: user.lastName,
            brand: user.brands.find((brand) => brand.id === previewModal.data.brandId)?.companyName ?? null,
            openToWork: previewModal.data.openToWork,
            email: previewModal.data.email,
            description: previewModal.data.description,
            regions: previewModal.data.regions,
            profilePictureUrl: user.communityProfile.profilePictureUrl,
            activities: user.activities.filter(({ id }) => id !== Activity.OTHER),
            links: previewModal.data.links,
          }}
        />
      )}
    </>
  );
};
