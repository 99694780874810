export const dashboard = {
  charts: {
    labels: {
      commission: 'Compensation Creative Shelter',
      copyright: 'Copyrights part',
      currentQ: 'Current quarter (K{{ quarter }})',
      expired: 'Overdue',
      nettoBenefit: 'Net benefit',
      notExpired: 'Not expired',
      paid: 'Paid invoices',
      performance: 'Professional income part',
      previousQ: 'Previous quarters',
      total: 'Total number of invoices',
      unpaid: 'Unpaid invoices',
    },
    netAdvantage: 'Net benefit',
    overview: 'Overview',
    table: {
      amount: 'Number of',
      description: 'Description',
      value: 'Amount',
    },
    title: 'Annual overview',
    totalBilled: 'Total invoiced',
  },
  frameworkAgreement: {
    checkboxError: 'Please agree with the framework agreement and get started.',
    checkboxLabel: 'I accept the framework agreement',
    subtitle: 'No small print, just clear agreements.',
    title: 'Read the framework agreement.',
  },
  header: {
    actionBlock: {
      button: 'Create invoice',
      dropdown: {
        client: 'Create client',
        creditnote: 'Create credit note',
        quotation: 'Create quotation',
      },
    },
    description: 'Welcome back to your Creative Shelter dashboard!',
  },
  progression: {
    hide: 'hide',
    optional: {
      accountant: 'Forward your documents automatically!',
      community: 'Join the Craft Community!',
      completed: 'You make use of all (optional) expansion options',
      invite: 'Invite someone to use the platform',
      plan: 'Upgrade your pricing plan',
      theme: 'Personalize your application.',
      title: 'Expand your account.',
    },
    required: {
      completed: 'Your Creative Shelter account is fully up-to-date!',
      create: 'Create and activate your account.',
      iban: 'Link your IBAN to your Creative Shelter account',
      signAddendum2024: 'Sign the addendum to your framework agreement with Creative Shelter',
      signContract: 'Sign your framework agreement',
      signContractModalTitle: 'Sign your Creative Shelter framework agreement',
      title: 'Complete your Creative Shelter account',
      vatRegulation: 'Indicate your VAT status',
    },
  },
  royaltiesReformAnnouncement: {
    messageIt:
      '<p>A new year, and a new addendum to your framework agreement. To be found on your dashboard, under “Complete your Creative Shelter account”. As of this year, the distribution key is 60/40, professional income/copyright. Once signed, you can invoice.\n<br></p>\n<p>Computer program performances will from now on be without a compensation for copyrights. You can still use it, but you will no longer get a tax benefit from it.\n<br></p>\n<p>And the tariff plans have been adapted! The Premium and Classic tariff plans remain. This way, you get more for the same fee, you pay less, for the same service. You <0> can find the changes and new content here. </0></p>',
    messageNonIt:
      '<p>A new year, and for the first time, an addendum to your framework agreement. To be found on your dashboard, under “Complete your Creative Shelter account”. As of this year, the distribution key is 60/40, professional income/copyright. Once signed, you can invoice.\n<br></p>\n<p>And the tariff plans have been adapted! Only the Premium and Classic tariff plans remain. This way, you get more for the same fee, you pay less, for the same service. You <0> can find the changes and new content here. </0></p>',
    titleIt: '<span className="text-secondary-500">2024</span> , what exactly is changing?',
    titleNonIt: '<span className="text-secondary-500">2024</span>, what exactly is changing?',
  },
  stats: {
    copyrightAverage: {
      label: 'Average copyrights',
      tooltip:
        'Four-year average that you received as compensation for your copyrights via Creative Shelter.<br> Why this is important and how it is calculated<a href="https://knowledge.creativeshelter.be/hoe-wordt-jouw-gemiddelde-auteursrechten-berekend-op-het-dashboard" target="_blank">read more here!</a>',
    },
    copyrightThisYear: {
      label: 'Copyrights {{year}}',
      tooltip:
        'Total amount of compensation for your copyrights of all paid invoices this year. <br>You can read everything about your annual limit of the compensation for your copyrights here. <a href="https://www.creativeshelter.be/begroting-hervorming-van-auteursrecht/" target="_blank"> </a>',
    },
    overdueInvoices: 'Overdue invoices',
    pendingInvoices: 'Invoices for approval',
    pendingQuotations: 'Quotations for approval',
  },
};
