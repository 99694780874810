import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';
import { z } from 'zod';

import { getAccessToken } from '~/utils/auth';

import type { CreditNote } from './types';

import { apiResourceSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';

export const creditNotesQueryOptions = queryOptions({
  queryKey: QueryKeys.creditNotes,
  queryFn: async () => {
    const token = getAccessToken();
    const response = await request('/creditnotes', { token });

    // TODO: Schema validation
    const { data: creditNotes } = apiResourceSchema(z.unknown()).parse(response);

    return creditNotes as CreditNote[];
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const useCreditNotes = () => {
  return useSuspenseQuery(creditNotesQueryOptions);
};
