import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';
import { useTranslation } from 'react-i18next';

import { Title } from '~/components/UI';

import { styles } from './InvitationsOverviewCards.styles';

type Props = {
  children: ReactNode;
};

export const InvitationsOverviewCards = ({ children }: Props) => {
  const { t } = useTranslation(['invitations']);

  return (
    <div {...stylex.props(styles.wrapper)}>
      <Title as="h4">{t('invitations:overview.title')}</Title>

      <div {...stylex.props(styles.cards)}>{children}</div>
    </div>
  );
};
