import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import invariant from 'tiny-invariant';

import { Button, FormControl, FormErrorMessage, FormLabel, FormTextInput } from '~/components';
import { Blocker } from '~/components/Functional';
import { europeanUnionVatCountryCodes } from '~/constants/countries';
import { routes } from '~/providers/RouterProvider/router.routes';
import { includes } from '~/utils/arrays';

import type { InternationalOrganisationFormType } from './types';

import { FormAddressCity } from '../../shared/FormAddressCity';
import { FormAddressNumber } from '../../shared/FormAddressNumber';
import { FormAddressStreet } from '../../shared/FormAddressStreet';
import { FormAddressZipCode } from '../../shared/FormAddressZipCode';
import { FormCompanyName } from '../../shared/FormCompanyName';
import { mapVatCountryCodeToCountryCode } from '../../utils';
import { useCreateClientContext } from '../context';
import { useCreateOrganisationContext } from './context';
import { useIsBlocked } from './hooks';
import { VatCountryCodeSelect } from './shared/VatCountryCodeSelect';

export const CreateInternationalOrganisation = () => {
  const { organisation, setOrganisation } = useCreateClientContext();
  const { vatCountryCode } = useCreateOrganisationContext();

  invariant(!includes(europeanUnionVatCountryCodes, vatCountryCode), 'Expected selected country to not be part of the European Union');

  const navigate = useNavigate();
  const { search } = useLocation();
  const { t } = useTranslation(['clients', 'validation']);

  const { control, handleSubmit } = useForm<InternationalOrganisationFormType>({
    defaultValues: {
      otherNumber: organisation?.otherNumber ?? '',
      companyName: organisation?.companyName ?? '',
      address: {
        street: organisation?.address.street ?? '',
        number: organisation?.address.number ?? '',
        zipCode: organisation?.address.zipCode ?? '',
        city: organisation?.address.city ?? '',
        countryCode: organisation?.address.countryCode ?? mapVatCountryCodeToCountryCode(vatCountryCode),
      },
    },
  });

  const isBlocked = useIsBlocked(control);

  const onSubmit = handleSubmit((data) => {
    setOrganisation({ clientType: 'organisation', ...data });
    navigate({ pathname: routes.createClientContactStep, search }, { state: { blockable: false } });
  });

  return (
    <>
      <Blocker isBlocked={isBlocked} message={t('clients:create.blocker')} />

      <form className="grid gap-4 grid-cols-3" onSubmit={onSubmit}>
        <div className="col-span-full lg:col-span-1">
          <VatCountryCodeSelect />
        </div>

        <div className="col-span-full lg:col-span-2">
          <FormControl control={control} name="otherNumber" rules={{ required: true }}>
            <FormLabel>{t('clients:fields.vatOrOtherNumber.label')}</FormLabel>
            <FormTextInput autoFocus />
            <FormErrorMessage required={t('validation:required')} />
          </FormControl>
        </div>

        <FormCompanyName className="col-span-full" control={control} />
        <FormAddressStreet className="col-span-full lg:col-span-2" control={control} />
        <FormAddressNumber className="col-span-full lg:col-span-1" control={control} />
        <FormAddressZipCode className="col-span-full lg:col-span-1" control={control} />
        <FormAddressCity className="col-span-full lg:col-span-2" control={control} />

        <div className="col-span-full justify-self-end">
          <Button isSubmit>{t('clients:create.next')}</Button>
        </div>
      </form>
    </>
  );
};
