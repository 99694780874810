import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { SubmittedQuotation } from '~/api/quotations/types';

import { useClients } from '~/api/clients';
import { useUser } from '~/api/user';
import { BrandDot, Tooltip } from '~/components';
import { Icon } from '~/components/SVG';
import { CoDocumentStatus } from '~/components/UI';
import { ColumnIds } from '~/constants/table';
import { SearchParamKeys } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { filterBoolean, includes } from '~/utils/arrays';
import { qs } from '~/utils/searchParams';

import { getQuotationStatus, QuotationStatus } from '../utils';
import { QuotationsTableOptions } from './QuotationsTableOptions';

const columnHelper = createColumnHelper<SubmittedQuotation>();

export const useQuotationsTableColumns = () => {
  const { data: user } = useUser();
  const { data: clients } = useClients();

  const { t } = useTranslation(['quotations']);
  const { formatCurrency } = useIntl();

  return useMemo(
    () => [
      // Title & entry numbers
      // Note: old quotations only have a description instead of title
      columnHelper.accessor(({ description, entryNumber, title }) => `${title || description} ${entryNumber}`, {
        id: ColumnIds.quotationsTitle,
        header: t('quotations:overview.columns.entryNumber'),
        cell: ({ row: { original: quotation } }) => {
          const brand = user.brands.find((brand) => brand.id === quotation.brandId);

          return (
            <div className="lg:truncate">
              <strong>{quotation.title || quotation.description}</strong>
              <div className="lg:truncate">
                <span>{quotation.entryNumber}</span>
                {brand && <BrandDot color={brand.color} small />}
              </div>
            </div>
          );
        },
        meta: {
          styles: { size: '3fr', minSize: 125 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Brand (hidden, column filter only)
      columnHelper.accessor(({ brandId }) => brandId ?? 0, {
        id: ColumnIds.quotationsBrand,
        enableGlobalFilter: false,
      }),

      // Client
      columnHelper.accessor(({ clientId }) => clients.find((client) => client.id === clientId)?.name, {
        id: ColumnIds.quotationsClient,
        header: t('quotations:overview.columns.client'),
        cell: ({ row: { original: quotation } }) => {
          const client = clients.find(({ id }) => id === quotation.clientId);

          if (!client) return null;

          return (
            <div className="lg:truncate" onClick={(e) => e.stopPropagation()}>
              <Link
                className="text-dark-gray hover:text-dark-gray hover:underline"
                to={{
                  pathname:
                    client.clientType === 'organisation'
                      ? r(routes.showClient, { clientId: client.id })
                      : r(routes.editContact, { clientId: client.id, contactId: client.contacts[0].id }),
                  search: qs({ [SearchParamKeys.REDIRECT_PATH]: location.pathname }),
                }}
              >
                {client.name}
              </Link>
            </div>
          );
        },
        sortUndefined: 'last',
        meta: {
          styles: { size: '2fr', minSize: 125 },
          isResizable: true,
        },
      }),

      // Contact (hidden, global filter only)
      columnHelper.accessor(
        ({ contactId }) => {
          const contact = clients.flatMap(({ contacts }) => contacts).find(({ id }) => id === contactId);
          return contact ? `${contact.firstName} ${contact.lastName}` : '';
        },
        {
          id: ColumnIds.quotationsContact,
        },
      ),

      // Doc date
      columnHelper.accessor(({ docDate }) => dayjs(docDate).format('DD/MM/YYYY'), {
        id: ColumnIds.quotationsDocDate,
        header: t('quotations:overview.columns.date'),
        cell: ({ getValue, row: { original: quotation } }) => {
          const notYetAcceptedOrDeclined = [QuotationStatus.SENT, QuotationStatus.EXPIRED].includes(getQuotationStatus(quotation));
          const daysUntilExpired = dayjs(quotation.expirationDate).startOf('day').diff(dayjs().startOf('day'), 'day');

          return (
            <div className="lg:truncate">
              <span>{getValue()}</span>

              {notYetAcceptedOrDeclined && (
                <div className={classNames('lg:truncate', daysUntilExpired > 0 ? 'text-primary-500' : 'text-error-500')}>
                  <Icon inline name={daysUntilExpired > 0 ? 'HourglassTop' : 'HourglassBottom'} size={16} />
                  <span className="ml-1">
                    {daysUntilExpired > 0
                      ? `${daysUntilExpired} ${t('quotations:overview.columns.days')}`
                      : dayjs(quotation.expirationDate).format('DD/MM/YYYY')}
                  </span>
                </div>
              )}
            </div>
          );
        },
        sortingFn: ({ original: a }, { original: b }) => {
          const dateA = dayjs(a.docDate);
          const dateB = dayjs(b.docDate);

          return dateA.isSame(dateB) ? 0 : dateA.isBefore(dateB) ? -1 : 1;
        },
        meta: {
          styles: { size: 'max-content', minSize: 100 },
          isResizable: true,
        },
      }),

      // Doc year (hidden, column filter only)
      columnHelper.accessor(({ docYear }) => docYear, {
        id: ColumnIds.quotationsDocYear,
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
      }),

      // Doc quarter (hidden, column filter only)
      columnHelper.accessor(({ docDate, docYear }) => `${docYear} ${dayjs(docDate).quarter()}`, {
        id: ColumnIds.quotationsDocQuarter,
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
      }),

      // Status
      columnHelper.accessor((quotation) => getQuotationStatus(quotation), {
        id: ColumnIds.quotationsStatus,
        header: t('quotations:overview.columns.status.title'),
        cell: ({ getValue, row: { original: quotation } }) => {
          const status = getQuotationStatus(quotation);
          const hasAllowedDate = includes([QuotationStatus.ALLOWED] as const, status) && quotation.allowedOn !== null;

          if (!hasAllowedDate) {
            return (
              <CoDocumentStatus small status={status}>
                {t(`quotations:overview.columns.status.options.${getValue()}`)}
              </CoDocumentStatus>
            );
          }

          return (
            <Tooltip disableSafePolygon>
              <Tooltip.Trigger>
                <div className="w-full truncate">
                  <CoDocumentStatus small status={status}>
                    {t(`quotations:overview.columns.status.options.${getValue()}`)}
                  </CoDocumentStatus>
                </div>
              </Tooltip.Trigger>

              <Tooltip.Content>{dayjs(quotation.allowedOn).format('DD/MM/YYYY')}</Tooltip.Content>
            </Tooltip>
          );
        },
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
        meta: {
          styles: { size: 'max-content', minSize: 120, justify: 'center' },
          mobileStyles: { size: 'max-content', minBreakpoint: 'sm' },
        },
      }),

      // Total
      columnHelper.accessor(({ calculationData }) => calculationData.coExclVat, {
        id: ColumnIds.quotationsTotal,
        header: t('quotations:overview.columns.total'),
        cell: ({ getValue }) => <span className="lg:truncate">{formatCurrency(getValue())}</span>,
        enableGlobalFilter: false,
        meta: {
          styles: { size: 'max-content', minSize: 100, justify: 'end' },
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: quotation } }) => <QuotationsTableOptions quotation={quotation} />,
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [clients, formatCurrency, t, user],
  );
};

export const useQuotationsTableFilters = (quotations: SubmittedQuotation[]) => {
  const { data: user } = useUser();
  const { data: clients } = useClients();

  const { t } = useTranslation(['filters', 'quotations']);
  const { compareForSort } = useIntl();

  const clientOptions = useMemo(
    () =>
      [...new Set(quotations.map(({ clientId }) => clientId))]
        .map((clientId) => {
          const clientName = clients.find(({ id }) => id === clientId)?.name ?? '';
          return { label: clientName, value: clientName };
        })
        .sort((a, b) => compareForSort(a.label, b.label)),
    [clients, compareForSort, quotations],
  );

  const brandOptions = useMemo(
    () =>
      [...new Set(quotations.map(({ brandId }) => brandId))]
        .map((brandId) => {
          if (brandId === 0) return { label: user.companyName, value: 0 };

          const brand = user.brands.find((brand) => brand.id === brandId);

          if (!brand) return null;

          return { label: brand.companyName, value: brand.id };
        })
        .filter(filterBoolean)
        .sort((a, b) => compareForSort(a.label, b.label)),
    [compareForSort, quotations, user.brands, user.companyName],
  );

  const docYearOptions = useMemo(
    () =>
      [...new Set(quotations.map(({ docYear }) => docYear))]
        .sort((a, b) => b - a)
        .map((docYear) => ({ label: `${docYear}`, value: docYear })),
    [quotations],
  );

  const docQuarterOptions = useMemo(
    () =>
      [...new Set(quotations.map(({ docDate, docYear }) => `${docYear}Q${dayjs(docDate).quarter()}`))]
        .map((quarter) => ({ year: +quarter.split('Q')[0], quarter: +quarter.split('Q')[1] }))
        .sort((a, b) => b.year - a.year || b.quarter - a.quarter)
        .map(({ quarter, year }) => ({ label: t('filters:quarter.option', { year, quarter }), value: `${year} ${quarter}` })),

    [quotations, t],
  );

  const statusOptions = useMemo(
    () =>
      [QuotationStatus.ALLOWED, QuotationStatus.DECLINED, QuotationStatus.SENT, QuotationStatus.EXPIRED].map((status) => ({
        label: t(`quotations:overview.columns.status.options.${status}`),
        value: status,
      })),
    [t],
  );

  return useMemo(
    () => ({
      clients: clientOptions,
      brands: brandOptions,
      docYears: docYearOptions,
      docQuarters: docQuarterOptions,
      statuses: statusOptions,
    }),
    [brandOptions, clientOptions, docQuarterOptions, docYearOptions, statusOptions],
  );
};
