import { useTranslation } from 'react-i18next';

import type { AddShortcodeDropdownProps as Props } from '../types';

import { DropdownMenu } from '../../DropdownMenu/DropdownMenu';
import styles from './AddShortcodeDropdown.module.scss';

export const AddShortcodeDropdown = ({ editor, shortcodes }: Props) => {
  const { t } = useTranslation(['quotations']);

  /**
   * Handles the insert shortcode on click
   */
  const insertShortcode = (shortcode: string) => {
    editor
      .chain()
      .focus()
      .command(({ ...props }) => {
        props.tr.insertText(shortcode);
        return true;
      })
      .run();
  };

  return (
    <div className={styles['add-shortcodes']}>
      <DropdownMenu
        iconName="KeyboardArrowDown"
        items={shortcodes.map((shortcode) => ({
          text: t(`quotations:richText.shortcodes.${shortcode.label}`),
          onClick: () => insertShortcode(shortcode.code.toUpperCase()),
        }))}
        label="shortcodes"
      />
    </div>
  );
};
