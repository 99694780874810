import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';
import { checkEmailAddressInUseSchema } from './schemas';

export const useCheckEmailAddressInUse = () => {
  return useMutation({
    mutationFn: async (emailAddress: string) => {
      const body = { emailAddress };
      const response = await request('/registration/email-address-in-use', { method: 'POST', body });

      return checkEmailAddressInUseSchema.parse(response);
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) &&
      captureException(new Error('Failed to check email address during registration', { cause: error }), { level: 'fatal' }),
  });
};
