import * as stylex from '@stylexjs/stylex';

import type { MediaQueryDesktopAndBelow } from '~/styles/mediaQueries';

import { spacing } from '~/styles/tokens.stylex';

const DekstopAndBelow: MediaQueryDesktopAndBelow = '@media (width < 1920px)';

export const styles = stylex.create({
  content: {
    gridArea: 'content',
    alignSelf: 'center',
    paddingInline: spacing.large,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  centerContainer: {
    width: '100%',
    maxWidth: {
      default: 600,
      [DekstopAndBelow]: 400,
    },
    display: 'flex',
    flexDirection: 'column',
    gap: {
      default: spacing.xlarge,
      [DekstopAndBelow]: spacing.medium,
    },
  },
});
