import { useParams } from 'react-router-dom';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useClients } from '~/api/clients';
import { Redirect } from '~/components/Functional';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { isClientEditable } from '../utils';
import { EditClientForm } from './EditClientForm';

export const EditClient = () => {
  const { data: clients } = useClients();

  const { clientId } = useParams<Params<typeof routes.editClient>>();

  const client = clients.find((client) => client.id === Number(clientId));

  if (!client) return <Redirect to={routes.clients} />;

  if (!isClientEditable(client)) return <Redirect to={r(routes.showClient, { clientId: client.id })} />;

  return <EditClientForm client={client} />;
};
