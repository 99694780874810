import type { CSSProperties } from 'react';

import { autoUpdate, flip, offset, size, useFloating } from '@floating-ui/react';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useScreenWidth } from '~/hooks/useScreenWidth';

import { useDragContext } from '../LinesDragDropContext/context';

/**
 * Checks whether the current line is the one that needs to have a border top.
 *
 * Every line has a bottom border as a visual separator. When a line is being dragged, this border is hidden,
 * which means the line below should have a top border added to replace it.
 */
export const useShouldHaveTopBorder = (sectionIndex: number, lineIndex: number): boolean => {
  const { destination, source } = useDragContext();

  // No line is being dragged
  if (!source) return false;

  // Dragging outside of any dropzone
  // This moves all lines up, so there is no need for an extra border
  if (!destination) return false;

  // The line is not being dragged over this section
  if (destination.droppableId !== `${sectionIndex}`) return false;

  return source.droppableId === destination.droppableId
    ? // Same section
      lineIndex <= source.index
      ? destination.index === lineIndex
      : destination.index === lineIndex - 1
    : // Different section
      destination.index === lineIndex;
};

/**
 * Check if a line is free based on the discount options
 */
export const useIsFreeLine = (lineFieldName: `sections.${number}.lines.${number}`): boolean => {
  const { watch } = useInvoiceQuotationFormContext();

  const watchDiscountType = watch(`${lineFieldName}.discountType`);
  const watchDiscountValue = +(watch(`${lineFieldName}.discountValue`) ?? 0);
  const watchPrice = +watch(`${lineFieldName}.price`);
  const watchQuantity = +watch(`${lineFieldName}.quantity`);

  return match(watchDiscountType)
    .with('percentage', () => watchDiscountValue >= 100)
    .with('flatRate', () => watchDiscountValue >= watchPrice * watchQuantity)
    .with(null, () => false)
    .exhaustive();
};

/**
 * Get the calculated line total, including and excluding discount.
 */
export const useLineTotal = (lineFieldName: `sections.${number}.lines.${number}`): { discountedTotal: number; total: number } => {
  const { watch } = useInvoiceQuotationFormContext();

  const watchDiscountType = watch(`${lineFieldName}.discountType`);
  const watchDiscountValue = +(watch(`${lineFieldName}.discountValue`) ?? 0);
  const watchPrice = +watch(`${lineFieldName}.price`);
  const watchQuantity = +watch(`${lineFieldName}.quantity`);

  const watchTotal = watchPrice * watchQuantity;
  const watchDiscountedTotal = match(watchDiscountType)
    .with('flatRate', () => Math.min(Math.max(watchTotal - watchDiscountValue, 0), watchTotal))
    .with('percentage', () => Math.min(Math.max(watchTotal - (watchDiscountValue / 100) * watchTotal, 0), watchTotal))
    .with(null, () => 0)
    .exhaustive();

  return useMemo(
    () => ({
      total: watchTotal,
      discountedTotal: watchDiscountedTotal,
    }),
    [watchTotal, watchDiscountedTotal],
  );
};

/**
 * Position a listbox using Floating UI.
 *
 * This hook is used instead of HeadlessUI's native anchor positioning
 * to have more control over the alignment and width, as it allows for
 * choosing any reference element.
 */
export const useFloatingListbox = (length: number) => {
  const screen = useScreenWidth();

  const { floatingStyles, ...rest } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(4),
      size({
        apply: ({ availableHeight, elements, rects }) => {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${Math.min(availableHeight, 240)}px`,
          });
        },
        padding: screen.isMobile ? { top: 4, left: 4, bottom: 68, right: 4 } : 4, // Add padding for navigation bar on mobile
      }),
      flip(),
    ],
  });

  return {
    floatingStyles: {
      ...floatingStyles,
      minHeight: length > 1 ? '70px' : 0,
    } as CSSProperties,
    ...rest,
  };
};
