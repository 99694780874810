export const filters = {
  clients_name: {
    label: 'Nom du client',
    placeholder: 'Tous les clients',
  },
  clients_type: {
    label: 'Type de client',
    placeholder: 'Tous les types',
  },
  credit_notes_brand: {
    label: 'Nom commercial',
    placeholder: 'Tous les noms commerciaux',
  },
  credit_notes_client: {
    label: 'Client',
    placeholder: 'Tous les clients',
  },
  credit_notes_doc_quarter: {
    label: 'Trimestre',
    placeholder: 'Tous les trimestres',
  },
  credit_notes_doc_year: {
    label: 'Année',
    placeholder: 'Toutes les années',
  },
  credit_notes_status: {
    label: 'Statut',
    placeholder: 'Tous les statuts',
  },
  invoices_brand: {
    label: 'Nom commercial',
    placeholder: 'Tous les noms commerciaux',
  },
  invoices_client: {
    label: 'Client',
    placeholder: 'Tous les clients',
  },
  invoices_doc_quarter: {
    label: 'Trimestre',
    placeholder: 'Tous les trimestres',
  },
  invoices_doc_year: {
    label: 'Année',
    placeholder: 'Toutes les années',
  },
  invoices_status: {
    label: 'Statut',
    placeholder: 'Tous les statuts',
  },
  products_activity_type: {
    label: 'Type de prestation',
    placeholder: 'Toutes les prestations',
  },
  products_categories: {
    label: 'Catégories',
    placeholder: 'Toutes les catégories',
  },
  products_status: {
    label: 'Statut',
    placeholder: 'Tous les statuts',
  },
  quarter: {
    option: 'Trimestre {{quarter}} - {{year}}',
  },
  quotations_brand: {
    label: 'Nom commercial',
    placeholder: 'Tous les noms commerciaux',
  },
  quotations_client: {
    label: 'Client',
    placeholder: 'Tous les clients',
  },
  quotations_doc_quarter: {
    label: 'Trimestre',
    placeholder: 'Tous les trimestres',
  },
  quotations_doc_year: {
    label: 'Année',
    placeholder: 'Toutes les années',
  },
  quotations_status: {
    label: 'Statut',
    placeholder: 'Tous les statuts',
  },
};
