import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UpdateProductPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { productSchema } from './schemas';
import { productsQueryOptions } from './useProducts';

export const useUpdateProduct = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateProductPayload) => {
      const token = getAccessToken();
      const response = await request(`/products/${id}`, { method: 'PUT', token, body });

      const { data: product } = apiResourceSchema(productSchema).parse(response);

      return product;
    },
    onSuccess: (updatedProduct) => {
      queryClient.setQueryData(productsQueryOptions.queryKey, (products) =>
        (products ?? []).map((product) => (product.id === updatedProduct.id ? updatedProduct : product)),
      );
      queryClient.invalidateQueries(productsQueryOptions);
    },
  });
};
