import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { User } from '~/types/user';

import { getAccessToken } from '~/utils/auth';

import type { UpdateEmailSettingsPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useUpdateEmailSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateEmailSettingsPayload) => {
      const token = getAccessToken();
      const response = await request('/user', { method: 'PATCH', token, body });

      // TODO: Schema validation
      const { data: user } = apiResourceSchema(z.unknown()).parse(response);

      return user as User;
    },
    // Optimistically update the user
    onMutate: async (variables) => {
      await queryClient.cancelQueries(userQueryOptions);

      const previousUser = queryClient.getQueryData(userQueryOptions.queryKey);

      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, emailSettings: variables.emailSettings };
      });

      return { previousUser };
    },
    // Use the context to roll back if the mutation fails
    onError: (error, variables, context) => {
      queryClient.setQueryData(userQueryOptions.queryKey, () => context?.previousUser);
    },
    // Always refetch after error or success
    onSettled: () => {
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
