import { AnimatePresence, motion, useAnimationFrame } from 'framer-motion';
import { useRef } from 'react';

import type { AnimateExpandProps as Props } from './types';

export const AnimateExpand = ({ children, className }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  /**
   * Add the `overflow-y: hidden` CSS rule during the animation
   */
  useAnimationFrame(() => {
    if (!ref.current) return;

    const isAnimating = ref.current.style.height !== 'auto';
    ref.current.style.overflowY = isAnimating ? 'hidden' : 'visible';
  });

  return (
    <AnimatePresence initial={false}>
      {children && (
        <motion.div
          animate="expanded"
          className={className}
          exit="collapsed"
          initial="collapsed"
          ref={ref}
          transition={{ type: 'just' }}
          variants={{ collapsed: { height: 0 }, expanded: { height: 'auto' } }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
