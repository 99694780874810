import { useTranslation } from 'react-i18next';

import { Button, Tooltip } from '~/components';
import { useModal } from '~/hooks/useModal';

import { useSectionContext } from '../Section/context';
import { DeleteSectionModal } from './DeleteSectionModal';
import styles from './SectionTitle.module.scss';

export const SectionTitle = () => {
  const { isDefaultSection, section } = useSectionContext();
  const modal = useModal();

  const { t } = useTranslation(['lines']);

  if (isDefaultSection) return null;

  return (
    <tbody>
      <tr className={styles.SectionTitle}>
        <td className={styles.Title} colSpan={5}>
          <p>
            <span className={styles.Name}>{section.name}</span>
            <Tooltip>
              <Tooltip.Trigger>
                <span className={styles.Tag}>{t('lines:photoAlbum.tag')}</span>
              </Tooltip.Trigger>

              <Tooltip.Content>{t('lines:photoAlbum.tagTooltip')}</Tooltip.Content>
            </Tooltip>
          </p>

          <p>
            <small className={styles.Description}>{t('lines:photoAlbum.description')}</small>
          </p>
        </td>
        <td>
          <Button icon="Delete" onClick={modal.open} type="iconOnly" />
        </td>
      </tr>

      {modal.isOpen && <DeleteSectionModal onClose={modal.close} />}
    </tbody>
  );
};
