import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';

import { clientsQueryOptions } from '~/api/clients';
import { conditionsQueryOptions, customConditionsQueryOptions } from '~/api/conditions';
import { creditNotesQueryOptions } from '~/api/creditNotes';
import { invoicesQueryOptions } from '~/api/invoices';
import { plansQueryOptions } from '~/api/plans';
import { productsQueryOptions } from '~/api/products';
import { quotationsQueryOptions } from '~/api/quotations';
import { userQueryOptions } from '~/api/user';
import { ErrorBoundaryWrapper, Layout, RouteErrorBoundary } from '~/components';
import { env } from '~/constants/env';
import { Activate } from '~/pages/Auth/Activate/Activate';
import { AuthLayout } from '~/pages/Auth/AuthLayout/AuthLayout';
import { ForgotPassword } from '~/pages/Auth/ForgotPassword/ForgotPassword';
import { AdminLogin } from '~/pages/Auth/Login/AdminLogin';
import { Login } from '~/pages/Auth/Login/Login';
import { Registration } from '~/pages/Auth/Registration/Registration';
import { RegistrationAccountInformation } from '~/pages/Auth/Registration/RegistrationAccountInformation';
import { RegistrationBusinessInformation } from '~/pages/Auth/Registration/RegistrationBusinessInformation';
import { RegistrationCompany } from '~/pages/Auth/Registration/RegistrationCompany';
import { RegistrationSuccess } from '~/pages/Auth/Registration/RegistrationSuccess';
import { RegistrationVatInformation } from '~/pages/Auth/Registration/RegistrationVatInformation';
import { ResetPasswordGuard } from '~/pages/Auth/ResetPassword/ResetPassword';
import { ClientQuotationView } from '~/pages/ClientQuotationView/ClientQuotationView';
import { ClientDetailGuard } from '~/pages/Clients/ClientDetail/ClientDetail';
import { ClientsOverview } from '~/pages/Clients/ClientsOverview/ClientsOverview';
import { ChooseClientType } from '~/pages/Clients/CreateClient/ChooseClientType/ChooseClientType';
import { CreateClient } from '~/pages/Clients/CreateClient/CreateClient';
import { CreateContactStep } from '~/pages/Clients/CreateClient/CreateContactStep/CreateContactStep';
import { CreateOrganisationStep } from '~/pages/Clients/CreateClient/CreateOrganistationStep/CreateOrganisationStep';
import { CreateContact } from '~/pages/Clients/CreateContact/CreateContact';
import { EditClient } from '~/pages/Clients/EditClient/EditClient';
import { EditContact } from '~/pages/Clients/EditContact/EditContact';
import { CommunityOverview } from '~/pages/Community/CommunityOverview';
import { Dashboard } from '~/pages/Dashboard/Dashboard';
import { CreateCreditNote } from '~/pages/InvoicesCreditnotes/CreateCreditNote/CreateCreditNote';
import { CreateEditInvoice } from '~/pages/InvoicesCreditnotes/CreateEditInvoice/CreateEditInvoice';
import { InvoiceDetailGuard } from '~/pages/InvoicesCreditnotes/InvoiceDetail/InvoiceDetail';
import { InvoiceDetailErrorBoundary } from '~/pages/InvoicesCreditnotes/InvoiceDetail/InvoiceDetailErrorBoundary';
import { InvoicesCreditnotesOverview } from '~/pages/InvoicesCreditnotes/InvoicesCreditnotesOverview/InvoicesCreditnotesOverview';
import { CreateEditProduct } from '~/pages/Products/CreateEditProduct/CreateEditProduct';
import { ProductsOverview } from '~/pages/Products/ProductsOverview/ProductsOverview';
import { CreateEditQuotation } from '~/pages/Quotations/CreateEditQuotation/CreateEditQuotation';
import { QuotationsOverview } from '~/pages/Quotations/QuotationsOverview/QuotationsOverview';
import { Documents } from '~/pages/Settings/Documents/Documents';
import { General } from '~/pages/Settings/General/General';
import { Integrations } from '~/pages/Settings/Integrations/Integrations';
import { OauthBillit } from '~/pages/Settings/Integrations/Oauth/OauthBillit';
import { Invitations } from '~/pages/Settings/Invitations/Invitations';
import { PaymentPlan } from '~/pages/Settings/PaymentPlan/PaymentPlan';
import { Profile } from '~/pages/Settings/Profile/Profile';
import { insertIf } from '~/utils/arrays';

import { App } from '../../App';
import { queryClient } from '../QueryClientProvider/queryClient';
import { AuthGuard, NoAuthGuard, QueryDataGuard } from './RouteGuards';
import { routes } from './router.routes';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    element: <App />,
    errorElement: (
      <ErrorBoundaryWrapper>
        <RouteErrorBoundary />
      </ErrorBoundaryWrapper>
    ),
    children: [
      // Auth routes
      {
        path: '/',
        element: (
          <NoAuthGuard>
            <AuthLayout>
              <Outlet />
            </AuthLayout>
          </NoAuthGuard>
        ),
        children: [
          {
            path: routes.login,
            element: <Login />,
          },
          {
            path: routes.adminLogin,
            element: <AdminLogin />,
          },
          {
            path: routes.register,
            element: <Registration />,
            children: [
              {
                index: true,
                element: <RegistrationVatInformation />,
              },
              {
                path: routes.registerAccountInformation,
                element: <RegistrationAccountInformation />,
              },
              {
                path: routes.registerBusinessInformation,
                element: <RegistrationBusinessInformation />,
              },
              {
                path: routes.registerCompany,
                element: <RegistrationCompany />,
              },
              {
                path: routes.registerSuccess,
                element: <RegistrationSuccess />,
              },
            ],
          },
          {
            path: routes.activate,
            element: <Activate />,
          },
          {
            path: routes.forgotPassword,
            element: <ForgotPassword />,
          },
          {
            path: routes.resetPassword,
            element: <ResetPasswordGuard />,
          },
        ],
      },

      // Authenticated routes
      {
        path: '/',
        // Fill the query cache before the page loads.
        // This ensures all suspense queries will suspend simultaneously, allowing us to show a single global loading state once.
        // The promise is not awaited so that the QueryDataGuard component renders immediately (which contains a Suspense boundary).
        // (If the promise was awaited, the user would stay on the same page until the promise resolves, or a blank screen in case of initial load.)
        loader: () => {
          return {
            queries: Promise.all([
              queryClient.ensureQueryData(userQueryOptions),
              queryClient.ensureQueryData(quotationsQueryOptions),
              queryClient.ensureQueryData(invoicesQueryOptions),
              queryClient.ensureQueryData(creditNotesQueryOptions),
              queryClient.ensureQueryData(productsQueryOptions),
              queryClient.ensureQueryData(clientsQueryOptions),
              queryClient.ensureQueryData(conditionsQueryOptions),
              queryClient.ensureQueryData(customConditionsQueryOptions),
              queryClient.ensureQueryData(plansQueryOptions),
            ]),
          };
        },
        element: (
          <AuthGuard>
            <QueryDataGuard>
              <Layout>
                <Outlet />
              </Layout>
            </QueryDataGuard>
          </AuthGuard>
        ),
        children: [
          {
            index: true,
            element: <Navigate replace to={routes.dashboard} />,
          },
          {
            path: routes.dashboard,
            element: <Dashboard />,
          },
          {
            path: routes.quotations,
            element: <QuotationsOverview />,
          },
          {
            path: routes.createQuotation,
            element: <CreateEditQuotation />,
          },
          {
            path: routes.editQuotation,
            element: <CreateEditQuotation />,
          },
          {
            path: routes.invoices,
            element: <InvoicesCreditnotesOverview />,
          },
          {
            path: routes.showInvoice,
            element: <InvoiceDetailGuard />,
            errorElement: <InvoiceDetailErrorBoundary />,
          },
          {
            path: routes.createInvoice,
            element: <CreateEditInvoice />,
          },
          {
            path: routes.editInvoice,
            element: <CreateEditInvoice />,
          },
          {
            path: routes.createCreditnote,
            element: <CreateCreditNote />,
          },
          {
            path: routes.products,
            element: <ProductsOverview />,
          },
          {
            path: routes.createProduct,
            element: <CreateEditProduct />,
          },
          {
            path: routes.editProduct,
            element: <CreateEditProduct />,
          },
          {
            path: routes.clients,
            element: <ClientsOverview />,
          },
          {
            path: routes.showClient,
            element: <ClientDetailGuard />,
          },
          {
            path: routes.createClient,
            element: <ChooseClientType />,
          },
          {
            path: routes.createClient,
            element: <CreateClient />,
            children: [
              {
                path: routes.createClientOrganisationStep,
                element: <CreateOrganisationStep />,
              },
              {
                path: routes.createClientContactStep,
                element: <CreateContactStep />,
              },
              {
                path: routes.createClientPrivateStep,
                element: <CreateContactStep />,
              },
            ],
          },
          {
            path: routes.editClient,
            element: <EditClient />,
          },
          {
            path: routes.createContact,
            element: <CreateContact />,
          },
          {
            path: routes.editContact,
            element: <EditContact />,
          },
          {
            path: routes.community,
            element: <CommunityOverview />,
          },
          {
            path: routes.settingsProfile,
            element: <Profile />,
          },
          {
            path: routes.settingsGeneral,
            element: <General />,
          },
          {
            path: routes.settingsInvitations,
            element: <Invitations />,
          },
          {
            path: routes.settingsDocuments,
            element: <Documents />,
          },
          {
            path: routes.settingsIntegrations,
            element: <Integrations />,
          },
          {
            path: routes.settingsPaymentPlan,
            element: <PaymentPlan />,
          },
          {
            path: routes.oauthBillit,
            element: <OauthBillit />,
          },
        ],
      },

      // Non-authenticated routes
      {
        path: routes.clientQuotationView,
        element: <ClientQuotationView />,
      },

      // Design system
      ...insertIf(!env.IS_PRODUCTION_ENV, {
        path: routes.designSystem,
        lazy: async () => {
          const { DesignSystem } = await import('~/pages/DesignSystem/DesignSystem');
          return { Component: DesignSystem };
        },
      }),

      // Redirect
      {
        path: '*',
        element: <Navigate replace to={routes.dashboard} />,
      },
    ],
  },
]);
