import classNames from 'classnames';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useCreateCustomCondition } from '~/api/conditions';
import { Button, Modal, Spinner } from '~/components';
import { includes } from '~/utils/arrays';

import type { Answers, CreateCustomConditionProps as Props } from './types';

import { Fieldset } from '../components/Fieldset';
import { InputFieldCheckbox } from '../components/InputFieldCheckbox';
import { InputFieldRadio } from '../components/InputFieldRadio';
import { YesNoInput } from '../components/YesNoInput';
import { initialState } from './consts';
import styles from './CreateCustomConditionModal.module.scss';
import { CustomConditionStep, ModifyHow, ReproduceHow, ReproduceHowMany, ShareHow, UseHow, UseHowLong, UseWhere } from './types';

export const CreateCustomConditionModal = ({ onClose }: Props) => {
  const mutation = useCreateCustomCondition();

  // i18n
  const { t } = useTranslation(['common', 'settings']);

  // Local state
  const [currentStep, setCurrentStep] = useState<CustomConditionStep | null>(CustomConditionStep.START);
  const [showOther, setShowOther] = useState(false);
  const [other, setOther] = useState('');
  const [answers, setAnswers] = useState<Answers>(initialState);
  const [customConditionsName, setCustomConditionsName] = useState('');
  const [stepsHistory, setStepsHistory] = useState<CustomConditionStep[]>([]);

  /**
   * Saves the given step with the given answers and goes to the given next step
   */
  const saveStepAndGoToNext = (key: CustomConditionStep, value: Answers[keyof Answers]) => {
    // Set the new answer
    const newAnswers = {
      ...answers,
      [key]: value,
    };
    setAnswers(newAnswers);

    // Push the current step in the history
    if (currentStep) {
      const newStepsHistory = [...stepsHistory];
      newStepsHistory.push(currentStep);
      setStepsHistory(newStepsHistory);
    }

    // Check if this was the last step and push data to API if so
    if (currentStep === CustomConditionStep.OVERVIEW) {
      return mutation.mutate(
        {
          name: customConditionsName,
          wizardData: answers,
        },
        {
          onSuccess: () => setCurrentStep(CustomConditionStep.THANKS),
          onError: () => toast.error(t('common:error')),
        },
      );
    }

    // Get the next step
    const nextStep = getNextStep(value);

    // Go to the next step (if necessary)
    if (nextStep !== null) {
      setShowOther(false);
      setOther('');
      setCurrentStep(nextStep as CustomConditionStep);
    } else {
      onClose();
      setCurrentStep(CustomConditionStep.START);
      setAnswers(initialState);
    }
  };

  /**
   * Defines which step is next after the How stap has been passed (because multiple flows possible)
   */
  const getNextStep = (answer: Answers[keyof Answers]) => {
    switch (currentStep) {
      case CustomConditionStep.START:
        return CustomConditionStep.ALREADY_AGREEMENT;

      case CustomConditionStep.ALREADY_AGREEMENT:
        return answer === true ? CustomConditionStep.ALREADY_AGREEMENT_PAPER : CustomConditionStep.ALREADY_AGREEMENT_NO;

      case CustomConditionStep.ALREADY_AGREEMENT_NO:
        return CustomConditionStep.TRANSFER_COPYRIGHT;

      case CustomConditionStep.ALREADY_AGREEMENT_PAPER:
        return answer === true ? CustomConditionStep.ALREADY_AGREEMENT_YES_PAPER : CustomConditionStep.ALREADY_AGREEMENT_NO_PAPER;

      case CustomConditionStep.ALREADY_AGREEMENT_NO_PAPER:
        return CustomConditionStep.TRANSFER_COPYRIGHT;

      case CustomConditionStep.ALREADY_AGREEMENT_YES_PAPER:
        return null;

      case CustomConditionStep.TRANSFER_COPYRIGHT:
        return answer === true ? CustomConditionStep.TRANSFER_COPYRIGHT_YES : CustomConditionStep.TRANSFER_COPYRIGHT_NO;

      case CustomConditionStep.TRANSFER_COPYRIGHT_YES:
        return null;

      case CustomConditionStep.TRANSFER_COPYRIGHT_NO:
        return CustomConditionStep.USE_WHERE;

      case CustomConditionStep.USE_WHERE:
        return CustomConditionStep.USE_HOW_LONG;

      case CustomConditionStep.USE_HOW_LONG:
        return CustomConditionStep.USE_HOW;

      case CustomConditionStep.USE_HOW: {
        if (answers[CustomConditionStep.USE_HOW][0] === true) return CustomConditionStep.REPRODUCE_HOW;
        if (answers[CustomConditionStep.USE_HOW][1] === true) return CustomConditionStep.SHARE_HOW;
        if (answers[CustomConditionStep.USE_HOW][2] === true) return CustomConditionStep.MODIFY_HOW;
        return CustomConditionStep.OVERVIEW;
      }
      case CustomConditionStep.REPRODUCE_HOW: {
        if (
          (answer as Answers[CustomConditionStep.REPRODUCE_HOW])[0] ||
          (answer as Answers[CustomConditionStep.REPRODUCE_HOW])[1] ||
          (answer as Answers[CustomConditionStep.REPRODUCE_HOW])[2]
        )
          return CustomConditionStep.REPRODUCE_HOW_MANY;
        if (answers[CustomConditionStep.USE_HOW][1] === true) return CustomConditionStep.SHARE_HOW;
        if (answers[CustomConditionStep.USE_HOW][2] === true) return CustomConditionStep.MODIFY_HOW;
        return CustomConditionStep.OVERVIEW;
      }
      case CustomConditionStep.REPRODUCE_HOW_MANY: {
        if (answers[CustomConditionStep.USE_HOW][1] === true) return CustomConditionStep.SHARE_HOW;
        if (answers[CustomConditionStep.USE_HOW][2] === true) return CustomConditionStep.MODIFY_HOW;
        return CustomConditionStep.OVERVIEW;
      }
      case CustomConditionStep.SHARE_HOW: {
        if (answers[CustomConditionStep.USE_HOW][2] === true) return CustomConditionStep.MODIFY_HOW;
        return CustomConditionStep.OVERVIEW;
      }
      case CustomConditionStep.MODIFY_HOW:
        return CustomConditionStep.OVERVIEW;

      case CustomConditionStep.OVERVIEW:
        return CustomConditionStep.THANKS;

      case CustomConditionStep.THANKS:
        return null;
    }
  };

  /**
   * Go to the previous step
   */
  const goToPreviousStep = () => {
    const newStepsHistory = [...stepsHistory];
    const previousStep = newStepsHistory.pop();

    setShowOther(false);
    setStepsHistory(newStepsHistory);
    setCurrentStep(previousStep || null);
  };

  return (
    <Modal onClose={onClose} variant={currentStep === CustomConditionStep.OVERVIEW ? 'wide' : null}>
      <Modal.Title>
        {currentStep !== CustomConditionStep.OVERVIEW && currentStep !== CustomConditionStep.THANKS
          ? t(`settings:general.customConditions.wizard.${CustomConditionStep.START}.title`)
          : currentStep === CustomConditionStep.OVERVIEW
            ? t(`settings:general.customConditions.wizard.${CustomConditionStep.OVERVIEW}.question`)
            : t(`settings:general.customConditions.wizard.${CustomConditionStep.THANKS}.question`)}
      </Modal.Title>

      {currentStep === CustomConditionStep.START && (
        <>
          <p>{t(`settings:general.customConditions.wizard.${CustomConditionStep.START}.description`)}</p>

          <div className={styles['wizard-content']}>
            <Button onClick={() => saveStepAndGoToNext(currentStep, true)}>
              {t(`settings:general.customConditions.wizard.${currentStep}.ok`)}
            </Button>
          </div>
        </>
      )}

      {!includes([CustomConditionStep.START, CustomConditionStep.OVERVIEW, CustomConditionStep.THANKS] as const, currentStep) && (
        <div>
          <form>
            {currentStep === CustomConditionStep.ALREADY_AGREEMENT && (
              <Fieldset step={currentStep}>
                <YesNoInput answers={answers} saveAndNext={(value) => saveStepAndGoToNext(currentStep, value)} step={currentStep} />
              </Fieldset>
            )}
            {(currentStep === CustomConditionStep.ALREADY_AGREEMENT_NO ||
              currentStep === CustomConditionStep.ALREADY_AGREEMENT_NO_PAPER) && (
              <Fieldset step={currentStep}>
                <Button onClick={() => saveStepAndGoToNext(currentStep, true)}>
                  {t('settings:general.customConditions.wizard.common_answers.start_questions')}
                </Button>
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.ALREADY_AGREEMENT_PAPER && (
              <Fieldset step={currentStep}>
                <YesNoInput answers={answers} saveAndNext={(value) => saveStepAndGoToNext(currentStep, value)} step={currentStep} />
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.ALREADY_AGREEMENT_YES_PAPER && (
              <Fieldset step={currentStep}>
                <Button onClick={() => saveStepAndGoToNext(currentStep, true)} type="secondary">
                  {t('settings:general.customConditions.wizard.common_answers.close')}
                </Button>
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.TRANSFER_COPYRIGHT && (
              <Fieldset step={currentStep}>
                <YesNoInput answers={answers} saveAndNext={(value) => saveStepAndGoToNext(currentStep, value)} step={currentStep} />
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.TRANSFER_COPYRIGHT_YES && (
              <Fieldset step={currentStep}>
                <Button icon="Close" onClick={() => saveStepAndGoToNext(currentStep, true)}>
                  {t('settings:general.customConditions.wizard.common_answers.close')}
                </Button>
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.TRANSFER_COPYRIGHT_NO && (
              <Fieldset step={currentStep}>
                <Button onClick={() => saveStepAndGoToNext(currentStep, true)}>
                  {t('settings:general.customConditions.wizard.common_answers.ok')}
                </Button>
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.USE_WHERE && (
              <Fieldset key={currentStep} step={currentStep}>
                {[UseWhere.BELGIUM, UseWhere.EUROPE, UseWhere.WORLD, UseWhere.OTHER].map((option, index) => {
                  return (
                    <InputFieldRadio
                      checked={answers[currentStep].selected === index}
                      key={index}
                      onChange={() => {
                        if (index !== 3) saveStepAndGoToNext(currentStep, { selected: index, text: null });
                        if (index === 3) {
                          setAnswers({ ...answers, [currentStep]: { selected: index, text: null } });
                          setShowOther(true);
                        }
                      }}
                      onClick={() => {
                        if (index !== 3) saveStepAndGoToNext(currentStep, { selected: index, text: null });
                        if (index === 3) {
                          setAnswers({ ...answers, [currentStep]: { selected: index, text: null } });
                          setShowOther(true);
                        }
                      }}
                      step={currentStep}
                      value={option}
                    >
                      {t(`settings:general.customConditions.wizard.use_where.options.${option}`)}
                    </InputFieldRadio>
                  );
                })}
                {(showOther || answers[currentStep].text) && (
                  <div className={styles['other-form']}>
                    <input
                      className="mb-2"
                      name="other"
                      onChange={(e) => setOther(e.currentTarget.value)}
                      onClick={(e) => setOther(e.currentTarget.value)}
                      placeholder={t('settings:general.customConditions.wizard.use_where.other_placeholder')}
                      required
                      type="text"
                      value={other || answers[currentStep].text || ''}
                    />
                    <Button onClick={() => saveStepAndGoToNext(currentStep, { selected: 3, text: other })}>
                      {t('settings:general.customConditions.wizard.common_answers.next')}
                    </Button>
                  </div>
                )}
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.USE_HOW_LONG && (
              <Fieldset step={currentStep}>
                {[UseHowLong.FULL, UseHowLong.OTHER].map((option, index) => {
                  return (
                    <InputFieldRadio
                      checked={answers[currentStep].selected === index}
                      key={index}
                      onChange={() => {
                        if (index !== 1) saveStepAndGoToNext(currentStep, { selected: index, text: null });
                        if (index === 1) {
                          setAnswers({ ...answers, [currentStep]: { selected: index, text: null } });
                          setShowOther(true);
                        }
                      }}
                      onClick={() => {
                        if (index !== 1) saveStepAndGoToNext(currentStep, { selected: index, text: null });
                        if (index === 1) {
                          setAnswers({ ...answers, [currentStep]: { selected: index, text: null } });
                          setShowOther(true);
                        }
                      }}
                      step={currentStep}
                      value={option}
                    >
                      {t(`settings:general.customConditions.wizard.use_how_long.options.${option}`)}
                    </InputFieldRadio>
                  );
                })}
                {(showOther || answers[currentStep].text) && (
                  <div className={styles['other-form']}>
                    <input
                      className="mb-2"
                      name="other"
                      onChange={(e) => setOther(e.currentTarget.value)}
                      onClick={(e) => setOther(e.currentTarget.value)}
                      placeholder={t('settings:general.customConditions.wizard.use_how_long.other_placeholder')}
                      required
                      type="text"
                      value={other || answers[currentStep].text || ''}
                    />
                    <Button onClick={() => saveStepAndGoToNext(currentStep, { selected: 1, text: other })}>
                      {t('settings:general.customConditions.wizard.common_answers.next')}
                    </Button>
                  </div>
                )}
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.USE_HOW && (
              <Fieldset step={currentStep}>
                {[UseHow.REPRODUCE, UseHow.SHARE_PUBLIC, UseHow.EDIT].map((option) => {
                  return (
                    <InputFieldCheckbox
                      checked={answers[currentStep][option]}
                      key={option}
                      onChange={() => {
                        setAnswers({ ...answers, [currentStep]: { ...answers[currentStep], [option]: !answers[currentStep][option] } });
                      }}
                      step={currentStep}
                      value={option}
                    >
                      {t(`settings:general.customConditions.wizard.use_how.options.${option}`)}
                    </InputFieldCheckbox>
                  );
                })}
                <div className={styles['other-form']}>
                  <Button
                    disabled={!Object.values(answers[currentStep]).some((answer) => answer === true)}
                    onClick={() => saveStepAndGoToNext(currentStep, answers[currentStep])}
                  >
                    {t('settings:general.customConditions.wizard.common_answers.next')}
                  </Button>
                </div>
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.REPRODUCE_HOW && (
              <Fieldset step={currentStep}>
                {[ReproduceHow.ANY, ReproduceHow.SPECIFIC, ReproduceHow.ELECTRONIC_FIXED, ReproduceHow.ELECTRONIC_ONLINE].map((option) => {
                  return (
                    <Fragment key={option}>
                      <InputFieldCheckbox
                        checked={answers[currentStep][option]}
                        onChange={() => {
                          if (option === ReproduceHow.ANY) {
                            setAnswers({
                              ...answers,
                              [currentStep]: {
                                0: !answers[currentStep][option],
                                1: false,
                                2: false,
                                3: false,
                                text: null,
                              },
                            });
                          } else {
                            setAnswers({
                              ...answers,
                              [currentStep]: {
                                ...answers[currentStep],
                                [option]: !answers[currentStep][option],
                                0: false,
                              },
                            });
                          }
                        }}
                        step={currentStep}
                        value={option}
                      >
                        {t(`settings:general.customConditions.wizard.reproduce_how.options.${option}`)}
                      </InputFieldCheckbox>
                      {option === 1 && answers[currentStep][1] && (
                        <input
                          className="my-2"
                          name="other"
                          onChange={(e) => setOther(e.currentTarget.value)}
                          onClick={(e) => setOther(e.currentTarget.value)}
                          placeholder={t('settings:general.customConditions.wizard.reproduce_how.other_placeholder')}
                          required
                          type="text"
                          value={other || answers[currentStep].text || ''}
                        />
                      )}
                    </Fragment>
                  );
                })}
                <div className={styles['other-form']}>
                  <Button
                    disabled={!Object.values(answers[currentStep]).some((answer) => answer === true)}
                    onClick={() =>
                      saveStepAndGoToNext(currentStep, { ...answers[currentStep], text: answers[currentStep][1] ? other : null })
                    }
                  >
                    {t('settings:general.customConditions.wizard.common_answers.next')}
                  </Button>
                </div>
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.REPRODUCE_HOW_MANY && (
              <Fieldset step={currentStep}>
                {[ReproduceHowMany.UNLIMITED, ReproduceHowMany.LIMITED].map((option, index) => {
                  return (
                    <InputFieldRadio
                      checked={answers[currentStep].selected === index}
                      key={index}
                      onChange={() => {
                        if (index === 1) {
                          setAnswers({ ...answers, [currentStep]: { selected: 1, text: '' } });
                          setShowOther(true);
                        } else {
                          saveStepAndGoToNext(currentStep, { selected: index, text: null });
                        }
                      }}
                      onClick={() => {
                        if (index === 1) {
                          setAnswers({ ...answers, [currentStep]: { selected: 1, text: '' } });
                          setShowOther(true);
                        } else {
                          saveStepAndGoToNext(currentStep, { selected: index, text: null });
                        }
                      }}
                      step={currentStep}
                      value={option}
                    >
                      {t(`settings:general.customConditions.wizard.reproduce_how_many.options.${option}`)}
                    </InputFieldRadio>
                  );
                })}
                {(showOther || answers[currentStep].text) && (
                  <div className={styles['other-form']}>
                    <input
                      className="mb-2"
                      name="other"
                      onChange={(e) => setOther(e.currentTarget.value)}
                      onClick={(e) => setOther(e.currentTarget.value)}
                      placeholder={t('settings:general.customConditions.wizard.reproduce_how_many.other_placeholder')}
                      required
                      type="text"
                      value={other || answers[currentStep].text || ''}
                    />
                    <Button onClick={() => saveStepAndGoToNext(currentStep, { selected: 1, text: other })}>
                      {t('settings:general.customConditions.wizard.common_answers.next')}
                    </Button>
                  </div>
                )}
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.SHARE_HOW && (
              <Fieldset step={currentStep}>
                {[ShareHow.PUBLISHED, ShareHow.DISPOSABLE, ShareHow.BOOK, ShareHow.PROMO].map((option, index) => {
                  return (
                    <InputFieldRadio
                      checked={answers[currentStep].selected === index}
                      key={index}
                      onChange={() => {
                        if (index === 0 || index === 1) {
                          setShowOther(true);
                        } else {
                          setShowOther(false);
                        }
                        setAnswers({ ...answers, [currentStep]: { selected: index, text: '' } });
                      }}
                      onClick={() => {
                        if (index === 0 || index === 1) {
                          setShowOther(true);
                        } else {
                          setShowOther(false);
                        }
                        setAnswers({ ...answers, [currentStep]: { selected: index, text: '' } });
                      }}
                      step={currentStep}
                      value={option}
                    >
                      {t(`settings:general.customConditions.wizard.share_how.options.${option}`)}
                    </InputFieldRadio>
                  );
                })}
                <div className={styles['other-form']}>
                  {(showOther || answers[currentStep].text) && (
                    <input
                      className="mb-2"
                      name="other"
                      onChange={(e) => setOther(e.currentTarget.value)}
                      onClick={(e) => setOther(e.currentTarget.value)}
                      placeholder={t('settings:general.customConditions.wizard.share_how.other_placeholder')}
                      required
                      type="text"
                      value={other || answers[currentStep].text || ''}
                    />
                  )}
                  <Button
                    disabled={answers[currentStep].selected === null}
                    onClick={() => saveStepAndGoToNext(currentStep, { selected: answers[currentStep].selected, text: other })}
                  >
                    {t('settings:general.customConditions.wizard.common_answers.next')}
                  </Button>
                </div>
              </Fieldset>
            )}
            {currentStep === CustomConditionStep.MODIFY_HOW && (
              <Fieldset step={currentStep}>
                {[ModifyHow.EDIT, ModifyHow.TRANSLATE].map((option) => {
                  return (
                    <InputFieldRadio
                      checked={!!answers[currentStep]}
                      key={option}
                      onChange={() => saveStepAndGoToNext(currentStep, option)}
                      onClick={() => saveStepAndGoToNext(currentStep, option)}
                      step={currentStep}
                      value={option}
                    >
                      {t(`settings:general.customConditions.wizard.modify_how.options.${option}`)}
                    </InputFieldRadio>
                  );
                })}
              </Fieldset>
            )}
          </form>

          <Button extraClasses={styles.previous} icon="ArrowBack" onClick={() => goToPreviousStep()} type="tertiary">
            {t('settings:general.customConditions.wizard.common_answers.previous')}
          </Button>
        </div>
      )}

      {currentStep === CustomConditionStep.OVERVIEW && (
        <div>
          <div className={classNames(styles['wizard-content'], styles['is-grid'])}>
            <div>
              <p>
                <strong>{t('settings:general.customConditions.wizard.use_where.question')}</strong>
                <br />
                {t(
                  `settings:general.customConditions.wizard.use_where.options.${answers[CustomConditionStep.USE_WHERE].selected as UseWhere}`,
                )}
                {answers[CustomConditionStep.USE_WHERE].selected === 3 && `(${answers[CustomConditionStep.USE_WHERE].text})`}
              </p>
            </div>
            <div>
              <p>
                <strong>{t('settings:general.customConditions.wizard.use_how_long.question')}</strong>
                <br />
                {t(
                  `settings:general.customConditions.wizard.use_how_long.options.${
                    answers[CustomConditionStep.USE_HOW_LONG].selected as UseHowLong
                  }`,
                )}
                {answers[CustomConditionStep.USE_HOW_LONG].selected === 1 && ` (${answers[CustomConditionStep.USE_HOW_LONG].text})`}
              </p>
            </div>
            <div>
              <p>
                <strong>{t('settings:general.customConditions.wizard.use_how.question')}</strong>
                <br />
                {Object.values(answers[CustomConditionStep.USE_HOW]).map((answer, index) => {
                  if (answer === true) {
                    return (
                      <span key={index}>
                        {t(`settings:general.customConditions.wizard.use_how.options.${index as UseHow}`)} <br />
                      </span>
                    );
                  }
                })}
              </p>
            </div>
            {answers[CustomConditionStep.USE_HOW][0] === true && (
              <div>
                <p>
                  <strong>{t('settings:general.customConditions.wizard.reproduce_how.question')}</strong>
                  <br />
                  {Object.values(answers[CustomConditionStep.REPRODUCE_HOW]).map((answer, index) => {
                    if (index !== 4 && answer === true) {
                      return (
                        <span key={index}>
                          {t(`settings:general.customConditions.wizard.reproduce_how.options.${index as ReproduceHow}`)}
                          {index === 1 && answer && ` (${answers[CustomConditionStep.REPRODUCE_HOW].text})`}
                          <br />
                        </span>
                      );
                    }
                  })}
                </p>
              </div>
            )}
            {answers[CustomConditionStep.USE_HOW][0] === true &&
              (answers[CustomConditionStep.REPRODUCE_HOW][0] === true ||
                answers[CustomConditionStep.REPRODUCE_HOW][1] === true ||
                answers[CustomConditionStep.REPRODUCE_HOW][2] === true) && (
                <div>
                  <p>
                    <strong>{t('settings:general.customConditions.wizard.reproduce_how_many.question')}</strong>
                    <br />
                    {t(
                      `settings:general.customConditions.wizard.reproduce_how_many.options.${
                        answers[CustomConditionStep.REPRODUCE_HOW_MANY].selected as ReproduceHowMany
                      }`,
                    )}
                    {answers[CustomConditionStep.REPRODUCE_HOW_MANY].selected === 1 &&
                      ` (${answers[CustomConditionStep.REPRODUCE_HOW_MANY].text})`}
                  </p>
                </div>
              )}
            {answers[CustomConditionStep.USE_HOW][1] === true && (
              <div>
                <p>
                  <strong>{t('settings:general.customConditions.wizard.share_how.question')}</strong>
                  <br />
                  {answers[CustomConditionStep.SHARE_HOW].selected === 0 || answers[CustomConditionStep.SHARE_HOW].selected === 1
                    ? t(
                        `settings:general.customConditions.wizard.share_how.options.${answers[CustomConditionStep.SHARE_HOW].selected}`,
                      ).replace('...', answers[CustomConditionStep.SHARE_HOW].text ?? '')
                    : t(
                        `settings:general.customConditions.wizard.share_how.options.${
                          answers[CustomConditionStep.SHARE_HOW].selected as ShareHow
                        }`,
                      )}
                </p>
              </div>
            )}
            {answers[CustomConditionStep.USE_HOW][2] === true && (
              <div>
                <p>
                  <strong>{t('settings:general.customConditions.wizard.modify_how.question')}</strong>
                  <br />
                  {t(`settings:general.customConditions.wizard.modify_how.options.${answers[CustomConditionStep.MODIFY_HOW] as ModifyHow}`)}
                </p>
              </div>
            )}
            <form
              className={styles['overview-form']}
              onSubmit={(e) => {
                e.preventDefault();
                saveStepAndGoToNext(currentStep, true);
              }}
            >
              <div>
                <label htmlFor="customConditionsName">
                  <strong>{t('settings:general.customConditions.wizard.overview.name.label')}</strong>
                </label>
                <input
                  className="form-control"
                  name="customConditionsName"
                  onChange={(e) => setCustomConditionsName(e.currentTarget.value)}
                  placeholder={t('settings:general.customConditions.wizard.overview.name.placeholder')}
                  required
                  type="text"
                  value={customConditionsName}
                />
              </div>
              <Button disabled={mutation.isPending} extraClasses="mt-2" isSubmit>
                {mutation.isPending && <Spinner size={24} />}
                <span>{t('settings:general.customConditions.wizard.overview.finish')}</span>
              </Button>
            </form>
          </div>

          <Button extraClasses={styles.previous} icon="ArrowBack" onClick={() => goToPreviousStep()} type="tertiary">
            {t('settings:general.customConditions.wizard.common_answers.previous')}
          </Button>
        </div>
      )}

      {currentStep === CustomConditionStep.THANKS && (
        <div>
          <p className="my-6">
            <strong>{t('settings:general.customConditions.wizard.thanks.subtitle')}</strong>
            <br />
            {t('settings:general.customConditions.wizard.thanks.explanation')}
          </p>

          <Button onClick={() => saveStepAndGoToNext(currentStep, true)}>
            {t('settings:general.customConditions.wizard.thanks.close')}
          </Button>
        </div>
      )}
    </Modal>
  );
};
