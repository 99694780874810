import classNames from 'classnames';
import { useReducer } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { PlanId } from '~/api/plans/constants';
import { AnimateExpand } from '~/components';
import { Icon } from '~/components/SVG';
import { useIntl } from '~/hooks/useIntl';
import { routes } from '~/providers/RouterProvider/router.routes';
import { getActiveCommissionBracket } from '~/utils/user';

import type { CurrentCommissionProps as Props } from './types';

import styles from './CurrentCommission.module.scss';

export const CurrentCommission = ({ user }: Props) => {
  const [shouldShowDetails, toggleShouldShowDetails] = useReducer((prev) => !prev, false);
  const { t } = useTranslation(['settings']);

  const activeBracket = getActiveCommissionBracket(user);
  const hasFixedCreativePercentage =
    user.planId === PlanId.earlyAdopter ||
    (user.commissionPercentages.percentagesCreative[1] === user.commissionPercentages.percentagesCreative[2] &&
      user.commissionPercentages.percentagesCreative[2] === user.commissionPercentages.percentagesCreative[3]);

  const creativeCommissionPercentage = user.yearlyCopyrightLimitExceeded
    ? 0
    : user.planId === PlanId.earlyAdopter
      ? user.commissionPercentages.percentagesCreative[1]
      : user.commissionPercentages.percentagesCreative[activeBracket];

  const nonCreativeCommissionPercentage = user.yearlyCopyrightLimitExceeded
    ? 0
    : user.planId === PlanId.earlyAdopter
      ? user.commissionPercentages.percentagesNotCreative[1]
      : user.commissionPercentages.percentagesNotCreative[activeBracket];

  const activeDiscounts = user.invitorDiscounts.filter(({ isActive }) => isActive);
  const discountPercentage = activeDiscounts.reduce((total, { percentage }) => Math.min(total + percentage, 1), 0);
  const creativeCommissionPercentageWithDiscount = Math.max(creativeCommissionPercentage * (1 - discountPercentage), 0);
  const nonCreativeCommissionPercentageWithDiscount = Math.max(nonCreativeCommissionPercentage * (1 - discountPercentage), 0);

  const { formatDecimal } = useIntl();

  return (
    <div className={styles.Wrapper}>
      <div className={styles.Header}>
        <p className={styles.Percentage}>{formatDecimal(creativeCommissionPercentageWithDiscount)}%</p>
        <div className={styles.Text}>
          <p>{t('settings:paymentPlan.commission.currentCommission')}</p>
          <p>
            <span>{t(`settings:paymentPlan.commission.planName.${user.planId}`)}</span>
            {creativeCommissionPercentageWithDiscount > 0 && discountPercentage > 0 && (
              <button
                className={classNames(styles.DetailsToggle, shouldShowDetails && styles.Open)}
                onClick={() => toggleShouldShowDetails()}
              >
                <span>{t('settings:paymentPlan.commission.details.toggle')}</span>
                <Icon name="KeyboardArrowDown" size={20} />
              </button>
            )}
          </p>
        </div>
      </div>

      <AnimateExpand>
        {shouldShowDetails && (
          <>
            <div className={styles.Separator} />

            <div className={styles.Details}>
              <div className={styles.DetailsRow}>
                <p>{t('settings:paymentPlan.commission.details.baseCommission')}</p>
                <p className={styles.DetailsRow__Percentage}>{formatDecimal(creativeCommissionPercentage)}%</p>
                {!hasFixedCreativePercentage && (
                  <p className={styles.DetailsRow__Info}>{t(`settings:paymentPlan.commission.details.bracket.${activeBracket}`)}</p>
                )}
              </div>

              {discountPercentage > 0 && (
                <div className={styles.DetailsRow}>
                  <p>{t('settings:paymentPlan.commission.details.referral', { amount: formatDecimal(discountPercentage * 100) })}</p>
                  <p className={styles.DetailsRow__Percentage}>- {formatDecimal(creativeCommissionPercentage * discountPercentage)}%</p>
                  <div className={classNames(styles.DetailsRow__Info, styles.DiscountNames)}>
                    {activeDiscounts.map(({ id, inviteeName }) => (
                      <p className={styles.DiscountName} key={id}>
                        <Icon name="Sell" size={18} />
                        <span>{inviteeName}</span>
                      </p>
                    ))}
                    {Array.from({ length: Math.max(4 - activeDiscounts.length, 0) })
                      .fill(null)
                      .map((_, i) => (
                        <p className={styles.DiscountLink} key={i}>
                          <Icon name="Sell" size={18} />
                          <Link to={routes.settingsInvitations}>{t('settings:paymentPlan.commission.details.invite')}</Link>
                        </p>
                      ))}
                  </div>
                </div>
              )}

              <div className={classNames(styles.DetailsRow, styles.Total)}>
                <p>{t('settings:paymentPlan.commission.details.total')}</p>
                <p className={styles.DetailsRow__Percentage}>{formatDecimal(creativeCommissionPercentageWithDiscount)}%</p>
              </div>
            </div>
          </>
        )}
      </AnimateExpand>

      <div className={styles.Separator} />

      <div className={styles.Description}>
        <ul>
          <li>
            <Trans
              components={{ a: <a /> }}
              i18nKey={
                nonCreativeCommissionPercentageWithDiscount > 0
                  ? 'settings:paymentPlan.commission.description.creative'
                  : 'settings:paymentPlan.commission.description.creativeFree'
              }
              values={{ percentage: formatDecimal(creativeCommissionPercentageWithDiscount) }}
            />
          </li>
          <li>
            <Trans
              components={{ a: <a /> }}
              i18nKey={
                nonCreativeCommissionPercentageWithDiscount > 0
                  ? 'settings:paymentPlan.commission.description.nonCreative'
                  : 'settings:paymentPlan.commission.description.nonCreativeFree'
              }
              values={{ percentage: formatDecimal(nonCreativeCommissionPercentageWithDiscount) }}
            />
          </li>
          <li>
            <Trans components={{ a: <a /> }} i18nKey="settings:paymentPlan.commission.description.subcontractingFree" />
          </li>
        </ul>
      </div>

      {(user.commissionPercentages.adminOverride || user.yearlyCopyrightLimitExceeded) && (
        <>
          <div className={styles.Separator} />

          <div className={styles.Disclaimer}>
            <Icon name="ErrorOutline" size={18} />
            <span>
              {user.yearlyCopyrightLimitExceeded
                ? t('settings:paymentPlan.commission.disclaimer.copyrightLimitExceeded')
                : hasFixedCreativePercentage && user.planId !== PlanId.earlyAdopter
                  ? t('settings:paymentPlan.commission.disclaimer.adminOverrideFixed')
                  : t('settings:paymentPlan.commission.disclaimer.adminOverride')}
            </span>
          </div>
        </>
      )}

      {(user.commissionPercentages.adminOverride ||
        user.yearlyCopyrightLimitExceeded ||
        user.inviteeDiscount ||
        discountPercentage > 0) && (
        <div className={styles.Tags}>
          {discountPercentage > 0 && (
            <span className={classNames(styles.Tag, styles.Tag__Discount)}>
              <Icon name="Sell" size={14} />
              <span>{t('settings:paymentPlan.tags.discount')}</span>
            </span>
          )}

          {user.inviteeDiscount?.type && !user.commissionPercentages.adminOverride && (
            <span className={classNames(styles.Tag, styles.Tag__Promotion)}>
              {t(`settings:paymentPlan.tags.${user.inviteeDiscount.type}`, { daysValid: user.inviteeDiscount.daysValid })}
            </span>
          )}

          {user.commissionPercentages.adminOverride && !user.yearlyCopyrightLimitExceeded && (
            <span className={classNames(styles.Tag, styles.Tag__CustomPercentage)}>{t('settings:paymentPlan.tags.customPercentage')}</span>
          )}

          {user.yearlyCopyrightLimitExceeded && (
            <span className={classNames(styles.Tag, styles.Tag__CustomPercentage)}>
              {t('settings:paymentPlan.tags.copyrightLimitExceeded')}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
