import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useUnlinkAccountant = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request('/integrations/accountant/unlink', { method: 'POST', token });
    },
    onSuccess: () => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, accountant: null };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
