import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { Client } from '~/api/clients/types';

import { useDeleteClient } from '~/api/clients';
import { Button, Modal } from '~/components';

export const DeleteClientModal = ({ client, onClose }: { client: Client; onClose: () => void }) => {
  const mutation = useDeleteClient(client.id);

  const { t } = useTranslation(['clients', 'common']);

  const handleDelete = () => {
    mutation.mutate(undefined, {
      onSuccess: () => toast.success(t('clients:alerts.successDeleted')),
      onError: () => toast.error(t('common:error')),
      onSettled: () => onClose(),
    });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('clients:overview.delete.title')}</Modal.Title>

      <p>{t('clients:overview.delete.description', { name: client.name })}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Delete" isLoading={mutation.isPending} onClick={handleDelete}>
          {t('clients:overview.delete.submit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
