import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { CreditNote } from '~/api/creditNotes/types';

import { useDownloadCreditNote } from '~/api/creditNotes';
import { DropdownMenu } from '~/components';

export const CreditNotesTableOptions = ({ creditNote }: { creditNote: CreditNote }) => {
  const downloadMutation = useDownloadCreditNote();

  const { t } = useTranslation(['common', 'creditnotes']);

  if (!creditNote.allowed) return null;

  return (
    <DropdownMenu
      dropdownClass="text-sm"
      isLoading={downloadMutation.isPending}
      items={[
        {
          iconName: 'Download',
          text: t('creditnotes:overview.rowActions.download'),
          onClick: () =>
            downloadMutation.mutate(creditNote.id, {
              onError: () => toast.error(t('common:error')),
            }),
        },
      ]}
    />
  );
};
