import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import { styles } from './ButtonGroup.styles';

type Props = {
  children: ReactNode;
  end?: boolean;
  styles?: stylex.StyleXStyles;
  wrapReverse?: boolean;
};

export const ButtonGroup = ({ children, end = false, styles: styleOverrides, wrapReverse = false }: Props) => {
  return <div {...stylex.props(styles.buttonGroup, end && styles.end, wrapReverse && styles.wrapReverse, styleOverrides)}>{children}</div>;
};
