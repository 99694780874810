import * as stylex from '@stylexjs/stylex';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';

import { styles } from './AuthLayoutFooter.styles';

const socials = ['website', 'instagram', 'facebook', 'linkedIn'] as const;

export const AuthLayoutFooter = () => {
  const { t } = useTranslation(['auth']);

  return (
    <div {...stylex.props(styles.footer)}>
      <span>{t('auth:footer.trademark', { year: dayjs().year() })}</span>
      <div {...stylex.props(styles.socialsList)}>
        {socials.map((social) => (
          <a
            href={t(`auth:footer.socialLinks.${social}`)}
            key={social}
            rel="noreferrer"
            target="_blank"
            {...stylex.props(styles.socialLink)}
          >
            <Icon
              name={match(social)
                .returnType<IconName>()
                .with('website', () => 'Language')
                .with('instagram', () => 'Instagram')
                .with('facebook', () => 'Facebook')
                .with('linkedIn', () => 'LinkedIn')
                .exhaustive()}
              size={20}
            />
          </a>
        ))}
      </div>
    </div>
  );
};
