import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { quotationAttachmentQueryOptions } from './useQuotationAttachment';

export const useRemoveQuotationAttachment = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request(`/quotations/${id}/attachment`, { method: 'DELETE', token });
    },
    onSuccess: () => {
      queryClient.setQueryData(quotationAttachmentQueryOptions(id).queryKey, null);
    },
  });
};
