import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useCreditNotes, useDownloadCreditNotes } from '~/api/creditNotes';
import { Button } from '~/components';
import { ColumnIds } from '~/constants/table';
import { useTable } from '~/hooks/useTable';

import { useCreditNotesTableColumns, useCreditNotesTableFilterOptions } from './CreditNotesTable.hooks';

export const CreditNotesTable = () => {
  const { data: creditNotes } = useCreditNotes();
  const downloadCreditNotesMutation = useDownloadCreditNotes();

  const { t } = useTranslation(['common', 'filters']);

  const downloadCreditNotes = () => {
    downloadCreditNotesMutation.mutate(table.getSelectedRowIds(), {
      onError: () => toast.error(t('common:error')),
    });
  };

  const columns = useCreditNotesTableColumns();

  const data = creditNotes;
  const [table, Table] = useTable({
    data,
    columns,
    initialState: {
      columnVisibility: {
        [ColumnIds.creditNotesBrand]: false,
        [ColumnIds.creditNotesContact]: false,
        [ColumnIds.creditNotesDocYear]: false,
        [ColumnIds.creditNotesDocQuarter]: false,
      },
      sorting: [
        {
          id: ColumnIds.creditNotesDocDate,
          desc: true,
        },
      ],
    },
    enableRowSelection: (row) => !!row.original.allowed,
    enableSortingRemoval: false,
  });

  const filterOptions = useCreditNotesTableFilterOptions(data);

  const selectedRowsCount = table.getSelectedRowIds().length;

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <div className="flex flex-grow gap-4">
          <Table.Search />
          <Table.FiltersToggle />
        </div>

        {selectedRowsCount > 0 && (
          <Button disabled={downloadCreditNotesMutation.isPending} onClick={downloadCreditNotes}>
            {`${t('common:datatable.download')} (${selectedRowsCount})`}
          </Button>
        )}
      </Table.Menu>

      <Table.Filters
        options={{
          [ColumnIds.creditNotesClient]: filterOptions.clients,
          [ColumnIds.creditNotesBrand]: filterOptions.brands,
          [ColumnIds.creditNotesDocYear]: filterOptions.docYears,
          [ColumnIds.creditNotesDocQuarter]: filterOptions.docQuarters,
          [ColumnIds.creditNotesStatus]: filterOptions.statuses,
        }}
      >
        <Table.Filter columnId={ColumnIds.creditNotesClient} type="combobox" />
        {filterOptions.brands.length > 0 && <Table.Filter columnId={ColumnIds.creditNotesBrand} type="select" />}
        <Table.Filter columnId={ColumnIds.creditNotesDocYear} type="multiselect" />
        <Table.Filter columnId={ColumnIds.creditNotesDocQuarter} type="multiselect" />
        <Table.Filter columnId={ColumnIds.creditNotesStatus} type="multiselect" />
      </Table.Filters>

      <Table.Grid>
        <Table.Header />
        <Table.Selection />
        <Table.Body>
          {(row) => <Table.Row onClick={row.original.allowed ? row.getToggleSelectedHandler() : undefined} row={row} />}
        </Table.Body>
      </Table.Grid>

      <Table.Pagination showPageSizeOptions />
    </Table.Root>
  );
};
