import * as stylex from '@stylexjs/stylex';

import type { MediaQueryTinyMobileAndBelow } from '~/styles/mediaQueries';

import { brandColors, fontWeight, neutralColors, radius, spacing, systemColors } from '~/styles/tokens.stylex';

const TinyMobileAndBelow: MediaQueryTinyMobileAndBelow = '@media (width < 400px)';

export const styles = stylex.create({
  card: {
    padding: spacing.normal,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: systemColors.borderLight,
    borderRadius: radius.xxsmall,
  },

  header: {
    display: 'flex',
    gap: spacing.xsmall,
    alignItems: 'center',
  },

  titleDiscount: {
    marginInlineStart: 'auto',
    textAlign: 'right',
  },

  noDiscounts: {
    color: systemColors.textMuted,
    fontStyle: 'italic',
  },

  commissionSaved: {
    marginBlockEnd: spacing.small,
    padding: spacing.xsmall,
    textAlign: 'center',
    color: neutralColors.white,
    backgroundColor: brandColors.primary500,
    borderRadius: radius.xxxsmall,
  },

  discountType: {
    marginBlockEnd: spacing.xsmall,
  },

  discounts: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xsmall,
  },

  discount: {
    marginInlineStart: spacing.small,
    display: 'grid',
    columnGap: spacing.xxsmall,
    alignItems: 'center',
    gridTemplateColumns: {
      default: 'auto auto 1fr',
      [TinyMobileAndBelow]: 'auto 1fr',
    },
    gridTemplateAreas: {
      default: '"icon name date"',
      [TinyMobileAndBelow]: `
        "icon name"
        ". date"
      `,
    },
  },

  icon: {
    gridArea: 'icon',
    color: brandColors.primary500,
  },

  iconMuted: {
    color: brandColors.primary200,
  },

  iconInvisible: {
    visibility: 'hidden',
  },

  inviteeName: {
    gridArea: 'name',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  date: {
    gridArea: 'date',
    textAlign: {
      default: 'right',
      [TinyMobileAndBelow]: 'unset',
    },
    color: systemColors.textMuted,
  },

  inviteAnotherUserButton: {
    fontWeight: fontWeight.regular,
    textDecoration: 'none',
    color: {
      default: brandColors.primary300,
      ':hover': brandColors.primary400,
    },
  },
});
