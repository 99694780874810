import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';

export const useResendVerificationEmail = () => {
  return useMutation({
    mutationFn: (emailAddress: string) => {
      const body = { emailAddress };
      return request('/registration/resend-verification-email', { method: 'POST', body });
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) &&
      captureException(new Error('Failed to resend verification email', { cause: error }), { level: 'error' }),
  });
};
