import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { Brand } from '~/types/user';

import { getAccessToken } from '~/utils/auth';

import type { CreateBrandPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useCreateBrand = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateBrandPayload) => {
      const token = getAccessToken();
      const response = await request('/brands', { method: 'POST', token, body });

      // TODO: Schema validation
      const { data: brand } = apiResourceSchema(z.unknown()).parse(response);

      return brand as Brand;
    },
    onSuccess: (brand) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, brands: [...user.brands, brand] };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
