import { Fragment, useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { AnimateExpand, Button, FormCheckbox, FormControl, FormLabel, FormTextInput } from '~/components';
import { Icon } from '~/components/SVG';
import { generateEntryNumber } from '~/utils/string';

import type { EditEntryNumberFormProps as Props } from './types';

export const EditEntryNumberForm = ({ entryNumber, form, numberingSeries, type }: Props) => {
  const [showEntryNumberForm, toggleShowEntryNumberForm] = useReducer((show) => !show, false);

  const {
    control,
    formState: { defaultValues },
    getValues,
    register,
    reset,
    setValue,
    watch,
  } = form;

  const showSuffix = watch('showSuffix');
  const watchNextNumber = watch('nextNumber');

  const incrementNextNumber = useCallback(() => setValue('nextNumber', getValues('nextNumber') + 1), [setValue, getValues]);
  const decrementNextNumber = useCallback(() => setValue('nextNumber', getValues('nextNumber') - 1), [setValue, getValues]);

  const previewNextNumber = `${watchNextNumber}`.padStart(numberingSeries.format.amountOfDigits ?? 1, '0');
  const originalEntryNumber = generateEntryNumber({ nextNumber: numberingSeries.nextNumber, format: numberingSeries.format });
  const isDirty = originalEntryNumber !== entryNumber;

  const { t } = useTranslation(['invoices', 'settings']);

  useEffect(() => {
    if (!showSuffix) setValue('suffix', '');
  }, [showSuffix, setValue]);

  return (
    <div className="py-3 my-2 border-y border-gray-300">
      <div className="flex justify-between items-center">
        <p className="flex flex-col">
          <strong>
            {type === 'invoice'
              ? t('invoices:createEdit.confirmation.entryNumber.invoiceNumber')
              : t('invoices:createEdit.confirmation.entryNumber.creditNoteNumber')}
          </strong>
          <span className="break-all">{entryNumber}</span>
        </p>

        <div className="flex gap-x-2">
          {showEntryNumberForm && isDirty && <Button icon="Undo" onClick={() => reset(form.formState.defaultValues)} type="iconOnly" />}
          <Button icon={!showEntryNumberForm ? 'Edit' : isDirty ? 'Check' : 'Close'} onClick={toggleShowEntryNumberForm} type="iconOnly" />
        </div>
      </div>

      <AnimateExpand>
        {showEntryNumberForm && (
          <form className="mt-4 p-4 bg-light-gray">
            {/* Warnings */}
            <div className="mb-4 grid grid-cols-[auto_1fr] gap-1.5 text-warning-500">
              <span className="relative top-[2px]">
                <Icon name="Info" size={18} />
              </span>
              <p className="text-warning-500">{t('invoices:createEdit.confirmation.entryNumber.warning')}</p>

              {entryNumber.length > 30 && (
                <>
                  <span className="relative top-[2px]">
                    <Icon name="Info" size={18} />
                  </span>
                  <p className="text-warning-500">{t('settings:fields.numberingSeries.format.lengthWarning', { limit: 30 })}</p>
                </>
              )}
            </div>

            <div className="grid grid-cols-[1fr_2fr] gap-3">
              {/* Prefix */}
              <FormControl as={Fragment} control={control} name="prefix">
                <FormLabel>{t('invoices:createEdit.confirmation.entryNumber.prefix')}</FormLabel>
                <FormTextInput />
              </FormControl>

              {/* Serial number */}
              <label htmlFor="nextNumber">{t('invoices:createEdit.confirmation.entryNumber.serialNumber')}</label>
              <input type="number" {...register('nextNumber')} hidden />
              <div className="flex gap-x-2 items-center">
                <input className="!bg-gray-200" disabled value={previewNextNumber} />
                {watchNextNumber > (defaultValues?.nextNumber ?? 0) && (
                  <Button extraClasses="h-fit" icon="Remove" onClick={decrementNextNumber} type="iconOnly" />
                )}
                <Button extraClasses="h-fit" icon="Add" onClick={incrementNextNumber} type="iconOnly" />
              </div>

              {/* Show suffix */}
              <div className="col-start-2">
                <FormControl control={control} name="showSuffix">
                  <FormCheckbox label={t('settings:fields.numberingSeries.format.useSuffix')} />
                </FormControl>
              </div>

              {/* Suffix */}
              {showSuffix && (
                <FormControl as={Fragment} control={control} name="suffix">
                  <FormLabel>{t('invoices:createEdit.confirmation.entryNumber.suffix')}</FormLabel>
                  <FormTextInput />
                </FormControl>
              )}
            </div>
          </form>
        )}
      </AnimateExpand>
    </div>
  );
};
