import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useLinkCashaca } from '~/api/user';
import { Button, FormControl, FormErrorMessage, FormLabel, FormTextInput, Modal, Spinner } from '~/components';

import type { CashacaModalFormType, CashacaModalProps as Props } from './types';

export const CashacaModal = ({ onClose }: Props) => {
  const mutation = useLinkCashaca();

  const { control, handleSubmit } = useForm<CashacaModalFormType>();

  const { t } = useTranslation(['common', 'settings', 'validation']);

  const onSubmit = handleSubmit((data) => {
    mutation.mutate(data.apiToken, {
      onSuccess: () => {
        toast.success(t('settings:alerts.integrations.successCashacaPaired'));
        onClose();
      },
      onError: () => toast.error(t('common:error')),
    });
  });

  return (
    <Modal onClose={onClose}>
      <Modal.Title>
        {t('settings:integrations.externalSystems.pairWith', {
          system: t('settings:integrations.externalSystems.systems.cashaca.name'),
        })}
      </Modal.Title>

      <p>
        <Trans component={{ a: <a /> }} i18nKey="settings:integrations.externalSystems.systems.cashaca.modal.description" />
      </p>

      <form className="space-y-4" onSubmit={onSubmit}>
        <FormControl control={control} name="apiToken" rules={{ required: true }}>
          <FormLabel>{t('settings:integrations.externalSystems.systems.cashaca.modal.apiToken')}</FormLabel>
          <FormTextInput placeholder={t('settings:integrations.externalSystems.systems.cashaca.modal.apiToken')} />
          <FormErrorMessage required={t('validation:required')} />
        </FormControl>

        <Modal.Actions>
          <Button disabled={mutation.isPending} isSubmit>
            {mutation.isPending && <Spinner size={24} />}
            <span>{t('settings:integrations.pair')}</span>
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
