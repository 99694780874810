import { Trans } from 'react-i18next';

import type { InviteeDiscount } from '~/types/user';

import { LinkButton } from '~/components/UI';
import { useScreenWidth } from '~/hooks/useScreenWidth';
import { routes } from '~/providers/RouterProvider/router.routes';

import { styles } from './InviteeDiscountCountdown.styles';

const isSummerPromo = (referralCode: string) => ['IKWERKINDEZOMER', 'ZOMERPROMO60'].includes(referralCode);

type Props = {
  discount: InviteeDiscount | null;
};

export const InviteeDiscountCountdown = ({ discount }: Props) => {
  const screen = useScreenWidth();

  if (discount?.type !== 'PremiumForFree' || screen.isSmallerThan('md')) {
    return null;
  }

  return (
    <LinkButton
      icon={isSummerPromo(discount.referralCode) ? 'WbSunny' : 'Redeem'}
      size="narrow"
      styles={styles.linkButton}
      to={routes.settingsPaymentPlan}
      variant="secondary"
    >
      {screen.isLargerThan('md') && (
        <Trans
          count={discount.daysActive}
          i18nKey={isSummerPromo(discount.referralCode) ? 'common:topbar.activePremiumForFreeSummer' : 'common:topbar.activePremiumForFree'}
        />
      )}
    </LinkButton>
  );
};
