import * as stylex from '@stylexjs/stylex';

import type { MediaQueryTabletAndBelow } from '~/styles/mediaQueries';

import { spacing } from '~/styles/tokens.stylex';

const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';

export const styles = stylex.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.medium,
  },

  cards: {
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoFlow: {
      default: 'column',
      [TabletAndBelow]: 'row',
    },
    gap: spacing.medium,
    alignItems: 'start',
  },
});
