import type { ReactNode } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { MaintenanceError, UnauthenticatedError } from '~/api/errors';
import { useMaintenanceContext } from '~/providers/MaintenanceProvider/MaintenanceProvider.context';
import { routes } from '~/providers/RouterProvider/router.routes';
import { removeAccessToken } from '~/utils/auth';

import { Redirect } from '../Functional';

type Props = {
  children: ReactNode;
};

/**
 * Handle UnauthenticatedError and MaintenanceError before rendering an error message.
 */
export const ErrorBoundaryWrapper = ({ children }: Props) => {
  const error = useRouteError();
  const queryClient = useQueryClient();
  const { setIsMaintenanceMode } = useMaintenanceContext();

  useEffect(() => {
    if (error instanceof UnauthenticatedError) {
      queryClient.removeQueries();
    }
  }, [error, queryClient]);

  useEffect(() => {
    if (error instanceof MaintenanceError) {
      setIsMaintenanceMode(true);
    }
  }, [error, setIsMaintenanceMode]);

  if (error instanceof UnauthenticatedError) {
    removeAccessToken();
    return <Redirect state={{ isSessionExpired: true }} to={routes.login} />;
  }

  if (error instanceof MaintenanceError) {
    return null;
  }

  return children;
};
