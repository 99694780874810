import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import { styles } from './AuthLayoutContent.styles';

export const AuthLayoutContent = ({ children }: { children: ReactNode }) => {
  return (
    <div {...stylex.props(styles.content)}>
      <div {...stylex.props(styles.centerContainer)}>{children}</div>
    </div>
  );
};
