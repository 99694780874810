import type { ComponentProps } from 'react';

import type { WithoutStyleProps } from '~/components/types';

import { Button } from '../Button/Button';
import { ExternalLink } from './ExternalLink';

type DelegatedButtonProps = Pick<ComponentProps<typeof Button>, 'children' | 'size' | 'variant' | 'scale' | 'icon' | 'styles'>;
type Props = WithoutStyleProps<ComponentProps<typeof ExternalLink>> & DelegatedButtonProps;

export const ExternalLinkButton = (delegated: Props) => {
  return (
    <Button
      as={ExternalLink}
      // The Button component will render an ExternalLink, but TypeScript expects button properties
      // to be passed to the Button component, not knowing that we can pass Link properties.
      {...(delegated as DelegatedButtonProps)}
    />
  );
};
