import { useEffect, useState } from 'react';

/**
 * Hook that returns the current scroll position
 */
export const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.scrollY);
      setIsTop(!window.scrollY);
    };

    // Trigger initial position
    updatePosition();

    window.addEventListener('scroll', updatePosition);

    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return { scrollPosition, isTop };
};
