import { z } from 'zod';

export const accessTokenSchema = z.object({
  accessToken: z.string(),
});

export const checkEmailAddressInUseSchema = z.object({
  inUse: z.boolean(),
});

export const checkVatNumberInUseSchema = z.object({
  inUse: z.boolean(),
});

export const referralCodeInformationSchema = z.object({
  code: z.string(),
  invitorType: z.enum(['user', 'admin', 'accountant']),
  invitorName: z.string(),
});

export const receivedInvitationSchema = z.object({
  referralCode: referralCodeInformationSchema,
  name: z.string(),
  date: z.string(),
});
