import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { Client } from '~/api/clients/types';

import { DropdownMenu } from '~/components';
import { useModal } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { isClientEditable } from '../utils';
import { DeleteClientModal } from './DeleteClientModal';

export const ClientsTableOptions = ({ client }: { client: Client }) => {
  const deleteModal = useModal();

  const navigate = useNavigate();
  const { t } = useTranslation(['clients']);

  return (
    <>
      <DropdownMenu
        dropdownClass="text-sm"
        items={[
          ...(client.clientType === 'organisation'
            ? [
                {
                  iconName: 'Visibility' as const,
                  text: t('clients:overview.rowActions.view'),
                  onClick: () => navigate(r(routes.showClient, { clientId: client.id })),
                },
              ]
            : []),
          ...(isClientEditable(client) || client.clientType === 'private'
            ? [
                {
                  iconName: 'Edit' as const,
                  text: t('clients:overview.rowActions.edit'),
                  onClick: () =>
                    navigate(
                      client.clientType === 'organisation'
                        ? r(routes.editClient, { clientId: client.id })
                        : r(routes.editContact, { clientId: client.id, contactId: client.contacts[0].id }),
                    ),
                },
              ]
            : []),
          ...(client.clientType === 'organisation'
            ? [
                {
                  iconName: 'PersonAdd' as const,
                  text: t('clients:overview.rowActions.addContact'),
                  onClick: () => navigate(r(routes.createContact, { clientId: client.id })),
                },
              ]
            : []),
          {
            iconName: 'Delete',
            text: t('clients:overview.rowActions.delete'),
            onClick: deleteModal.open,
          },
        ]}
      />

      {deleteModal.isOpen && <DeleteClientModal client={client} onClose={deleteModal.close} />}
    </>
  );
};
