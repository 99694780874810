import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UploadCommunityProfilePicture } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { userQueryOptions } from '../user';
import { userCommunityProfileSchema } from './schemas';

export const useUploadCommunityProfilePicture = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (file: UploadCommunityProfilePicture) => {
      const body = new FormData();

      if (file) body.append('profilePicture', file);

      const token = getAccessToken();
      const response = await request(`/community/${id}/profile-picture`, { method: 'POST', token, body });
      const { data: communityProfile } = apiResourceSchema(userCommunityProfileSchema).parse(response);

      return communityProfile;
    },
    onSuccess: (communityProfile) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, communityProfile };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
