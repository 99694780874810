import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { Invitation } from '~/types/user';

import { getAccessToken } from '~/utils/auth';

import type { CreateInvitationPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useCreateInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateInvitationPayload) => {
      const token = getAccessToken();
      const response = await request('/invitations', { method: 'POST', token, body });

      // TODO: Schema validation
      const { data: invitation } = apiResourceSchema(z.unknown()).parse(response);

      return invitation as Invitation;
    },
    onSuccess: (invitation) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, invitations: [...user.invitations, invitation] };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
