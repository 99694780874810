export enum Language {
  DUTCH = 'nl',
  FRENCH = 'fr',
  ENGLISH = 'en',
}

export enum CreativeWork {
  NO_CREATIVE = 0,
  CREATIVE = 1,
  CREATIVE_COMPUTER = 2,
  REUSAGE = 3,
  SUBCONTRACTING = 4,
}

// Replacement of the old CreativeWork enum
export enum ActivityType {
  CreativeGeneral = 'CreativeGeneral',
  CreativeSubcontracting = 'CreativeSubcontracting',
  CreativeComputer = 'CreativeComputer',
  CreativeReuse = 'CreativeReuse',
  NonCreative = 'NonCreative',
}
