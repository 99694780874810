import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlanId } from '~/api/plans/constants';
import { useUser } from '~/api/user';
import { CollapsibleSection, Locked } from '~/components';
import { hasCorrectPlan } from '~/utils/user';

import { CreateCustomConditionModal } from './CreateCustomConditionModal/CreateCustomConditionModal';
import { CustomConditionsTable } from './CustomConditionsTable';

export const CustomConditions = () => {
  const { data: user } = useUser();

  const { t } = useTranslation(['common', 'settings']);

  const [showWizard, setShowWizard] = useState(false);

  if (!hasCorrectPlan(user, PlanId.premium)) {
    return <Locked text={t('common:locked.minPremium')} />;
  }

  return (
    <>
      <CollapsibleSection.Button onClick={() => setShowWizard(true)}>
        {t('settings:general.customConditions.create')}
      </CollapsibleSection.Button>

      <CustomConditionsTable />

      {showWizard && <CreateCustomConditionModal onClose={() => setShowWizard(false)} />}
    </>
  );
};
