import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { CollapsibleSection, PageHeader } from '~/components';
import { SettingsNavigation } from '~/components/Templates/Settings';
import { AnchorTargets } from '~/constants/url';

import { Activities } from './Activities/Activities';
import { Brands } from './Brands/Brands';
import { CommunitySettings } from './CommunitySettings/CommunitySettings';
import { FinancialInfo } from './FinancialInfo/FinancialInfo';
import { ProfileInfo } from './ProfileInfo/ProfileInfo';
import { VatRegulation } from './VatRegulation/VatRegulation';

export const Profile = () => {
  const { data: user } = useUser();

  const { t } = useTranslation(['settings']);

  return (
    <>
      <PageHeader description={t('settings:profile.description')} title={t('settings:profile.title')} />

      <SettingsNavigation
        items={[
          { anchor: AnchorTargets.PROFILE_INFO, label: t('settings:profile.info.title') },
          { anchor: AnchorTargets.PROFILE_ACTIVITIES, label: t('settings:profile.activities.title') },
          { anchor: AnchorTargets.PROFILE_VAT_REGULATION, label: t('settings:profile.vatRegulation.title') },
          { anchor: AnchorTargets.PROFILE_FINANCIAL, label: t('settings:profile.financial.title') },
          { anchor: AnchorTargets.PROFILE_COMMUNITY, label: t('settings:profile.community.title') },
          { anchor: AnchorTargets.PROFILE_BRANDS, label: t('settings:profile.brands.title') },
        ]}
      />

      {/* Profile Info */}
      <ProfileInfo />

      {/* Activities */}
      <CollapsibleSection
        description={t('settings:profile.activities.description')}
        id={AnchorTargets.PROFILE_ACTIVITIES}
        title={t('settings:profile.activities.title')}
      >
        <Activities />
      </CollapsibleSection>

      {/* Vat Regulation */}
      <CollapsibleSection
        description={t('settings:profile.vatRegulation.description')}
        id={AnchorTargets.PROFILE_VAT_REGULATION}
        title={t('settings:profile.vatRegulation.title')}
      >
        <VatRegulation />
      </CollapsibleSection>

      {/* Financial information */}
      <CollapsibleSection
        description={t('settings:profile.financial.description')}
        id={AnchorTargets.PROFILE_FINANCIAL}
        title={t('settings:profile.financial.title')}
      >
        <FinancialInfo />
      </CollapsibleSection>

      {/* Community */}
      <CollapsibleSection
        description={t('settings:profile.community.description')}
        id={AnchorTargets.PROFILE_COMMUNITY}
        title={t('settings:profile.community.title')}
      >
        <CommunitySettings
          // Remount the form when one of these values changes,
          // as they can change from outside this component (which does not reset the form).
          key={[user.brands.map(({ companyName }) => companyName)].join()}
        />
      </CollapsibleSection>

      {/* Brands */}
      <CollapsibleSection
        description={t('settings:profile.brands.description')}
        id={AnchorTargets.PROFILE_BRANDS}
        title={t('settings:profile.brands.title')}
      >
        <Brands />
      </CollapsibleSection>
    </>
  );
};
