import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { CreateProductPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { productSchema } from './schemas';
import { productsQueryOptions } from './useProducts';

export const useCreateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateProductPayload) => {
      const token = getAccessToken();
      const response = await request('/products', { method: 'POST', token, body });

      const { data: product } = apiResourceSchema(productSchema).parse(response);

      return product;
    },
    onSuccess: (product) => {
      queryClient.setQueryData(productsQueryOptions.queryKey, (products) => [product, ...(products ?? [])]);
      queryClient.invalidateQueries(productsQueryOptions);
    },
  });
};
