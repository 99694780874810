import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { clientsQueryOptions } from './useClients';

export const useDeleteClient = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request(`/clients/${id}`, { method: 'DELETE', token });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};
