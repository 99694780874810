import * as stylex from '@stylexjs/stylex';

import { fontWeight, lineHeight } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  alertTitle: {
    color: 'inherit',
    fontWeight: fontWeight.medium,
    lineHeight: lineHeight.tight,
  },
});
