import type { ButtonProps } from '@headlessui/react';
import type { ElementType, ForwardedRef, Ref } from 'react';

import { Button as HuiButton } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { forwardRef } from 'react';
import { match } from 'ts-pattern';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';

import type { WithoutStyleProps } from '../../types';

import { Spinner } from '../Spinner/Spinner';
import { styles } from './Button.styles';

type Props<T extends ElementType> = WithoutStyleProps<ButtonProps<T>> & {
  form?: string;
  icon?: IconName;
  loading?: boolean;
  scale?: boolean;
  size?: 'normal' | 'large' | 'narrow' | 'square';
  styles?: stylex.StyleXStyles;
  variant?: 'theme' | 'primary' | 'secondary' | 'tertiary' | 'link';
};

export const Button = forwardRef(
  <T extends ElementType = 'button'>(
    { children, form, icon, loading, scale = false, size = 'normal', styles: styleOverrides, variant = 'primary', ...delegated }: Props<T>,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    return (
      <HuiButton
        {...stylex.props(styles.base, styles[size], styles[variant], scale && styles.scale, loading && styles.loading, styleOverrides)}
        {...delegated}
        disabled={delegated.disabled || loading}
        form={form}
        ref={ref as Ref<HTMLElement>}
      >
        {loading && (
          <Spinner
            size={match(variant)
              .returnType<'regular' | 'inline'>()
              .with('link', () => 'inline')
              .otherwise(() => 'regular')}
          />
        )}

        {icon && !loading && (
          <Icon
            name={icon}
            size={match(size)
              .with('large', () => 24)
              .otherwise(() => 20)}
          />
        )}

        <span {...stylex.props(styles.contentWrapper)}>{children}</span>
      </HuiButton>
    );
  },
);

Button.displayName = 'Button';
