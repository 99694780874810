import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { Invoice } from '~/types/invoice';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { invoicesQueryOptions } from './useInvoices';

export const useCreateInvoice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const token = getAccessToken();
      const response = await request('/invoices/draft', { method: 'POST', token });

      // TODO: Schema validation
      const { data: invoice } = apiResourceSchema(z.unknown()).parse(response);

      return invoice as Invoice;
    },
    onSuccess: (invoice) => {
      queryClient.setQueryData(invoicesQueryOptions.queryKey, (invoices) => [invoice, ...(invoices ?? [])]);
      queryClient.invalidateQueries(invoicesQueryOptions);
    },
  });
};
