import classNames from 'classnames';

import { Icon } from '~/components/SVG';

import type { AnnouncementProps as Props } from './types';

import { Button } from '../Button/Button';
import styles from './Announcement.module.scss';

export const Announcement = ({ children, iconName = 'Campaign', link, onClose, title }: Props) => {
  const isSmall = !title;

  return (
    <div className={classNames(styles.Announcement, isSmall ? styles.Small : styles.Large, !!link && styles.HasLink)}>
      {!!onClose && (
        <Button extraClasses={styles.CloseButton} inverted onClick={() => onClose()} type="iconOnly">
          <Icon name="Close" />
        </Button>
      )}

      <div className={styles.Icon}>
        <Icon name={iconName} size={isSmall ? 32 : 48} />
      </div>

      {title && <h2 className={styles.Title}>{title}</h2>}

      <div className={styles.Message}>{children}</div>

      {link && (
        <div className={styles.Cta}>
          <div className={styles.CtaMessage}>{link.message}</div>

          <a className={styles.Link} href={link.url} rel="noreferrer" target="_blank">
            {link.label}
          </a>
        </div>
      )}
    </div>
  );
};
