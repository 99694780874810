import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { previewHtmlSchema } from '../common/schemas';
import { request } from '../request';

export const usePreviewInvoice = () => {
  return useMutation({
    mutationFn: async (id: number) => {
      const token = getAccessToken();
      const response = await request(`/invoices/${id}/preview`, { token });

      const { previewHTML } = previewHtmlSchema.parse(response);

      return previewHTML;
    },
  });
};
