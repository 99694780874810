import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useSignContract, useUser } from '~/api/user';
import { Button, FormCheckbox, FormControl, FormErrorMessage, Modal } from '~/components';
import { FrameworkAgreement } from '~/components/Templates/FrameworkAgreement';

import type { ContractModalProps as Props } from './types';

export const ContractModal = ({ onClose }: Props) => {
  const { data: user } = useUser();
  const mutation = useSignContract();

  const userNeedsToSignAddendum = user.contractSigned && !user.addendumSigned;

  const { control, handleSubmit } = useForm<{ agreed: boolean }>({
    defaultValues: { agreed: false },
  });

  const { t } = useTranslation(['common', 'dashboard', 'settings']);

  const onSubmit = handleSubmit(() => {
    mutation.mutate(undefined, {
      onSuccess: () => onClose(),
      onError: () => toast.error(t('settings:accountCompletionModal.frameworkAgreement.error')),
    });
  });

  return (
    <Modal onClose={onClose} variant="wide">
      <Modal.Title>
        {userNeedsToSignAddendum
          ? t('dashboard:progression.required.signAddendum2024')
          : t('dashboard:progression.required.signContractModalTitle')}
      </Modal.Title>

      <div className="space-y-4">
        <FrameworkAgreement isAddendum={userNeedsToSignAddendum} />

        <form className="space-y-8" onSubmit={onSubmit}>
          <FormControl control={control} name="agreed" rules={{ required: true }}>
            <FormCheckbox label={t('dashboard:frameworkAgreement.checkboxLabel')} />
            <FormErrorMessage required={t('dashboard:frameworkAgreement.checkboxError')} />
          </FormControl>

          <Modal.Actions>
            <Button hasSpinner icon="Send" isLoading={mutation.isPending} isSubmit>
              <span>{t('common:send')}</span>
            </Button>
          </Modal.Actions>
        </form>
      </div>
    </Modal>
  );
};
