import { IconTooltip } from '~/components';

import type { FormHelpTextProps as Props } from './types';

import styles from './FormHelpText.module.scss';

export const FormHelpText = ({ children, tooltip }: Props) => {
  return (
    <div className={styles.Wrapper}>
      <span className={styles.HelpText}>{children}</span>
      {tooltip && <IconTooltip text={tooltip} />}
    </div>
  );
};
