import { Trans } from 'react-i18next';

import { Icon } from '~/components/SVG';

import type { ErrorBoundaryMessageProps as Props } from './types';

import styles from './ErrorBoundaryMessage.module.scss';

export const ErrorBoundaryMessage = ({ children }: Props) => {
  return (
    <div className={styles.Wrapper}>
      <span className={styles.Icon}>
        <Icon name="ErrorOutline" size={28} />
      </span>

      <h4 className={styles.Title}>{children}</h4>
      <p className={styles.Body}>
        <Trans components={{ a: <a /> }} i18nKey="common:errorBoundaryMessage" />
      </p>
    </div>
  );
};
