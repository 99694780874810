import type { UseFormGetValues } from 'react-hook-form';

import { useEffect, useMemo } from 'react';

import type { OrganisationAddress } from '~/api/clients/types';

import type { ContactFormType } from '../../types';

import { useCreateClientContext } from '../context';

/** Save form data in context when returning to the previous step. */
export const useSaveContactFormData = (getValues: UseFormGetValues<ContactFormType>) => {
  const { setContactFormData } = useCreateClientContext();

  useEffect(() => () => setContactFormData(getValues()), [getValues, setContactFormData]);
};

export const useOrganisationAddresses = (): OrganisationAddress[] => {
  const { establishmentUnits, organisation } = useCreateClientContext();

  return useMemo(
    () =>
      establishmentUnits.length > 0 ? establishmentUnits : organisation ? [{ type: 'headquarters' as const, ...organisation.address }] : [],
    [organisation, establishmentUnits],
  );
};
