import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PlanId } from '~/api/plans/constants';
import { useUpdateAppSettings, useUser } from '~/api/user';
import { AnchorTargets, SearchParamKeys, SearchParamModals } from '~/constants/url';
import { useModal } from '~/hooks/useModal';
import { routes } from '~/providers/RouterProvider/router.routes';
import { insertIf } from '~/utils/arrays';
import { qs } from '~/utils/searchParams';
import { AccountCompletionStep, getAccountCompletionProgress } from '~/utils/user';

import type { AccountCompletionCardSteps } from './AccountCompletionCard/types';

import { AccountCompletionStepType } from '../types';
import styles from './AccountCompletion.module.scss';
import { AccountCompletionCard } from './AccountCompletionCard/AccountCompletionCard';
import { AccountCompletionCardStyle } from './AccountCompletionCard/types';
import { ContractModal } from './ContractModal/ContractModal';

export const AccountCompletion = () => {
  const { data: user } = useUser();
  const mutation = useUpdateAppSettings();

  const { t } = useTranslation(['dashboard']);

  const contractModal = useModal();

  const requiredAccountSteps = useMemo<AccountCompletionCardSteps[]>(
    () => [
      {
        checked: true,
        body: t('dashboard:progression.required.create'),
      },
      {
        checked: getAccountCompletionProgress(user)[AccountCompletionStep.FINANCIAL_INFO],
        body: t('dashboard:progression.required.iban'),
        link: {
          pathname: routes.settingsProfile,
          search: qs({ [SearchParamKeys.OPEN_MODAL]: SearchParamModals.FINANCIAL_INFO }),
          hash: AnchorTargets.PROFILE_FINANCIAL,
        },
      },
      {
        checked: getAccountCompletionProgress(user)[AccountCompletionStep.VAT_REGULATION],
        body: t('dashboard:progression.required.vatRegulation'),
        link: {
          pathname: routes.settingsProfile,
          search: qs({ [SearchParamKeys.OPEN_MODAL]: SearchParamModals.VAT_REGULATION }),
          hash: AnchorTargets.PROFILE_VAT_REGULATION,
        },
      },
      {
        checked: getAccountCompletionProgress(user)[AccountCompletionStep.FRAMEWORK_AGREEMENT],
        body:
          user.contractSigned && !user.addendumSigned
            ? t('dashboard:progression.required.signAddendum2024')
            : t('dashboard:progression.required.signContract'),
        action: contractModal.open,
      },
    ],
    [t, user, contractModal.open],
  );

  const optionalAccountSteps = useMemo<AccountCompletionCardSteps[]>(
    () => [
      {
        checked: user.invoiceForwardingSettings.length > 0 || !!user.accountant,
        body: t('dashboard:progression.optional.accountant'),
        link: {
          pathname: routes.settingsIntegrations,
          hash: AnchorTargets.INTEGRATIONS_AUTO_FORWARDING,
        },
      },
      {
        checked: user.invitations.length > 0,
        body: t('dashboard:progression.optional.invite'),
        link: routes.settingsInvitations,
      },
      ...insertIf(user.planId !== PlanId.earlyAdopter, {
        checked: user.planId === PlanId.premium,
        body: t('dashboard:progression.optional.plan'),
        link: {
          pathname: routes.settingsPaymentPlan,
          hash: AnchorTargets.PLAN_PICKER,
        },
      }),
      {
        checked: user.communityProfile.enabled,
        body: t('dashboard:progression.optional.community'),
        link: routes.community,
      },
    ],
    [t, user.accountant, user.communityProfile.enabled, user.invitations.length, user.invoiceForwardingSettings.length, user.planId],
  );

  const closeCard = (type: AccountCompletionStepType) => {
    mutation.mutate({ [type === AccountCompletionStepType.REQUIRED ? 'showRequiredSteps' : 'showOptionalSteps']: false });
  };

  if (!user.showRequiredSteps && !user.showOptionalSteps) return null;

  return (
    <section className={styles.AccountCompletion}>
      {/* Required */}
      {user.showRequiredSteps && (
        <AccountCompletionCard
          completedDescription={t('dashboard:progression.required.completed')}
          onClose={() => closeCard(AccountCompletionStepType.REQUIRED)}
          steps={requiredAccountSteps}
          style={AccountCompletionCardStyle.PRIMARY}
          title={t('dashboard:progression.required.title')}
        />
      )}

      {/* Optional */}
      {user.showOptionalSteps && (
        <AccountCompletionCard
          completedDescription={t('dashboard:progression.optional.completed')}
          hideButton={
            !user.showRequiredSteps ? (
              <button className={styles.HideButton} onClick={() => closeCard(AccountCompletionStepType.OPTIONAL)}>
                {t('dashboard:progression.hide')}
              </button>
            ) : undefined
          }
          onClose={() => closeCard(AccountCompletionStepType.OPTIONAL)}
          steps={optionalAccountSteps}
          style={AccountCompletionCardStyle.SECONDARY}
          title={t('dashboard:progression.optional.title')}
        />
      )}

      {/* Sign contract modal */}
      {contractModal.isOpen && <ContractModal onClose={contractModal.close} />}
    </section>
  );
};
