export const lines = {
  actions: {
    applyDiscount: 'Apply a discount',
    removeDiscount: 'Remove discount',
  },
  benefit: 'Benefit',
  creativeWork: {
    header: 'Type of performance',
    options: {
      '0': 'Non creative work',
      '1': 'Creative performance',
      '2': 'Computer programme creative performance',
      '3': 'Reuse',
      '4': 'Subcontracting',
      CreativeComputer: 'Computer programme creative performance',
      CreativeGeneral: 'Creative performance',
      CreativeReuse: 'Reuse',
      CreativeSubcontracting: 'Subcontracting',
      NonCreative: 'No creative performance',
    },
    placeholder: 'Creative work?',
  },
  description: {
    header: 'Description',
    placeholder: 'Description',
    placeholderProduct: 'Additional information',
  },
  discount: {
    free: 'Free',
    header: 'Discount',
  },
  discountType: {
    flatRate: '€',
    percentage: '%',
  },
  lineTotal: {
    header: 'Total',
  },
  modal: {
    title: 'Invoice line',
  },
  new: {
    invoiceLine: 'Add line',
    photoAlbum: 'Add photo album',
    photoAlbumLine: 'Add photo album line',
    product: 'Add product',
    productTooltip:
      '<a href="https://knowledge.creativeshelter.be/producten-wat-zijn-ze-en-hoe-gebruik-je-ze" target="_blank">Find out what products are here!</a>',
    quotationLine: 'Add line',
    textLine: 'Add text line',
  },
  photoAlbum: {
    deleteModal: {
      description: 'Are you sure you want to delete the “photo album” section? This also deletes all lines in this section.',
      title: 'Delete photo album',
    },
    description: 'Add all invoice lines related to the photo album here.',
    tag: '6% VAT',
    tagTooltip: 'All lines under the photo album section are invoiced at 6% VAT.',
    title: 'Photo album',
  },
  price: {
    header: 'Price',
    placeholder: 'Price',
  },
  productName: {
    label: 'Product',
    tooltip: 'This line is based on a product. Changes are possible, they are not saved in your product, but only on your invoice.',
  },
  quantity: {
    header: 'Number of',
    placeholder: 'Number of',
  },
  subtotal: 'Subtotal',
  textLine: 'Text line',
  total: 'Total',
  totalVat: 'Total VAT',
  units: {
    characters_one: 'Character',
    characters_other: 'Characters',
    days_one: 'Day',
    days_other: 'Days',
    halfDays_one: 'Half day',
    halfDays_other: 'Half days',
    hours_one: 'Hour',
    hours_other: 'Hours',
    kilometers: 'Km',
    none: '—',
    placeholder: 'Select',
    words_one: 'Word',
    words_other: 'Words',
  },
  vatExported: 'Export',
  vatPercentage: 'VAT ({{ percentage }}%)',
  vatShifted: 'VAT shifted',
  vatTooltip:
    'We always charge VAT on the invoice to your client, regardless of your VAT status, as Creative Shelter is a VAT-registered company. <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-de-btw-vrijstellingsregeling-en-bestaan-er-nog-andere-regelingen?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">Learn more about it here!</a>',
};
