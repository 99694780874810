import * as stylex from '@stylexjs/stylex';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateAppSettings, useUser } from '~/api/user';
import { PageHeader } from '~/components';
import { env } from '~/constants/env';

import { DiscountsCard } from './DiscountsCard';
import { InvitationBenefits } from './InvitationBenefits';
import { InvitationCTA } from './InvitationCTA';
import { styles } from './Invitations.styles';
import { InvitationsCommandMenuItems } from './InvitationsCommandMenuItems';
import { InvitationsOverviewCards } from './InvitationsOverviewCards';
import { InvitorDiscountProgress } from './InvitorDiscountProgress';
import { PendingInvitationsCard } from './PendingInvitationsCard';

export const Invitations = () => {
  const { data: user } = useUser();
  const { mutate } = useUpdateAppSettings();

  const { t } = useTranslation(['invitations']);

  // Track invitations page view
  useEffect(() => {
    if (!user.appSettings.hasSeenInvitationsPage) mutate({ appSettings: { hasSeenInvitationsPage: true } });
  }, [mutate, user.appSettings.hasSeenInvitationsPage]);

  return (
    <>
      <PageHeader description={t('invitations:pageHeader.description')} title={t('invitations:pageHeader.title')} />

      <div {...stylex.props(styles.wrapper)}>
        <InvitationCTA />

        <InvitorDiscountProgress />

        <InvitationBenefits />

        <InvitationsOverviewCards>
          <PendingInvitationsCard />
          <DiscountsCard />
        </InvitationsOverviewCards>
      </div>

      {(env.IS_LOCAL_DEV_ENV || env.IS_STAGING_ENV) && <InvitationsCommandMenuItems />}
    </>
  );
};
