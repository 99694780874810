import * as stylex from '@stylexjs/stylex';

import { spacing } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  base: {
    display: 'flex',
  },

  inline: {
    display: 'inline-flex',
  },
});

export const flexWrapStyles = stylex.create({
  wrap: {
    flexWrap: 'wrap',
  },

  reverse: {
    flexWrap: 'wrap-reverse',
  },
});

export const flexDirectionStyles = stylex.create({
  row: {
    flexDirection: 'row',
  },

  column: {
    flexDirection: 'column',
  },
});

export const gapStyles = stylex.create({
  none: {
    gap: spacing.none,
  },

  pixel: {
    gap: spacing.pixel,
  },

  tiny: {
    gap: spacing.tiny,
  },

  xxxsmall: {
    gap: spacing.xxxsmall,
  },

  xxsmall: {
    gap: spacing.xxsmall,
  },

  xsmall: {
    gap: spacing.xsmall,
  },

  small: {
    gap: spacing.small,
  },

  normal: {
    gap: spacing.normal,
  },

  medium: {
    gap: spacing.medium,
  },

  large: {
    gap: spacing.large,
  },

  xlarge: {
    gap: spacing.xlarge,
  },

  xxlarge: {
    gap: spacing.xxlarge,
  },

  xxxlarge: {
    gap: spacing.xxxlarge,
  },
});

export const justifyContentStyles = stylex.create({
  stretch: {
    justifyContent: 'stretch',
  },

  start: {
    justifyContent: 'start',
  },

  center: {
    justifyContent: 'center',
  },

  end: {
    justifyContent: 'end',
  },

  spaceBetween: {
    justifyContent: 'space-between',
  },

  spaceAround: {
    justifyContent: 'space-around',
  },

  spaceEvenly: {
    justifyContent: 'space-evenly',
  },
});

export const alignItemsStyles = stylex.create({
  stretch: {
    alignItems: 'stretch',
  },

  start: {
    alignItems: 'start',
  },

  center: {
    alignItems: 'center',
  },

  end: {
    alignItems: 'end',
  },

  baseline: {
    alignItems: 'baseline',
  },
});
