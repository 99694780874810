import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Announcement, Button, FloatingActionButton, PageHeader } from '~/components';
import { routes } from '~/providers/RouterProvider/router.routes';

import { ProductsTable } from './ProductsTable';

export const ProductsOverview = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['products']);

  return (
    <>
      <PageHeader description={t('products:header.description')} title={t('products:header.title')}>
        <Button data-pf-id="products-header-create-product" icon="DesignServices" onClick={() => navigate(routes.createProduct)}>
          {t('products:header.actionBlock.button')}
        </Button>
      </PageHeader>

      <div className="my-8">
        <Announcement iconName="NewReleases">
          <Trans components={{ a: <a /> }} i18nKey="products:announcement" />
        </Announcement>
      </div>

      <ProductsTable />

      <FloatingActionButton options={[{ text: t('products:header.actionBlock.button'), onClick: () => navigate(routes.createProduct) }]} />
    </>
  );
};
