import { useMemo } from 'react';

import type { Brand } from '~/types/user';

import { useUser } from '~/api/user';
import { useTable } from '~/hooks/useTable';

import { useBrandsTableColumns } from './BrandsTable.hooks';

export const BrandsTable = () => {
  const { data: user } = useUser();

  const data = useMemo(
    () => [
      {
        id: 0,
        companyName: user.companyName,
        color: '',
        logo: user.logo ?? null,
        email: user.email,
        isFavorite: false,
      } satisfies Brand,
      ...user.brands,
    ],
    [user.brands, user.companyName, user.email, user.logo],
  );

  const columns = useBrandsTableColumns();

  const [table, Table] = useTable({ data, columns });

  return (
    <Table.Root table={table}>
      <Table.Menu>
        <Table.Search />
      </Table.Menu>

      <Table.Grid>
        <Table.Header />
        <Table.Body>{(row) => <Table.Row row={row} />}</Table.Body>
      </Table.Grid>

      <Table.Pagination />
    </Table.Root>
  );
};
