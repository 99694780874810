import type { CountryFlagProps as Props } from './types';

import styles from './CountryFlag.module.scss';

const CDN_URL = 'https://cdn.jsdelivr.net/gh/lipis/flag-icons/flags/4x3/';

export const CountryFlag = ({ countryCode }: Props) => {
  const url = `${CDN_URL}${countryCode.toLowerCase()}.svg`;

  return <img alt={countryCode} className={styles.CountryFlag} loading="lazy" src={url} />;
};
