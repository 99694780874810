import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { invoiceHistoryQueryOptions } from './useInvoiceHistory';
import { invoicesQueryOptions } from './useInvoices';

export const useStartPaymentFollowUp = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request(`/invoices/${id}/follow-up/start`, { method: 'POST', token });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(invoiceHistoryQueryOptions(id));
      return queryClient.invalidateQueries(invoicesQueryOptions);
    },
  });
};
