import { z } from 'zod';

const genericInvoiceHistoryRecordSchema = z.object({
  type: z.enum([
    'Created',
    'Finalized',
    'LockedByUser',
    'Expired',
    'PaidByClient',
    'PaidByCreativeShelter',
    'Credited',
    'FollowUpManualReminder',
    'FollowUpContactClientByPhone',
    'FollowUpStarted',
    'FollowUpLastManualReminder',
    'FollowUpFormalNoticeSent',
    'FollowUpInvoiceSentToLawyer',
    'FollowUpInvoiceSentToBailiff',
  ]),
  timestamp: z.string(),
  attributes: z.null(),
});

const approvedInvoiceHistoryRecordSchema = genericInvoiceHistoryRecordSchema.extend({
  type: z.literal('Approved'),
  attributes: z.object({
    approvalType: z.enum(['manual', 'automatic']),
  }),
});

const emailApprovedAndSentInvoiceHistoryRecordSchema = genericInvoiceHistoryRecordSchema.extend({
  type: z.literal('EmailApprovedAndSent'),
  attributes: z.object({
    recipient: z.enum(['client', 'user']),
  }),
});

const emailPaymentReminderInvoiceHistoryRecordSchema = genericInvoiceHistoryRecordSchema.extend({
  type: z.literal('EmailPaymentReminder'),
  attributes: z.object({
    reminderType: z.enum(['first', 'second']),
  }),
});

const partiallyPaidInvoiceHistoryRecordSchema = genericInvoiceHistoryRecordSchema.extend({
  type: z.literal('PartiallyPaid'),
  attributes: z.object({
    amount: z.number(),
  }),
});

export const invoiceHistoryRecordSchema = z.discriminatedUnion('type', [
  genericInvoiceHistoryRecordSchema,
  approvedInvoiceHistoryRecordSchema,
  emailApprovedAndSentInvoiceHistoryRecordSchema,
  emailPaymentReminderInvoiceHistoryRecordSchema,
  partiallyPaidInvoiceHistoryRecordSchema,
]);

export const invoiceHistorySchema = z.array(invoiceHistoryRecordSchema);
