import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { quotationSchema } from './schemas';
import { quotationsQueryOptions } from './useQuotations';

export const useDeclineQuotation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const token = getAccessToken();
      const response = await request(`/quotations/${id}/decline-by-user`, { method: 'POST', token });

      const { data: quotation } = apiResourceSchema(quotationSchema).parse(response);

      return quotation;
    },
    onSuccess: (quotation) => {
      queryClient.setQueryData(quotationsQueryOptions.queryKey, (quotations) =>
        (quotations ?? []).map((oldQuotation) => (oldQuotation.id === quotation.id ? quotation : oldQuotation)),
      );

      queryClient.invalidateQueries(quotationsQueryOptions);
    },
  });
};
