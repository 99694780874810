export type CreateCustomConditionProps = {
  onClose: () => void;
};

export type Answers = {
  [CustomConditionStep.ALREADY_AGREEMENT_NO_PAPER]: boolean | null;
  [CustomConditionStep.ALREADY_AGREEMENT_NO]: boolean | null;
  [CustomConditionStep.ALREADY_AGREEMENT_PAPER]: boolean | null;
  [CustomConditionStep.ALREADY_AGREEMENT_YES_PAPER]: boolean | null;
  [CustomConditionStep.ALREADY_AGREEMENT]: boolean | null;
  [CustomConditionStep.MODIFY_HOW]: ModifyHow | null;
  [CustomConditionStep.OVERVIEW]: boolean | null;
  [CustomConditionStep.REPRODUCE_HOW_MANY]: { selected: ReproduceHowMany | null; text: string | null };
  [CustomConditionStep.REPRODUCE_HOW]: {
    [ReproduceHow.ANY]: boolean;
    [ReproduceHow.ELECTRONIC_FIXED]: boolean;
    [ReproduceHow.ELECTRONIC_ONLINE]: boolean;
    [ReproduceHow.SPECIFIC]: boolean;
    text: string | null;
  };
  [CustomConditionStep.SHARE_HOW]: { selected: ShareHow | null; text: string | null };
  [CustomConditionStep.START]: boolean | null;
  [CustomConditionStep.THANKS]: boolean | null;
  [CustomConditionStep.TRANSFER_COPYRIGHT_NO]: boolean | null;
  [CustomConditionStep.TRANSFER_COPYRIGHT_YES]: boolean | null;
  [CustomConditionStep.TRANSFER_COPYRIGHT]: boolean | null;
  [CustomConditionStep.USE_HOW_LONG]: {
    selected: UseHowLong | null;
    text: string | null;
  };
  [CustomConditionStep.USE_HOW]: {
    [UseHow.EDIT]: boolean;
    [UseHow.REPRODUCE]: boolean;
    [UseHow.SHARE_PUBLIC]: boolean;
  };
  [CustomConditionStep.USE_WHERE]: {
    selected: UseWhere | null;
    text: string | null;
  };
};

export enum CustomConditionStep {
  START = 'start',

  ALREADY_AGREEMENT = 'already_agreement',
  ALREADY_AGREEMENT_NO = 'already_agreement_no',
  ALREADY_AGREEMENT_PAPER = 'already_agreement_paper',
  ALREADY_AGREEMENT_NO_PAPER = 'already_agreement_no_paper',
  ALREADY_AGREEMENT_YES_PAPER = 'already_agreement_yes_paper',

  TRANSFER_COPYRIGHT = 'transfer_copyright',
  TRANSFER_COPYRIGHT_YES = 'transfer_copyright_yes',
  TRANSFER_COPYRIGHT_NO = 'transfer_copyright_no',

  USE_WHERE = 'use_where',
  USE_HOW_LONG = 'use_how_long',
  USE_HOW = 'use_how',

  REPRODUCE_HOW = 'reproduce_how',
  REPRODUCE_HOW_MANY = 'reproduce_how_many',

  SHARE_HOW = 'share_how',

  MODIFY_HOW = 'modify_how',

  OVERVIEW = 'overview',

  THANKS = 'thanks',
}

export enum UseWhere {
  BELGIUM,
  EUROPE,
  WORLD,
  OTHER,
}

export enum UseHowLong {
  FULL,
  OTHER,
}

export enum UseHow {
  REPRODUCE,
  SHARE_PUBLIC,
  EDIT,
}

export enum ReproduceHow {
  ANY,
  SPECIFIC,
  ELECTRONIC_FIXED,
  ELECTRONIC_ONLINE,
}

export enum ReproduceHowMany {
  UNLIMITED,
  LIMITED,
}

export enum ShareHow {
  PUBLISHED,
  DISPOSABLE,
  BOOK,
  PROMO,
}

export enum ModifyHow {
  EDIT,
  TRANSLATE,
}
