import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { Invoice } from '~/types/invoice';

import { getAccessToken } from '~/utils/auth';

import type { UpdateDraftInvoicePayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { invoicesQueryOptions } from './useInvoices';

export const useUpdateDraftInvoice = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateDraftInvoicePayload) => {
      const token = getAccessToken();
      const response = await request(`/invoices/${id}`, { method: 'PATCH', token, body });

      // TODO: Schema validation
      const { data: invoice } = apiResourceSchema(z.unknown()).parse(response);

      return invoice as Invoice;
    },
    onSuccess: (updatedInvoice) => {
      queryClient.setQueryData(invoicesQueryOptions.queryKey, (invoices) =>
        (invoices ?? []).map((invoice) => (invoice.id === updatedInvoice.id ? updatedInvoice : invoice)),
      );
    },
  });
};
