export const invoices = {
  alerts: {
    duplicateEntryNumber: 'This invoice number already exists, please use another one.',
    successCreated: 'The invoice was created successfully!',
    successDeleted: 'The concept was removed successfully!',
    successUpdated: 'The invoice was adapted successfully.',
  },
  createEdit: {
    advantageSimulationModal: {
      distribution: {
        commission: {
          descriptionWithCs:
            'The compensation to Creative Shelter. This is calculated per invoice, based on <a href="https://craft.creativeshelter.be/settings/plan">your current compensation</a> and is deducted from the amount (excluding VAT) you invoiced to your client. We take care of the administrative hassle so that you can invoice copyrights and thus earn more at the end of the day.',
          descriptionWithCsMinimumCommissionApplied:
            "The compensation to Creative Shelter. Because your invoice amount is too low, the minimum compensation of € 12.50 applies. In doubt about whether you still get a net benefit out of it? Contact us and we'll check it out together!",
          disclaimerWithCs: '',
          label: 'Compensation Creative Shelter',
        },
        costs: {
          descriptionWithCs:
            'We take into account 20% costs of the total invoice amount. This is an estimate, it may be higher or lower, although this is a realistic average.',
          descriptionWithoutCs:
            'We deduct 20 % charges from the total invoice amount. This is an estimate, this may be higher or lower, though this is a realistic average.',
          disclaimerWithCs: '',
          disclaimerWithoutCs: '',
          label: 'Costs',
        },
        netAmount: {
          descriptionWithCs:
            'The net amount you have left when you invoice with Creative Shelter, after deducting our compensation, costs, social security contributions, taxes, etc. All clear, Creative Shelter pays off!',
          descriptionWithCsNoAdvantage:
            "According to our estimation, you will not be able to benefit from this invoice. If you mainly or exclusively invoice non-creative performances, it will be beneficial not to send this invoice through us. Any doubts? Feel free to contact us and we'll look into it together!",
          descriptionWithoutCs:
            'The net amount you have left when you invoice without Creative Shelter, after deducting costs, social security contributions, taxes,... \nBe sure to compare, Creative Shelter pays off!',
          disclaimerWithCs: '',
          disclaimerWithoutCs: '',
          label: 'Net income',
        },
        socialContribution: {
          descriptionWithCs:
            'According to the distribution key, your invoice is split partly in copyrights and  partly in professional income. On your professional income (after deducting the costs you contribute), you will have to pay 20.5% social security contributions. These are the provisional contributions for your social insurance fund, with which you build your social rights.',
          descriptionWithoutCs:
            'On the full invoice amount, after deducting the costs you contribute, you will have to pay 20.5% social security contributions. These are the provisional contributions for your social insurance fund, with which you build your social rights.',
          disclaimerWithCs: 'The amount will be lower, as you will receive part of your income as a compensation for your copyrights.',
          disclaimerWithoutCs: '',
          label: 'Social contribution',
        },
        taxes: {
          descriptionWithCs:
            'Your invoice will be split partly in copyrights and partly in professional income according to the distribution key. Only on the latter part will you have to pay personal taxes (after deduction of your costs and social security contribution).',
          descriptionWithoutCs:
            'You pay personal income taxes on the full invoice amount, after deducting your costs and social contributions. How much you pay depends on your marital status and income. The higher your income, the more taxes you will pay. As there is no applicable distribution key, the tax amount will be higher compared to invoicing with copyrights.',
          disclaimerWithCs:
            'The average tax rate will be lower as your professional income is also reduced. Here, we reckon on a rate between 0% and 30%, depending on the distribution of your specific invoice.',
          disclaimerWithCsNotMainOccupation:
            'You indicated that you are active in a secondary occupation and we assume that you also receive income in your main occupation. To calculate the tax portion, we take a percentage of 45% into account.',
          disclaimerWithoutCs: '',
          label: 'Taxes',
        },
        withholdingTaxes: {
          descriptionWithCs:
            'The withholding tax is a tax that is calculated on your compensation for your copyrights. We calculate this automatically for you, deduct it and transfer it to the tax authorities. You don\'t have to do anything for this yourself, but this way you will receive your compensation for your copyrights in the correct way.\n\nIf you\'d like to read more about it, you can do that <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-roerende-voorheffing-en-hoe-wordt-deze-berekend?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank"> here</a>!',
          disclaimerWithCs: '',
          label: 'Withholding tax',
        },
      },
      label: {
        withCs: 'With Creative Shelter',
        withoutCs: 'Without Creative Shelter',
      },
      noDataDisclaimer: "The benefit cannot be calculated yet. Complete your invoice and make sure you've added at least one line.",
      title: 'Simulation benefit',
      toggle: {
        withCs: 'With Creative Shelter',
        withoutCs: 'Without Creative Shelter',
      },
      tooltip:
        'More information about the simulation can be found <a href="https://knowledge-creativeshelter-be.translate.goog/het-voordeel-als-je-factureert-via-creative-shelter?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank"> here</a>! ',
      totals: {
        advantage: 'Benefit',
        netAmount: 'Net income',
        noAdvantage: 'No benefit',
        totalInvoiced: 'Client invoice',
      },
    },
    assignment: {
      description: 'Briefly describe what your assignment entails.',
      title: 'Assignment',
    },
    attachments: {
      attachment: 'Attachment',
      description: 'Attach documents to this invoice.',
      error: {
        delete: 'Something went wrong while removing the attachment.',
        upload: 'Something went wrong while uploading the attachment.',
      },
      smalltext: 'You can only upload a .pdf',
      title: 'Attachments',
    },
    automaticApprovalModal: {
      automatic: 'Instant invoicing',
      description:
        'Your invoice is eligible for instant approval! If you wish, you can still choose to have your invoice reviewed by us. Want to have your invoice approved and sent instantly?',
      manual: 'Manual approval',
      title: 'Instant invoicing',
    },
    basicInfo: {
      description: 'All the basic information of your invoice.',
      title: 'Basic Information',
    },
    blocker: {
      leave: 'Exit without forwarding',
      message:
        'Are you sure you want to exit your invoice? It remains in edit mode until it is sent again. This means that we cannot approve it yet.',
    },
    clientInfo: {
      addEmailReceiver: 'Add recipient',
      emailSelfTooltip: 'You have chosen to forward invoices and credit notes to your client yourself.',
    },
    coTooLowModal: {
      description:
        'The amount you want to invoice is too low; after our compensation, you would have nothing left. \n\nAdjust, increase your invoice, or send it outside the platform.',
      dismiss: 'I get it!',
      title: 'Your invoice amount is too low',
    },
    confirmation: {
      description: "Are you sure you want to send the invoice? You can't make any changes after this.",
      descriptionEdit:
        'Invoice adjusted, ready to send it again? Once you do this, we can approve it. Not done yet? Save your invoice without forwarding it.',
      entryNumber: {
        creditNoteNumber: 'Credit note number',
        invoiceNumber: 'Invoice number',
        prefix: 'Prefix',
        serialNumber: 'Sequence number',
        suffix: 'Suffix',
        warning:
          'The numbering series ensures that your documents are automatically numbered correctly. Adjustments are allowed and possible, but at your own risk.',
      },
      minCommission: 'The minimum compensation of € 12.50 applies to this invoice.',
      submit: {
        create: 'Yes, create invoice',
        edit: 'Yes, edit invoice',
        saveAndExit: 'Save and exit',
      },
      title: 'Your invoice will be sent soon',
      titleEdit: 'Your invoice will be forwarded again',
    },
    lines: {
      description: 'List here the products and/or services you offer.',
      title: 'Invoicing lines',
    },
    preview: 'Preview invoice',
    remark: {
      description: 'Comments or questions for Creative Shelter.',
      title: 'Remarks',
    },
    royalties: {
      description: 'Choose here how you wish to grant your copyrights to the customer.',
      title: 'Copyrights',
      tooltip: 'The custom general terms and conditions are currently only available in Dutch.',
    },
    submit: {
      create: 'Create invoice',
      edit: 'Edit invoice',
    },
    title: {
      create: 'Invoice details',
      edit: 'Edit invoice',
    },
    vatExemptLimit: {
      exceeded: {
        buttonText: 'I get it!',
        description:
          'Your total turnover already exceeds €25,000. As a result, you are no longer allowed to make use of the VAT exemption regime. Please change this before continuing to invoice. For more information, please visit our <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-de-btw-vrijstellingsregeling-en-bestaan-er-nog-andere-regelingen?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=en-US&_x_tr_pto=wapp" target="_blank">Knowledge Base</a>.',
        title: 'Limit reached',
      },
      firstWarning: {
        buttonText: 'Okay, understood!',
        description:
          'Your total turnover is close to € 25,000. To be allowed to make use of the VAT exemption scheme, you may not exceed this limit. Are you keeping an eye on this?',
        title: 'Nearly at the limit',
      },
      secondWarning: {
        buttonText: 'Okay, I get it!',
        description:
          'Your total turnover is close to € 25,000. To be allowed to use the VAT exemption scheme, you may not go over this. Do you adjust this in time? ',
        title: 'Limit almost reached',
      },
    },
  },
  details: {
    confirmEditModal: {
      bodyLocked:
        "This invoice is still in edit mode. And that's why we can't approve it yet. Have you finished, completed/adjusted everything? Then don't forget to resend your invoice.",
      bodyPending: "This invoice is subject to approval. You can still edit, but don't forget to resend it again when you're done.",
      cancel: 'Cancel',
      confirm: 'Understood',
      title: 'Attention!',
    },
    errorBoundaryTitle: 'An error occurred while loading your invoice.',
    followUp: {
      modal: {
        accept: 'Start',
        addRemark: 'Add comment',
        input: {
          placeholder: 'Any comment',
        },
        message:
          'Your customer received automatic invoice reminder emails from us, but we did not receive payment. By starting a further follow-up, we will contact your customer personally to pay your invoice. \n\nDo you confirm that we can start this follow-up?',
        remarkMessage:
          'Do you have any additional information or a comment before we start monitoring your invoice? You can fill it in here.\n\nAnd do you have any further questions? Please send an e-mail to administratie@creativeshelter.be.',
        title: 'Start follow-up',
        titleRemark: 'Follow-up started',
      },
      start: 'Start following up of unpaid invoice',
    },
    history: {
      error: 'Something went wrong while retrieving the history.',
      message: {
        Approved: {
          default: '<strong>Approved </strong> and <strong> sent </strong> to your client',
          instant: '<strong>Instantly approved</strong> and<strong> sent</strong> to your client',
        },
        Created: {
          default: '<strong>Created</strong> as draft',
        },
        Credited: {
          default: '<strong>Credited </strong>',
        },
        EmailApprovedAndSent: {
          client: '<strong>Invoice </strong> sent to your client',
          user: '<strong>Invoice sent </strong> to your email address',
        },
        EmailPaymentReminder: {
          first: '<strong>First reminder email </strong> sent to your client',
          second: '<strong>Second reminder email </strong> sent to your client',
        },
        Expired: {
          default: '<strong>Expired </strong>',
        },
        Finalized: {
          default: '<strong>Sent </strong> for approval',
        },
        FollowUpContactClientByPhone: {
          default: 'Someone from the administration contacted </strong> your client <strong> by phone.',
        },
        FollowUpFormalNoticeSent: {
          default: '<strong>Registered letter drawn up and sent</strong>',
        },
        FollowUpInvoiceSentToBailiff: {
          default: '<strong>Follow-up by the bailiff started</strong>',
        },
        FollowUpInvoiceSentToLawyer: {
          default: '<strong>Registered letter sent by lawyer</strong>',
        },
        FollowUpLastManualReminder: {
          default: '<strong>Last reminder </strong> sent by someone from the administration',
        },
        FollowUpManualReminder: {
          default: '<strong>Reminder sent by someone from the administration </strong>',
        },
        FollowUpStarted: {
          default: '<strong>Payment follow-up started </strong>',
        },
        LockedByUser: {
          default: '<strong>In progress</strong> placed',
        },
        PaidByClient: {
          default: '<strong>Paid </strong> by your client',
        },
        PaidByCreativeShelter: {
          default: '<strong>Paid </strong> to you by Creative Shelter',
        },
        PartiallyPaid: {
          default: '<strong>Partial payment</strong> received of {{amount}}',
        },
      },
      title: 'History',
    },
    paymentInfo: {
      alreadyPaid: 'Already paid',
      copyright: 'Total copyrights',
      credited: 'This invoice has been credited',
      details: 'Details',
      dueDate: 'Expiry date',
      expectedPaidDate: 'Expected payment date',
      expectedPaidDateTooltip:
        'You will receive payment from Creative Shelter the first business day after we received payment from your client.',
      forInvoice: 'Invoice {{entryNumber}}',
      notAvailebleYet: "We'll show you payment information as soon as your invoice has been approved and sent to your client.",
      paidDate: 'Payment date',
      partialPayment: 'Payment {{number}}',
      performance: 'Professional income',
      remaining: 'Still to be paid',
      status: {
        label: 'Status',
        options: {
          fcPaid: 'Paid out',
          notPaid: 'Outstanding',
          paid: 'Paid',
        },
      },
      subtitle: '{{client}}to Creative Shelter',
      subtitleFc: 'Creative Shelter to you',
      subtotalCo: 'Subtotal',
      title: 'Payments',
      total: 'Total',
      totalPayment1: 'Total professional income + VAT',
      totalPayment2: 'Total copyrights (net)',
      vat: 'VAT',
      vat21: 'VAT 21%',
      vat6: 'VAT 6%',
      vatCopyright: 'VAT 6% (copyrights)',
      vatExceptionallyExempt: 'Exempt',
      vatPerformance: 'VAT 21% (professional income)',
      vatTotal: 'Total VAT',
      withholdingTax: 'Withholding tax',
    },
    paymentInfoDetail: {
      remark: {
        byAdmin: 'Comment from {{name}}.',
        byUser: 'Your comment',
      },
      title: 'Payment follow-up',
      types: {
        FollowUpContactClientByPhone: {
          description:
            "We'll contact your client by phone for an update on your unpaid invoice.\n<br>\nAnd as always, if there's an update, we'll let you know!",
          title: 'Someone from the administration contacted your client by phone.',
        },
        FollowUpFormalNoticeSent: {
          description:
            'A registered letter has been sent to your client. This is sent via e-mail and post. This way we can be sure that it will reach them.',
          title: 'The registered letter has been sent.',
        },
        FollowUpInvoiceSentToBailiff: {
          description:
            'A bailiff has been appointed to further monitor your unpaid invoice.\n<br> \nThe file is taken over and the bailiff will discuss it further with your client.',
          title: 'Your invoice will be further monitored by the bailiff.',
        },
        FollowUpInvoiceSentToLawyer: {
          description:
            'A registered letter was sent via our partner lawyer. This is sent by email and post. This way, we can be sure that it reaches your client.',
          title: 'A registered letter was sent via the lawyer.',
        },
        FollowUpLastManualReminder: {
          description:
            'Your client already received automatic reminders, including manual ones, and we already contacted them by telephone.\n<br> \nA final manual reminder will now follow to receive payment. Is there no response or update about the payment? Then we can move on to the next steps.',
          title: 'Someone from the administration sent your client a final reminder.',
        },
        FollowUpManualReminder: {
          description:
            "You indicated that payment follow-up could start, so we took action.\n<br>\nWe've already sent a manual reminder to your customer. As soon as there is an update, we'll let you know!",
          title: 'Someone from the administration sent your client a manual reminder.',
        },
        FollowUpStarted: {
          description:
            'We will continue to follow up on your expired invoice. Your client will receive additional reminders from us, we will try to contact you by phone and, if necessary, we will take further steps.\n<br>\nFurther steps include a registered letter, one via the lawyer and, if necessary, the bailiff (within Belgium).\n<br>\nAs soon as there is more news or an agreement about payment, we will inform you!',
          title: 'Payment follow-up started',
        },
      },
    },
    statusInfo: {
      date: {
        coPaid: 'Payment date',
        credited: 'Credit note date',
        expired: 'Expiry date',
        fcPaid: 'Payout date',
        sent: 'Invoice date',
      },
      message: {
        CoPaid: {
          default: 'Your client has paid the invoice. You will receive payment from Creative Shelter the next working day.',
        },
        CoPartiallyPaid: {
          default: 'We received a partial payment from your client. You can follow up the payments in detail below.',
        },
        Credited: {
          default: 'You credited this invoice.<br>Credit note number:{{creditNoteNumber}}.<br>Reason: {{creditNoteReason}}',
        },
        Expired: {
          default:
            'Your invoice has expired. We will follow this up for you with your client<a href="https://knowledge.creativeshelter.be/wat-als-mijn-klant-opdrachtgever-nog-niet-betaald-heeft-op-de-vervaldag" target="_blank"> according to our guidelines</a> .',
          followingUp:
            'Your invoice has not been paid by your customer within the due period. In order to obtain payment, we have started the necessary follow-up. You can follow every step below.',
        },
        FcPaid: {
          default: 'Your invoice has been paid. Below you will find the payments in detail.',
        },
        LockedByUser: {
          default:
            'Your invoice is in progress. This means that we cannot check, approve and send it to your client. Please resend it has been completed/adjusted.',
        },
        Pending: {
          default:
            'We\'ve received your invoice and will approve it as soon as possible. Want to invoice even faster? <a href="https://knowledge.creativeshelter.be/producten-wat-zijn-ze-en-hoe-gebruik-je-ze" target="_blank">Find out here</a> how to use products for instant invoicing. ',
        },
        Sent: {
          default: 'Your invoice has been approved and sent to your client',
        },
      },
      progressBars: {
        stepOneTooltip: {
          body: 'In this step, your invoice is checked and approved by the Care team.',
          title: 'Step 1: created',
        },
        stepThreeTooltip: {
          body: 'Did your client pay the invoice? Then the last step is completed.',
          title: 'Step 3: paid',
        },
        stepTwoTooltip: {
          body: 'When your invoice is approved, it will be sent to your client in this step.',
          title: 'Step 2: approved and sent',
        },
      },
      title: {
        paymentFollowUp: 'Payment in follow-up',
      },
    },
  },
  fields: {
    billingPeriod: {
      helpText: 'Expiry date <0>{{ date }}</0>',
      label: 'Payment terms',
      options: {
        '15': '15 days',
        '30': '30 days',
        '45': '45 days',
        '60': '60 days',
      },
      placeholder: 'Select a term',
    },
    billingPeriodEndOfMonth: {
      label: 'End of month',
      tooltip:
        'The payment term for your invoice will be {{days}} days at the end of the month. On the left you can see what the due date will be.',
    },
    brandId: {
      label: 'Trademark',
      placeholder: 'Select a trade name',
    },
    clientId: {
      add: 'Add client',
      label: 'Client',
      placeholder: 'Select a client.',
    },
    condition: {
      label: 'Copyrights regimes',
      link: 'Please check',
      placeholder: 'Select a regime',
    },
    contactId: {
      add: 'Add contact',
      label: 'Contact person',
      placeholder: 'Select a contact',
    },
    customAgreementId: {
      label: 'Framework agreement',
      placeholder: 'Select a framework agreement',
    },
    description: {
      label: 'Description',
      placeholder: 'Enter the description of the invoice here.',
    },
    entryNumber: {
      label: 'Invoice number',
    },
    numberingSeriesId: {
      helpText: 'The invoice number will be <0>{{entryNumber}}</0>.',
      label: 'Numbering range',
      labelTooltip:
        'Questions about creating a numbering range? Click <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">here</a> for more information.',
      placeholder: 'Select a numbering range',
      tooltip: 'This is the preview of your invoice number. When you send your invoice, the number will be definitive.',
      tooltipCreate:
        'A numbering range helps you number your invoices correctly and smoothly. You can create as many as you want, but one is sufficient. More info questions? <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=en&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">Please check it here.</a>',
    },
    orderReference: {
      label: 'Client reference number',
      placeholder: 'Client reference number',
    },
    remark: {
      label: 'Remarks',
      placeholder:
        'Do you have an additional comment or question about this invoice, addressed to Creative Shelter? You can fill this in here! ',
    },
    specialConditions: {
      custom: 'This document is linked to our agreement dated {{date}}',
      label: 'Special conditions',
      placeholder: 'Fill in any special conditions here.',
    },
    title: {
      label: 'Title',
      placeholder: 'Title',
    },
  },
  header: {
    actionBlock: {
      button: 'Create invoice',
      buttonMobile: 'New invoice',
      dropdown: {
        creditnote: 'Create credit note',
        notCreative: 'Non creative Invoice (Billit)',
      },
    },
    description: 'View your concepts and sent invoices.',
    title: 'Invoices',
  },
  overview: {
    columns: {
      client: 'Client',
      date: 'Invoice date',
      days: 'days',
      dueDate: 'Expiration date',
      entryNumber: 'Invoice',
      saved: 'Benefit',
      status: {
        lockedByUserTooltip:
          "Oops! This invoice is still in 'edit mode', and cannot be approved by our admin. Click on 'Edit invoice' to release your invoice for approval.",
        options: {
          CoPaid: 'Paid',
          CoPartiallyPaid: 'Partially paid',
          Credited: 'Credited',
          Expired: 'Expired',
          FcPaid: 'Paid out',
          LockedByUser: 'Not completed',
          PaymentInFollowUp: 'Payment in follow-up',
          Pending: 'For approval',
          Sent: 'Sent',
        },
        title: 'Status',
      },
      total: 'Total (excl. VAT)',
      year: 'Year',
    },
    created: {
      subtitle: 'Invoices',
    },
    excel: {
      copyright21: 'Total copyrights at 21% VAT',
      copyright6: 'Total copyright at 6% VAT',
      paidDate: 'Payment date',
      performance: 'Total professional income',
      vatCopyright21: 'VAT on copyrights at 21% VAT',
      vatCopyright6: 'VAT on copyrights at 6% VAT',
      vatPerformance: 'VAT on professional income',
    },
    lockedByAdmin: {
      description: 'Invoice with number {{number}} can no longer be edited as it is being approvd by our admin.',
      title: 'Invoice cannot be edited.',
    },
    rowActions: {
      advantage: 'View benefit',
      credit: 'Credit',
      details: 'More details',
      download: 'Dowload',
      duplicate: 'Copy',
      edit: {
        error: 'Something went wrong when opening the invoice. Please try again.',
        label: 'Edit invoice',
      },
      preview: 'View client invoice',
    },
  },
};
