import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownMenu } from '~/components';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { insertIf } from '~/utils/arrays';

import { useSectionContext } from '../../Section/context';
import { useLineContext } from '../../SectionLines/context';

export const LineActions = () => {
  const {
    clearErrors,
    formState: { isSubmitted },
    getValues,
    setValue,
    trigger,
    watch,
  } = useInvoiceQuotationFormContext();
  const { line, lineFieldName, lineIndex } = useLineContext();

  const { t } = useTranslation(['common', 'lines']);

  const discountTypeFieldName = `${lineFieldName}.discountType` as const;
  const discountValueFieldName = `${lineFieldName}.discountValue` as const;
  const watchDiscountType = watch(discountTypeFieldName);

  const { isDefaultSection, linesFieldArray, sectionIndex, sectionsFieldArray } = useSectionContext();
  const { fields: sections, remove: removeSection } = sectionsFieldArray;
  const { fields: lines, insert: insertLine, remove: removeLine } = linesFieldArray;

  /**
   * Remove the line, or the whole section if it is the last line (unless it's the default section)
   */
  const remove = useCallback(
    (lineIndex: number) => {
      const isLastLine = lines.length === 1;
      const isLastSection = sections.length === 1;

      if (isLastLine && !isDefaultSection) {
        removeSection(sectionIndex);
      } else {
        removeLine(lineIndex);
      }

      // Retrigger the section validation as removing a line doesn't do this automatically
      if (isSubmitted && isLastLine && isLastSection) {
        trigger('sections');
      }
    },
    [isDefaultSection, isSubmitted, lines.length, removeLine, removeSection, sectionIndex, sections.length, trigger],
  );

  return (
    <div data-pf-id="line-actions-button">
      <DropdownMenu
        dropdownClass="text-sm"
        iconSize={24}
        items={[
          {
            text: t('common:delete'),
            iconName: 'Delete',
            onClick: () => remove(lineIndex),
          },
          {
            text: 'Kopiëren',
            iconName: 'ContentCopy',
            onClick: () => insertLine(lineIndex + 1, getValues(lineFieldName)),
          },
          ...insertIf(line.type === 'entry', {
            text: watchDiscountType === null ? t('lines:actions.applyDiscount') : t('lines:actions.removeDiscount'),
            iconName: 'Sell',
            onClick: () => {
              if (watchDiscountType === null) {
                setValue(discountTypeFieldName, 'percentage');
                setValue(discountValueFieldName, '');
              } else {
                setValue(discountTypeFieldName, null);
                setValue(discountValueFieldName, null);
                if (isSubmitted) clearErrors(discountValueFieldName);
              }
            },
            'data-pf-id': watchDiscountType === null ? 'line-actions-apply-discount' : undefined,
          }),
        ]}
      />
    </div>
  );
};
