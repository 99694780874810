import * as stylex from '@stylexjs/stylex';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';

import { styles } from './Pill.styles';

type Props = {
  children: string;
  icon?: IconName;
};

export const Pill = ({ children, icon }: Props) => {
  return (
    <div {...stylex.props(styles.pill)}>
      {icon && <Icon name={icon} size={20} />}
      <span>{children}</span>
    </div>
  );
};
