import type { ComponentProps } from 'react';

import type { FilteredFormFieldProps } from '../types';

import { useFormFieldContext } from '../FormFieldContext';
import { Input } from './Input';

type Props = FilteredFormFieldProps<ComponentProps<typeof Input>> & {
  afterOnChange?: (values: { newValue: string; oldValue: string }) => void;
};

export const FormInput = ({ afterOnChange, ...delegated }: Props) => {
  const { field, fieldState } = useFormFieldContext();

  return (
    <Input
      disabled={field.disabled}
      invalid={fieldState.invalid}
      name={field.name}
      onBlur={field.onBlur}
      onChange={(value) => {
        field.onChange(value);
        afterOnChange?.({ oldValue: field.value, newValue: value });
      }}
      ref={field.ref}
      value={field.value}
      {...delegated}
    />
  );
};
