export const quotations = {
  alerts: {
    successCreated: 'Le devis a bien été créé !',
    successDeleted: 'Le concept a bien été supprimé !',
    successUpdated: 'Le devis a bien été modifié !',
  },
  clientAccept: {
    card: {
      button: "Accepter l'offre",
      description: {
        accept: "Êtes-vous d'accord avec l'offre de {{user}} ? Acceptez-la via le bouton ci-dessous.",
        accepted: "L'offre est acceptée.",
        declined: "L'offre est rejetée.",
        expired: "L'offre a expiré et ne peut plus être approuvée.",
      },
      title: 'Offre de {{user}}',
    },
    preview: {
      assignment: {
        title: 'Mission',
      },
      client: 'Données client',
      conditions: {
        description: 'Consulter les conditions générales de cette offre',
        title: 'Conditions générales',
      },
      createdBy: 'Cette offre a été préparée et envoyée par',
      description: 'Description',
      expirationDate: "Valable jusqu'au",
      freelancer: 'Exécutant de la mission',
      introduction: 'Introduction',
      specialConditions: 'Conditions particulières',
      title: 'Titre',
    },
  },
  createEdit: {
    assignment: {
      description: 'Décrivez brièvement en quoi consiste votre mission.',
      title: 'Mission',
    },
    attachments: {
      attachment: 'Pièce jointe',
      description: 'Ajouter des documents à cette offre',
      error: {
        delete: "Une erreur s'est produite lors de la suppression de la pièce jointe.",
        upload: "Une erreur s'est produite lors du téléchargement de la pièce jointe.",
      },
      title: 'E-mail et pièces jointes',
    },
    basicInfo: {
      description: 'Toutes les informations de base de votre offre.',
      title: 'Informations de base',
    },
    blocker: {
      message:
        "Êtes-vous sûr de vouloir quitter la page ? Les modifications que vous avez apportées au devis n'ont pas encore été enregistrées.",
    },
    clientInfo: {
      addEmailReceiver: 'Ajouter un destinataire',
      emailSelfTooltip: "Vous avez choisi d'envoyer vous-même des devis à votre client.",
    },
    confirmation: {
      description:
        "Êtes-vous sûr de vouloir transmettre l'offre? L'offre ne sera pas vérifié par un administrateur et immédiatement envoyé au client.",
      submit: {
        create: "Oui, envoyer l'offre",
        edit: "Oui, modifier l'offre",
      },
      title: 'Votre devis sera envoyé',
    },
    lines: {
      description: 'Listez ici les produits et/ou services que vous proposez.',
      title: "Lignes de l'offre",
    },
    preview: "Exemple de l'offre",
    royalties: {
      description: "Choisissez ici comment vous souhaitez attribuer vos droits d'auteur à votre client.",
      title: 'Droits d’auteur',
      tooltip: "Les conditions générales personnalisées ne sont actuellement disponibles qu'en néerlandais.",
    },
    submit: {
      create: "Envoyer l'offre",
      edit: "Modifier l'offre",
    },
    title: {
      create: "Détails de l'offre",
      edit: "Modifier l'offre",
    },
  },
  fields: {
    attachment: {
      label: 'Pièce jointe',
    },
    brandId: {
      label: 'Marque de commerce',
      placeholder: 'Sélectionner la marque de commerce',
    },
    clientId: {
      add: 'Ajouter un client',
      label: 'Client',
      placeholder: 'Sélectionner un client.',
    },
    condition: {
      label: "Accords de droits d'auteur",
      placeholder: 'Sélectionner un régime.',
    },
    contactId: {
      add: 'Ajouter un contact',
      label: 'Personne de contact',
      placeholder: 'Sélectionner un client.',
    },
    customAgreementId: {
      label: 'Contrat-cadre',
      placeholder: 'Sélectionnez un contrat-cadre',
    },
    description: {
      label: 'Description',
      placeholder: "Donnez une brève description de l'offre.",
    },
    entryNumber: {
      label: "Numéro de l'offre",
      last: 'Votre dernier devis a le numéro <0>{{entryNumber}}</0>.',
      placeholder: "Numéro de l'offre",
    },
    expirationDate: {
      label: "Date d'échéance de l'offre",
      placeholder: 'JJ/MM/AAAA',
    },
    introduction: {
      label: 'Introduction',
      placeholder: 'Donnez une courte description',
    },
    mail: {
      default:
        '<p>\nBonjour #CLIENTNAME#,<br/><br/>\n\n#FREELANCERFIRSTNAME# a établi une offre. Merci de vérifier celle-ci. <br/><br/>\n\nIci : #QUOTATIONLINK#.<br/><br/>\n\nDès que vous donnez votre approbation, #FREELANCERFIRSTNAME# pourra se mettre au travail.\n</p>',
      label: 'Email',
      placeholder: 'Écrivez votre e-mail ici.',
    },
    specialConditions: {
      custom: 'Ce document est lié à notre contrat-cadre du {{date}}',
      label: 'Conditions particulières',
      placeholder: 'Remplissez ici toutes les conditions eventuelles spéciales.',
    },
    title: {
      label: 'Titre',
      placeholder: 'Titre',
    },
  },
  header: {
    actionBlock: {
      button: 'Créer une offre',
      buttonMobile: 'Nouvelle offre',
    },
    description: 'Visualisez tous vos concepts et offres envoyés.',
    title: 'Offres',
  },
  overview: {
    allowModal: {
      description: "Êtes-vous sûr d'indiquer l'offre comme approuvée ?",
      title: "Marquer l'offre comme approuvée",
    },
    columns: {
      client: 'Client',
      date: "Date de l'offre",
      days: 'jours',
      entryNumber: 'Offre',
      status: {
        options: {
          Allowed: 'Approuvé',
          Declined: 'Refusé',
          Expired: 'Expiré',
          Pending: 'Pour approbation',
          Sent: 'Envoyé',
        },
        title: 'Statut',
      },
      total: 'Total (hors TVA)',
      year: 'Année',
    },
    created: {
      subtitle: 'Offres',
    },
    declineModal: {
      description: "Êtes-vous sûr d'indiquer l'offre comme rejetée ?",
      title: "Marquer l'offre comme rejetée",
    },
    rowActions: {
      allow: 'Approuver',
      decline: 'Rejeter',
      download: 'Télécharger',
      duplicate: 'Copier',
      edit: {
        label: 'Éditer',
      },
      invoice: 'Créer la facture',
    },
  },
  richText: {
    linkModal: {
      label: 'Lien',
      placeholder: 'www.creativehelter.be',
      title: 'Saisir un lien',
    },
    shortcodes: {
      Clientname: 'Nom du client',
      Freelancerfirstname: 'Prénom du freelance',
      InviteeName: 'Nom du destinataire',
      Quotationlink: "Lien vers l'offre",
    },
  },
};
