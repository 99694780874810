import type { CSSProperties } from 'react';

import { ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Combobox as HUI_Combobox } from '@headlessui/react';
import classNames from 'classnames';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/SVG';

import type { ComboboxValue, MultiComboboxProps as Props } from './types';

import styles from './MultiCombobox.module.scss';

const includesString = (haystack: string, needle: string) =>
  haystack.toLowerCase().replace(/\s+/g, '').includes(needle.toLowerCase().replace(/\s+/g, ''));

export const MultiCombobox = <T extends ComboboxValue = ComboboxValue>({
  allowCustomValues = false,
  disabled = false,
  id,
  innerRef,
  invalid = false,
  onBlur,
  onChange,
  options,
  placeholder,
  value,
}: Props<T>) => {
  const [comboboxWidth, setComboboxWidth] = useState(0);
  const multiComboboxRef = useRef<HTMLDivElement>(null);

  const [query, setQuery] = useState('');

  const visibleOptions = useMemo(() => {
    if (query === '') return options;

    const filteredOptions = options
      .filter((option) => {
        const filterValue = option.filterValue ?? option.label;
        return Array.isArray(filterValue) ? filterValue.some((value) => includesString(value, query)) : includesString(filterValue, query);
      })
      .slice(0, 100);

    if (allowCustomValues && query.trim() !== '' && !filteredOptions.some(({ value }) => value === query.trim())) {
      return [{ label: query.trim(), value: query.trim() as T }, ...filteredOptions];
    }

    return filteredOptions;
  }, [allowCustomValues, options, query]);

  const selectedOptions = options.filter((option) => value.includes(option.value));

  const { t } = useTranslation(['common']);

  useLayoutEffect(() => {
    if (multiComboboxRef.current) setComboboxWidth(multiComboboxRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <HUI_Combobox
      as="div"
      className={classNames(styles.Wrapper, disabled && styles.Disabled, invalid && styles.Invalid)}
      disabled={disabled}
      immediate
      multiple
      onChange={(value) => {
        onChange(value ?? []);
        setQuery('');
      }}
      ref={multiComboboxRef}
      value={value}
    >
      <div className={styles.Tags}>
        {selectedOptions.map((option) => (
          <div className={styles.Tag} key={option.value}>
            <span>{option.label}</span>

            <span
              className={styles.RemoveTag}
              onClick={(e) => {
                e.stopPropagation();
                onChange(value.filter((value) => value !== option.value));
              }}
            >
              <Icon name="Close" size={10} />
            </span>
          </div>
        ))}

        <ComboboxInput
          className={styles.Input}
          displayValue={() => query}
          id={id}
          onBlur={onBlur}
          onChange={(event) => setQuery(event.target.value)}
          placeholder={selectedOptions.length === 0 ? placeholder : t('common:listbox.search')}
          ref={innerRef}
        />
      </div>

      <div className={styles.Buttons}>
        <ComboboxButton className={styles.ButtonUnfold}>
          <Icon name="KeyboardArrowDown" />
        </ComboboxButton>
      </div>

      <ComboboxOptions
        anchor={{ to: 'bottom end', gap: 10, padding: 40, offset: 49 }}
        className={styles.Options}
        modal={false}
        style={{ '--combobox-width': `${comboboxWidth}px` } as CSSProperties}
      >
        {options.length === 0 ? (
          <div className={styles.NoOptions}>{t('common:listbox.noOptions')}</div>
        ) : visibleOptions.length === 0 && query.trim() !== '' && !allowCustomValues ? (
          <div className={styles.NoResults}>{t('common:listbox.noResults')}</div>
        ) : (
          visibleOptions.map((option) => (
            <ComboboxOption className={styles.Option} disabled={option.disabled} key={option.value} value={option.value}>
              <span>{option.label}</span>
              <span className={styles.Check}>
                <Icon name="Check" size={20} />
              </span>
            </ComboboxOption>
          ))
        )}
      </ComboboxOptions>
    </HUI_Combobox>
  );
};
