export const settings = {
  accountCompletionModal: {
    action: {
      complete: 'Voltooien',
      next: 'Volgende',
    },
    description: 'Vul je gegevens aan, zodat je snel kan beginnen met factureren.',
    financialInfo: {
      check: 'Controleren',
      description: 'Vul hieronder je IBAN-nummer in.',
      error: 'Er ging iets mis bij het opslaan van je IBAN-nummer.',
      tab: 'Bankgegevens',
    },
    frameworkAgreement: {
      description: "Lees hier de overeenkomst, akkoord kan je geven door in het veld hieronder 'Gelezen en goedgekeurd' te typen.",
      descriptionAddendum2024:
        "Sinds de start van het nieuwe jaar is de verdeelsleutel gewijzigd naar 60/40, beroepsinkomsten/auteursrechten. Hieronder vind je het addendum op jouw raamovereenkomst met Creative Shelter. Door in het veld hieronder 'Gelezen en goedgekeurd' te typen, geef je je akkoord en kan je verder factureren.",
      error: 'Er ging iets mis bij het tekenen van je overeenkomst.',
      tab: 'Raamovereenkomst',
    },
    successModal: {
      action: "Let's get started!",
      description: 'Je bent nu klaar om jouw factuur of offerte te versturen!',
      title: 'Account aangevuld',
    },
    title: 'Vervolledig jouw account',
    vatRegulation: {
      error: 'Er ging iets mis bij het opslaan van je btw-status.',
      tab: 'Btw-status',
    },
  },
  alerts: {
    brands: {
      successCreated: 'Je handelsnaam werd aangemaakt!',
      successDeleted: 'Je handelsnaaam werd verwijderd!',
      successFavorite: 'Je favoriete handelsnaam is succesvol gewijzigd!',
      successUpdated: 'Je handelsnaam werd gewijzigd!',
    },
    community: {
      errorProfilePictureDeleted:
        'Er ging iets mis bij het verwijderen van je Community profielfoto. Neem contact op met service@creativeshelter.be als het probleem zich blijft voordoen.',
      errorProfilePictureTooLarge: 'De afbeelding die je wilt uploaden mag niet groter zijn dan 5 Mb.',
      errorProfilePictureUploaded:
        'Er ging iets mis bij het uploaden van je Community profielfoto. Neem contact op met service@creativeshelter.be als het probleem zich blijft voordoen.',
      errorUpdated:
        'Er ging iets mis bij het updaten van je Community profiel. Neem contact op met service@creativeshelter.be als het probleem zich blijft voordoen.',
      successProfilePictureDeleted: 'Je Community profielfoto werd verwijderd!',
      successProfilePictureUploaded: 'Je Community profielfoto werd gewijzigd!',
      successUpdated: 'Je Community profiel werd gewijzigd!',
    },
    customConditions: {
      successDeleted: 'De custom algemene voorwaarde werd succesvol verwijderd!',
    },
    integrations: {
      successAccountantLinked: 'Je accountant werd succesvol toegevoegd!',
      successAccountantUnlinked: 'Je accountant werd succesvol verwijderd!',
      successBillitPaired: 'Je boekhoudpakket werd succesvol toegevoegd!',
      successCashacaPaired: 'Cashaca werd succesvol gekoppeld!',
      successForwardingEmailLinked: 'Het e-mailadres werd succesvol toegevoegd!',
      successForwardingEmailUnlinked: 'Het e-mailadres werd succesvol verwijderd!',
    },
    invitations: {
      clipboardError: 'Kopiëren naar het klembord wordt niet ondersteund in jouw browser.',
    },
    numberingSeries: {
      duplicateFormat: 'Het formaat van deze nummeringreeks heb je al gebruikt, kies je een nieuwe?',
      successCreated: 'Je nummeringreeks werd succesvol aangemaakt!',
      successDeleted: 'Je nummeringreeks werd succesvol verwijderd!',
      successUpdated: 'Je nummeringreeks werd succesvol gewijzigd!',
    },
    successUpdated: 'De instellingen werden succesvol opgeslagen!',
  },
  documents: {
    description: 'Een overzicht van alle persoonlijke documenten.',
    headers: {
      name: 'Bestandsnaam',
      type: 'Type',
    },
    title: 'Documenten',
  },
  edit: 'Aanpassen',
  fields: {
    accountantEmail: {
      label: 'E-mailadres van je accountant',
      placeholder: 'E-mailadres',
    },
    activities: {
      other: {
        label: 'Vul hier je extra (creatieve) activiteiten in',
      },
      primary: {
        label: 'Hoofdactiviteit',
      },
      secondary: {
        label: 'Extra activiteiten',
      },
    },
    annex: {
      label: 'Toevoeging',
      placeholder: 'Toevoeging',
    },
    box: {
      label: 'Bus',
      placeholder: 'Bus',
    },
    brandId: {
      label: 'Handelsnaam',
    },
    brandName: {
      label: 'Handelsnaam',
      placeholder: 'Jouw handelsnaam',
    },
    city: {
      label: 'Stad of gemeente',
      placeholder: 'Stad of gemeente',
    },
    color: {
      label: 'Kleur',
    },
    communityDescription: {
      label: 'Stel jezelf en/of je activiteiten kort voor',
    },
    communityLinks: {
      label: 'Links',
      placeholders: {
        behance: 'https://www.behance.net/',
        facebook: 'https://www.facebook.com/',
        instagram: 'https://www.instagram.com/',
        linkedIn: 'https://www.linkedin.com/in/',
        website: 'https://',
      },
    },
    communityOpenToWork: {
      label: 'Ik ben beschikbaar voor opdrachten',
    },
    communityRegion: {
      label: 'Regio',
      placeholder: 'Niet gespecificeerd',
    },
    companyName: {
      label: 'Bedrijfsnaam',
    },
    currentPassword: {
      label: 'Huidig wachtwoord',
    },
    email: {
      label: 'E-mailadres',
      placeholder: 'E-mailadres',
    },
    firstName: {
      label: 'Voornaam',
      placeholder: 'Naam',
    },
    forwarding: {
      alerts: {
        billtobox: 'Vergeet niet om ‘administratie@creativeshelter.be’ als een gekend e-mailadres toe te voegen in Billtobox.',
      },
      frequency: {
        label: 'Frequentie',
        options: {
          instant: 'Per factuur of creditnota (.pdf/.xml)',
          monthly: 'Per maand (.zip)',
          quarterly: 'Per kwartaal (.zip)',
        },
        tooltip:
          'Als je een boekhoudpakket ingaf, kies dan voor de optie om per factuur of creditnota een pdf- en xml-bestand door te sturen.',
      },
      platformEmail: {
        label: 'E-mailadres van je Accountable account',
      },
    },
    lang: {
      label: 'Taal',
    },
    lastName: {
      label: 'Achternaam',
      placeholder: 'Achternaam',
    },
    logo: {
      error: {
        delete: 'Er ging iets mis bij het verwijderen van het logo. Probeer je het nog eens opnieuw?',
        upload: 'Er ging iets fout bij het uploaden van het logo.',
      },
      label: 'Handelsnaam logo',
      tooltip:
        'Als je deze handelsnaam selecteert bij het opstellen van een document, wordt dit logo gebruikt in plaats van het logo van je hoofdhandelsnaam.',
    },
    mailText: {
      default:
        'Hey #NAME#<br/><br/>Freelancers zijn er om elkaar te helpen en te steunen. En precies om die reden stel ik je vandaag graag de tool van Creative Shelter voor. Zelf heb ik hier al veel aan gehad en er veel bij gewonnen.<br/><br/>Heb je ooit al gehoord van Creative Shelter? Wel, Creative Shelter biedt een online platform aan dat me, dankzij auteursrechten, meer helpt te verdienen aan mijn freelance opdrachten. Snel en eenvoudig en daarbovenop juridisch volledig in orde.<br/><br/>Misschien is dit ook wel iets voor jou?<br/><br/>Groeten,<br/>{{ firstName }} {{ lastName }}',
      label: 'E-mail',
      placeholder: 'Schrijf hier je bericht',
    },
    mainOccupation: {
      label: 'Zelfstandige activiteit',
      options: {
        '0': 'Bijberoep',
        '1': 'Hoofdberoep',
      },
    },
    newPassword: {
      label: 'Nieuw wachtwoord',
    },
    newPasswordConfirmation: {
      label: 'Bevestig nieuw wachtwoord',
    },
    number: {
      label: 'Huisnummer',
      placeholder: '#',
    },
    numberingSeries: {
      format: {
        amountOfDigits: {
          label: 'Aantal cijfers',
          option: '{{amountOfDigits}} cijfers',
        },
        lengthWarning: 'We raden aan de lengte van het nummer te beperken tot {{limit}} karakters.',
        nextNumber: 'Het volgende nummer in deze reeks is <strong>{{number}}</strong>',
        prefixText: {
          label: 'Voorvoegsel',
        },
        separator: {
          label: 'Scheidingsteken',
          none: 'Geen',
        },
        suffixText: {
          label: 'Achtervoegsel',
        },
        useSuffix: 'Achtervoegsel gebruiken',
        year: {
          label: 'Jaar/maand',
          options: {
            YY: 'Jaar kort ({{yearMonth}})',
            YYMM: 'Jaar kort + maand ({{yearMonth}})',
            YYYY: 'Jaar ({{yearMonth}})',
            YYYYMM: 'Jaar + maand ({{yearMonth}})',
            none: 'Geen',
          },
        },
      },
      name: {
        label: 'Naam',
        placeholder: 'Bv. Verkoopfacturen',
        tooltip: 'Geef je nummeringreeks een naam, zo herken je deze snel.',
      },
      resetInterval: {
        description: 'Reset de telling automatisch naar 1.',
        label: 'Telling herstarten',
        options: {
          monthly: 'Maandelijks',
          never: 'Nooit',
          yearly: 'Jaarlijks',
        },
      },
      startAt: {
        label: 'Startnummer',
      },
      type: {
        label: 'Type',
        options: {
          both: "Facturen en creditnota's",
          credit_notes: "Creditnota's",
          invoices: 'Facturen',
        },
        tooltip:
          "In je boekhouding bestaan er twee belangrijke type documenten, je facturen of creditnota's. Aan jou de keuze om één nummeringreeks die beide omvat, maar een opsplitsing kan ook. Dat laten we aan jou over.",
      },
    },
    phone: {
      label: 'GSM-nummer',
      placeholder: '+32 4 999 999 99',
    },
    street: {
      label: 'Straatnaam',
      placeholder: 'Straatnaam',
    },
    vatID: {
      label: 'Btw-nummer',
      placeholder: 'BE1233456789',
    },
    zipcode: {
      label: 'Postcode',
      placeholder: 'Postcode',
    },
  },
  general: {
    customConditions: {
      create: 'Nieuwe voorwaarden',
      delete: {
        action: 'Verwijder',
        description: 'Ben je zeker dat je de custom algemene voorwaarde ({{name}}) wil verwijderen?',
        title: 'Verwijder custom algemene voorwaarden',
      },
      description: 'Bekijk hier het overzicht van al je custom algemene voorwaarden of maak er één aan.',
      table: {
        actions: {
          creatingPdf: 'Pdf aan het genereren...',
          delete: 'Verwijder',
          title: 'Acties',
          view: 'Bekijk',
        },
        name: 'Naam',
      },
      title: 'Custom algemene voorwaarden',
      wizard: {
        already_agreement: {
          question: 'Heb je al afgesproken met je klant over hoe je werk mag gebruikt worden?',
        },
        already_agreement_no: {
          question: 'No worries! Aan de hand van volgende vragen bepaal jij wat je klant met je werk mag doen.',
        },
        already_agreement_no_paper: {
          question: 'No worries! Aan de hand van volgende vragen zetten we ze op papier voor jou.',
        },
        already_agreement_paper: {
          question: 'Heb je die afspraken op papier?',
        },
        already_agreement_uploaded: {
          question: 'Bedankt om de afspraken met je klant te uploaden. We gaan er mee aan de slag en contacteren je zo snel mogelijk!',
        },
        already_agreement_yes_paper: {
          question:
            "Perfect, goede afspraken maken goede vrienden. Duid in het profiel van de klant aan dat je al een bestaande overeenkomst hebt en bezorg ons deze via e-mail (<a href='mailto:administratie@creativeshelter.be'>administratie@creativeshelter.be</a>). Wij nemen deze overeenkomst graag voor je onder de loep, en tekenen mee als derde partij.",
        },
        common_answers: {
          close: 'Ok, sluit de wizard',
          next: 'Ok, volgende vraag',
          no: 'Nee',
          ok: 'Ok',
          previous: 'Vorige vraag',
          start_questions: 'Ok, start de vragen',
          yes: 'Ja',
        },
        modify_how: {
          options: {
            '0': 'Te bewerken/wijzigen door elementen toe te voegen en/of weg te laten.',
            '1': 'Te vertalen/om te zetten.',
          },
          question: 'Hoe mag je werk bewerkt of gewijzigd worden?',
        },
        overview: {
          error:
            'Er ging iets mis tijdens het aanmaken van je custom algemene voorwaarden. Probeer het later nog eens of stuur ons een bericht.',
          finish: 'Ok, maak mijn custom algemene voorwaarden aan.',
          name: {
            label:
              'Geef hier de naam in van je nieuwe custom algemene voorwaarden. Dit kan de naam van de klant bevatten of de specifieke afspraken.',
            placeholder: "Bijvoorbeeld 'Gebruiksrecht Benelux 1 jaar'",
          },
          question: 'Bedankt, dat waren alle vragen! Hier kan je nog een overzicht terugvinden van je antwoorden:',
        },
        reproduce_how: {
          options: {
            '0': 'Op eender welke wijze, in eender welke vorm, met alle technieken en op alle dragers.',
            '1': 'In een specifieke vorm.',
            '2': 'In elektronische vorm op vaste informatiedrager.',
            '3': 'In elektronische vorm op online informatiedrager.',
          },
          other_placeholder: 'Specifieer je in welke vorm?',
          question: 'Op welke manier mag je werk gereproduceerd worden?',
        },
        reproduce_how_many: {
          options: {
            '0': 'Onbeperkt',
            '1': 'Beperkt',
          },
          other_placeholder: 'Specifieer het aantal',
          question: 'Op hoeveel exemplaren mag je werk gereproduceerd worden?',
        },
        share_how: {
          options: {
            '0': 'Publiceren in ...',
            '1': 'Ter beschikking via ...',
            '2': 'Opnemen in een boek, catalogus, ...',
            '3': 'Als promomateriaal en voor commerciële doeleinden.',
          },
          other_placeholder: 'Specificeer',
          question: 'Op welke manier mag je werk meegedeeld worden?',
        },
        start: {
          description: 'Volg onderstaande wizard om je eigen custom algemene voorwaarden samen te stellen.',
          ok: 'Ok, start de wizard',
          title: 'Maak je custom algemene voorwaarden',
        },
        thanks: {
          close: 'Sluit de wizard',
          explanation:
            "Maak je factuur aan en selecteer de naam van je custom AV's onderaan het keuzemenu 'afspraak auteursrechten'. Je AV's op maat zijn automatisch opgenomen in de bijlage die we samen met onze factuur aan je klant sturen onder artikel 8 (8.1 tot en met 8.3). Ook in het veld bijzondere voorwaarden op onze factuur aan je klant wordt er verwezen naar custom AV's.",
          question:
            'Je custom algemene voorwaarden worden nu voor je gemaakt. Je kan ze binnenkort bekijken en gebruiken bij de aanmaak van je factuur!',
          subtitle: 'Hoe ga ik aan de slag?',
        },
        transfer_copyright: {
          question: 'Wil je je auteursrechten op je werk overdragen aan je klant?',
        },
        transfer_copyright_no: {
          question: 'Ok top, je dient dan aan te geven wat er met je werk mag gebeuren.',
        },
        transfer_copyright_yes: {
          question: "Ok, ideaal! Kies dan voor 'overdracht' bij het aanmaken van een offerte of factuur.",
        },
        use_how: {
          multiple: 'meerdere opties mogelijk',
          options: {
            '0': 'Reproducties maken',
            '1': 'Publiekelijk meedelen',
            '2': 'Bewerken/wijzigen',
          },
          question: 'Wat mag je klant doen met je werk?',
        },
        use_how_long: {
          options: {
            '0': 'Volledige duurtijd',
            '1': 'Andere periode',
          },
          other_placeholder: 'Specifieer de periode',
          question: 'Hoe lang mag je klant je werk gebruiken?',
        },
        use_where: {
          options: {
            '0': 'België',
            '1': 'Europa',
            '2': 'De hele wereld',
            '3': 'Andere',
          },
          other_placeholder: 'Bijvoorbeeld Benelux',
          question: 'Waar mag je klant je werk gebruiken?',
        },
      },
    },
    description: 'Wijzig de lay-out en de werking van jouw Craft-platform.',
    email: {
      bcc: {
        description:
          "Dit houdt in dat je in bcc staat bij alle e-mails die we naar je klanten versturen, van nieuwe offertes, facturen en creditnota's tot betalingsherinneringen van facturen.",
        title: 'Zet mij in bcc bij alle e-mails naar mijn klanten',
      },
      creationReminder: {
        description: 'Stuur geen factuur te laat dankzij deze reminder.',
        options: {
          '0': 'Geen herinnering',
          '1': 'Op de laatste dag van de maand',
          '2': '1 week voor het einde van de maand',
          '3': '2 weken voor het einde van de maand',
        },
        title: 'Herinnering voor het aanmaken van facturen',
      },
      description: 'Kies hier welke e-mails en notificaties je wilt ontvangen',
      followUpEnabled: {
        description: 'Ontvang een melding als er een update is over de betalingsopvolging van een vervallen factuur',
        title: 'Update betalingsopvolgingen',
      },
      invitationReminder: {
        description:
          'Krijg een reminder als de collega-freelancer die je uitnodigde, op zijn of haar beurt een herinnering krijgt om zich te registeren.',
        title: 'Herinnering over verstuurde uitnodigingen',
      },
      nettoSaved: {
        description:
          'Krijg een overzicht van wat je maandelijks bespaart door Creative Shelter te gebruiken. Zo zie je zwart op wit wat je extra verdient door je auteursrechten te verzilveren.',
        title: 'Maandelijkse e-mail over nettobesparingen',
      },
      title: 'E-mails & notificaties',
    },
    numberingSeries: {
      add: 'Nieuwe nummeringreeks',
      delete: {
        description: 'Ben je zeker dat je "{{numberingSeries}}" wil verwijderen?',
        title: 'Verwijderen',
      },
      description:
        'Configuratie voor automatische nummering van facturen en creditnota\'s. Vragen, meer info vind je <a href="https://knowledge.creativeshelter.be/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in" target="_blank">hier</a>.',
      edit: {
        format: 'Formaat/opties wijzigen',
        name: 'Naam/type wijzigen',
      },
      headers: {
        name: 'Naam',
        nextNumber: 'Volgend nummer',
        type: 'Type',
      },
      title: 'Nummeringreeksen',
    },
    theme: {
      description: 'Kies het thema van je Craft platform',
      title: 'Platform thema',
    },
    themeInvoice: {
      colorTheme: {
        helpText: 'Kies een kleurencombinatie voor de documenten naar je klanten.',
        subtitle: 'Kleurenthema',
      },
      description: 'Kies het thema van de facturen voor je klant.',
      logo: {
        helpText: 'Upload hier het logo van jouw eenmanszaak.',
        subtitle: 'Logo',
      },
      title: 'Factuurthema',
    },
    title: 'Instellingen',
  },
  integrations: {
    accountant: {
      current: 'Onderstaande accountant is toegevoegd aan je account:',
      description:
        'Door je accountant toe te voegen, krijgt die toegang tot jouw facturen en documenten via <a href=https://api.creativeshelter.be/accountants/login target="_blank">het Credit-platform</a> van Creative Shelter.',
      pair: 'Toevoegen',
      requested: 'Onderstaande accountant heeft toegang gevraagd tot jouw account:',
      title: 'Geef je accountant toegang',
      unpair: 'Verwijderen',
    },
    description: 'Koppel hier je Creative Shelter account met externe services.',
    externalSystems: {
      description:
        'Door de koppeling met een extern systeem breid je de functionaliteit van je account uit met die van het externe systeem.',
      pairWith: 'Koppelen met {{ system }}',
      paired: 'Gekoppeld',
      systems: {
        billit: {
          description:
            'Maak je naast creatieve facturen ook wel eens niet-creatieve facturen aan? Deze integratie maakt het mogelijk om die niet-creatieve facturen eenvoudig aan te maken en te koppelen aan je Creative Shelter account.',
          name: 'Billit',
        },
        cashaca: {
          description: 'Cashaca vereenvoudigt boekhouding. Dit is ideaal voor zelfstandigen met een omzet van minder dan € 500.000.',
          modal: {
            apiToken: 'Cashaca API code',
            description:
              'Geef hier je persoonlijke Cashaca API code in.<br/>Heb je nog geen Cashaca account? <a href="https://app.cashaca.be/gebruikers/registreer?utm_source=creativeshelter" target="_blank">Registreer je dan hier</a>.',
          },
          name: 'Cashaca',
        },
      },
      title: 'Koppelen aan externe systemen',
    },
    forwarding: {
      deleteModal: {
        confirm: 'Ja, ik ben zeker.',
        description: "Ben je zeker dat je jouw facturen en creditnota's niet langer wilt doorsturen naar {{email}}?",
        title: 'Stoppen met automatisch doorsturen',
      },
      description: "Stuur jouw facturen en creditnota's automatisch door naar jouw accountant of boekhoudpakket.",
      title: 'Automatisch doorsturen',
    },
    pair: 'Koppelen',
    processing: 'We halen je data op',
    title: 'Boekhouding & integraties',
  },
  invitations: {
    description: 'Bekijk je uitnodigingen en volg ze op.',
    discountsCard: {
      active: 'Actief',
      commissionSaved: '<strong>{{amount}}</strong> bespaard',
      discount: '{{percentage}} korting',
      endedAt: 'Geëindigd op {{endDate}}',
      expired: 'Verlopen',
      invite: 'Nodig iemand uit!',
      noDiscounts: 'Je ontving (voorlopig) nog geen beloningen.',
      startedAt: 'Gestart op {{startDate}}',
      title: 'Beloningen',
    },
    intro: {
      disclaimer:
        '* Deze korting is één jaar geldig en gaat in zodra er een factuur wordt verstuurd door je collega-freelancer, binnen de 30 dagen na het aanmaken van een account. Dit geldt ook voor de persoon die je uitgenodigd hebt, het Premium-plan aan een Classic-prijs, deze wordt dan automatisch geactiveerd.',
      paragraphs: [
        {
          body: 'Je bent fan en je maakt wel eens reclame voor ons. Da’s top, en dat willen we belonen. Niet door je in de bloemetjes te zetten - ook al gunnen we jou dat - maar door je een korting te geven*.',
          subtitle: 'Nodig iemand uit om via ons te factureren!',
        },
        {
          body: 'Dat is 25% op je huidige vergoeding. Een vierde dus, dat je gewoon van ons cadeau krijgt. En hoe meer mensen je uitnodigt, hoe meer korting jij krijgt! Dus, nodig jij 4 nieuwe gebruikers uit die factureren? Dan betaal jij helemaal niets meer, en factureer jij HELEMAAL GRATIS.',
          subtitle: 'En hoe werkt dat dan, die korting?',
        },
        {
          body: 'We leggen jou in de watten, maar verwelkomen je collega-freelancer met open armen! Die krijgt een jaar lang het Premium-plan aan een Classic-prijs. Zo genieten jullie beiden extra hard het komende jaar.',
          subtitle: 'Krijgt de uitgenodigde dan ook iets?',
        },
      ],
      title: 'Zin in korting, en om nog meer te verdienen?',
    },
    invitationsCard: {
      noInvitations: 'Je hebt nog geen uitnodigingen verstuurd.',
      noMoreInvitations: 'Je hebt geen lopende uitnodigingen meer.',
      status: {
        accepted: 'Geregistreerd',
        declined: 'Geweigerd',
        pending: 'In afwachting',
      },
      title: 'Uitnodigingen',
    },
    invite: {
      error: {
        userAlreadyExists: 'De gebruiker die je wilt uitnodigen, factureert al via het Craft-platform.',
      },
      success: 'Je uitnodiging is succesvol verzonden en is onderweg naar {{ name }}.',
      title: 'Nodig iemand uit',
    },
    steps: {
      copied: 'Gekopieerd',
      sendInvitationButton: 'Verdien 25% korting',
      stepOne: 'Je nodigt iemand uit met je persoonlijke code, die in aanmerking komt.',
      stepThree: 'Proficiat, tijd voor 25% korting! Een jaar lang. 365 dagen besparen.',
      stepTwo: 'Je collega-freelancer maakt een account aan én factureert binnen de 30 dagen.',
      title: 'Hoe krijg je 25% korting?',
    },
    title: 'Uitnodigingen',
  },
  nps: {
    sendFeedback: 'Feedback versturen',
    titleStepOne: 'In welke mate zou je Creative Shelter aanbevelen bij andere freelancers?',
    titleStepThree: 'Bedankt! We gaan met je feedback aan de slag!',
    titleStepTwo: 'Wat zouden we nog kunnen verbeteren aan onze dienstverlening?',
  },
  paymentPlan: {
    changePlanModal: {
      confirm: 'Wijzig plan',
      descriptionDowngrade:
        "Ben je zeker dat je jouw {{currentPlan}} wilt verlagen naar {{newPlan}}? Twijfel je nog over de verschillende plannen en vergoedingen, neem dan zeker contact met ons op via <a href='mailto:service@creativeshelter.be'>service@creativeshelter.be</a>, we helpen je graag verder.",
      descriptionUpgrade: 'Zin in meer? Upgrade jouw {{currentPlan}} naar {{newPlan}}!',
      title: 'Plan wijzigen',
    },
    commission: {
      currentCommission: 'Huidige vergoeding',
      description: {
        creative:
          '<a href="https://knowledge.creativeshelter.be/een-creatieve-prestatie-wat-houdt-dit-in" target="_blank">Creatieve prestaties</a> aan {{percentage}}%',
        creativeFree:
          '<a href="https://knowledge.creativeshelter.be/een-creatieve-prestatie-wat-houdt-dit-in" target="_blank">Creatieve prestaties</a> gratis, woo-hoo!',
        nonCreative:
          '<a href="https://knowledge.creativeshelter.be/wat-factureer-ik-als-geen-creatieve-prestatie" target="_blank">Niet-creatieve prestaties</a> aan {{percentage}}%',
        nonCreativeFree:
          '<a href="https://knowledge.creativeshelter.be/wat-factureer-ik-als-geen-creatieve-prestatie" target="_blank">Niet-creatieve prestaties</a> gratis, woo-hoo!',
        subcontractingFree:
          '<a href="https://knowledge.creativeshelter.be/onderaanneming-wat-is-dat-precies" target="_blank">Onderaanneming</a> gratis',
      },
      details: {
        baseCommission: 'Standaardvergoeding',
        bracket: {
          '1': 'Eerste schijf',
          '2': 'Tweede schijf',
          '3': 'Derde schijf',
        },
        invite: 'Zin in meer korting? Nodig iemand uit!',
        referral: 'Referral korting (-{{amount}}%)',
        toggle: 'Details',
        total: 'Nieuwe vergoeding',
      },
      disclaimer: {
        adminOverride: 'Je vergoeding wijkt af van het standaardtarief, het staffelprincipe is niet van toepassing voor jou.',
        adminOverrideFixed: 'Je vergoeding wijkt af van het standaardtarief, het staffelprincipe is niet van toepassing voor jou.',
        copyrightLimitExceeded:
          'Je hebt jouw limiet van auteursrechten voor dit jaar overschreden. Omdat al jouw prestaties nu beroepsinkomsten zijn, mag je gratis factureren tot het einde van het jaar.',
      },
      planName: {
        '4': 'Early adopter',
        '5': 'Classic-plan',
        '6': 'Premium-plan',
      },
    },
    earlyAdopter: {
      description: 'Als Early Adopter geniet je van alle features van ons Premium-plan, aan de voordeligste prijs.',
      features: [
        {
          text: 'Onmiddellijke uitbetaling auteursvergoeding',
        },
        {
          text: 'Keuzemogelijkheid betalingstermijn',
        },
        {
          text: 'Opvolging onbetaalde facturen t.e.m. deurwaarder',
        },
        {
          text: 'Algemene voorwaarden op maat',
        },
        {
          text: 'Koppeling met externe tools',
          tooltip: 'Billit en/of cashaca',
        },
      ],
      title: 'Tariefplan',
    },
    freeInvitationCTA: {
      text_one: 'Jouw promo is nog {{count}} dag actief. Je factureert dus vandaag nog als allerlaatste dag helemaal gratis.',
      text_other: 'Jouw promo is nog {{count}} dagen actief. Je factureert dus helemaal gratis.',
    },
    header: {
      description: 'Hier check je je huidige vergoeding en betalingsplan.',
      title: 'Tariefplan',
    },
    invitationCTA: {
      link: 'Klik hier om een uitnodiging te versturen.',
      text: 'Krijg 25% korting op je vergoeding voor iedere collega-freelancer die je uitnodigt!',
    },
    picker: {
      commissionText: 'per creatieve prestatie',
      contact: 'Contacteer ons',
      current: 'Huidig plan',
      disclaimer: 'Alle tarieven zijn exclusief korting',
      features: {
        '5': [
          {
            text: 'Toegang tot het Craft-platform',
          },
          {
            text: '<a href="https://knowledge.creativeshelter.be/wat-als-mijn-klant-opdrachtgever-nog-niet-betaald-heeft-op-de-vervaldag" target="_blank">Automatische herinneringsmails</a> voor onbetaalde facturen',
            tooltip: 'Verzonden 2 en 8 dagen na vervaldatum van je factuur',
          },
          {
            text: 'Onmiddellijke uitbetaling van je factuur zodra je klant heeft betaald',
            tooltip: 'Zowel je beroepsinkomsten als je auteursvergoeding',
          },
          {
            text: 'Je facturen <a href="https://knowledge.creativeshelter.be/kan-ik-mijn-facturen-automatisch-doorsturen-naar-mijn-accountant" target="_blank">automatisch doorsturen</a> naar je accountant/boekhoudpakket',
            tooltip: 'Als pdf- of xml-bestand',
          },
          {
            text: 'Aangifte roerende voorheffing en opstellen <a href="https://knowledge.creativeshelter.be/wat-is-de-fiscale-fiche-281.45-1" target="_blank">jaarlijks verplicht fiscale fiche 281.45</a>',
          },
          {
            text: 'Gratis rechtsbijstand en professionele BA-verzekering',
            tooltip:
              'Meer daarover lees je <a href="https://knowledge.creativeshelter.be/een-ba-verzekering-bescherm-jezelf-extra-dankzij-creative-shelter" target="_blank">hier</a>!',
          },
        ],
        '6': [
          {
            text: 'Alle voordelen van het Classic‑plan',
          },
          {
            text: '<a href="https://knowledge.creativeshelter.be/wat-als-mijn-klant-opdrachtgever-nog-niet-betaald-heeft-op-de-vervaldag" target="_blank">Extra opvolging onbetaalde facturen</a> tot en met deurwaarder',
            tooltip: 'Deurwaarder wordt enkel aangesteld binnen België',
          },
          {
            link: '/settings/general#custom-conditions',
            text: 'Algemene voorwaarden op maat',
          },
          {
            text: 'Koppeling met externe tools',
            tooltip: 'Billit en/of Cashaca',
          },
          {
            text: 'Gratis Billit-licentie',
            tooltip: 'Als je nog geen betalende licentie hebt',
          },
          {
            text: 'Priority support',
          },
        ],
        label: 'Voor dit plan krijg je:',
      },
      hideDetails: 'Verberg details',
      select: 'Kies dit plan',
      showDetails: 'Toon details',
      subtitle: {
        '5': 'Voor wie de basis genoeg is',
        '6': 'Voor wie de beste service wilt',
      },
    },
    plans: {
      sectionDescription: 'Een overzicht van de verschillende tariefplannen en de bijhorende voordelen en vergoedingen.',
      sectionTitle: 'Tariefplannen',
    },
    progress: {
      disclaimer: 'Je teller wordt jaarlijks gereset op {{startDate}}. De vergoeding hierboven is zonder eventuele kortingen.',
      title: 'Totaal gefactureerd',
      tooltip: "Bij dit bedrag wordt er geen rekening gehouden met de prestatie 'onderaanneming' als je die factureert.",
      zeroProgress: 'Voorlopig nog niets, dus hup, factureren maar zodat je je teller kan vullen!',
    },
    requestPlanChangeModal: {
      cancel: 'Plan behouden',
      confirmationDescription: 'We nemen zo snel mogelijk contact met je op om jouw wijziging van plan te bekijken.',
      confirmationDismiss: 'Begrepen!',
      confirmationTitle: 'Aanvraag verstuurd',
      description:
        'Je maakt momenteel gebruik van het {{currentPlan}} aan een afwijkend (voordeliger) tarief. Als je jouw plan wijzigt, zal dit voordeel verloren gaan. Ben je toch zeker dat je jouw plan wilt veranderen naar {{newPlan}}? Vraag dan een wijziging aan, zodat wij dit verder met jou kunnen bekijken.',
      requestChange: 'Wijziging aanvragen',
      title: 'Plan wijzigen',
    },
    tags: {
      PremiumForClassic: 'Premium aan Classic',
      PremiumForFree: '{{daysValid}} dagen gratis',
      copyrightLimitExceeded: 'Aangepaste vergoeding',
      customPercentage: 'Aangepaste vergoeding',
      discount: 'Referral korting',
      mostPopular: 'Meest gekozen',
    },
  },
  profile: {
    activities: {
      description: 'Duid hier aan in welke creatieve sector jij actief bent.',
      modal: {
        description:
          'Kijk je even na of jouw activiteiten nog correct zijn? Je kunt deze instellingen hierna nog terugvinden onder jouw profiel.',
        next: 'Volgende',
        previous: 'Vorige',
        title: 'Activiteiten bevestigen',
      },
      title: 'Activiteiten',
      warnings: {
        stylingAndInterior: {
          body: 'Vanaf 2024 komen niet alle creatieve styling/interieur prestaties nog in aanmerking voor een auteursvergoeding. De interpretatie van de wet is strenger dan de wet zelf. Twijfel je of je prestatie in aanmerking komt? <a href="mailto:administratie@creativeshelter.be?subject=Komen mijn prestaties voor styling/interieur nog in aanmerking?" target="_blank">Mail ons</a>, en we pikken het verder met je op. Voor andere creatieve activiteiten kom je nog steeds in aanmerking.',
          title: 'Belangrijk! Stylist/interieur-vormgever, lees je even verder?',
        },
      },
    },
    brands: {
      add: 'Handelsnaam toevoegen',
      delete: {
        description: 'Ben je zeker dat je "{{brand}}" wil verwijderen?',
        title: 'Handelsnaam verwijderen',
      },
      description: 'Bekijk, creëer en bewerk je handelsnamen',
      edit: 'Handelsnaam aanpassen',
      favorite: {
        markAsFavorite: 'Aanduiden als favoriet',
        removeFavorite: 'Verwijderen als favoriet',
      },
      headers: {
        color: 'Handelsnaam kleur',
        email: 'E-mailadres',
        logo: 'Logo',
        name: 'De naam van je handelsnaam',
      },
      logoAdd: 'Voeg je logo toe',
      logoEdit: 'Bewerk je logo',
      mainBrand: 'Hoofdhandelsnaam',
      title: 'Handelsnamen',
    },
    community: {
      blocker: 'Ben je zeker dat de pagina wilt verlaten? De aanpassingen aan je Community profiel zijn nog niet opgeslagen.',
      communityHidden:
        'De toegang tot de Craft Community werd voor jou uitgeschakeld. Voor meer info, mag je ons altijd <a href="mailto:admin@creativeshelter.be?subject= Geblokkeerde toegang Craft Community" target="_blank">een e-mail sturen</a>.',
      description: 'Pas hier je Community-profiel aan',
      disableCommunityProfile: 'Verwijder je Community profiel',
      enableCommunityProfile: 'Maak je profiel openbaar in de Community',
      title: 'Community',
    },
    description: 'Wijzig de instellingen van je Creative Shelter-account',
    financial: {
      bank: 'Naam bank',
      bic: 'BIC/SWIFT',
      description: 'Je bank- en financiële gegevens',
      edit: 'Aanpassen',
      iban: 'IBAN',
      title: 'Financiële gegevens',
    },
    info: {
      language: {
        en: 'Engels',
        fr: 'Frans',
        nl: 'Nederlands',
      },
      refreshKboInformation: {
        action: 'Gegevens refreshen',
        lastUpdatedAt: 'Laatst geüpdatet {{datetime}}',
        tooltip:
          'Je adres halen we op uit de <kboLink>Kruispuntbank van Ondernemingen</kboLink>. Is je adres gewijzigd? Dan kan je hier de nieuwste gegevens ophalen. (Enkel mogelijk indien de gegevens ouder zijn dan 7 dagen).',
      },
      subsections: {
        address: 'Adresgegevens',
        personal: 'Persoonlijke informatie',
      },
      title: 'Profielinformatie',
    },
    password: {
      edit: 'Wachtwoord wijzigen',
      title: 'Wachtwoord veranderen',
    },
    title: 'Profiel',
    vatRegulation: {
      description: 'Hieronder vind je wat extra informatie over jouw huidige btw-status.',
      edit: 'Aanpassen',
      help: 'Duid aan welke btw-status je gebruikt. Dit bepaalt of er btw zal aangerekend worden op jouw facturen. Kleine ondernemingen met een omzet onder € 25.000 kunnen de btw-vrijstellingsregeling volgen, dit kan je zelf aanvragen. Meer informatie vind je <a href="https://knowledge.creativeshelter.be/wat-is-de-btw-vrijstellingsregeling-en-bestaan-er-nog-andere-regelingen" target=”_blank”>hier</a>.',
      info: {
        none: 'Je hebt jouw btw-status nog niet aangeduid.',
        vatExempt:
          'Je maakt gebruik van de <strong>btw-vrijstellingsregeling</strong>. Facturen tussen jou en Creative Shelter worden opgemaakt zonder btw. Volg hieronder op hoe ver je van de limiet zit.',
        vatNormal:
          'Je maakt gebruik van de <strong>gewone btw-status</strong>. Facturen tussen jou en Creative Shelter worden opgemaakt met btw.',
      },
      title: 'Btw-status',
      toggle: {
        vatExempt: 'Btw-vrijgesteld',
        vatNormal: 'Btw-plichtig',
      },
      tooltip: 'Als je omzet hoger is dan € 25.000, mag je geen gebruik meer maken van de btw-vrijstellingsregeling.',
      totalRevenueInYear: 'Totale omzet {{ year }}',
    },
  },
};
