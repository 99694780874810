import type { Placement } from '@floating-ui/react';

import {
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMemo, useRef, useState } from 'react';

type Options = {
  disableSafePolygon?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  open?: boolean;
  placement?: Placement;
};

export const useTooltip = ({
  disableSafePolygon = false,
  onOpenChange: setControlledOpen,
  open: controlledOpen,
  placement = 'top',
}: Options) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const arrowRef = useRef<HTMLDivElement>(null);

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(Math.sqrt(2 * 12 ** 2) / 2), // Half of the hypotenuse length of the arrow box
      flip({ fallbackAxisSideDirection: 'start', crossAxis: placement.includes('-') }),
      shift({ padding: 8 }),
      arrow({ element: arrowRef, padding: 4 }),
    ],
  });

  const context = data.context;

  const hover = useHover(context, { move: false, handleClose: disableSafePolygon ? null : safePolygon() });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(() => ({ open, setOpen, ...interactions, ...data, arrowRef }), [open, setOpen, interactions, data]);
};
