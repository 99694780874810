import { infiniteQueryOptions, useInfiniteQuery } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';
import { qs } from '~/utils/searchParams';

import type { CommunityProfileFilters } from './types';

import { paginatedApiResourceSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';
import { communityProfileSchema } from './schemas';

const communityProfilesQueryOptions = (filters: CommunityProfileFilters) =>
  infiniteQueryOptions({
    queryKey: QueryKeys.community(filters),
    queryFn: async ({ pageParam }) => {
      const token = getAccessToken();

      const queryString = qs({
        page: pageParam,
        name: filters.name || undefined,
        activityIds: filters.activityIds,
        openToWork: filters.openToWork ? '1' : undefined,
        regions: filters.regions,
      });

      const response = await request(`/community${queryString}`, { token });

      return paginatedApiResourceSchema(communityProfileSchema.array()).parse(response);
    },
    getNextPageParam: (lastPage) => lastPage.pagination.nextPage,
    initialPageParam: 1,
    staleTime: 30 * 1000,
    gcTime: 2 * 60 * 1000,
  });

export const useCommunityProfiles = (filters: CommunityProfileFilters) => {
  return useInfiniteQuery(communityProfilesQueryOptions(filters));
};
