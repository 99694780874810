import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { Button } from '~/components';
import { Icon } from '~/components/SVG';
import { AnchorTargets, SearchParamModals } from '~/constants/url';
import { useModal } from '~/hooks/useModal';
import { useShouldOpenModal } from '~/hooks/useShouldOpenModal';
import { parsePhoneNumber } from '~/utils/libphonenumberjs';
import { formatVatNumber } from '~/utils/string';

import profileStyles from '../Profile.module.scss';
import { PasswordModal } from './PasswordModal/PasswordModal';
import styles from './ProfileInfo.module.scss';
import { ProfileInfoModal } from './ProfileInfoModal/ProfileInfoModal';

export const ProfileInfo = () => {
  const { data: user } = useUser();

  const { t } = useTranslation(['common', 'settings']);

  const profileInfoModal = useModal(useShouldOpenModal(SearchParamModals.PROFILE_INFO));
  const passwordModal = useModal(useShouldOpenModal(SearchParamModals.CHANGE_PASSWORD));

  return (
    <>
      <section className={styles['profile-info']} id={AnchorTargets.PROFILE_INFO}>
        <div className={styles['info-square']}>
          <div className={styles.heading}>
            <strong>
              {user.firstName} {user.lastName}
            </strong>
            <span>{user.companyName}</span>
            <span>{formatVatNumber(user.vatID)}</span>
          </div>

          <div className={styles['contact-info']}>
            <span>
              <Icon name="Home" />
              {`${user.address.street} ${user.address.number}${user.address.annex ?? ''}${
                user.address.box ? `, ${user.address.box}` : ''
              } – ${user.address.zipcode} ${user.address.city}`}
            </span>

            <span>
              <Icon name="Translate" />
              {t(`settings:profile.info.language.${user.lang}`)}
            </span>

            <span>
              <Icon name="Mail" />
              {user.email}
            </span>

            <span>
              <Icon name="BarChart" />
              {t(`settings:fields.mainOccupation.options.${user.mainOccupation}`)}
            </span>

            {user.phone && (
              <span>
                <Icon name="Call" />
                {parsePhoneNumber(user.phone)?.format('INTERNATIONAL') || user.phone}
              </span>
            )}
          </div>
        </div>

        <div className={profileStyles['button-group']}>
          <Button icon="Password" onClick={passwordModal.open} type="secondary">
            {t('settings:profile.password.edit')}
          </Button>

          <Button icon="Edit" onClick={profileInfoModal.open}>
            {t('settings:edit')}
          </Button>
        </div>
      </section>

      {profileInfoModal.isOpen && <ProfileInfoModal onClose={profileInfoModal.close} />}
      {passwordModal.isOpen && <PasswordModal onClose={passwordModal.close} />}
    </>
  );
};
