import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { quotationSchema } from './schemas';
import { quotationsQueryOptions } from './useQuotations';

export const useDuplicateQuotation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      const token = getAccessToken();
      const response = await request(`/quotations/${id}/duplicate`, { method: 'POST', token });

      const { data: quotation } = apiResourceSchema(quotationSchema).parse(response);

      return quotation;
    },
    onSuccess: (quotation) => {
      queryClient.setQueryData(quotationsQueryOptions.queryKey, (quotations) => [quotation, ...(quotations ?? [])]);
      queryClient.invalidateQueries(quotationsQueryOptions);
    },
  });
};
