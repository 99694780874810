import * as stylex from '@stylexjs/stylex';

import { radius, spacing, statusColors, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  alert: {
    padding: spacing.xsmall,
    display: 'flex',
    alignItems: 'start',
    gap: spacing.xsmall,
    borderRadius: radius.xxxsmall,
    borderWidth: 1,
  },

  success: {
    color: statusColors.successDark,
    borderColor: statusColors.successDark,
  },

  info: {
    color: statusColors.infoDark,
    borderColor: statusColors.infoDark,
  },

  warning: {
    color: statusColors.warningDark,
    borderColor: statusColors.warningDark,
  },

  error: {
    color: statusColors.errorDark,
    borderColor: statusColors.errorDark,
  },

  iconWrapper: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  contentWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.xxxsmall,
  },

  closeButton: {
    width: 20,
    height: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: radius.xxxsmall,
    outlineStyle: {
      default: 'none',
      ':is([data-focus])': 'solid',
    },
    outlineWidth: '1px',
    outlineColor: systemColors.outline,
  },
});
