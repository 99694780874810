import { Menu, MenuButton, MenuHeading, MenuItem, MenuItems, MenuSection } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { Icon } from '~/components/SVG';

import { styles } from './CommandMenu.styles';
import { useCommandMenuContext } from './CommandMenuProvider.context';

const CommandMenu = () => {
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const { menuItems } = useCommandMenuContext();

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if ((e.key === 'k' || e.key === 'K') && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        menuButtonRef.current?.click();
      }
    };

    document.addEventListener('keydown', onKeydown);
    return () => document.removeEventListener('keydown', onKeydown);
  }, []);

  const menuGroups = Object.entries(Object.groupBy(menuItems, ({ group }) => group));

  if (menuGroups.length === 0) return null;

  return createPortal(
    <Menu>
      <MenuButton {...stylex.props(styles.button)} ref={menuButtonRef}>
        <Icon name="DataObject" size={16} />
      </MenuButton>

      <MenuItems {...stylex.props(styles.items)} anchor={{ to: 'bottom start', gap: 4 }} modal={false}>
        {menuGroups.map(([group, menuItems]) => (
          <MenuSection {...stylex.props(styles.section)} key={group}>
            <MenuHeading {...stylex.props(styles.header)}>
              <span {...stylex.props(styles.headerText)}>{group}</span>
            </MenuHeading>
            {(menuItems ?? []).map((menuItem) => (
              <MenuItem key={menuItem.id} {...stylex.props(styles.item)}>
                <button {...stylex.props(styles.itemButton)} onClick={menuItem.onClick}>
                  <kbd {...stylex.props(styles.shortcut, !menuItem.shortcut && styles.shortcutHidden)}>{menuItem.shortcut}</kbd>
                  {menuItem.label}
                </button>
              </MenuItem>
            ))}
          </MenuSection>
        ))}
      </MenuItems>
    </Menu>,
    document.body,
  );
};

export default CommandMenu;
