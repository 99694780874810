import { z } from 'zod';

import { PlanId } from './constants';

const basePlanSchema = z.object({
  creativePercentages: z.object({
    1: z.number(),
    2: z.number(),
    3: z.number(),
  }),
  nonCreativePercentages: z.object({
    1: z.number(),
    2: z.number(),
    3: z.number(),
  }),
  revenueAmounts: z.object({
    1: z.number(),
    2: z.number(),
    3: z.number(),
  }),
});

export const classicPlanSchema = basePlanSchema.extend({
  id: z.literal(PlanId.classic),
  name: z.literal('classic'),
});

export const premiumPlanSchema = basePlanSchema.extend({
  id: z.literal(PlanId.premium),
  name: z.literal('premium'),
});

export const earlyAdopterPlanSchema = z.object({
  id: z.literal(PlanId.earlyAdopter),
  name: z.literal('early_adopter'),
  creativePercentages: z.object({
    1: z.number(),
  }),
  nonCreativePercentages: z.object({
    1: z.number(),
  }),
  revenueAmounts: z.object({
    1: z.number(),
  }),
});

export const freePlanSchema = basePlanSchema.extend({
  id: z.literal(PlanId.free),
  name: z.literal('free'),
});

export const availablePlanSchema = z.union([classicPlanSchema, premiumPlanSchema]);
export const planSchema = z.union([classicPlanSchema, premiumPlanSchema, earlyAdopterPlanSchema, freePlanSchema]);
