import { useTranslation } from 'react-i18next';

import { Button } from '~/components/UI';

import { useRegistrationContext } from '../Registration.context';

export const RegistrationAddReferralCodeButton = () => {
  const { referralCodeInformation, setShowReferralCodeForm, showReferralCodeForm } = useRegistrationContext();

  const { t } = useTranslation(['auth']);

  if (referralCodeInformation !== null || showReferralCodeForm) return null;

  return (
    <Button onClick={() => setShowReferralCodeForm(true)} size="narrow" variant="secondary">
      {t('auth:registrationPage.common.referralCode.addButton')}
    </Button>
  );
};
