import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import * as stylex from '@stylexjs/stylex';
import { useEffect, useId } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { match, Pattern } from 'ts-pattern';

import type { Language } from '~/types/app';

import { useRegister } from '~/api/auth';
import { FetchError, HttpError, TooManyRequestsError } from '~/api/errors';
import {
  Field,
  Form,
  FormCheckbox,
  FormField,
  FormListbox,
  FormTextarea,
  FormValidationError,
  Label,
  ListboxOption,
  ValidationError,
} from '~/components/Form';
import { Redirect } from '~/components/Functional';
import { Alert, AlertDescription, AlertTitle, Button, ButtonGroup, ExternalLink, Flex, IconTooltip, Text } from '~/components/UI';
import { AmplitudeEventName, AmplitudeUserProperty } from '~/constants/amplitude';
import { useActiveColorTheme } from '~/hooks/useAppColorTheme';
import { routes } from '~/providers/RouterProvider/router.routes';
import { Activity, MainOccupation } from '~/types/user';
import { parsePhoneNumber } from '~/utils/libphonenumberjs';

import type { BusinessInformationFormFields } from './RegistrationBusinessInformation.types';

import { AuthLayoutContent } from '../AuthLayout/AuthLayoutContent';
import { AuthLayoutTitleGroup } from '../AuthLayout/AuthLayoutTitleGroup';
import { PreviousStepButton } from './PreviousStepButton';
import { useRegistrationContext } from './Registration.context';
import { styles } from './RegistrationBusinessInformation.styles';
import { availableActivities, availablePrimaryActivities } from './RegistrationBusinessInformation.utils';
import { RegistrationAddReferralCodeButton } from './RegistrationReferralCode/RegistrationAddReferralCodeButton';
import { RegistrationReferralCode } from './RegistrationReferralCode/RegistrationReferralCode';
import { checkAccountInformationStepCompleted, checkVatInformationStepCompleted } from './utils';

export const RegistrationBusinessInformation = () => {
  const mutation = useRegister();

  const { accountInformation, businessInformation, referralCodeInformation, setBusinessInformation, setUser, vatInformation } =
    useRegistrationContext();

  const { control, handleSubmit, setValue, watch } = useForm<BusinessInformationFormFields>({
    defaultValues: businessInformation,
  });
  const formId = useId();

  const watchPrimaryActivity = watch('primaryActivity');
  const availableSecondaryActivities = availableActivities.filter((activity) => activity !== watchPrimaryActivity);
  const isOtherActivitySelected = watch(`secondaryActivities.a${Activity.OTHER}`);
  const isItDevelopmentActivitySelected =
    watch('primaryActivity') === Activity.IT_DEVELOPMENT || watch(`secondaryActivities.a${Activity.IT_DEVELOPMENT}`);

  const colorTheme = useActiveColorTheme();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(['auth', 'common', 'validation']);

  // Uncheck the secondary activity if it has been selected as primary activity
  useEffect(() => {
    if (watchPrimaryActivity) setValue(`secondaryActivities.a${watchPrimaryActivity}`, false);
  }, [watchPrimaryActivity, setValue]);

  // Clear the other information when the other activity isn't checked
  useEffect(() => {
    if (!isOtherActivitySelected) setValue('otherActivityInformation', '');
  }, [isOtherActivitySelected, setValue]);

  if (!checkVatInformationStepCompleted(vatInformation)) return <Redirect to={routes.register} />;
  if (!checkAccountInformationStepCompleted(accountInformation)) return <Redirect to={routes.registerAccountInformation} />;

  const onSubmit = handleSubmit((data) => {
    setBusinessInformation(data);

    // These fields can't be null after successful validation.
    invariant(data.mainOccupation !== null, 'Expected main occupation to be defined');
    invariant(data.primaryActivity !== null, 'Expected primary activity to be defined');

    mutation.mutate(
      {
        vatNumber: vatInformation.vatNumber,
        emailAddress: accountInformation.emailAddress,
        password: accountInformation.password,
        phoneNumber: match(accountInformation.callingCode)
          .with(
            '32',
            () =>
              parsePhoneNumber(`+${accountInformation.callingCode}${accountInformation.phoneNumber}`)?.format('E.164') ??
              `+${accountInformation.callingCode}${accountInformation.phoneNumber}`,
          )
          .with('31', () => `+${accountInformation.callingCode}${accountInformation.phoneNumber}`)
          .with(Pattern.any, () => accountInformation.phoneNumber)
          .exhaustive(),
        mainOccupation: data.mainOccupation,
        primaryActivity: data.primaryActivity,
        secondaryActivities: Object.entries(data.secondaryActivities)
          .filter(([, isSelected]) => isSelected)
          .map(([activity]) => +activity.slice(1)), // Remove the `a` prefix
        otherActivityInformation: data.otherActivityInformation,
        language: i18n.language as Language,
        referralCode: referralCodeInformation?.code ?? null,
        colorCombo: colorTheme,
      },
      {
        onSuccess: (user) => {
          setUser(user);

          Sentry.setUser({
            id: user.id,
            username: `${user.firstName} ${user.lastName}`,
            email: user.email,
            ip_address: '{{auto}}',
          });

          const amplitudeIdentifyEvent = new amplitude.Identify();
          amplitudeIdentifyEvent.set(AmplitudeUserProperty.MainOccupation, user.mainOccupation === MainOccupation.YES);
          amplitude.identify(amplitudeIdentifyEvent);
          amplitude.track(AmplitudeEventName.RegistrationStep3Completed);

          navigate(routes.registerSuccess);
        },
      },
    );
  });

  return (
    <AuthLayoutContent>
      <AuthLayoutTitleGroup
        pill={t('auth:registrationPage.common.header.pill')}
        subtitle={t('auth:registrationPage.stepBusinessInformation.header.subtitle')}
        title={t('auth:registrationPage.stepBusinessInformation.header.title', {
          name: vatInformation?.founder?.firstName ?? vatInformation?.companyName ?? '',
        })}
      />

      <Flex autoAnimate direction="column" gap="normal">
        <Form id={formId} onSubmit={onSubmit}>
          <Flex autoAnimate direction="column" gap="normal">
            <FormField control={control} name="mainOccupation" rules={{ validate: (value) => value !== null }}>
              <Label>{t('auth:registrationPage.stepBusinessInformation.form.mainOccupationField.label')}</Label>
              <FormListbox autoFocus placeholder={t('common:placeholders.select')}>
                {/*eslint-disable-next-line @eslint-react/prefer-shorthand-boolean */}
                <ListboxOption value={true}>
                  {t('auth:registrationPage.stepBusinessInformation.form.mainOccupationField.options.mainOccupation')}
                </ListboxOption>
                <ListboxOption value={false}>
                  {t('auth:registrationPage.stepBusinessInformation.form.mainOccupationField.options.secondaryOccupation')}
                </ListboxOption>
              </FormListbox>
              <FormValidationError validate={t('validation:required')} />
            </FormField>

            <FormField control={control} name="primaryActivity" rules={{ required: true }}>
              <Label>{t('auth:registrationPage.stepBusinessInformation.form.primaryActivityField.label')}</Label>
              <FormListbox placeholder={t('common:placeholders.select')}>
                {availablePrimaryActivities.map((activity) => (
                  <ListboxOption key={activity} value={activity}>
                    {({ selectedOption }) => (
                      <Flex direction="column">
                        <Text weight={!selectedOption ? 'medium' : 'regular'}>{t(`common:activities.${activity}.label`)}</Text>
                        {!selectedOption && <Text size="small">{t(`common:activities.${activity}.description`)}</Text>}
                      </Flex>
                    )}
                  </ListboxOption>
                ))}
              </FormListbox>
              <FormValidationError required={t('validation:required')} />
            </FormField>

            {isItDevelopmentActivitySelected && (
              <Alert variant="warning">
                <AlertTitle>{t('auth:registrationPage.stepBusinessInformation.alertItDevelopers.title')}</AlertTitle>
                <AlertDescription>{t('auth:registrationPage.stepBusinessInformation.alertItDevelopers.description')}</AlertDescription>
              </Alert>
            )}

            {watchPrimaryActivity !== null && (
              <Field>
                <Label optional passive>
                  {t('auth:registrationPage.stepBusinessInformation.form.secondaryActivityField.label')}
                </Label>

                <div {...stylex.props(styles.secondaryActivities)}>
                  {availableSecondaryActivities.map((activity) => (
                    <FormField control={control} key={activity} name={`secondaryActivities.a${activity}`}>
                      <Label styles={styles.secondaryActivityLabel}>
                        <Flex alignItems="center" gap="xxsmall">
                          <FormCheckbox />
                          {t(`common:activities.${activity}.label`)}
                          {activity !== Activity.OTHER && (
                            <span onClick={(e) => e.stopPropagation()}>
                              <IconTooltip name="InfoOutlined">{t(`common:activities.${activity}.description`)}</IconTooltip>
                            </span>
                          )}
                        </Flex>
                      </Label>
                    </FormField>
                  ))}
                </div>
              </Field>
            )}

            {isOtherActivitySelected && (
              <FormField control={control} name="otherActivityInformation" rules={{ required: true }}>
                <Label>{t('auth:registrationPage.stepBusinessInformation.form.otherActivitiesField.label')}</Label>
                <FormTextarea />
                <FormValidationError required={t('validation:required')} />
              </FormField>
            )}
          </Flex>
        </Form>

        <RegistrationReferralCode />

        {mutation.error && (
          <ValidationError>
            {match(mutation.error)
              .with(Pattern.instanceOf(TooManyRequestsError), () => t('common:errors.tooManyRequests'))
              .with(Pattern.instanceOf(HttpError), () => (
                <Trans components={{ email: <ExternalLink /> }} i18nKey="auth:registrationPage.stepBusinessInformation.errors.httpError" />
              ))
              .with(Pattern.instanceOf(FetchError), () => t('common:errors.fetchError'))
              .otherwise(() => t('auth:registrationPage.stepBusinessInformation.errors.fallback'))}
          </ValidationError>
        )}

        <Flex direction="column" gap="small">
          <ButtonGroup wrapReverse>
            <Button form={formId} loading={mutation.isPending} type="submit">
              {t('auth:registrationPage.stepBusinessInformation.form.submitButton')}
            </Button>

            <RegistrationAddReferralCodeButton />
          </ButtonGroup>

          <PreviousStepButton to="accountInformation" />
        </Flex>
      </Flex>
    </AuthLayoutContent>
  );
};
