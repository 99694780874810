import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import type { Address, OrganisationClient } from '~/api/clients/types';

import { Button, CountryFlag, IconTooltip } from '~/components';
import { useIntl } from '~/hooks/useIntl';
import { formatVatNumber } from '~/utils/string';

export const OrganisationInfoCard = ({
  onEdit,
  organisation,
  tooltip,
}: {
  onEdit?: () => void;
  organisation: {
    address: Omit<Address, 'type'> | null;
    companyName: OrganisationClient['name'];
    otherNumber: string | null;
    vatNumber: string | null;
  };
  tooltip?: string;
}) => {
  const { displayNameOfCountry } = useIntl();
  const { t } = useTranslation(['clients']);

  return (
    <article className="relative p-6 border border-gray-300 flex gap-6 max-sm:flex-col [&_span]:text-ellipsis [&_span]:overflow-hidden">
      <div className="flex-[2] flex flex-col gap-y-1">
        <span className={classNames('font-semibold', onEdit && 'max-sm:mr-6')}>{organisation.companyName}</span>
        {organisation.vatNumber && <span>{formatVatNumber(organisation.vatNumber)}</span>}
        {organisation.otherNumber && <span>{organisation.otherNumber}</span>}
      </div>

      {organisation.address && (
        <div className="flex-[3] flex flex-col gap-y-1">
          <span className={classNames('font-semibold', onEdit && 'sm:mr-6')}>{t('clients:addressType.headquarters')}</span>
          <span>
            {organisation.address.street} {organisation.address.number}
          </span>
          <span>
            {organisation.address.zipCode} {organisation.address.city}
          </span>
          <span className="flex gap-x-2 items-baseline">
            <span className="relative bottom-0.5 shrink-0">
              <CountryFlag countryCode={organisation.address.countryCode} />
            </span>
            <span className="text-ellipsis overflow-hidden">{displayNameOfCountry(organisation.address.countryCode)}</span>
          </span>
        </div>
      )}

      {onEdit && (
        <div className="absolute top-3 right-3 leading-none">
          <Button icon="Edit" onClick={onEdit} type="iconOnly" />
        </div>
      )}

      {tooltip && (
        <div className="absolute top-3 right-3 leading-none">
          <IconTooltip size={24} text={tooltip} />
        </div>
      )}
    </article>
  );
};
