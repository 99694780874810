import classNames from 'classnames';

import { AnimateExpand } from '~/components';

import type { FiltersProps as Props } from './types';

import { FilterGroup } from './FilterGroup';
import { FilterLabel } from './FilterLabel';
import styles from './Filters.module.scss';

export const Filters = ({ children, show }: Props) => {
  return (
    <div className={classNames(styles.Wrapper, show && styles.Show)}>
      <AnimateExpand>{show && <div className={styles.Filters}>{children}</div>}</AnimateExpand>
    </div>
  );
};

Filters.Group = FilterGroup;
Filters.Label = FilterLabel;
