import type { HTMLAttributes, ReactNode } from 'react';

import { useAutoAnimate } from '@formkit/auto-animate/react';
import * as stylex from '@stylexjs/stylex';

import type { WithoutStyleProps } from '../../types';

import { alignItemsStyles, flexDirectionStyles, flexWrapStyles, gapStyles, justifyContentStyles, styles } from './Flex.styles';

type Props = WithoutStyleProps<HTMLAttributes<HTMLDivElement>> & {
  alignItems?: 'stretch' | 'start' | 'center' | 'end' | 'baseline';
  autoAnimate?: boolean;
  children: ReactNode;
  direction?: 'row' | 'column';
  gap?:
    | 'none'
    | 'pixel'
    | 'tiny'
    | 'xxxsmall'
    | 'xxsmall'
    | 'xsmall'
    | 'small'
    | 'normal'
    | 'medium'
    | 'large'
    | 'xlarge'
    | 'xxlarge'
    | 'xxxlarge';
  inline?: boolean;
  justifyContent?: 'stretch' | 'start' | 'center' | 'end' | 'spaceBetween' | 'spaceAround' | 'spaceEvenly';
  styles?: stylex.StyleXStyles;
  wrap?: boolean | 'wrap' | 'reverse';
};

export const Flex = ({
  alignItems = 'stretch',
  autoAnimate = false,
  children,
  direction = 'row',
  gap = 'none',
  inline = false,
  justifyContent = 'stretch',
  styles: styleOverrides,
  wrap = false,
  ...delegated
}: Props) => {
  const [ref] = useAutoAnimate();

  const Component = inline ? 'span' : 'div';

  return (
    <Component
      {...stylex.props(
        styles.base,
        inline && styles.inline,
        wrap && flexWrapStyles[wrap === true ? 'wrap' : wrap],
        flexDirectionStyles[direction],
        gapStyles[gap],
        justifyContentStyles[justifyContent],
        alignItemsStyles[alignItems],
        styleOverrides,
      )}
      {...delegated}
      ref={autoAnimate ? ref : undefined}
    >
      {children}
    </Component>
  );
};
