import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { InvoiceForwardingSetting } from '~/types/user';

import { useUpdateInvoiceForwardingSettings, useUser } from '~/api/user';
import { Button, FormControl, FormError, FormSelect, Spinner } from '~/components';
import { Alert, AlertTitle } from '~/components/UI';
import { EMAIL } from '~/constants/regex';
import { useModalWithData } from '~/hooks/useModal';

import type { AutoForwardingFormType, AutoForwardingFormProps as Props } from './types';

import styles from './AutoForwarding.module.scss';
import { DeleteAutoForwardingModal } from './DeleteAutoForwardingModal';

export const AutoForwardingForm = (props: Props) => {
  const { t } = useTranslation(['common']);

  const { enableForm, invoiceForwardingSettings, isEnabled } = props;

  if (!invoiceForwardingSettings && !isEnabled) {
    return (
      <div>
        <Button icon="Add" onClick={() => enableForm('create')} type="tertiary">
          {t('common:add')}
        </Button>
      </div>
    );
  }

  return <InnerAutoForwardingForm {...props} />;
};

const InnerAutoForwardingForm = ({ disableForm, enableForm, invoiceForwardingSettings, isEnabled }: Props) => {
  const { data: user } = useUser();
  const mutation = useUpdateInvoiceForwardingSettings();

  const { t } = useTranslation(['common', 'settings', 'validation']);

  const isEdit = !!invoiceForwardingSettings;
  const deleteModal = useModalWithData<InvoiceForwardingSetting>();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<AutoForwardingFormType>({
    defaultValues: {
      receiverEmail: isEdit ? invoiceForwardingSettings.receiverEmail : '',
      frequency: isEdit ? invoiceForwardingSettings.frequency : 'instant',
      platformEmail: isEdit ? invoiceForwardingSettings.platformEmail : '',
    },
  });

  const watchReceiverEmail = watch('receiverEmail').toLowerCase();
  const isAccountable = ['revenue@accountable.eu', 'inkomsten@accountable.eu'].includes(watchReceiverEmail);
  const isBilltobox = watchReceiverEmail.includes('billtobox');

  const onSubmit = handleSubmit((data) => {
    mutation.mutate(
      {
        entries: isEdit
          ? user.invoiceForwardingSettings.map((entry) => (entry.id === invoiceForwardingSettings.id ? data : entry))
          : [...user.invoiceForwardingSettings, data],
      },
      {
        onSuccess: () => {
          toast.success(t('settings:alerts.integrations.successForwardingEmailLinked'));
          reset();
          disableForm();
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  });

  return (
    <>
      <form className={classNames(styles.Row, user.invoiceForwardingSettings.length === 0 && styles.OnlyRow)} onSubmit={onSubmit}>
        {/* Receiver email */}
        <div className={styles.ReceiverEmail}>
          <label className={styles.MobileLabel} htmlFor="receiverEmail">
            {t('settings:fields.email.label')}
          </label>

          <input
            {...register('receiverEmail', { required: true, maxLength: 255, pattern: EMAIL })}
            autoComplete="off"
            className={classNames(styles.ReceiverEmail, errors.receiverEmail && 'is-invalid')}
            disabled={!isEnabled}
            placeholder={t('settings:fields.email.placeholder')}
          />

          {errors.receiverEmail && (
            <FormError>
              {errors.receiverEmail.type === 'required' && t('validation:required')}
              {errors.receiverEmail.type === 'pattern' && t('validation:email.invalid')}
              {errors.receiverEmail.type === 'maxLength' &&
                t('validation:maxLength', { attribute: t('settings:fields.email.label'), max: 255 })}
            </FormError>
          )}
        </div>

        {/* Frequency */}
        <div className={styles.Frequency}>
          <FormControl control={control} name="frequency">
            <FormSelect
              disabled={!isEnabled}
              options={(['instant', 'monthly', 'quarterly'] as const).map((frequency) => ({
                value: frequency,
                label: t(`settings:fields.forwarding.frequency.options.${frequency}`),
              }))}
            />
          </FormControl>

          {errors.frequency && <FormError>{errors.frequency.type === 'required' && t('validation:required')}</FormError>}
        </div>

        {isEdit && !isEnabled ? (
          <div className={styles.Actions}>
            <Button icon="Edit" onClick={() => enableForm(invoiceForwardingSettings.id)} type="iconOnly" />
            <Button
              icon="Delete"
              onClick={() => {
                disableForm();
                deleteModal.open(invoiceForwardingSettings);
              }}
              type="iconOnly"
            />
          </div>
        ) : (
          <div className={styles.ActionsPlaceholder} />
        )}

        {/* Platform email (Accountable) */}
        {isAccountable && (
          <>
            <div className={styles.PlatformEmail}>
              <p className={styles.PlatformEmailLabel}>{t('settings:fields.forwarding.platformEmail.label')}</p>

              <div className={styles.PlatformEmailInput}>
                <input
                  {...register('platformEmail', { required: true, maxLength: 255, pattern: EMAIL })}
                  autoComplete="off"
                  className={classNames(errors.platformEmail && 'is-invalid')}
                  disabled={!isEnabled}
                />

                {errors.platformEmail && (
                  <FormError>
                    {errors.platformEmail.type === 'required' && t('validation:required')}
                    {errors.platformEmail.type === 'pattern' && t('validation:email.invalid')}
                    {errors.platformEmail.type === 'maxLength' &&
                      t('validation:maxLength', { attribute: t('settings:fields.email.label'), max: 255 })}
                  </FormError>
                )}
              </div>
            </div>

            <div className={styles.ActionsPlaceholder} />
          </>
        )}

        {isEnabled && isBilltobox && (
          <>
            <div className="col-span-2">
              <Alert variant="info">
                <AlertTitle>{t('settings:fields.forwarding.alerts.billtobox')}</AlertTitle>
              </Alert>
            </div>
            <div />
          </>
        )}

        {isEnabled && (
          <div className={styles.FormActions}>
            <Button disabled={mutation.isPending} isSubmit>
              {mutation.isPending && <Spinner size={24} />}
              <span>{t('common:save')}</span>
            </Button>

            {user.invoiceForwardingSettings.length > 0 && (
              <Button
                onClick={() => {
                  reset();
                  disableForm();
                }}
                type="tertiary"
              >
                {t('common:cancel')}
              </Button>
            )}
          </div>
        )}
      </form>

      {deleteModal.isOpen && (
        <DeleteAutoForwardingModal
          invoiceForwardingSetting={deleteModal.data}
          onClose={() => {
            deleteModal.close();
            // TODO: fix bug where create form opens after cancelling deletion
            if (user.invoiceForwardingSettings.length === 1) enableForm('create');
          }}
        />
      )}
    </>
  );
};
