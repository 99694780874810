import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomCondition } from '~/api/conditions/types';

import { ColumnIds } from '~/constants/table';

import { CustomConditionsTableOptions } from './CustomConditionsTableOptions';

const columnHelper = createColumnHelper<CustomCondition>();

export const useCustomConditionsTableColumns = () => {
  const { t } = useTranslation(['settings']);

  return useMemo(
    () => [
      // Name
      columnHelper.accessor('name', {
        id: ColumnIds.customConditionsName,
        header: t('settings:general.customConditions.table.name'),
        cell: ({ getValue }) => <div className="lg:truncate">{getValue()}</div>,
        meta: {
          styles: { size: '1fr', minSize: 150 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: customCondition } }) => <CustomConditionsTableOptions customCondition={customCondition} />,
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [t],
  );
};
