import classNames from 'classnames';

import { Icon } from '~/components/SVG';

import type { FormErrorProps as Props } from './types';

export const FormError = ({ children, isWarning = false, white = false }: Props) => {
  return (
    <p className={classNames('ml-4 mt-2 mb-3 space-x-1.5 text-error-500', isWarning && 'text-warning-500', white && 'text-white')}>
      <Icon inline name="Info" size={20} />
      <span>{children}</span>
    </p>
  );
};
