import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const PaperPlane3 = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="Referral 1"
      viewBox="865.06 168.28 79.02 55.91"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <g>
        <polygon
          {...stylex.props(styles.stroke, styles.fillWhite)}
          points="872.43 185.74 865.06 204.86 881.27 206.88 886.94 207.59 922.27 212 885.54 192.65 872.43 185.74"
        ></polygon>
        <polygon
          {...stylex.props(styles.stroke, styles.fillWhite)}
          points="892.43 168.28 884.44 180.94 944.08 214.72 892.43 168.28"
        ></polygon>
        <polygon
          {...stylex.props(styles.stroke, styles.fillSecondary)}
          points="881.27 206.88 888.5 224.19 931.5 216.86 944.08 214.72 922.27 212 886.94 207.59 881.27 206.88"
        ></polygon>
        <polygon
          {...stylex.props(styles.stroke, styles.fillPrimary)}
          points="884.44 180.94 885.54 192.65 922.27 212 944.08 214.72 884.44 180.94"
        ></polygon>
      </g>
    </svg>
  );
};
