import { useOutletContext } from 'react-router-dom';
import invariant from 'tiny-invariant';

import type { CreateOrganisationClientPayload, VatInformation } from '~/api/clients/types';
import type { DeepPartial } from '~/types/utils';

import type { ContactFormType } from '../types';

export type CreateClientContext = {
  // Organisation for non-private clients
  organisation: CreateOrganisationClientPayload['client'] | null;
  setOrganisation: (organisation: CreateOrganisationClientPayload['client'] | null) => void;

  // Addresses from VAT information for Belgian organisation
  establishmentUnits: VatInformation['establishmentUnits'];
  setEstablishmentUnits: (addresses: VatInformation['establishmentUnits']) => void;

  // Autosave of contact form data
  contactFormData: DeepPartial<ContactFormType> | null;
  setContactFormData: (data: DeepPartial<ContactFormType>) => void;

  isLoading: boolean;
  onCreate: (contact: CreateOrganisationClientPayload['contact']) => void;

  resetClientContext: () => void;
};

export const useCreateClientContext = () => {
  const context = useOutletContext<CreateClientContext>();

  invariant(context, 'useCreateClientContext can only be called inside `/clients/create` routes');

  return context;
};
