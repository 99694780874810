import { motion } from 'framer-motion';

import { springTransition } from '~/utils/motion';

import type { NavigationBackdropProps as Props } from './types';

import styles from './NavigationBackdrop.module.scss';

export const NavigationBackdrop = ({ isShown, onClick }: Props) => {
  return (
    <motion.div
      animate={isShown ? 'visible' : 'hidden'}
      className={styles.Backdrop}
      initial={false}
      onClick={onClick}
      variants={{
        visible: {
          opacity: 0.6,
          display: 'block',
          transition: springTransition,
        },
        hidden: {
          opacity: 0,
          display: 'none',
          transition: {
            opacity: springTransition,
            display: { delay: 0.3 },
          },
        },
      }}
    />
  );
};
