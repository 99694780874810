import type { HTMLProps } from 'react';
import type { Control } from 'react-hook-form';

import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormErrorMessage, FormLabel, FormTextInput } from '~/components';

type FormWithCompanyName = { companyName: string };

export const FormCompanyName = <T extends FormWithCompanyName>({
  control,
  ...props
}: HTMLProps<HTMLDivElement> & {
  control: T extends FormWithCompanyName ? Control<T> : never;
}) => {
  const { t } = useTranslation(['clients', 'validation']);

  return (
    <div {...props}>
      <FormControl as={Fragment} control={control} name="companyName" rules={{ required: true }}>
        <FormLabel>{t('clients:fields.companyName.label')}</FormLabel>
        <FormTextInput />
        <FormErrorMessage required={t('validation:required')} />
      </FormControl>
    </div>
  );
};
