import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Client } from '~/api/clients/types';

import { ColumnIds } from '~/constants/table';
import { useIntl } from '~/hooks/useIntl';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { formatVatNumber } from '~/utils/string';

import { ClientsTableOptions } from './ClientsTableOptions';

const columnHelper = createColumnHelper<Client>();

export const useClientsTableColumns = () => {
  const { t } = useTranslation(['clients']);

  return useMemo(
    () => [
      // Name
      columnHelper.accessor('name', {
        id: ColumnIds.clientsName,
        header: t('clients:overview.columns.name'),
        cell: ({ getValue }) => <div className="lg:truncate">{getValue()}</div>,
        meta: {
          styles: { size: '5fr', minSize: 150 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Client type
      columnHelper.accessor(({ clientType }) => t(`clients:type.${clientType}`), {
        id: ColumnIds.clientsType,
        header: t('clients:overview.columns.type'),
        cell: ({ getValue }) => <div className="lg:truncate">{getValue()}</div>,
        meta: {
          styles: { size: '3fr', minSize: 100 },
          isResizable: true,
        },
      }),

      // Vat number or other number
      columnHelper.accessor(({ otherNumber, vatNumber }) => (vatNumber ? formatVatNumber(vatNumber) : otherNumber || undefined), {
        id: ColumnIds.clientsVat,
        header: t('clients:overview.columns.vatOrOtherNumber'),
        cell: ({ getValue }) => <span className="lg:truncate">{getValue() || '–'}</span>,
        sortUndefined: 'last',
        meta: {
          styles: { size: '4fr', minSize: 150 },
          mobileStyles: { size: 'auto', justify: 'end', minBreakpoint: 'sm' },
          isResizable: true,
        },
      }),

      // Contacts
      columnHelper.accessor(
        ({ clientType, contacts }) =>
          clientType === 'organisation'
            ? contacts
                .filter(({ deleted }) => !deleted)
                .map(({ fullName }) => fullName)
                .join(' ')
            : undefined,
        {
          id: ColumnIds.clientsContacts,
          header: t('clients:overview.columns.contacts'),
          cell: ({ row: { original: client } }) => {
            if (client.clientType === 'private') return '–';

            const existingContacts = client.contacts.filter(({ deleted }) => !deleted);

            return (
              <ul className="lg:overflow-hidden">
                {existingContacts.slice(0, existingContacts.length > 3 ? 2 : 3).map((contact) => (
                  <li className="lg:truncate" key={contact.id} onClick={(e) => e.stopPropagation()}>
                    <Link
                      className="text-dark-gray hover:text-dark-gray hover:underline"
                      to={r(routes.editContact, { clientId: client.id, contactId: contact.id })}
                    >
                      {contact.fullName}
                    </Link>
                  </li>
                ))}
                {existingContacts.length > 3 && (
                  <li className="text-medium-gray italic lg:truncate">
                    {t('clients:overview.moreContacts', { amount: existingContacts.length - 2 })}
                  </li>
                )}
              </ul>
            );
          },
          sortUndefined: 'last',
          meta: {
            styles: { size: '4fr', minSize: 150 },
            isResizable: true,
          },
        },
      ),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: client } }) => <ClientsTableOptions client={client} />,
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [t],
  );
};

export const useClientsTableFilterOptions = (clients: Client[]) => {
  const { t } = useTranslation(['clients']);
  const { compareForSort } = useIntl();

  const nameOptions = useMemo(
    () => [...new Set(clients.map((client) => client.name))].sort(compareForSort).map((name) => ({ label: name, value: name })),
    [clients, compareForSort],
  );

  const clientTypeOptions = useMemo(
    () =>
      (['organisation', 'private'] as const).map((clientType) => {
        const clientTypeText = t(`clients:type.${clientType}`);
        return { label: clientTypeText, value: clientTypeText };
      }),
    [t],
  );

  return useMemo(
    () => ({
      names: nameOptions,
      clientTypes: clientTypeOptions,
    }),
    [nameOptions, clientTypeOptions],
  );
};
