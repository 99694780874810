import { Icon } from '~/components/SVG';

import type { WarningProps as Props } from './types';

import styles from './Warning.module.scss';

export const Warning = ({ children, title }: Props) => {
  return (
    <article className={styles.Wrapper}>
      <header className={styles.Header}>
        <Icon name="Warning" size={18} />
        <span>{title}</span>
      </header>

      <p className={styles.Body}>{children}</p>
    </article>
  );
};
