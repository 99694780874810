import { Trans, useTranslation } from 'react-i18next';

import { CollapsibleSection, PageHeader } from '~/components';
import { AnchorTargets } from '~/constants/url';

import { AccountantForm } from './AccountantForm/AccountantForm';
import { AutoForwarding } from './AutoForwardingForm/AutoForwarding';
import { ExternalSystems } from './ExternalSystems/ExternalSystems';

export const Integrations = () => {
  const { t } = useTranslation(['settings']);

  return (
    <>
      <PageHeader description={t('settings:integrations.description')} title={t('settings:integrations.title')} />

      {/* Forward to accountant of accountancy software */}
      <CollapsibleSection
        description={t('settings:integrations.forwarding.description')}
        id={AnchorTargets.INTEGRATIONS_AUTO_FORWARDING}
        title={t('settings:integrations.forwarding.title')}
      >
        <AutoForwarding />
      </CollapsibleSection>

      {/* Connect with accountant (Credit) */}
      <CollapsibleSection
        description={<Trans components={{ a: <a /> }} i18nKey="settings:integrations.accountant.description" />}
        id={AnchorTargets.INTEGRATIONS_ACCOUNTANT}
        title={t('settings:integrations.accountant.title')}
      >
        <AccountantForm />
      </CollapsibleSection>

      {/* Link with external system */}
      <CollapsibleSection
        description={t('settings:integrations.externalSystems.description')}
        id={AnchorTargets.INTEGRATIONS_EXTERNAL_SERVICES}
        title={t('settings:integrations.externalSystems.title')}
      >
        <ExternalSystems />
      </CollapsibleSection>
    </>
  );
};
