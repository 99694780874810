import type { PluginFunc } from 'dayjs';

const workingWeekDays = [1, 2, 3, 4, 5];

// prettier-ignore
const holidays = [
  '2024-01-01', '2024-04-01', '2024-05-01', '2024-05-09', '2024-05-20', '2024-07-21', '2024-08-15', '2024-11-01', '2024-11-11', '2024-12-25',
  '2025-01-01', '2025-04-21', '2025-05-01', '2025-05-29', '2025-06-09', '2025-07-21', '2025-08-15', '2025-11-01', '2025-11-11', '2025-12-25',
  '2026-01-01', '2026-04-06', '2026-05-01', '2026-05-14', '2026-05-25', '2026-07-21', '2026-08-15', '2026-11-01', '2026-11-11', '2026-12-25',
  '2027-01-01', '2027-03-29', '2027-05-01', '2027-05-06', '2027-05-17', '2027-07-21', '2027-08-15', '2027-11-01', '2027-11-11', '2027-12-25',
  '2028-01-01', '2028-04-17', '2028-05-01', '2028-05-25', '2028-06-05', '2028-07-21', '2028-08-15', '2028-11-01', '2028-11-11', '2028-12-25',
  '2029-01-01', '2029-04-02', '2029-05-01', '2029-05-10', '2029-05-21', '2029-07-21', '2029-08-15', '2029-11-01', '2029-11-11', '2029-12-25',
  '2030-01-01', '2030-04-22', '2030-05-01', '2030-05-30', '2030-06-10', '2030-07-21', '2030-08-15', '2030-11-01', '2030-11-11', '2030-12-25',
];

export const businessDays: PluginFunc = (options, Dayjs) => {
  Dayjs.prototype.isBusinessDay = function () {
    return workingWeekDays.includes(this.day()) && !holidays.includes(this.format('YYYY-MM-DD'));
  };

  Dayjs.prototype.nextBusinessDay = function () {
    const nextDay = this.add(1, 'day');
    return nextDay.isBusinessDay() ? nextDay : nextDay.nextBusinessDay();
  };
};
