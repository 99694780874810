import type { UseFormReturn } from 'react-hook-form';

import { useTranslation } from 'react-i18next';

import { CollapsibleSection, FormControl, FormTextArea } from '~/components';

import type { ContactFormType } from '../types';

type ContactCommentsFormType = Pick<ContactFormType, 'comments'>;

export const ContactCommentsFormSection = <T extends ContactCommentsFormType>({
  form,
}: {
  form: T extends ContactCommentsFormType ? UseFormReturn<T> : never;
}) => {
  const { control, getValues } = form;

  const { t } = useTranslation(['clients', 'common', 'validation']);

  return (
    <CollapsibleSection
      description={t('clients:createEdit.subsections.comments.description')}
      initialCollapsed={!getValues('comments')}
      title={t('clients:createEdit.subsections.comments.title')}
    >
      <FormControl control={control} name="comments">
        <FormTextArea />
      </FormControl>
    </CollapsibleSection>
  );
};
