import { useTranslation } from 'react-i18next';

import type { YesNoInputProps as Props } from './types';

import { InputFieldRadio } from './InputFieldRadio';

export const YesNoInput = ({ answers, saveAndNext, step }: Props) => {
  // i18n
  const { t } = useTranslation(['settings']);

  return (
    <>
      <InputFieldRadio
        checked={answers[step] === true}
        onChange={() => saveAndNext(true)}
        onClick={() => saveAndNext(true)}
        step={step}
        value="yes"
      >
        {t('settings:general.customConditions.wizard.common_answers.yes')}
      </InputFieldRadio>

      <InputFieldRadio
        checked={answers[step] === false}
        onChange={() => saveAndNext(false)}
        onClick={() => saveAndNext(false)}
        step={step}
        value="no"
      >
        {t('settings:general.customConditions.wizard.common_answers.no')}
      </InputFieldRadio>
    </>
  );
};
