import { HexColorPicker } from 'react-colorful';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, FormControl, FormErrorMessage, FormField, FormHelpText, FormLabel, FormTextInput, Modal, UploadInput } from '~/components';
import { EMAIL } from '~/constants/regex';

import type { CreateEditBrandFormType, CreateEditBrandModalProps as Props } from './types';

export const CreateEditBrandModal = ({ brand, isLoading, onClose, onSubmit }: Props) => {
  const { control, handleSubmit, watch } = useForm<CreateEditBrandFormType>({
    defaultValues: {
      companyName: brand?.companyName ?? '',
      email: brand?.email ?? '',
      color: brand?.color ?? `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    },
  });
  const watchLogo = watch('logo');

  const { t } = useTranslation(['common', 'settings', 'validation']);

  return (
    <Modal onClose={onClose} variant="wide">
      <Modal.Title>{t(`settings:profile.brands.${brand ? 'edit' : 'add'}`)}</Modal.Title>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-grid-2">
          {/* Brand name */}
          <FormControl control={control} name="companyName" rules={{ required: true, maxLength: 255 }}>
            <FormLabel>{t('settings:fields.brandName.label')}</FormLabel>
            <FormTextInput placeholder={t('settings:fields.brandName.placeholder')} />
            <FormErrorMessage
              maxLength={t('validation:maxLength', { attribute: t('settings:fields.brandName.label'), max: 255 })}
              required={t('validation:required')}
            />
          </FormControl>

          {/* Email */}
          <FormControl control={control} name="email" rules={{ required: true, maxLength: 255, pattern: EMAIL }}>
            <FormLabel>{t('settings:fields.email.label')}</FormLabel>
            <FormTextInput placeholder={t('settings:fields.email.placeholder')} />
            <FormErrorMessage
              maxLength={t('validation:maxLength', { attribute: t('settings:fields.brandName.label'), max: 255 })}
              pattern={t('validation:email.invalid')}
              required={t('validation:required')}
            />
          </FormControl>

          {/* Color */}
          <FormControl control={control} name="color" rules={{ required: true }}>
            <FormLabel>{t('settings:fields.color.label')}</FormLabel>
            <FormField>
              {({ field, id }) => (
                <HexColorPicker color={field.value} id={id} onBlur={field.onBlur} onChange={(value) => field.onChange(value)} />
              )}
            </FormField>
            <FormErrorMessage required={t('validation:required')} />
          </FormControl>

          {/* Logo */}
          <FormControl control={control} name="logo">
            <FormLabel optional tooltip={t('settings:fields.logo.tooltip')}>
              {t('settings:fields.logo.label')}
            </FormLabel>
            <FormField>
              {({ field }) => (
                <UploadInput
                  fileTypes=".png, .jpg, .jpeg, .bmp"
                  onChange={(e) => e.currentTarget.files?.[0] && field.onChange(e.currentTarget.files[0])}
                />
              )}
            </FormField>
            <FormHelpText>{watchLogo?.name}</FormHelpText>
          </FormControl>
        </div>

        <Modal.Actions>
          <Button hasSpinner icon="Save" isLoading={isLoading} isSubmit>
            {t('common:save')}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  );
};
