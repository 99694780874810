import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UpdatePasswordPayload } from './types';

import { request } from '../request';

export const useUpdatePassword = () => {
  return useMutation({
    mutationFn: (body: UpdatePasswordPayload) => {
      const token = getAccessToken();
      return request('/user', { method: 'POST', token, body });
    },
  });
};
