import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { AddPaymentFollowUpRemarkPayload } from './types';

import { request } from '../request';
import { invoicesQueryOptions } from './useInvoices';

export const useAddPaymentFollowUpRemark = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: AddPaymentFollowUpRemarkPayload) => {
      const token = getAccessToken();
      return request(`/invoices/${id}/follow-up/add-remark`, { method: 'POST', token, body });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(invoicesQueryOptions);
    },
  });
};
