import { useTranslation } from 'react-i18next';

import { FormControl, FormErrorMessage, FormLabel, FormTextArea, FormTextInput } from '~/components';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';

import { useLineContext } from '../../SectionLines/context';

export const FormDescription = () => {
  const { control } = useInvoiceQuotationFormContext();
  const { isMobile, isProductLine, line, lineFieldName } = useLineContext();
  const isTextLine = line.type === 'text';

  const { t } = useTranslation(['lines', 'validation']);

  const label = isProductLine
    ? t('lines:description.placeholderProduct')
    : isTextLine
      ? t('lines:textLine')
      : t('lines:description.header');

  return (
    <FormControl
      control={control}
      name={`${lineFieldName}.description`}
      rules={{
        required: !isProductLine,
        validate: (value) => !!value.trim() || isProductLine,
        maxLength: 255,
      }}
    >
      {isMobile && !isTextLine && <FormLabel optional={isProductLine}>{label}</FormLabel>}

      {isProductLine || isMobile ? (
        <FormTextInput placeholder={isMobile ? undefined : t('lines:description.placeholderProduct')} />
      ) : (
        <FormTextArea placeholder={t('lines:description.placeholder')} />
      )}

      {isMobile && (
        <FormErrorMessage
          maxLength={t('validation:maxLength', { attribute: label, max: 255 })}
          required={t('validation:required')}
          validate={t('validation:required')}
        />
      )}
    </FormControl>
  );
};
