import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { NumberingSeries } from '~/types/user';

import { getAccessToken } from '~/utils/auth';

import type { UpdateNumberingSeriesPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useUpdateNumberingSeries = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateNumberingSeriesPayload) => {
      const token = getAccessToken();
      const response = await request(`/numbering-series/${id}`, { method: 'PATCH', token, body });

      // TODO: Schema validation
      const { data: numberingSeries } = apiResourceSchema(z.unknown()).parse(response);

      return numberingSeries as NumberingSeries;
    },
    onSuccess: (updatedNumberingSeries) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return {
          ...user,
          numberingSeries: user.numberingSeries.map((numberingSeries) =>
            numberingSeries.id === updatedNumberingSeries.id ? updatedNumberingSeries : numberingSeries,
          ),
        };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
