import classNames from 'classnames';
import IBAN from 'iban';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdateFinancialInfo, useUser } from '~/api/user';
import { Button, FormError, Modal, Spinner } from '~/components';

import type { FinancialInfoTabProps as Props } from './types';

export const FinancialInfoTab = ({ isLastTab, onCompleted }: Props) => {
  const { data: user } = useUser();
  const mutation = useUpdateFinancialInfo();

  const { t } = useTranslation(['settings', 'validation']);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<{ iban: string }>({
    defaultValues: {
      iban: '',
    },
  });

  const onSubmit = handleSubmit((data) => {
    mutation.mutate(
      {
        financial: {
          iban: data.iban,
          bank: '',
          bic: '',
        },
      },
      {
        onError: () => toast.error(t('settings:accountCompletionModal.financialInfo.error')),
      },
    );
  });

  return (
    <div className="space-y-4">
      <p>{t('settings:accountCompletionModal.financialInfo.description')}</p>

      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="iban">{t('settings:profile.financial.iban')}</label>

          <div className="flex flex-col sm:flex-row gap-4">
            <input
              type="text"
              {...register('iban', { required: true, validate: (value) => value && IBAN.isValid(value) })}
              className={classNames(errors.iban && 'is-invalid')}
              disabled={mutation.isSuccess}
              placeholder={t('settings:profile.financial.iban')}
            />

            <Button disabled={mutation.isPending || mutation.isSuccess} isSubmit>
              {mutation.isPending && <Spinner size={24} />}
              <span>{t('settings:accountCompletionModal.financialInfo.check')}</span>
            </Button>
          </div>

          {errors.iban && (
            <FormError>
              {errors.iban.type === 'required' && t('validation:required')}
              {errors.iban.type === 'validate' && t('validation:iban')}
            </FormError>
          )}
        </div>

        <div className="flex flex-col sm:flex-row gap-x-4">
          <div className="form-group flex-grow">
            <label htmlFor="bank">{t('settings:profile.financial.bank')}</label>
            <input disabled type="text" value={user.financial.bank ?? ''} />
          </div>

          <div className="form-group flex-grow">
            <label htmlFor="bic">{t('settings:profile.financial.bic')}</label>
            <input disabled type="text" value={user.financial.bic ?? ''} />
          </div>
        </div>

        <Modal.Actions>
          <Button disabled={!mutation.isSuccess} onClick={() => onCompleted()}>
            {t(`settings:accountCompletionModal.action.${isLastTab ? 'complete' : 'next'}`)}
          </Button>
        </Modal.Actions>
      </form>
    </div>
  );
};
