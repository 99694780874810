/**
 * Get an array of page indices that should be shown in the pagination.
 * The current page should be in the middle of five options if possible,
 * with the first and last page always visible.
 *
 * For example, on page 4 out of 9, the navigation options will be 1, 3, 4, 5, and 9.
 */
export const getPageNavigationOptions = (pageCount: number, currentPage: number) => {
  if (pageCount <= 5) {
    return Array.from({ length: pageCount }, (_, i) => i);
  }

  const lastPage = pageCount - 1;

  if (currentPage === 0 || currentPage === 1) {
    return [0, 1, 2, 3, lastPage];
  }

  if (currentPage === lastPage || currentPage === lastPage - 1) {
    return [0, lastPage - 3, lastPage - 2, lastPage - 1, lastPage];
  }

  return [0, currentPage - 1, currentPage, currentPage + 1, lastPage];
};
