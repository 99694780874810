import classNames from 'classnames';

import type { SpinnerProps as Props } from './types';

import styles from './Spinner.module.scss';

export const Spinner = ({ centered = false, size = 32 }: Props) => {
  return (
    <div className={classNames(styles.spinner, { 'mx-auto': centered })} style={{ width: size, height: size }}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
