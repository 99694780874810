import { Dialog } from '@headlessui/react';

import type { ModalContainerProps as Props } from './types';

import styles from './Modal.module.scss';

export const ModalContainer = ({ children, isOpen, onClose }: Props) => {
  return (
    <Dialog className={styles.modal} onClose={onClose} open={isOpen}>
      <div aria-hidden="true" className={styles.backdrop} />

      <div className={styles.scrollContainer}>
        <div className={styles.centerContainer}>{children}</div>
      </div>
    </Dialog>
  );
};
