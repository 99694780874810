import * as stylex from '@stylexjs/stylex';
import { Trans, useTranslation } from 'react-i18next';

import { Person1 } from '~/components/SVG/Illustration/referrals/Person1';
import { Person2 } from '~/components/SVG/Illustration/referrals/Person2';
import { Span, Text, Title } from '~/components/UI';

import { styles } from './InvitationBenefits.styles';

export const InvitationBenefits = () => {
  const { t } = useTranslation(['invitations']);

  return (
    <div {...stylex.props(styles.wrapper)}>
      <Title as="h4">{t('invitations:benefits.title')}</Title>

      <div {...stylex.props(styles.benefitCards)}>
        <div {...stylex.props(styles.benefitCard, styles.benefitCardInvitor)}>
          <div {...stylex.props(styles.illustrationWrapper, styles.illustrationInvitorWrapper)}>
            <Person1 styles={styles.illustrationInvitor} />
          </div>

          <Text size="large" weight="bold">
            {t('invitations:benefits.invitor.title')}
          </Text>

          <Text>
            <Trans components={{ bold: <Span weight="semiBold" /> }} i18nKey="invitations:benefits.invitor.benefit" />
          </Text>
        </div>

        <div {...stylex.props(styles.benefitCard, styles.benefitCardInvitee)}>
          <Text size="large" weight="bold">
            {t('invitations:benefits.invitee.title')}
          </Text>

          <Text>
            <Trans components={{ bold: <Span weight="semiBold" /> }} i18nKey="invitations:benefits.invitee.benefit" />
          </Text>

          <div {...stylex.props(styles.illustrationWrapper, styles.illustrationInviteeWrapper)}>
            <Person2 styles={styles.illustrationInvitee} />
          </div>
        </div>
      </div>

      <Text size="tiny" styles={styles.disclaimer}>
        {t('invitations:benefits.disclaimer')}
      </Text>
    </div>
  );
};
