import type { Client } from '~/api/clients/types';
import type { Invoice } from '~/types/invoice';

import { europeanUnionCountryCodes } from '~/constants/countries';

import { includes } from './arrays';

/**
 * Total revenue of all sent invoices in the given year
 */
export const getRevenueByYear = (invoices: Invoice[], year: number) => {
  return invoices
    .filter((invoice) => invoice.docYear === year && invoice.allowed && !invoice.credited)
    .reduce((grandTotal, invoice) => grandTotal + invoice.calculationData.fcExclVat, 0);
};

/**
 * Get the VAT regulation applicable to the client
 */
export const getVatRegime = (client: Client): 'applied' | 'shifted' | 'export' | 'exceptionallyExempt' => {
  const address =
    // Organisation clients have a headquarters address
    client.addresses.find(({ type }) => type === 'headquarters') ??
    // Private clients have one contact with an address
    client.contacts[0].address;

  // Client is outside EU
  if (!includes(europeanUnionCountryCodes, address.countryCode)) return 'export';

  // Client is a EU organisation outside Belgium with a valid vat number
  if (client.clientType === 'organisation' && address.countryCode !== 'BE' && client.vatValid && client.vatActive) return 'shifted';

  // Client has zero vat exception
  if (client.exceptionalZeroVat) return 'exceptionallyExempt';

  return 'applied';
};
