import { useScreenWidth } from '~/hooks/useScreenWidth';

export const useSlidesPerView = (carouselWidth: number | null) => {
  const screen = useScreenWidth();

  if (screen.isLargerThan('2xl') && carouselWidth) return Math.floor(carouselWidth / 325);
  if (screen.isLargerThan('2xl') && !carouselWidth) return 4;
  if (screen.isLargerThan('lg')) return 3;
  if (screen.isLargerThan('md')) return 2;
  return 1;
};
