import type { Dispatch } from 'react';
import type { UseFieldArrayReturn } from 'react-hook-form';

import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

import type { InvoiceQuotationFormSection, InvoiceQuotationFormType } from '~/types/invoiceQuotation';

export const LinesSectionContext = createContext<{
  isDefaultSection: boolean;
  linesFieldArray: UseFieldArrayReturn<InvoiceQuotationFormType, `sections.${number}.lines`>;
  mobileLineReducer: [string | null, Dispatch<{ lineIndex: number; sectionIndex: number } | null>];
  section: InvoiceQuotationFormSection;
  sectionIndex: number;
  sectionsFieldArray: UseFieldArrayReturn<InvoiceQuotationFormType, 'sections'>;
} | null>(null);

export const useSectionContext = () => {
  const instance = useContext(LinesSectionContext);
  invariant(instance, 'Section components must be wrapped in <Section />');

  return instance;
};
