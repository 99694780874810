import type { UseFormReturn } from 'react-hook-form';

import classNames from 'classnames';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { OrganisationAddress } from '~/api/clients/types';

import { CollapsibleSection, FormCheckbox, FormControl, FormLabel, FormSelect, FormTextInput } from '~/components';

import type { ContactFormType } from '../types';

import { FormAddressCity } from './FormAddressCity';
import { FormAddressNumber } from './FormAddressNumber';
import { FormAddressStreet } from './FormAddressStreet';
import { FormAddressZipCode } from './FormAddressZipCode';
import { FormCountryCode } from './FormCountryCode';

type ContactAddressFormType = Pick<ContactFormType, 'useOrganisationAddress' | 'addressIndex' | 'address' | 'organisationName'>;

export const ContactAddressFormSection = <T extends ContactAddressFormType>({
  addresses,
  form: { control },
  isPrivateClient = false,
}: {
  addresses: OrganisationAddress[];
  form: T extends ContactAddressFormType ? UseFormReturn<T> : never;
  isPrivateClient?: boolean;
}) => {
  const watchUseOrganisationAddress = useWatch({ control, name: 'useOrganisationAddress' });

  const { t } = useTranslation(['clients', 'validation']);

  return (
    <CollapsibleSection title={t('clients:createEdit.subsections.contactAddress')}>
      <div className="grid gap-4 grid-cols-6">
        {/* Toggle address type */}
        {addresses.length > 0 && (
          // 10px margin top to baseline align text with first address line in two-column layout
          <div className={classNames('lg:mt-2.5 col-span-full', watchUseOrganisationAddress && 'lg:col-span-3')}>
            <FormControl control={control} name="useOrganisationAddress">
              <FormCheckbox label={t('clients:fields.useOrganisationAddress.label')} />
            </FormControl>
          </div>
        )}

        {/* Name of informal association */}
        {isPrivateClient && (
          <div className="col-span-full">
            <FormControl control={control} name="organisationName">
              <FormLabel optional tooltip={t('clients:fields.organisationName.tooltip')}>
                {t('clients:fields.organisationName.label')}
              </FormLabel>
              <FormTextInput />
            </FormControl>
          </div>
        )}

        {watchUseOrganisationAddress ? (
          // Organisation address(es)
          <div className="col-span-full lg:col-span-3">
            {addresses.length === 1 ? (
              <div className="px-4 border border-gray-300 bg-light-gray">
                <DisplayAddress address={addresses[0]} />
              </div>
            ) : (
              <FormControl control={control} name="addressIndex">
                <FormSelect
                  options={addresses.map((address, i) => ({
                    value: i,
                    label: `${address.street} ${address.number}, ${address.zipCode} ${address.city}`,
                  }))}
                  OptionWrapper={({ value }) => <DisplayAddress address={addresses[value]} />}
                />
              </FormControl>
            )}
          </div>
        ) : (
          // Contact address
          <>
            <FormAddressStreet className="col-span-full lg:col-span-4" control={control} />
            <FormAddressNumber className="col-span-full lg:col-span-2" control={control} />
            <FormAddressZipCode className="col-span-full lg:col-span-2" control={control} />
            <FormAddressCity className="col-span-full lg:col-span-2" control={control} />
            <FormCountryCode className="col-span-full lg:col-span-2" control={control} />
          </>
        )}
      </div>
    </CollapsibleSection>
  );
};

const DisplayAddress = ({ address }: { address: OrganisationAddress }) => {
  const { t } = useTranslation(['clients']);

  return (
    // 7px vertical margin so the Select component will be 64px in height
    <div className="my-[7px] flex flex-col">
      <span className="font-medium">{t(`clients:addressType.${address.type}`)}</span>
      <span>
        {address.street} {address.number}, {address.zipCode} {address.city}
      </span>
    </div>
  );
};
