import { useWindowSize } from '@react-hook/window-size';
import { useLayoutEffect, useRef, useState } from 'react';

/**
 * Get a DOM element's DOMRect
 *
 * Recalculates the rectangle when window size or
 * any of the provided dependencies changes
 *
 * DOMRect state retains its last value when the element unmounts
 */
export const useBoundingClientRect = <T extends HTMLElement>(...deps: unknown[]) => {
  const ref = useRef<T>(null);
  const [rect, setRect] = useState(() => new DOMRect(0, 0, 0, 0));

  const windowSize = useWindowSize();

  useLayoutEffect(() => {
    if (ref.current) {
      setRect(ref.current.getBoundingClientRect());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize, ...deps]);

  return [rect, ref] as const;
};
