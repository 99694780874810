import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUploadLogo, useUser } from '~/api/user';
import { Spinner } from '~/components';
import { Icon } from '~/components/SVG';

export const LogoInput = () => {
  const { data: user } = useUser();
  const mutation = useUploadLogo();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [objectUrl, setObjectUrl] = useState<string | null>(null);

  const { t } = useTranslation(['settings']);

  const onLogoUpload = (logo: File) => {
    const data = new FormData();
    data.append('logoUpload', logo, logo.name);

    // Optimistically show new logo
    setObjectUrl(window.URL.createObjectURL(logo));

    mutation.mutate(data, {
      onError: () => {
        toast.error(t('settings:fields.logo.error.upload'));
        setObjectUrl(null);
      },
    });
  };

  const onLogoDelete = () => {
    const data = new FormData();
    data.append('logoUpload', '');

    mutation.mutate(data, {
      onSuccess: () => {
        setObjectUrl(null);
        if (fileInputRef.current) fileInputRef.current.value = '';
      },
      onError: () => {
        toast.error(t('settings:fields.logo.error.delete'));
      },
    });
  };

  const hasLogo = !!objectUrl || !!user.logo;

  return (
    <div className="w-fit px-4 relative">
      <button
        className={classNames(
          'flex justify-center items-center bg-primary-50 relative group',
          !hasLogo && 'rounded-full size-20',
          hasLogo && 'bg-transparent',
        )}
        onClick={() => fileInputRef.current?.click()}
      >
        {hasLogo && <img alt="Logo" className="min-w-28 h-28 object-contain" src={objectUrl ?? user.logo} />}
        <span
          className={classNames(
            'flex justify-center items-center text-primary-500',
            hasLogo && 'absolute inset-0 hidden group-hover:flex bg-white/50',
          )}
        >
          <Icon name="Upload" size={32} />
        </span>
      </button>

      <input
        accept="image/jpeg,image/png"
        hidden
        id="logo"
        onChange={(e) => e.target.files?.[0] && onLogoUpload(e.target.files[0])}
        ref={fileInputRef}
        type="file"
      />

      {hasLogo && (
        <button className="absolute bottom-0 -right-4 text-medium-gray hover:text-primary-500" onClick={() => onLogoDelete()}>
          {mutation.isPending ? <Spinner size={24} /> : <Icon name="Delete" />}
        </button>
      )}
    </div>
  );
};
