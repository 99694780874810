import classNames from 'classnames';
import { forwardRef } from 'react';

import { Icon } from '~/components/SVG';

import type { NumberInputProps as Props } from './types';

import styles from './NumberInput.module.scss';

export const NumberInput = forwardRef<HTMLInputElement, Props>(
  ({ disabled = false, iconName, id, invalid = false, onBlur, onChange, placeholder, value }, ref) => {
    return (
      <div className={styles.Wrapper}>
        {iconName && (
          <span className={styles.Icon}>
            <Icon name={iconName} />
          </span>
        )}

        <input
          className={classNames(styles.NumberInput, invalid && styles.NumberInput__Invalid)}
          disabled={disabled}
          id={id}
          min={0}
          onBlur={onBlur}
          onChange={(e) => onChange(Number.isNaN(+e.target.value) ? 0 : +e.target.value)} // Conversion table: https://i.stack.imgur.com/LLrgj.png
          placeholder={placeholder}
          ref={ref}
          step={1}
          type="number"
          value={value}
        />
      </div>
    );
  },
);

NumberInput.displayName = 'NumberInput';
