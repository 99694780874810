import type { IconName } from '~/components/SVG';

import type { Status } from './types';

export const getIconName = (status: Status) =>
  (
    ({
      info: 'InfoOutlined',
      success: 'Check',
      warning: 'ErrorOutline',
      error: 'Close',
    }) satisfies Record<Status, IconName>
  )[status];
