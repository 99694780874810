import { Trans, useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { ErrorModal, InfoModal, WarningModal } from '~/components';

import type { VatExemptLimitModalProps as Props } from './types';

export const VAT_EXEMPT_REVENUE_FIRST_WARNING = 20_000;
const VAT_EXEMPT_REVENUE_SECOND_WARNING = 22_500;
const VAT_EXEMPT_REVENUE_LIMIT = 25_000;

export const VatExemptLimitModal = ({ onClose, revenue }: Props) => {
  const { data: user } = useUser();
  const { t } = useTranslation(['invoices']);

  if (!user.vatExempt) return null;

  if (revenue >= VAT_EXEMPT_REVENUE_LIMIT) {
    return (
      <ErrorModal
        buttonText={t('invoices:createEdit.vatExemptLimit.exceeded.buttonText')}
        onClose={() => onClose(true)}
        title={t('invoices:createEdit.vatExemptLimit.exceeded.title')}
      >
        <p>
          <Trans components={{ a: <a /> }} i18nKey="invoices:createEdit.vatExemptLimit.exceeded.description" />
        </p>
      </ErrorModal>
    );
  }

  if (revenue >= VAT_EXEMPT_REVENUE_SECOND_WARNING) {
    return (
      <WarningModal
        buttonText={t('invoices:createEdit.vatExemptLimit.secondWarning.buttonText')}
        onClose={() => onClose(false)}
        title={t('invoices:createEdit.vatExemptLimit.secondWarning.title')}
      >
        <p>
          <Trans components={{ a: <a /> }} i18nKey="invoices:createEdit.vatExemptLimit.secondWarning.description" />
        </p>
      </WarningModal>
    );
  }

  if (revenue >= VAT_EXEMPT_REVENUE_FIRST_WARNING) {
    return (
      <InfoModal
        buttonText={t('invoices:createEdit.vatExemptLimit.firstWarning.buttonText')}
        onClose={() => onClose(false)}
        title={t('invoices:createEdit.vatExemptLimit.firstWarning.title')}
      >
        <p>
          <Trans components={{ a: <a /> }} i18nKey="invoices:createEdit.vatExemptLimit.firstWarning.description" />
        </p>
      </InfoModal>
    );
  }

  return null;
};
