import { Icon } from '~/components/SVG';

import type { ErrorMessageProps as Props } from './types';

import styles from './ErrorMessage.module.scss';

export const ErrorMessage = ({ children }: Props) => {
  return (
    <p className={styles.ErrorMessage}>
      <Icon inline name="Info" size={20} />
      <span>{children}</span>
    </p>
  );
};
