import { Link } from 'react-router-dom';

import { Icon } from '~/components/SVG';
import { AnchorTargets } from '~/constants/url';
import { routes } from '~/providers/RouterProvider/router.routes';

import type { LockedProps as Props } from './types';

export const Locked = ({ text }: Props) => {
  return (
    <Link className="space-x-1 font-bold text-primary-500" to={{ pathname: routes.settingsPaymentPlan, hash: AnchorTargets.PLAN_PICKER }}>
      <Icon inline name="Lock" />
      <span>{text}</span>
    </Link>
  );
};
