import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';
import { clientSchema } from './schemas';

export const clientsQueryOptions = queryOptions({
  queryKey: QueryKeys.clients,
  queryFn: async () => {
    const token = getAccessToken();
    const response = await request('/clients', { token });

    const { data: clients } = apiResourceSchema(clientSchema.array()).parse(response);

    clients.forEach(({ addresses }) =>
      addresses
        .sort(({ street: a }, { street: b }) => a.localeCompare(b))
        .sort(({ city: a }, { city: b }) => a.localeCompare(b))
        .sort(({ type: a }, { type: b }) => {
          const sortPriority = { headquarters: 1, 'establishment unit': 2 } as const;
          return sortPriority[a] - sortPriority[b];
        }),
    );

    return clients;
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const useClients = () => {
  return useSuspenseQuery(clientsQueryOptions);
};
