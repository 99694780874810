import * as stylex from '@stylexjs/stylex';

import { styles } from './InputPrefix.styles';

type Props = {
  children: string;
  onClick?: () => void;
};

export const InputPrefix = ({ children, onClick }: Props) => {
  return (
    <div {...stylex.props(styles.prefix)} onClick={onClick}>
      {children}
    </div>
  );
};
