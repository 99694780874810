import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

import type { ReferralCodeInformation } from '~/api/auth/types';
import type { BelgianVatInformation } from '~/api/clients/types';
import type { User } from '~/types/user';

import type { AccountInformationFormFields } from './RegistrationAccountInformation.types';
import type { BusinessInformationFormFields } from './RegistrationBusinessInformation.types';

export type RegistrationVatInformation = BelgianVatInformation & { fetchedAt: number };
export type RegistrationAccountInformation = AccountInformationFormFields;
export type RegistrationBusinessInformation = BusinessInformationFormFields;
export type RegistrationReferralCodeInformation = ReferralCodeInformation;

export const RegistrationContext = createContext<{
  accountInformation: RegistrationAccountInformation;
  businessInformation: RegistrationBusinessInformation;
  clearRegistrationInformation: () => void;
  hadReferralCode: boolean;
  referralCodeInformation: RegistrationReferralCodeInformation | null;
  setAccountInformation: (accountInformation: RegistrationAccountInformation) => void;
  setBusinessInformation: (businessInformation: RegistrationBusinessInformation) => void;
  setHadReferralCode: (hadReferralCode: boolean) => void;
  setReferralCodeInformation: (referralCodeInformation: RegistrationReferralCodeInformation | null) => void;
  setShowReferralCodeForm: (show: boolean) => void;
  setUser: (user: User) => void;
  setVatInformation: (vatInformation: RegistrationVatInformation | null) => void;
  showReferralCodeForm: boolean;
  user: User | null;
  vatInformation: RegistrationVatInformation | null;
} | null>(null);

export const useRegistrationContext = () => {
  const context = useContext(RegistrationContext);

  invariant(context, 'useRegistrationContext can only be called in /register routes');

  return context;
};
