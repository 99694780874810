import type { ReactNode } from 'react';

import classNames from 'classnames';

import type { EuVatCountryCode } from '~/api/common/types';

import { FormField } from '~/components';

export const FormVatNumberField = ({ countryCode }: { countryCode: EuVatCountryCode }) => {
  return (
    <CountryCodeWrapper countryCode={countryCode}>
      <FormField>
        {({ field, fieldState, id }) => (
          <input
            autoComplete="off"
            autoFocus
            className={classNames('w-full h-10 pl-16 pr-4 py-0 border text-dark-gray', fieldState.invalid && 'is-invalid')}
            id={id}
            onBlur={field.onBlur}
            onChange={(e) => {
              const value = e.target.value;
              const vatNumberWithoutCountryCode = value.toUpperCase().startsWith(countryCode)
                ? value.slice(countryCode.length).trim()
                : value;

              field.onChange(vatNumberWithoutCountryCode);
            }}
            ref={field.ref}
            value={field.value ?? ''}
          />
        )}
      </FormField>
    </CountryCodeWrapper>
  );
};

export const ReadOnlyVatNumberField = ({ countryCode, vatNumber }: { countryCode: EuVatCountryCode; vatNumber: string }) => {
  return (
    <CountryCodeWrapper countryCode={countryCode}>
      <input className="w-full h-10 pl-16 pr-4 py-0 border text-dark-gray hover:border-gray-300" disabled value={vatNumber} />
    </CountryCodeWrapper>
  );
};

const CountryCodeWrapper = ({ children, countryCode }: { children: ReactNode; countryCode: EuVatCountryCode }) => {
  return (
    <div className="relative">
      <div className="absolute inset-y-px left-px w-12 h-[38px] border-r border-gray-300 text-dark-gray bg-light-gray flex justify-center items-center pointer-events-none">
        <span>{countryCode}</span>
      </div>

      {children}
    </div>
  );
};
