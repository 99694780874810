import { Icon } from '~/components/SVG';

import type { ModalCloseButtonProps as Props } from './types';

import styles from './Modal.module.scss';

export const ModalCloseButton = ({ onClose }: Props) => {
  return (
    <button aria-label="Close" className={styles.close} onClick={onClose}>
      <Icon name="Close" />
    </button>
  );
};
