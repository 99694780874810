import * as stylex from '@stylexjs/stylex';

import { radius } from '~/styles/tokens.stylex';

import { animations } from './Spinner.stylex';

export const styles = stylex.create({
  spinner: {
    flexShrink: 0,
    position: 'relative',
    animationName: animations.rotationAnimation,
    animationTimingFunction: 'linear',
    animationDuration: '1.25s',
    animationIterationCount: 'infinite',
  },

  // Size

  regular: {
    width: 20,
    height: 20,
  },

  inline: {
    width: '0.8em',
    height: '0.8em',
  },

  // Inner element
  inner: {
    position: 'absolute',
    inset: 0,
    borderWidth: 2,
    borderColor: 'currentcolor',
    borderRadius: radius.full,
    animationName: animations.circularClipAnimation,
    animationTimingFunction: 'linear',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
  },
});
