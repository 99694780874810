import type { ComponentProps } from 'react';

import { match } from 'ts-pattern';

import { CraftHorizontal, CraftSymbol, CraftVertical } from '~/components/SVG';

type Props = ComponentProps<typeof CraftHorizontal> & {
  variant: 'horizontal' | 'vertical' | 'symbol';
};
export const Logo = ({ variant, ...delegated }: Props) => {
  const Component = match(variant)
    .with('horizontal', () => CraftHorizontal)
    .with('vertical', () => CraftVertical)
    .with('symbol', () => CraftSymbol)
    .exhaustive();

  return <Component {...delegated} />;
};
