import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdatePlan } from '~/api/user';
import { Button, Modal } from '~/components';

import type { ChangePlanModalProps as Props } from './types';

export const ChangePlanModal = ({ onClose, planId, user }: Props) => {
  const mutation = useUpdatePlan();

  const { t } = useTranslation(['common', 'settings']);

  const changePlan = () => {
    mutation.mutate(
      { planId },
      {
        onSuccess: () => {
          toast.success(t('settings:alerts.successUpdated'));
          onClose();
        },
        onError: () => toast.error(t('common:error')),
      },
    );
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:paymentPlan.changePlanModal.title')}</Modal.Title>

      <p>
        <Trans
          components={{ a: <a /> }}
          i18nKey={
            planId > user.planId
              ? 'settings:paymentPlan.changePlanModal.descriptionUpgrade'
              : 'settings:paymentPlan.changePlanModal.descriptionDowngrade'
          }
          values={{ currentPlan: t(`settings:paymentPlan.commission.planName.${user.planId}`), newPlan: t(`common:plan.${planId}`) }}
        />
      </p>

      <Modal.Actions>
        <Button hasSpinner icon="Send" isLoading={mutation.isPending} onClick={changePlan}>
          {t('settings:paymentPlan.changePlanModal.confirm')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
