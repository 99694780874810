import { Logo } from '~/components/UI';

import styles from './SpinnerLogo.module.scss';

export const SpinnerLogo = () => {
  return (
    <div className={styles.spinnerLogo}>
      <Logo variant="symbol" />
    </div>
  );
};
