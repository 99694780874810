import type { FilterLabelProps as Props } from './types';

import styles from './Filters.module.scss';

export const FilterLabel = ({ children, htmlFor }: Props) => {
  return (
    <label className={styles.FilterLabel} htmlFor={htmlFor}>
      {children}
    </label>
  );
};
