import classNames from 'classnames';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { match, Pattern } from 'ts-pattern';

import type { AvailablePlan } from '~/api/plans/types';

import { usePlans } from '~/api/plans';
import { PlanId } from '~/api/plans/constants';
import { Button, CollapsibleSection } from '~/components';
import { Icon } from '~/components/SVG';
import { AnchorTargets } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { useModalWithData } from '~/hooks/useModal';
import { includes } from '~/utils/arrays';
import { getActiveCommissionBracket } from '~/utils/user';

import type { PlanPickerProps as Props } from './types';

import { PROGRESS_BAR_MAX } from '../constants';
import { ChangePlanModal } from './ChangePlanModal';
import { PlanFeature } from './PlanFeature';
import styles from './PlanPicker.module.scss';
import { RequestPlanChangeModal } from './RequestPlanChangeModal';

export const PlanPicker = ({ user }: Props) => {
  const { data: plans } = usePlans();

  const { t } = useTranslation(['common', 'settings']);

  const activeBracket = getActiveCommissionBracket(user);
  const availablePlans = plans.filter((plan): plan is AvailablePlan => includes([PlanId.classic, PlanId.premium], plan.id));

  const classicPlan = availablePlans.find((plan) => plan.id === PlanId.classic)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const changePlanModal = useModalWithData<AvailablePlan['id']>();
  const requestPlanChangeModal = useModalWithData<AvailablePlan['id']>();
  const [detailsMobile, setDetailsMobile] = useState<AvailablePlan['id'] | null>(null);

  const { formatDecimal } = useIntl();

  return (
    <CollapsibleSection
      description={t('settings:paymentPlan.plans.sectionDescription')}
      id={AnchorTargets.PLAN_PICKER}
      title={t('settings:paymentPlan.plans.sectionTitle')}
    >
      <div className={styles.Plans}>
        {availablePlans.map((plan) => {
          const creativePercentages = match(user.inviteeDiscount?.type)
            .with('PremiumForClassic', () => classicPlan.creativePercentages)
            .with(Pattern.any, () => plan.creativePercentages)
            .exhaustive();

          return (
            <article
              className={classNames(
                styles.PlanCard,
                user.planId === plan.id && styles.Active,
                detailsMobile === plan.id && styles.Expanded,
              )}
              key={plan.name}
            >
              <header className={styles.Header}>
                <h2 className={styles.Header__Title}>{t(`common:plan.${plan.id}`)}</h2>
                <p className={styles.Header__Subtitle}>{t(`settings:paymentPlan.picker.subtitle.${plan.id}`)}</p>
              </header>

              <div className={styles.Commission}>
                <span className={styles.Commission__Percentage}>{formatDecimal(creativePercentages[activeBracket])}%</span>
                <span className={styles.Commission__Text}>{t('settings:paymentPlan.picker.commissionText')}</span>
              </div>

              {plan.id === user.planId ? (
                <div className={styles.ActivePlan}>{t('settings:paymentPlan.picker.current')}</div>
              ) : (
                <Button
                  block
                  disabled={!!user.inviteeDiscount}
                  extraClasses="h-10"
                  onClick={() => (user.commissionPercentages.adminOverride ? requestPlanChangeModal : changePlanModal).open(plan.id)}
                >
                  {user.commissionPercentages.adminOverride
                    ? t('settings:paymentPlan.picker.contact')
                    : t('settings:paymentPlan.picker.select')}
                </Button>
              )}

              <div className={styles.Separator} />

              <div className={styles.Features}>
                <p className={styles.Features__Label}>{t('settings:paymentPlan.picker.features.label')}</p>

                <ul className={styles.Features__List}>
                  {typeof t(`settings:paymentPlan.picker.features.${plan.id}`, { returnObjects: true }) === 'string'
                    ? (t(`settings:paymentPlan.picker.features.${plan.id}`, { returnObjects: true }) as unknown as string) // i18n cimode
                    : t(`settings:paymentPlan.picker.features.${plan.id}`, { returnObjects: true }).map((translation, i) => {
                        const text = (
                          <Trans
                            components={{ a: <a /> }}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            i18nKey={`settings:paymentPlan.picker.features.${plan.id}.${i}.text` as any}
                          />
                        );
                        const textWithLink = 'link' in translation ? <Link to={translation.link ?? ''}>{text}</Link> : text;

                        return (
                          <PlanFeature
                            key={`${plan.name}_${i}`}
                            text={textWithLink}
                            tooltip={
                              'tooltip' in translation ? (
                                <Trans
                                  components={{ a: <a /> }}
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  i18nKey={`settings:paymentPlan.picker.features.${plan.id}.${i}.tooltip` as any}
                                />
                              ) : undefined
                            }
                          />
                        );
                      })}
                </ul>
              </div>

              <div className={styles.Separator} />

              <div className={styles.Progress}>
                <div className={styles.Progress__Percentages}>
                  {([1, 2, 3] as const).map((bracket) => (
                    <span className={classNames(styles.Progress__Percentage, activeBracket === bracket && styles.Active)} key={bracket}>
                      {formatDecimal(creativePercentages[bracket])}%
                    </span>
                  ))}
                </div>

                <div className={styles.Progress__Bar}>
                  <div className={styles.Progress__BarBackground} />
                  <div
                    className={styles.Progress__BarFilled}
                    style={{ width: `${Math.min((user.totalInvoicedAmount / PROGRESS_BAR_MAX) * 100, 100)}%` }}
                  />

                  <div
                    className={classNames(styles.Progress__Milestone, styles.Progress__Milestone_1, activeBracket > 1 && styles.Active)}
                  />
                  <div
                    className={classNames(styles.Progress__Milestone, styles.Progress__Milestone_2, activeBracket > 2 && styles.Active)}
                  />
                </div>
              </div>

              <div className={styles.ShowDetailsMobile}>
                <button onClick={() => setDetailsMobile(detailsMobile === plan.id ? null : plan.id)}>
                  {detailsMobile === plan.id ? t('settings:paymentPlan.picker.hideDetails') : t('settings:paymentPlan.picker.showDetails')}
                </button>
                <Icon name={detailsMobile === plan.id ? 'KeyboardArrowUp' : 'KeyboardArrowDown'} size={20} />
              </div>

              {plan.id === PlanId.premium && !user.inviteeDiscount && (
                <div className={styles.Tag}>{t('settings:paymentPlan.tags.mostPopular')}</div>
              )}

              {plan.id === PlanId.premium && user.inviteeDiscount?.type === 'PremiumForClassic' && (
                <div className={styles.Tag}>{t(`settings:paymentPlan.tags.${user.inviteeDiscount.type}`)}</div>
              )}
              {plan.id === PlanId.premium && user.inviteeDiscount?.type === 'PremiumForFree' && (
                <div className={styles.Tag}>
                  {t(`settings:paymentPlan.tags.${user.inviteeDiscount.type}`, { daysValid: user.inviteeDiscount.daysValid })}
                </div>
              )}
            </article>
          );
        })}
      </div>

      <p className={styles.Disclaimer}>{t('settings:paymentPlan.picker.disclaimer')}</p>

      {changePlanModal.isOpen && <ChangePlanModal onClose={changePlanModal.close} planId={changePlanModal.data} user={user} />}

      {requestPlanChangeModal.isOpen && (
        <RequestPlanChangeModal onClose={requestPlanChangeModal.close} planId={requestPlanChangeModal.data} user={user} />
      )}
    </CollapsibleSection>
  );
};
