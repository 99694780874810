import type { FieldProps } from '@headlessui/react';
import type { ReactNode } from 'react';

import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Field as HuiField } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';

import type { WithoutStyleProps } from '../../types';

import { styles } from './Field.styles';

type Props = WithoutStyleProps<FieldProps> & {
  children: ReactNode;
};

export const Field = ({ children, ...delegated }: Props) => {
  const [ref] = useAutoAnimate();

  return (
    <HuiField {...delegated}>
      <div {...stylex.props(styles.field)} ref={ref}>
        {children}
      </div>
    </HuiField>
  );
};
