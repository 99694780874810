import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useDeleteNumberingSeries } from '~/api/user';
import { Button, Modal } from '~/components';

import type { DeleteNumberingSeriesModalProps } from './types';

export const DeleteNumberingSeriesModal = ({ numberingSeries, onClose }: DeleteNumberingSeriesModalProps) => {
  const mutation = useDeleteNumberingSeries(numberingSeries.id);

  const { t } = useTranslation(['common', 'settings']);

  const deleteNumberingSeries = () => {
    mutation.mutate(undefined, {
      onSuccess: () => {
        toast.success(t('settings:alerts.numberingSeries.successDeleted'));
        onClose();
      },
      onError: () => toast.error(t('common:error')),
    });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:general.numberingSeries.delete.title')}</Modal.Title>

      <p>{t('settings:general.numberingSeries.delete.description', { numberingSeries: numberingSeries.name })}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Delete" isLoading={mutation.isPending} onClick={deleteNumberingSeries}>
          {t('common:delete')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
