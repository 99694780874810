import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import type { User } from '~/types/user';

import { getAccessToken } from '~/utils/auth';

import type { UpdateActivitiesPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { conditionsQueryOptions } from '../conditions';
import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useUpdateActivities = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateActivitiesPayload) => {
      const token = getAccessToken();
      const response = await request('/user', { method: 'PATCH', token, body });

      // TODO: Schema validation
      const { data: user } = apiResourceSchema(z.unknown()).parse(response);

      return user as User;
    },
    onSuccess: (user) => {
      queryClient.setQueryData(userQueryOptions.queryKey, () => user);
      queryClient.invalidateQueries(userQueryOptions);
      queryClient.invalidateQueries(conditionsQueryOptions); // Conditions change when the "education" activity is selected or deselected.
    },
  });
};
