import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { CommunityProfile } from '~/api/community/types';

import { useCommunityProfiles, useUpdateCommunityProfileAnalytics } from '~/api/community';
import { communityRegions } from '~/api/community/constants';
import { Button, Checkbox, MultiSelect, Select, Spinner, TextInput } from '~/components';
import { CommunityProfileCard, CommunityProfileCardSkeleton, CommunityProfileModal } from '~/components/Templates/Community';
import { Filters } from '~/components/UI';
import { mainActivities } from '~/constants/constants';
import { useIntersectionObserver } from '~/hooks/useIntersectionObserver';
import { useIntl } from '~/hooks/useIntl';
import { useModalWithData } from '~/hooks/useModal';

import { useCommunityProfileFilters } from './hooks';

export const CommunityProfilesList = () => {
  // Filters
  const [showFilters, setShowFilters] = useState(false);
  const filters = useCommunityProfileFilters();

  // Query
  const { data, fetchNextPage, hasNextPage, isError, isFetchingNextPage, isPending, isSuccess } = useCommunityProfiles({
    name: filters.search.debouncedValue.trim(),
    activityIds: filters.activityId.value ? [filters.activityId.value] : [],
    openToWork: filters.isOpenToWork.value,
    regions: filters.regions.value,
  });
  const handleFetchNextPage = useCallback(() => !isFetchingNextPage && fetchNextPage(), [fetchNextPage, isFetchingNextPage]);
  const profiles = isSuccess ? data.pages.flatMap(({ data }) => data) : [];
  const totalResults = isSuccess ? data.pages[0].pagination.totalResults : null;

  // Analytics
  const mutation = useUpdateCommunityProfileAnalytics('view');

  // Intersection observers
  const { ref: buttonRef } = useIntersectionObserver<HTMLButtonElement>(handleFetchNextPage, { threshold: 0.5 });
  const { isIntersecting: isFiltersVisible, ref: filtersRef } = useIntersectionObserver(null, { threshold: 0.1 });

  const { t } = useTranslation(['common', 'community']);
  const detailModal = useModalWithData<CommunityProfile>();

  const { compareForSort } = useIntl();
  const activityOptions = useMemo(
    () => mainActivities.map((activity) => ({ value: activity, label: t(`common:activities.${activity}.label`) })),
    [t],
  );
  const regionOptions = useMemo(
    () =>
      [...communityRegions]
        .map((region) => ({ value: region, label: t(`community:regions.${region}`) }))
        .sort(({ label: a }, { label: b }) => compareForSort(a, b)),
    [compareForSort, t],
  );

  return (
    <>
      {/* Filters */}
      <div className="flex gap-x-4" ref={filtersRef}>
        <div className="flex-grow">
          <TextInput
            clearable
            iconName="Search"
            onChange={filters.search.onChange}
            placeholder={t('community:overview.filters.name.placeholder')}
            value={filters.search.value}
          />
        </div>

        <Button extraClasses="!px-3" icon="Tune" onClick={() => setShowFilters((prev) => !prev)} type="secondary">
          <span className="max-md:hidden">{showFilters ? t('common:datatable.hideFilters') : t('common:datatable.showFilters')}</span>
        </Button>
      </div>

      <Filters show={showFilters}>
        <Filters.Group>
          <Filters.Label htmlFor="activityId">{t('community:overview.filters.activity.label')}</Filters.Label>
          <Select
            clearable
            id="activityId"
            onChange={filters.activityId.onChange}
            options={activityOptions}
            placeholder={t('community:overview.filters.activity.placeholder')}
            value={filters.activityId.value}
          />
        </Filters.Group>

        <Filters.Group>
          <Filters.Label htmlFor="region">{t('community:overview.filters.region.label')}</Filters.Label>
          <MultiSelect
            id="region"
            onChange={filters.regions.onChange}
            options={regionOptions}
            placeholder={t('community:overview.filters.region.placeholder')}
            value={filters.regions.value}
          />
        </Filters.Group>

        <Filters.Group>
          <Filters.Label htmlFor="openToWork">{t('community:overview.filters.openToWork.label')}</Filters.Label>
          <Checkbox
            checked={filters.isOpenToWork.value}
            id="openToWork"
            label={t('community:overview.filters.openToWork.text')}
            onChange={filters.isOpenToWork.onChange}
          />
        </Filters.Group>
      </Filters>

      {/* Result count */}
      {!isError && (
        <div className="mt-2 mb-6 flex items-baseline gap-x-1 text-sm text-medium-gray">
          <span>
            {totalResults === null ? t('community:overview.loading') : t('community:overview.resultCount', { count: totalResults ?? 0 })}
          </span>
          {filters.hasSomeActive && (
            <Button onClick={() => filters.reset()} type="tertiary">
              {t('community:overview.resetFilters')}
            </Button>
          )}
        </div>
      )}

      {/* Error */}
      {isError && (
        <span className="block mt-6 text-error-500">
          <Trans components={{ a: <a /> }} i18nKey="community:overview.errorLoading" />
        </span>
      )}

      {/* Profile cards */}
      {(isPending || profiles.length > 0) && (
        <div className={classNames('grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-8', isPending && 'cursor-wait')}>
          {isPending && Array.from({ length: 12 }, () => null).map((_, i) => <CommunityProfileCardSkeleton animate key={i} />)}
          {!isPending &&
            profiles.map((profile) => (
              <CommunityProfileCard
                key={profile.id}
                onClick={() => {
                  mutation.mutate(profile.id);
                  detailModal.open(profile);
                }}
                profile={profile}
              />
            ))}
        </div>
      )}

      {(hasNextPage || !isFiltersVisible) && !isError && (
        <div className="my-8 flex justify-center">
          {/* Show more button */}
          {hasNextPage && (
            <Button
              className="block mx-auto mt-6 font-medium text-primary-500"
              disabled={isFetchingNextPage}
              onClick={handleFetchNextPage}
              ref={buttonRef}
              type="tertiary"
            >
              {isFetchingNextPage ? <Spinner /> : t('community:overview.showMore')}
            </Button>
          )}

          {/* Scroll to top button */}
          {!hasNextPage && !isFiltersVisible && (
            <Button icon="ArrowUpward" onClick={() => window.scrollTo(0, 0)} type="tertiary">
              {t('community:overview.scrollToTop')}
            </Button>
          )}
        </div>
      )}

      {/* Detail modal */}
      {detailModal.isOpen && <CommunityProfileModal onClose={detailModal.close} profile={detailModal.data} />}
    </>
  );
};
