export const filters = {
  clients_name: {
    label: 'Client name',
    placeholder: 'All clients',
  },
  clients_type: {
    label: 'Type of client',
    placeholder: 'All types',
  },
  credit_notes_brand: {
    label: 'Trade name',
    placeholder: 'All trade names',
  },
  credit_notes_client: {
    label: 'Client',
    placeholder: 'All clients',
  },
  credit_notes_doc_quarter: {
    label: 'Quarter',
    placeholder: 'All quarters',
  },
  credit_notes_doc_year: {
    label: 'Year',
    placeholder: 'All years',
  },
  credit_notes_status: {
    label: 'Status',
    placeholder: 'All statuses',
  },
  invoices_brand: {
    label: 'Trade name',
    placeholder: 'All trade names',
  },
  invoices_client: {
    label: 'Client',
    placeholder: 'All clients',
  },
  invoices_doc_quarter: {
    label: 'Quarterly',
    placeholder: 'All quarters',
  },
  invoices_doc_year: {
    label: 'Year',
    placeholder: 'All years',
  },
  invoices_status: {
    label: 'Status',
    placeholder: 'All statuses',
  },
  products_activity_type: {
    label: 'Type of performance',
    placeholder: 'All performances',
  },
  products_categories: {
    label: 'Categories',
    placeholder: 'All categories',
  },
  products_status: {
    label: 'Status',
    placeholder: 'All statuses',
  },
  quarter: {
    option: 'Quarter {{quarter}} - {{year}}',
  },
  quotations_brand: {
    label: 'Trade name',
    placeholder: 'All trade names',
  },
  quotations_client: {
    label: 'Client',
    placeholder: 'All clients',
  },
  quotations_doc_quarter: {
    label: 'Quarter',
    placeholder: 'All quarters',
  },
  quotations_doc_year: {
    label: 'Year',
    placeholder: 'All years',
  },
  quotations_status: {
    label: 'Status',
    placeholder: 'All statuses',
  },
};
