import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { Contact } from '~/api/clients/types';

import { useCreateContact } from '~/api/clients';
import { Button, FloatingActionButton, PageHeader } from '~/components';
import { Blocker } from '~/components/Functional';
import { SearchParamKeys } from '~/constants/url';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { Language } from '~/types/app';
import { qs } from '~/utils/searchParams';

import type { ContactFormType } from '../types';
import type { CreateContactFormProps as Props } from './types';

import { ContactAddressFormSection } from '../shared/ContactAddressFormSection';
import { ContactCommentsFormSection } from '../shared/ContactCommentsFormSection';
import { ContactCustomAgreementFormSection } from '../shared/ContactCustomAgreementFormSection';
import { ContactEmailsFormSection } from '../shared/ContactEmailsFormSection';
import { ContactPersonalInfoFormSection } from '../shared/ContactPersonalInfoFormSection';
import { OrganisationInfoCard } from '../shared/OrganisationInfoCard';
import { mapContactFormDataToPayload, mapContactResponseToFormData } from '../utils';

export const CreateContactForm = ({ client }: Props) => {
  const mutation = useCreateContact(client.id);

  // When a contact is duplicated, pre-fill the form with the data in location state ({ duplicateContact: Contact; }).
  const { state } = useLocation();
  const duplicateContact = (state?.duplicateContact as Contact | undefined) ?? null;
  const duplicateContactFormValues = duplicateContact ? mapContactResponseToFormData(duplicateContact, client) : null;

  const form = useForm<ContactFormType>({
    defaultValues: {
      firstName: '',
      lastName: '',
      function: '',
      language: duplicateContactFormValues?.language ?? Language.DUTCH,
      phoneNumber: '',
      useOrganisationAddress: duplicateContactFormValues?.useOrganisationAddress ?? true,
      addressIndex: duplicateContactFormValues?.addressIndex ?? 0,
      address: duplicateContactFormValues?.address ?? {
        street: '',
        number: '',
        zipCode: '',
        city: '',
        countryCode: 'BE',
      },
      emails: duplicateContactFormValues?.emails ?? [
        {
          type: 'client',
          emailAddress: '',
          enableForQuotations: true,
          enableForInvoicesAndCreditNotes: true,
          enableForPaymentReminders: true,
        },
      ],
      customAgreement: false,
      comments: duplicateContactFormValues?.comments ?? '',
    },
  });

  const {
    formState: { isDirty },
    getValues,
    handleSubmit,
  } = form;

  const navigate = useNavigate();
  const { t } = useTranslation(['clients', 'common']);

  const searchParams = useSearchParams()[0];
  const redirectPath = searchParams.get(SearchParamKeys.REDIRECT_PATH);

  const onSubmit = handleSubmit((data) => {
    if (mutation.isPending) return;

    mutation.mutate(mapContactFormDataToPayload(data, getValues('useOrganisationAddress'), client.addresses), {
      onSuccess: (contact) => {
        toast.success(t('clients:alerts.successContactCreated'));
        navigate(
          redirectPath
            ? {
                pathname: redirectPath,
                search: qs({ [SearchParamKeys.CONTACT_ID]: contact.id }),
              }
            : r(routes.showClient, { clientId: client.id }),
          { state: { blockable: false } },
        );
      },
      onError: () => toast.error(t('common:error')),
    });
  });

  return (
    <>
      <PageHeader backRoute={redirectPath ?? r(routes.showClient, { clientId: client.id })} title={client.name}>
        <Button hasSpinner icon="PersonAdd" isLoading={mutation.isPending} onClick={onSubmit}>
          {t('clients:createContact.submit')}
        </Button>
      </PageHeader>

      <Blocker isBlocked={isDirty || !!duplicateContact} message={t('clients:createContact.blocker')} />

      <div className="mb-12">
        <OrganisationInfoCard
          organisation={{
            companyName: client.name,
            vatNumber: client.vatNumber,
            otherNumber: client.otherNumber,
            address: client.addresses.find((address) => address.type === 'headquarters') ?? client.addresses[0],
          }}
        />
      </div>

      <form onSubmit={onSubmit}>
        <ContactPersonalInfoFormSection form={form} />
        <ContactAddressFormSection addresses={client.addresses} form={form} />
        <ContactEmailsFormSection form={form} />
        <ContactCustomAgreementFormSection form={form} />
        <ContactCommentsFormSection form={form} />
      </form>

      <FloatingActionButton icon="PersonAdd" options={[{ text: t('clients:createContact.submit'), onClick: onSubmit }]} />
    </>
  );
};
