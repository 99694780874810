import { FloatingPortal } from '@floating-ui/react';

import type { TooltipContentProps as Props } from './types';

import { useTooltipContext } from './context';
import styles from './Tooltip.module.scss';

export const TooltipContent = (props: Props) => {
  const { getFloatingProps, open, refs, strategy, x, y } = useTooltipContext();

  return (
    <FloatingPortal>
      {open && (
        <div
          className={styles.tooltip}
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            visibility: x === null || x === undefined ? 'hidden' : 'visible',
            ...props.style,
          }}
          {...getFloatingProps({ ...props, onClick: (e) => e.stopPropagation() })}
        />
      )}
    </FloatingPortal>
  );
};
