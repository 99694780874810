import { Trans, useTranslation } from 'react-i18next';

import { Modal } from '~/components';

import type { AdvantageSimulationModalProps as Props } from './types';

import { AdvantageSimulationChart } from './AdvantageSimulationChart';

export const AdvantageSimulationModal = ({ calculationData, onClose }: Props) => {
  const { t } = useTranslation(['invoices']);

  return (
    <Modal onClose={onClose}>
      <Modal.Title tooltip={<Trans components={{ a: <a /> }} i18nKey="invoices:createEdit.advantageSimulationModal.tooltip" />}>
        {t('invoices:createEdit.advantageSimulationModal.title')}
      </Modal.Title>

      {calculationData.advantageWithCs && calculationData.advantageWithoutCs && calculationData.coExclVat > 0 ? (
        <AdvantageSimulationChart calculationData={calculationData} />
      ) : (
        <p className="text-medium-gray italic">{t('invoices:createEdit.advantageSimulationModal.noDataDisclaimer')}</p>
      )}
    </Modal>
  );
};
