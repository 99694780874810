import type { ReactNode } from 'react';

import { FloatingPortal } from '@floating-ui/react';
import * as stylex from '@stylexjs/stylex';

import { useTooltipContext } from './Tooltip.context';
import { styles } from './TooltipContent.styles';

type Props = {
  children: ReactNode;
};

export const TooltipContent = ({ children }: Props) => {
  const { arrowRef, floatingStyles, getFloatingProps, middlewareData, open, placement, refs } = useTooltipContext();

  if (!open) return null;

  const mainPlacement = placement.split('-')[0] as 'top' | 'right' | 'bottom' | 'left';
  const staticSide = (
    {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    } as const
  )[mainPlacement];

  const isPlacedHorizontally = mainPlacement === 'left' || mainPlacement === 'right';

  return (
    <FloatingPortal>
      <div
        {...stylex.props(styles.tooltip)}
        style={floatingStyles}
        {...getFloatingProps({ onClick: (e) => e.stopPropagation() })}
        ref={refs.setFloating}
      >
        {children}

        <div
          {...stylex.props(styles.arrow, isPlacedHorizontally && styles.arrowHorizontal)}
          ref={arrowRef}
          style={{
            left: middlewareData.arrow?.x ? `${middlewareData.arrow.x}px` : undefined,
            top: middlewareData.arrow?.y ? `${middlewareData.arrow.y}px` : undefined,
            [staticSide]: `${-(arrowRef.current?.offsetWidth ?? 0) / 2}px`,
          }}
        />
      </div>
    </FloatingPortal>
  );
};
