import type { FormTextAreaProps as Props } from './types';

import { useFormControlContext } from '../../context';
import { TextArea } from './TextArea';

export const FormTextArea = (props: Props) => {
  const { field, fieldState, id } = useFormControlContext();

  return (
    <TextArea
      id={id}
      invalid={fieldState.invalid}
      onBlur={field.onBlur}
      onChange={field.onChange}
      ref={field.ref}
      value={field.value ?? ''}
      {...props}
    />
  );
};
