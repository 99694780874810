import { HardBreak } from '@tiptap/extension-hard-break';
import { Link } from '@tiptap/extension-link';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Underline } from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useStableRef } from '~/hooks/useStableRef';

import type { RichTextEditorProps as Props } from './types';

import { TextEditorMenu } from './Extensions/TextEditorMenu';
import './RichTextEditor.scss';

export const RichTextEditor = ({
  isInvalid,
  onChange,
  placeholder,
  setShouldUpdateContent,
  shortcodes,
  shouldUpdateContent,
  value,
  withLinkButton = true,
}: Props) => {
  const { t } = useTranslation(['common']);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        hardBreak: false,
        heading: { levels: [] },
      }),
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            Enter: () => {
              if (this.editor.isActive('bulletList') || this.editor.isActive('orderedList')) {
                return this.editor.commands.createParagraphNear();
              }

              return this.editor.commands.setHardBreak();
            },
          };
        },
      }),
      Underline,
      Link,
      Placeholder.configure({
        placeholder: placeholder ?? t('common:placeholders.richText'),
      }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const text = editor.getText();

      if (!text) return onChange?.('');

      const input = editor.getHTML();
      // {Emoji} also filters out all the numbers
      const filteredInput = input?.replace(/\p{Emoji_Presentation}/gu, '');
      onChange?.(filteredInput);
    },
  });

  const stableSetContent = useStableRef(editor?.commands.setContent);

  /**
   * Workaround to update the editor content from outside this component.
   */
  useEffect(() => {
    if (shouldUpdateContent && setShouldUpdateContent) {
      stableSetContent.current?.(value);
      setShouldUpdateContent(false);
    }
  }, [shouldUpdateContent, setShouldUpdateContent, value, stableSetContent]);

  return (
    <div className={classNames('richTextEditor', { isInvalid })}>
      <TextEditorMenu editor={editor} shortcodes={shortcodes} withLinkButton={withLinkButton} />
      <EditorContent editor={editor} />
    </div>
  );
};
