import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useUpdateClient } from '~/api/clients';
import { Button, FormControl, FormErrorMessage, FormLabel, FormTextInput, Label, PageHeader, TextInput } from '~/components';
import { Blocker } from '~/components/Functional';
import { europeanUnionCountryCodes } from '~/constants/countries';
import { SearchParamKeys } from '~/constants/url';
import { r, routes } from '~/providers/RouterProvider/router.routes';
import { includes } from '~/utils/arrays';

import type { EditClientFormType, EditClientFormProps as Props } from './types';

import { FormAddressCity } from '../shared/FormAddressCity';
import { FormAddressNumber } from '../shared/FormAddressNumber';
import { FormAddressStreet } from '../shared/FormAddressStreet';
import { FormAddressZipCode } from '../shared/FormAddressZipCode';
import { FormCompanyName } from '../shared/FormCompanyName';
import { FormCountryCode } from '../shared/FormCountryCode';

const noop = () => null;

export const EditClientForm = ({ client }: Props) => {
  const mutation = useUpdateClient(client.id);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<EditClientFormType>({
    defaultValues: {
      companyName: client.name,
      otherNumber: client.otherNumber ?? '',
      address: {
        street: client.addresses[0].street,
        number: client.addresses[0].number,
        zipCode: client.addresses[0].zipCode,
        city: client.addresses[0].city,
        countryCode: client.addresses[0].countryCode,
      },
    },
  });

  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const redirectPath = searchParams.get(SearchParamKeys.REDIRECT_PATH);
  const { t } = useTranslation(['clients', 'common', 'validation']);

  const isBelgianOrganisation = client.addresses[0].countryCode === 'BE';
  const isEuropeanOrganisation = !isBelgianOrganisation && includes(europeanUnionCountryCodes, client.addresses[0].countryCode);
  const isInternationalOrganisation = !isBelgianOrganisation && !isEuropeanOrganisation;
  const hasOtherNumber = (isBelgianOrganisation && !client.vatNumber) || isEuropeanOrganisation || isInternationalOrganisation;

  const onSubmit = handleSubmit((data) => {
    if (mutation.isPending) return;

    mutation.mutate(data, {
      onSuccess: () => {
        toast.success(t('clients:alerts.successUpdated'));
        const redirectRoute = redirectPath ?? r(routes.showClient, { clientId: client.id });
        navigate(redirectRoute, { state: { blockable: false } });
      },
      onError: () => toast.error(t('common:error')),
    });
  });

  return (
    <>
      <PageHeader backRoute={redirectPath ?? r(routes.showClient, { clientId: client.id })} title={client.name}>
        <Button hasSpinner icon="Edit" isLoading={mutation.isPending} onClick={onSubmit}>
          {t('clients:editClient.submit')}
        </Button>
      </PageHeader>

      <Blocker isBlocked={isDirty} message={t('clients:editClient.blocker')} />

      <form className="grid gap-4 grid-cols-6" onSubmit={onSubmit}>
        <FormCompanyName
          className={classNames('col-span-full', client.vatNumber && hasOtherNumber ? 'lg:col-span-2' : 'lg:col-span-3')}
          control={control}
        />

        {client.vatNumber && (
          <div className={classNames('col-span-full', hasOtherNumber ? 'lg:col-span-2' : 'lg:col-span-3')}>
            <Label id="vatNumber">{t('clients:fields.vatNumber.label')}</Label>
            <TextInput disabled id="vatNumber" onChange={noop} value={client.vatNumber} />
          </div>
        )}

        {hasOtherNumber && (
          <div className={classNames('col-span-full', client.vatNumber ? 'lg:col-span-2' : 'lg:col-span-3')}>
            <FormControl control={control} name="otherNumber" rules={{ required: !client.vatNumber }}>
              <FormLabel optional={!!client.vatNumber}>
                {isInternationalOrganisation ? t('clients:fields.vatOrOtherNumber.label') : t('clients:fields.otherNumber.label')}
              </FormLabel>
              <FormTextInput />
              <FormErrorMessage required={t('validation:required')} />
            </FormControl>
          </div>
        )}

        <FormAddressStreet className="col-span-full lg:col-span-4" control={control} />
        <FormAddressNumber className="col-span-full lg:col-span-2" control={control} />
        <FormAddressZipCode className="col-span-full lg:col-span-2" control={control} />
        <FormAddressCity className="col-span-full lg:col-span-2" control={control} />
        <FormCountryCode className="col-span-full lg:col-span-2" control={control} disabled />
      </form>
    </>
  );
};
