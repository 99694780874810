import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import { styles } from './InputWrapper.styles';

type Props = {
  children: ReactNode;
  hasPaddingLeft?: boolean;
  hasPaddingRight?: boolean;
};
export const InputWrapper = ({ children, hasPaddingLeft = true, hasPaddingRight = true }: Props) => {
  return (
    <div
      {...stylex.props(
        styles.wrapper,
        styles.dataStates,
        !hasPaddingLeft && styles.noPaddingLeft,
        !hasPaddingRight && styles.noPaddingRight,
      )}
    >
      {children}
    </div>
  );
};
