import classNames from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { LegendProps as Props } from '~/pages/Dashboard/Charts/types';

import { Icon } from '~/components/SVG';
import { SearchParamKeys } from '~/constants/url';
import { useIntl } from '~/hooks/useIntl';
import { getInvoiceOverviewCalculations } from '~/pages/Dashboard/utils';
import { InvoiceStatus } from '~/pages/InvoicesCreditnotes/utils';
import { routes } from '~/providers/RouterProvider/router.routes';
import { qs } from '~/utils/searchParams';

import styles from './Legend.module.scss';

export const Legend = ({ invoices, year }: Props) => {
  const { formatCurrency } = useIntl();
  const { t } = useTranslation(['dashboard']);

  const overviewByYearLegendData = useMemo(() => getInvoiceOverviewCalculations(invoices, year), [invoices, year]);

  return (
    <table className={styles.legend} id="overview-pie-chart">
      <thead>
        <tr>
          <th className="text-left">{t('dashboard:charts.table.description')}</th>
          <th className="text-right">{t('dashboard:charts.table.amount')}</th>
          <th className="text-right">{t('dashboard:charts.table.value')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {t('dashboard:charts.labels.total')} {year}
          </td>
          <td className="text-right">{overviewByYearLegendData.total.amount}</td>
          <td className="text-right">{formatCurrency(overviewByYearLegendData.total.value)}</td>
          <td className="text-right">
            <Link className={styles.icon} to={{ pathname: routes.invoices, search: qs({ [SearchParamKeys.INVOICE_FILTER_YEAR]: year }) }}>
              <Icon inline name="Visibility" />
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <strong>{t('dashboard:charts.labels.unpaid')}</strong>
          </td>
          <td className="text-right">{overviewByYearLegendData.unpaidPending.amount + overviewByYearLegendData.unpaidExpired.amount}</td>
          <td className="text-right">
            {formatCurrency(overviewByYearLegendData.unpaidPending.value + overviewByYearLegendData.unpaidExpired.value)}
          </td>
          <td></td>
        </tr>
        <tr>
          <td>
            <div className={classNames(styles.color, 'bg-light-gray')} />
            {t('dashboard:charts.labels.expired')}
          </td>
          <td className="text-right">{overviewByYearLegendData.unpaidExpired.amount}</td>
          <td className="text-right">{formatCurrency(overviewByYearLegendData.unpaidExpired.value)}</td>
          <td className="text-right">
            <Link
              className={styles.icon}
              to={{
                pathname: routes.invoices,
                search: qs({ [SearchParamKeys.INVOICE_FILTER_YEAR]: year, [SearchParamKeys.INVOICE_FILTER_STATUS]: InvoiceStatus.EXPIRED }),
              }}
            >
              <Icon inline name="Visibility" />
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className={classNames(styles.color, 'bg-medium-gray')} />
            {t('dashboard:charts.labels.notExpired')}
          </td>
          <td className="text-right">{overviewByYearLegendData.unpaidPending.amount}</td>
          <td className="text-right">{formatCurrency(overviewByYearLegendData.unpaidPending.value)}</td>
          <td className="text-right">
            <Link
              className={styles.icon}
              to={{
                pathname: routes.invoices,
                search: qs({ [SearchParamKeys.INVOICE_FILTER_YEAR]: year, [SearchParamKeys.INVOICE_FILTER_STATUS]: InvoiceStatus.SENT }),
              }}
            >
              <Icon inline name="Visibility" />
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <strong>{t('dashboard:charts.labels.paid')}</strong>
          </td>
          <td className="text-right">{overviewByYearLegendData.paidCurrentQ.amount + overviewByYearLegendData.paidPreviousQ.amount}</td>
          <td className="text-right">
            {formatCurrency(overviewByYearLegendData.paidCurrentQ.value + overviewByYearLegendData.paidPreviousQ.value)}
          </td>
          <td className="text-right">
            <Link
              className={styles.icon}
              to={{
                pathname: routes.invoices,
                search: qs({ [SearchParamKeys.INVOICE_FILTER_YEAR]: year, [SearchParamKeys.INVOICE_FILTER_STATUS]: InvoiceStatus.FC_PAID }),
              }}
            >
              <Icon inline name="Visibility" />
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className={classNames(styles.color, 'bg-secondary-500')} />
            {t('dashboard:charts.labels.currentQ', { quarter: dayjs().quarter() })}
          </td>
          <td className="text-right">{overviewByYearLegendData.paidCurrentQ.amount}</td>
          <td className="text-right">{formatCurrency(overviewByYearLegendData.paidCurrentQ.value)}</td>
          <td className="text-right">
            <Link
              className={styles.icon}
              to={{
                pathname: routes.invoices,
                search: qs({
                  [SearchParamKeys.INVOICE_FILTER_YEAR]: year,
                  [SearchParamKeys.INVOICE_FILTER_STATUS]: InvoiceStatus.FC_PAID,
                  [SearchParamKeys.INVOICE_FILTER_QUARTER]: dayjs().quarter(),
                }),
              }}
            >
              <Icon inline name="Visibility" />
            </Link>
          </td>
        </tr>
        <tr>
          <td>
            <div className={classNames(styles.color, 'bg-primary-500')} />
            {t('dashboard:charts.labels.previousQ')}
          </td>
          <td className="text-right">{overviewByYearLegendData.paidPreviousQ.amount}</td>
          <td className="text-right">{formatCurrency(overviewByYearLegendData.paidPreviousQ.value)}</td>
          <td className="text-right"></td>
        </tr>
      </tbody>
    </table>
  );
};
