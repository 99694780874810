import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import { styles } from './LabelDescriptionGroup.styles';

type Props = {
  children: ReactNode;
};

export const LabelDescriptionGroup = ({ children }: Props) => {
  return <div {...stylex.props(styles.group)}>{children}</div>;
};
