import { useBillitAuthorizationUrl } from '~/api/user';
import billitImage from '~/assets/images/partners/billit.png';
import cashacaImage from '~/assets/images/partners/cashaca.png';
import { useModal } from '~/hooks/useModal';

import { CashacaModal } from './CashacaModal';
import { ExternalSystemCard } from './ExternalSystemCard';
import styles from './ExternalSystems.module.scss';

export const ExternalSystems = () => {
  const mutation = useBillitAuthorizationUrl();
  const cashacaModal = useModal();

  const pairBillit = () => {
    mutation.mutate(undefined, {
      onSuccess: (authorizationUrl) => {
        location.href = authorizationUrl;
      },
    });
  };

  return (
    <>
      <ul className={styles.systems}>
        <ExternalSystemCard disabled={mutation.isPending} imageUrl={billitImage} onPair={pairBillit} system="billit" />
        <ExternalSystemCard imageUrl={cashacaImage} onPair={cashacaModal.open} system="cashaca" />
      </ul>

      {cashacaModal.isOpen && <CashacaModal onClose={cashacaModal.close} />}
    </>
  );
};
