import type { Quotation } from '~/api/quotations/types';
import type { Invoice } from '~/types/invoice';

export type StatsSummaryProps = {
  invoices: Invoice[];
  quotations: Quotation[];
};

export enum Stats {
  PENDING_QUOTATIONS = 'pendingQuotations',
  PENDING_INVOICES = 'pendingInvoices',
  OVERDUE_INVOICES = 'overdueInvoices',
}
