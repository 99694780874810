import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PlanId } from '~/api/plans/constants';
import { useUpdateAppSettings, useUser } from '~/api/user';
import { Icon } from '~/components/SVG';
import { ColorPicker } from '~/components/UI';
import { useActiveColorTheme } from '~/hooks/useAppColorTheme';

import { DropdownMenu } from '../../DropdownMenu/DropdownMenu';
import { useSettingsMenuItems } from '../hooks';
import { InviteeDiscountCountdown } from './InviteeDiscountCountdown';
import styles from './TopBar.module.scss';

export const TopBar = () => {
  const { data: user } = useUser();
  const mutation = useUpdateAppSettings();

  const settingsMenuItems = useSettingsMenuItems();

  const navigate = useNavigate();
  const { t } = useTranslation(['common']);

  const colorTheme = useActiveColorTheme();

  return (
    <div className={styles.TopBarWrapper}>
      <div className={styles.TopBar}>
        <InviteeDiscountCountdown discount={user.inviteeDiscount} />

        <a className={styles.Faq} href="https://knowledge.creativeshelter.be/" rel="noreferrer" target="_blank">
          <Icon name="Help" size={30} />
        </a>

        <ColorPicker
          onSelect={(colorTheme) => mutation.mutate({ appSettings: { theme: { ...user.appSettings.theme, appColor: colorTheme } } })}
          value={colorTheme}
        />

        <div className={styles.Profile}>
          <span>
            <strong>{user.firstName}</strong>
            <br />
            {t(`common:plan.${user.planId}`)} {user.planId !== PlanId.earlyAdopter && t('common:topbar.user')}
          </span>

          <div>
            <DropdownMenu
              dropdownClass={styles.SettingsDropdown}
              dropdownType="navigation"
              iconName="KeyboardArrowDown"
              items={settingsMenuItems.map((item) => ({
                iconName: item.iconName,
                text: item.text,
                onClick: typeof item.route === 'string' ? () => navigate(item.route) : item.onClick,
                'data-pf-id': item['data-pf-id'],
              }))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
