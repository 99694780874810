import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { quotationsQueryOptions } from './useQuotations';

export const useDeleteQuotation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request(`/quotations/${id}`, { method: 'DELETE', token });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(quotationsQueryOptions);
    },
  });
};
