import { useTranslation } from 'react-i18next';

import { Button } from '~/components';
import { useModal } from '~/hooks/useModal';

import profileStyles from '../Profile.module.scss';
import { BrandsTable } from './BrandsTable';
import { CreateBrandModal } from './CreateEditBrandModal/CreateBrandModal';

export const Brands = () => {
  const { t } = useTranslation(['settings']);

  const createBrandModal = useModal();

  return (
    <>
      <BrandsTable />

      <div className={profileStyles['button-group']}>
        <Button icon="Add" onClick={createBrandModal.open}>
          {t('settings:profile.brands.add')}
        </Button>
      </div>

      {createBrandModal.isOpen && <CreateBrandModal onClose={createBrandModal.close} />}
    </>
  );
};
