import { DialogPanel } from '@headlessui/react';
import classNames from 'classnames';

import { noop } from '~/utils/functions';

import type { ModalProps as Props } from './types';

import styles from './Modal.module.scss';
import { ModalActions } from './ModalActions';
import { ModalCloseButton } from './ModalCloseButton';
import { ModalContainer } from './ModalContainer';
import { ModalTitle } from './ModalTitle';

export const Modal = ({ children, isOpen = true, modal = false, onClose = noop, variant = null }: Props) => {
  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      <DialogPanel className={classNames(styles.panel, variant === 'wide' && styles.wide, variant === 'preview' && styles.preview)}>
        {!modal && <ModalCloseButton onClose={onClose} />}

        {children}
      </DialogPanel>
    </ModalContainer>
  );
};

Modal.Title = ModalTitle;
Modal.Actions = ModalActions;
