import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useAdminLogin } from '~/api/auth';
import { ErrorScreen, LoadingScreen } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

export const AdminLogin = () => {
  const { isError, mutate: loginAsAdmin } = useAdminLogin();

  const { activationKey } = useParams<Params<typeof routes.adminLogin>>();
  const navigate = useNavigate();

  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (!activationKey) return;

    loginAsAdmin({ activationKey }, { onSuccess: () => navigate(routes.dashboard, { replace: true }) });
  }, [activationKey, loginAsAdmin, navigate]);

  if (isError) return <ErrorScreen>{t('common:errors.unknown')}</ErrorScreen>;

  return <LoadingScreen />;
};
