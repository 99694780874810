import type { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

export const FormFieldContext = createContext<{
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
} | null>(null);

export const useFormFieldContext = () => {
  const context = useContext(FormFieldContext);
  invariant(context, 'Form components must be wrapped in <FormField />');

  return context;
};
