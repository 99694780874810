import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { DraftQuotation, FinalQuotation } from '~/api/quotations/types';

import { useClients } from '~/api/clients';
import { useCreateQuotation, useQuotations } from '~/api/quotations';
import { useUser } from '~/api/user';
import { Button, CollapsibleSection, FloatingActionButton, PageHeader } from '~/components';
import { Carousel } from '~/components/Templates/InvoiceQuotation';
import { useModalWithData } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { DeleteDraftQuotationModal } from './Modals/DeleteDraftQuotationModal';
import { QuotationsTable } from './QuotationsTable';

export const QuotationsOverview = () => {
  const { data: user } = useUser();
  const { data: clients } = useClients();
  const { data: quotations } = useQuotations();

  const createQuotationMutation = useCreateQuotation();

  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'quotations']);

  const draftDeleteModal = useModalWithData<DraftQuotation | FinalQuotation>();

  const draftQuotations = useMemo(
    () => quotations.filter((quotation): quotation is DraftQuotation | FinalQuotation => !quotation.allowed),
    [quotations],
  );

  const createDraftQuotation = () => {
    createQuotationMutation.mutate(undefined, {
      onSuccess: ({ id }) => navigate(r(routes.createQuotation, { quotationId: id })),
      onError: () => toast.error(t('common:error')),
    });
  };

  return (
    <main className="quotations">
      <PageHeader description={t('quotations:header.description')} title={t('quotations:header.title')}>
        <Button
          data-pf-id="quotations-header-create-quotation"
          hasSpinner
          icon="Description"
          isLoading={createQuotationMutation.isPending}
          onClick={createDraftQuotation}
        >
          {t('quotations:header.actionBlock.button')}
        </Button>
      </PageHeader>

      {/* Drafts */}
      {draftQuotations.length > 0 && (
        <CollapsibleSection title={t('common:draft.overviewTitle')}>
          <Carousel
            slides={draftQuotations.map((quotation) => ({
              id: quotation.id,
              title: quotation.title,
              brandColor: user.brands.find((brand) => brand.id === quotation.brandId)?.color ?? null,
              client: clients.find((client) => client.id === quotation.clientId) ?? null,
              contact:
                clients
                  .filter(({ deleted }) => !deleted)
                  .flatMap(({ contacts }) => contacts)
                  .filter(({ deleted }) => !deleted)
                  .find(({ id }) => id === quotation.contactId) ?? null,
              editAction: (quotationId) => navigate(r(routes.createQuotation, { quotationId })),
              deleteAction: () => draftDeleteModal.open(quotation),
            }))}
          />
        </CollapsibleSection>
      )}

      {/* Quotations */}
      <CollapsibleSection title={t('quotations:overview.created.subtitle')}>
        <QuotationsTable />
      </CollapsibleSection>

      {/* Delete draft modal */}
      {draftDeleteModal.isOpen && <DeleteDraftQuotationModal onClose={draftDeleteModal.close} quotation={draftDeleteModal.data} />}

      {/* FAB */}
      <FloatingActionButton options={[{ text: t('quotations:header.actionBlock.buttonMobile'), onClick: createDraftQuotation }]} />
    </main>
  );
};
