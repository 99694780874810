import { Dialog, DialogPanel } from '@headlessui/react';
import classNames from 'classnames';

import { Icon } from '~/components/SVG';

import type { StatusModalProps as Props } from './types';

import { ModalCloseButton } from '../ModalCloseButton';
import { ModalContainer } from '../ModalContainer';
import styles from './StatusModal.module.scss';
import { getIconName } from './utils';

const StatusModal = ({ buttonText, children, isOpen = true, onClose, status, title }: Props) => {
  return (
    <ModalContainer isOpen={isOpen} onClose={onClose}>
      <DialogPanel className={styles.panel}>
        <div className={styles.header}>
          <div className={classNames(styles.icon, styles[`icon--${status}`])}>
            <Icon name={getIconName(status)} size={40} />
          </div>

          <ModalCloseButton onClose={onClose} />
        </div>

        <Dialog.Title className={styles.title}>{title}</Dialog.Title>

        {children}

        <button className={classNames(styles.action, styles[`action--${status}`])} onClick={() => onClose()}>
          {buttonText}
        </button>
      </DialogPanel>
    </ModalContainer>
  );
};

export const SuccessModal = (props: Omit<Props, 'status'>) => <StatusModal status="success" {...props} />;
export const InfoModal = (props: Omit<Props, 'status'>) => <StatusModal status="info" {...props} />;
export const WarningModal = (props: Omit<Props, 'status'>) => <StatusModal status="warning" {...props} />;
export const ErrorModal = (props: Omit<Props, 'status'>) => <StatusModal status="error" {...props} />;
