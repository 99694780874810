import { useFormContext } from 'react-hook-form';
import invariant from 'tiny-invariant';

import type { InvoiceQuotationFormType } from '~/types/invoiceQuotation';

export const useInvoiceQuotationFormContext = () => {
  const methods = useFormContext<InvoiceQuotationFormType>();

  invariant(methods, 'useInvoiceQuotationFormContext hook must be called inside a FormProvider');

  return methods;
};
