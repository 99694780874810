import type { FormLabelProps as Props } from './types';

import { useFormControlContext } from '../context';
import { Label } from './Label';

export const FormLabel = ({ children, optional, tooltip }: Props) => {
  const { id } = useFormControlContext();

  return (
    <Label id={id} optional={optional} tooltip={tooltip}>
      {children}
    </Label>
  );
};
