import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Icon } from '~/components/SVG';
import { springTransition } from '~/utils/motion';

import type { FloatingActionButtonProps as Props } from './types';

import styles from './FloatingActionButton.module.scss';
import { FABItemsVariants, fabListVariants, rotateFABVariants } from './motion';

export const FloatingActionButton = ({ icon, isMultiple, options }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const fab = useRef<HTMLButtonElement>(null);
  const fabOptions = useRef<HTMLUListElement>(null);

  /**
   * Handles the click in the floating action button
   */
  const handleFabClick = () => {
    if (options.length > 1) {
      setIsOpen(!isOpen);
    } else {
      options[0].onClick();
    }
  };

  /**
   * Handles the click outside action for the Floating Action Button
   */
  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        fab.current &&
        !fab.current.contains(event.target as HTMLButtonElement) &&
        fabOptions.current &&
        !fabOptions.current.contains(event.target as HTMLDivElement)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const memoizedOptions = useMemo(
    () =>
      options.map(({ disabled, icon, onClick, text }, index) => (
        <motion.li className={styles.option} custom={index} key={index} variants={FABItemsVariants}>
          <button className="tag" disabled={disabled ?? false} onClick={onClick}>
            {icon && <Icon inline name={icon} size={14} />}
            {text}
          </button>
        </motion.li>
      )),
    [options],
  );

  if (!isMultiple) {
    return (
      <>
        <motion.button
          animate={!icon && (isOpen ? 'open' : 'closed')}
          className={styles.fab}
          onClick={handleFabClick}
          ref={fab}
          transition={springTransition}
          variants={rotateFABVariants}
        >
          <Icon name={icon || 'Close'} />
        </motion.button>

        <motion.ul
          animate={isOpen ? 'visible' : 'hidden'}
          className={styles.options}
          initial="false"
          ref={fabOptions}
          variants={fabListVariants}
        >
          {memoizedOptions}
        </motion.ul>
      </>
    );
  }

  return (
    <>
      {options.map((option, i) => (
        <button
          className={classNames(styles.fab, styles[option.style || 'primary'])}
          key={`${option.text}-${i}`}
          onClick={option.onClick}
          ref={fab}
          style={{ right: `${1 + i * 4}rem` }}
        >
          <Icon name={option.icon || 'Close'} />
        </button>
      ))}
    </>
  );
};
