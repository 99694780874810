import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { CreateContactPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { contactSchema } from './schemas';
import { clientsQueryOptions } from './useClients';

export const useCreateContact = (clientId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateContactPayload) => {
      const token = getAccessToken();
      const response = await request(`/clients/${clientId}/contacts`, { method: 'POST', token, body });

      const { data: contact } = apiResourceSchema(contactSchema).parse(response);

      return contact;
    },
    onSuccess: (contact) => {
      queryClient.setQueryData(clientsQueryOptions.queryKey, (clients) =>
        (clients ?? []).map((client) => (client.id === clientId ? { ...client, contacts: [contact, ...client.contacts] } : client)),
      );
      queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};
