export const lines = {
  actions: {
    applyDiscount: 'Pas korting toe',
    removeDiscount: 'Verwijder korting',
  },
  benefit: 'Voordeel',
  creativeWork: {
    header: 'Soort prestatie',
    options: {
      '0': 'Geen creatieve prestatie',
      '1': 'Creatieve prestatie',
      '2': 'Computerprogramma prestatie',
      '3': 'Hergebruik',
      '4': 'Onderaanneming',
      CreativeComputer: 'Computerprogramma prestatie',
      CreativeGeneral: 'Creatieve prestatie',
      CreativeReuse: 'Hergebruik',
      CreativeSubcontracting: 'Onderaanneming',
      NonCreative: 'Geen creatieve prestatie',
    },
    placeholder: 'Creatief werk?',
  },
  description: {
    header: 'Beschrijving',
    placeholder: 'Beschrijving',
    placeholderProduct: 'Extra informatie',
  },
  discount: {
    free: 'Gratis',
    header: 'Korting',
  },
  discountType: {
    flatRate: '€',
    percentage: '%',
  },
  lineTotal: {
    header: 'Totaal',
  },
  modal: {
    title: 'Factuurlijn',
  },
  new: {
    invoiceLine: 'Voeg factuurlijn toe',
    photoAlbum: 'Voeg fotoalbum toe',
    photoAlbumLine: 'Voeg fotoalbum lijn toe',
    product: 'Voeg product toe',
    productTooltip:
      'Ontdek <a href="https://knowledge.creativeshelter.be/producten-wat-zijn-ze-en-hoe-gebruik-je-ze" target="_blank">hier</a> wat producten zijn!',
    quotationLine: 'Voeg offertelijn toe',
    textLine: 'Voeg tekstregel toe',
  },
  photoAlbum: {
    deleteModal: {
      description: "Weet je zeker dat je de sectie 'fotoalbum' wilt verwijderen? Dit verwijdert ook alle lijnen in deze sectie.",
      title: 'Fotoalbum verwijderen',
    },
    description: 'Voeg hier alle factuurlijnen toe die betrekking hebben op het fotoalbum.',
    tag: '6% btw',
    tagTooltip: 'Alle lijnen onder de sectie fotoalbum worden gefactureerd aan 6% btw.',
    title: 'Fotoalbum',
  },
  price: {
    header: 'Prijs',
    placeholder: 'Prijs',
  },
  productName: {
    label: 'Product',
    tooltip:
      'Deze lijn is op basis van een product. Wijzigingen kunnen, deze worden niet bewaard in je product, maar alleen op je factuur.',
  },
  quantity: {
    header: 'Aantal',
    placeholder: 'Aantal',
  },
  subtotal: 'Subtotaal',
  textLine: 'Tekstregel',
  total: 'Totaal',
  totalVat: 'Totaal btw',
  units: {
    characters_one: 'teken',
    characters_other: 'tekens',
    days_one: 'dag',
    days_other: 'dagen',
    halfDays_one: 'halve dag',
    halfDays_other: 'halve dagen',
    hours_one: 'uur',
    hours_other: 'uren',
    kilometers: 'km',
    none: '—',
    placeholder: 'Selecteer',
    words_one: 'woord',
    words_other: 'woorden',
  },
  vatExported: 'Export',
  vatPercentage: 'Btw ({{ percentage }}%)',
  vatShifted: 'Btw verlegd',
  vatTooltip:
    'Op de factuur naar je klant rekenen we altijd btw, ongeacht je btw-status, omdat Creative Shelter een btw-plichtige onderneming is. <a href="https://knowledge.creativeshelter.be/wat-is-de-btw-vrijstellingsregeling-en-bestaan-er-nog-andere-regelingen" target="_blank">Lees er hier meer over!</a>',
};
