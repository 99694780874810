// Same as Done
import type { SVGProps } from 'react';

export const Check = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} viewBox="0 -960 960 960" xmlns="http://www.w3.org/2000/svg">
      <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
    </svg>
  );
};
