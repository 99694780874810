import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const Coffee1 = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      viewBox="879.2 426.65 181.05 254.32"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <g>
        <path
          {...stylex.props(styles.stroke, styles.fillSecondary)}
          d="M1001.05,585.52v45.6c0,7.51,6.09,13.59,13.59,13.59h24.4c4.13,0,7.83-1.84,10.32-4.75-23.36-5.37-36.53-26.19-41.12-60.04h-1.57c-3.1,0-5.61,2.51-5.61,5.61Z"
        ></path>
        <path
          {...stylex.props(styles.stroke, styles.fillWhite)}
          d="M1008.24,579.91c4.59,33.85,17.76,54.68,41.12,60.04,2.03-2.38,3.27-5.46,3.27-8.83v-45.6c0-3.1-2.51-5.61-5.61-5.61h-38.77Z"
        ></path>
        <path
          {...stylex.props(styles.stroke, styles.fillPrimary)}
          d="M985.33,597.18v21.5c0,2.34,1.89,4.23,4.23,4.23h11.49s0-9.98,0-9.98h-3.7c-1.12,0-2.04-.91-2.04-2.04v-5.91c0-1.12.91-2.04,2.04-2.04h3.7s0-9.98,0-9.98h-11.49c-2.34,0-4.23,1.89-4.23,4.23Z"
        ></path>
      </g>
      <g>
        <path
          {...stylex.props(styles.stroke, styles.fillWhite)}
          d="M1035.77,471.07c-3.07,5.35-14.48,13.9-14.48,13.9,0,0,9.21,5.48,6.36,11.62-2.85,6.14-6.8,6.36-6.8,6.36,0,0,8.55,4.31,6.36,9.28-2.19,4.97-8.24,7.16-8.24,7.16,0,0,9.99.13,6.92,8.99-3.07,8.86-11.62,8.86-10.75,17.41.55,5.39,10,13.33,10.3,13.58-.5-.21-23.9-10.11-28.58-28.02-4.74-18.1,10.39-24.68,17.19-21.61-.3-.29-21.19-20.93-17.19-34.65,4.03-13.82,17.19-6.8,17.19-6.8,0,0-17.46-18.05-7.24-35.09,7.9-13.16,18.64-2.41,18.64-2.41,0,0,13.82-6.8,16.23,3.07,2.41,9.87-10.75,26.54-10.75,26.54,0,0,7.9,5.31,4.83,10.66Z"
        ></path>
        <line {...stylex.props(styles.stroke, styles.fillWhite)} x1="1025.46" x2="1018.98" y1="430.81" y2="434.76"></line>
      </g>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M936.6,643.87s-8.86-7.68-22.72-4.9c-13.86,2.78-13.36,9.83-13.36,9.83,0,0-20.69.49-21.31,8.49-.62,8,30.96,2.39,30.96,2.39,0,0-6.37,10.01,6.15,10.97,12.53.97,41.03-10.13,41.03-10.13,0,0,.69,9.15,15.76,8.92,15.07-.24,27.01-9.81,27.01-9.81,0,0,9.55,12.29,28.66,9.56,19.11-2.72,31-16.16,31.45-22.07.46-5.91-8.75-4.53-8.75-4.53,0,0,6.99-13.46-10.06-14.78-17.05-1.32-24.06,12.49-24.06,12.49,0,0-4.28-8.03-15.73-9.26s-15.73,4.04-15.73,4.04c0,0-11.37-11.08-28.85-6.78-17.48,4.31-20.45,15.57-20.45,15.57Z"
      ></path>
    </svg>
  );
};
