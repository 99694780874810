import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import { getAccessToken } from '~/utils/auth';

import type { UpdateNpsPayload } from './types';

import { request } from '../request';

export const useUpdateNps = () => {
  return useMutation({
    mutationFn: async (body: UpdateNpsPayload) => {
      const token = getAccessToken();
      const response = await request('/nps', { method: 'PATCH', token, body });

      // TODO: Schema validation
      const { nps } = z.object({ nps: z.unknown() }).parse(response);

      return nps as { id: number };
    },
  });
};
