import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import type { Language } from '~/types/app';
import type { Activity, User } from '~/types/user';

import type { ColorTheme } from '../user/types';

import { apiResourceSchema } from '../common/schemas';
import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';

type Payload = {
  colorCombo: ColorTheme;
  emailAddress: string;
  language: Language;
  mainOccupation: boolean;
  otherActivityInformation: string;
  password: string;
  phoneNumber: string;
  primaryActivity: Activity;
  referralCode: string | null;
  secondaryActivities: Activity[];
  vatNumber: string;
};

export const useRegister = () => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const response = await request('/registration/create-user-account', { method: 'POST', body });

      // TODO: Schema validation
      const { data: user } = apiResourceSchema(z.unknown()).parse(response);

      return user as User;
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) && captureException(new Error('Failed to register new user', { cause: error }), { level: 'fatal' }),
  });
};
