import type { Row } from '@tanstack/react-table';

import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import { Fragment, useState } from 'react';

import { Icon } from '~/components/SVG';
import { ColumnIds } from '~/constants/table';
import { useScreenWidth } from '~/hooks/useScreenWidth';

import type { TableRowProps as Props } from './types';

import { AnimateExpand } from '../../AnimateExpand/AnimateExpand';
import { useTableContext } from '../context';
import styles from './TableRow.module.scss';

const alignmentStyles = {
  start: styles.Start,
  center: styles.Center,
  end: styles.End,
  stretch: styles.Stretch,
};

export const TableRow = <TData,>({ onClick, row }: Props<TData>) => {
  const [showMobileList, setShowMobileList] = useState(false);

  const screen = useScreenWidth();

  return (
    <>
      <div
        className={classNames(styles.TableRow, !!onClick && styles.Clickable)}
        key={row.id}
        onClick={() => (screen.isLarge ? onClick?.(row.original) : setShowMobileList((prev) => !prev))}
      >
        {row.getVisibleCells().map((cell) => {
          const { meta } = cell.column.columnDef;
          const hidden =
            (!meta?.mobileStyles && screen.isSmallerThan('lg')) ||
            (!!meta?.mobileStyles?.minBreakpoint && screen.isSmallerThan(meta.mobileStyles.minBreakpoint));

          return (
            <div
              className={classNames(
                styles.TableCell,
                screen.isLarge && alignmentStyles[meta?.styles.justify ?? 'start'],
                screen.isSmallerThan('lg') && !hidden && alignmentStyles[meta?.mobileStyles?.justify ?? 'start'],
                hidden && styles.Hidden,
                cell.column.id === ColumnIds.options && styles.OptionsCell,
              )}
              key={cell.id}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </div>
          );
        })}

        <div className={classNames(styles.TableCell, styles.MobileOnly)}>
          <button
            className={classNames(styles.MobileToggle, showMobileList && styles.Expanded)}
            onClick={(e) => {
              e.stopPropagation();
              setShowMobileList((prev) => !prev);
            }}
          >
            <Icon name="KeyboardArrowDown" />
          </button>
        </div>
      </div>

      <div className={styles.MobileListWrapper}>
        <AnimateExpand>{showMobileList && <TableMobileList row={row} />}</AnimateExpand>
      </div>
    </>
  );
};

const TableMobileList = <TData,>({ row }: { row: Row<TData> }) => {
  const { getHeaderGroups } = useTableContext<TData>();
  const screen = useScreenWidth();

  const mobileListCells = row
    .getVisibleCells()
    .filter((cell) => cell.column.columnDef.id !== ColumnIds.selector)
    .filter(
      (cell) =>
        !cell.column.columnDef.meta?.mobileStyles ||
        (cell.column.columnDef.meta.mobileStyles.minBreakpoint &&
          screen.isSmallerThan(cell.column.columnDef.meta.mobileStyles.minBreakpoint)),
    );
  const mobileListHeaders = getHeaderGroups()
    .flatMap(({ headers }) => headers)
    .filter((header) => header.column.columnDef.id !== ColumnIds.selector)
    .filter(
      (header) =>
        !header.column.columnDef.meta?.mobileStyles ||
        (header.column.columnDef.meta.mobileStyles.minBreakpoint &&
          screen.isSmallerThan(header.column.columnDef.meta.mobileStyles.minBreakpoint)),
    );

  return (
    <div className={styles.MobileList}>
      {mobileListCells.map((cell, i) => {
        const header = mobileListHeaders[i];

        return (
          <Fragment key={cell.id}>
            <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
            <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
          </Fragment>
        );
      })}
    </div>
  );
};
