import * as stylex from '@stylexjs/stylex';

import { brandColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  // Prevent stroke from being clipped
  svg: {
    padding: 2,
    overflow: 'visible',
  },

  stroke: {
    strokeWidth: '2px',
    stroke: 'black',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },

  fillPrimary: {
    fill: brandColors.primary500,
  },

  fillSecondary: {
    fill: brandColors.secondary500,
  },

  fillBlack: {
    fill: 'black',
  },

  fillWhite: {
    fill: 'white',
  },
});
