import type { IconName } from '~/components/SVG';
import type { PaymentFollowUpStatusType } from '~/types/invoice';

export const getPaymentFollowUpIcon = (type: PaymentFollowUpStatusType) =>
  (
    ({
      FollowUpStarted: 'Flag',
      FollowUpManualReminder: 'NotificationImportant',
      FollowUpLastManualReminder: 'NotificationImportant',
      FollowUpContactClientByPhone: 'Call',
      FollowUpFormalNoticeSent: 'Gavel',
      FollowUpInvoiceSentToLawyer: 'Gavel',
      FollowUpInvoiceSentToBailiff: 'Gavel',
      FollowUpRemark: 'Comment',
    }) as const satisfies Record<PaymentFollowUpStatusType, IconName>
  )[type];
