import * as stylex from '@stylexjs/stylex';

import { lineHeight } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  span: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    color: 'inherit',
  },

  noLineHeight: {
    lineHeight: lineHeight.none,
  },
});
