import type { NumberingResetInterval, NumberingSeparator, NumberingSeries, NumberingYearMonthFormat } from '~/types/user';

export type NumberingSeriesTableProps = {
  expandedNumberingSeries: { id: number; section: NumberingSeriesFormSection } | null;
  setExpandedNumberingSeries: (expandedNumberingSeries: { id: number; section: NumberingSeriesFormSection } | null) => void;
};

export type CreateNumberingSeriesModalProps = {
  onClose: () => void;
  onCreate: (id: number) => void;
};

export type CreateNumberingSeriesFormType = {
  name: string;
  type: NumberingSeriesType;
};

export type EditNumberingSeriesFormProps = {
  numberingSeries: NumberingSeries;
  onClose: () => void;
  section: NumberingSeriesFormSection;
};

export type EditNumberingSeriesFormType = {
  format: {
    amountOfDigits: 0 | 1 | 2 | 3 | 4 | 5 | 6 | null;
    prefixSeparator: NumberingSeparator | null;
    prefixText: string | null;
    prefixYearMonth: NumberingYearMonthFormat | null;
    suffixSeparator: NumberingSeparator | null;
    suffixText: string | null;
    suffixYearMonth: NumberingYearMonthFormat | null;
  };
  name: string;
  nextNumber: number;
  resetInterval: NumberingResetInterval;
  type: NumberingSeriesType;
};

export type DeleteNumberingSeriesModalProps = {
  numberingSeries: NumberingSeries;
  onClose: () => void;
};

export enum NumberingSeriesType {
  Invoices = 'invoices',
  CreditNotes = 'credit_notes',
  Both = 'both',
}

export enum NumberingSeriesFormSection {
  NAME_AND_TYPE = 'name_and_type',
  FORMAT_AND_OPTIONS = 'format_and_options',
}
