import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AnimateExpand } from '~/components';
import { Icon } from '~/components/SVG';
import { useIntl } from '~/hooks/useIntl';

import type { PaymentFollowUpDetailProps, PaymentFollowUpDetailsProps } from './types';

import styles from './PaymentFollowUpDetails.module.scss';
import { getPaymentFollowUpIcon } from './utils';

export const PaymentFollowUpDetails = ({ paymentFollowUp }: PaymentFollowUpDetailsProps) => {
  const [openPaymentFollowUp, setOpenPaymentFollowUp] = useState<number | null>(paymentFollowUp[0].id);

  return (
    <div className={styles.AccordionWrapper}>
      {paymentFollowUp.map((paymentFollowUp) => (
        <PaymentFollowUpDetail
          isOpen={openPaymentFollowUp === paymentFollowUp.id}
          key={paymentFollowUp.id}
          paymentFollowUp={paymentFollowUp}
          toggleOpen={() => setOpenPaymentFollowUp((id) => (id === paymentFollowUp.id ? null : paymentFollowUp.id))}
        />
      ))}
    </div>
  );
};

const PaymentFollowUpDetail = ({ isOpen, paymentFollowUp: { remark, timestamp, type }, toggleOpen }: PaymentFollowUpDetailProps) => {
  const { t } = useTranslation(['invoices']);
  const { formatDate } = useIntl();

  return (
    <article className={styles.AccordionItem}>
      <button className={styles.AccordionHeader} onClick={toggleOpen}>
        <div className={styles.HeaderIcon}>
          <Icon name={getPaymentFollowUpIcon(type)} />
        </div>

        <div>
          <div className={styles.Title}>
            {type === 'FollowUpRemark'
              ? t('invoices:details.paymentInfoDetail.remark.byAdmin', { name: remark?.causerName })
              : t(`invoices:details.paymentInfoDetail.types.${type}.title`)}
          </div>
          <div className={styles.Timestamp}>{formatDate(timestamp)}</div>
        </div>

        <div className={styles.ExpandIcon}>
          <Icon name={isOpen ? 'Remove' : 'Add'} size={20} />
        </div>
      </button>

      <AnimateExpand>
        {isOpen && (
          <div className={styles.AccordionBody}>
            {type !== 'FollowUpRemark' && (
              <p className={styles.Description}>
                <Trans i18nKey={`invoices:details.paymentInfoDetail.types.${type}.description`} />
              </p>
            )}

            {remark && (
              <div className={styles.Remark}>
                {type !== 'FollowUpRemark' && (
                  <p className={styles.RemarkTitle}>
                    {remark.causerType === 'user'
                      ? t('invoices:details.paymentInfoDetail.remark.byUser')
                      : t('invoices:details.paymentInfoDetail.remark.byAdmin', { name: remark.causerName })}
                  </p>
                )}
                <p className={styles.RemarkMessage}>{remark.message}</p>
              </div>
            )}
          </div>
        )}
      </AnimateExpand>
    </article>
  );
};
