import { Trans } from 'react-i18next';

import type { FieldsetProps as Props } from './types';

import styles from '../CreateCustomConditionModal/CreateCustomConditionModal.module.scss';

export const Fieldset = ({ children, step }: Props) => {
  return (
    <fieldset className={styles['wizard-content']}>
      <legend>
        <Trans components={{ a: <a /> }} i18nKey={`settings:general.customConditions.wizard.${step}.question`} />
      </legend>
      {children}
    </fieldset>
  );
};
