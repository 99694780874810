import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const ReferralTarget = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="Referral Target"
      id="Referral_Target"
      viewBox="414.05 206.02 371.9 387.95"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <g>
        <ellipse {...stylex.props(styles.stroke, styles.fillBlack)} cx="600.64" cy="582.53" rx="146.94" ry="10.44"></ellipse>
        <g>
          <g>
            <g>
              <path
                {...stylex.props(styles.stroke, styles.fillWhite)}
                d="M701.44,367.12c-21.49-59.33-65.26-100.69-108-107.15-12.07-1.83-24.05-.87-35.43,3.25-3.38,1.22-6.62,2.7-9.72,4.4-44.29,24.36-59.68,96.13-33.93,167.25,23.99,66.25,75.77,110.09,122.86,107.97,3.6-.16,7.17-.59,10.7-1.3h.02s9.85-2.76,9.85-2.76c.39-.14.78-.29,1.16-.43,9.63-3.69,18.12-9.41,25.37-16.79,30.98-31.53,39.29-93.22,17.12-154.43ZM679.54,466.9c-2.9,11.42-7.54,21.4-13.42,28.88-5.33,6.77-11.65,11.52-18.8,14.11-4.22,1.53-8.72,2.3-13.39,2.3-15.01,0-32.01-7.79-47.86-21.95-18.1-16.16-33.31-39.54-42.83-65.83-5.76-15.9-9.22-32.02-10.3-47.91-1.01-14.86.11-28.79,3.31-41.4,2.9-11.42,7.54-21.4,13.42-28.88,5.33-6.77,11.65-11.52,18.8-14.11,4.21-1.53,8.72-2.3,13.39-2.3,15.01,0,32.01,7.79,47.86,21.94,11.87,10.6,22.5,24.3,31.09,39.9,0,0,0,0,0,.01,0,0,0,0,0,.01,4.5,8.17,8.45,16.87,11.72,25.91,5.76,15.9,9.22,32.02,10.3,47.91.11,1.65.2,3.29.25,4.91,0,.01,0,.03,0,.04.48,13.01-.72,25.25-3.57,36.45Z"
              ></path>
              <path
                {...stylex.props(styles.stroke, styles.fillSecondary)}
                d="M647.92,541.53l-20.59,5.76h-.03s-9.48,2.66-9.48,2.66c-30.77,6.28-64.74-8.68-92.31-37.89-8.72-9.24-16.8-19.91-23.93-31.79-6.78-11.29-12.71-23.67-17.52-36.95-13.11-36.21-15.56-72.59-8.95-102.8,5.65-25.83,17.92-47.15,35.8-59.97,4.05-2.91,8.4-5.38,13.02-7.37,1.24-.54,2.5-1.04,3.79-1.5,2.32-.84,4.67-1.55,7.05-2.13h0c.95-.23,1.9-.44,2.86-.64h0l20.38-5.69c-3.38,1.22-6.62,2.7-9.72,4.4-4.68,2.57-9.04,5.67-13.06,9.25-17.88,15.9-29.09,41.16-32.37,70.77-2.96,26.74.56,57.04,11.49,87.22,23.99,66.25,75.77,110.09,122.86,107.97,3.6-.16,7.17-.59,10.7-1.3Z"
              ></path>
              <path
                {...stylex.props(styles.stroke, styles.fillPrimary)}
                d="M683.11,430.45s0-.03,0-.04c-.06-1.63-.14-3.27-.25-4.91-1.08-15.89-4.54-32.01-10.3-47.91-3.27-9.04-7.22-17.73-11.72-25.91,0,0,0-.01,0-.01,0,0,0,0,0-.01-8.59-15.59-19.22-29.3-31.09-39.9-15.85-14.15-32.85-21.94-47.86-21.94-4.67,0-9.17.77-13.39,2.3-7.15,2.59-13.47,7.34-18.8,14.11-5.88,7.47-10.52,17.46-13.42,28.88-3.21,12.61-4.32,26.54-3.31,41.4,1.08,15.89,4.55,32.01,10.3,47.91,9.52,26.3,24.73,49.67,42.83,65.83,15.85,14.15,32.85,21.95,47.86,21.95,4.67,0,9.17-.77,13.39-2.3,7.15-2.59,13.47-7.34,18.8-14.11,5.87-7.47,10.51-17.46,13.42-28.88,2.85-11.2,4.04-23.44,3.57-36.45ZM634.68,474.97c-19.75,7.15-47.75-20.17-62.55-61.02-14.8-40.85-10.77-79.77,8.98-86.92,19.75-7.15,47.76,20.17,62.55,61.02,14.79,40.85,10.77,79.77-8.98,86.92Z"
              ></path>
              <path
                {...stylex.props(styles.stroke, styles.fillWhite)}
                d="M618.76,431c-8.01,2.9-19.37-8.18-25.37-24.75-6-16.57-4.37-32.35,3.64-35.25,8.01-2.9,19.37,8.18,25.37,24.75,6,16.57,4.37,32.35-3.64,35.25ZM643.66,388.05c-14.79-40.85-42.8-68.17-62.55-61.02-19.75,7.15-23.77,46.07-8.98,86.92,14.79,40.85,42.8,68.17,62.55,61.02,19.75-7.15,23.77-46.07,8.98-86.92Z"
              ></path>
              <path
                {...stylex.props(styles.stroke, styles.fillPrimary)}
                d="M618.76,431c-8.01,2.9-19.37-8.18-25.37-24.75-6-16.57-4.37-32.35,3.64-35.25,8.01-2.9,19.37,8.18,25.37,24.75,6,16.57,4.37,32.35-3.64,35.25Z"
              ></path>
            </g>
            <g>
              <g>
                <path
                  {...stylex.props(styles.fillBlack)}
                  d="M527.43,428.49v1.76l-6.23,1.55v-1.04l2.94-4.12c.65-.93,1.08-1.51,1.08-2.04,0-.44-.3-.79-1.01-.61-.67.17-1.05.67-1.12,1.38l-2.07.52c.05-1.81,1.28-3.26,3.27-3.76,1.99-.5,3.07.42,3.07,1.94,0,.83-.48,1.66-1.51,3.09l-1.54,2.1,3.12-.78Z"
                ></path>
                <path
                  {...stylex.props(styles.fillBlack)}
                  d="M534.93,425.55c0,1.8-1.35,3.34-3.31,3.83-1.93.48-3.21-.3-3.4-1.91l2.04-.51c.17.56.63.82,1.4.63.76-.19,1.3-.8,1.3-1.53s-.56-1.03-1.45-.81l-2.84.71v-4.74l5.85-1.46v1.85l-3.9.97v1.21s1.19-.3,1.19-.3c1.96-.49,3.12.45,3.12,2.06Z"
                ></path>
              </g>
              <g>
                <path
                  {...stylex.props(styles.fillWhite)}
                  d="M555.97,419.64c0,1.8-1.35,3.34-3.31,3.83-1.93.48-3.21-.3-3.4-1.91l2.04-.51c.17.56.63.82,1.4.63.76-.19,1.3-.8,1.3-1.53s-.56-1.03-1.45-.81l-2.84.71v-4.74l5.85-1.46v1.85l-3.9.97v1.21s1.19-.3,1.19-.3c1.96-.49,3.12.45,3.12,2.06Z"
                ></path>
                <path
                  {...stylex.props(styles.fillWhite)}
                  d="M565.2,415.81c0,2.64-1.53,4.91-4.04,5.54-2.52.63-4.04-.88-4.04-3.52s1.53-4.91,4.04-5.54c2.52-.63,4.04.88,4.04,3.52ZM563.14,416.32c0-1.51-.69-2.47-1.99-2.15s-1.99,1.63-1.99,3.14.69,2.47,1.99,2.15,1.99-1.63,1.99-3.14Z"
                ></path>
              </g>
              <g>
                <path
                  {...stylex.props(styles.fillBlack)}
                  d="M581.8,406.33v1.22l-3.35,8.34-2.36.59,3.08-7.56-3.74.93v-1.94l6.37-1.59Z"
                ></path>
                <path
                  {...stylex.props(styles.fillBlack)}
                  d="M588.91,410.45c0,1.8-1.35,3.34-3.31,3.83-1.93.48-3.21-.3-3.4-1.91l2.04-.51c.17.56.63.82,1.4.63.76-.19,1.3-.8,1.3-1.53s-.56-1.03-1.45-.81l-2.84.71v-4.74l5.85-1.46v1.85l-3.9.97v1.21s1.19-.3,1.19-.3c1.96-.49,3.12.45,3.12,2.06Z"
                ></path>
              </g>
              <g>
                <path
                  {...stylex.props(styles.fillWhite)}
                  d="M603.17,407.08v1.76l-6.3,1.57v-1.76l2.18-.54v-4.65l-2.05,1.14v-1.96l2.85-1.7,1.3-.32v6.97l2.03-.51Z"
                ></path>
                <path
                  {...stylex.props(styles.fillWhite)}
                  d="M611.63,402.36c0,2.64-1.53,4.91-4.04,5.54-2.52.63-4.04-.88-4.04-3.52s1.53-4.91,4.04-5.54c2.52-.63,4.04.88,4.04,3.52ZM609.58,402.87c0-1.51-.69-2.47-1.99-2.15-1.3.32-1.99,1.63-1.99,3.14s.69,2.47,1.99,2.15c1.3-.32,1.99-1.63,1.99-3.14Z"
                ></path>
                <path
                  {...stylex.props(styles.fillWhite)}
                  d="M620.85,400.06c0,2.64-1.53,4.91-4.04,5.54-2.52.63-4.04-.88-4.04-3.52s1.53-4.91,4.04-5.54c2.52-.63,4.04.88,4.04,3.52ZM618.79,400.58c0-1.51-.69-2.47-1.99-2.15-1.3.32-1.99,1.63-1.99,3.14s.69,2.47,1.99,2.15c1.3-.32,1.99-1.63,1.99-3.14Z"
                ></path>
              </g>
              <path
                {...stylex.props(styles.fillBlack)}
                d="M632.99,394.77c0,1.53-1.13,2.86-2.62,3.23-1.5.37-2.64-.39-2.64-1.92s1.14-2.85,2.64-3.23c1.49-.37,2.62.4,2.62,1.91ZM631.58,395.13c0-.73-.56-1.08-1.21-.92-.67.17-1.22.79-1.22,1.52s.55,1.08,1.22.92c.64-.16,1.21-.79,1.21-1.52ZM631.59,401.45l-1.39.35,5.83-10.18,1.39-.35-5.83,10.18ZM640.05,396.93c0,1.53-1.13,2.86-2.63,3.24-1.49.37-2.63-.4-2.63-1.92s1.14-2.85,2.63-3.22c1.5-.37,2.63.4,2.63,1.91ZM638.64,397.28c0-.72-.56-1.07-1.22-.9-.65.16-1.21.79-1.21,1.51s.55,1.09,1.21.93c.65-.16,1.22-.79,1.22-1.54Z"
              ></path>
            </g>
          </g>
          <g>
            <g>
              <path
                {...stylex.props(styles.stroke, styles.fillWhite)}
                d="M525.51,512.07l-7.18,33.96h0s-24.5,10.03-24.5,10.03l7.75-75.77c7.13,11.88,15.21,22.54,23.93,31.79Z"
              ></path>
              <polygon
                {...stylex.props(styles.stroke, styles.fillBlack)}
                points="518.33 546.03 509.81 586.29 490.73 586.29 493.83 556.05 518.33 546.03"
              ></polygon>
            </g>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="570.54 532.92 599.82 591.8 630.73 591.8 610.67 545.62 570.54 532.92"
            ></polygon>
            <g>
              <path
                {...stylex.props(styles.stroke, styles.fillBlack)}
                d="M687.37,526.51l-22.7,21.29-5.71-9.46c9.63-3.69,18.12-9.41,25.37-16.79l3.05,4.96Z"
              ></path>
              <polygon
                {...stylex.props(styles.stroke, styles.fillWhite)}
                points="717.8 576.01 681.7 576.01 664.66 547.8 687.37 526.51 717.8 576.01"
              ></polygon>
            </g>
          </g>
        </g>
        <g>
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M593.44,259.97c-12.07-1.83-24.05-.87-35.43,3.25l-20.38,5.69h0c-.96.19-1.91.41-2.86.64,14.68-12.84,33.76-20.47,55.66-21.01l3.01,11.43Z"
          ></path>
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M590.43,248.54c-21.9.54-40.98,8.16-55.66,21.01h0c-2.37.58-4.72,1.29-7.05,2.13-1.28.46-2.54.96-3.79,1.5l-11.93-37.24,14.26,6.17,11.85,5.12,7.48-12.15,5.85-9.51,9.95,6.06,12.27,7.46,5.1-10.5,4.16-8.57,7.5,28.52Z"
          ></path>
          <polygon
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            points="578.77 228.59 573.67 239.09 561.4 231.63 568.3 222.43 578.77 228.59"
          ></polygon>
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M545.59,235.08l-7.48,12.15-11.85-5.12c2.19-3.99,6.32-12.1,6.32-12.1l13.01,5.07Z"
          ></path>
        </g>
      </g>
      <g>
        <path
          {...stylex.props(styles.stroke, styles.fillBlack)}
          d="M545.74,297.13s12.41,13.33,4.07,25c-8.33,11.67-24.63,7.41-24.63,7.41,0,0,3.15,15-12.41,17.96-3.39.64-6.74.6-9.92.15-11.39-1.64-20.45-8.66-20.45-8.66,0,0-2.84.67-7.3,1.55,5.65-25.83,17.92-47.15,35.8-59.97,1.8,1.42,2.8,2.32,2.8,2.32,0,0,4.99-9.3,21.52-6,2.85.57,6.04,1.51,9.61,2.94,18.69,7.48.9,17.33.9,17.33Z"
        ></path>
        <path
          {...stylex.props(styles.stroke, styles.fillWhite)}
          d="M728.47,493.89s-27.78-3.52-38.89,6.11c-11.11,9.63,20.37,12.18,20.37,12.18,0,0-18.89,4.49-8.89,11.52,10,7.04,38.52,2.22,38.52,2.22,0,0-.19,14.8,10.37,10.47,10.55-4.33,12.04-15.47,12.04-15.47,0,0,22.96.74,22.96-13.33s-31.66-7.96-31.66-7.96c0,0-.37-11.67-12.78-12.59-12.41-.93-12.04,6.85-12.04,6.85Z"
        ></path>
        <path
          {...stylex.props(styles.stroke, styles.fillWhite)}
          d="M643.11,225.57s-33.7,5.95-25.92,14.44,27.78,6.85,27.78,6.85c0,0,4.63,11.3,21.48,11.3s22.78-11.48,22.78-11.48c0,0,18.52,8.52,30.18,4.26s5.18-15.93,5.18-15.93c0,0,15.18-2.78,19.07-12.59,3.89-9.81-28.52-.93-28.52-.93,0,0-7.41-11.48-14.07-12.59s-6.85,11.11-6.85,11.11c0,0-5.56-13.7-13.33-12.96-7.78.74-10.18,10.55-10.18,10.55,0,0-6.11-11.67-19.81-8.52-13.7,3.15-7.78,16.48-7.78,16.48Z"
        ></path>
        <path
          {...stylex.props(styles.stroke, styles.fillWhite)}
          d="M438.49,310.2s-31.48,7.78-21.48,18.52c10,10.74,59.26-.93,59.26-.93,0,0,14.81,11.48,30.37,8.52,15.55-2.96,12.41-17.96,12.41-17.96,0,0,16.3,4.26,24.63-7.41,8.33-11.67-4.07-25-4.07-25,0,0,17.79-9.85-.9-17.32-24.28-9.71-31.13,3.07-31.13,3.07,0,0-22.59-20.49-47.59-19.63-25,.86-13.52,28.33-13.52,28.33,0,0-22.22-2.04-28.33,11.11-6.11,13.15,20.37,18.7,20.37,18.7Z"
        ></path>
      </g>
    </svg>
  );
};
