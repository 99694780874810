import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useClients } from '~/api/clients';
import { Button, CollapsibleSection, PageHeader } from '~/components';
import { Redirect } from '~/components/Functional';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import type { InnerClientDetailProps as Props } from './types';

import { OrganisationInfoCard } from '../shared/OrganisationInfoCard';
import { isClientEditable } from '../utils';
import { ContactsTable } from './ContactsTable';

export const ClientDetailGuard = () => {
  const { data: clients } = useClients();
  const { clientId } = useParams<Params<typeof routes.showClient>>();

  const client = clients
    .filter(({ deleted }) => !deleted)
    .filter((client) => client.clientType === 'organisation')
    .find((client) => client.id === Number(clientId));

  if (!client) return <Redirect to={routes.clients} />;

  return <ClientDetail client={client} />;
};

const ClientDetail = ({ client }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['clients', 'common']);

  return (
    <>
      <PageHeader backRoute={routes.clients} title={client.name}>
        <Button icon="PersonAdd" onClick={() => navigate(r(routes.createContact, { clientId: client.id }))}>
          {t('clients:detail.contacts.add')}
        </Button>
      </PageHeader>

      <div className="mb-12">
        <OrganisationInfoCard
          organisation={{
            companyName: client.name,
            vatNumber: client.vatNumber,
            otherNumber: client.otherNumber,
            address: client.addresses.find((address) => address.type === 'headquarters') ?? client.addresses[0],
          }}
          {...(isClientEditable(client)
            ? { onEdit: () => navigate({ pathname: r(routes.editClient, { clientId: client.id }) }) }
            : { tooltip: t('clients:kboOrganisationTooltip') })}
        />
      </div>

      <CollapsibleSection title={t('clients:detail.contacts.title')}>
        <ContactsTable client={client} />
      </CollapsibleSection>
    </>
  );
};
