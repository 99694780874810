import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

type Props = SvgProps & {
  arrow?: boolean;
  checklist?: boolean;
  cloud?: boolean;
  coin?: boolean;
  folder?: boolean;
  megaphone?: boolean;
  sheetOfPaper?: boolean;
  sparkles?: boolean;
  speechBalloon?: boolean;
};

export const CreateAccountSmall = ({
  arrow = true,
  checklist = true,
  cloud = true,
  coin = true,
  folder = true,
  megaphone = true,
  sheetOfPaper = true,
  sparkles = true,
  speechBalloon = true,
  styles: styleOverrides,
  ...delegated
}: Props) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="MEAA 3"
      viewBox="364.87 138.04 453.87 510.53"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      {speechBalloon && (
        <g>
          <polygon
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            points="784.7 454.82 487.66 490.37 469.44 492.55 451.81 494.66 447.95 460.82 493.15 455.66 625.49 440.56 743.02 427.15 781.04 422.82 782.77 437.93 784.7 454.82"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="785.58 462.56 482.18 501.88 470.68 503.37 453.07 505.66 451.81 494.66 469.44 492.55 487.66 490.37 784.7 454.82 785.58 462.56"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillWhite)}
            points="798.37 574.66 662.32 590.18 653.16 606.55 640.6 628.98 637.53 634.46 616.7 610.71 604.49 596.78 482.92 610.65 465.28 612.67 455.72 528.92 453.07 505.66 470.68 503.37 482.18 501.88 785.58 462.56 798.37 574.66"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillBlack)}
            points="616.7 610.71 484.64 625.77 465.28 612.67 482.92 610.65 604.49 596.78 616.7 610.71"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillBlack)}
            points="817.74 587.77 681.69 603.29 681.69 603.29 656.89 647.57 637.53 634.47 640.6 628.98 653.16 606.55 662.32 590.18 798.37 574.66 785.58 462.56 784.7 454.82 782.77 437.93 781.05 422.81 800.41 435.92 802.33 452.71 803.19 460.27 817.74 587.77"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillBlack)}
            points="750.84 529.57 632.97 538.57 628.01 473.57 625.49 440.56 743.02 427.15 745.49 459.51 750.84 529.57"
          ></polygon>
        </g>
      )}

      {sheetOfPaper && (
        <g>
          <rect
            {...stylex.props(styles.stroke, styles.fillWhite)}
            height="148"
            transform="translate(-31.95 52.57) rotate(-4.37)"
            width="118.21"
            x="614.35"
            y="371.27"
          ></rect>
        </g>
      )}

      {megaphone && (
        <g>
          <polygon
            {...stylex.props(styles.stroke, styles.fillWhite)}
            points="489.28 345.75 506.64 382.92 491.98 390.73 485.6 394.13 468.76 356.72 466.31 351.29 439.64 354.43 432.89 355.23 412.19 320.72 458.99 234.39 465.75 244.83 466.63 246.2 519.83 328.43 526.79 339.2 489.28 345.75"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillBlack)}
            points="468.76 356.72 442.84 359.77 432.89 355.23 439.64 354.43 466.31 351.29 468.76 356.72"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillWhite)}
            points="558.9 312.19 526.79 339.2 519.83 328.43 533.7 317.85 483.65 235.71 466.63 246.2 465.75 244.83 458.99 234.39 498.08 214.86 502.61 222.11 558.9 312.19"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="533.7 317.85 519.83 328.43 466.63 246.2 483.65 235.71 533.7 317.85"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillBlack)}
            points="536.75 343.74 499.23 350.29 516.6 387.46 495.56 398.68 485.6 394.13 491.98 390.73 506.64 382.92 489.28 345.75 526.79 339.2 558.9 312.19 502.61 222.11 498.08 214.86 508.03 219.4 568.86 316.73 536.75 343.74"
          ></polygon>
        </g>
      )}

      {folder && (
        <g>
          <polygon
            {...stylex.props(styles.stroke, styles.fillWhite)}
            points="775.2 259.94 773.57 268.99 759.23 348.85 640.27 328.78 626.67 326.49 642.24 224.37 705.99 232.26 704.46 241.76 703.33 248.76 717.04 250.89 775.2 259.94"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="787.44 271.15 771.47 360.05 638.91 337.69 626.67 326.49 640.27 328.78 759.23 348.85 773.57 268.99 775.2 259.94 787.44 271.15"
          ></polygon>
          <line {...stylex.props(styles.stroke)} x1="653.29" x2="691.62" y1="241.31" y2="246.63"></line>
          <rect
            {...stylex.props(styles.stroke, styles.fillWhite)}
            height="37.59"
            transform="translate(57.59 -111.21) rotate(9.09)"
            width="40.99"
            x="708.06"
            y="287.97"
          ></rect>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="718.23 243.46 717.04 250.89 703.33 248.76 704.46 241.76 705.99 232.26 718.23 243.46"
          ></polygon>
          <polygon
            {...stylex.props(styles.fillBlack)}
            points="741.12 301.84 738.73 316.78 731.26 315.58 732.46 308.12 724.99 306.92 723.8 314.39 716.33 313.19 718.72 298.26 741.12 301.84"
          ></polygon>
        </g>
      )}

      {checklist && (
        <g>
          <polygon
            {...stylex.props(styles.stroke, styles.fillWhite)}
            points="669.93 468.89 554.01 487.56 542.7 489.38 512.1 299.38 639.33 278.89 641.43 291.9 669.93 468.89"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            points="575.06 458.19 561.04 460.45 555.11 461.4 551.78 440.72 571.73 437.51 572.58 442.79 575.06 458.19"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillWhite)}
            points="554.41 337.31 540.38 339.57 534.46 340.53 531.13 319.84 551.08 316.63 551.93 321.91 554.41 337.31"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="561.19 341.64 541.23 344.85 534.46 340.53 540.38 339.57 554.41 337.31 551.93 321.91 551.08 316.63 557.86 320.95 561.19 341.64"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="567.99 380.68 548.03 383.89 541.26 379.56 547.18 378.61 561.21 376.35 558.73 360.94 557.88 355.66 564.65 359.99 567.99 380.68"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            points="561.21 376.35 547.18 378.61 541.26 379.56 537.92 358.88 557.88 355.66 558.73 360.94 561.21 376.35"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            points="568.72 417.48 554.69 419.74 548.76 420.69 545.43 400.01 565.39 396.8 566.24 402.07 568.72 417.48"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="575.49 421.81 555.54 425.02 548.76 420.69 554.69 419.74 568.72 417.48 566.24 402.07 565.39 396.8 572.16 401.12 575.49 421.81"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="581.84 462.51 561.89 465.73 555.11 461.4 561.04 460.45 575.06 458.19 572.58 442.79 571.73 437.51 578.51 441.83 581.84 462.51"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillBlack)}
            points="683.34 480.07 556.1 500.56 554.01 487.55 669.93 468.89 641.43 291.9 652.74 290.08 683.34 480.07"
          ></polygon>
        </g>
      )}

      {coin && (
        <g>
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M490.29,482.63c-.74,2.57-1.62,5.16-2.63,7.74-.37.94-.75,1.88-1.16,2.81-.09.21-.19.43-.28.64-1.24,2.81-2.59,5.49-4.04,8.06-7.14,12.62-16.69,22.13-26.46,27.04l-2.65-23.27-1.25-11-3.86-33.84,45.2-5.16c.79,8.2-.12,17.47-2.86,26.96Z"
          ></path>
          <path {...stylex.props(styles.stroke)} d="M407.9,527.4c-1.64-.7-3.18-1.55-4.65-2.53l4.65,2.53Z"></path>
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M478.62,478.31c-15.97,37.06-47.64,59.04-70.73,49.09l-4.65-2.53c-2.52-1.69-4.78-3.77-6.77-6.18-8.07-9.82-11.65-25.21-10.18-42.58h0c.91-10.8,3.77-22.37,8.71-33.83,5.21-12.07,12.07-22.55,19.77-30.84,2.71-2.92,5.52-5.57,8.4-7.92,3.87-3.17,7.86-5.79,11.88-7.82,3.88-1.96,7.79-3.36,11.64-4.15,3.51-.72,6.97-.93,10.34-.6l.62.07c2.78.34,5.48,1.06,8.07,2.17.02.01.05.02.07.03,1.58.68,3.08,1.5,4.5,2.45h0c19.21,12.78,23.17,48.17,8.31,82.64Z"
          ></path>
          <path
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            d="M403.25,524.87l-15.46-8.42c-20.01-8.62-27.01-38.41-18.12-70.32l16.63,29.97h0c-1.46,17.37,2.11,32.76,10.18,42.59,1.99,2.42,4.25,4.5,6.77,6.18Z"
          ></path>
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M465.81,393.22s-.05-.02-.07-.03c-2.6-1.11-5.29-1.83-8.07-2.17l-.62-.07c-3.37-.34-6.83-.13-10.34.6-3.85.79-7.76,2.19-11.64,4.15-4.02,2.03-8.01,4.66-11.88,7.82-2.88,2.35-5.69,5-8.4,7.92l-22.72-8.01c2.52-2.95,5.14-5.66,7.84-8.12,3.51-3.19,7.14-5.94,10.83-8.19,3.25-1.99,6.53-3.6,9.81-4.79,2.65-.96,5.28-1.65,7.89-2.05,5.99-.93,11.83-.34,17.2,1.97,1.63.7,3.18,1.55,4.63,2.52l15.54,8.46Z"
          ></path>
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M414.79,411.44c-7.7,8.29-14.57,18.76-19.77,30.84-4.94,11.46-7.8,23.03-8.71,33.82l-16.63-29.97h0c1.37-4.91,3.11-9.86,5.24-14.8,4.61-10.69,10.52-20.13,17.16-27.9l22.72,8.02Z"
          ></path>
          <line {...stylex.props(styles.stroke)} x1="369.67" x2="369.67" y1="446.12" y2="446.12"></line>
          <polygon
            {...stylex.props(styles.fillBlack)}
            points="462.65 458.43 452.25 477.55 443.28 476.02 448.48 466.46 439.51 464.93 434.31 474.49 425.33 472.96 435.73 453.85 462.65 458.43"
          ></polygon>
        </g>
      )}

      {arrow && (
        <g>
          <polygon
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            points="698.36 406.2 690.27 415.22 679.73 426.96 652.04 405.67 629.68 431.75 599.74 340.59 594.01 323.15 692.5 365.74 682.43 376.35 672.27 387.04 680.64 393.19 698.36 406.2"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="692.5 365.74 692.5 380.7 680.64 393.19 672.27 387.04 682.43 376.35 692.5 365.74"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="698.36 406.2 698.36 421.16 679.73 441.92 652.04 420.63 629.68 446.71 594.01 338.11 594.01 323.15 599.74 340.59 629.68 431.75 652.04 405.67 679.73 426.96 690.27 415.22 698.36 406.2"
          ></polygon>
        </g>
      )}

      {sparkles && (
        <g>
          <g>
            <line {...stylex.props(styles.stroke)} x1="645.44" x2="645.44" y1="170.44" y2="188.16"></line>
            <line {...stylex.props(styles.stroke)} x1="654.3" x2="636.59" y1="179.3" y2="179.3"></line>
          </g>
          <g>
            <line {...stylex.props(styles.stroke)} x1="580.13" x2="580.13" y1="221" y2="246.09"></line>
            <line {...stylex.props(styles.stroke)} x1="588.99" x2="571.27" y1="233.54" y2="233.54"></line>
          </g>
          <g>
            <line {...stylex.props(styles.stroke)} x1="619.44" x2="619.44" y1="139.04" y2="152.81"></line>
            <line {...stylex.props(styles.stroke)} x1="624.3" x2="614.58" y1="145.92" y2="145.92"></line>
          </g>
        </g>
      )}

      {cloud && (
        <g>
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M570.89,528.69s-11.2-9.72-28.73-6.2c-17.53,3.51-16.89,12.43-16.89,12.43,0,0-26.16.62-26.94,10.74-.78,10.12,39.15,3.02,39.15,3.02,0,0-8.06,12.65,7.78,13.88s51.88-12.81,51.88-12.81c0,0,.88,11.57,19.93,11.27,19.05-.3,34.15-12.41,34.15-12.41,0,0,12.08,15.54,36.24,12.09s39.19-20.43,39.77-27.91c.58-7.48-11.06-5.72-11.06-5.72,0,0,8.84-17.02-12.72-18.68-21.56-1.66-30.43,15.8-30.43,15.8,0,0-5.41-10.15-19.9-11.71s-19.89,5.1-19.89,5.1c0,0-14.38-14.01-36.48-8.57-22.11,5.44-25.86,19.69-25.86,19.69Z"
          ></path>
        </g>
      )}
    </svg>
  );
};
