import { Activity } from '~/types/user';

export const activities = [
  Activity.VISUAL_COMMUNICATION,
  Activity.DIGITAL_DESIGN,
  Activity.PHOTOGRAPHY,
  Activity.AUDIOVISUAL,
  Activity.LANGUAGE_AND_COMMUNICATION,
  Activity.IT_DEVELOPMENT,
  Activity.EDUCATION,
  Activity.PERFORMING_ARTISTS,
  Activity.STYLING_AND_INTERIOR,
  Activity.ONLINE_CONTENT_CREATION,
  Activity.OTHER,
] as const;

export const mainActivities = activities.filter((activity) => activity !== Activity.OTHER);

export const otherActivityIndex = activities.findIndex((activity) => activity === Activity.OTHER);
