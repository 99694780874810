import { cloneElement } from 'react';

import type { TooltipTriggerProps as Props } from './types';

import { useTooltipContext } from './context';

export const TooltipTrigger = ({ children, ...props }: Props) => {
  const { getReferenceProps, open, refs } = useTooltipContext();

  // eslint-disable-next-line @eslint-react/no-clone-element
  return cloneElement(
    children,
    getReferenceProps({
      ref: refs.setReference,
      ...props,
      ...children.props,
      'data-state': open ? 'open' : 'closed', // Can be used to style the trigger based on the state,
    }),
  );
};
