import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdateVatRegulation } from '~/api/user';
import { Button, Modal, Spinner } from '~/components';
import { VatRegulationType } from '~/pages/Settings/Profile/VatRegulation/VatRegulationToggle/types';
import { VatRegulationToggle } from '~/pages/Settings/Profile/VatRegulation/VatRegulationToggle/VatRegulationToggle';

import type { VatRegulationTabProps as Props } from './types';

export const VatRegulationTab = ({ isLastTab, onCompleted }: Props) => {
  const mutation = useUpdateVatRegulation();

  const { t } = useTranslation(['settings']);

  const { control, handleSubmit } = useForm({ defaultValues: { vatRegulationType: VatRegulationType.NORMAL } });

  const onSubmit = handleSubmit((data) => {
    const vatExempt = data.vatRegulationType === VatRegulationType.EXEMPT;

    mutation.mutate(
      { vatExempt },
      {
        onSuccess: () => onCompleted(),
        onError: () => toast.error(t('settings:accountCompletionModal.vatRegulation.error')),
      },
    );
  });

  return (
    <div className="space-y-4">
      <p>
        <Trans components={{ a: <a /> }} i18nKey="settings:profile.vatRegulation.help" />
      </p>

      <form onSubmit={onSubmit}>
        <div className="form-group">
          <Controller
            control={control}
            name="vatRegulationType"
            render={({ field: { onChange, value } }) => <VatRegulationToggle onChange={onChange} value={value} />}
          />
        </div>

        <Modal.Actions>
          <Button disabled={mutation.isPending} isSubmit>
            {mutation.isPending && <Spinner size={24} />}
            <span>{t(`settings:accountCompletionModal.action.${isLastTab ? 'complete' : 'next'}`)}</span>
          </Button>
        </Modal.Actions>
      </form>
    </div>
  );
};
