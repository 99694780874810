import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useSignContract = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request('/user/contract/sign', { method: 'PATCH', token });
    },
    onSuccess: () => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, contractSigned: true, addendumSigned: true };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
