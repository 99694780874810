import { useTranslation } from 'react-i18next';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { PageHeader } from '~/components';
import { Icon } from '~/components/SVG';
import { SearchParamKeys } from '~/constants/url';
import { routes } from '~/providers/RouterProvider/router.routes';

import styles from './ChooseClientType.module.scss';

export const ChooseClientType = () => {
  const { t } = useTranslation(['clients']);

  const searchParams = useSearchParams()[0];
  const { search } = useLocation();

  return (
    <>
      <PageHeader backRoute={searchParams.get(SearchParamKeys.REDIRECT_PATH) ?? routes.clients} title={t('clients:create.title')} />

      <div className={styles.Wrapper}>
        {(['organisation', 'private'] as const).map((clientType) => (
          <Link
            className={styles.LinkButton}
            key={clientType}
            to={{ pathname: clientType === 'organisation' ? routes.createClientOrganisationStep : routes.createClientPrivateStep, search }}
          >
            <span className={styles.IconWrapper}>
              <Icon name={clientType === 'organisation' ? 'DomainAdd' : 'PersonAdd'} size={32} />
            </span>

            <span className={styles.Title}>{t(`clients:create.chooseType.${clientType}.title`)}</span>
            <span className={styles.Description}>{t(`clients:create.chooseType.${clientType}.description`)}</span>
          </Link>
        ))}
      </div>
    </>
  );
};
