import { useMatch, useParams } from 'react-router-dom';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useQuotations } from '~/api/quotations';
import { Redirect } from '~/components/Functional';
import { routes } from '~/providers/RouterProvider/router.routes';

import { getQuotationStatus, QuotationStatus } from '../utils';
import { CreateEditQuotationForm } from './CreateEditQuotationForm';

export const CreateEditQuotation = () => {
  const { data: quotations } = useQuotations();

  const { quotationId } = useParams<Params<typeof routes.editQuotation>>();
  const quotation = quotations.find((quotation) => quotation.id === Number(quotationId));
  const isEditRoute = !!useMatch(routes.editQuotation);

  if (!quotation) return <Redirect to={routes.quotations} />;

  const isEditable =
    (!isEditRoute && getQuotationStatus(quotation) === QuotationStatus.PENDING) ||
    (isEditRoute && [QuotationStatus.SENT, QuotationStatus.EXPIRED].includes(getQuotationStatus(quotation)));

  if (!isEditable) return <Redirect to={routes.quotations} />;

  return <CreateEditQuotationForm isEdit={isEditRoute} quotation={quotation} />;
};
