import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spinner } from '~/components';

import type { AutoSaveIndicatorProps as Props } from './types';

export const AutoSaveIndicator = ({ isSaving }: Props) => {
  const [show, setShow] = useState(false);

  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (isSaving) {
      setShow(true);
    }
  }, [isSaving]);

  if (!show) return null;

  return (
    <div className="flex gap-2 justify-center items-center text-primary-500 text-sm mr-2">
      {isSaving ? (
        <>
          <Spinner size={24} />
          <span>{t('common:autoSave')}</span>
        </>
      ) : (
        <span>{t('common:saved')}</span>
      )}
    </div>
  );
};
