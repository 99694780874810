import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';
import { planSchema } from './schemas';

export const plansQueryOptions = queryOptions({
  queryKey: QueryKeys.plans,
  queryFn: async () => {
    const token = getAccessToken();
    const response = await request('/plans', { token });

    const { data: plans } = apiResourceSchema(planSchema.array()).parse(response);

    return plans;
  },
  staleTime: Infinity,
  gcTime: Infinity,
});

export const usePlans = () => {
  return useSuspenseQuery(plansQueryOptions);
};
