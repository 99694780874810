import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';

export const useUpdateCommunityProfileAnalytics = (type: 'view' | 'mail') => {
  return useMutation({
    mutationFn: (id: number) => {
      const token = getAccessToken();
      const body = { type };
      return request(`/community/${id}/analytics`, { method: 'POST', token, body });
    },
  });
};
