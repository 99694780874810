import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const Coffee2 = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      viewBox="146.54 389.91 127.04 240.12"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M193.67,580.81s-4.89-7.33-13.96-2.79c-9.07,4.54-10.47,15.01-10.47,15.01,0,0-5.58-1.74-9.42,2.79-3.84,4.54,1.74,10.12,1.74,10.12,0,0-22.11,13.58-12.68,17.77s31.18-4.86,31.18-4.86c0,0-8.03,10.82,6.63,11.17,14.66.35,29.66-14.31,29.66-14.31,0,0,1.05,11.51,13.61,12.91,12.56,1.4,20.59-12.56,20.59-12.56,0,0,23.03-11.52,23.03-20.24s-25.47.7-25.47.7c0,0,16.49-15.25,1.83-17.34-14.66-2.09-24.51,8.62-24.51,8.62,0,0,11.36-16.66-2-19.15-13.36-2.49-29.75,12.17-29.75,12.17Z"
      ></path>
      <ellipse {...stylex.props(styles.stroke, styles.fillBlack)} cx="208.83" cy="594.98" rx="31.44" ry="7.38"></ellipse>
      <polygon
        {...stylex.props(styles.stroke, styles.fillPrimary)}
        points="249.07 553.15 249.07 584.74 228 584.74 228 574.21 238.54 574.21 238.54 563.68 228 563.68 228 553.15 249.07 553.15"
      ></polygon>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M222.73,503.46c-5.27,11.38-11.85,15.96-11.85,15.96,0,0,7.8-19.86-2.98-28.91-12.32-10.33-30.02-17.75-28.79-33.83,1.23-16.08,15.15-24.58,23.91-36.96,10.74-15.17,4.68-29.56,4.57-29.81.26.33,15.73,20.18,3.01,39.42-11.2,16.95-12.96,20.83-10.27,27.49,2.69,6.66,11.36,9.63,18.49,16.03,10.05,9.01,7.56,22.7,3.91,30.6Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M228,553.15v10.53l4.96,10.53h-4.96v10.53s1.04,2.73-10.71,2.83c-5.26.05-12.07.01-17.98-.04v-48.15h26.29c4.07,0,7.37,3.3,7.37,7.37v6.4h-4.96Z"
      ></path>
      <polygon {...stylex.props(styles.stroke, styles.fillBlack)} points="232.97 563.68 232.97 574.21 228 563.68 232.97 563.68"></polygon>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M199.31,587.53v7.21h-5.88c-4.07,0-7.37-3.3-7.37-7.37,0,0,5.95.09,13.25.16Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M199.31,539.38v48.15c-7.3-.07-13.25-.16-13.25-.16v-40.62c0-4.07,3.3-7.37,7.37-7.37h5.88Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M232.97,584.74v2.63c0,4.07-3.3,7.37-7.37,7.37h-26.29v-7.21c5.91.05,12.72.09,17.98.04,11.76-.1,10.71-2.83,10.71-2.83h4.96Z"
      ></path>
    </svg>
  );
};
