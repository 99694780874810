import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../SVG.types';

import * as CreativeShelterIcons from './CreativeShelter';
import { styles } from './Icon.styles';
import * as Icons8Material from './Icons8Material';
import * as MaterialSymbols from './MaterialSymbols';

const Icons = {
  ...MaterialSymbols,
  ...Icons8Material,
  ...CreativeShelterIcons,
};

export type IconName = keyof typeof Icons;

type Props = SvgProps & {
  inline?: boolean;
  name: IconName;
  size?: number;
};

export const Icon = ({ inline = false, name, size = 24, styles: styleOverrides }: Props) => {
  const Component = Icons[name];

  return <Component {...stylex.props(styles.icon, styles.size(size), inline && styles.inline, styleOverrides)} />;
};
