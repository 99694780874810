import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { env } from '~/constants/env';
import { Language } from '~/types/app';

import { resources } from './resources';

export const initTranslations = () =>
  i18n.use(initReactI18next).init({
    resources,
    lng: Language.DUTCH,
    fallbackLng: env.IS_LOCAL_DEV_ENV ? [Language.DUTCH, 'dev'] : Language.DUTCH,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transWrapTextNodes: 'span',
    },
  });
