import { Trans, useTranslation } from 'react-i18next';

import { Warning } from '~/components';

export const StylingAndInteriorWarning = () => {
  const { t } = useTranslation(['settings']);

  return (
    <Warning title={t('settings:profile.activities.warnings.stylingAndInterior.title')}>
      <Trans components={{ a: <a /> }} i18nKey="settings:profile.activities.warnings.stylingAndInterior.body" />
    </Warning>
  );
};
