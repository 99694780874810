import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { setAccessToken } from '~/utils/auth';

import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';
import { accessTokenSchema } from './schemas';

type Payload = {
  emailAddress: string;
  password: string;
  token: string;
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: async (body: Payload) => {
      const response = await request('/authentication/reset-password', { method: 'POST', body });

      const { accessToken } = accessTokenSchema.parse(response);

      return accessToken;
    },
    onSuccess: (accessToken) => {
      setAccessToken(accessToken);
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) && captureException(new Error('Failed to reset password', { cause: error }), { level: 'error' }),
  });
};
