import type { ReactNode } from 'react';

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/SVG';

import type { SelectProps as Props } from './types';

import styles from './Select.module.scss';

const defaultBy = <T,>(a: T, b: T) => a === b;
const defaultOptionWrapper = ({ children }: { children: ReactNode }) => children;

export const Select = <T,>({
  by = defaultBy,
  clearable,
  disabled = false,
  Footer,
  id,
  innerRef,
  invalid = false,
  onBlur,
  onChange,
  options,
  OptionWrapper = defaultOptionWrapper,
  placeholder,
  value,
  variant = 'regular',
}: Props<T>) => {
  const selectedOption = options.find((option) => by(option.value, value)) ?? null;

  const { t } = useTranslation(['common']);

  return (
    <Listbox by={by} disabled={disabled} onChange={onChange} value={value}>
      <ListboxButton
        className={classNames(
          styles.Button,
          invalid && styles.Button__Invalid,
          disabled && styles.Button__Disabled,
          variant === 'minimal' && styles.Minimal,
        )}
        id={id}
        onBlur={onBlur}
        ref={innerRef}
      >
        {selectedOption ? (
          <span className={styles.ButtonContent}>
            <OptionWrapper value={selectedOption.value}>{selectedOption.label}</OptionWrapper>
          </span>
        ) : (
          <span className={classNames(styles.ButtonContent, styles.ButtonContent__Placeholder)}>{placeholder}</span>
        )}

        <div className={styles.Buttons}>
          {clearable && value !== null && (
            <span
              className={styles.ButtonClear}
              onClick={(e) => {
                e.stopPropagation();
                onChange(null);
              }}
            >
              <Icon name="Close" />
            </span>
          )}
          <span className={styles.ButtonExpand}>
            <Icon name="KeyboardArrowDown" />
          </span>
        </div>
      </ListboxButton>

      <ListboxOptions anchor={{ to: 'bottom', gap: 4 }} className={styles.Options} modal={false}>
        {options.length > 0 ? (
          options.map((option, i) => (
            <ListboxOption className={styles.Option} disabled={option.disabled} key={i} value={option.value}>
              <OptionWrapper value={option.value}>{option.label}</OptionWrapper>
            </ListboxOption>
          ))
        ) : (
          <div className={styles.NoOptions}>{t('common:listbox.noOptions')}</div>
        )}
        {Footer && <div className={styles.Footer}>{Footer}</div>}
      </ListboxOptions>
    </Listbox>
  );
};
