import type { RefCallback } from 'react';

import { useEffect, useMemo, useState } from 'react';

export const useIntersectionObserver = <T extends HTMLElement = HTMLElement>(
  onIntersect: (() => void) | null,
  options: IntersectionObserverInit = { threshold: 1, root: null, rootMargin: '0px' },
) => {
  const [element, setElement] = useState<T | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!element) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          setIsIntersecting(true);
          onIntersect?.();
        } else {
          setIsIntersecting(false);
        }
      },
      {
        threshold: options.threshold,
        root: options.root,
        rootMargin: options.rootMargin,
      },
    );

    observer.observe(element);

    return () => observer.unobserve(element);
  }, [element, onIntersect, options.root, options.rootMargin, options.threshold]);

  return useMemo(() => ({ isIntersecting, ref: setElement as RefCallback<T> }), [isIntersecting]);
};
