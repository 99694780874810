import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';

import type { Contact } from '~/api/clients/types';
import type { Params } from '~/providers/RouterProvider/router.types';

import { DropdownMenu } from '~/components';
import { useModal } from '~/hooks/useModal';
import { r, routes } from '~/providers/RouterProvider/router.routes';

import { DeleteContactModal } from './DeleteContactModal';

export const ContactsTableOptions = ({ contact }: { contact: Contact }) => {
  const { clientId } = useParams<Params<typeof routes.showClient>>();
  invariant(clientId, 'Missing clientId in route parameters.');

  const deleteModal = useModal();

  const navigate = useNavigate();
  const { t } = useTranslation(['clients']);

  return (
    <>
      <DropdownMenu
        dropdownClass="text-sm"
        items={[
          {
            iconName: 'Edit',
            text: t('clients:detail.contacts.rowActions.edit'),
            onClick: () => navigate(r(routes.editContact, { clientId, contactId: contact.id })),
          },
          {
            iconName: 'ContentCopy',
            text: t('clients:detail.contacts.rowActions.duplicate'),
            onClick: () => navigate(r(routes.createContact, { clientId }), { state: { duplicateContact: contact } }),
          },
          {
            iconName: 'Delete',
            text: t('clients:detail.contacts.rowActions.delete'),
            onClick: deleteModal.open,
          },
        ]}
      />

      {deleteModal.isOpen && <DeleteContactModal clientId={+clientId} contact={contact} onClose={deleteModal.close} />}
    </>
  );
};
