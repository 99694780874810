import type { TextEditorMenuProps as Props } from '../types';

import { Button } from '../../Button/Button';
import { AddHyperlinkButton } from './AddHyperlinkButton';
import { AddShortcodeDropdown } from './AddShortcodeDropdown';

export const TextEditorMenu = ({ editor, shortcodes, withLinkButton }: Props) => {
  if (!editor) return null;

  return (
    <nav className="richTextMenu flex flex-wrap">
      <Button
        extraClasses={editor.isActive('bold') ? 'active' : ''}
        icon="FormatBold"
        onClick={() => editor.chain().focus().toggleBold().run()}
        type="iconOnly"
      />
      <Button
        extraClasses={editor.isActive('italic') ? 'active' : ''}
        icon="FormatItalic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        type="iconOnly"
      />
      <Button
        extraClasses={editor.isActive('underline') ? 'active' : ''}
        icon="FormatUnderlined"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        type="iconOnly"
      />
      <Button
        extraClasses={editor.isActive('bulletList') ? 'active' : ''}
        icon="FormatListBulleted"
        onClick={() => {
          let chain = editor.chain();
          chain = editor.isActive('bulletList')
            ? chain
            : chain.insertContent(`<p>${editor.state.doc.textBetween(editor.state.selection.from, editor.state.selection.to)}</p>`);
          chain.focus().toggleBulletList().run();
        }}
        type="iconOnly"
      />
      <Button
        extraClasses={editor.isActive('orderedList') ? 'active' : ''}
        icon="FormatListNumbered"
        onClick={() => {
          let chain = editor.chain();
          chain = editor.isActive('orderedList')
            ? chain
            : chain.insertContent(`<p>${editor.state.doc.textBetween(editor.state.selection.from, editor.state.selection.to)}</p>`);
          chain.focus().toggleOrderedList().run();
        }}
        type="iconOnly"
      />

      {withLinkButton && <AddHyperlinkButton editor={editor} />}

      {shortcodes && <AddShortcodeDropdown editor={editor} shortcodes={shortcodes} />}
    </nav>
  );
};
