import * as stylex from '@stylexjs/stylex';

import type { MediaQueryDesktop, MediaQueryLaptopAndBelow } from '~/styles/mediaQueries';

import { brandColors, fontWeight, neutralColors, spacing } from '~/styles/tokens.stylex';

const Desktop: MediaQueryDesktop = '@media (1280px <= width < 1920px)';

const LaptopAndBelow: MediaQueryLaptopAndBelow = '@media (width < 1280px)';

export const styles = stylex.create({
  invitationButtonWrapper: {
    marginInline: 'auto',
    paddingInline: spacing.xsmall,
    paddingBlock: {
      default: spacing.large,
      [Desktop]: spacing.normal,
      [LaptopAndBelow]: spacing.xxsmall,
    },
  },

  invitationButton: {
    position: 'relative',
    minHeight: 40,
    marginInlineStart: spacing.medium,
    paddingInline: spacing.xsmall,
    color: neutralColors.gray800,
    borderColor: {
      default: brandColors.secondary500,
      ':hover': neutralColors.gray800,
    },
    backgroundColor: {
      default: brandColors.secondary500,
      ':hover': neutralColors.white,
    },
    fontWeight: fontWeight.medium,
  },

  invitationButtonCollapsed: {
    marginInlineStart: spacing.none,
    paddingInlineStart: spacing.none,
    paddingInlineEnd: spacing.none,
    width: 60,
  },

  invitationIllustration: {
    position: 'absolute',
    marginBlock: 'auto',
    bottom: -8,
    left: -42,
    height: 48,
  },

  invitationIllustrationCollapsed: {
    bottom: 'unset',
    left: 'unset',
    inset: 0,
    margin: 'auto',
    height: 38,
    transform: 'unset',
  },

  invitationButtonTextCollapsed: {
    display: 'none',
  },
});
