import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { billitSsoUrlSchema } from './schemas';

export const useBillitSsoUrl = () => {
  return useMutation({
    mutationFn: async () => {
      const token = getAccessToken();
      const body = { provider: 'billit' };
      const response = await request('/oauth2/sso/billit', { method: 'POST', token, body });

      const { redirectUrl } = billitSsoUrlSchema.parse(response);

      return redirectUrl;
    },
  });
};
