export const clients = {
  addressType: {
    'establishment unit': 'Vestigingseenheid',
    headquarters: 'Maatschappelijke zetel',
  },
  alerts: {
    errorKboInformation: 'Er liep iets mis bij het ophalen van de bedrijfsgegevens, probeer je het later opnieuw?',
    errorViesInformation: 'Er liep iets mis bij het ophalen van de bedrijfsgegevens, probeer je het later opnieuw?',
    successContactCreated: 'Je contactpersoon is succesvol aangemaakt!',
    successContactDeleted: 'Je contactpersoon is succesvol verwijderd!',
    successContactEditedOrganisation: 'Je contactpersoon is succesvol bewerkt!',
    successContactEditedPrivate: 'De klant is succesvol bewerkt!',
    successCreated: 'De klant werd succesvol aangemaakt!',
    successDeleted: 'De klant werd succesvol verwijderd!',
    successSyncStarted: 'Je klanten werden succesvol gesynchroniseerd met Billit',
    successUpdated: 'De klant werd succesvol aangepast.',
  },
  card: {
    label: 'Opdrachtgever',
  },
  contact: 'Contactpersoon',
  create: {
    blocker: 'Ben je zeker dat je de pagina wilt verlaten? Je klant is nog niet opgeslagen, en alle aanpassingen gaan verloren.',
    chooseType: {
      organisation: {
        description: 'Voeg een organisatie met contactpersoon toe',
        title: 'Organisatie',
      },
      private: {
        description: 'Voeg een particuliere klant of feitelijke vereniging toe',
        title: 'Particulier',
      },
    },
    company: {
      changeVatNumber: 'Niet wat je zoekt? Geef een ander btw-nummer in.',
      companyInformationDisclaimer:
        'Bovenstaande organisatie vonden we op basis van het btw-nummer. Extra informatie, zoals de contactgegevens, adres, ... kan je toevoegen in de volgende stap.',
      companyInformationDisclaimerWithoutAddress:
        'Bovenstaande organisatie vonden we op basis van het btw-nummer. Het adres van de maatschappelijke zetel ontbreekt nog, vul je dit nog even in of vraag je dit op?',
      searchCompanyInformation: 'Opzoeken',
      switchToOtherNumber: 'Heeft je klant geen btw-nummer? <0>Klik hier om een ander nummer in te vullen.</0>',
      switchToVatNumber: 'Heeft je klant een btw-nummer? <0>Klik hier om te zoeken op btw-nummer.</0>',
    },
    next: 'Volgende',
    submit: 'Maak klant aan',
    title: 'Klant aanmaken',
    type: {
      contact: 'Contactpersoon',
      organisation: 'Organisatie',
    },
  },
  createContact: {
    blocker: 'Ben je zeker dat je de pagina wilt verlaten? Je contactpersoon is nog niet opgeslagen.',
    submit: 'Voeg contactpersoon toe',
  },
  createEdit: {
    subsections: {
      comments: {
        description:
          'Hier kan je notities bijhouden voor jezelf, over deze klant. Denk aan een afwijkende betalingstermijn, speciale afspraken, ...',
        title: 'Notities',
      },
      contactAddress: 'Adres',
      customAgreement: {
        description:
          'Heb je een bestaande raamovereenkomst met jouw klant? Dan kan je dit hier aanduiden. Meer info? <a href="https://knowledge.creativeshelter.be/opdrachtgevers-en-overeenkomsten-wij-zetten-je-op-weg" target="_blank">Lees er alles over in onze Knowledge Base.</a>',
        title: 'Raamovereenkomst',
      },
      emails: {
        description:
          'Vul hier aan naar wie we welke e-mails versturen. Als je ervoor kiest om (bepaalde) e-mails zelf te versturen, dan ontvang je deze zelf met de nodige bestanden, zodat je deze kan doorsturen naar jouw opdrachtgever.',
        title: 'E-mails',
      },
      personalInfo: 'Persoonlijke gegevens',
    },
  },
  customAgreementModal: {
    cancel: 'Neen, ga terug',
    description:
      'Bevestig enkel als je met deze klant al een getekende overeenkomst hebt. Wij sturen je hierna een e-mail om de overeenkomst na te lezen. Waarom? Wij dienen namelijk mee in de overeenkomst te stappen. Meer info? Lees <a href="https://knowledge.creativeshelter.be/opdrachtgevers-en-overeenkomsten-wij-zetten-je-op-weg" target="_blank">hier</a>.',
    submit: 'Ja, ik heb een getekende overeenkomst',
    title: 'Opgelet!',
  },
  detail: {
    contacts: {
      add: 'Contactpersoon toevoegen',
      columns: {
        email: 'E-mailadres',
        name: 'Naam',
        phoneNumber: 'Telefoonnummer',
      },
      delete: {
        description: 'Ben je zeker dat je contactpersoon "{{name}}" wilt verwijderen?',
        submit: 'Verwijder contactpersoon',
        title: 'Verwijder contactpersoon',
      },
      moreEmails: 'En nog {{amount}} andere',
      rowActions: {
        delete: 'Verwijder',
        duplicate: 'Kopieer',
        edit: 'Bewerk',
      },
      selfEmail: '{{email}} (jijzelf)',
      title: 'Contactpersonen',
    },
  },
  editClient: {
    blocker: 'Ben je zeker dat je de pagina wilt verlaten? De aanpassingen aan je klant zijn niet opgeslagen.',
    submit: 'Bewerk',
  },
  editContact: {
    blocker: 'Ben je zeker dat je de pagina wilt verlaten? De aanpassingen aan je contactpersoon zijn niet opgeslagen.',
    submit: 'Bewerk',
  },
  fields: {
    address: {
      label: 'Adres',
    },
    city: {
      label: 'Gemeente',
      placeholder: 'Stad of gemeente',
    },
    clientType: {
      label: 'Type klant',
      options: {
        '1': 'Organisatie',
        '2': 'Particulier persoon',
      },
    },
    companyName: {
      label: 'Bedrijfsnaam',
    },
    countryCode: {
      label: 'Land',
      placeholder: 'Selecteer een land...',
    },
    customAgreement: {
      label: 'Ik heb al een bestaande overeenkomst met deze klant.',
      small:
        '<strong>Opgelet!</strong> Stelt je klant een overeenkomst voor? Lees <a href="https://knowledge.creativeshelter.be/opdrachtgevers-en-overeenkomsten-wij-zetten-je-op-weg" target="_blank">hier</a> wat je dient te doen.',
      tooltip: 'Afspraken zoals een prijsofferte, telefonische- en/of e-mailbevestiging vallen hier niet onder.',
    },
    emailAddresses: {
      addReceiver: 'Extra ontvanger toevoegen',
      addSelf: 'Zelf versturen',
      invoicesAndCreditNotes: "Facturen en creditnota's",
      label: 'E-mailadres',
      paymentReminders: 'Betalingsherinneringen',
      quotations: 'Offertes',
    },
    enterpriseNumber: {
      label: 'Btw- of ondernemingsnummer',
    },
    firstName: {
      label: 'Voornaam',
      placeholder: 'Voornaam',
    },
    function: {
      label: 'Functie',
      placeholder: 'Functie',
    },
    lang: {
      label: 'Taal',
    },
    lastName: {
      label: 'Achternaam',
      placeholder: 'Achternaam',
    },
    number: {
      label: 'Huisnummer',
      placeholder: 'Nummer',
    },
    organisationName: {
      label: 'Naam organisatie',
      tooltip: 'Is je klant een feitelijke organisatie, vul dan hier de organisatie naam in',
    },
    otherNumber: {
      label: 'Ander nummer',
      placeholder: 'Ander nummer',
    },
    street: {
      label: 'Straat',
      placeholder: 'Straat',
    },
    telephone: {
      label: 'Telefoonnummer',
      placeholder: 'Telefoonnummer',
    },
    useOrganisationAddress: {
      label: 'Gebruik het adres van de organisatie',
    },
    userComments: {
      label: 'Notities',
      placeholder: 'Opmerkingen voor jezelf of voor Creative Shelter.',
    },
    vatNumber: {
      label: 'Btw-nummer',
    },
    vatOrOtherNumber: {
      label: 'Btw- of ander type nummer',
    },
    zipcode: {
      label: 'Postcode',
      placeholder: 'Postcode',
    },
  },
  header: {
    actionBlock: {
      button: 'Klant aanmaken',
      buttonMobile: 'Nieuwe klant',
      dropdown: {
        billit: 'Synchroniseer je klanten (Billit)',
      },
    },
    description: 'Voeg klanten toe of bewerk bestaande klanten.',
    title: 'Klanten',
  },
  kboOrganisationTooltip: 'De bedrijfsgegevens kun je niet aanpassen. Je klant gaf deze zo in bij het KBO, waar wij ze ook ophalen.',
  overview: {
    columns: {
      contacts: 'Contactpersonen',
      email: 'E-mailadres',
      name: 'Naam',
      type: 'Klant type',
      vat: 'Btw-nummer',
      vatOrOtherNumber: 'Btw- of ander type nummer',
    },
    delete: {
      description: 'Ben je zeker dat je de klant ({{name}}) wilt verwijderen?',
      submit: 'Verwijder klant',
      title: 'Klant verwijderen',
    },
    moreContacts: 'En nog {{amount}} andere',
    rowActions: {
      addContact: 'Voeg contactpersoon toe',
      delete: 'Verwijder',
      edit: 'Bewerk',
      view: 'Bekijk',
    },
    subtitle: 'Je klanten',
  },
  type: {
    organisation: 'Organisatie',
    private: 'Particulier',
  },
};
