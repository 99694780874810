import { useTranslation } from 'react-i18next';

import type { ActivityOptionProps as Props } from './types';

import styles from './ActivityOption.module.scss';

export const ActivityOption = ({ children, value }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.Wrapper}>
      <p className={styles.Activity}>{children}</p>
      <p className={styles.Description}>{t(`common:activities.${value}.description`)}</p>
    </div>
  );
};
