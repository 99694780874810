import type { ComponentProps } from 'react';
import type { ControllerFieldState, ControllerRenderProps, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form';

import { useMemo } from 'react';
import { useController } from 'react-hook-form';

import type { RequiredBy } from '~/types/utils';

import { FormFieldContext } from '../FormFieldContext';
import { Field } from './Field';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = ComponentProps<typeof Field> & RequiredBy<UseControllerProps<TFieldValues, TName>, 'control'>;

export const FormField = <TFieldValues extends FieldValues = FieldValues, TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
  props: Props<TFieldValues, TName>,
) => {
  const { control, defaultValue, disabled, name, rules, shouldUnregister, ...delegated } = props;

  const { field, fieldState } = useController({ name, control, defaultValue, rules, shouldUnregister, disabled });

  const context = useMemo(
    () => ({
      field: {
        name: field.name,
        onBlur: field.onBlur,
        onChange: field.onChange,
        ref: field.ref,
        value: field.value,
        disabled: field.disabled,
      } satisfies ControllerRenderProps,
      fieldState: {
        invalid: fieldState.invalid,
        isDirty: fieldState.isDirty,
        isTouched: fieldState.isTouched,
        isValidating: fieldState.isValidating,
        error: fieldState.error,
      } satisfies ControllerFieldState,
    }),
    [
      field.disabled,
      field.name,
      field.onBlur,
      field.onChange,
      field.ref,
      field.value,
      fieldState.error,
      fieldState.invalid,
      fieldState.isDirty,
      fieldState.isTouched,
      fieldState.isValidating,
    ],
  );

  return (
    <FormFieldContext.Provider value={context}>
      <Field {...delegated} />
    </FormFieldContext.Provider>
  );
};
