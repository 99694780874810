import * as stylex from '@stylexjs/stylex';

import { spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  authLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: spacing.xsmall,
    paddingTop: spacing.normal,
    borderTopWidth: 1,
    borderColor: systemColors.borderLight,
    textAlign: 'center',
  },
});
