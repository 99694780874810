import { queryOptions, useQuery } from '@tanstack/react-query';

import type { QuotationForClient } from './types';

import { apiResourceSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';
import { quotationForClientSchema } from './schemas';

export const quotationForClientQueryOptions = (allowKey: string) =>
  queryOptions({
    queryKey: QueryKeys.quotationForClient(allowKey),
    queryFn: async () => {
      const response = await request(`/quotations/${allowKey}/allow-by-client`);

      // TODO: Schema validation
      const { data } = apiResourceSchema(quotationForClientSchema).parse(response);

      return data as QuotationForClient;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });

export const useQuotationForClient = (allowKey: string) => {
  return useQuery(quotationForClientQueryOptions(allowKey));
};
