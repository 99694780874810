import type { ZodIssue } from 'zod';

import { ZodIssueCode } from 'zod';

export class FormattedZodError extends Error {
  constructor(issues: ZodIssue[], options?: ErrorOptions) {
    const message = FormattedZodError.stringifyIssues(issues);

    super(message, options);
    this.name = 'FormattedZodError';
  }

  static stringifyIssues(issues: ZodIssue[], indentation = 0): string {
    return issues
      .map((issue, i) => {
        const leftPadding = new Array(indentation * 2).fill(' ').join('');
        const path = issue.path
          .map((key) => (typeof key === 'number' ? `[${key}]` : key))
          .join('.')
          .replaceAll('.[', '[');

        const isFirstLine = i === 0 && indentation === 0;
        const message = `${isFirstLine ? '' : '\n'}${leftPadding}- ${issue.code} error at ${path === '' ? 'root' : path}: ${issue.message}`;

        if (issue.code !== ZodIssueCode.invalid_union) {
          return message;
        }

        const bestMatchingUnionError = issue.unionErrors.reduce(
          (previous, current) => (current.issues.length < previous.issues.length ? current : previous),
          issue.unionErrors[0],
        );

        return [message, this.stringifyIssues(bestMatchingUnionError.issues, indentation + 1)].join('');
      })
      .join('');
  }
}
