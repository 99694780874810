import classNames from 'classnames';

import { Icon } from '~/components/SVG';

import type { IconTooltipProps as Props } from './types';

import { Tooltip } from './Tooltip';

export const IconTooltip = ({ color = 'text-gray-300', iconName = 'Help', inline = true, size = 20, text, ...options }: Props) => {
  return (
    <Tooltip {...options}>
      <Tooltip.Trigger>
        <span className={classNames('data-[state=open]:text-secondary-500', color)}>
          <Icon inline={inline} name={iconName} size={size} />
        </span>
      </Tooltip.Trigger>
      <Tooltip.Content>{text}</Tooltip.Content>
    </Tooltip>
  );
};
