import { DialogTitle } from '@headlessui/react';

import { Icon } from '~/components/SVG';

import type { ModalTitleProps as Props } from './types';

import { IconTooltip } from '../Tooltip/IconTooltip';
import styles from './Modal.module.scss';

export const ModalTitle = ({ children, icon, tooltip }: Props) => {
  return (
    <DialogTitle className={styles.title}>
      {icon && (
        <span className={styles.icon}>
          <Icon name={icon} size={32} />
        </span>
      )}

      <span>{children}</span>

      {tooltip && <IconTooltip inline={false} size={24} text={tooltip} />}
    </DialogTitle>
  );
};
