import { createColumnHelper } from '@tanstack/react-table';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Product } from '~/api/products/types';
import type { Language } from '~/types/app';

import { CheckboxInput, IconTooltip } from '~/components';
import { ColumnIds } from '~/constants/table';
import { useIntl } from '~/hooks/useIntl';
import { useProductCategories } from '~/hooks/useProductCategories';

import { ProductsTableOptions } from './ProductsTableOptions';
import { isProductSelectable } from './utils';

const columnHelper = createColumnHelper<Product>();

export const useProductsTableColumns = (language: Language) => {
  const { t } = useTranslation(['common', 'lines', 'products']);
  const { formatCurrency } = useIntl();

  return useMemo(
    () => [
      // Selector
      columnHelper.display({
        id: ColumnIds.selector,
        header: ({ table }) => (
          // Label is necessary to trigger the click event
          <label className="p-0">
            <CheckboxInput
              checked={table.getIsAllPageRowsSelected()}
              indeterminate={table.getIsSomePageRowsSelected()}
              onChange={table.getToggleAllPageRowsSelectedHandler()}
            />
          </label>
        ),
        cell: ({ row }) =>
          row.getCanSelect() && (
            <div onClick={(e) => e.stopPropagation()}>
              <label className="p-0 max-lg:relative max-lg:after:block max-lg:after:absolute max-lg:after:-inset-4 max-lg:after:cursor-pointer">
                <CheckboxInput checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
              </label>
            </div>
          ),
        meta: {
          styles: { size: 'max-content' },
          mobileStyles: { size: 'max-content' },
        },
      }),

      // Name
      columnHelper.accessor(({ name }) => `${name.nl ?? ''} ${name.fr ?? ''} ${name.en ?? ''}`, {
        id: ColumnIds.productsName,
        header: t('products:overview.columns.name'),
        cell: ({ row: { original: product } }) => {
          const translation = product.name[language];
          const isSelectable = isProductSelectable({ name: translation, status: product.status });

          return (
            <div className={classNames('lg:truncate', !isSelectable && 'text-medium-gray', !translation && 'italic')}>
              {translation ?? t('products:modal.missingTranslations')}
            </div>
          );
        },
        meta: {
          styles: { size: '2fr', minSize: 100 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Status
      columnHelper.accessor('status', {
        id: ColumnIds.productsStatus,
        header: t('products:overview.columns.status.title'),
        cell: ({ getValue }) => {
          const status = getValue();

          return (
            <IconTooltip
              color={status === 'Approved' ? 'text-success-500' : status === 'Rejected' ? 'text-error-500' : 'text-warning-500'}
              disableSafePolygon
              iconName={status === 'Approved' ? 'Check' : status === 'Rejected' ? 'Close' : 'HourglassEmpty'}
              text={t(`products:overview.columns.status.options.${status}`)}
            />
          );
        },
        filterFn: 'equalsOneOf',
        sortingFn: (a, b) => {
          const priority = { InReview: 3, Approved: 2, Rejected: 1 } as const;
          return priority[a.original.status] > priority[b.original.status] ? -1 : 1;
        },
        enableGlobalFilter: false,
        meta: {
          styles: { size: 'max-content', minSize: 75, justify: 'center' },
          mobileStyles: { size: 'max-content' },
          isResizable: true,
        },
      }),

      // Activity type
      columnHelper.accessor(({ activityType }: Product) => t(`lines:creativeWork.options.${activityType}`), {
        id: ColumnIds.productsActivityType,
        header: t('products:overview.columns.creativeWork'),
        cell: ({ getValue }) => <div className="lg:truncate">{getValue()}</div>,
        filterFn: 'equalsOneOf',
        enableGlobalFilter: false,
        meta: {
          styles: { size: 'max-content', minSize: 100, justify: 'center' },
          isResizable: true,
        },
      }),

      // Price
      columnHelper.accessor(({ price }) => price ?? undefined, {
        id: ColumnIds.productsPrice,
        header: t('products:overview.columns.price'),
        cell: ({ getValue }) => {
          const price = getValue();

          return <div className="lg:truncate">{price ? formatCurrency(price) : '-'}</div>;
        },
        sortUndefined: 'last',
        meta: {
          styles: { size: '1fr', minSize: 100, justify: 'end' },
          isResizable: true,
        },
      }),

      // Categories
      columnHelper.accessor(({ productCategories }: Product) => (productCategories.length > 0 ? productCategories : undefined), {
        id: ColumnIds.productsCategories,
        header: t('products:overview.columns.productCategories'),
        cell: ({ row: { original: product } }) => <div className="lg:truncate">{product.productCategories.join(', ')}</div>,
        filterFn: 'arrIncludesSome',
        sortUndefined: 'last',
        meta: {
          styles: { size: '2fr', minSize: 100 },
          isResizable: true,
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: product } }) => <ProductsTableOptions product={product} />,

        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [formatCurrency, language, t],
  );
};

export const useProductsTableFilters = (products: Product[]) => {
  const productCategories = useProductCategories();

  const { t } = useTranslation(['lines', 'products']);
  const { compareForSort } = useIntl();

  const statusOptions = useMemo(
    () =>
      (['Approved', 'InReview', 'Rejected'] satisfies Product['status'][]).map((status) => ({
        label: t(`products:overview.columns.status.options.${status}`),
        value: status,
      })),
    [t],
  );

  const activityTypeOptions = useMemo(
    () =>
      [...new Set(products.map(({ activityType }) => activityType))].sort(compareForSort).map((activityType) => ({
        label: t(`lines:creativeWork.options.${activityType}`),
        value: t(`lines:creativeWork.options.${activityType}`),
      })),
    [compareForSort, products, t],
  );

  const categoryOptions = useMemo(
    () =>
      productCategories.map((category) => ({
        label: category,
        value: category,
      })),
    [productCategories],
  );

  return useMemo(
    () => ({
      statuses: statusOptions,
      activityTypes: activityTypeOptions,
      categories: categoryOptions,
    }),
    [activityTypeOptions, categoryOptions, statusOptions],
  );
};
