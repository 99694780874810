import type { ControllerFieldState, ControllerRenderProps, FieldPath, FieldValues } from 'react-hook-form';

import { useId, useMemo } from 'react';
import { useController } from 'react-hook-form';

import type { FormControlProps as Props } from './types';

import { FormControlContext } from '../context';

export const FormControl = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  as,
  children,
  ...props
}: Props<TFieldValues, TName>) => {
  const { field, fieldState } = useController(props);

  const memoizedField = useMemo(
    () =>
      ({
        name: field.name,
        onChange: field.onChange,
        onBlur: field.onBlur,
        ref: field.ref,
        value: field.value,
      }) satisfies ControllerRenderProps,
    [field.name, field.onBlur, field.onChange, field.ref, field.value],
  );

  const memoizedFieldState = useMemo(
    () =>
      ({
        error: fieldState.error,
        invalid: fieldState.invalid,
        isDirty: fieldState.isDirty,
        isTouched: fieldState.isTouched,
        isValidating: fieldState.isValidating,
      }) satisfies ControllerFieldState,
    [fieldState.error, fieldState.invalid, fieldState.isDirty, fieldState.isTouched, fieldState.isValidating],
  );

  const uuid = useId();
  const id = `${props.name}_${uuid}`;

  const context = useMemo(
    () => ({
      field: memoizedField,
      fieldState: memoizedFieldState,
      id,
    }),
    [id, memoizedField, memoizedFieldState],
  );

  const Wrapper = as ?? 'div';

  return (
    <FormControlContext.Provider value={context}>
      <Wrapper>{children}</Wrapper>
    </FormControlContext.Provider>
  );
};
