import { Trans, useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { useUser } from '~/api/user';
import { Button } from '~/components';
import { SearchParamModals } from '~/constants/url';
import { useModal } from '~/hooks/useModal';
import { useShouldOpenModal } from '~/hooks/useShouldOpenModal';

import profileStyles from '../Profile.module.scss';
import { EditVatRegulationModal } from './EditVatRegulationModal/EditVatRegulationModal';
import { VatExemptRevenueProgress } from './VatExemptRevenueProgress/VatExemptRevenueProgress';

export const VatRegulation = () => {
  const { data: user } = useUser();

  const { t } = useTranslation(['settings']);

  const modal = useModal(useShouldOpenModal(SearchParamModals.VAT_REGULATION));

  return (
    <>
      {match(user.vatExempt)
        .with(true, () => (
          <p className="mb-4">
            <Trans i18nKey="settings:profile.vatRegulation.info.vatExempt" />
          </p>
        ))
        .with(false, () => (
          <p>
            <Trans i18nKey="settings:profile.vatRegulation.info.vatNormal" />
          </p>
        ))
        .with(null, () => <p className="text-medium-gray italic">{t('settings:profile.vatRegulation.info.none')}</p>)
        .exhaustive()}

      {user.vatExempt && <VatExemptRevenueProgress />}

      <div className={profileStyles['button-group']}>
        <Button icon="Edit" onClick={modal.open}>
          {t('settings:profile.vatRegulation.edit')}
        </Button>
      </div>

      {modal.isOpen && <EditVatRegulationModal onClose={modal.close} />}
    </>
  );
};
