import classNames from 'classnames';

import type { CommunityProfileCardSkeletonProps } from './types';

import styles from './CommunityProfileCard.module.scss';

export const CommunityProfileCardSkeleton = ({ animate = false }: CommunityProfileCardSkeletonProps) => {
  return (
    <article className={classNames(styles.Skeleton, animate && styles.AnimatePulse)}>
      <div className={styles.SkeletonLogo} />
    </article>
  );
};
