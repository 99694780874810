import { useMatch, useParams } from 'react-router-dom';

import type { Params } from '~/providers/RouterProvider/router.types';

import { useProducts } from '~/api/products';
import { Redirect } from '~/components/Functional';
import { routes } from '~/providers/RouterProvider/router.routes';

import { CreateEditProductForm } from './CreateEditProductForm';

export const CreateEditProduct = () => {
  const { data: products } = useProducts();

  const { productId } = useParams<Params<typeof routes.editProduct>>();
  const isEdit = !!useMatch(routes.editProduct);

  if (!isEdit) return <CreateEditProductForm />;

  const product = products.find((product) => product.id === Number(productId));

  if (!product) return <Redirect to={routes.products} />;

  return <CreateEditProductForm isEdit product={product} />;
};
