import { useTranslation } from 'react-i18next';

import { IconTooltip } from '~/components';

import type { LabelProps as Props } from './types';

import styles from './Label.module.scss';

export const Label = ({ children, id, optional = false, tooltip }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.Wrapper}>
      <label className={styles.Label} htmlFor={id}>
        <span>{children}</span>
        {optional && <span className={styles.Optional}>{t('common:optionalInput')}</span>}
      </label>

      {tooltip && <IconTooltip text={tooltip} />}
    </div>
  );
};
