import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { billitAuthorizationUrlSchema } from './schemas';

export const useBillitAuthorizationUrl = () => {
  return useMutation({
    mutationFn: async () => {
      const token = getAccessToken();
      const response = await request('/oauth2/authorization/billit', { token });

      const { redirectUrl } = billitAuthorizationUrlSchema.parse(response);

      return redirectUrl;
    },
  });
};
