import { useTranslation } from 'react-i18next';

import { PlanId } from '~/api/plans/constants';
import { useUser } from '~/api/user';
import { PageHeader } from '~/components';

import { CommissionInfo } from './CommissionInfo/CommissionInfo';
import { PlanPicker } from './PlanPicker/PlanPicker';

export const PaymentPlan = () => {
  const { data: user } = useUser();
  const { t } = useTranslation(['common', 'settings']);

  return (
    <>
      <PageHeader description={t('settings:paymentPlan.header.description')} title={t('settings:paymentPlan.header.title')} />

      <CommissionInfo />

      {user.planId !== PlanId.earlyAdopter && <PlanPicker user={user} />}
    </>
  );
};
