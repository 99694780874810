import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useDeleteInvoice } from '~/api/invoices';
import { Button, Modal } from '~/components';

import type { DeleteDraftModalProps as Props } from './types';

export const DeleteDraftInvoiceModal = ({ invoiceId, onClose }: Props) => {
  const mutation = useDeleteInvoice(invoiceId);

  const { t } = useTranslation(['common', 'invoices']);

  const deleteDraftInvoice = () => {
    mutation.mutate(undefined, {
      onSuccess: () => toast.success(t('invoices:alerts.successDeleted')),
      onError: () => toast.error(t('common:error')),
      onSettled: () => onClose(),
    });
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('common:draft.deleteModal.title')}</Modal.Title>

      <p>{t('common:draft.deleteModal.description')}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Delete" isLoading={mutation.isPending} onClick={deleteDraftInvoice}>
          {t('common:draft.deleteModal.submit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
