import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';

import { Icon } from '~/components/SVG';

import type { TabSectionProps as Props } from './types';

import styles from './TabSection.module.scss';

export const TabSection = ({ tabs }: Props) => {
  return (
    <TabGroup>
      <TabList as="nav" className={styles['tab-navigation']}>
        {tabs.map(({ title }, i) => (
          <Tab as={Fragment} key={i}>
            {({ selected }) => (
              <button className={classNames(styles['tab-title'], { [styles.active]: selected })}>
                <span className={styles.label}>{title}</span>
                <Icon name={selected ? 'KeyboardArrowUp' : 'KeyboardArrowDown'} />
              </button>
            )}
          </Tab>
        ))}
      </TabList>

      <TabPanels as="section">
        {tabs.map((tab, i) => (
          <TabPanel key={i}>{tab.component}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
};
