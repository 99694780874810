import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UploadInvoiceAttachmentPayload } from './types';

import { request } from '../request';
import { invoiceAttachmentQueryOptions } from './useInvoiceAttachment';

export const useUploadInvoiceAttachment = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: UploadInvoiceAttachmentPayload) => {
      const token = getAccessToken();
      return request(`/invoices/${id}/attachment`, { method: 'POST', token, body });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(invoiceAttachmentQueryOptions(id));
    },
  });
};
