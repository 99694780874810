import type { ReactNode } from 'react';

import { Button } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { useMemo, useState } from 'react';
import { match } from 'ts-pattern';

import type { IconName } from '~/components/SVG';

import { Icon } from '~/components/SVG';

import { AlertContext } from './Alert.context';
import { styles } from './Alert.styles';

type Props = {
  children: ReactNode;
  dismissable?: boolean;
  icon?: IconName;
  onDismiss?: () => void;
  variant: 'success' | 'info' | 'warning' | 'error';
};

export const Alert = ({ children, dismissable = false, icon, onDismiss, variant }: Props) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const [ariaLabelId, setAriaLabelId] = useState('');
  const [ariaDescriptionId, setAriaDescriptionId] = useState('');

  const context = useMemo(() => ({ setAriaLabelId, setAriaDescriptionId }), []);

  if (isDismissed) return null;

  return (
    <AlertContext.Provider value={context}>
      {/* TODO: add themes to set link colors based on variant, e.g. `variant === 'success' && successTheme` where linkColor is defined as statusColors.success500 */}
      <div {...stylex.props(styles.alert, styles[variant])} aria-describedby={ariaDescriptionId} aria-labelledby={ariaLabelId}>
        <div {...stylex.props(styles.iconWrapper)}>
          <Icon
            name={
              icon ??
              match(variant)
                .returnType<IconName>()
                .with('success', () => 'Check')
                .with('info', () => 'InfoOutlined')
                .with('warning', () => 'ErrorOutline')
                .with('error', () => 'Cancel')
                .exhaustive()
            }
            size={18}
          />
        </div>

        <div {...stylex.props(styles.contentWrapper)}>{children}</div>

        {dismissable && (
          <Button
            {...stylex.props(styles.closeButton)}
            onClick={() => {
              setIsDismissed(true);
              onDismiss?.();
            }}
          >
            <Icon name="Close" size={16} />
          </Button>
        )}
      </div>
    </AlertContext.Provider>
  );
};
