import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

import type { InvoiceQuotationFormLine } from '~/types/invoiceQuotation';

export const LineContext = createContext<{
  isMobile: boolean;
  isProductLine: boolean;
  line: InvoiceQuotationFormLine & { id: string };
  lineFieldName: `sections.${number}.lines.${number}`;
  lineIndex: number;
} | null>(null);

export const useLineContext = () => {
  const context = useContext(LineContext);

  invariant(context, 'Line components must be wrapped in <SectionLines />');

  return context;
};
