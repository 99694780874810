import * as stylex from '@stylexjs/stylex';

import type { MediaQueryLaptopAndBelow, MediaQueryTabletAndBelow } from '~/styles/mediaQueries';

import { spacing, systemColors } from '~/styles/tokens.stylex';

const TabletAndBelow: MediaQueryTabletAndBelow = '@media (width < 1024px)';
const LaptopAndBelow: MediaQueryLaptopAndBelow = '@media (width < 1280px)';

export const styles = stylex.create({
  authLayout: {
    minHeight: '100dvh',
    padding: spacing.normal,
    display: 'grid',
    gridTemplateColumns: {
      default: '3fr 4fr',
      [LaptopAndBelow]: '1fr 1fr',
    },
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateAreas: {
      default: `
        'header header'
        'sidebar content'
        'sidebar footer'
      `,
      [TabletAndBelow]: `
        'header header'
        'content content'
        'sidebar sidebar'
        'footer footer'
      `,
    },
    columnGap: spacing.normal,
    rowGap: {
      default: spacing.normal,
      [TabletAndBelow]: spacing.xlarge,
    },
    color: systemColors.textDark,
  },
});
