import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Brand } from '~/types/user';

import { Icon } from '~/components/SVG';
import { ColumnIds } from '~/constants/table';

import { BrandsTableOptions } from './BrandsTableOptions';

const columnHelper = createColumnHelper<Brand>();

export const useBrandsTableColumns = () => {
  const { t } = useTranslation(['common', 'settings']);

  return useMemo(
    () => [
      // Logo
      columnHelper.display({
        id: ColumnIds.brandsLogo,
        header: t('settings:profile.brands.headers.logo'),
        cell: ({ row: { original: brand } }) => {
          if (!brand.logo) return null;

          return (
            <img
              alt={`Logo ${brand.companyName}`}
              className="object-contain max-h-12 lg:max-w-3/4 lg:max-h-20" // Make logos scale when resizing the column, but limit the max height
              src={brand.logo}
            />
          );
        },
        meta: {
          styles: { size: '100px', minSize: 50 },
          isResizable: true,
        },
      }),

      // Company name
      columnHelper.accessor('companyName', {
        id: ColumnIds.brandsName,
        header: t('settings:profile.brands.headers.name'),
        cell: ({ getValue, row: { original: brand } }) => (
          <div className="flex items-center gap-x-1">
            {getValue()}
            {brand.isFavorite && <Icon name="Star" size={20} />}
          </div>
        ),
        meta: {
          styles: { size: '1fr', minSize: 150 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Email
      columnHelper.accessor('email', {
        id: ColumnIds.brandsEmail,
        header: t('settings:profile.brands.headers.email'),
        cell: ({ getValue }) => <div className="lg:truncate">{getValue()}</div>,
        meta: {
          styles: { size: '1fr', minSize: 150 },
          isResizable: true,
        },
      }),

      // Color
      columnHelper.display({
        id: ColumnIds.brandsColor,
        header: t('settings:profile.brands.headers.color'),
        cell: ({ row: { original: brand } }) =>
          brand.id === 0 ? (
            <div className="lg:truncate">{t('settings:profile.brands.mainBrand')}</div>
          ) : (
            <div className="size-5 rounded-full max-lg:mr-[7px]" style={{ backgroundColor: brand.color }} />
          ),
        meta: {
          styles: { size: 'max-content', minSize: 100, justify: 'center' },
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: brand } }) => <BrandsTableOptions brand={brand} />,
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [t],
  );
};
