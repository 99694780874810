import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';
import { checkVatNumberInUseSchema } from './schemas';

export const useCheckVatNumberInUse = () => {
  return useMutation({
    mutationFn: async (vatNumber: string) => {
      const body = { vatNumber };
      const response = await request('/registration/vat-number-in-use', { method: 'POST', body });

      return checkVatNumberInUseSchema.parse(response);
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) &&
      captureException(new Error('Failed to check VAT number during registration', { cause: error }), { level: 'fatal' }),
  });
};
