import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';

import type { Client, CreateClientPayload } from '~/api/clients/types';

import { useCreateOrganisationClient, useCreatePrivateClient } from '~/api/clients';
import { SearchParamKeys } from '~/constants/url';
import { routes } from '~/providers/RouterProvider/router.routes';
import { qs } from '~/utils/searchParams';

import type { CreateClientContext } from './context';

export const CreateClient = () => {
  const createOrganisationClientMutation = useCreateOrganisationClient();
  const createPrivateClientMutation = useCreatePrivateClient();

  const [organisation, setOrganisation] = useState<CreateClientContext['organisation']>(null);
  const [establishmentUnits, setEstablishmentUnits] = useState<CreateClientContext['establishmentUnits']>([]);
  const [contactFormData, setContactFormData] = useState<CreateClientContext['contactFormData']>(null);

  const resetClientContext = useCallback(() => {
    setOrganisation(null);
    setEstablishmentUnits([]);
    setContactFormData(null);
  }, []);

  const isPrivateRoute = !!useMatch(routes.createClientPrivateStep);

  const navigate = useNavigate();
  const searchParams = useSearchParams()[0];
  const { t } = useTranslation(['clients', 'common']);

  const onCreate = useCallback(
    (contact: CreateClientPayload['contact']) => {
      const mutationOptions = {
        onSuccess: (client: Client) => {
          toast.success(t('clients:alerts.successCreated'));

          const redirectPath = searchParams.get(SearchParamKeys.REDIRECT_PATH);

          navigate(
            redirectPath
              ? {
                  pathname: redirectPath,
                  search: qs({ [SearchParamKeys.CLIENT_ID]: client.id }),
                }
              : routes.clients,
            { state: { blockable: false } },
          );
        },
        onError: () => toast.error(t('common:error')),
      };

      if (isPrivateRoute) {
        return createPrivateClientMutation.mutate({ client: { clientType: 'private' }, contact }, mutationOptions);
      }

      invariant(!!organisation, 'Unexpected missing organisation');

      return createOrganisationClientMutation.mutate({ client: organisation, contact }, mutationOptions);
    },
    [createOrganisationClientMutation, createPrivateClientMutation, isPrivateRoute, navigate, organisation, searchParams, t],
  );

  const context = useMemo(
    () =>
      ({
        organisation,
        setOrganisation,
        establishmentUnits,
        setEstablishmentUnits,
        contactFormData,
        setContactFormData,
        onCreate,
        isLoading: createOrganisationClientMutation.isPending || createPrivateClientMutation.isPending,
        resetClientContext,
      }) satisfies CreateClientContext,
    [
      organisation,
      establishmentUnits,
      contactFormData,
      onCreate,
      createOrganisationClientMutation.isPending,
      createPrivateClientMutation.isPending,
      resetClientContext,
    ],
  );

  return <Outlet context={context} />;
};
