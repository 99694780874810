import classNames from 'classnames';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { Modal } from '~/components';
import { useIntl } from '~/hooks/useIntl';

import type { CommunityProfileModalProps as Props } from './types';

import { CommunityProfileFooter } from '../CommunityProfileFooter/CommunityProfileFooter';
import { CommunityProfilePicture } from '../CommunityProfilePicture/CommunityProfilePicture';
import styles from './CommunityProfileModal.module.scss';

export const CommunityProfileModal = ({ onClose, profile }: Props) => {
  const { t } = useTranslation(['common', 'community']);

  const { formatConjunctiveList } = useIntl();

  return (
    <Modal onClose={onClose}>
      <article className={styles.Wrapper} data-css-container="community-profile-modal">
        <header className={styles.Header}>
          <div className={styles.LogoWrapper}>
            <CommunityProfilePicture firstName={profile.firstName} lastName={profile.lastName} url={profile.profilePictureUrl} />
          </div>

          <div className={styles.TextWrapper}>
            <span className={styles.Brand}>{profile.brand ?? `${profile.firstName} ${profile.lastName}`}</span>
            {profile.brand ? <span className={styles.Name}>{`${profile.firstName} ${profile.lastName}`}</span> : ''}
            {profile.openToWork && <span className={styles.OpenToWork}>{t('community:profileCard.openToWork')}</span>}
            {profile.regions.length > 0 && (
              <p>
                {t('community:profileCard.activeInRegion', {
                  regions: formatConjunctiveList(profile.regions.map((region) => t(`community:regions.${region}`))),
                })}
              </p>
            )}
          </div>
        </header>

        <div>
          <ul className={styles.Activities}>
            {profile.activities.map((activity, i) => (
              <li className={classNames(styles.Activity, activity.isPrimaryActivity && styles.Primary)} key={i}>
                {t(`common:activities.${activity.id}.label`)}
              </li>
            ))}
          </ul>
        </div>

        {profile.description && (
          <>
            <hr />
            <div className={styles.Description}>{parse(profile.description)}</div>
            <hr />
          </>
        )}

        <CommunityProfileFooter profile={profile} />
      </article>
    </Modal>
  );
};
