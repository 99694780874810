import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { FormControl, FormErrorMessage, FormLabel, FormSelect } from '~/components';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { CreativeWork } from '~/types/app';
import { insertIf } from '~/utils/arrays';
import { isItDeveloper } from '~/utils/user';

import { useSectionContext } from '../../Section/context';
import { useLineContext } from '../../SectionLines/context';

export const FormActivityType = () => {
  const { data: user } = useUser();

  const { control } = useInvoiceQuotationFormContext();
  const { section } = useSectionContext();
  const { isMobile, isProductLine, lineFieldName } = useLineContext();

  const { t } = useTranslation(['lines', 'validation']);

  return (
    <FormControl control={control} name={`${lineFieldName}.creativeWork`} rules={{ required: true }}>
      {isMobile && <FormLabel>{t('lines:creativeWork.header')}</FormLabel>}

      <FormSelect
        disabled={isProductLine}
        options={(
          [
            CreativeWork.CREATIVE,
            ...insertIf(isItDeveloper(user) && !section.isPhotoAlbum, CreativeWork.CREATIVE_COMPUTER),
            CreativeWork.NO_CREATIVE,
            CreativeWork.REUSAGE,
            CreativeWork.SUBCONTRACTING,
          ] as const
        ).map((creativeWork) => ({ value: creativeWork, label: t(`lines:creativeWork.options.${creativeWork}`) }))}
        placeholder={t('lines:creativeWork.placeholder')}
      />

      {isMobile && <FormErrorMessage required={t('validation:required')} />}
    </FormControl>
  );
};
