import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const Person2 = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="Referral 1"
      viewBox="538.15 325.53 579.93 326.13"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <g>
        <g>
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M1027.58,524.26s0,0,0,0c-6.69-9.52-18.14-18.76-37.45-19.23-10.4-.25-19.78,1.28-27.98,3.69-9.45,8.28-14.49,17.98-16.73,27.63-3.84-3.59-7.51-6.83-10.3-8.88-1.4-1.03-2.78-2.01-4.12-2.94-8.92-6.17-16.5-10.16-21.67-12.56-5.22-2.42-10.9-3.69-16.65-3.67-4.93.03-9.34.7-13.32,1.9h0c-11.97,3.6-20.11,11.95-27.03,21.82-3.64,5.18-6.94,10.78-10.3,16.32,4.04,1.66,9.66,8.81,15.92,19.62,4.96,8.55,10.33,19.4,15.65,31.64,8.44,19.42,22.22,37.19,42.4,46.09,1.33.59,2.7,1.14,4.08,1.64,7.59,2.77,16.02,4.33,25.34,4.33,8.83,0,17.91-2.18,26.71-6.32,5.24-2.46,10.39-5.62,15.31-9.43,11.55-8.92,21.88-21.4,29.5-36.79.8-1.63,1.58-3.29,2.32-4.98,5.21-11.86,8.83-25.3,10.27-40.05.19-1.95.32-3.87.39-5.76.33-8.71-.57-16.73-2.33-24.07Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M783.35,381.05c-2.04,2.38-3.87,5.03-5.37,7.9-.71,1.36-1.23,2.67-1.58,3.93-2.62.84-5.48,2.04-8.54,3.73-2.27,1.25-4.49,2.31-6.65,3.19-4.78,1.97-9.25,3.08-13.29,3.5-8.23.84-14.64-1.25-18.1-5.05-5.45-5.99-3.8-14.03.91-19.51,3.44,1.72,7.91,2.58,13.15,2.07.64-.06,1.28-.13,1.92-.19,14.63-.31,29.68-.72,37.54.44Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M775.77,413.87c-.94,1.45-2.08,2.78-3.42,3.88-3.24,2.67-7.63,4.02-13.12,2.67-.89-.22-1.72-.49-2.5-.81-7.09-2.92-9.27-9.8-8.79-16.32,4.03-.41,8.51-1.53,13.28-3.5,7.58.35,12.03,2.22,13.77,7.15.43,1.21,1.07,4.4.78,6.93Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M781.96,420.24c-.11.8-.28,1.65-.52,2.54-.54,2.05-1.4,3.69-2.46,4.99-2.44,2.97-6,4.07-9.48,3.95,4.12-4.54,3.64-10.49,2.85-13.96,1.34-1.1,2.48-2.43,3.42-3.88,1-1.55,1.78-3.24,2.32-4.95,2.63,2.22,4.65,5.78,3.87,11.31Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M769.5,431.71h0c-2.55-.09-5.06-.85-7.06-2.02-4.15-2.42-5.61-6.53-5.7-10.08.77.32,1.61.59,2.5.81,5.49,1.35,9.88,0,13.12-2.67.79,3.47,1.27,9.42-2.85,13.96Z"
          />
          <line {...stylex.props(styles.stroke)} x1="787.08" x2="787.08" y1="432.2" y2="432.2" />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M847.38,433.31c-.05,3.65-2.07,6.91-5.39,9.68-4.73-5.99-16.52-6.21-24.97-5.48,1.49-4.56,3.03-9.63,4.43-14.9,13.94-.36,26.03,3.21,25.94,10.7Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M788.67,375.82c-1.87,1.54-3.66,3.3-5.32,5.23-7.86-1.16-22.91-.75-37.54-.44,14.58-1.53,30.09-4.62,42.86-4.79Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            d="M841.99,442.98c-6.7,5.58-18.69,9.17-30.4,9.93,1.35-3.54,3.34-8.97,5.43-15.4,8.45-.73,20.24-.51,24.97,5.48Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M852.63,475.43c-11.11,3.94-21.28,8.35-30.69,13.15-26.25,7.04-48.89,14.93-68.97,23.42,7.48-25.41,16.62-48.56,27.8-68.94,2.69,4.84,8.69,7.79,16.04,9.13h0c4.56.83,9.64,1.05,14.78.72,11.71-.75,23.7-4.34,30.4-9.93,3.33-2.77,5.35-6.03,5.4-9.68,1.77,7.22,3.12,14.36,4.01,21.42.87,6.98,1.3,13.88,1.23,20.7Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M796.81,452.19c-7.35-1.34-13.35-4.28-16.04-9.13,2.03-3.71,4.14-7.33,6.31-10.86.22,7.85,2.15,15.02,9.73,19.99Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M821.94,488.58c-32.6,16.61-56.15,37.81-78.26,59.74h0c2.78-12.55,5.86-24.67,9.29-36.32,20.09-8.5,42.73-16.38,68.97-23.42Z"
          />
          <line {...stylex.props(styles.stroke)} x1="879.33" x2="879.33" y1="510.19" y2="510.18" />
          <path
            {...stylex.props(styles.stroke)}
            d="M945.41,536.34c4.2,3.94,8.6,8.3,12.21,11.99h0c3,3.07,5.47,5.66,6.83,7.15,3.19,3.48,5.9,3.18,6.32,2.92"
          />
          <line {...stylex.props(styles.stroke)} x1="879.33" x2="879.33" y1="510.19" y2="510.18" />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M879.33,510.19c-11.97,3.6-20.11,11.96-27.03,21.82-11.63,2.24-23.26,7.68-34.89,16.32,2.37-6.98,7.77-16.99,20.11-26.31,15.16-11.45,30.54-12.99,41.81-11.83Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M962.14,508.71c-9.45,8.28-14.49,17.98-16.73,27.63-3.84-3.59-7.52-6.83-10.3-8.88-1.4-1.03-2.78-2.01-4.12-2.94,6.24-4.78,16.92-11.62,31.16-15.81Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M801.17,416.81c-5.22,5.29-13.97,4.52-19.2,3.43.77-5.53-1.25-9.09-3.87-11.31.45-1.41.74-2.84.87-4.21,2.42,2.52,6.05,4.03,10.14,4.24,7.36.37,14.86-2.96,19.52-10.71,0,0,.35,10.65-7.46,18.57Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M778.96,404.71c-.13,1.38-.42,2.8-.87,4.21-.54,1.71-1.32,3.4-2.32,4.95.29-2.53-.35-5.72-.78-6.93-1.74-4.92-6.19-6.8-13.77-7.15,2.16-.89,4.38-1.94,6.65-3.19,3.06-1.68,5.92-2.89,8.54-3.73-1.37,4.91-.17,9,2.56,11.83Z"
          />
          <line {...stylex.props(styles.stroke)} x1="769.5" x2="769.5" y1="431.71" y2="431.71" />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M852.3,532c-3.64,5.18-6.94,10.78-10.3,16.32h-24.59c11.63-8.65,23.26-14.09,34.89-16.32Z"
          />
          <polygon {...stylex.props(styles.stroke, styles.fillBlack)} points="796.81 452.19 796.81 452.19 796.81 452.19 796.81 452.19" />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M821.44,422.6c-1.4,5.27-2.94,10.34-4.42,14.9-2.09,6.43-4.08,11.86-5.43,15.4-5.13.33-10.21.11-14.77-.72h0c-7.58-4.96-9.52-12.13-9.73-19.99-3.35-1.04-6.11-2.69-8.1-4.44,1.07-1.29,1.92-2.93,2.47-4.98.24-.89.41-1.73.52-2.54,5.23,1.09,13.98,1.85,19.2-3.44,7.81-7.91,7.46-18.57,7.46-18.57-4.67,7.75-12.17,11.08-19.53,10.71-4.09-.2-7.72-1.72-10.14-4.24-2.73-2.83-3.93-6.93-2.56-11.83,0,0,5.35,3.87,8.84,2.9,3.49-.97,6.27-6.87,5.9-9.64-.36-2.77-7.8-5.09-7.8-5.09,1.66-1.94,3.45-3.69,5.32-5.23-12.76.17-28.28,3.26-42.86,4.79-.64.07-1.28.13-1.92.19-5.24.5-9.71-.35-13.15-2.07-8.39-4.21-10.59-13.61-2.64-21.14,5.54-5.25,33.05-12.12,55.81-10.62,23.27,1.53,38.93,10.62,42.39,36.3,1.46,10.85-1.25,25.74-4.87,39.33Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M785.25,395.78c-3.49.96-8.84-2.9-8.84-2.9.35-1.26.87-2.57,1.58-3.93,1.5-2.87,3.33-5.52,5.36-7.9,0,0,7.44,2.32,7.8,5.09.36,2.77-2.41,8.67-5.9,9.64Z"
          />
          <path {...stylex.props(styles.stroke)} d="M808.63,398.24c1.6-2.65,2.87-5.82,3.69-9.54" />
          <path {...stylex.props(styles.stroke)} d="M788.67,375.82c4.91-4.07,10.31-6.69,14.99-7.36" />
          <path {...stylex.props(styles.stroke)} d="M787.08,432.2h0c4.57,1.41,10.24,1.68,16.59-.49" />
        </g>
        <line {...stylex.props(styles.stroke)} x1="743.67" x2="743.67" y1="548.33" y2="548.33" />
        <path
          {...stylex.props(styles.stroke, styles.fillWhite)}
          d="M1027.57,524.25c-6.69-9.52-18.14-18.76-37.45-19.23-10.4-.25-19.78,1.28-27.98,3.69-14.24,4.19-24.92,11.03-31.16,15.81-8.92-6.17-16.5-10.16-21.67-12.56-5.22-2.42-10.9-3.69-16.66-3.67-4.93.02-9.34.7-13.32,1.89h0c-11.27-1.16-26.65.38-41.81,11.83-12.33,9.32-17.74,19.32-20.11,26.31h-73.73c22.12-21.93,45.66-43.13,78.26-59.74,9.41-4.8,19.58-9.21,30.69-13.15,0,0,22.16-10.03,55.63-14.44,1.91,19.1,28.71,20.92,72.76,6.22,36.52,17.51,46.55,57.03,46.55,57.03Z"
        />
        <path
          {...stylex.props(styles.stroke, styles.fillPrimary)}
          d="M981.02,467.22c-44.05,14.7-70.85,12.88-72.76-6.22,11.54-1.52,24.43-2.37,38.21-1.91,13.54.45,24.96,3.53,34.55,8.13Z"
        />
        <g>
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M957.98,367.6c-3.87,2.02-7.36,4.98-10.2,8.68-7.35-3.49-14.06-6.79-20.13-10.22-3.74-2.11-7.23-4.27-10.5-6.56-1.66-1.16-3.25-2.35-4.78-3.59,1.17-1.88,2.45-3.66,3.81-5.34,1.87-2.31,3.9-4.43,6.07-6.33.14.29.29.56.44.83,1.52,2.64,18.5,15.14,35.28,22.54Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M962.91,429.47c-9.38,3.5-19.67,3.9-29.45.45-15.29-5.39-25.64-18.9-28.66-34.84.03.02.06.05.1.08,1.94,1.54,3.95,2.92,6.04,4.17,4.38,2.62,9.09,4.63,14.12,6.13.09.03.18.05.26.08h0c2.76.82,5.63,1.48,8.59,2.01,2.68.48,5.44.84,8.28,1.11,2.3,6.94,6.83,12.71,12.9,15.98l4.91,3.15.89.57c.65.41,1.33.78,2.02,1.12Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M985.22,401.94c-1.08,4.66-5.23,7.67-9.27,6.74-4.05-.94-6.45-5.47-5.37-10.13,1.08-4.66,5.23-7.68,9.27-6.74,4.05.94,6.45,5.47,5.38,10.13Z"
          />
          <path {...stylex.props(styles.stroke, styles.fillWhite)} d="M989.51,372.53c-.49-.3-.99-.57-1.5-.83,0-.03,0-.07,0-.1l1.49.94Z" />
          <path
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            d="M959.73,366.77c-.59.26-1.17.54-1.75.84-16.79-7.39-33.76-19.89-35.28-22.54-.15-.27-.3-.54-.44-.83-3-6.11-3.3-17.11,14.57-17.52,3.21-.07,6.97.18,11.01.71,1.38.18,2.79.39,4.22.63,2.52.75,4.61,2.16,6.21,4.35h0c4.14,5.66,5.01,16.5,1.45,34.35Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            d="M999.1,379.25c-.75.01-1.5,0-2.26-.02-.05-.06-.1-.13-.15-.19-1.88-2.46-4.13-4.56-6.7-6.2l-.49-.31-1.49-.94c.51-8.11-.16-16.66-2.33-24.08,0,0,0,0,0,0-1.23-4.24-2.96-8.12-5.22-11.34,8.43,3.59,14.94,7.88,16.66,12.32,4.47,11.56,11.66,30.62,1.98,30.75Z"
          />
          <path {...stylex.props(styles.stroke, styles.fillWhite)} d="M989.51,372.53c-.49-.3-.99-.57-1.5-.83,0-.03,0-.07,0-.1l1.49.94Z" />
          <path {...stylex.props(styles.stroke)} d="M996.84,379.22c-.05,0-.1,0-.14,0,0-.06,0-.12,0-.18.05.06.1.13.15.19Z" />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M916.18,350.57c-1.36,1.68-2.64,3.46-3.81,5.34-2.44-1.96-4.72-4.03-6.86-6.24,3.34-.55,6.88-.31,10.67.9Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M925.32,405.54c-.09-.03-.17-.05-.26-.08-5.03-1.5-9.74-3.52-14.12-6.13-2.08-1.25-4.1-2.63-6.04-4.17-.04-.03-.07-.05-.1-.08-3.8-3.02-7.33-6.62-10.57-10.86-.55-9.93,1.16-18.83,5.48-26.54,1.58-2.83,3.51-5.5,5.8-8,2.13,2.21,4.42,4.28,6.86,6.24,1.53,1.23,3.13,2.43,4.78,3.59.29,15.31,3.01,30.66,8.16,46.04Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            d="M942.19,408.66c-2.84-.27-5.6-.63-8.28-1.11-3.79-14.86-5.76-28.62-6.26-41.49,6.07,3.43,12.78,6.73,20.13,10.22-2.93,3.82-5.15,8.42-6.34,13.58-1.52,6.58-1.14,13.09.75,18.8Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M933.91,407.55c-2.96-.53-5.82-1.19-8.59-2.01-5.16-15.38-7.88-30.73-8.16-46.04,3.26,2.29,6.76,4.45,10.5,6.56.49,12.87,2.46,26.63,6.26,41.49Z"
          />
          <line {...stylex.props(styles.stroke)} x1="917.16" x2="917.16" y1="359.5" y2="359.49" />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M988.02,371.59s0,.07,0,.1c-.59-.31-1.2-.59-1.82-.84h0c-3.04-2.72-6.7-4.69-10.82-5.65-5.35-1.24-10.74-.58-15.64,1.55,3.56-17.86,2.69-28.69-1.45-34.35h0c-1.6-2.19-3.7-3.61-6.21-4.35-1.3-.38-2.71-.59-4.22-.63,0,0,10.85-5.04,24.15,1.32,3.41,1.63,6.21,4.2,8.48,7.42,2.26,3.22,3.99,7.1,5.22,11.34,0,0,0,0,0,0,2.17,7.42,2.84,15.97,2.33,24.08Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M985.22,401.94c-1.08,4.66-5.23,7.67-9.27,6.74-4.05-.94-6.45-5.47-5.37-10.13,1.08-4.66,5.23-7.68,9.27-6.74,4.05.94,6.45,5.47,5.38,10.13Z"
          />
          <path {...stylex.props(styles.stroke)} d="M996.84,379.22c-.05,0-.1,0-.14,0,0-.06,0-.12,0-.18.05.06.1.13.15.19Z" />
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M986.18,370.85c-1.2-.5-2.46-.9-3.76-1.2-5.37-1.24-10.79-.58-15.7,1.58-8.63,3.79-15.71,12.21-18.22,23.07-2.84,12.29.95,24.35,8.84,31.4.84.75,1.72,1.45,2.66,2.08l-4.91-3.14c-6.07-3.27-10.6-9.03-12.9-15.98-1.89-5.71-2.27-12.22-.75-18.8,1.19-5.15,3.41-9.76,6.34-13.58,2.83-3.7,6.33-6.66,10.2-8.68.57-.3,1.16-.58,1.75-.84,4.9-2.14,10.3-2.79,15.64-1.55,4.12.95,7.77,2.92,10.82,5.64Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M985.22,401.94c-1.08,4.66-5.23,7.67-9.27,6.74-4.05-.94-6.45-5.47-5.37-10.13,1.08-4.66,5.23-7.68,9.27-6.74,4.05.94,6.45,5.47,5.38,10.13ZM996.84,379.22c-.05,0-.1,0-.14,0,0-.06,0-.12,0-.18-1.88-2.46-4.13-4.56-6.7-6.2l-.49-.31c-.49-.3-.99-.57-1.5-.83-.59-.31-1.2-.59-1.82-.84h0c-1.2-.5-2.46-.9-3.76-1.2-5.37-1.24-10.79-.58-15.7,1.58-8.63,3.79-15.71,12.21-18.22,23.07-2.84,12.29.95,24.35,8.84,31.4.84.75,1.72,1.45,2.66,2.08l.89.57c.66.41,1.33.78,2.03,1.12,1.65.81,3.4,1.45,5.24,1.87,14.79,3.42,29.98-7.61,33.93-24.65,2.37-10.24.13-20.32-5.24-27.47Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M985.22,401.94c-1.08,4.66-5.23,7.67-9.27,6.74-4.05-.94-6.45-5.47-5.37-10.13,1.08-4.66,5.23-7.68,9.27-6.74,4.05.94,6.45,5.47,5.38,10.13Z"
          />
          <path
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            d="M985.22,401.94c-1.08,4.66-5.23,7.67-9.27,6.74-4.05-.94-6.45-5.47-5.37-10.13,1.08-4.66,5.23-7.68,9.27-6.74,4.05.94,6.45,5.47,5.38,10.13Z"
          />
          <line {...stylex.props(styles.stroke)} x1="988.27" x2="982.77" y1="371.75" y2="368.29" />
        </g>
      </g>
      <g>
        <path
          {...stylex.props(styles.stroke, styles.fillSecondary)}
          d="M775.28,558.24h-8.2l-37.71-85.99c-1.13-2.59-3.69-4.26-6.52-4.26h-133.01c-1.16,0-2.18.45-2.93,1.16l2.49-2.46c2.84-2.71,6.61-4.22,10.54-4.22h124.13c5.6,0,10.67,3.31,12.92,8.44l33.95,77.43,4.35,9.91Z"
        />
        <path
          {...stylex.props(styles.stroke, styles.fillWhite)}
          d="M686.08,525.29l-6.06-11.88h-11.88l6.06,11.88h-11.88l-12.11-23.76h35.65l12.11,23.76h-11.88Z"
        />
        <path
          {...stylex.props(styles.stroke, styles.fillWhite)}
          d="M769.5,563.76h-144.24l-14.44-32.94-24.91-56.81c-.78-1.78-.26-3.69,1-4.87.76-.71,1.77-1.16,2.93-1.16h133.01c2.83,0,5.38,1.67,6.52,4.26l37.71,85.99,2.42,5.52Z"
        />
        <polygon
          {...stylex.props(styles.stroke, styles.fillWhite)}
          points="786.85 558.24 775.28 558.24 770.93 548.33 782.93 548.33 786.85 558.24"
        />
        <polygon
          {...stylex.props(styles.stroke, styles.fillWhite)}
          points="799.35 558.24 786.85 558.24 782.93 548.33 795.43 548.33 799.35 558.24"
        />
        <polygon
          {...stylex.props(styles.stroke, styles.fillWhite)}
          points="811.85 558.24 799.35 558.24 795.43 548.33 807.93 548.33 811.85 558.24"
        />
        <polygon
          {...stylex.props(styles.stroke, styles.fillWhite)}
          points="824.35 558.24 811.85 558.24 807.93 548.33 820.43 548.33 824.35 558.24"
        />
        <polygon
          {...stylex.props(styles.stroke, styles.fillWhite)}
          points="846.86 558.24 824.35 558.24 820.43 548.33 842 548.33 846.86 558.24"
        />
        <path
          {...stylex.props(styles.stroke, styles.fillBlack)}
          d="M847.25,559.92c0,2.02-1.62,3.84-3.84,3.84h-73.91l-2.42-5.52h79.78c.27.55.4,1.13.4,1.68Z"
        />
        <polygon
          {...stylex.props(styles.fillBlack)}
          points="689.74 521.85 681.74 521.85 677.65 513.84 669.65 513.84 673.73 521.85 665.72 521.85 657.56 505.83 681.58 505.83 689.74 521.85"
        />
      </g>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M589.78,532.13c-11.93.35-57.04,27.17-51.09,39.84,3.32,7.08,38.97-7.09,38.97-7.09,0,0-3.76,14.9,6.43,16.17s30.45-13.73,30.45-13.73c0,0,.44,16.54,18.36,12.68,25.21-5.43,19.88-14.97,19.88-14.97,0,0,28.02-3.38,34.57-12.96,6.56-9.59-36.1-2.52-36.1-2.52,0,0,26.12-15.93,8.49-15.98-17.63-.05-29.01,11.47-29.01,11.47,0,0,7-14.17-7.67-12.85-14.67,1.33-24.28,13.9-24.28,13.9,0,0,2.93-14.32-9-13.97Z"
      />
      <path
        {...stylex.props(styles.stroke, styles.fillBlack)}
        d="M1019.25,594.13c-.74,1.69-1.52,3.35-2.32,4.98-7.62,15.4-17.96,27.87-29.5,36.79-4.11.8-7.92,1.23-10.85,1.01-12.52-.97-6.15-10.98-6.15-10.98,0,0-31.58,5.61-30.97-2.39.62-8,21.3-8.49,21.3-8.49,0,0-.51-7.05,13.36-9.83,13.86-2.78,22.72,4.9,22.72,4.9,0,0,2.97-11.27,20.45-15.57.66-.16,1.31-.3,1.96-.42Z"
      />
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M994.44,599.86s-8.86-7.68-22.72-4.9c-13.86,2.78-13.36,9.83-13.36,9.83,0,0-20.69.49-21.31,8.49-.62,8,30.96,2.39,30.96,2.39,0,0-6.37,10.01,6.15,10.97,12.53.97,41.03-10.13,41.03-10.13,0,0,.69,9.15,15.76,8.92,15.07-.24,27.01-9.81,27.01-9.81,0,0,9.55,12.29,28.66,9.56,19.11-2.72,31-16.16,31.45-22.07.46-5.91-8.75-4.53-8.75-4.53,0,0,6.99-13.46-10.06-14.78-17.05-1.32-24.06,12.49-24.06,12.49,0,0-4.28-8.03-15.73-9.26-11.45-1.23-15.73,4.04-15.73,4.04,0,0-11.37-11.08-28.85-6.78-17.48,4.31-20.45,15.57-20.45,15.57Z"
      />
    </svg>
  );
};
