import type { ChartDataset } from 'chart.js';

import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CollapsibleSection, CustomChart } from '~/components';
import { getInvoiceCalculations, getInvoiceOverviewCalculations } from '~/pages/Dashboard/utils';

import type { ChartsProps as Props } from './types';

import { useStackedBarChartOptions } from './hooks';
import { Legend } from './Legend/Legend';

const overviewPieOptions = { responsive: true, plugins: { legend: { display: false } } };

export const Charts = ({ invoices, user }: Props) => {
  // Local state
  const [renderCharts, setRenderCharts] = useState(false);

  // i18n
  const { t } = useTranslation(['dashboard']);

  // CSS variables
  const style = document && document.body ? getComputedStyle(document.body) : null;
  const primaryColor = style?.getPropertyValue('--color-primary-500') ?? '#004b37';
  const secondaryColor = style?.getPropertyValue('--color-secondary-500') ?? '#ff8ca0';
  const grayColor = '#757575';
  const lightColor = '#F3F3F3';

  /**
   * Triggered everytime primaryColor and secondaryColor changes
   * Sets the renderCharts local state to true if primaryColor & secondaryColor are not empty strings
   */
  useEffect(() => {
    if (primaryColor.trim() !== '' && secondaryColor.trim() !== '') {
      setRenderCharts(true);
    }
  }, [primaryColor, secondaryColor]);

  // Constants
  const months = useMemo(() => Array.from({ length: 12 }, (_, i) => dayjs().day(1).month(i).locale(user.lang).format('MMMM')), [user.lang]);
  const lastActiveYear = useMemo(() => {
    const invoiceYears = invoices.filter((invoice) => !invoice.draft).map((invoice) => invoice.docYear);
    if (invoiceYears.length === 0) return dayjs().year();
    return Math.max(...invoiceYears);
  }, [invoices]);
  const overviewLabels = useMemo(
    () => [
      t('dashboard:charts.labels.expired'),
      t('dashboard:charts.labels.notExpired'),
      t('dashboard:charts.labels.currentQ', { quarter: dayjs().quarter() }),
      t('dashboard:charts.labels.previousQ'),
    ],
    [t],
  );

  // Total Billed data
  const [totalBilledYear, setTotalBilledYear] = useState(lastActiveYear);
  const totalBilledByYear = useMemo(() => getInvoiceCalculations(invoices, totalBilledYear), [invoices, totalBilledYear]);
  const totalBilledDataset = useMemo<ChartDataset[]>(
    () => [
      // PERFORMANCE
      {
        label: t('dashboard:charts.labels.performance'),
        data: totalBilledByYear.performance,
        backgroundColor: primaryColor,
      },
      // COPYRIGHT
      {
        label: t('dashboard:charts.labels.copyright'),
        data: totalBilledByYear.copyright,
        backgroundColor: secondaryColor,
      },
    ],
    [t, totalBilledByYear, primaryColor, secondaryColor],
  );

  // Overview
  const [overviewYear, setOverviewYear] = useState(lastActiveYear);
  const overviewByYear = useMemo(() => {
    const { paidCurrentQ, paidPreviousQ, unpaidExpired, unpaidPending } = getInvoiceOverviewCalculations(invoices, overviewYear);
    return [unpaidExpired, unpaidPending, paidCurrentQ, paidPreviousQ];
  }, [invoices, overviewYear]);
  const overviewDataset = useMemo<ChartDataset[]>(
    () => [
      {
        data: overviewByYear.map(({ amount }) => amount),
        backgroundColor: [lightColor, grayColor, secondaryColor, primaryColor],
      },
    ],
    [overviewByYear, primaryColor, secondaryColor],
  );

  const stackedBarOptions = useStackedBarChartOptions();

  return (
    <CollapsibleSection title={t('dashboard:charts.title')}>
      {renderCharts && (
        <div className="charts-section">
          {/* TOTAL BILLED */}
          <CustomChart
            datasets={totalBilledDataset}
            labels={months}
            options={stackedBarOptions}
            setYear={(year) => setTotalBilledYear(year)}
            title={t('dashboard:charts.totalBilled')}
            type="bar"
            year={totalBilledYear}
          />

          {/* OVERVIEW */}
          <div className="col-span-full">
            <CustomChart
              BigLegend={
                <div>
                  <Legend invoices={invoices} year={overviewYear} />
                </div>
              }
              datasets={overviewDataset}
              labels={overviewLabels}
              options={overviewPieOptions}
              setYear={(year) => setOverviewYear(year)}
              title={t('dashboard:charts.overview')}
              type="pie"
              year={overviewYear}
            />
          </div>
        </div>
      )}
    </CollapsibleSection>
  );
};
