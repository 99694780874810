import { Trans } from 'react-i18next';

export const SwitchNumberType = ({
  i18nKey,
  onSwitch,
}: {
  i18nKey: 'clients:create.company.switchToVatNumber' | 'clients:create.company.switchToOtherNumber';
  onSwitch: () => void;
}) => {
  return (
    <p className="my-6 text-sm">
      <Trans
        components={[
          <button
            className="text-primary-500 hover:text-secondary-500 focus-within:text-secondary-500 cursor-pointer outline-none"
            key={null}
            onClick={onSwitch}
            type="button"
          />,
        ]}
        i18nKey={i18nKey}
      />
    </p>
  );
};
