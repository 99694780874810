import type { ComponentProps, ReactNode } from 'react';

import { env } from '~/constants/env';

import { useFormFieldContext } from '../FormFieldContext';
import { ValidationError } from './ValidationError';

type FieldErrorType = 'required' | 'minLength' | 'maxLength' | 'min' | 'max' | 'pattern' | 'validate';
type Props = Partial<Record<FieldErrorType | `validate-${string}`, ReactNode>> & Omit<ComponentProps<typeof ValidationError>, 'children'>;

export const FormValidationError = ({ styles, ...messages }: Props) => {
  const {
    fieldState: { error },
  } = useFormFieldContext();

  if (!error) return null;

  const errorMessage = messages[error.type as FieldErrorType] ?? messages[`validate-${error.type}`];

  if (errorMessage === null || errorMessage === undefined) {
    if (env.IS_LOCAL_DEV_ENV)
      throw new Error(`A validation error of type '${error.type}' occurred, but no message has been provided for this type.`);

    return null;
  }

  return <ValidationError styles={styles}>{errorMessage}</ValidationError>;
};
