import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Doc } from '~/types/user';

import { ColumnIds } from '~/constants/table';

import { DocumentsTableOptions } from './DocumentsTableOptions';

const columnHelper = createColumnHelper<Doc>();

export const useDocumentsTableColumns = () => {
  const { t } = useTranslation(['common', 'settings']);

  return useMemo(
    () => [
      // Title
      columnHelper.accessor('title', {
        id: ColumnIds.documentsName,
        header: t('settings:documents.headers.name'),
        cell: ({ getValue }) => <span className="lg:truncate">{getValue()}</span>,
        meta: {
          styles: { size: '1fr', minSize: 200 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // File extension
      columnHelper.accessor('extension', {
        id: ColumnIds.documentsExtension,
        header: t('settings:documents.headers.type'),
        enableGlobalFilter: false,
        enableSorting: false,
        meta: {
          styles: { size: '80px' },
          mobileStyles: { size: 'max-content', minBreakpoint: 'xs' },
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.documentsDownload,
        header: t('common:datatable.download'),
        cell: ({ row: { original: document } }) => <DocumentsTableOptions document={document} />,
        meta: {
          styles: { size: 'max-content', minSize: 60, justify: 'center' },
        },
      }),
    ],
    [t],
  );
};
