import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { receivedInvitationSchema } from './schemas';

export const useFetchInvitations = () => {
  return useMutation({
    mutationFn: async (emailAddress: string) => {
      const body = { emailAddress };
      const response = await request('/registration/list-invitations', { method: 'POST', body });

      const { data: invitations } = apiResourceSchema(receivedInvitationSchema.array()).parse(response);

      // Sorted from new to old
      return invitations.toSorted(({ date: a }, { date: b }) => dayjs(b).diff(dayjs(a)));
    },
  });
};
