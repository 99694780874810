import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const PaperPlane2 = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="Referral 1"
      viewBox="547.69 120.49 39.26 48.17"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <g>
        <path {...stylex.props(styles.stroke, styles.fillWhite)} d="M562.27,155.83l-12.3,12.83-2.28-48.16s14.13,35.43,14.57,35.33Z"></path>
        <polygon
          {...stylex.props(styles.stroke, styles.fillWhite)}
          points="586.95 130.07 571.92 145.75 547.7 120.5 586.95 130.07"
        ></polygon>
        <path {...stylex.props(styles.stroke, styles.fillPrimary)} d="M576.36,156.42s-13.75-.95-14.09-.59l9.65-10.07,4.44,10.67Z"></path>
        <path
          {...stylex.props(styles.stroke, styles.fillSecondary)}
          d="M571.92,145.75l-9.65,10.07h0c-.44.1-14.57-35.33-14.57-35.33l24.22,25.25Z"
        ></path>
      </g>
    </svg>
  );
};
