import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { PageHeader } from '~/components';
import { ColumnIds } from '~/constants/table';
import { useTable } from '~/hooks/useTable';

import { useDocumentsTableColumns } from './DocumentsTable.hooks';

export const Documents = () => {
  const { data: user } = useUser();

  const { t } = useTranslation(['common', 'settings']);

  const data = user.documents;
  const columns = useDocumentsTableColumns();
  const [table, Table] = useTable({
    data,
    columns,
    initialState: {
      sorting: [
        {
          id: ColumnIds.documentsName,
          desc: false,
        },
      ],
    },
    enableSortingRemoval: false,
  });

  return (
    <>
      <PageHeader description={t('settings:documents.description')} title={t('settings:documents.title')} />

      <section>
        <Table.Root table={table}>
          <Table.Menu>
            <Table.Search />
          </Table.Menu>

          <Table.Grid>
            <Table.Header />
            <Table.Body>{(row) => <Table.Row row={row} />}</Table.Body>
          </Table.Grid>

          <Table.Pagination />
        </Table.Root>
      </section>
    </>
  );
};
