import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { getInvoiceStatus, InvoiceStatus } from '~/pages/InvoicesCreditnotes/utils';
import { getQuotationStatus, QuotationStatus } from '~/pages/Quotations/utils';

import type { StatsSummaryProps as Props } from './types';

import { CopyrightStatBlock } from './CopyrightStatBlock/CopyrightStatBlock';
import { StatBlock } from './StatBlock/StatBlock';
import styles from './StatsSummary.module.scss';
import { Stats } from './types';

export const StatsSummary = ({ invoices, quotations }: Props) => {
  const { data: user } = useUser();
  const { t } = useTranslation(['dashboard']);

  const pendingQuotations = quotations
    .filter(({ draft }) => !draft)
    .filter((quotation) => getQuotationStatus(quotation) === QuotationStatus.SENT).length;
  const pendingInvoices = invoices
    .filter(({ draft }) => !draft)
    .filter((invoice) => getInvoiceStatus(invoice) === InvoiceStatus.PENDING).length;
  const expiredInvoices = invoices
    .filter(({ draft }) => !draft)
    .filter((invoice) => getInvoiceStatus(invoice) === InvoiceStatus.EXPIRED).length;

  const showAverageReceivedPastYears = user.copyright.averageReceivedPastYears > 0;

  return (
    <section className={styles.StatsGrid}>
      <div className={showAverageReceivedPastYears ? styles.Span_3 : styles.Span_Full}>
        <CopyrightStatBlock
          label={t('dashboard:stats.copyrightThisYear.label', { year: dayjs().year() })}
          tooltip={<Trans components={{ a: <a /> }} i18nKey="dashboard:stats.copyrightThisYear.tooltip" />}
          value={user.copyright.totalReceivedThisYear}
        />
      </div>

      {showAverageReceivedPastYears && (
        <div className={styles.Span_3}>
          <CopyrightStatBlock
            label={t('dashboard:stats.copyrightAverage.label')}
            tooltip={<Trans components={{ a: <a /> }} i18nKey="dashboard:stats.copyrightAverage.tooltip" />}
            value={user.copyright.averageReceivedPastYears}
          />
        </div>
      )}

      <div className={styles.Span_2}>
        <StatBlock icon="Description" title={t(`dashboard:stats.${Stats.PENDING_QUOTATIONS}`)} total={pendingQuotations} />
      </div>

      <div className={styles.Span_2}>
        <StatBlock icon="RequestQuote" title={t(`dashboard:stats.${Stats.PENDING_INVOICES}`)} total={pendingInvoices} />
      </div>

      <div className={styles.Span_2}>
        <StatBlock icon="HourglassBottom" title={t(`dashboard:stats.${Stats.OVERDUE_INVOICES}`)} total={expiredInvoices} />
      </div>
    </section>
  );
};
