import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CountryFlag, Label, Select } from '~/components';
import { vatCountryCodes } from '~/constants/countries';
import { useIntl } from '~/hooks/useIntl';

import { mapVatCountryCodeToCountryCode } from '../../../utils';
import { useCreateClientContext } from '../../context';
import { useCreateOrganisationContext } from '../context';

export const VatCountryCodeSelect = ({ disabled = false }: { disabled?: boolean }) => {
  const { resetClientContext } = useCreateClientContext();
  const { setVatCountryCode, vatCountryCode } = useCreateOrganisationContext();

  const { displayNameOfCountry } = useIntl();
  const countryOptions = useMemo(
    () =>
      vatCountryCodes
        .map((countryCode) => ({
          name: displayNameOfCountry(mapVatCountryCodeToCountryCode(countryCode)),
          code: countryCode,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [displayNameOfCountry],
  );

  const { t } = useTranslation(['clients']);

  return (
    <div>
      <Label>{t('clients:fields.countryCode.label')}</Label>

      <Select
        disabled={disabled}
        onChange={(countryCode) => {
          setVatCountryCode(countryCode);

          // Reset contexts to default state on country code change
          resetClientContext();
        }}
        options={countryOptions.map((country) => ({
          label: country.name,
          value: country.code,
        }))}
        OptionWrapper={({ children, value }) => (
          <span className="flex items-center gap-x-2">
            <CountryFlag countryCode={mapVatCountryCodeToCountryCode(value)} />
            <span className="text-ellipsis overflow-hidden [button_&]:truncate">{children}</span>
          </span>
        )}
        value={vatCountryCode}
      />
    </div>
  );
};
