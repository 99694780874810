import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, lineHeight, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  description: {
    fontSize: fontSize.tiny,
    fontWeight: fontWeight.regular,
    lineHeight: lineHeight.tight,
    color: systemColors.textMuted,
  },
});
