import type { UpdateCommunityProfilePayload, UserCommunityProfile } from '~/api/community/types';

import type { EditUserCommunityProfileFormType } from './types';

export const mapUserCommunityProfileFormDataToPayload = (data: EditUserCommunityProfileFormType): UpdateCommunityProfilePayload => ({
  enabled: data.enabled,
  email: data.email,
  description: data.description,
  regions: data.regions,
  brandId: data.brandId === 0 ? null : data.brandId,
  openToWork: data.openToWork,
  links: data.links,
});

export const mapUserCommunityProfileResponseToFormData = (communityProfile: UserCommunityProfile): EditUserCommunityProfileFormType => ({
  enabled: communityProfile.enabled,
  email: communityProfile.email,
  description: communityProfile.description ?? '',
  regions: communityProfile.regions,
  brandId: communityProfile.brandId ?? 0,
  openToWork: communityProfile.openToWork,
  links: {
    website: communityProfile.links.website ?? '',
    linkedIn: communityProfile.links.linkedIn ?? '',
    instagram: communityProfile.links.instagram ?? '',
    facebook: communityProfile.links.facebook ?? '',
    behance: communityProfile.links.behance ?? '',
  },
});
