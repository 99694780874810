import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { setAccessToken } from '~/utils/auth';

import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';
import { accessTokenSchema } from './schemas';

export const useActivateAccount = () => {
  return useMutation({
    mutationFn: async (activationKey: string) => {
      const body = { activationKey };
      const response = await request('/registration/activate-user-account', { method: 'POST', body });

      const { accessToken } = accessTokenSchema.parse(response);

      return accessToken;
    },
    onSuccess: (accessToken) => {
      setAccessToken(accessToken);
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) && captureException(new Error('Failed to activate account', { cause: error }), { level: 'fatal' }),
  });
};
