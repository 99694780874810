import classNames from 'classnames';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { Button, IconTooltip } from '~/components';
import { SearchParamModals } from '~/constants/url';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useModal } from '~/hooks/useModal';
import { useShouldOpenModal } from '~/hooks/useShouldOpenModal';
import { isPhotographer } from '~/utils/user';

import type { SectionActionsProps as Props } from './types';

import { useSectionContext } from '../Section/context';
import { createLine, createPhotoAlbumSection, createTextLine } from '../utils';
import { AddProductsModal } from './AddProductsModal/AddProductsModal';
import styles from './SectionActions.module.scss';

export const SectionActions = ({ type }: Props) => {
  const { t } = useTranslation(['lines']);

  const { data: user } = useUser();
  const {
    clearErrors,
    formState: { isSubmitted },
  } = useInvoiceQuotationFormContext();
  const {
    isDefaultSection,
    linesFieldArray,
    mobileLineReducer: [, expandMobileLine],
    section,
    sectionIndex,
    sectionsFieldArray,
  } = useSectionContext();

  const { fields: sections, prepend: prependSection } = sectionsFieldArray;
  const { append: appendLine, fields: lines } = linesFieldArray;

  const shouldOpenModal = useShouldOpenModal(SearchParamModals.ADD_PRODUCT);
  const addProductsModal = useModal(shouldOpenModal && isDefaultSection);

  // Limit to one photo album section
  const canAddPhotoAlbumSection = isPhotographer(user) && isDefaultSection && !sections.some((section) => section.isPhotoAlbum);

  /**
   * Add an empty line to the section
   */
  const onAddLine = useCallback(() => {
    expandMobileLine({ sectionIndex, lineIndex: lines.length });
    appendLine(createLine());

    // Clear the sections error as appending a line doesn't retrigger the validation.
    // As a side effect, this clears all errors of each line as well, which is acceptable behavior.
    if (isSubmitted) clearErrors('sections');
  }, [appendLine, clearErrors, expandMobileLine, isSubmitted, lines.length, sectionIndex]);

  /**
   * Add a photo album section with an empty line
   */
  const onAddPhotoAlbum = useCallback(() => {
    prependSection(createPhotoAlbumSection(t('lines:photoAlbum.title')));
    expandMobileLine({ sectionIndex: 0, lineIndex: 0 });
  }, [expandMobileLine, prependSection, t]);

  /**
   * Add a text-only line
   */
  const onAddTextLine = useCallback(() => {
    expandMobileLine({ sectionIndex, lineIndex: lines.length });
    appendLine(createTextLine());
  }, [appendLine, expandMobileLine, lines.length, sectionIndex]);

  return (
    <tbody>
      <tr className={styles.SectionActions}>
        <td className={classNames(styles.ActionsGroup, !isDefaultSection && styles.Indented)}>
          <Button icon="Add" onClick={addProductsModal.open} type="tertiary">
            {t('lines:new.product')}
            <IconTooltip text={<Trans components={{ a: <a /> }} i18nKey="lines:new.productTooltip" />} />
          </Button>

          <Button icon="Add" onClick={onAddLine} type="tertiary">
            {section.isPhotoAlbum
              ? t('lines:new.photoAlbumLine')
              : type === 'invoice'
                ? t('lines:new.invoiceLine')
                : t('lines:new.quotationLine')}
          </Button>

          {canAddPhotoAlbumSection && (
            <Button icon="Add" onClick={onAddPhotoAlbum} type="tertiary">
              {t('lines:new.photoAlbum')}
            </Button>
          )}

          <Button data-pf-id="add-text-line-button" icon="Add" onClick={onAddTextLine} type="tertiary">
            {t('lines:new.textLine')}
          </Button>
        </td>

        {addProductsModal.isOpen && (
          <td>
            <AddProductsModal onClose={addProductsModal.close} />
          </td>
        )}
      </tr>
    </tbody>
  );
};
