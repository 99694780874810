import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormErrorMessage, FormField, FormLabel } from '~/components';
import { Icon } from '~/components/SVG';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useIntl } from '~/hooks/useIntl';

import { useLineContext } from '../../SectionLines/context';
import { useFloatingListbox } from '../hooks';
import { preventNumberInputOnWheel } from '../utils';
import styles from './FormDiscount.module.scss';

export const FormDiscount = () => {
  const {
    clearErrors,
    control,
    formState: { isSubmitted },
    watch,
  } = useInvoiceQuotationFormContext();
  const { isMobile, lineFieldName } = useLineContext();

  const watchDiscountType = watch(`${lineFieldName}.discountType`);
  const watchPrice = +watch(`${lineFieldName}.price`);
  const watchQuantity = +watch(`${lineFieldName}.quantity`);
  const watchTotal = watchPrice * watchQuantity;

  const { floatingStyles, refs } = useFloatingListbox(2);

  const { formatCurrency, formatPercentage } = useIntl();
  const { t } = useTranslation(['lines', 'validation']);

  if (watchDiscountType === null) return null;

  return (
    <FormControl
      control={control}
      name={`${lineFieldName}.discountValue`}
      rules={{
        required: true,
        min: watchDiscountType === 'percentage' ? 1 : 0.01,
        max: watchDiscountType === 'percentage' ? 100 : watchTotal,
      }}
    >
      {isMobile && <FormLabel>{t('lines:discount.header')}</FormLabel>}

      <div className={styles.Wrapper} data-css-container="discount-input" data-pf-id="line-discount-input" ref={refs.setReference}>
        <FormField>
          {({ field, fieldState, id }) => (
            <input
              className={classNames(styles.DiscountInput, fieldState.invalid && styles.Invalid)}
              id={id}
              inputMode="decimal"
              max={watchDiscountType === 'percentage' ? 100 : watchTotal}
              min={watchDiscountType === 'percentage' ? 1 : 0}
              onBlur={field.onBlur}
              onChange={field.onChange}
              onWheel={preventNumberInputOnWheel}
              ref={field.ref}
              step={1}
              type="number"
              value={field.value}
            />
          )}
        </FormField>

        <FormControl as={Fragment} control={control} name={`${lineFieldName}.discountType`}>
          <FormField>
            {({ field, id }) => (
              <Listbox
                onChange={(value) => {
                  field.onChange(value);
                  if (isSubmitted) clearErrors(`${lineFieldName}.discountValue`);
                }}
                value={field.value}
              >
                <ListboxButton className={styles.DiscountTypeSelect} id={id} onBlur={field.onBlur} ref={field.ref}>
                  {t(`lines:discountType.${watchDiscountType}`)}
                  <Icon name="KeyboardArrowDown" />
                </ListboxButton>

                <ListboxOptions className={styles.Options} modal={false} ref={refs.setFloating} style={floatingStyles}>
                  {(['percentage', 'flatRate'] as const).map((discountType) => (
                    <ListboxOption className={styles.Option} key={discountType} value={discountType}>
                      {t(`lines:discountType.${discountType}`)}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </Listbox>
            )}
          </FormField>
        </FormControl>
      </div>

      {isMobile && (
        <FormErrorMessage
          max={
            watchDiscountType === 'percentage'
              ? t('validation:max', { attribute: t('lines:quantity.header'), max: formatPercentage(1) })
              : t('validation:maxDiscount')
          }
          min={t('validation:min', {
            attribute: t('lines:quantity.header'),
            min: watchDiscountType === 'percentage' ? formatPercentage(0.01) : formatCurrency(0.01),
          })}
          required={t('validation:required')}
        />
      )}
    </FormControl>
  );
};
