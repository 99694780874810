import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../SVG.types';

export const CraftVertical = ({ styles, ...delegated }: SvgProps) => {
  return (
    <svg {...stylex.props(styles)} viewBox="0 0 507 294.2" xmlns="http://www.w3.org/2000/svg" {...delegated}>
      <polygon points="0 0 0 128 64 128 64 64 128 64 128 128 191.9 128 191.9 0 0 0" />
      <path d="M381.9,183h38.2v31H381.9v77.2H345.7V214H319.2V183h26.5v-6.2c0-31.5,21.2-48.9,48.9-48.9,8.7,0,16.3,1.3,27.4,4.2l-4.9,30.1c-5.4-1.8-11.8-3.3-16.5-3.3-11.6,0-18.7,6.9-18.7,17.8V183Z" />
      <path d="M279.8,198.3c-7.5-11.3-19.2-17.6-34.4-17.6-28,0-50.3,25.2-50.3,56.3s22.3,56.5,50.3,56.5c15.2,0,26.9-6.4,34.4-17.7v15.4h31.1V182.9H279.8Zm-27.2,65.3a26.7,26.7,0,1,1,0-53.4c15.2,0,27.1,11.7,27.1,26.7C279.8,252.2,267.8,263.6,252.6,263.6Z" />
      <path d="M56.3,263.6c-14.8,0-25.8-11.5-25.8-26.5,0-15.4,11-26.7,25.8-26.7,10.4,0,19.2,5.7,21.4,13.7h31.5c-2.9-24.9-25.8-43.5-52.9-43.5C24.3,180.7,0,205,0,237.2s24.3,56.3,56.3,56.3c27.6,0,50.7-18.5,53.2-44.3H77.9C75.7,257.5,66.6,263.6,56.3,263.6Z" />
      <path d="M181.6,180.7c-12.1,0-21.6,5.3-28,14.6V182.9H122.5V291.2h31.1V237.8c0-20.1,12.1-29.1,27.1-29.1a54.76,54.76,0,0,1,11.3,1.5V182A65.66,65.66,0,0,0,181.6,180.7Z" />
      <path d="M507,256.4c-6.5,2.5-12.3,4.5-19.2,4.5-10.5,0-17-5.1-17-16.3V214H507V183H470.8V146.2H434.6V183H413.4v31h21.2v31.8c0,29.2,17.8,48.4,47.3,48.4a82,82,0,0,0,25.1-3.9V256.4Z" />
    </svg>
  );
};
