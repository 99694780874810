import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import type { Contact } from '~/api/clients/types';

import { useClients, useDeleteContact } from '~/api/clients';
import { Button, Modal } from '~/components';

export const DeleteContactModal = ({ clientId, contact, onClose }: { clientId: number; contact: Contact; onClose: () => void }) => {
  const { data: clients } = useClients();
  const mutation = useDeleteContact(clientId, contact.id);

  const { t } = useTranslation(['clients', 'common']);

  const handleDelete = () => {
    mutation.mutate(undefined, {
      onSuccess: () => toast.success(t('clients:alerts.successDeleted')),
      onError: () => toast.error(t('common:error')),
      onSettled: () => onClose(),
    });
  };

  const client = clients.filter(({ deleted }) => !deleted).find(({ contacts }) => contacts.some(({ id }) => id === contact.id));

  if (!client || client.clientType !== 'organisation') return null;

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('clients:detail.contacts.delete.title')}</Modal.Title>

      <p>{t('clients:detail.contacts.delete.description', { name: contact.fullName })}</p>

      <Modal.Actions>
        <Button hasSpinner icon="Delete" isLoading={mutation.isPending} onClick={handleDelete}>
          {t('clients:detail.contacts.delete.submit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
