import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Icon } from '~/components/SVG';
import { CommunityProfileCardSkeleton } from '~/components/Templates/Community';
import { AnchorTargets } from '~/constants/url';
import { routes } from '~/providers/RouterProvider/router.routes';

export const CommunityLocked = () => {
  const { t } = useTranslation(['community']);

  return (
    <div className="relative">
      <div className="absolute z-1 -inset-2" style={{ backdropFilter: 'blur(6px)' }}>
        <div className="mt-20 mx-8 flex flex-col gap-y-4 justify-center items-center text-primary-500 text-center">
          <Icon name="LockPerson" size={100} />
          <Link className="font-semibold" to={{ pathname: routes.settingsProfile, hash: AnchorTargets.PROFILE_COMMUNITY }}>
            {t('community:overview.locked')}
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-8">
        {Array.from({ length: 6 }, () => null).map((_, i) => (
          <CommunityProfileCardSkeleton key={i} />
        ))}
      </div>
    </div>
  );
};
