import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../SVG.types';

export const CraftHorizontal = ({ styles, ...delegated }: SvgProps) => {
  return (
    <svg {...stylex.props(styles)} viewBox="0 0 629.7 141.7" xmlns="http://www.w3.org/2000/svg" {...delegated}>
      <path d="M523.2,47h32.5V73.4H523.2v65.8H492.4V73.4H469.8V47h22.6V41.7C492.4,14.8,510.5,0,534,0c7.4,0,13.9,1.1,23.4,3.6l-4.2,25.7c-4.6-1.5-10.1-2.8-14.1-2.8-9.9,0-16,5.9-16,15.2V47Z" />
      <path d="M436.1,60c-6.4-9.6-16.4-15-29.3-15-23.9,0-42.9,21.4-42.9,48s19,48.1,42.9,48.1c13,0,22.9-5.5,29.3-15v13.2h26.5V46.9H436.1ZM413,115.7a22.8,22.8,0,1,1,23.1-22.8C436.1,105.9,425.9,115.7,413,115.7Z" />
      <path d="M245.6,115.7c-12.6,0-22-9.8-22-22.6,0-13.2,9.4-22.8,22-22.8,8.8,0,16.4,4.9,18.2,11.7h26.9c-2.4-21.2-22-37-45.1-37-27.3,0-48,20.7-48,48.1s20.7,48,48,48c23.5,0,43.3-15.8,45.3-37.8H264C262.1,110.4,254.4,115.7,245.6,115.7Z" />
      <path d="M352.4,45a27.92,27.92,0,0,0-23.9,12.4V46.9H302v92.3h26.5V93.7c0-17.1,10.3-24.8,23.1-24.8a45.23,45.23,0,0,1,9.6,1.3V46.1A55.68,55.68,0,0,0,352.4,45Z" />
      <path d="M629.7,109.5c-5.5,2.1-10.5,3.8-16.4,3.8-8.9,0-14.4-4.4-14.4-13.9v-26h30.8V47H598.9V15.6H568.1V47H550V73.4h18.1v27c0,24.9,15.2,41.3,40.3,41.3a66.43,66.43,0,0,0,21.3-3.3V109.5Z" />
      <polygon points="0 29.4 0 139.2 54.9 139.2 54.9 84.3 109.8 84.3 109.8 139.2 164.8 139.2 164.8 29.4 0 29.4" />
    </svg>
  );
};
