import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { getAccessToken } from '~/utils/auth';

import type { CreateNpsPayload } from './types';

import { request } from '../request';
import { userQueryOptions } from '../user';

export const useCreateNps = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateNpsPayload) => {
      const token = getAccessToken();
      const response = await request('/nps', { method: 'POST', token, body });

      // TODO: Schema validation
      const { nps } = z.object({ nps: z.unknown() }).parse(response);

      return nps as { id: number };
    },
    onSuccess: (nps, variables) => {
      // Immediately close the NPS popup when the close button is clicked
      if (variables.closed) {
        queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
          if (!user) return;

          return { ...user, showNps: false };
        });
      }
    },
  });
};
