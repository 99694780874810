import { useTranslation } from 'react-i18next';
import { match, Pattern } from 'ts-pattern';

import { Alert, AlertDescription, AlertTitle } from '~/components/UI';

import { useRegistrationContext } from '../Registration.context';

export const RegistrationReferralCodeInformation = () => {
  const { referralCodeInformation, setHadReferralCode, setReferralCodeInformation } = useRegistrationContext();

  const { t } = useTranslation(['auth']);

  if (!referralCodeInformation) return null;

  return (
    <Alert
      dismissable
      icon="Redeem"
      onDismiss={() => {
        setReferralCodeInformation(null);
        setHadReferralCode(true);
      }}
      variant="success"
    >
      <AlertTitle>{t('auth:registrationPage.common.referralCode.applied')}</AlertTitle>
      <AlertDescription>
        {match(referralCodeInformation)
          .with(
            {
              invitorType: 'admin',
              code: Pattern.union('IKWERKINDEZOMER', 'ZOMERPROMO60'),
            },
            () =>
              t('auth:registrationPage.common.referralCode.types.fromAdmin.ZOMER2024', {
                referralCode: referralCodeInformation.code,
              }),
          )
          .with({ invitorType: 'admin' }, () =>
            t('auth:registrationPage.common.referralCode.types.fromAdmin.generic', {
              referralCode: referralCodeInformation.code,
            }),
          )
          .with({ invitorType: 'user' }, () =>
            t('auth:registrationPage.common.referralCode.types.fromUser', {
              referralCode: referralCodeInformation.code,
              name: referralCodeInformation.invitorName,
            }),
          )
          .with({ invitorType: 'accountant' }, () =>
            t('auth:registrationPage.common.referralCode.types.fromAccountant', {
              referralCode: referralCodeInformation.code,
              name: referralCodeInformation.invitorName,
            }),
          )
          .exhaustive()}
      </AlertDescription>
    </Alert>
  );
};
