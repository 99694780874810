import * as stylex from '@stylexjs/stylex';

import { neutralColors, radius, spacing, systemColors } from '~/styles/tokens.stylex';

import { formColors } from '../tokens.stylex';

const isActive = ':is([data-active])';
const isFocused = ':is([data-focus])';

export const styles = stylex.create({
  // The ListboxButton uses the styles from InputWrapper so that it visually looks identical.
  // The styles defined here are applied in addition to the InputWrapper styles.
  listboxButton: {
    width: '100%',
    outlineStyle: 'none',
    textAlign: 'start',
    height: 'unset',
    minHeight: 40,
    borderColor: {
      default: systemColors.borderLight,
      [isFocused]: formColors.focusBorder,
      [isActive]: formColors.focusBorder,
    },
  },

  disabled: {
    color: formColors.disabledText,
    backgroundColor: formColors.disabledBackground,
    cursor: 'not-allowed',
  },

  invalid: {
    borderColor: formColors.invalid,
  },

  selectedItem: {
    minWidth: 0,
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
  },

  placeholder: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    color: formColors.placeholderText,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'clip',
  },

  options: {
    width: 'var(--button-width)',
    padding: spacing.xxsmall,
    borderWidth: 1,
    borderColor: neutralColors.gray100,
    borderRadius: radius.xxxsmall,
    outlineStyle: 'none',
    // Scroll shadows
    // Shadow gradients are always visible and cover gradients scroll with the element, essentially revealing the shadows on scroll.
    background: `
      local linear-gradient(to bottom, ${neutralColors.white}, ${neutralColors.white}, transparent) top center / 100% 32px,
      local linear-gradient(to top, ${neutralColors.white}, ${neutralColors.white}, transparent) bottom center / 100% 32px,
      scroll linear-gradient(to bottom, ${neutralColors.gray050}, transparent) top center / 100% 10px,
      scroll linear-gradient(to top, ${neutralColors.gray050}, transparent) bottom center / 100% 10px,
      ${neutralColors.white}
    `,
    backgroundRepeat: 'no-repeat',
  },
});
