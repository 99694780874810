import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const GraphicDesignerHead = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="KIIA 1"
      viewBox="312.22 339.49 97.94 94.63"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M365.74,376.13c-2.98,2.38-5.49,5.51-7.3,9.15-6.89-1.77-13.2-3.48-18.99-5.41-3.56-1.19-6.92-2.46-10.11-3.88-1.62-.72-3.18-1.48-4.71-2.28.69-1.81,1.49-3.55,2.38-5.22,1.22-2.29,2.61-4.45,4.15-6.45.17.22.34.44.52.64,1.75,2.01,18.41,9.91,34.05,13.45Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillSecondary)}
        d="M380.31,428.42c-7.47,4.57-16.23,6.63-25.21,5.3-14.03-2.08-25.17-11.94-30.42-25.12.03.01.06.03.1.05,1.92.99,3.88,1.85,5.88,2.57,4.2,1.52,8.57,2.46,13.14,2.91.08.01.16.02.24.03h0c2.51.24,5.08.33,7.71.29,2.38-.04,4.81-.18,7.3-.43,3.13,5.58,7.99,9.77,13.74,11.56l4.74,1.88.86.34c.63.24,1.27.45,1.93.62Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M394.86,401.06c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M393.63,375.1c-.47-.17-.94-.33-1.43-.47,0-.03,0-.06-.01-.09l1.44.56Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillPrimary)}
        d="M367.1,375.12c-.46.32-.92.66-1.36,1.01-15.65-3.54-32.3-11.44-34.05-13.45-.17-.2-.35-.42-.52-.64-3.6-4.75-5.69-14.13,9.58-17.47,2.74-.6,6.01-1.01,9.57-1.23,1.21-.08,2.46-.13,3.73-.16,2.29.22,4.32,1.09,6.06,2.7h0c4.5,4.17,7.05,13.32,6.98,29.25Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillPrimary)}
        d="M402.99,379.26c-.64.14-1.29.25-1.94.36-.05-.04-.1-.09-.16-.14-2.02-1.79-4.31-3.23-6.78-4.2l-.47-.18-1.44-.56c-.91-7.05-2.92-14.27-6.02-20.28,0,0,0,0,0,0-1.77-3.43-3.9-6.47-6.38-8.86,7.84,1.67,14.14,4.27,16.36,7.79,5.77,9.18,15.12,24.34,6.83,26.07Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M393.63,375.1c-.47-.17-.94-.33-1.43-.47,0-.03,0-.06-.01-.09l1.44.56Z"
      ></path>
      <path {...stylex.props(styles.stroke)} d="M401.04,379.62s-.08.01-.12.02c0-.05-.02-.11-.03-.16.05.04.1.09.16.14Z"></path>
      <path
        {...stylex.props(styles.stroke, styles.fillBlack)}
        d="M327.02,368.49c-.89,1.67-1.69,3.41-2.38,5.22-2.42-1.27-4.73-2.67-6.93-4.21,2.78-1.03,5.86-1.41,9.31-1.01Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M344.04,414.15c-.08,0-.16-.02-.24-.03-4.57-.45-8.94-1.39-13.14-2.91-2-.72-3.96-1.58-5.88-2.57-.04-.02-.07-.04-.1-.05-3.77-1.96-7.39-4.46-10.89-7.56-2.13-8.43-2.15-16.35.27-23.7.88-2.69,2.09-5.3,3.65-7.84,2.2,1.54,4.51,2.94,6.93,4.22,1.52.8,3.09,1.56,4.71,2.28,2.8,13.1,7.7,25.82,14.7,38.16Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillPrimary)}
        d="M359.04,414.01c-2.48.25-4.91.39-7.29.43-5.74-12.12-9.73-23.6-12.3-34.57,5.79,1.93,12.09,3.64,18.99,5.41-1.87,3.77-3.01,8.09-3.18,12.71-.21,5.9,1.2,11.43,3.78,16.01Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M351.75,414.44c-2.63.04-5.2-.05-7.71-.29-6.99-12.34-11.89-25.06-14.7-38.16,3.18,1.42,6.54,2.69,10.1,3.88,2.57,10.97,6.56,22.45,12.3,34.57Z"
      ></path>
      <line {...stylex.props(styles.stroke)} x1="329.35" x2="329.34" y1="375.99" y2="375.99"></line>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M392.19,374.54s0,.06.01.09c-.56-.17-1.13-.3-1.7-.42h0c-3.07-1.83-6.53-2.91-10.23-3.04-4.8-.17-9.32,1.29-13.17,3.95.08-15.92-2.48-25.08-6.98-29.25h0c-1.74-1.61-3.78-2.48-6.06-2.7-1.18-.11-2.42-.05-3.73.16,0,0,8.47-6.14,20.95-2.9,3.2.83,6.03,2.57,8.52,4.96,2.48,2.39,4.61,5.43,6.38,8.86,0,0,0,0,0,0,3.1,6.01,5.1,13.23,6.02,20.28Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M394.86,401.06c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79Z"
      ></path>
      <path {...stylex.props(styles.stroke)} d="M401.04,379.62s-.08.01-.12.02c0-.05-.02-.11-.03-.16.05.04.1.09.16.14Z"></path>
      <path
        {...stylex.props(styles.stroke, styles.fillPrimary)}
        d="M390.5,374.21c-1.12-.23-2.26-.36-3.43-.4-4.82-.17-9.36,1.31-13.22,3.98-6.78,4.7-11.45,13.11-11.79,22.84-.39,11.03,4.88,20.74,12.83,25.48.85.51,1.72.95,2.63,1.34l-4.74-1.88c-5.75-1.79-10.61-5.98-13.74-11.56-2.58-4.59-3.99-10.11-3.78-16.01.16-4.62,1.3-8.94,3.18-12.71,1.81-3.65,4.32-6.77,7.3-9.15.44-.35.9-.69,1.36-1.01,3.85-2.66,8.37-4.12,13.17-3.95,3.69.13,7.16,1.21,10.23,3.04Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M394.86,401.06c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79ZM401.04,379.62s-.08.01-.12.02c0-.05-.02-.11-.03-.16-2.02-1.79-4.31-3.23-6.78-4.2l-.47-.18c-.47-.17-.94-.33-1.43-.47-.56-.17-1.13-.3-1.7-.42h0c-1.12-.23-2.26-.37-3.43-.41-4.82-.17-9.36,1.31-13.22,3.98-6.78,4.7-11.45,13.11-11.79,22.84-.39,11.03,4.88,20.74,12.83,25.48.85.51,1.72.95,2.63,1.34l.86.34c.63.24,1.27.45,1.93.63,1.55.42,3.16.68,4.81.73,13.27.47,24.47-11.54,25.01-26.83.32-9.19-3.28-17.46-9.09-22.71Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M394.86,401.06c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79Z"
      ></path>
      <path
        {...stylex.props(styles.stroke, styles.fillWhite)}
        d="M394.86,401.06c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79Z"
      ></path>
      <line {...stylex.props(styles.stroke)} x1="392.44" x2="387.14" y1="374.64" y2="372.58"></line>
    </svg>
  );
};
