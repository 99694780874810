import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { LinkAccountantPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { accountantSchema } from './schemas';
import { userQueryOptions } from './useUser';

export const useLinkAccountant = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: LinkAccountantPayload) => {
      const token = getAccessToken();
      const response = await request('/integrations/accountant/link', { method: 'POST', token, body });

      const { data: accountant } = apiResourceSchema(accountantSchema).parse(response);

      return accountant;
    },
    onSuccess: (accountant) => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, accountant: { approved: true, data: accountant } };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
