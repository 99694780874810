import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdatePlan } from '~/api/user';
import { Button, Modal, SuccessModal } from '~/components';
import { useModal } from '~/hooks/useModal';

import type { RequestPlanChangeModalProps as Props } from './types';

export const RequestPlanChangeModal = ({ onClose, planId, user }: Props) => {
  const mutation = useUpdatePlan();

  const { t } = useTranslation(['common', 'settings']);

  const successModal = useModal();

  const requestPlanChange = () => {
    mutation.mutate(
      { planId },
      {
        onSuccess: () => successModal.open(),
        onError: () => toast.error(t('common:error')),
      },
    );
  };

  if (successModal.isOpen) {
    return (
      <SuccessModal
        buttonText={t('settings:paymentPlan.requestPlanChangeModal.confirmationDismiss')}
        onClose={onClose}
        title={t('settings:paymentPlan.requestPlanChangeModal.confirmationTitle')}
      >
        <p>{t('settings:paymentPlan.requestPlanChangeModal.confirmationDescription')}</p>
      </SuccessModal>
    );
  }

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('settings:paymentPlan.requestPlanChangeModal.title')}</Modal.Title>

      <p>
        {t('settings:paymentPlan.requestPlanChangeModal.description', {
          currentPlan: t(`settings:paymentPlan.commission.planName.${user.planId}`),
          newPlan: t(`common:plan.${planId}`),
        })}
      </p>

      <Modal.Actions>
        <Button onClick={onClose} type="secondary">
          {t('settings:paymentPlan.requestPlanChangeModal.cancel')}
        </Button>
        <Button hasSpinner icon="Send" isLoading={mutation.isPending} onClick={requestPlanChange}>
          {t('settings:paymentPlan.requestPlanChangeModal.requestChange')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
