import { createContext, useContext } from 'react';
import invariant from 'tiny-invariant';

export const AlertContext = createContext<{
  setAriaDescriptionId: (id: string) => void;
  setAriaLabelId: (id: string) => void;
} | null>(null);

export const useAlertContext = () => {
  const context = useContext(AlertContext);

  invariant(context, 'Alert components must be wrapped in <Alert />');

  return context;
};
