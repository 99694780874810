import type { WheelEvent } from 'react';

/**
 * Prevent the default browser behaviour of incrementing/decrementing the value
 * when scrolling inside a focused number input field.
 */
export const preventNumberInputOnWheel = (e: WheelEvent<HTMLInputElement>) => {
  const input = e.currentTarget;

  // Blur the input temporarily
  input.blur();

  // Queue a task to refocus the input field
  setTimeout(() => input.focus(), 0);
};
