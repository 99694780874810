export const creditnotes = {
  alerts: {
    duplicateEntryNumber: 'Dit creditnotanummer bestaat al, verander je het even?',
    successCreated: 'De creditnota werd succesvol aangemaakt!',
  },
  create: {
    confirmation: {
      description:
        'Ben je zeker dat je de creditnota wilt aanmaken? Wanneer de creditnota goedgekeurd en doorgestuurd is, kan je geen aanpassingen meer doen.',
      submit: 'Ja, maak creditnota',
      title: 'Je creditnota wordt doorgestuurd',
    },
    partialPaymentsWarning: {
      message_one:
        'Voor deze factuur hebben wij al een gedeeltelijke betaling van {{ payment }} ontvangen. Crediteer de factuur gerust, wij pikken het verder met je op voor de opvolging van deze betaling.',
      message_other:
        'Voor deze factuur hebben wij al gedeeltelijke betalingen van {{ payment }} ontvangen. Crediteer de factuur gerust, wij pikken het verder met je op voor de opvolging van deze betalingen.',
      title: 'Een heads-up!',
    },
    submit: 'Maak creditnota',
    title: 'Creditnota details',
  },
  fields: {
    invoiceId: {
      label: 'Creditnota voor factuur',
      placeholder: 'Selecteer een factuur',
    },
    numberingSeriesId: {
      helpText: 'Het creditnotanummer zal <0>{{entryNumber}}</0> zijn.',
      label: 'Nummeringreeks',
      labelTooltip:
        'Vragen over het aanmaken van een nummeringreeks? Klik <a href="https://knowledge.creativeshelter.be/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in" target="_blank">hier</a> voor meer informatie.',
      placeholder: 'Selecteer een nummeringreeks',
      tooltip: 'Dit is een preview van je creditnotanummer. Je nummer zelf is pas definitief als je de factuur naar ons verstuurt.',
      tooltipCreate:
        'Een nummeringreeks helpt je jouw facturen correct en vlot te nummeren. Je kan er zoveel aanmaken als je wilt, maar één is voldoende. Lees je graag wat nog wat meer, of zit je nog met vragen? <a href="https://knowledge.creativeshelter.be/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in" target="_blank">Dat kan hier.</a>',
    },
    reason: {
      label: 'Reden',
      placeholder: 'Vul hier je reden in.',
    },
  },
  overview: {
    columns: {
      client: 'Klant',
      date: 'Datum',
      entryNumber: 'Creditnota',
      reason: 'Reden',
      status: {
        options: {
          Approved: 'Goedgekeurd',
          Pending: 'Ter goedkeuring',
        },
        title: 'Status',
      },
      total: 'Gecrediteerd',
      year: 'Jaar',
    },
    created: {
      subtitle: "Creditnota's",
    },
    rowActions: {
      download: 'Download',
    },
  },
};
