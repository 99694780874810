import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

export const CreativeShelterGift = ({ styles: styleOverrides, ...delegated }: SvgProps) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="Referral 2"
      viewBox="704.55 130.76 133.34 112.54"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      <ellipse {...stylex.props(styles.stroke, styles.fillBlack)} cx="771.22" cy="233.08" rx="66.67" ry="10.22"></ellipse>
      <g>
        <g>
          <g>
            <polygon
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              points="763.87 168.62 725.78 168.62 719.4 151.09 757.49 151.09 763.87 168.62"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              points="815.01 168.62 778.45 168.62 772.06 151.09 808.63 151.09 815.01 168.62"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="782.89 180.83 768.31 180.83 766.43 175.67 763.87 168.62 757.49 151.09 772.06 151.09 778.45 168.62 781.01 175.67 782.89 180.83"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              points="830.29 210.58 800.54 210.58 789.71 180.83 819.46 180.83 830.29 210.58"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="730.22 180.83 717.8 206.54 713.76 195.44 720.66 180.83 723.1 175.67 726.06 169.4 728.34 175.67 730.22 180.83 730.22 180.83"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              points="770.8 210.58 741.05 210.58 730.22 180.83 730.22 180.83 759.97 180.83 764.08 192.12 764.08 192.12 768.91 205.41 770.8 210.58"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="741.05 210.58 728.21 235.16 717.8 206.54 730.22 180.83 741.05 210.58"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="830.29 210.58 817.45 235.16 787.71 235.16 800.54 210.58 830.29 210.58"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="800.54 210.58 787.71 235.16 776.88 205.41 785.69 188.53 785.69 188.53 789.71 180.83 800.54 210.58"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="726.06 169.4 723.1 175.67 720.66 180.83 713.76 195.44 706.56 175.67 719.4 151.09 725.78 168.62 726.06 169.4"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="770.8 210.58 757.96 235.16 728.21 235.16 741.05 210.58 770.8 210.58"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="819.46 180.83 782.89 180.83 781.01 175.67 778.45 168.62 815.01 168.62 819.46 180.83"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="768.31 180.83 730.22 180.83 728.34 175.67 726.06 169.4 725.78 168.62 763.87 168.62 766.43 175.67 768.31 180.83"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillPrimary)}
              points="789.71 180.83 785.69 188.53 785.69 188.53 776.88 205.41 769.89 205.41 782.89 180.83 789.71 180.83"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="782.89 180.83 769.89 205.41 768.91 205.41 764.08 192.12 764.08 192.12 768.31 180.83 768.31 180.83 782.89 180.83"
            ></polygon>
            <polygon
              {...stylex.props(styles.stroke, styles.fillPrimary)}
              points="768.31 180.83 768.31 180.83 764.08 192.12 759.97 180.83 768.31 180.83"
            ></polygon>
          </g>
          <g>
            <path
              {...stylex.props(styles.stroke, styles.fillPrimary)}
              d="M763.24,156.51c-.02.16-.04.33-.07.49,0,0-8.23,11.35-20.93,5.03-12.7-6.31-11.99-27.21-4.29-29.79,6.03-2.02,16.03,6.64,20.06,10.46,1.11,1.06,1.77,1.74,1.77,1.74,1.68,3.24,3.92,8.26,3.47,12.06Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M775.53,155.96l-12.36,1.03c.03-.16.05-.32.07-.49.45-3.8-1.79-8.83-3.47-12.06,0,0-.66-.69-1.77-1.74,0,0,4.81-2.56,8.92-2.73,1.67-.07,3.14.37,4.29.91,1.68.79,2.69,1.81,2.69,1.81,0,0,4.29,7.38,1.62,13.26Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillPrimary)}
              d="M792,160.26c-8.77.52-16.47-4.3-16.47-4.3,2.67-5.88-1.62-13.26-1.62-13.26,0,0-1.01-1.02-2.69-1.81,1.44-1.67,4.78-4.9,10.92-7.79,8.77-4.12,12.22-2.92,16.31,4.47,4.09,7.39,2.33,22.18-6.44,22.69Z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
