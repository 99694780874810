import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './TableRow.module.scss';

export const TableRowNoResults = () => {
  const { t } = useTranslation(['common']);
  return (
    <div className={styles.TableRow}>
      <div className={classNames(styles.TableCell, styles.NoResults)}>{t('common:datatable.noData')}</div>
    </div>
  );
};
