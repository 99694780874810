import dayjs from 'dayjs';

import type { RegistrationAccountInformation, RegistrationVatInformation } from './Registration.context';

export const checkVatInformationStepCompleted = (
  vatInformation: RegistrationVatInformation | null,
): vatInformation is RegistrationVatInformation => !!vatInformation && dayjs(vatInformation.fetchedAt).isAfter(dayjs().subtract(1, 'hour'));

export const checkAccountInformationStepCompleted = (
  accountInformation: RegistrationAccountInformation | null,
): accountInformation is RegistrationAccountInformation => !!accountInformation && !!accountInformation.password;
