import type { BreadcrumbsProps as Props } from './types';

import { Breadcrumb } from './Breadcrumb';
import styles from './Breadcrumbs.module.scss';

export const Breadcrumbs = ({ children }: Props) => {
  return (
    <nav className={styles.Breadcrumbs} data-css-container="breadcrumbs">
      <ol>{children}</ol>
    </nav>
  );
};

Breadcrumbs.Item = Breadcrumb;
