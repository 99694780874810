import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { download } from '../request';

export const useDownloadQuotation = () => {
  return useMutation({
    mutationFn: (id: number) => {
      const token = getAccessToken();
      const body = { ids: [id], ext: 'pdf' };
      return download('/quotations/download', { method: 'POST', token, body });
    },
  });
};
