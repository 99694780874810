export const communityRegions = [
  'Antwerp',
  'Brussels',
  'EastFlanders',
  'FlemishBrabant',
  'Hainaut',
  'Liege',
  'Limburg',
  'Luxembourg',
  'Namur',
  'WalloonBrabant',
  'WestFlanders',
] as const;
