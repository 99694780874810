import type { ReactNode } from 'react';

import * as stylex from '@stylexjs/stylex';

import { styles } from './ValidationError.styles';

type Props = {
  children: ReactNode;
  styles?: stylex.StyleXStyles;
};

export const ValidationError = ({ children, styles: styleOverrides }: Props) => {
  return <div {...stylex.props(styles.validationError, styleOverrides)}>{children}</div>;
};
