import { useTranslation } from 'react-i18next';

import { useUpdateCommunityProfileAnalytics } from '~/api/community';
import { Icon } from '~/components/SVG';

import type { CommunityProfileFooterProps as Props } from './types';

import styles from './CommunityProfileFooter.module.scss';

const linkIcons = {
  website: 'Language',
  linkedIn: 'LinkedIn',
  instagram: 'Instagram',
  facebook: 'Facebook',
  behance: 'Behance',
} as const;

export const CommunityProfileFooter = ({ profile: { email, id, links } }: Props) => {
  const mutation = useUpdateCommunityProfileAnalytics('mail');

  const filteredLinks = [
    { type: 'website' as const, url: links.website },
    { type: 'linkedIn' as const, url: links.linkedIn },
    { type: 'instagram' as const, url: links.instagram },
    { type: 'facebook' as const, url: links.facebook },
    { type: 'behance' as const, url: links.behance },
  ].filter((link): link is { type: 'website' | 'linkedIn' | 'instagram' | 'facebook' | 'behance'; url: string } => link.url !== null);

  const { t } = useTranslation(['community']);

  return (
    <footer className={styles.Footer}>
      <div className={styles.LinkIcons} onClick={(e) => e.stopPropagation()}>
        {filteredLinks.length > 0 &&
          filteredLinks.map(({ type, url }) => (
            <a href={/^https?:\/\//i.test(url) ? url : `https://${url}`} key={type} rel="noreferrer" target="_blank">
              <Icon name={linkIcons[type]} />
            </a>
          ))}
      </div>

      <a
        className={styles.MailLink}
        href={`mailto:${email}`}
        onClick={(e) => {
          e.stopPropagation();
          mutation.mutate(id);
        }}
        rel="noreferrer"
        target="_blank"
      >
        <Icon name="Mail" />
        {t('community:profileCard.mailLink')}
      </a>
    </footer>
  );
};
