import { useTranslation } from 'react-i18next';

import { LogoInput } from './LogoInput';
import { ThemePicker } from './ThemePicker/ThemePicker';

export const InvoiceTheme = () => {
  const { t } = useTranslation(['settings']);

  return (
    <div className="flex flex-col sm:flex-row gap-x-6 gap-y-8">
      <div className="flex-1">
        <p className="font-medium">{t('settings:general.themeInvoice.logo.subtitle')}</p>
        <p className="mb-4">
          <small className="text-gray-400">{t('settings:general.themeInvoice.logo.helpText')}</small>
        </p>
        <LogoInput />
      </div>

      <div className="flex-1 md:flex-[2]">
        <p className="font-medium">{t('settings:general.themeInvoice.colorTheme.subtitle')}</p>
        <p className="mb-4">
          <small className="text-gray-400">{t('settings:general.themeInvoice.colorTheme.helpText')}</small>
        </p>
        <ThemePicker />
      </div>
    </div>
  );
};
