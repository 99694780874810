import { useMutation, useQueryClient } from '@tanstack/react-query';

import { request } from '../request';
import { quotationForClientQueryOptions } from './useQuotationForClient';

export const useAllowQuotationByClient = (allowKey: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return request(`/quotations/${allowKey}/allow-by-client`, { method: 'POST' });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(quotationForClientQueryOptions(allowKey));
    },
  });
};
