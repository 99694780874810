import type { FormMultiSelectProps as Props } from './types';

import { useFormControlContext } from '../../context';
import { MultiSelect } from './MultiSelect';

export const FormMultiSelect = <T,>({ onAfterChange, ...props }: Props<T>) => {
  const { field, fieldState, id } = useFormControlContext();

  return (
    <MultiSelect
      id={id}
      innerRef={field.ref}
      invalid={fieldState.invalid}
      onBlur={field.onBlur}
      onChange={(value: T[]) => {
        field.onChange(value);
        onAfterChange?.({ oldValue: field.value, newValue: value });
      }}
      value={field.value ?? []}
      {...props}
    />
  );
};
