import type { ListboxOptionProps } from '@headlessui/react';

import { ListboxOption as HuiListboxOption } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { Fragment } from 'react';

import type { WithoutStyleProps } from '../../types';

import { styles } from './ListboxOption.styles';

type Props<TType> = WithoutStyleProps<ListboxOptionProps<'div', TType>> & {
  unstyled?: boolean;
};

export const ListboxOption = <TType,>({ children, unstyled, ...delegated }: Props<TType>) => {
  return (
    <HuiListboxOption as={Fragment} {...delegated}>
      {(state) => (
        <div
          {...stylex.props(
            styles.option,
            state.selectedOption && !unstyled && styles.optionInButton,
            !state.selectedOption && styles.optionInList,
            !state.selectedOption && !unstyled && styles.spacingInList,
            !state.selectedOption && state.selected && styles.selected,
            !state.selectedOption && state.disabled && styles.disabled,
          )}
        >
          {typeof children === 'function' ? children(state) : children}
        </div>
      )}
    </HuiListboxOption>
  );
};
