import { useTranslation } from 'react-i18next';

import { Button } from '~/components';

import { useTableContext } from '../context';
import styles from './TableSelection.module.scss';

export const TableSelection = () => {
  const { getIsAllRowsSelected, getSelectedRowIds, resetRowSelection, toggleAllRowsSelected } = useTableContext();

  const { t } = useTranslation(['common']);

  if (getSelectedRowIds().length === 0) return null;

  return (
    <div className={styles.TableSelection}>
      <span className={styles.CurrentSelection}>
        {t('common:datatable.selection.amountSelected', { amount: getSelectedRowIds().length })}
      </span>

      {!getIsAllRowsSelected() && (
        <Button onClick={() => toggleAllRowsSelected()} type="tertiary">
          {t('common:datatable.selection.selectAll')}
        </Button>
      )}

      <Button onClick={() => resetRowSelection()} type="tertiary">
        {t('common:datatable.selection.removeSelection')}
      </Button>
    </div>
  );
};
