import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { FormField } from '~/components';
import { Icon } from '~/components/SVG';

import type { ActivityCheckboxProps as Props } from './types';

import styles from './ActivityCheckbox.module.scss';

export const ActivityCheckbox = ({ activity }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <FormField>
      {({ field, id }) => (
        <label className={classNames(styles.Wrapper, field.value && styles.Checked)}>
          <input
            checked={field.value ?? false}
            className={styles.NativeCheckbox}
            id={id}
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            type="checkbox"
            value={activity}
          />
          <div className={classNames(styles.Checkbox, field.value && styles.Checked)}>{field.value && <Icon name="Check" size={14} />}</div>
          {t(`common:activities.${activity}.label`)}
        </label>
      )}
    </FormField>
  );
};
