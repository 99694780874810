import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';

import { LinkButton } from '~/components/UI';
import { routes } from '~/providers/RouterProvider/router.routes';

import { styles } from './PreviousStepButton.styles';

type Props = {
  to: 'vatNumber' | 'accountInformation';
};

export const PreviousStepButton = ({ to }: Props) => {
  const { t } = useTranslation(['auth']);

  return (
    <LinkButton
      icon="ArrowBack"
      styles={styles.button}
      to={match(to)
        .with('vatNumber', () => routes.register)
        .with('accountInformation', () => routes.registerAccountInformation)
        .exhaustive()}
    >
      {t('auth:registrationPage.common.previousStepButton')}
    </LinkButton>
  );
};
