import type { SettingsNavigationProps as Props } from './types';

import styles from './SettingsNavigation.module.scss';

export const SettingsNavigation = ({ items }: Props) => {
  return (
    <ul className={styles.List}>
      {items.map(({ anchor, label }) => (
        <li className={styles.ListItem} key={anchor}>
          <a className={styles.Link} href={`#${anchor}`}>
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
};
