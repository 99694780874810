import type { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';

import * as stylex from '@stylexjs/stylex';

import type { WithoutStyleProps } from '../../types';

import { styles } from './Link.styles';

type HTMLAnchorProps = DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>;
type Props = WithoutStyleProps<HTMLAnchorProps> & {
  styles?: stylex.StyleXStyles;
};

export const ExternalLink = ({ children, styles: styleOverrides, ...delegated }: Props) => {
  return (
    <a {...stylex.props(styles.link, styleOverrides)} {...delegated} target={delegated.target ?? '_blank'}>
      {children}
    </a>
  );
};
