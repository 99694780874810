import { z } from 'zod';

export const conditionSchema = z.object({
  id: z.number(),
  name: z.string(),
});

export const customConditionSchema = z.object({
  id: z.number(),
  name: z.string(),
  pdfCreated: z.boolean(),
  wizardData: z.unknown(),
});

export const conditionPdfSchema = z.object({
  pdf: z.string(),
});

export const customConditionPdfSchema = z.object({
  pdf: z.string().min(12), // The length of the PDF header `%PDF-1.x` when Base64 encoded is 12
});
