import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';
import { productSchema } from './schemas';

export const productsQueryOptions = queryOptions({
  queryKey: QueryKeys.products,
  queryFn: async () => {
    const token = getAccessToken();
    const response = await request('/products', { token });

    const { data: products } = apiResourceSchema(productSchema.array()).parse(response);

    return products;
  },
  staleTime: 60 * 1000,
  gcTime: Infinity,
});

export const useProducts = () => {
  return useSuspenseQuery(productsQueryOptions);
};
