import type { FormCheckboxProps as Props } from './types';

import { useFormControlContext } from '../../context';
import { Checkbox } from './Checkbox';

export const FormCheckbox = (props: Props) => {
  const { field, id } = useFormControlContext();

  return <Checkbox checked={!!field.value} id={id} onBlur={field.onBlur} onChange={field.onChange} ref={field.ref} {...props} />;
};
