import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { enumValues } from '~/utils/objects';

import type { VatRegulationToggleProps as Props } from './types';

import { VatRegulationType } from './types';
import styles from './VatRegulationToggle.module.scss';

export const VatRegulationToggle = ({ onChange, value }: Props) => {
  const { t } = useTranslation(['settings']);

  return (
    <div className={styles.toggle}>
      {enumValues(VatRegulationType).map((type) => (
        <button
          className={classNames(styles.button, value === type && styles.active)}
          disabled={value === type}
          key={type}
          onClick={() => onChange(type)}
        >
          {t(`settings:profile.vatRegulation.toggle.${type}`)}
        </button>
      ))}
    </div>
  );
};
