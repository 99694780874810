import * as stylex from '@stylexjs/stylex';

import { fontWeight, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  base: {
    lineHeight: '120%',
    color: systemColors.textBlack,

    // Unset global styles
    margin: 'unset',
  },

  h1: {
    fontSize: '3.5rem',
    fontWeight: fontWeight.bold,
  },

  h2: {
    fontSize: '2.5rem',
    fontWeight: fontWeight.bold,
  },

  h3: {
    fontSize: '2rem',
    fontWeight: fontWeight.bold,
  },

  h4: {
    fontSize: '1.5rem',
    fontWeight: fontWeight.bold,
  },

  h5: {
    fontSize: '1.25rem',
    fontWeight: fontWeight.bold,
  },

  h6: {
    fontSize: '1.25rem',
    fontWeight: fontWeight.semiBold,
  },
});
