import { useCallback, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import type { User } from '~/types/user';

import { env } from '~/constants/env';

import { RegistrationCommandMenuItems } from './Dev/RegistrationCommandMenuItems';
import { RegistrationContext } from './Registration.context';
import {
  useAccountInformation,
  useAmplitude,
  useBusinessInformation,
  useReferralCode,
  useReferralCodeForm,
  useVatInformation,
} from './Registration.hooks';

export const Registration = () => {
  useAmplitude();

  const { clearVatInformation, setVatInformation, vatInformation } = useVatInformation();
  const { accountInformation, clearAccountInformation, setAccountInformation } = useAccountInformation();
  const { businessInformation, clearBusinessInformation, setBusinessInformation } = useBusinessInformation();
  const { clearReferralCodeInformation, referralCodeInformation, setReferralCodeInformation } = useReferralCode(
    accountInformation.emailAddress,
  );
  const { hadReferralCode, setHadReferralCode, setShowReferralCodeForm, showReferralCodeForm } =
    useReferralCodeForm(referralCodeInformation);
  const [user, setUser] = useState<User | null>(null);

  const clearRegistrationInformation = useCallback(() => {
    clearVatInformation();
    clearAccountInformation();
    clearBusinessInformation();
    clearReferralCodeInformation();
  }, [clearAccountInformation, clearBusinessInformation, clearReferralCodeInformation, clearVatInformation]);

  const context = useMemo(
    () => ({
      vatInformation,
      setVatInformation,
      accountInformation,
      setAccountInformation,
      businessInformation,
      setBusinessInformation,
      referralCodeInformation,
      setReferralCodeInformation,
      clearRegistrationInformation,
      showReferralCodeForm,
      setShowReferralCodeForm,
      hadReferralCode,
      setHadReferralCode,
      user,
      setUser,
    }),
    [
      accountInformation,
      businessInformation,
      clearRegistrationInformation,
      hadReferralCode,
      referralCodeInformation,
      setAccountInformation,
      setBusinessInformation,
      setHadReferralCode,
      setReferralCodeInformation,
      setShowReferralCodeForm,
      setVatInformation,
      showReferralCodeForm,
      user,
      vatInformation,
    ],
  );

  return (
    <RegistrationContext.Provider value={context}>
      <Outlet />
      {(env.IS_LOCAL_DEV_ENV || env.IS_STAGING_ENV) && <RegistrationCommandMenuItems />}
    </RegistrationContext.Provider>
  );
};
