export const ColumnIds = {
  selector: 'selector',
  options: 'options',

  quotationsTitle: 'quotations_title',
  quotationsBrand: 'quotations_brand',
  quotationsClient: 'quotations_client',
  quotationsContact: 'quotations_contact',
  quotationsDocDate: 'quotations_doc_date',
  quotationsDocYear: 'quotations_doc_year',
  quotationsDocQuarter: 'quotations_doc_quarter',
  quotationsStatus: 'quotations_status',
  quotationsTotal: 'quotations_total',

  invoicesTitle: 'invoices_title',
  invoicesClient: 'invoices_client',
  invoicesBrand: 'invoices_brand',
  invoicesContact: 'invoices_contact',
  invoicesDocDate: 'invoices_doc_date',
  invoicesDocYear: 'invoices_doc_year',
  invoicesDocQuarter: 'invoices_doc_quarter',
  invoicesStatus: 'invoices_status',
  invoicesTotal: 'invoices_total',
  invoicesAdvantage: 'invoices_advantage',

  creditNotesDescription: 'credit_notes_description',
  creditNotesBrand: 'credit_notes_brand',
  creditNotesClient: 'credit_notes_client',
  creditNotesContact: 'credit_notes_contact',
  creditNotesDocDate: 'credit_notes_doc_date',
  creditNotesDocYear: 'credit_notes_doc_year',
  creditNotesDocQuarter: 'credit_notes_doc_quarter',
  creditNotesReason: 'credit_notes_reason',
  creditNotesStatus: 'credit_notes_status',
  creditNotesTotal: 'credit_notes_total',

  productsName: 'products_name',
  productsStatus: 'products_status',
  productsActivityType: 'products_activity_type',
  productsPrice: 'products_price',
  productsCategories: 'products_categories',

  clientsName: 'clients_name',
  clientsType: 'clients_type',
  clientsVat: 'clients_vat',
  clientsContacts: 'clients_contacts',

  contactsName: 'contacts_name',
  contactsEmail: 'contacts_email',
  contactsPhoneNumber: 'contacts_phone_number',

  brandsLogo: 'brands_logo',
  brandsName: 'brands_name',
  brandsEmail: 'brands_email',
  brandsColor: 'brands_color',

  numberingSeriesName: 'numbering_series_name',
  numberingSeriesType: 'numbering_series_type',
  numberingSeriesNextNumber: 'numbering_series_next_number',

  customConditionsName: 'custom_conditions_name',

  documentsName: 'documents_name',
  documentsExtension: 'documents_extension',
  documentsDownload: 'documents_download',
} as const;
