import * as stylex from '@stylexjs/stylex';

import { Pill, Text, Title } from '~/components/UI';

import { styles } from './AuthLayoutTitleGroup.styles';

type Props = {
  pill: string;
  subtitle: string;
  title: string;
};

export const AuthLayoutTitleGroup = ({ pill, subtitle, title }: Props) => {
  return (
    <div {...stylex.props(styles.titleGroup)}>
      <Pill>{pill}</Pill>
      <Title as="h3">{title}</Title>
      <Text>{subtitle}</Text>
    </div>
  );
};
