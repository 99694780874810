export const community = {
  header: {
    activateProfileLink: 'Make your profile public',
    description: 'Find fellow freelancers and connect with each other',
    editProfileLink: 'Edit your profile',
    preview: 'Check your profile',
    title: 'Craft Community',
  },
  overview: {
    errorLoading:
      "Something went wrong while retrieving the data. If the problem persists, contact <a href='mailto:service@creativeshelter.be'> service@creativeshelter.be</a>.",
    filters: {
      activity: {
        label: 'Activities',
        placeholder: 'All Activities',
      },
      name: {
        placeholder: 'Search by name',
      },
      openToWork: {
        label: 'Availability',
        text: 'Available for assignments',
      },
      region: {
        label: 'Region',
        placeholder: 'All regions',
      },
    },
    loading: 'Loading...',
    locked: 'Make your profile public to become part of the Craft Community',
    resetFilters: '(clear filters)',
    resultCount_one: '{{count}}result',
    resultCount_other: '{{count}}results',
    resultCount_zero: 'No match found based on your search criteria',
    scrollToTop: 'Back to top',
    showMore: 'Show more',
  },
  profileCard: {
    activeInRegion: 'Active in {{regions}}',
    mailLink: 'Email me',
    openToWork: 'Available for assignments',
  },
  regions: {
    Antwerp: 'Antwerp',
    Brussels: 'Brussels',
    EastFlanders: 'East Flanders',
    FlemishBrabant: 'Flemish Brabant',
    Hainaut: 'Hainaut',
    Liege: 'Liège',
    Limburg: 'Limburg',
    Luxembourg: 'Luxemburg',
    Namur: 'Namur',
    WalloonBrabant: 'Walloon Brabant',
    WestFlanders: 'West-Flanders',
  },
};
