import type { HTMLProps } from 'react';
import type { Control } from 'react-hook-form';

import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormErrorMessage, FormLabel, FormTextInput } from '~/components';

type FormWithAddressCity = { address: { city: string } };

export const FormAddressCity = <T extends FormWithAddressCity>({
  control,
  ...props
}: HTMLProps<HTMLDivElement> & {
  control: T extends FormWithAddressCity ? Control<T> : never;
}) => {
  const { t } = useTranslation(['clients', 'validation']);

  return (
    <div {...props}>
      <FormControl as={Fragment} control={control} name="address.city" rules={{ required: true }}>
        <FormLabel>{t('clients:fields.city.label')}</FormLabel>
        <FormTextInput />
        <FormErrorMessage required={t('validation:required')} />
      </FormControl>
    </div>
  );
};
