import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { apiResourceSchema } from '../common/schemas';
import { NotFoundError, shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';
import { referralCodeInformationSchema } from './schemas';

export const useFetchReferralCodeInformation = () => {
  return useMutation({
    mutationFn: async (referralCode: string) => {
      const response = await request(`/registration/referral-code/${referralCode}`);

      const { data: referralCodeInformation } = apiResourceSchema(referralCodeInformationSchema).parse(response);

      return referralCodeInformation;
    },
    onError: (error) => {
      if (!(error instanceof NotFoundError) && shouldCaptureErrorInSentry(error)) {
        captureException(new Error('Failed to fetch referral code information during registration', { cause: error }), {
          level: 'error',
        });
      }
    },
  });
};
