import { env } from '~/constants/env';

import type { FormErrorMessageProps as Props } from './types';

import { useFormControlContext } from '../context';
import { ErrorMessage } from './ErrorMessage';

export const FormErrorMessage = (messages: Props) => {
  const {
    fieldState: { error },
  } = useFormControlContext();

  if (!error) return null;

  const errorMessage = messages[error.type];

  if (!errorMessage) {
    // eslint-disable-next-line no-console
    if (env.IS_LOCAL_DEV_ENV) console.warn(`An error of type '${error.type}' occurred, but no message has been provided for this type.`);

    return null;
  }

  return <ErrorMessage>{typeof errorMessage === 'function' ? errorMessage() : errorMessage}</ErrorMessage>;
};
