import { Icon } from '~/components/SVG';

import type { AttachmentProps as Props } from './types';

import { Button } from '../Button/Button';
import { Spinner } from '../Spinner/Spinner';
import styles from './Attachment.module.scss';

export const Attachment = ({ isLoading = false, onDeleteClick, onViewClick, text }: Props) => {
  return (
    <div className={styles.attachment}>
      <span className={styles.text}>{text}</span>
      <div className={styles.icons}>
        {isLoading ? (
          <Spinner size={24} />
        ) : (
          <>
            <Button onClick={onViewClick} type="iconOnly">
              <Icon name="Visibility" />
            </Button>
            <Button onClick={onDeleteClick} type="iconOnly">
              <Icon name="Delete" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
