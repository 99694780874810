import type { EntryLine, Line, TextLine } from '~/api/common/types';
import type { InvoiceQuotationFormLine, InvoiceQuotationFormSection } from '~/types/invoiceQuotation';

import { insertIf } from './arrays';

export const groupLinesInFormSections = (lines: Line[]): InvoiceQuotationFormSection[] => {
  if (lines.length === 0) {
    return [{ name: '', isPhotoAlbum: false, lines: [] }];
  }

  const photoAlbumLines = lines.filter((line) => line.isPhotoAlbum);
  const defaultLines = lines.filter((line) => !line.isPhotoAlbum);

  const mapLinesToFormLines = (lines: Line[]): InvoiceQuotationFormLine[] =>
    lines.map((line) =>
      line.type === 'entry'
        ? {
            type: 'entry',
            description: line.description,
            price: `${line.price}`,
            discountType: line.discountType,
            discountValue: `${line.discountValue ?? ''}`,
            quantity: `${line.quantity}`,
            unit: line.unit,
            creativeWork: line.creativeWork,
            productId: line.productId,
            productName: line.productName,
            productStatus: line.productStatus,
          }
        : {
            type: 'text',
            description: line.description,
          },
    );

  return [
    ...insertIf(photoAlbumLines.length > 0, {
      name: photoAlbumLines[0]?.sectionName ?? '',
      isPhotoAlbum: true,
      lines: mapLinesToFormLines(photoAlbumLines),
    }),
    {
      name: '',
      isPhotoAlbum: false,
      lines: mapLinesToFormLines(defaultLines),
    },
  ];
};

export const flattenFormSectionsToLines = (sections: InvoiceQuotationFormSection[]): (Omit<EntryLine, 'total'> | TextLine)[] =>
  sections.flatMap((section) =>
    section.lines.map((line) =>
      line.type === 'entry'
        ? {
            type: 'entry',
            sectionName: section.name,
            isPhotoAlbum: section.isPhotoAlbum,
            description: line.description,
            price: +line.price,
            discountType: line.discountType,
            discountValue: line.discountValue ? +line.discountValue : null,
            quantity: +line.quantity,
            unit: line.unit,
            creativeWork: line.creativeWork,
            productId: line.productId,
            productName: line.productName,
            productStatus: line.productStatus,
          }
        : {
            type: 'text',
            sectionName: section.name,
            isPhotoAlbum: section.isPhotoAlbum,
            description: line.description,
          },
    ),
  );
