import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../SVG.types';

export const CraftSymbol = ({ styles, ...delegated }: SvgProps) => {
  return (
    <svg {...stylex.props(styles)} viewBox="0 0 795.97964 530.65341" xmlns="http://www.w3.org/2000/svg" {...delegated}>
      <polygon points="0 0 0 530.653 265.326 530.653 265.326 265.327 530.653 265.327 530.653 530.653 795.98 530.653 795.98 0 0 0" />
    </svg>
  );
};
