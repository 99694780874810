import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UpdateQuotationPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { quotationSchema } from './schemas';
import { quotationsQueryOptions } from './useQuotations';

export const useUpdateQuotation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateQuotationPayload) => {
      const token = getAccessToken();
      const response = await request(`/quotations/${id}`, { method: 'PUT', token, body });

      const { data: quotation } = apiResourceSchema(quotationSchema).parse(response);

      return quotation;
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(quotationsQueryOptions);
    },
  });
};
