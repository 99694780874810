import type * as stylex from '@stylexjs/stylex';

import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useResendVerificationEmail } from '~/api/auth';
import { Button, Span, Text } from '~/components/UI';

type Props = {
  emailAddress: string;
  linkStyles?: stylex.StyleXStyles;
};

export const ResendVerificationEmail = ({ emailAddress, linkStyles }: Props) => {
  const mutation = useResendVerificationEmail();
  const [secondsUntilRetry, setSecondsUntilRetry] = useState(0);

  const { t } = useTranslation(['auth']);

  const resendVerificationEmail = () =>
    mutation.mutate(emailAddress, {
      onSuccess: () => {
        setSecondsUntilRetry(15);

        const intervalId = setInterval(
          () =>
            setSecondsUntilRetry((seconds) => {
              if (seconds === 1) clearInterval(intervalId);
              return seconds - 1;
            }),
          1000,
        );
      },
      onError: () => toast.error(t('auth:resendVerificationEmail.error')),
    });

  if (secondsUntilRetry > 0) {
    return (
      <Text size="small">
        <Trans
          components={{ bold: <Span weight="medium" /> }}
          i18nKey={'auth:resendVerificationEmail.sent'}
          values={{ emailAddress, seconds: secondsUntilRetry }}
        />
      </Text>
    );
  }

  return (
    <Text size="small">
      <Trans
        components={{
          button: <Button loading={mutation.isPending} onClick={resendVerificationEmail} styles={linkStyles} variant="link" />,
        }}
        i18nKey="auth:resendVerificationEmail.send"
      />
    </Text>
  );
};
