import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { useHeartbeat } from '~/api/application';

let currentApiVersion: string | null = null;

export const useForceRefresh = () => {
  const { data: heartbeat } = useHeartbeat();

  // Set the initial application version
  useEffect(() => {
    if (heartbeat.apiVersion && !currentApiVersion) {
      currentApiVersion = heartbeat.apiVersion;
      Sentry.setContext('Craft API', { version: heartbeat.apiVersion });
    }
  }, [heartbeat.apiVersion]);

  // Force refresh the page when the API version is updated
  useEffect(() => {
    const needsRefresh =
      currentApiVersion !== null && // An apiVersion has been stored in memory
      heartbeat.apiVersion !== null && // and an apiVersion has been returned by the heartbeat
      currentApiVersion !== heartbeat.apiVersion; // and the stored apiVersion is not the same as the new apiVersion.

    if (needsRefresh) window.location.reload(); // 🖕
  }, [heartbeat.apiVersion]);
};
