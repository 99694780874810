import { useMemo } from 'react';

import type { CountryCode } from '~/api/common/types';

import { Language } from '~/types/app';

import { useCraftLocalStorage } from './useCraftLocalStorage';

/**
 * Collection of helper functions of the Intl namespace,
 * with automatic locale based on the authenticated user or the chosen app language.
 */
export const useIntl = (localeOverride?: Language) => {
  const [language] = useCraftLocalStorage('language');

  const locale = localeOverride ?? language ?? Language.DUTCH;

  // NumberFormat
  const decimalFormatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'decimal', maximumFractionDigits: 2 }), [locale]);
  const currencyFormatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }), [locale]);
  const shortCurrencyFormatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }), [locale]); // prettier-ignore
  const percentageFormatter = useMemo(() => new Intl.NumberFormat(locale, { style: 'percent' }), [locale]);

  // DateTimeFormat
  const dateFormatter = useMemo(() => new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'long', year: 'numeric' }), [locale]);
  const shortDateFormatter = useMemo(() => new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'long' }), [locale]);
  const dateTimeFormatter = useMemo(
    () => new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: '2-digit' }),
    [locale],
  );

  // ListFormat
  const conjunctiveListFormatter = useMemo(() => new Intl.ListFormat(locale, { style: 'long', type: 'conjunction' }), [locale]);

  // Collator
  const sortCollator = useMemo(() => new Intl.Collator(locale, { usage: 'sort' }), [locale]);

  // DisplayNames
  const regionDisplayNames = useMemo(() => new Intl.DisplayNames(locale, { type: 'region', fallback: 'code' }), [locale]);

  return useMemo(
    () => ({
      formatDecimal: (value: number) => decimalFormatter.format(value),
      formatCurrency: (value: number) => currencyFormatter.format(value),
      formatCurrencyShort: (value: number) => shortCurrencyFormatter.format(value),
      formatPercentage: (value: number) => percentageFormatter.format(value),
      formatDate: (date: string | Date) => dateFormatter.format(new Date(date)),
      formatDateShort: (date: string | Date) => shortDateFormatter.format(new Date(date)),
      formatDateTime: (date: string | Date) => dateTimeFormatter.format(new Date(date)),
      formatConjunctiveList: (list: Iterable<string>) => conjunctiveListFormatter.format(list),
      compareForSort: (x: string, y: string) => sortCollator.compare(x, y),
      displayNameOfCountry: (countryCode: CountryCode) => regionDisplayNames.of(countryCode) ?? '',
    }),
    [
      conjunctiveListFormatter,
      currencyFormatter,
      dateFormatter,
      dateTimeFormatter,
      decimalFormatter,
      percentageFormatter,
      regionDisplayNames,
      shortCurrencyFormatter,
      shortDateFormatter,
      sortCollator,
    ],
  );
};
