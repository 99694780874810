import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, FormControl, FormErrorMessage, FormLabel, FormSelect } from '~/components';
import { mainActivities } from '~/constants/constants';

import type { ActivitiesFormStepOneProps, ActivitiesFormStepOneType } from './types';

import { ActivityOption } from '../ActivityOption/ActivityOption';

export const ActivitiesFormStepOne = ({ formData, onNextStep }: ActivitiesFormStepOneProps) => {
  const { t } = useTranslation(['common', 'settings', 'validation']);

  const { control, handleSubmit } = useForm<ActivitiesFormStepOneType>({
    defaultValues: {
      primaryActivity: formData.primaryActivity,
    },
  });

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onNextStep)}>
      <FormControl control={control} name="primaryActivity" rules={{ required: true }}>
        <FormLabel>{t('settings:fields.activities.primary.label')}</FormLabel>
        <FormSelect
          options={mainActivities.map((activity) => ({
            value: activity,
            label: t(`common:activities.${activity}.label`),
          }))}
          OptionWrapper={ActivityOption}
        />
        <FormErrorMessage required={t('validation:required')} />
      </FormControl>

      <div className="flex justify-end">
        <Button isSubmit type="secondary">
          {t('settings:profile.activities.modal.next')}
        </Button>
      </div>
    </form>
  );
};
