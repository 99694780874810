import type { FilterFn, TableOptions } from '@tanstack/react-table';

import { getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';

import {
  Table,
  TableBody,
  TableFilter,
  TableFilters,
  TableFiltersToggle,
  TableGrid,
  TableHeader,
  TableMenu,
  TablePagination,
  TableRow,
  TableSearch,
  TableSelection,
} from '~/components';

// Custom filter function for multi select and multi combobox
const equalsOneOf: FilterFn<unknown> = (row, columnId: string, filterValue: unknown[]) => {
  return filterValue.includes(row.getValue(columnId));
};
equalsOneOf.autoRemove = (value: unknown) => !Array.isArray(value) || value.length === 0;

export const useTable = <TData extends { id: number }>(
  options: Pick<TableOptions<TData>, 'data' | 'columns' | 'initialState' | 'enableRowSelection' | 'enableSortingRemoval'>,
) => {
  const table = useReactTable({
    // Core
    data: options.data,
    columns: options.columns,
    getCoreRowModel: getCoreRowModel(),
    initialState: {
      pagination: { pageSize: 10 },
      ...options.initialState,
    },
    defaultColumn: {
      filterFn: 'equals', // Use strict equals comparing `===` as default
    },
    getRowId: ({ id }) => `${id}`,

    // Selection
    enableRowSelection: options.enableRowSelection,

    // Filters
    getFilteredRowModel: getFilteredRowModel(),
    manualFiltering: false, // Enable this if the data is server-side filtered
    filterFns: { equalsOneOf },
    globalFilterFn: 'includesString',

    // Sorting
    getSortedRowModel: getSortedRowModel(),
    manualSorting: false, // Enable this if the data is server-side sorted
    enableSortingRemoval: options.enableSortingRemoval,

    // Pagination
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: false, // Enable this if the data is server-side paginated

    // Unused features
    enableHiding: false, // Some columns are hidden by passing the initial visibility state. Yet, this option doesn't needed to be enabled as the visibility state never changes.
    enablePinning: false,
    enableGrouping: false,
    enableColumnResizing: false, // Note: This is handled manually

    // Custom features
    _features: [
      // Filters toggle state
      {
        getInitialState: (state): { showColumnFilters: boolean } => ({ ...state, showColumnFilters: false }),
        createTable: (table) => {
          table.toggleColumnFilters = () => table.setState((state) => ({ ...state, showColumnFilters: !state.showColumnFilters }));
        },
      },
      // Selected row ids (as number)
      {
        createTable: (table) => {
          table.getSelectedRowIds = () =>
            Object.entries(table.getState().rowSelection)
              .filter(([, isSelected]) => isSelected)
              .map(([id]) => +id);
        },
      },
    ],
  });

  /**
   * Table components are exposed from this hook to enable strong typing, by passing the inferred data type to these components (where applicable).
   *
   * Note that this does not enforce correctness, as it is possible to mix the components of multiple `useTable` instances.\
   * The typing of these components only exist for convenience — it is the consumer's responsibility to use these correctly.
   */
  const components = useMemo(
    () => ({
      Root: Table<TData>,
      Menu: TableMenu,
      Grid: TableGrid,
      Header: TableHeader,
      Body: TableBody<TData>,
      Row: TableRow<TData>,
      Search: TableSearch,
      FiltersToggle: TableFiltersToggle,
      Filters: TableFilters,
      Filter: TableFilter,
      Pagination: TablePagination,
      Selection: TableSelection,
    }),
    [],
  );

  return [table, components] as const;
};
