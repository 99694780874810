import { Trans, useTranslation } from 'react-i18next';

import { CollapsibleSection, PageHeader } from '~/components';
import { SettingsNavigation } from '~/components/Templates/Settings';
import { AnchorTargets } from '~/constants/url';

import { CustomConditions } from './CustomConditions/CustomConditions';
import { EmailSettings } from './EmailSettings/EmailSettings';
import { InvoiceTheme } from './InvoiceTheme/InvoiceTheme';
import { NumberingSeries } from './NumberingSeries/NumberingSeries';

export const General = () => {
  const { t } = useTranslation(['settings']);

  return (
    <>
      <PageHeader description={t('settings:general.description')} title={t('settings:general.title')} />

      <SettingsNavigation
        items={[
          { anchor: AnchorTargets.GENERAL_INVOICE_THEME, label: t('settings:general.themeInvoice.title') },
          { anchor: AnchorTargets.GENERAL_NUMBERING_SERIES, label: t('settings:general.numberingSeries.title') },
          { anchor: AnchorTargets.GENERAL_EMAIL_SETTINGS, label: t('settings:general.email.title') },
          { anchor: AnchorTargets.GENERAL_CUSTOM_CONDITIONS, label: t('settings:general.customConditions.title') },
        ]}
      />

      {/* Invoice theme */}
      <CollapsibleSection
        description={t('settings:general.themeInvoice.description')}
        id={AnchorTargets.GENERAL_INVOICE_THEME}
        title={t('settings:general.themeInvoice.title')}
      >
        <InvoiceTheme />
      </CollapsibleSection>

      {/* Numbering series */}
      <CollapsibleSection
        description={<Trans components={{ a: <a /> }} i18nKey="settings:general.numberingSeries.description" />}
        id={AnchorTargets.GENERAL_NUMBERING_SERIES}
        title={t('settings:general.numberingSeries.title')}
      >
        <NumberingSeries />
      </CollapsibleSection>

      {/* Email information */}
      <CollapsibleSection
        description={t('settings:general.email.description')}
        id={AnchorTargets.GENERAL_EMAIL_SETTINGS}
        title={t('settings:general.email.title')}
      >
        <EmailSettings />
      </CollapsibleSection>

      {/* Custom conditions */}
      <CollapsibleSection
        description={t('settings:general.customConditions.description')}
        id={AnchorTargets.GENERAL_CUSTOM_CONDITIONS}
        title={t('settings:general.customConditions.title')}
      >
        <CustomConditions />
      </CollapsibleSection>
    </>
  );
};
