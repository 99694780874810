import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { NumberingSeries } from '~/types/user';

import { DropdownMenu } from '~/components';
import { ColumnIds } from '~/constants/table';
import { useStableRef } from '~/hooks/useStableRef';
import { generateEntryNumber } from '~/utils/string';

import { getNumberingSeriesType } from './utils';

const columnHelper = createColumnHelper<NumberingSeries>();

export const useNumberingSeriesTableColumns = (actions: {
  onDelete: (numberingSeries: NumberingSeries) => void;
  onEditFormatAndOptions: (numberingSeries: NumberingSeries) => void;
  onEditNameAndType: (numberingSeries: NumberingSeries) => void;
}) => {
  const stableActions = useStableRef(actions);

  const { t } = useTranslation(['settings']);

  return useMemo(
    () => [
      // Name
      columnHelper.accessor('name', {
        id: ColumnIds.numberingSeriesName,
        header: t('settings:general.numberingSeries.headers.name'),
        cell: ({ getValue }) => <div className="lg:truncate">{getValue()}</div>,
        meta: {
          styles: { size: '2fr', minSize: 100 },
          mobileStyles: { size: '1fr' },
          isResizable: true,
        },
      }),

      // Type
      columnHelper.accessor((numberingSeries) => getNumberingSeriesType(numberingSeries), {
        id: ColumnIds.numberingSeriesType,
        header: t('settings:general.numberingSeries.headers.type'),
        cell: ({ getValue }) => <div className="lg:truncate">{t(`settings:fields.numberingSeries.type.options.${getValue()}`)}</div>,
        meta: {
          styles: { size: '1fr', minSize: 75 },
          isResizable: true,
        },
      }),

      // Next number
      columnHelper.accessor((numberingSeries) => generateEntryNumber(numberingSeries), {
        id: ColumnIds.numberingSeriesNextNumber,
        header: t('settings:general.numberingSeries.headers.nextNumber'),
        cell: ({ getValue }) => <div className="lg:truncate">{getValue()}</div>,
        meta: {
          styles: { size: '1fr', minSize: 100 },
          mobileStyles: { size: 'max-content', justify: 'end', minBreakpoint: 'sm' },
          isResizable: true,
        },
      }),

      // Options
      columnHelper.display({
        id: ColumnIds.options,
        cell: ({ row: { original: numberingSeries } }) => (
          <DropdownMenu
            dropdownClass="text-sm"
            items={[
              {
                iconName: 'Edit' as const,
                text: t('settings:general.numberingSeries.edit.name'),
                onClick: () => stableActions.current.onEditNameAndType(numberingSeries),
              },
              {
                iconName: 'Edit' as const,
                text: t('settings:general.numberingSeries.edit.format'),
                onClick: () => stableActions.current.onEditFormatAndOptions(numberingSeries),
              },
              {
                iconName: 'Delete' as const,
                text: t('settings:general.numberingSeries.delete.title'),
                onClick: () => stableActions.current.onDelete(numberingSeries),
              },
            ]}
          />
        ),
        meta: {
          styles: { size: 'max-content', minSize: 40 },
        },
      }),
    ],
    [stableActions, t],
  );
};
