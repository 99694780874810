import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UpdateClientPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { clientSchema } from './schemas';
import { clientsQueryOptions } from './useClients';

export const useUpdateClient = (clientId: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateClientPayload) => {
      const token = getAccessToken();
      const response = await request(`/clients/${clientId}`, { method: 'PATCH', token, body });

      const { data: client } = apiResourceSchema(clientSchema).parse(response);

      return client;
    },
    onSuccess: (updatedClient) => {
      queryClient.setQueryData(clientsQueryOptions.queryKey, (clients) =>
        (clients ?? []).map((client) => (client.id === updatedClient.id ? updatedClient : client)),
      );
      queryClient.invalidateQueries(clientsQueryOptions);
    },
  });
};
