import * as stylex from '@stylexjs/stylex';

import type { MediaQueryMobile, MediaQuerySmallMobileAndBelow } from '~/styles/mediaQueries';

import { brandColors, neutralColors, radius, spacing, systemColors } from '~/styles/tokens.stylex';

const Mobile: MediaQueryMobile = '@media (640px <= width < 768px)';
const SmallMobileAndBelow: MediaQuerySmallMobileAndBelow = '@media (width < 640px)';

const columnWidth = '56px';
const barThickness = '12px';
const circleSize = '32px';
const gridTemplateColumns = `2fr [pct-25-start] ${columnWidth} [pct-25-end] 1fr [pct-50-start] ${columnWidth} [pct-50-end] 1fr [pct-75-start] ${columnWidth} [pct-75-end] 1fr [pct-100-start] ${columnWidth} [pct-100-end]`;
const mobileGridTemplateColumns = `4fr [pct-25-start] ${columnWidth} [pct-25-end] 1fr [pct-50-start] ${columnWidth} [pct-50-end] 1fr [pct-75-start] ${columnWidth} [pct-75-end] 1fr [pct-100-start] ${columnWidth} [pct-100-end]`;
const gridTemplateRows = `76px [pct-25-start] ${circleSize} [pct-25-end] 48px [pct-50-start] ${circleSize} [pct-50-end] 48px [pct-75-start] ${circleSize} [pct-75-end] 48px [pct-100-start] ${circleSize} [pct-100-end]`;

export const styles = stylex.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.medium,
  },

  progressCard: {
    display: 'grid',
    gridTemplateColumns: {
      default: gridTemplateColumns,
      [Mobile]: mobileGridTemplateColumns,
      [SmallMobileAndBelow]: '1fr auto 1fr',
    },
    gridTemplateRows: {
      default: null,
      [SmallMobileAndBelow]: gridTemplateRows,
    },
    gridTemplateAreas: {
      default: `
        "label-discount label-25 . label-50 . label-75 . label-100"
        "bar bar bar bar bar bar bar bar"
        "label-invitations label-1 . label-2 . label-3 . label-4"
      `,
      [SmallMobileAndBelow]: `
        "label-discount bar label-invitations"
        "label-25 bar label-1"
        ". bar ."
        "label-50 bar label-2"
        ". bar ."
        "label-75 bar label-3"
        ". bar ."
        "label-100 bar label-4"
      `,
    },
    rowGap: {
      default: spacing.small,
      [SmallMobileAndBelow]: spacing.none,
    },
    columnGap: {
      default: spacing.none,
      [SmallMobileAndBelow]: spacing.medium,
    },
    paddingInline: spacing.medium,
    paddingBlock: spacing.normal,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: systemColors.borderLight,
    borderRadius: radius.xxsmall,
  },

  headerLabelDiscount: {
    gridArea: 'label-discount',
    alignSelf: 'start',
    textAlign: {
      default: 'left',
      [SmallMobileAndBelow]: 'right',
    },
  },

  headerLabelInvitations: {
    gridArea: 'label-invitations',
    alignSelf: 'start',
    textAlign: 'left',
  },

  labelDiscount: {
    minWidth: {
      default: null,
      [SmallMobileAndBelow]: columnWidth,
    },
    alignSelf: 'center',
    textAlign: 'center',
    justifySelf: {
      default: 'stretch',
      [SmallMobileAndBelow]: 'end',
    },
  },

  labelInvitations: {
    alignSelf: 'center',
    textAlign: 'center',
    justifySelf: {
      default: 'stretch',
      [SmallMobileAndBelow]: 'start',
    },
  },

  labelActive: {
    borderRadius: radius.xxxsmall,
    color: neutralColors.white,
    backgroundColor: brandColors.primary500,
  },

  label25: { gridArea: 'label-25' },
  label50: { gridArea: 'label-50' },
  label75: { gridArea: 'label-75' },
  label100: { gridArea: 'label-100' },
  label1: { gridArea: 'label-1' },
  label2: { gridArea: 'label-2' },
  label3: { gridArea: 'label-3' },
  label4: { gridArea: 'label-4' },

  progressBar: {
    gridArea: 'bar',
    display: 'grid',
    gridTemplateColumns: {
      default: gridTemplateColumns,
      [Mobile]: mobileGridTemplateColumns,
      [SmallMobileAndBelow]: '1fr',
    },
    gridTemplateRows: {
      default: null,
      [SmallMobileAndBelow]: gridTemplateRows,
    },
    width: {
      default: null,
      [SmallMobileAndBelow]: barThickness,
    },
    height: {
      default: barThickness,
      [SmallMobileAndBelow]: 'unset',
    },
    background: {
      default: `linear-gradient(to right, ${neutralColors.gray050} calc(100% - ${columnWidth} / 2), transparent calc(100% - ${columnWidth} / 2))`,
      [SmallMobileAndBelow]: `linear-gradient(to bottom, transparent calc(${circleSize} / 2), ${neutralColors.gray050} calc(${circleSize} / 2) calc(100% - ${circleSize} / 2), transparent calc(100% - ${circleSize} / 2))`,
    },
    position: 'relative',
  },

  progressBarFilled: {
    backgroundColor: brandColors.primary500,
    marginInline: {
      default: `calc(${columnWidth} / 2 * -1)`,
      [SmallMobileAndBelow]: 'unset',
    },
    marginBlock: {
      default: null,
      [SmallMobileAndBelow]: `calc(${circleSize} / 2 * -1)`,
    },
  },

  progressBarFilled25: {
    gridColumn: {
      default: '1 / pct-25-start',
      [SmallMobileAndBelow]: 'unset',
    },
    gridRow: {
      default: null,
      [SmallMobileAndBelow]: '1 / pct-25-start',
    },
    marginInlineStart: 0,
    marginBlockStart: {
      default: null,
      [SmallMobileAndBelow]: `calc(${circleSize} / 2)`,
    },
  },

  progressBarFilled50: {
    gridColumn: {
      default: 'pct-25-end / pct-50-start',
      [SmallMobileAndBelow]: 'unset',
    },
    gridRow: {
      default: null,
      [SmallMobileAndBelow]: 'pct-25-end / pct-50-start',
    },
  },

  progressBarFilled75: {
    gridColumn: {
      default: 'pct-50-end / pct-75-start',
      [SmallMobileAndBelow]: 'unset',
    },
    gridRow: {
      default: null,
      [SmallMobileAndBelow]: 'pct-50-end / pct-75-start',
    },
  },

  progressBarFilled100: {
    gridColumn: {
      default: 'pct-75-end / pct-100-start',
      [SmallMobileAndBelow]: 'unset',
    },
    gridRow: {
      default: null,
      [SmallMobileAndBelow]: 'pct-75-end / pct-100-start',
    },
  },

  circleWrapper: {
    position: 'absolute',
    insetInline: 0,
    top: {
      default: `calc((${circleSize} - ${barThickness}) / 2 * -1)`,
      [SmallMobileAndBelow]: 0,
    },
    left: {
      default: null,
      [SmallMobileAndBelow]: `calc((${circleSize} - ${barThickness}) / 2 * -1)`,
    },
    display: 'grid',
    gridTemplateColumns: {
      default: gridTemplateColumns,
      [Mobile]: mobileGridTemplateColumns,
      [SmallMobileAndBelow]: '1fr auto 1fr',
    },
    gridTemplateRows: {
      default: null,
      [SmallMobileAndBelow]: gridTemplateRows,
    },
    justifyItems: 'center',
  },

  circle: {
    width: circleSize,
    height: circleSize,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: neutralColors.white,
    borderRadius: radius.full,
    backgroundColor: neutralColors.gray050,
  },

  circleFilled: {
    backgroundColor: brandColors.primary500,
  },

  circle25: { gridArea: 'pct-25' },
  circle50: { gridArea: 'pct-50' },
  circle75: { gridArea: 'pct-75' },
  circle100: { gridArea: 'pct-100' },
});
