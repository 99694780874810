import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useSignContract, useUser } from '~/api/user';
import { Button, FormCheckbox, FormControl, FormErrorMessage, Modal, Spinner } from '~/components';
import { FrameworkAgreement } from '~/components/Templates/FrameworkAgreement';

import type { FrameworkAgreementTabProps as Props } from './types';

export const FrameworkAgreementTab = ({ isLastTab, onCompleted }: Props) => {
  const { data: user } = useUser();
  const mutation = useSignContract();

  const userNeedsToSignAddendum = user.contractSigned && !user.addendumSigned;

  const { control, handleSubmit } = useForm<{ agreed: boolean }>({
    defaultValues: { agreed: false },
  });

  const { t } = useTranslation(['dashboard', 'settings']);

  const onSubmit = handleSubmit(() => {
    mutation.mutate(undefined, {
      onSuccess: () => onCompleted(),
      onError: () => toast.error(t('settings:accountCompletionModal.frameworkAgreement.error')),
    });
  });

  return (
    <div className="space-y-4">
      <FrameworkAgreement isAddendum={userNeedsToSignAddendum} />

      <form className="space-y-4" onSubmit={onSubmit}>
        <FormControl control={control} name="agreed" rules={{ required: true }}>
          <FormCheckbox label={t('dashboard:frameworkAgreement.checkboxLabel')} />
          <FormErrorMessage required={t('dashboard:frameworkAgreement.checkboxError')} />
        </FormControl>

        <Modal.Actions>
          <Button disabled={mutation.isPending} isSubmit>
            {mutation.isPending && <Spinner size={24} />}
            <span>{t(`settings:accountCompletionModal.action.${isLastTab ? 'complete' : 'next'}`)}</span>
          </Button>
        </Modal.Actions>
      </form>
    </div>
  );
};
