import type { Control, UseFormGetValues } from 'react-hook-form';

import { useMutationState } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import type { CalculationData } from '~/api/common/types';
import type { CreateEditQuotationFormType } from '~/pages/Quotations/CreateEditQuotation/types';

import { QueryKeys } from '~/api/keys';
import { useCalculateForQuotation } from '~/api/quotations';
import { mapQuotationFormDataToDraftPayload } from '~/pages/Quotations/CreateEditQuotation/utils';

import { useDebouncedState } from '../useDebouncedState';

/**
 * Perform calculation for quotation when input changes (debounced)
 */
export function useCalculationData({
  control,
  enabled,
  getValues,
  id,
}: {
  control: Control<CreateEditQuotationFormType>;
  enabled: boolean;
  getValues: UseFormGetValues<CreateEditQuotationFormType>;
  id: number;
}) {
  const { isPending, mutate } = useCalculateForQuotation(id);

  // Manually grab the data of the latest mutation call.
  // This prevents data from being undefined while a new request is pending.
  const lastCalculationData = useMutationState({
    filters: { mutationKey: QueryKeys.quotationCalculation(id), exact: true, status: 'success' },
    select: (mutation) => mutation.state.data,
  }).at(-1) as CalculationData;

  const [contactId, sections] = useWatch({
    control: control,
    name: ['contactId', 'sections'],
  });
  const values = useMemo(() => ({ contactId, sections }), [contactId, sections]);
  const [debouncedValues] = useDebouncedState(values);

  useEffect(() => {
    if (!enabled) return;

    mutate(mapQuotationFormDataToDraftPayload(getValues() as CreateEditQuotationFormType));
  }, [debouncedValues, mutate, enabled, getValues]);

  return useMemo(() => ({ data: lastCalculationData ?? null, isPending }), [isPending, lastCalculationData]);
}
