import { createPortal } from 'react-dom';

import type { CollapsibleSectionButtonProps as Props } from './types';

import { Button } from '../Button/Button';
import { useCollapsibleSectionContext } from './context';

export const CollapsibleSectionButton = ({ children, icon, onClick }: Props) => {
  const buttonPortal = useCollapsibleSectionContext();

  if (!buttonPortal) return null;

  return createPortal(
    <Button icon={icon} onClick={onClick}>
      {children}
    </Button>,
    buttonPortal,
  );
};
