import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { belgianVatInformationSchema } from '../clients/schemas';
import { apiResourceSchema } from '../common/schemas';
import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';

export const useFetchVatInformation = () => {
  return useMutation({
    mutationFn: async (vatNumber: string) => {
      const response = await request(`/registration/vat-information/${vatNumber}`);

      const { data: vatInformation } = apiResourceSchema(belgianVatInformationSchema).parse(response);

      return vatInformation;
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) &&
      captureException(new Error('Failed to fetch VAT information during registration', { cause: error }), { level: 'fatal' }),
  });
};
