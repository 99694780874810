import { CustomConditionStep } from './types';

export const initialState = {
  [CustomConditionStep.START]: null,
  [CustomConditionStep.ALREADY_AGREEMENT]: null,
  [CustomConditionStep.ALREADY_AGREEMENT_NO]: null,
  [CustomConditionStep.ALREADY_AGREEMENT_PAPER]: null,
  [CustomConditionStep.ALREADY_AGREEMENT_NO_PAPER]: null,
  [CustomConditionStep.ALREADY_AGREEMENT_YES_PAPER]: null,
  [CustomConditionStep.TRANSFER_COPYRIGHT]: null,
  [CustomConditionStep.TRANSFER_COPYRIGHT_YES]: null,
  [CustomConditionStep.TRANSFER_COPYRIGHT_NO]: null,
  [CustomConditionStep.USE_WHERE]: {
    selected: null,
    text: null,
  },
  [CustomConditionStep.USE_HOW_LONG]: {
    selected: null,
    text: null,
  },
  [CustomConditionStep.USE_HOW]: {
    0: false,
    1: false,
    2: false,
  },
  [CustomConditionStep.REPRODUCE_HOW]: {
    0: false,
    1: false,
    2: false,
    3: false,
    text: null,
  },
  [CustomConditionStep.REPRODUCE_HOW_MANY]: { selected: null, text: null },
  [CustomConditionStep.SHARE_HOW]: { selected: null, text: null },
  [CustomConditionStep.MODIFY_HOW]: null,
  [CustomConditionStep.OVERVIEW]: null,
  [CustomConditionStep.THANKS]: null,
};
