export const creditnotes = {
  alerts: {
    duplicateEntryNumber: 'Ce numéro de note de crédit existe déjà, veuillez le modifier.',
    successCreated: 'La note de crédit a bien été créée !',
  },
  create: {
    confirmation: {
      description:
        'Voulez-vous créer la note de crédit ? Une fois la note de crédit approuvée et envoyée, vous ne pouvez plus y apporter de modifications.',
      submit: 'Oui, créer la note de crédit',
      title: 'Votre note de crédit est en cours de transmission',
    },
    partialPaymentsWarning: {
      message_one:
        "Nous avons déjà reçu un paiement partiel de {{ payment }} pour cette facture. N'hésitez pas à créditer la facture, nous vous contacterons pour faire le suivi de ce paiement.",
      message_other:
        "Nous avons déjà reçu des paiements partiels de {{ payment }} pour cette facture. N'hésitez pas à créditer la facture, nous vous contacterons pour faire le suivi de ces paiements.",
      title: 'Avertissement !',
    },
    submit: 'Créer une note de crédit',
    title: 'Détails de la note de crédit',
  },
  fields: {
    invoiceId: {
      label: 'Note de crédit pour la facture',
      placeholder: 'Sélectionner une facture',
    },
    numberingSeriesId: {
      helpText: 'Le numéro de la note de crédit sera <0>{{entryNumber}}</0>.',
      label: 'Série de numérotation',
      labelTooltip:
        'Des questions sur la création d\'une série de numérotation ? Cliquez <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp" target="_blank">ici</a> pour plus d\'info.',
      placeholder: 'Sélectionner une série de numérotation.',
      tooltip: "Voici l'aperçu de votre numéro de la note de crédit. Lors de l'envoi de votre facture, le numéro sera définitif.",
      tooltipCreate:
        'Une série de numérotation vous aide à numéroter vos factures de manière correcte et fluide. Vous pouvez en créer autant que vous le souhaitez, mais une seule suffit. En savoir plus ou des questions ? <a href="https://knowledge-creativeshelter-be.translate.goog/wat-is-een-nummeringreeks-en-hoe-stel-ik-deze-in?_x_tr_sl=nl&_x_tr_tl=fr&_x_tr_hl=nl&_x_tr_pto=wapp">Consultez l\'info ici.</a>',
    },
    reason: {
      label: 'Raison',
      placeholder: 'Saisir la raison ici.',
    },
  },
  overview: {
    columns: {
      client: 'Client',
      date: 'Date',
      entryNumber: 'Note de crédit',
      reason: 'Raison',
      status: {
        options: {
          Approved: 'Approuvé',
          Pending: 'Pour approbation',
        },
        title: 'Statut',
      },
      total: 'Crédité',
      year: 'Année',
    },
    created: {
      subtitle: 'Notes de crédit',
    },
    rowActions: {
      download: 'Télécharger',
    },
  },
};
