import type { LabelProps } from '@headlessui/react';
import type { ReactNode } from 'react';

import { Label as HuiLabel } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';
import { useTranslation } from 'react-i18next';

import { IconTooltip } from '~/components/UI';

import type { WithoutStyleProps } from '../../types';

import { styles } from './Label.styles';

type Props = WithoutStyleProps<LabelProps> & {
  children: ReactNode;
  optional?: boolean;
  styles?: stylex.StyleXStyles;
  tooltip?: ReactNode;
};

export const Label = ({ children, optional = false, styles: styleOverrides, tooltip, ...delegated }: Props) => {
  const { t } = useTranslation(['common']);

  return (
    <HuiLabel {...stylex.props(styles.label, styleOverrides)} {...delegated}>
      <span>{children}</span>
      {optional && <span {...stylex.props(styles.optional)}>{t('common:optionalInput')}</span>}
      {tooltip && <IconTooltip>{tooltip}</IconTooltip>}
    </HuiLabel>
  );
};
