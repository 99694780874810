import { queryOptions, useQuery } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { QueryKeys } from '../keys';
import { request } from '../request';
import { invoiceHistorySchema } from './schemas';

export const invoiceHistoryQueryOptions = (id: number) =>
  queryOptions({
    queryKey: QueryKeys.invoiceHistory(id),
    queryFn: async () => {
      const token = getAccessToken();
      const response = await request(`/invoices/${id}/history`, { token });

      const { data: invoiceHistory } = apiResourceSchema(invoiceHistorySchema).parse(response);

      return invoiceHistory;
    },
    staleTime: 60 * 1000,
  });

export const useInvoiceHistory = (id: number) => {
  return useQuery(invoiceHistoryQueryOptions(id));
};
