import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { download } from '../request';

export const useDownloadInvoices = () => {
  return useMutation({
    mutationFn: (ids: number[]) => {
      const token = getAccessToken();
      const body = { ids };
      return download('/invoices/download', { method: 'POST', token, body });
    },
  });
};
