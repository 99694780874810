import { init as initSentry, reactRouterV6BrowserTracingIntegration, replayIntegration } from '@sentry/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import invariant from 'tiny-invariant';

import { GlobalErrorBoundary } from '~/components';
import { env } from '~/constants/env';
import { MaintenanceProvider } from '~/providers/MaintenanceProvider/MaintenanceProvider';
import { QueryClientProvider } from '~/providers/QueryClientProvider/QueryClientProvider';
import { RouterProvider } from '~/providers/RouterProvider/RouterProvider';
import '~/styles/Toastify.scss';
import { initTranslations } from '~/translations/i18n';
import { businessDays } from '~/utils/dayjs';

import { CommandMenuProvider } from './providers/CommandMenuProvider/CommandMenuProvider';

// Init Sentry
initSentry({
  dsn: env.IS_PRODUCTION_ENV || env.IS_STAGING_ENV ? env.SENTRY_DSN : '',
  environment: env.VERCEL_ENVIRONMENT,
  normalizeDepth: 10,
  integrations: [
    replayIntegration({ maskAllText: false, networkDetailAllowUrls: [env.API_URL] }),
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
      shouldCreateSpanForRequest: (url) => !/\/ping\/?$/.test(url),
    }),
  ],
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 0.2,
  tracePropagationTargets: [env.API_URL],
});

dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(businessDays);

// Load the translations
initTranslations();

// Render the app
const container = document.getElementById('root');
invariant(container, 'Missing root element in the DOM');

const root = createRoot(container);
root.render(
  <StrictMode>
    <QueryClientProvider>
      <CommandMenuProvider>
        <GlobalErrorBoundary>
          <MaintenanceProvider>
            <RouterProvider />
          </MaintenanceProvider>
        </GlobalErrorBoundary>
      </CommandMenuProvider>
    </QueryClientProvider>
  </StrictMode>,
);
