import { captureException } from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

import { shouldCaptureErrorInSentry } from '../errors';
import { request } from '../request';

type Payload = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

export const useCreateUncompletedRegistration = () => {
  return useMutation({
    mutationFn: (body: Payload) => {
      return request('/registration/notify-sales-team', { method: 'POST', body });
    },
    onError: (error) =>
      shouldCaptureErrorInSentry(error) &&
      captureException(new Error('Failed to notify sales team about uncompleted registration', { cause: error }), { level: 'error' }),
  });
};
