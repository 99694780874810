import { useTranslation } from 'react-i18next';

import { Button, Modal } from '~/components';

import type { CreditNoteConfirmationModal as Props } from './types';

import { EditEntryNumberForm } from './EditEntryNumberForm';
import { useEntryNumberForm } from './hooks';

export const CreditNoteConfirmationModal = ({ disabled, numberingSeries, onClose, onConfirm }: Props) => {
  const { entryNumber, form } = useEntryNumberForm(numberingSeries);
  const { t } = useTranslation(['common', 'creditnotes']);

  return (
    <Modal onClose={onClose}>
      <Modal.Title>{t('creditnotes:create.confirmation.title')}</Modal.Title>

      <p>{t('creditnotes:create.confirmation.description')}</p>

      <EditEntryNumberForm entryNumber={entryNumber} form={form} numberingSeries={numberingSeries} type="creditNote" />

      <Modal.Actions>
        <Button disabled={disabled} icon="Send" onClick={() => onConfirm({ entryNumber, nextNumber: form.getValues('nextNumber') + 1 })}>
          {t('creditnotes:create.confirmation.submit')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
