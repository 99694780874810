import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import invariant from 'tiny-invariant';

import { useUpdateActivities, useUser } from '~/api/user';
import { Modal } from '~/components';
import { activities } from '~/constants/constants';
import { filterBoolean } from '~/utils/arrays';

import type { ActivitiesFormType } from './types';

import { ActivitiesFormStepOne } from './ActivitiesFormStepOne';
import { ActivitiesFormStepTwo } from './ActivitiesFormStepTwo';

export const ActivitiesModal = () => {
  const { data: user } = useUser();
  const mutation = useUpdateActivities();

  const { t } = useTranslation(['common', 'settings']);

  const [formStep, setFormStep] = useState<1 | 2>(1);

  const primaryActivity = user.activities.find((activity) => activity.isPrimaryActivity);
  const secondaryActivities = user.activities.filter((activity) => !activity.isPrimaryActivity);
  const otherActivity = user.activities.find((activity) => !!activity.extraInformation);

  const [formData, setFormData] = useState<ActivitiesFormType>({
    primaryActivity: primaryActivity?.id ?? null,
    secondaryActivities: activities.map((activity) => secondaryActivities.map(({ id }) => id).includes(activity)),
    otherActivityInformation: otherActivity?.extraInformation ?? '',
  });

  const onSubmit = (data: ActivitiesFormType) => {
    // Narrow types of the required fields
    invariant(data.primaryActivity, 'Expected primaryActivity to be defined.');

    mutation.mutate(
      {
        primaryActivity: data.primaryActivity,
        secondaryActivities: data.secondaryActivities.map((selected, i) => (selected ? activities[i] : null)).filter(filterBoolean),
        otherActivityInformation: data.otherActivityInformation,
        appSettings: { ...user.appSettings, showActivityModal: false },
      },
      {
        onError: () => toast.error(t('common:error')),
      },
    );
  };

  return (
    <Modal modal onClose={() => null}>
      <Modal.Title>{t('settings:profile.activities.modal.title')}</Modal.Title>

      <p>{t('settings:profile.activities.modal.description')}</p>

      {formStep === 1 && (
        <ActivitiesFormStepOne
          formData={formData}
          onNextStep={(data) => {
            setFormData((prev) => ({ ...prev, ...data }));
            setFormStep(2);
          }}
        />
      )}

      {formStep === 2 && (
        <ActivitiesFormStepTwo
          formData={formData}
          onFinish={(data) => {
            setFormData((prev) => ({ ...prev, ...data }));
            onSubmit({ ...formData, ...data });
          }}
          onPreviousStep={(data) => {
            setFormData((prev) => ({ ...prev, ...data }));
            setFormStep(1);
          }}
        />
      )}
    </Modal>
  );
};
