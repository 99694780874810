import * as stylex from '@stylexjs/stylex';

import type { SvgProps } from '../../SVG.types';

import { styles } from '../Illustration.styles';

type Props = SvgProps & {
  arrow?: boolean;
  cloud?: boolean;
  laptop?: boolean;
  person?: boolean;
  sparkle?: boolean;
};

export const KiiaPersonWithComputer = ({
  arrow = true,
  cloud = true,
  laptop = true,
  person = true,
  sparkle = true,
  styles: styleOverrides,
  ...delegated
}: Props) => {
  return (
    <svg
      {...stylex.props(styles.svg, styleOverrides)}
      data-name="KIIA 2"
      viewBox="78.92 283.74 450.94 339.4"
      xmlns="http://www.w3.org/2000/svg"
      {...delegated}
    >
      {person && (
        <g>
          <g>
            <path
              {...stylex.props(styles.stroke, styles.fillBlack)}
              d="M484.39,623.13h-68.35c26.56-5.58,44.83-16.83,53-35l15.35,35Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M529.86,623.13h-45.47l-15.35-35c-8.17,18.17-26.44,29.43-53,35h-4.44c1.74-3.72,7.93-17.99,9.72-35.87.42-4.15.54-8.54.45-12.94,6.54-27.02,6.88-43.44-5.38-46.94-1.26,1.03-2.58,2.02-3.94,2.96-3.08-4.97-6.78-8.59-10.62-11.03l11.99-9.57c.95-.76,1.44-1.89,1.44-3.04,0-.91-.3-1.83-.92-2.61-1.41-1.77-3.93-2.1-5.62-.75l-15.49,12.36c-2.29-.5-4.52-.62-6.57-.42-11.18,1.11-16.44,8.35-18.85,13.85-4.03-.06-8.3.57-12.53,2.31-17.32,7.14-22.86,24.66-21.65,26.38.82,1.16,2.3,1.87,3.93,2.3l-16.61,13.26-4.39,8.74,9.5-2.34,12.37-9.87c-1.65,3.8-2.49,8.13-2.39,12.85-2.29,1.52-4.46,3.35-6.49,5.51l-42.19-18.5c5.38-8.48,11.18-17.26,17.32-26.05,15.34-21.98,32.78-44.01,51.02-61.57h0c14.04,8.62,57.67-16.77,55.89-34.68h0c4.56-.99,9.08-1.44,13.56-1.27,33.56,1.24,57.92,35.51,74.93,75.17,15.68,36.56,25.12,77.69,29.78,101.76Z"
            ></path>
            <g>
              <path
                {...stylex.props(styles.stroke, styles.fillWhite)}
                d="M392.53,454.55c-12.38,6.38-24.78,16.02-36.83,27.62h0c-18.24,17.56-35.68,39.59-51.02,61.57-8.97-6.28-17.57-12.68-22.12-20.43,0,0,12.44,8.77,25.96-8.11,7.99-9.98,8.73-20.11,8-26.73,4.81-4.57,10.93-11.97,11.68-20.52,22.73.14,44.34-3.74,64.33-13.41Z"
              ></path>
              <path
                {...stylex.props(styles.stroke, styles.fillBlack)}
                d="M308.52,515.19c-13.52,16.89-25.96,8.11-25.96,8.11-2.21-3.76-3.46-7.85-3.33-12.39,11.34,3.62,22.32,2.33,31.38-17.55,0,0,2.66-1.8,5.91-4.9.73,6.62,0,16.75-8,26.73Z"
              ></path>
            </g>
            <path
              {...stylex.props(styles.stroke, styles.fillPrimary)}
              d="M355.71,482.16c12.05-11.6,24.45-21.24,36.83-27.62.02,0,.03-.01.04-.02.78-.37,1.56-.76,2.33-1.15.04-.02.08-.04.11-.06,5.54-2.66,11.08-4.64,16.57-5.83,1.78,17.91-41.85,43.3-55.89,34.68Z"
            ></path>
            <polygon
              {...stylex.props(styles.stroke, styles.fillWhite)}
              points="359.64 623.13 287.92 623.13 308.52 600.02 359.64 623.13"
            ></polygon>
            <path {...stylex.props(styles.stroke)} d="M394.91,453.37c-.78.37-1.55.76-2.33,1.15"></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M279.34,614.38v-17.09h-8.7c2.12-3.64,4.37-7.44,6.73-11.36l19.47,8.81-17.51,19.65Z"
            ></path>
            <path {...stylex.props(styles.stroke)} d="M416.39,527.38c10.99-8.95,18.28-21.19,21.87-36.71"></path>
            <path
              {...stylex.props(styles.stroke, styles.fillBlack)}
              d="M421.78,574.33c-.31-15.04-3.15-30.14-5.38-35.98-1.14-2.99-2.47-5.65-3.94-8.01,1.36-.94,2.68-1.93,3.94-2.96,12.26,3.5,11.92,19.93,5.38,46.94Z"
            ></path>
            <path {...stylex.props(styles.stroke)} d="M473.85,566.38c-.3,8.14-1.93,15.37-4.8,21.75"></path>
          </g>
          <g>
            <path
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              d="M306.38,448.6c-6.05-.09-12.55.92-18.29,2.55-.63.18-1.25.36-1.86.56-4.41,1.41-8.25,3.19-10.91,5.1l-7.05-7.85c-4.23,2.8-9.11,5.53-13.53,6.8-3.16.9-6.08,1.05-8.37-.07-5.15-2.51-9.33-9.67-4.61-18.06,0,0,2.5,8.95,12.26,4.5,9.76-4.45-1.51-14.5-1.51-14.5,10.86-5.49,25.45-4.97,30.05-2.95,7.85,3.43,17.85,15.79,23.81,23.92Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M254.02,442.13c-9.76,4.45-12.26-4.5-12.26-4.5.94-1.68,2.25-3.41,3.97-5.16,1.94-1.97,4.26-3.57,6.78-4.84,0,0,11.27,10.04,1.51,14.5Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              d="M285.61,505.28c-1.12,2.67-3.49,4.43-6.36,5.19-4.91,1.31-11.26-.29-15.23-5.18-4.05-4.99-3.59-8.92-2.36-11.44,2.86,3.98,8.22,7.39,13.99,9.43,3.32,1.17,6.78,1.89,9.96,1.99Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              d="M289.78,479.79c-1.06,2.65-3.14,5.49-7.22,6.42-6.3,1.44-15.27-.88-21.71-5.51-2.07-1.49-3.88-3.22-5.26-5.14-5.92-8.27-3.67-15.38-.85-19.81,4.42-1.26,9.3-3.99,13.53-6.8l7.05,7.85c-1.72,1.24-2.94,2.53-3.51,3.82-2.31,5.25-1.22,9.76,1.96,13.11,2.1,2.22,5.13,3.93,8.69,5,1.07.32,2.18.59,3.34.79,1.25.22,2.58.29,3.97.26h0Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              d="M297.49,500.05c-1.45,2.78-4.18,4.34-7.54,4.95.67-.69,2.05-2.55.94-5.45-1.43-3.74-5.41-5.54-11.67-2.84-6.26,2.7-3.58,6.58-3.58,6.58-5.76-2.04-11.12-5.45-13.99-9.43-.12-.16-.23-.32-.34-.49-3.94-5.96-2.44-10.17-.47-12.67,6.44,4.63,15.41,6.95,21.71,5.51,4.08-.93,6.16-3.77,7.22-6.42.25.19,13.14,9.86,7.71,20.26Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M289.95,505s0,0,0,0c-1.36.24-2.82.33-4.34.28-3.19-.1-6.64-.82-9.96-1.99,0,0-2.68-3.88,3.58-6.58,6.26-2.7,10.24-.9,11.67,2.84,1.11,2.91-.27,4.77-.94,5.45Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              d="M328.2,467.95c-.75,8.55-6.87,15.95-11.68,20.52-3.26,3.1-5.91,4.9-5.91,4.9-9.06,19.88-20.03,21.17-31.38,17.55,0-.15.01-.3.02-.44,2.87-.76,5.24-2.52,6.36-5.19,1.52.05,2.98-.04,4.34-.28,0,0,0,0,0,0,3.36-.61,6.1-2.17,7.54-4.95,5.43-10.4-7.46-20.07-7.71-20.26h0c-1.39.04-2.73-.04-3.97-.26-1.16-.2-2.28-.47-3.34-.79-3.56-1.07-6.59-2.78-8.69-5-3.18-3.35-4.26-7.86-1.96-13.11.57-1.29,1.79-2.59,3.51-3.82,0,0,2.64,10.32,10.43,12.39,3.86,1.02,10.85-4.47,10.86-10.18.01-4.97-6.77-7.34-8.53-7.87,5.73-1.64,12.24-2.64,18.29-2.55,8.86.12,16.75,2.59,19.81,8.83,1.77,3.62,2.31,7.16,2.01,10.52Z"
            ></path>
            <path {...stylex.props(styles.stroke)} d="M268.27,448.96c6.16-4.08,10.95-8.3,10.95-8.3"></path>
            <path {...stylex.props(styles.stroke)} d="M262.11,459.66c4.4-4.14,8.81-5.09,13.21-2.85"></path>
            <path {...stylex.props(styles.stroke)} d="M289.95,505c-.19.19-.32.29-.32.29"></path>
            <path {...stylex.props(styles.stroke)} d="M289.78,479.79c10.67-.29,24.48-7.27,24.48-7.27"></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M296.62,459.02c-.01,5.71-7,11.2-10.86,10.18-7.79-2.07-10.43-12.39-10.43-12.39,2.66-1.91,6.5-3.69,10.91-5.1.61-.19,1.23-.38,1.86-.56,1.76.53,8.54,2.9,8.53,7.87Z"
            ></path>
          </g>
          <g>
            <path
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              d="M421.33,587.27c-1.79,17.88-7.98,32.15-9.72,35.87h-2.61l-79.45-34.85c2.03-2.16,4.2-4,6.49-5.51-.11-4.71.74-9.05,2.39-12.85,1.57-3.63,3.87-6.77,6.77-9.27-.75.06-4.48.3-7.62-.52-1.63-.42-3.11-1.13-3.93-2.3-1.21-1.72,4.33-19.24,21.65-26.38,4.23-1.74,8.49-2.37,12.53-2.31,2.63.04,5.17.36,7.52.85,3.63.76,6.85,1.9,9.37,2.99l17.13-13.67c3.84,2.45,7.53,6.06,10.62,11.03,1.47,2.36,2.8,5.02,3.94,8.01,2.23,5.83,5.07,20.93,5.38,35.98.09,4.4-.03,8.79-.45,12.94Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M345.19,560.66c-2.9,2.5-5.2,5.64-6.77,9.27l-12.37,9.87-9.5,2.34,4.39-8.74,16.61-13.26c3.15.82,6.88.58,7.62.52Z"
            ></path>
            <path {...stylex.props(styles.stroke)} d="M384.7,532.98c3.62,1.56,5.82,2.99,5.82,2.99"></path>
            <path {...stylex.props(styles.stroke)} d="M336.03,582.77c11.65-7.74,26.32-7.31,40.46-.48"></path>
            <path {...stylex.props(styles.stroke)} d="M357.51,554.69c7.89-1.63,17.41-.34,27.64,4.87"></path>
            <path {...stylex.props(styles.stroke)} d="M345.19,560.66c3.37-2.92,7.54-4.98,12.32-5.97"></path>
            <path
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              d="M393.23,515.71l-17.9,14.29c-2.36-.49-4.89-.81-7.52-.85,2.41-5.51,7.67-12.74,18.85-13.85,2.06-.2,4.28-.08,6.57.42Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M415.26,506.7c0,1.15-.49,2.28-1.44,3.04l-11.99,9.57-17.13,13.67c-2.53-1.09-5.74-2.23-9.37-2.99l17.9-14.29,15.49-12.36c1.7-1.36,4.21-1.02,5.62.75.62.78.92,1.7.92,2.61Z"
            ></path>
          </g>
          <g>
            <path
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              d="M364.03,370.89c-2.98,2.38-5.49,5.51-7.3,9.15-6.89-1.77-13.2-3.48-18.99-5.41-3.56-1.19-6.92-2.46-10.11-3.88-1.62-.72-3.18-1.48-4.71-2.28.69-1.81,1.49-3.55,2.38-5.22,1.22-2.29,2.61-4.45,4.15-6.45.17.22.34.44.52.64,1.75,2.01,18.41,9.91,34.05,13.45Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillSecondary)}
              d="M378.6,423.18c-7.47,4.57-16.23,6.63-25.21,5.3-14.03-2.08-25.17-11.94-30.42-25.12.03.01.06.03.1.05,1.92.99,3.88,1.85,5.88,2.57,4.2,1.52,8.57,2.46,13.14,2.91.08.01.16.02.24.03h0c2.51.24,5.08.33,7.71.29,2.38-.04,4.81-.18,7.3-.43,3.13,5.58,7.99,9.77,13.74,11.56l4.74,1.88.86.34c.63.24,1.27.45,1.93.62Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M393.15,395.82c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M391.92,369.86c-.47-.17-.94-.33-1.43-.47,0-.03,0-.06-.01-.09l1.44.56Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillPrimary)}
              d="M365.39,369.88c-.46.32-.92.66-1.36,1.01-15.65-3.54-32.3-11.44-34.05-13.45-.17-.2-.35-.42-.52-.64-3.6-4.75-5.69-14.13,9.58-17.47,2.74-.6,6.01-1.01,9.57-1.23,1.21-.08,2.46-.13,3.73-.16,2.29.22,4.32,1.09,6.06,2.7h0c4.5,4.17,7.05,13.32,6.98,29.25Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillPrimary)}
              d="M401.28,374.02c-.64.14-1.29.25-1.94.36-.05-.04-.1-.09-.16-.14-2.02-1.79-4.31-3.23-6.78-4.2l-.47-.18-1.44-.56c-.91-7.05-2.92-14.27-6.02-20.28,0,0,0,0,0,0-1.77-3.43-3.9-6.47-6.38-8.86,7.84,1.67,14.14,4.27,16.36,7.79,5.77,9.18,15.12,24.34,6.83,26.07Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M391.92,369.86c-.47-.17-.94-.33-1.43-.47,0-.03,0-.06-.01-.09l1.44.56Z"
            ></path>
            <path {...stylex.props(styles.stroke)} d="M399.33,374.38s-.08.01-.12.02c0-.05-.02-.11-.03-.16.05.04.1.09.16.14Z"></path>
            <path
              {...stylex.props(styles.stroke, styles.fillBlack)}
              d="M325.31,363.25c-.89,1.67-1.69,3.41-2.38,5.22-2.42-1.27-4.73-2.67-6.93-4.21,2.78-1.03,5.86-1.41,9.31-1.01Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M342.33,408.91c-.08,0-.16-.02-.24-.03-4.57-.45-8.94-1.39-13.14-2.91-2-.72-3.96-1.58-5.88-2.57-.04-.02-.07-.04-.1-.05-3.77-1.96-7.39-4.46-10.89-7.56-2.13-8.43-2.15-16.35.27-23.7.88-2.69,2.09-5.3,3.65-7.84,2.2,1.54,4.51,2.94,6.93,4.22,1.52.8,3.09,1.56,4.71,2.28,2.8,13.1,7.7,25.82,14.7,38.16Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillPrimary)}
              d="M357.34,408.77c-2.48.25-4.91.39-7.29.43-5.74-12.12-9.73-23.6-12.3-34.57,5.79,1.93,12.09,3.64,18.99,5.41-1.87,3.77-3.01,8.09-3.18,12.71-.21,5.9,1.2,11.43,3.78,16.01Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M350.04,409.2c-2.63.04-5.2-.05-7.71-.29-6.99-12.34-11.89-25.06-14.7-38.16,3.18,1.42,6.54,2.69,10.1,3.88,2.57,10.97,6.56,22.45,12.3,34.57Z"
            ></path>
            <line {...stylex.props(styles.stroke)} x1="327.64" x2="327.64" y1="370.76" y2="370.75"></line>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M390.48,369.3s0,.06.01.09c-.56-.17-1.13-.3-1.7-.42h0c-3.07-1.83-6.53-2.91-10.23-3.04-4.8-.17-9.32,1.29-13.17,3.95.08-15.92-2.48-25.08-6.98-29.25h0c-1.74-1.61-3.78-2.48-6.06-2.7-1.18-.11-2.42-.05-3.73.16,0,0,8.47-6.14,20.95-2.9,3.2.83,6.03,2.57,8.52,4.96,2.48,2.39,4.61,5.43,6.38,8.86,0,0,0,0,0,0,3.1,6.01,5.1,13.23,6.02,20.28Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M393.15,395.82c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79Z"
            ></path>
            <path {...stylex.props(styles.stroke)} d="M399.33,374.38s-.08.01-.12.02c0-.05-.02-.11-.03-.16.05.04.1.09.16.14Z"></path>
            <path
              {...stylex.props(styles.stroke, styles.fillPrimary)}
              d="M388.79,368.97c-1.12-.23-2.26-.36-3.43-.4-4.82-.17-9.36,1.31-13.22,3.98-6.78,4.7-11.45,13.11-11.79,22.84-.39,11.03,4.88,20.74,12.83,25.48.85.51,1.72.95,2.63,1.34l-4.74-1.88c-5.75-1.79-10.61-5.98-13.74-11.56-2.58-4.59-3.99-10.11-3.78-16.01.16-4.62,1.3-8.94,3.18-12.71,1.81-3.65,4.32-6.77,7.3-9.15.44-.35.9-.69,1.36-1.01,3.85-2.66,8.37-4.12,13.17-3.95,3.69.13,7.16,1.21,10.23,3.04Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M393.15,395.82c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79ZM399.33,374.38s-.08.01-.12.02c0-.05-.02-.11-.03-.16-2.02-1.79-4.31-3.23-6.78-4.2l-.47-.18c-.47-.17-.94-.33-1.43-.47-.56-.17-1.13-.3-1.7-.42h0c-1.12-.23-2.26-.37-3.43-.41-4.82-.17-9.36,1.31-13.22,3.98-6.78,4.7-11.45,13.11-11.79,22.84-.39,11.03,4.88,20.74,12.83,25.48.85.51,1.72.95,2.63,1.34l.86.34c.63.24,1.27.45,1.93.63,1.55.42,3.16.68,4.81.73,13.27.47,24.47-11.54,25.01-26.83.32-9.19-3.28-17.46-9.09-22.71Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M393.15,395.82c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79Z"
            ></path>
            <path
              {...stylex.props(styles.stroke, styles.fillWhite)}
              d="M393.15,395.82c-.15,4.18-3.21,7.46-6.84,7.33-3.63-.13-6.45-3.62-6.3-7.79.15-4.18,3.21-7.46,6.83-7.33,3.63.13,6.45,3.62,6.31,7.79Z"
            ></path>
            <line {...stylex.props(styles.stroke)} x1="390.73" x2="385.43" y1="369.4" y2="367.34"></line>
          </g>
        </g>
      )}

      {laptop && (
        <g>
          <polygon
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            points="308.52 600.02 287.92 623.13 279.34 623.13 279.34 614.38 296.85 594.73 308.52 600.02"
          ></polygon>
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M175.41,368.27v92.8l-4.61,1.91-11.26,4.66-.47-86.32c.47-7.28,2.26-10.71,5.27-12.79l4.99-3.45c2.57-1.78,6.08.06,6.08,3.19Z"
          ></path>
          <path
            {...stylex.props(styles.stroke, styles.fillWhite)}
            d="M238.52,531.46l-7.58,7.58-71.4-71.4,11.26-4.66,4.61-1.91,63.11,63.11c2.01,2.01,2.01,5.27,0,7.27Z"
          ></path>
          <line {...stylex.props(styles.stroke)} x1="221.17" x2="154.67" y1="539.03" y2="472.52"></line>
          <polygon
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            points="226.06 543.92 197.51 567.45 155.43 602.14 155.43 602.14 153.3 603.9 139.49 615.28 129.96 623.13 119.14 623.13 139.49 606.36 144.93 601.88 153.6 594.73 188.72 565.78 221.17 539.03 226.06 543.92"
          ></polygon>
          <path
            {...stylex.props(styles.stroke, styles.fillBlack)}
            d="M197.51,567.45l-42.08,34.69h0s-2.13,1.75-2.13,1.75c-2.95-.31-5.78-1.07-8.37-2.02l8.67-7.14,35.12-28.96c3.05.11,6.03.69,8.79,1.68Z"
          ></path>
          <line {...stylex.props(styles.stroke)} x1="129.96" x2="181.12" y1="623.13" y2="623.13"></line>
          <polygon
            {...stylex.props(styles.stroke, styles.fillSecondary)}
            points="279.34 614.38 279.34 623.13 181.12 623.13 192.48 614.38 279.34 614.38"
          ></polygon>
          <rect {...stylex.props(styles.stroke, styles.fillSecondary)} height="17.09" width="11.43" x="244.84" y="597.29"></rect>
          <rect {...stylex.props(styles.stroke, styles.fillSecondary)} height="17.09" width="11.43" x="233.42" y="597.29"></rect>
          <rect {...stylex.props(styles.stroke, styles.fillSecondary)} height="17.09" width="11.43" x="221.99" y="597.29"></rect>
          <polygon
            {...stylex.props(styles.stroke, styles.fillWhite)}
            points="221.99 597.29 221.99 614.38 192.48 614.38 214.67 597.29 221.99 597.29"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillPrimary)}
            points="409 623.13 377.48 623.13 280.59 580.63 244.84 564.95 256.02 556.04 287.36 569.78 329.54 588.29 409 623.13"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillWhite)}
            points="377.48 623.13 359.64 623.13 308.52 600.02 296.85 594.73 277.38 585.93 242.12 569.98 244.84 564.95 280.59 580.63 377.48 623.13"
          ></polygon>
          <rect {...stylex.props(styles.stroke, styles.fillWhite)} height="17.09" width="23.07" x="256.27" y="597.29"></rect>
        </g>
      )}

      {sparkle && (
        <g>
          <line {...stylex.props(styles.stroke)} x1="213.44" x2="213.44" y1="416.61" y2="429.01"></line>
          <line {...stylex.props(styles.stroke)} x1="219.64" x2="207.24" y1="422.81" y2="422.81"></line>
        </g>
      )}

      {cloud && (
        <path
          {...stylex.props(styles.stroke, styles.fillWhite)}
          d="M146.33,553.23s-6.53-10.66-35.21-6.99-34.76,17.47-10.3,22.25c0,0-27.75,3.49-20.77,16.36,6.99,12.87,54.05-2.35,54.05-2.35,0,0,14.34,11.91,29.05,9.15,14.71-2.76,20.41-9.15,20.41-9.15,0,0,21.47,12.83,28.11-2.43,6.64-15.26-6.6-25.92-20.94-26.29-14.34-.37-20.02,6.07-20.02,6.07,0,0,.9-10.3-8.48-14.71-9.38-4.41-15.9,8.09-15.9,8.09Z"
        ></path>
      )}

      {arrow && (
        <g transform="translate(0 35)">
          <polygon
            {...stylex.props(styles.stroke, styles.fillBlack)}
            points="230.33 313.57 229.35 316.68 236.73 321.48 241.76 315.53 250.09 321.11 251.07 317.99 250.8 317.81 242.74 312.41 242.6 312.58 240.7 314.82 237.71 318.36 231.4 314.26 230.33 313.57"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillBlack)}
            points="227.02 301.88 226.03 305 233.4 309.93 235.44 307.52 229.06 303.26 227.02 301.88"
          ></polygon>
          <polygon
            {...stylex.props(styles.stroke, styles.fillWhite)}
            points="229.06 303.26 235.44 307.52 233.4 309.93 230.33 313.57 231.4 314.26 237.71 318.36 240.7 314.82 242.6 312.58 242.74 312.41 250.8 317.81 251.07 317.99 258.52 283.74 227.02 301.88 229.06 303.26"
          ></polygon>
        </g>
      )}
    </svg>
  );
};
