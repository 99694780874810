import * as stylex from '@stylexjs/stylex';

import { fontSize, fontWeight, radius, spacing, systemColors } from '~/styles/tokens.stylex';

export const styles = stylex.create({
  secondaryActivities: {
    display: 'flex',
    gap: spacing.xxsmall,
    flexWrap: 'wrap',
  },

  secondaryActivityLabel: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    gap: spacing.small,
    paddingInline: spacing.small,
    paddingBlock: spacing.xsmall,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: systemColors.borderLight,
    borderRadius: radius.xxxsmall,
    fontSize: fontSize.regular,
    fontWeight: fontWeight.regular,
    cursor: 'pointer',
    userSelect: 'none',
  },
});
