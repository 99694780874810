import dayjs from 'dayjs';

import type { CreditNote } from '~/api/creditNotes/types';
import type { SubmittedQuotation } from '~/api/quotations/types';
import type { Invoice } from '~/types/invoice';

/**
 * Group an array of objects by property
 */
export const groupObjectsBy = <T, K extends PropertyKey>(array: T[], getKey: (item: T) => K) => {
  return array.reduce(
    (grouped, current) => {
      const group = getKey(current);

      grouped[group] ??= [];
      grouped[group].push(current);

      return grouped;
    },
    {} as Record<K, T[]>,
  );
};

/**
 * Returns the keys of a given enum object
 */
export const enumKeys = <O extends Record<string, unknown>, K extends keyof O = keyof O>(object: O): K[] => {
  return Object.keys(object).filter((k) => Number.isNaN(+k)) as K[];
};

/**
 * Returns the values of a given enum object
 */
export const enumValues = <O extends Record<string, unknown>, V extends O[keyof O] = O[keyof O]>(object: O): V[] => {
  return Object.entries(object)
    .filter(([key]) => Number.isNaN(+key))
    .map(([, value]) => value) as V[];
};

/**
 * Returns the year of the given data.
 * By default this is the docYear property if present. If not, this is the year from the docDate property.
 */
export const getObjectYear = <PageType extends SubmittedQuotation | Invoice | CreditNote>(data: PageType): number => {
  if (data.draft) {
    return dayjs(data.createdDate).year();
  }

  return typeof data.docYear === 'number' ? data.docYear : dayjs(data.docDate).year();
};
