import type { DescriptionProps } from '@headlessui/react';
import type { ReactNode } from 'react';

import { Description as HuiDescription } from '@headlessui/react';
import * as stylex from '@stylexjs/stylex';

import type { WithoutStyleProps } from '../../types';

import { styles } from './Description.style';

type Props = WithoutStyleProps<DescriptionProps> & {
  children: ReactNode;
};

export const Description = ({ children, ...delegated }: Props) => {
  return (
    <HuiDescription {...stylex.props(styles.description)} {...delegated}>
      {children}
    </HuiDescription>
  );
};
