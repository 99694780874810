import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useStartPaymentFollowUp } from '~/api/invoices';
import { useAddPaymentFollowUpRemark } from '~/api/invoices/useAddPaymentFollowUpRemark';
import { Button, FormControl, FormErrorMessage, FormTextInput, Modal, Spinner } from '~/components';

import type { StartFollowUpModalProps as Props } from './types';

export const StartFollowUpModal = ({ invoiceId, onClose }: Props) => {
  const startPaymentFollowUpMutation = useStartPaymentFollowUp(invoiceId);
  const addPaymentFollowUpRemarkMutation = useAddPaymentFollowUpRemark(invoiceId);

  const { control, handleSubmit } = useForm<{ remark: string }>({
    defaultValues: { remark: '' },
  });

  const { t } = useTranslation(['common', 'invoices', 'validation']);

  const onStartPaymentFollowUp = () => {
    startPaymentFollowUpMutation.mutate(undefined, {
      onError: () => toast.error(t('common:error')),
    });
  };

  const onAddPaymentFollowUpRemark = handleSubmit((data) => {
    addPaymentFollowUpRemarkMutation.mutate(data, {
      onSuccess: () => onClose(),
      onError: () => toast.error(t('common:error')),
    });
  });

  return (
    <Modal onClose={onClose}>
      <Modal.Title>
        {!startPaymentFollowUpMutation.isSuccess
          ? t('invoices:details.followUp.modal.title')
          : t('invoices:details.followUp.modal.titleRemark')}
      </Modal.Title>

      {!startPaymentFollowUpMutation.isSuccess ? (
        <>
          <p>{t('invoices:details.followUp.modal.message')}</p>

          <Modal.Actions>
            <Button disabled={startPaymentFollowUpMutation.isPending} onClick={onClose} type="secondary">
              {t('common:cancel')}
            </Button>
            <Button hasSpinner icon="Gavel" isLoading={startPaymentFollowUpMutation.isPending} onClick={onStartPaymentFollowUp}>
              {t('invoices:details.followUp.modal.accept')}
            </Button>
          </Modal.Actions>
        </>
      ) : (
        <>
          <p>{t('invoices:details.followUp.modal.remarkMessage')}</p>

          <form className="space-y-4" onSubmit={onAddPaymentFollowUpRemark}>
            <FormControl control={control} name="remark" rules={{ required: true }}>
              <FormTextInput placeholder={t('invoices:details.followUp.modal.input.placeholder')} />
              <FormErrorMessage required={t('validation:required')} />
            </FormControl>

            <Modal.Actions>
              <Button disabled={addPaymentFollowUpRemarkMutation.isPending} isSubmit>
                {addPaymentFollowUpRemarkMutation.isPending && <Spinner size={24} />}
                <span>{t('invoices:details.followUp.modal.addRemark')}</span>
              </Button>
            </Modal.Actions>
          </form>
        </>
      )}
    </Modal>
  );
};
