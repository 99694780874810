import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { UploadQuotationAttachmentPayload } from './types';

import { request } from '../request';
import { quotationAttachmentQueryOptions } from './useQuotationAttachment';

export const useUploadQuotationAttachment = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (body: UploadQuotationAttachmentPayload) => {
      const token = getAccessToken();
      return request(`/quotations/${id}/attachment`, { method: 'POST', token, body });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(quotationAttachmentQueryOptions(id));
    },
  });
};
