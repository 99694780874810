import { z } from 'zod';

import { calculationDataSchema, lineSchema } from '~/api/common/schemas';

import { clientForClientQuotationSchema } from '../clients/schemas';
import { iso8601DateTimeSchema } from '../common/schemas';

export const draftQuotationSchema = z.object({
  allowed: z.literal(false),
  allowedByClient: z.literal(false),
  allowedByUser: z.literal(false),
  allowedOn: z.null(),
  brandId: z.number().nullable(),
  calculationData: calculationDataSchema,
  clientId: z.number().nullable(),
  conditionId: z.number().nullable(),
  contactId: z.number().nullable(),
  conditionUrl: z.string().nullable(),
  customAgreementId: z.number().nullable(),
  customConditionId: z.number().nullable(),
  declinedByUser: z.literal(false),
  description: z.string().nullable(),
  docDate: z.null(),
  docYear: z.null(),
  draft: z.literal(true),
  entryNumber: z.string().nullable(),
  expirationDate: iso8601DateTimeSchema.nullable(),
  id: z.number(),
  introduction: z.string().nullable(),
  mail: z.string().nullable(),
  quotationLines: z.array(lineSchema),
  specialConditions: z.string().nullable(),
  title: z.string().nullable(),
});

export const submittedQuotationSchema = z.object({
  allowed: z.literal(true),
  allowedByClient: z.boolean(),
  allowedByUser: z.boolean(),
  allowedOn: iso8601DateTimeSchema.nullable(),
  brandId: z.number(),
  calculationData: calculationDataSchema,
  clientId: z.number(),
  conditionId: z.number().nullable(),
  contactId: z.number(),
  conditionUrl: z.string(),
  customAgreementId: z.number().nullable(),
  customConditionId: z.number().nullable(),
  declinedByUser: z.boolean(),
  description: z.string(),
  docDate: iso8601DateTimeSchema,
  docYear: z.number().min(2000),
  draft: z.literal(false),
  entryNumber: z.string(),
  expirationDate: iso8601DateTimeSchema,
  id: z.number(),
  introduction: z.string(),
  mail: z.string(),
  quotationLines: z.array(lineSchema).nonempty(),
  specialConditions: z.string().nullable(),
  title: z.string(),
});

// Temporary state between the final update call (draft false, allowed false)
// and the submit call (draft false, allowed true)
export const finalQuotationSchema = submittedQuotationSchema.extend({
  allowed: z.literal(false),
  allowedByClient: z.literal(false),
  allowedByUser: z.literal(false),
  allowedOn: z.null(),
  declinedByUser: z.literal(false),
  docDate: z.null(),
  docYear: z.null(),
});

// Schemas in a union are tried left-to-right, so they should be ordered from most likely to least likely.
export const quotationSchema = z.union([submittedQuotationSchema, draftQuotationSchema, finalQuotationSchema]);

export const quotationForClientSchema = z.object({
  quotation: quotationSchema,
  client: clientForClientQuotationSchema,
  user: z.unknown(),
});
