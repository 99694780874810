import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { env } from '~/constants/env';

import { useCraftLocalStorage } from './useCraftLocalStorage';

export const useAppLanguage = () => {
  const [language] = useCraftLocalStorage('language');

  const { i18n } = useTranslation();

  useEffect(() => {
    if (language !== null && language !== i18n.language) {
      i18n.changeLanguage(language);
    }

    if (env.VITE_MODE === 'testing') {
      i18n.changeLanguage('cimode');
    }
  }, [i18n, language]);
};
