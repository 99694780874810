import { useMutation } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { apiResourceSchema } from '../common/schemas';
import { ServerError } from '../errors';
import { request } from '../request';
import { vatInformationSchema } from './schemas';

export const useFetchVatInformation = () => {
  return useMutation({
    mutationFn: async (vatNumber: string) => {
      const token = getAccessToken();
      const response = await request(`/clients/vat-information/${vatNumber}`, { token });

      const { data: vatInformation } = apiResourceSchema(vatInformationSchema).parse(response);

      vatInformation.establishmentUnits
        .sort(({ street: a }, { street: b }) => a.localeCompare(b))
        .sort(({ city: a }, { city: b }) => a.localeCompare(b))
        .sort(({ type: a }, { type: b }) => {
          const sortPriority = { headquarters: 1, 'establishment unit': 2 } as const;
          return sortPriority[a] - sortPriority[b];
        });

      return vatInformation;
    },
    retry: (failureCount, error) => error instanceof ServerError && failureCount < 3,
  });
};
