import * as stylex from '@stylexjs/stylex';
import { useTranslation } from 'react-i18next';

import { useUser } from '~/api/user';
import { Text, Title } from '~/components/UI';

import { styles } from './InvitorDiscountProgress.styles';

export const InvitorDiscountProgress = () => {
  const { data: user } = useUser();

  const activeInvitorDiscounts = user.invitorDiscounts.filter(({ isActive }) => isActive).length;

  const { t } = useTranslation(['invitations']);

  return (
    <div {...stylex.props(styles.wrapper)}>
      <Title as="h4">{t('invitations:progressBar.title')}</Title>

      <div {...stylex.props(styles.progressCard)}>
        <Text styles={[styles.labelDiscount, styles.headerLabelDiscount]} weight="medium">
          {t('invitations:progressBar.labelDiscount')}
        </Text>
        <Text styles={[styles.labelDiscount, styles.label25, activeInvitorDiscounts === 1 && styles.labelActive]} weight="medium">
          {t('invitations:progressBar.label25Percent')}
        </Text>
        <Text styles={[styles.labelDiscount, styles.label50, activeInvitorDiscounts === 2 && styles.labelActive]} weight="medium">
          {t('invitations:progressBar.label50Percent')}
        </Text>
        <Text styles={[styles.labelDiscount, styles.label75, activeInvitorDiscounts === 3 && styles.labelActive]} weight="medium">
          {t('invitations:progressBar.label75Percent')}
        </Text>
        <Text styles={[styles.labelDiscount, styles.label100, activeInvitorDiscounts === 4 && styles.labelActive]} weight="medium">
          {t('invitations:progressBar.label100Percent')}
        </Text>

        <div {...stylex.props(styles.progressBar)}>
          {activeInvitorDiscounts >= 1 && <div {...stylex.props(styles.progressBarFilled, styles.progressBarFilled25)} />}
          {activeInvitorDiscounts >= 2 && <div {...stylex.props(styles.progressBarFilled, styles.progressBarFilled50)} />}
          {activeInvitorDiscounts >= 3 && <div {...stylex.props(styles.progressBarFilled, styles.progressBarFilled75)} />}
          {activeInvitorDiscounts >= 4 && <div {...stylex.props(styles.progressBarFilled, styles.progressBarFilled100)} />}

          <div {...stylex.props(styles.circleWrapper)}>
            <div {...stylex.props(styles.circle, styles.circle25, activeInvitorDiscounts >= 1 && styles.circleFilled)} />
            <div {...stylex.props(styles.circle, styles.circle50, activeInvitorDiscounts >= 2 && styles.circleFilled)} />
            <div {...stylex.props(styles.circle, styles.circle75, activeInvitorDiscounts >= 3 && styles.circleFilled)} />
            <div {...stylex.props(styles.circle, styles.circle100, activeInvitorDiscounts >= 4 && styles.circleFilled)} />
          </div>
        </div>

        <Text styles={[styles.labelInvitations, styles.headerLabelInvitations]} weight="medium">
          {t('invitations:progressBar.labelInvitations')}
        </Text>
        <Text styles={[styles.labelInvitations, styles.label1]} weight="medium">
          {t('invitations:progressBar.label1Invitation')}
        </Text>
        <Text styles={[styles.labelInvitations, styles.label2]} weight="medium">
          {t('invitations:progressBar.label2Invitations')}
        </Text>
        <Text styles={[styles.labelInvitations, styles.label3]} weight="medium">
          {t('invitations:progressBar.label3Invitations')}
        </Text>
        <Text styles={[styles.labelInvitations, styles.label4]} weight="medium">
          {t('invitations:progressBar.label4InvitationsOrMore')}
        </Text>
      </div>
    </div>
  );
};
