import type { Variants } from 'framer-motion';

import { springTransition } from '~/utils/motion';

export const rotateFABVariants: Variants = {
  open: { rotate: 0 },
  closed: { rotate: 45 },
};

export const fabListVariants: Variants = {
  visible: {
    display: 'flex',
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  hidden: {
    display: 'none',
    opacity: 0,
    transition: {
      when: 'afterChildren',
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
};

export const FABItemsVariants: Variants = {
  visible: {
    x: 0,
    opacity: 1,
    pointerEvents: 'all',
    transition: {
      y: springTransition,
    },
  },
  hidden: {
    x: 50,
    opacity: 0,
    pointerEvents: 'none',
    transition: {
      y: springTransition,
    },
  },
};
