import { env } from '~/constants/env';
import { Language } from '~/types/app';

import { developerDutch } from './dev';
import { english } from './en';
import { french } from './fr';
import { dutch } from './nl';

export const resources = {
  [Language.DUTCH]: dutch,
  [Language.ENGLISH]: english,
  [Language.FRENCH]: french,
  ...(env.IS_LOCAL_DEV_ENV && { dev: developerDutch }),
};
