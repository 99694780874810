import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useLinkCashaca = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (cashacaApiToken: string) => {
      const token = getAccessToken();
      const body = { accountancySoftware: 'cashaca', accountancySoftwareData: { cashacaApiToken } };
      return request('/integrations/accountancy-software', { method: 'POST', token, body });
    },
    onSuccess: () => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, integrations: { ...user.integrations, cashaca: true } };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
