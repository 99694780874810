import { useTranslation } from 'react-i18next';

import { FormControl, FormErrorMessage, FormLabel, FormTextInput, IconTooltip } from '~/components';
import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';

import { useLineContext } from '../../SectionLines/context';
import styles from './FormProductName.module.scss';

export const FormProductName = () => {
  const { control } = useInvoiceQuotationFormContext();
  const { isMobile, lineFieldName } = useLineContext();

  const { t } = useTranslation(['lines', 'validation']);

  return (
    <FormControl control={control} name={`${lineFieldName}.productName`} rules={{ required: true }}>
      {isMobile && <FormLabel>{t('lines:productName.label')}</FormLabel>}

      <div className={styles.Wrapper}>
        <FormTextInput disabled />

        <div className={styles.ProductIcon}>
          <IconTooltip color="text-primary-500" iconName="DesignServices" size={20} text={t('lines:productName.tooltip')} />
        </div>
      </div>

      {isMobile && <FormErrorMessage required={t('validation:required')} />}
    </FormControl>
  );
};
