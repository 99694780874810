import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useAuthorizeBillit = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (authorizationCode: string) => {
      const token = getAccessToken();
      const body = { code: authorizationCode };
      return request('/oauth2/callback/billit', { method: 'POST', token, body });
    },
    onSuccess: () => {
      queryClient.setQueryData(userQueryOptions.queryKey, (user) => {
        if (!user) return;

        return { ...user, integrations: { ...user.integrations, billit: true } };
      });
      queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
