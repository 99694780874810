import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { userQueryOptions } from './useUser';

export const useRefreshKboInformation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      const token = getAccessToken();
      return request('/user/refresh-kbo-information', { method: 'POST', token });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(userQueryOptions);
    },
  });
};
