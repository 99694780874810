import type { SwiperRef } from 'swiper/react';

import { useWindowWidth } from '@react-hook/window-size';
import classnames from 'classnames';
import { useLayoutEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Keyboard, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { CarouselProps as Props } from './types';

import './Carousel.scss';
import { useSlidesPerView } from './hooks';
import { Slide } from './Slide';

export const Carousel = ({ slides }: Props) => {
  const swiperRef = useRef<SwiperRef>(null);

  const windowWidth = useWindowWidth();

  const [carouselWidth, setCarouselWidth] = useState<number | null>(null);
  const slidesPerView = useSlidesPerView(carouselWidth);
  const showSurrounding = slidesPerView > 1;

  /**
   * Measure the width of the carousel
   */
  useLayoutEffect(() => {
    if (!(swiperRef.current instanceof Element)) return;

    const { width } = swiperRef.current.getBoundingClientRect();
    setCarouselWidth(width);
  }, [windowWidth]);

  return (
    <Swiper
      allowTouchMove
      className={classnames({
        'extra-padding': showSurrounding && slides.length > slidesPerView,
        'hidden-neighbors': !showSurrounding,
      })}
      keyboard={{ enabled: true }}
      modules={[Keyboard, Pagination, Navigation]}
      navigation
      pagination={{ clickable: true }}
      ref={swiperRef}
      slidesPerGroup={slidesPerView}
      slidesPerView={slidesPerView}
      spaceBetween={20}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={`${slide.id}-${index}`}>
          <Slide {...slide} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
