/**
 * Strongly-typed alternative to Boolean that can be used as predicate in Array.prototype.filter()
 */
export const filterBoolean = <T>(value: T): value is Exclude<T, false | null | undefined | '' | 0> => Boolean(value);

/**
 * Strongly-typed alternative to Array.prototype.includes() that narrows the type of the search element
 */
export const includes = <T>(array: Readonly<T[]>, searchElement: unknown): searchElement is T => {
  return array.includes(searchElement as T);
};

/**
 * Helper function that can be used to conditionally insert a value in an array
 * Must be used with spread syntax, e.g. [...insertIf(myCondition, myValue)]
 */
export const insertIf = <const T>(condition: boolean, value: T) => (condition ? ([value] as const) : ([] as const));
