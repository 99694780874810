import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import type { CreateCustomConditionPayload } from './types';

import { apiResourceSchema } from '../common/schemas';
import { request } from '../request';
import { customConditionSchema } from './schemas';
import { customConditionsQueryOptions } from './useCustomConditions';

export const useCreateCustomCondition = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: CreateCustomConditionPayload) => {
      const token = getAccessToken();
      const response = await request('/custom-conditions', { method: 'POST', token, body });

      const { data: customCondition } = apiResourceSchema(customConditionSchema).parse(response);

      return customCondition;
    },
    onSuccess: (customCondition) => {
      queryClient.setQueryData(customConditionsQueryOptions.queryKey, (customConditions) => [customCondition, ...(customConditions ?? [])]);
      queryClient.invalidateQueries(customConditionsQueryOptions);
    },
  });
};
