import { useTranslation } from 'react-i18next';

import { PlanFeature } from '../PlanPicker/PlanFeature';
import styles from './EarlyAdopterInfo.module.scss';

export const EarlyAdopterInfo = () => {
  const { t } = useTranslation(['settings']);

  return (
    <div className={styles.Wrapper}>
      <h2 className={styles.Title}>{t('settings:paymentPlan.earlyAdopter.title')}</h2>
      <p className={styles.Description}>{t('settings:paymentPlan.earlyAdopter.description')}</p>

      <ul className={styles.FeatureList}>
        {typeof t('settings:paymentPlan.earlyAdopter.features', { returnObjects: true }) === 'string'
          ? (t('settings:paymentPlan.earlyAdopter.features', { returnObjects: true }) as unknown as string) // i18n cimode
          : t('settings:paymentPlan.earlyAdopter.features', { returnObjects: true }).map((translation, i) => (
              <PlanFeature key={i} text={translation.text} tooltip={'tooltip' in translation ? translation.tooltip : undefined} />
            ))}
      </ul>
    </div>
  );
};
