import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAccessToken } from '~/utils/auth';

import { request } from '../request';
import { customConditionsQueryOptions } from './useCustomConditions';

export const useDeleteCustomCondition = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => {
      const token = getAccessToken();
      return request(`/custom-conditions/${id}`, { method: 'DELETE', token });
    },
    onSuccess: () => {
      return queryClient.invalidateQueries(customConditionsQueryOptions);
    },
  });
};
