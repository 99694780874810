import classNames from 'classnames';

import { IconTooltip } from '~/components';
import { Icon } from '~/components/SVG';

import type { AccountCompletionCheckboxProps as Props } from './types';

import styles from './AccountCompletionCheckbox.module.scss';

export const AccountCompletionCheckbox = ({ body, checked, tooltip }: Props) => {
  return (
    <>
      <div className={classNames(styles.box, { [styles.checked]: checked })}>
        {checked && <Icon name="Check" />}
        {!checked && <Icon name="ChevronRight" />}
      </div>
      <span>{body}</span>
      {tooltip && (
        <span className="ml-2">
          <IconTooltip color="text-gray-400" text={tooltip} />
        </span>
      )}
    </>
  );
};
