import { useTranslation } from 'react-i18next';

import { useInvoiceQuotationFormContext } from '~/hooks/InvoiceQuotationForm/useInvoiceQuotationFormContext';
import { useScreenWidth } from '~/hooks/useScreenWidth';

import styles from './LinesHeader.module.scss';

export const LinesHeader = () => {
  const { watch } = useInvoiceQuotationFormContext();

  const { t } = useTranslation(['lines']);
  const screen = useScreenWidth();

  if (!screen.isLarge) {
    return null;
  }

  const someLinesHaveDiscount = watch('sections')
    .flatMap(({ lines }) => lines)
    .some((line) => line.type === 'entry' && line.discountType !== null);

  return (
    <thead>
      <tr>
        <th className={styles.DragHandler} />
        <th className={styles.Description}>{t('lines:description.header')}</th>
        <th className={styles.PriceAmount}>
          <div>
            <span>{t('lines:quantity.header')}</span>
            <span>{t('lines:price.header')}</span>
            {someLinesHaveDiscount && <span>{t('lines:discount.header')}</span>}
          </div>
        </th>
        <th className={styles.Total}>{t('lines:lineTotal.header')}</th>
        <th className={styles.Delete} />
      </tr>
    </thead>
  );
};
